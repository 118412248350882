import {
	Alert,
	AsyncSelect,
	Badge,
	Stack,
	Text,
	Box,
	Button,
	Inline,
	Icons,
	Spinner,
	Table,
	Time,
	DeferRender,
} from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import {
	utcTimestampToLocalDate,
	getDiff,
	fromNow,
	endOf,
	dateToUTCString,
	formatDate,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import {
	numberToLocalString,
	withOrdinalSuffix,
} from "@sembark-travel/number-utils"
import {
	Col,
	Component,
	Divider,
	Grid,
	joinAttributes,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import {
	Form,
	SelectField,
	TextInputField,
	SelectInputField,
	validateFormValues,
	SubmissionError,
	withServerErrors,
	GetFieldValue,
	TextAreaInputField,
	useFieldValue,
	arrayMutators,
	EmptyNumberValidator,
} from "@sembark-travel/ui/form"
import React, { useId, useMemo, useEffect, useRef, useState } from "react"
import useSWR from "swr"
import * as Validator from "yup"
import { SelectHotelPaymentPreferences } from "../HotelPaymentPreferences"
import {
	IBooking,
	getDisplayNameForStage,
	getDescriptionForStage,
	stageToDisplayNameMapping,
} from "./store"
import {
	AddReplacementHotelForm,
	EditHotelBookingDetailsInDialog,
} from "./ItemForm"
import { InstalmentsInputField } from "../Payments"
import { TTripDestination } from "../TripDestinations"
import {
	IQuoteHotelExtraParams,
	IQuoteHotelParams,
	normalizeHotelExtras,
	normalizeHotels,
} from "./../Trips/store"
import { Money } from "@sembark-travel/ui/base"
import { SelectHotelContact } from "../Hotels"
import { IContact } from "../Contacts"

interface IInstalment {
	amount: number
	due_at: string
	currency: string
}

function XHR(xhr: XHRInstance) {
	return {
		async changeHotelBookingStage(
			bookingIds: Array<number>,
			stageId: number,
			comments?: string
		): Promise<void> {
			return xhr.patch("/trip-hotel-booking-stages", {
				items: bookingIds,
				stage: stageId,
				comments,
			})
		},
		async getBookingMethods(
			params: unknown
		): Promise<Array<{ id: string; name: string }>> {
			return xhr
				.get(`/hotel-booking-methods`, { params })
				.then((resp) => resp.data.data)
		},
		async getBookingDropReasons(
			params: unknown
		): Promise<Array<{ id: string; name: string }>> {
			return xhr
				.get(`/hotel-booking-drop-reasons`, { params })
				.then((resp) => resp.data.data)
		},
		async getBookingChangeReasons(
			params: unknown
		): Promise<Array<{ id: string; name: string }>> {
			return xhr
				.get(`/hotel-booking-change-reasons`, { params })
				.then((resp) => resp.data.data)
		},
		async getBookingStageTags(
			params: unknown
		): Promise<Array<{ id: string; name: string }>> {
			return xhr
				.get(`/hotel-booking-stage-tags`, { params })
				.then((resp) => resp.data.data)
		},
		async getInstalments(quoteId: string | number): Promise<{
			data: IInstalment[]
			meta: { total: number; currency: string }
		}> {
			return xhr
				.get(`/hotel-bookings/${quoteId}/instalment-presets`)
				.then((resp) => resp.data)
		},
	}
}

export function HotelBookingCurrentStage({
	booking,
	onChangeSuccess,
	tripStartDate: startDate,
	tripEndDate: endDate,
	minimal,
	tripDestinations,
}: {
	booking: IBooking
	onChangeSuccess?: () => void
	tripStartDate: Date
	tripEndDate: Date
	minimal?: boolean
	tripDestinations: Array<TTripDestination>
}) {
	const {
		booking_stage,
		is_shared,
		booking_method,
		change_reason,
		drop_reason,
	} = booking
	const { state, created_by, created_at, tag, comments } = booking_stage
	return (
		<Box>
			<Box display="inlineFlex" alignItems="center" whiteSpace="preserve">
				<Box
					marginRight="1"
					title={
						created_at && created_by
							? `By ${created_by.name}, ${fromNow(
									utcTimestampToLocalDate(created_at)
								)}`
							: undefined
					}
				>
					{state === "booked" ? (
						<Box display="inlineBlock" marginRight="2">
							<Icons.OkCircleSolid color="success" />
						</Box>
					) : null}
					<Box as="span" fontWeight="semibold">
						{getDisplayNameForStage(state)}
					</Box>{" "}
					{is_shared ? null : (
						<>
							{state === "booked" ? (
								<Box
									display="inline"
									fontSize="xs"
									fontWeight="normal"
									marginLeft="1"
									color="muted"
								>
									{booking_method}
								</Box>
							) : state === "changed" ? (
								<Box
									display="inline"
									fontSize="xs"
									fontWeight="normal"
									marginLeft="1"
									color="muted"
								>
									{change_reason}
								</Box>
							) : state === "dropped" ? (
								<Box
									display="inline"
									fontSize="xs"
									fontWeight="normal"
									marginLeft="1"
									color="muted"
								>
									{drop_reason}
								</Box>
							) : null}
						</>
					)}
					{!minimal && tag && !is_shared ? (
						<Box marginTop="1">
							<Badge primary outlined>
								{tag}
							</Badge>
						</Box>
					) : null}
				</Box>
				<HotelBookingStageChange
					tripDestinations={tripDestinations}
					booking={booking}
					onChangeSuccess={onChangeSuccess}
					tripStartDate={startDate}
					tripEndDate={endDate}
					key={state}
					minimal={minimal}
				/>
			</Box>
			{!minimal ? (
				<Box fontSize="sm" color="muted" marginTop="2">
					{comments && !is_shared ? (
						<blockquote style={{ maxHeight: "100px", overflow: "auto" }}>
							{comments}
						</blockquote>
					) : null}
					{created_by && created_at
						? joinAttributes(
								`by ${created_by.name}`,
								<RelativeTime value={utcTimestampToLocalDate(created_at)} />
							)
						: null}
				</Box>
			) : null}
		</Box>
	)
}

export function HotelBookingStageChange({
	booking,
	onChangeSuccess,
	tripStartDate: startDate,
	tripEndDate: endDate,
	minimal,
	tripDestinations,
}: {
	booking: IBooking
	onChangeSuccess?: () => void
	tripStartDate: Date
	tripEndDate: Date
	minimal?: boolean
	tripDestinations: Array<TTripDestination>
}) {
	const xhr = useXHR()
	const { id, booking_stage, can_modify } = booking
	const readOnly = !can_modify
	const { state } = booking_stage
	const [isOpen, openDialog, closeDialog] = useDialog()
	const canEdit = useMemo(() => {
		const nextStages = getNextStages(state)
		return Boolean(nextStages.length && !readOnly)
	}, [state, readOnly])
	if (!canEdit) return null
	return (
		<>
			<Button
				onClick={openDialog}
				{...(minimal ? { level: "tertiary", inline: true } : {})}
				title="Update Booking Status"
			>
				{minimal ? <Icons.Pencil /> : "Update Status"}
			</Button>
			<Dialog open={isOpen} onClose={closeDialog} title="Update Booking Status">
				<HotelBookingStageChangeForm
					tripDestinations={tripDestinations}
					booking={booking}
					onChangeSuccess={onChangeSuccess}
					tripStartDate={startDate}
					tripEndDate={endDate}
					onSubmit={async ({ replacementBookingDetails, ...values }) => {
						const resp = await xhr.patch(
							`/hotel-bookings/${id}/active-stage`,
							values
						)
						if (replacementBookingDetails) {
							await xhr.post(`/hotel-bookings`, replacementBookingDetails)
							showSnackbar(
								"Hotel Booking status updated and replacement added."
							)
						} else {
							showSnackbar("Hotel Booking status updated.")
						}
						onChangeSuccess && onChangeSuccess()
						closeDialog()
						const booking: IBooking = resp.data.data
						return booking
					}}
					onCancel={closeDialog}
				/>
			</Dialog>
		</>
	)
}

function getNextStages(
	currentState: IBooking["booking_stage"]["state"]
): Array<typeof currentState> {
	switch (currentState) {
		case "initialized":
		case "in_progress":
			return ["in_progress", "booked", "changed"]
		case "booked":
			return ["dropped"]
		case "changed":
		case "dropped":
			return []
		case "trip_dropped":
			return []
		default:
			return []
	}
}

interface IBookingStageChangeData {
	nextStage?: IBooking["booking_stage"]["state"]
	tag?: { id: number; name: string }
	booking_confirmation_id?: string
	hotel_contact?: IContact
	booking_method?: { name: string }
	change_reason?: { name: string }
	drop_reason?: { name: string }
	comments?: string
	instalments?: Array<{ due_at: Date; amount: number }>
	cancellation_charges?: number
}

const validate = validateFormValues(
	Validator.object().shape({
		nextStage: Validator.string().required("Please select a status"),
		booking_confirmation_id: Validator.string(),
		booking_method: Validator.object().when(
			"nextStage",
			(
				nextStage: IBooking["booking_stage"]["state"],
				schema: Validator.AnyObjectSchema
			) =>
				nextStage === "booked"
					? schema.required("Please select a method of booking")
					: schema
		),
		change_reason: Validator.object().when(
			"nextStage",
			(
				nextStage: IBooking["booking_stage"]["state"],
				schema: Validator.AnyObjectSchema
			) =>
				nextStage === "changed"
					? schema.required("Please select a reason for hotel change")
					: schema
		),
		drop_reason: Validator.object().when(
			"nextStage",
			(
				nextStage: IBooking["booking_stage"]["state"],
				schema: Validator.AnyObjectSchema
			) =>
				nextStage === "dropped"
					? schema.required("Please select a reason for hotel drop")
					: schema
		),
		instalments: Validator.array()
			.nullable()
			.of(
				Validator.object().shape({
					amount: EmptyNumberValidator().required(
						"Please provide the amount for instalment"
					),
					due_at: Validator.date().required(
						"Please provide a due date for instalment"
					),
				})
			),
		comments: Validator.string(),
		cancellation_charges: EmptyNumberValidator()
			.nullable()
			.min(0, "Please provide a non-negative value for charge."),
	})
)

function HotelBookingStageChangeForm({
	booking: minimalBooking,
	onChangeSuccess,
	tripStartDate: startDate,
	tripEndDate: endDate,
	onSubmit,
	onCancel,
	tripDestinations,
}: {
	booking: IBooking
	onChangeSuccess?: () => void
	tripStartDate: Date
	tripEndDate: Date
	tripDestinations: Array<TTripDestination>
	onSubmit: (data: {
		booking_confirmation_id?: string | null
		comments?: string | null
		tag?: string
		stage: string
		booking_method?: string | null
		change_reason?: string | null
		drop_reason?: string | null
		instalments?: null | Array<{ due_at: string; amount: number }>
		hotel_contact?: number
		replacementBookingDetails?: null | {
			details: Array<unknown>
			extras: Array<unknown>
		}
		cancellation_charges?: number
	}) => Promise<IBooking>
	onCancel?: () => void
}) {
	const { booking_stage } = minimalBooking
	const { state } = booking_stage
	const nextStages = getNextStages(state)
	const [refreshId, setRefreshId] = useState(0)
	const [replacementBookingDetails, setReplacementBookingDetails] = useState<
		| {
				dataToSave: Parameters<
					React.ComponentProps<typeof AddReplacementHotelForm>["onSubmit"]
				>[0]
				details: {
					details: IQuoteHotelParams
					extras: IQuoteHotelExtraParams
				}
		  }
		| undefined
	>(undefined)

	const xhr = useXHR()
	const {
		data: booking,
		error,
		mutate,
	} = useSWR<IBooking>(`/hotel-bookings/${minimalBooking.id}`, () =>
		xhr
			.get(`/hotel-bookings/${minimalBooking.id}`)
			.then((resp) => resp.data.data)
	)
	const initialValues: IBookingStageChangeData = useMemo(() => {
		return {
			nextStage: undefined,
			tag: undefined,
			comments: "",
			booking_confirmation_id: undefined,
			booking_method: undefined,
			change_reason: undefined,
			drop_reason: undefined,
			cancellation_charges: undefined,
		}
	}, [])
	if (error && !booking) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!booking) {
		return <Spinner alignCenter padding="4" />
	}
	const { currency, paid_payments_amount, booked_price } = booking
	return (
		<Form<IBookingStageChangeData>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async (values) => {
				if (!values.nextStage) {
					throw new Error("Please select a next stage")
				}
				const {
					nextStage,
					booking_method,
					change_reason,
					drop_reason,
					tag,
					instalments,
					cancellation_charges,
					hotel_contact,
					...otherData
				} = values
				await onSubmit({
					...otherData,
					tag: tag ? tag.name : undefined,
					stage: nextStage,
					booking_method: booking_method ? booking_method.name : null,
					change_reason: change_reason ? change_reason.name : null,
					drop_reason: drop_reason ? drop_reason.name : null,
					hotel_contact: hotel_contact?.id,
					instalments: instalments
						? instalments.map((d) => ({
								amount: d.amount,
								due_at: dateToUTCString(endOf(d.due_at, "day")),
								due_at_local: formatDate(d.due_at, "YYYY-MM-DD"),
							}))
						: null,
					replacementBookingDetails: replacementBookingDetails
						? {
								details: replacementBookingDetails.dataToSave.hotels.map(
									(data) => ({
										...data,
										trip_id: booking.trip_id,
									})
								),
								extras: replacementBookingDetails.dataToSave.extras.map(
									(data) => ({
										...data,
										trip_id: booking.trip_id,
									})
								),
							}
						: null,
					cancellation_charges: Number(cancellation_charges || 0),
				})
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<GetFieldValue<IBookingStageChangeData["nextStage"]> name="nextStage">
						{({ value: nextStage }) => (
							<Dialog.Body>
								<Stack gap="4">
									<SubmissionError />
									<Grid gap="4">
										<Col sm="auto" xs={12}>
											<SelectInputField name="nextStage" label="Select Status">
												<option value="">Select status...</option>
												{nextStages.map((state) => (
													<option
														key={state}
														title={getDescriptionForStage(state)}
														value={state}
													>
														{getDisplayNameForStage(state)}
													</option>
												))}
											</SelectInputField>
										</Col>
										<Col key={nextStage}>
											{nextStage === "in_progress" || nextStage === "booked" ? (
												<SelectField
													stage={
														nextStage === "booked" ? "booked" : "in_progress"
													}
													label="Tag"
													select={SelectHotelBookingStageTags}
													name="tag"
													searchable={false}
												/>
											) : nextStage === "dropped" ? (
												<SelectField
													name="drop_reason"
													select={SelectHotelBookingDropReason}
													label="Select Dropping Reason"
												/>
											) : nextStage === "changed" ? (
												<SelectField
													name="change_reason"
													select={SelectHotelBookingChangeReason}
													label="Select a reason for Change"
												/>
											) : null}
										</Col>
									</Grid>
									{nextStage === "dropped" ? (
										<Box>
											<TextInputField
												name="cancellation_charges"
												label="Drop Charges"
												secondaryLabel={currency}
												type="number"
												placeholder="e.g. 0.00"
												maxWidth="xs"
												help={({ value }) => {
													const receivable =
														(paid_payments_amount || 0) - Number(value)
													return (
														<Text fontSize="base" color="default">
															{joinAttributes(
																<>
																	Booking:{" "}
																	<Money
																		amount={booked_price || 0}
																		currency={currency}
																	/>
																</>,
																<>
																	Paid:{" "}
																	<Money
																		amount={paid_payments_amount || 0}
																		currency={currency}
																	/>
																</>,
																<Text
																	as="span"
																	color={receivable > 0 ? "success" : "danger"}
																>
																	{receivable > 0
																		? "Receivable"
																		: "Payable Due"}
																	:{" "}
																	<Money
																		amount={Math.abs(receivable)}
																		currency={currency}
																	/>
																</Text>
															)}
														</Text>
													)
												}}
											/>
										</Box>
									) : null}
									<Divider sm />
									{nextStage === "booked" ? (
										<Stack gap="8">
											<Stack gap="4">
												<Alert status="warning">
													<Text>
														Please verify following booking details once again.
														These details <strong>CAN NOT BE</strong> modified
														after booking.
													</Text>
												</Alert>
												<Box
													rounded="lg"
													bgColor="primary"
													borderWidth="1"
													borderColor="primary"
													padding="4"
												>
													<Stack gap="8">
														<BookingOverview booking={booking} />
														<Grid gap="4">
															<Col xs={12} sm={6}>
																<TitleValue title="Booked Price">
																	<Stack gap="2">
																		<Text fontSize="xl" fontWeight="semibold">
																			<Money
																				amount={booking.booked_price || 0}
																				currency={booking.currency}
																				showCurrency
																			/>
																		</Text>
																		<Text fontSize="sm">
																			This price should match to the booking
																			price that is confirmed with the hotel.
																		</Text>
																	</Stack>
																</TitleValue>
															</Col>
															<Col>
																<HotelPaymentPreferences
																	hotel={booking.hotel}
																	onChange={() => {
																		mutate()
																		setRefreshId((id) => id + 1)
																	}}
																/>
															</Col>
														</Grid>
													</Stack>
													<Divider sm />
													<Box>
														<EditHotelBookingDetailsInDialog
															tripDestinations={tripDestinations}
															booking={booking}
															startDate={startDate}
															endDate={endDate}
															level="primary"
															onSuccess={() => {
																onChangeSuccess && onChangeSuccess()
																mutate()
																setRefreshId((id) => id + 1)
															}}
														/>{" "}
														if above information is not correct.
													</Box>
												</Box>
											</Stack>
											<Text color="muted">
												If above details are correct, please proceed to fill
												following fields.
											</Text>
											<Grid gap="4">
												<Col xs={12} sm={6}>
													<SelectField
														name="booking_method"
														select={SelectHotelBookingMethod}
														label="Select Booking Method"
													/>
												</Col>
												<Col xs={12} sm="auto">
													<TextInputField
														label="Booking Confirmation Number"
														secondaryLabel="optional"
														name="booking_confirmation_id"
														type="text"
														placeholder="BKN123F1"
														help="Confirmation/Reference number by hotels if provided"
													/>
												</Col>
												<Col xs={12}>
													<SelectField
														label="Booking Confirmed by Hotel's Contact Person"
														secondaryLabel="optional"
														select={SelectHotelContact}
														name="hotel_contact"
														hotelId={booking.hotel_id}
														creatable
														fetchOnMount
														help="Contact details will be included in the Voucher PDF"
													/>
												</Col>
											</Grid>
											<InstalmentsData
												bookingId={booking.id}
												key={refreshId}
												checkin={booking.checkin}
												checkout={booking.checkout}
											/>
										</Stack>
									) : nextStage === "changed" || nextStage === "dropped" ? (
										<Component initialState={false}>
											{({ state, setState }) => (
												<Stack gap="4">
													{replacementBookingDetails ? (
														<Stack
															gap="2"
															padding="4"
															borderWidth="1"
															borderColor="success"
															rounded="md"
															bgColor="success"
														>
															<Text color="success" fontWeight="semibold">
																Replacement Added
															</Text>
															{replacementBookingDetails.details.details
																.length ? (
																<Table
																	headers={[
																		"Name",
																		"Night",
																		"Accommodation",
																		"Price",
																	]}
																	responsive
																	bordered
																	rows={normalizeHotels(
																		replacementBookingDetails.details.details
																	).map((d) => [
																		<Stack gap="px">
																			<Text>{d.hotel.name}</Text>
																			<Text fontSize="sm">
																				{joinAttributes(
																					d.hotel.address?.location?.name,
																					d.hotel.stars_string
																				)}
																			</Text>
																		</Stack>,
																		`${withOrdinalSuffix(
																			getDiff(d.date, startDate, "days") + 1
																		)} - ${d.meal_plan.name}`,
																		<Stack gap="px">
																			<Text>
																				{d.no_of_rooms} {d.room_type.name}
																			</Text>
																			<Text fontSize="sm">
																				{joinAttributes(
																					`${
																						d.persons_per_room * d.no_of_rooms
																					} Pax`,
																					[
																						d.adults_with_extra_bed,
																						`${d.adults_with_extra_bed} AwEb`,
																					],
																					[
																						d.children_with_extra_bed,
																						`${d.children_with_extra_bed} CwEb`,
																					],
																					[
																						d.children_without_extra_bed,
																						`${d.children_without_extra_bed} CNB`,
																					]
																				)}
																			</Text>
																		</Stack>,
																		numberToLocalString(d.booked_price),
																	])}
																/>
															) : null}
															{replacementBookingDetails.details.extras
																.length ? (
																<Table
																	headers={[
																		"Night",
																		"Name",
																		"Service",
																		"Price",
																	]}
																	responsive
																	bordered
																	rows={normalizeHotelExtras(
																		replacementBookingDetails.details.extras
																	).map((d) => [
																		d.date
																			? withOrdinalSuffix(
																					getDiff(d.date, startDate, "days") + 1
																				)
																			: null,
																		d.hotel.name,
																		d.service.name,
																		numberToLocalString(d.booked_price),
																	])}
																/>
															) : null}
															<Box>
																<Button
																	onClick={() => {
																		setState(true)
																		setTimeout(() => {
																			setReplacementBookingDetails(undefined)
																		}, 300)
																	}}
																	size="sm"
																>
																	Change Replacement
																</Button>
															</Box>
														</Stack>
													) : (
														<Box>
															<Button
																level="primary"
																onClick={() => {
																	setState(true)
																}}
															>
																Add Replacement
															</Button>
														</Box>
													)}
													<Dialog
														open={state}
														onClose={() => setState(false)}
														fitContainer
														title={`Add Replacement for ${booking.hotel.name}`}
													>
														<Dialog.Body>
															<Stack gap="4">
																<Box
																	borderWidth="1"
																	borderColor="primary"
																	bgColor="primary"
																	padding="4"
																	rounded="md"
																>
																	<Stack gap="4">
																		<Text
																			fontWeight="semibold"
																			color="warning"
																			fontSize="md"
																		>
																			Here are the hotel details which is to be
																			replaced.
																		</Text>
																		<BookingOverview
																			booking={booking}
																			nightsRelativeTo={startDate}
																		/>
																	</Stack>
																</Box>
																<DeferRender>
																	<AddReplacementHotelForm
																		tripDestinations={tripDestinations}
																		startDate={startDate}
																		endDate={endDate}
																		booking={booking}
																		onCancel={() => setState(false)}
																		onSubmit={async (data, _pax, details) => {
																			setReplacementBookingDetails({
																				dataToSave: data,
																				details,
																			})
																			setState(false)
																		}}
																	/>
																</DeferRender>
															</Stack>
														</Dialog.Body>
													</Dialog>
												</Stack>
											)}
										</Component>
									) : null}
									<TextInputField
										label="Any Comments"
										secondaryLabel="optional"
										name="comments"
										type="text"
									/>
								</Stack>
							</Dialog.Body>
						)}
					</GetFieldValue>
					<Dialog.Footer>
						<Button type="submit" disabled={submitting}>
							{submitting ? "Updating..." : "Update"}
						</Button>
						<Button onClick={onCancel} disabled={submitting}>
							Cancel
						</Button>
					</Dialog.Footer>
				</form>
			)}
		</Form>
	)
}

function InstalmentsData({
	bookingId,
	checkin,
	checkout,
}: {
	bookingId: number | string
	checkin: string
	checkout: string
}) {
	const id = useId()
	const xhr = useXHR()
	const { data: instalmentPresets } = useSWR(
		`/hotel-bookings/${bookingId}/instalment-presets?${id}`,
		() => XHR(xhr).getInstalments(bookingId),
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
		}
	)
	const { value, onChange } = useFieldValue("instalments")
	const hasSetValue = useRef(false)
	useEffect(() => {
		if (instalmentPresets && !hasSetValue.current) {
			const fieldData = (instalmentPresets?.data || []).map(
				({ amount, due_at }) => ({
					amount: parseFloat(amount.toFixed(2)),
					due_at: endOf(utcTimestampToLocalDate(due_at), "day"),
					percentage: parseFloat(
						((amount * 100) / (instalmentPresets?.meta?.total || 1)).toFixed(1)
					),
				})
			)
			onChange(fieldData)
			hasSetValue.current = true
		}
	}, [instalmentPresets, onChange])
	if (!value || !hasSetValue.current) {
		return <Box>Loading instalments</Box>
	}
	return (
		<Stack gap="2">
			<Inline gap="2" flexWrap="wrap">
				<Text fontWeight="semibold" fontSize="md">
					Payment Instalments
				</Text>
				<Inline gap="2" flexWrap="wrap" color="warning">
					<Text>
						(
						<Text as="span" fontWeight="semibold">
							Checkin:
						</Text>{" "}
						<Time timestamp={checkin} format="ddd Do MMM, YYYY" />,
					</Text>
					<Text>
						<Text as="span" fontWeight="semibold">
							Checkout:
						</Text>{" "}
						<Time timestamp={checkout} format="ddd Do MMM, YYYY" />)
					</Text>
				</Inline>
			</Inline>
			<InstalmentsInputField
				name="instalments"
				totalAmount={Number(instalmentPresets?.meta?.total || 1)}
				currency={instalmentPresets?.meta?.currency || "INR"}
			/>
		</Stack>
	)
}

function BookingOverview({
	booking,
	nightsRelativeTo: propNightsRelativeTo,
}: {
	booking: IBooking
	nightsRelativeTo?: Date
}) {
	const nightsRelativeTo: Date =
		propNightsRelativeTo ||
		localOrUtcTimestampToLocalDate(booking.checkin_local, booking.checkin)
	return (
		<Stack gap="4">
			<Inline gap="8" flexWrap="wrap">
				<TitleValue title="Hotel">
					<Text fontWeight="semibold">{booking.hotel.name}</Text>
					<Text fontSize="sm">
						{joinAttributes(
							booking.hotel.address?.location?.name,
							booking.hotel.stars_string
						)}
					</Text>
				</TitleValue>
				<TitleValue title="Checkin">
					<Time
						timestamp={booking.checkin}
						localTimestamp={booking.checkin_local}
					/>
				</TitleValue>
				<TitleValue title="Checkout">
					<Time
						timestamp={booking.checkout}
						localTimestamp={booking.checkout_local}
					/>{" "}
					({booking.no_of_nights}N)
				</TitleValue>
			</Inline>
			<Stack gap="8">
				{booking.details && booking.details.length ? (
					<Table
						headers={["Nights", "Accommodation", `Price (${booking.currency})`]}
						responsive
						bordered
						rows={booking.details.map((bookingDetail) => {
							const {
								meal_plan,
								room_type,
								no_of_rooms,
								adults_with_extra_bed,
								children_with_extra_bed,
								children_without_extra_bed,
								per_room_booked_price,
								per_adult_with_extra_bed_booked_price,
								per_child_with_extra_bed_booked_price,
								per_child_without_extra_bed_booked_price,
								room_configuration,
								booked_price,
							} = bookingDetail
							const night =
								getDiff(
									localOrUtcTimestampToLocalDate(
										bookingDetail.date_local,
										bookingDetail.date
									),
									nightsRelativeTo,
									"days"
								) + 1
							return [
								<Stack key={`${bookingDetail.id}`} gap="1">
									<Text>{withOrdinalSuffix(night)} Night</Text>
									<Text>{meal_plan.name}</Text>
								</Stack>,
								<Box display="inline">
									{joinAttributes(
										<Box display="inline">
											<Text>
												{no_of_rooms} {room_type.name}
											</Text>
											<Text fontSize="sm" whiteSpace="preserveLine">
												(
												{room_configuration?.description?.replace(
													/\+/g,
													"\n+ "
												)}
												)
											</Text>
										</Box>
									)}
								</Box>,
								<Box>
									<Stack>
										{per_room_booked_price ? (
											<Stack>
												<Inline>
													<Text>
														{numberToLocalString(per_room_booked_price)}
													</Text>
													<Box paddingX="1">/-</Box>
													<Text>
														Per Room {room_configuration?.per_room_details}
													</Text>
												</Inline>
												{adults_with_extra_bed ? (
													<Inline>
														<Text>
															{numberToLocalString(
																per_adult_with_extra_bed_booked_price
															)}
														</Text>
														<Box paddingX="1">/-</Box>
														<Text>
															Per Adult with Extra Bed{" "}
															{
																room_configuration?.per_adult_with_extra_bed_details
															}
														</Text>
													</Inline>
												) : null}
												{children_with_extra_bed ? (
													<Inline>
														<Text>
															{numberToLocalString(
																per_child_with_extra_bed_booked_price
															)}
														</Text>
														<Box paddingX="1">/-</Box>
														<Text>
															Per Child with Extra Bed{" "}
															{
																room_configuration?.per_child_with_extra_bed_details
															}
														</Text>
													</Inline>
												) : null}
												{children_without_extra_bed ? (
													<Inline>
														<Text>
															{numberToLocalString(
																per_child_without_extra_bed_booked_price
															)}
														</Text>
														<Box paddingX="1">/-</Box>
														<Text>
															Per Child without Extra Bed{" "}
															{
																room_configuration?.per_child_without_extra_bed_details
															}
														</Text>
													</Inline>
												) : null}
											</Stack>
										) : (
											<Box>{booked_price}</Box>
										)}
									</Stack>
								</Box>,
							]
						})}
					/>
				) : null}
				{booking.extras && booking.extras.length ? (
					<TitleValue title="Extra Services">
						<Box>
							{booking.extras.map((extra) => {
								const { date, date_local, service } = extra
								const night = date
									? getDiff(
											localOrUtcTimestampToLocalDate(date_local, date),
											localOrUtcTimestampToLocalDate(
												booking.checkin_local,
												booking.checkin
											),
											"days"
										) + 1
									: undefined
								return (
									<Box key={`${extra.id}-${night}`}>
										{night ? (
											<Box display="inline">
												{withOrdinalSuffix(night)} Night
												{" - "}
											</Box>
										) : null}
										<Box display="inline">{joinAttributes(service.name)}</Box>
									</Box>
								)
							})}
						</Box>
					</TitleValue>
				) : null}
			</Stack>
		</Stack>
	)
}

function HotelPaymentPreferences({
	hotel,
	onChange,
}: {
	hotel: IBooking["hotel"]
	onChange: () => void
}) {
	const xhr = useXHR()
	const [isDialogOpen, openDialog, closeDialog] = useDialog()
	return (
		<TitleValue title="Payment Preference">
			{hotel.payment_preference ? (
				<Stack gap="2">
					<Inline gap="4">
						<Text fontSize="md" fontWeight="semibold">
							{hotel.payment_preference?.name}
						</Text>
						<Box>
							<Button onClick={openDialog} size="sm" level="tertiary">
								<Icons.Pencil />
							</Button>
						</Box>
					</Inline>
				</Stack>
			) : (
				<Stack gap="2">
					<Text fontSize="md" color="muted" fontWeight="semibold">
						Not Set
					</Text>
					<Text>
						You should set payment preference for the hotel to easily create
						instalment accordingly.
					</Text>
					<Box>
						<Button onClick={openDialog} size="sm" level="primary">
							Set Payment Preference
						</Button>
					</Box>
				</Stack>
			)}
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				title="Set Payment Preference"
				sm
			>
				<Form<{
					payment_preference: IBooking["hotel"]["payment_preference"]
				}>
					initialValues={{
						payment_preference: hotel.payment_preference,
					}}
					onSubmit={withServerErrors(async (values) => {
						await xhr.patch(`/hotels/${hotel.id}/payment-preference`, {
							payment_preference_id: (
								values.payment_preference as unknown as {
									id: string
								}
							)?.id,
						})
						closeDialog()
						onChange()
					})}
					subscription={{ submitting: true }}
				>
					{({ submitting, handleSubmit }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Dialog.Body>
								<Stack gap="4">
									<Box>
										<SelectField
											name="payment_preference"
											select={SelectHotelPaymentPreferences}
											label="Select Payment Preference"
											fetchOnMount
											multiple={false}
										/>
									</Box>
									<SubmissionError />
								</Stack>
							</Dialog.Body>
							<Dialog.Footer>
								<Button type="submit" disabled={submitting}>
									{submitting ? "Updating..." : "Update"}
								</Button>
								<Button disabled={submitting} onClick={closeDialog}>
									Cancel
								</Button>
							</Dialog.Footer>
						</form>
					)}
				</Form>
			</Dialog>
		</TitleValue>
	)
}

function TitleValue({
	title,
	children,
}: {
	title: React.ReactNode
	children: React.ReactNode
}) {
	return (
		<Stack gap="1">
			<Text
				textTransform="uppercase"
				letterSpacing="wider"
				fontSize="sm"
				fontWeight="semibold"
				color="muted"
			>
				{title}
			</Text>
			<Box fontSize="md">{children}</Box>
		</Stack>
	)
}

export function SelectHotelBookingMethod(
	props: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			cacheKey="hotel-booking-methods"
			fetchOnMount
			creatable
			createOptionLabel={(q) => `Add "${q}"`}
			fetch={(q) => XHR(xhr).getBookingMethods({ q })}
		/>
	)
}

export function SelectHotelBookingChangeReason(
	props: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			cacheKey="hotel-booking-change-reasons"
			fetchOnMount
			creatable
			createOptionLabel={(q) => `Add "${q}"`}
			fetch={(q) => XHR(xhr).getBookingChangeReasons({ q })}
		/>
	)
}

export function SelectHotelBookingDropReason(
	props: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			cacheKey="hotel-booking-drop-reasons"
			fetchOnMount
			creatable
			createOptionLabel={(q) => `Add "${q}"`}
			fetch={(q) => XHR(xhr).getBookingDropReasons({ q })}
		/>
	)
}

export function SelectHotelBookingStageTags({
	stage,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	stage?: keyof typeof stageToDisplayNameMapping
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			cacheKey={`hotel-booking-stage-${stage || "all"}-tags`}
			fetchOnMount
			{...props}
			fetch={(q) => XHR(xhr).getBookingStageTags({ q, stage })}
		/>
	)
}

export function UpdateBookingTagInDialog({
	booking,
	children,
	onSuccess,
}: {
	booking: IBooking
	children: (props: { onEdit: () => void }) => React.ReactNode
	onSuccess: () => void
}) {
	const [isOpen, open, close] = useDialog()
	const initialValues: {
		tag?: { id: number; name: string }
		comments: string
	} = useMemo(() => {
		return {
			tag: undefined,
			comments: "",
		}
	}, [])
	const xhr = useXHR()
	if (
		(booking.booking_stage.state !== "in_progress" &&
			booking.booking_stage.state !== "booked") ||
		!booking.can_modify
	)
		return null
	return (
		<>
			{children({ onEdit: open })}
			<Dialog open={isOpen} onClose={close} sm title="Edit Tag/Comments">
				<Dialog.Body>
					<Form<typeof initialValues>
						initialValues={initialValues}
						onSubmit={withServerErrors(async (values) => {
							await xhr.patch(`/hotel-bookings/${booking.id}/tags`, {
								tag: values.tag?.name,
								comments: values.comments,
							})
							close()
							onSuccess()
						})}
						subscription={{ submitting: true }}
					>
						{({ submitting, handleSubmit }) => (
							<form noValidate onSubmit={handleSubmit}>
								<Stack gap="4">
									<SubmissionError />
									<Box>
										<SelectField
											label="Select Tag"
											name="tag"
											select={SelectHotelBookingStageTags}
											stage={booking.booking_stage.state}
											secondaryLabel="optional"
											searchable={false}
										/>
									</Box>
									<TextAreaInputField
										name="comments"
										label="Any Comments"
										secondaryLabel="optional"
										placeholder="Provide any additional comments if necessary"
									/>
								</Stack>
								<Divider sm />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Please wait..." : "Save"}
									</Button>
									<Button onClick={() => close()} disabled={submitting}>
										Cancel
									</Button>
								</Inline>
							</form>
						)}
					</Form>
				</Dialog.Body>
			</Dialog>
		</>
	)
}
