import {
	Box,
	Button,
	Icons,
	CheckboxInput,
	SelectInput,
	Inline,
	Dropdown,
	Stack,
	Text,
	Select,
} from "@sembark-travel/ui/base"
import {
	Search,
	areAdvancedFiltersAppliedDefault,
	useSearch,
} from "@sembark-travel/ui/list"
import { ButtonLink, useLocationQuery } from "@sembark-travel/ui/router"
import { addUnit } from "@sembark-travel/datetime-utils"
import {
	SelectField,
	DatePickerField,
	SwitchInputField,
} from "@sembark-travel/ui/form"
import pluralize from "pluralize"
import { Fragment, useEffect } from "react"
import { SelectCabTypes } from "../CabTypes"
import { generatePath } from "../router-utils"
import Storage from "../storage"
import { SelectTransporServiceProviders } from "../TransportServiceProviders"
import { SelectTransportServiceLocationPoints } from "../TransportServices"
import { SelectTripDestination } from "../TripDestinations"
import { SchedulesCalendar } from "./SchedulesCalendar"
import { SelectUsers } from "../Users"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import {
	ICabSchedulesFilters,
	ICabSchedulesFiltersInLocationQuery,
	cabScheduleFilterParamsToLocationQuery,
	cabSchedulesLocationQueryToParams,
	BOOKING_STATUS,
	useCalendarUIPreferences,
	TransitionWindowOptions,
} from "./utils"
import { useTripQuoteBookingsDiffCount } from "../TripQuoteBookingsDiff"
import { SelectDriver } from "../Drivers"

const CAB_SCHEDULE_HIDE_OLD_TRIPS_KEY = "cs_hot"
function storeHideOldTripsToStorage(value: boolean) {
	Storage.put(CAB_SCHEDULE_HIDE_OLD_TRIPS_KEY, Number(value))
}

function getHideOldTripsFromStorage(): boolean {
	return Boolean(Number(Storage.get(CAB_SCHEDULE_HIDE_OLD_TRIPS_KEY) || 0))
}

export function SchedulesCalendarView() {
	const {
		calendarUIPreferences,
		toggleCompactFit,
		changeTransitionWindowSize,
		toggleOperationsTeamVisibility,
	} = useCalendarUIPreferences()
	const [queryFilters, setQueryFilters] = useLocationQuery<
		ICabSchedulesFilters,
		ICabSchedulesFiltersInLocationQuery
	>({
		toQuery: cabScheduleFilterParamsToLocationQuery,
		fromQuery: cabSchedulesLocationQueryToParams,
	})
	const [params, setParams] = useSearch<ICabSchedulesFilters>({
		q: "",
		...queryFilters,
		date: queryFilters.date || new Date(),
		hide_past_trips: getHideOldTripsFromStorage(),
	})
	useEffect(() => {
		storeHideOldTripsToStorage(params.hide_past_trips || false)
		setQueryFilters(params)
	}, [params, setQueryFilters])
	const { hasPermission } = useCheckPermissions()
	const canViewOpsTeam = hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS)
	const tripQuoteBookingsDiffCount = useTripQuoteBookingsDiffCount({
		cabs_changed: true,
	})
	return (
		<Fragment>
			<Search
				title="Cab Schedules"
				initialParams={params}
				placeholder="Search by id, destination, guest..."
				Filters={CabScheduleFilters}
				areAdvancedFiltersApplied={(filters) => {
					const { date, hide_past_trips, ...otherFilters } = filters
					return areAdvancedFiltersAppliedDefault(otherFilters)
				}}
				onSearch={setParams}
				resetParams={(params) => ({
					q: "",
					date: params.date,
				})}
				actions={({ setSearchParamValue, searchParams: params }) => (
					<Box>
						<Inline gap="1">
							<NavigationButton
								days={-calendarUIPreferences.transitionWindowSize}
								onClick={(days) => {
									setSearchParamValue("date", addUnit(params.date, days, "day"))
								}}
							/>
							<NavigationButton
								days={calendarUIPreferences.transitionWindowSize}
								onClick={(days) => {
									setSearchParamValue("date", addUnit(params.date, days, "day"))
								}}
							/>

							<ButtonLink to={generatePath("/cab-schedules")} level="primary">
								<Icons.ViewList /> List View
							</ButtonLink>
							{tripQuoteBookingsDiffCount ? (
								<ButtonLink
									to={generatePath("/trip-quote-bookings-diff")}
									status="warning"
									title="Trip Quote & Cab Bookings Diff"
								>
									<Inline alignItems="center" as="span" gap="1">
										<Box as="span">
											<Icons.Attention />
										</Box>
										<Text as="span">{tripQuoteBookingsDiffCount}</Text>
									</Inline>
								</ButtonLink>
							) : null}
							<Dropdown alignRight="sm" display="inlineBlock">
								<Dropdown.ToggleButton>
									<Icons.Cog />
								</Dropdown.ToggleButton>
								<Dropdown.Menu>
									<li>
										<Box
											as="label"
											cursor="pointer"
											paddingX="3"
											paddingY="2"
											display="block"
											fontWeight="normal"
										>
											<Box display="flex" alignItems="center">
												<CheckboxInput
													id="toggle_compact_fit"
													checked={calendarUIPreferences.hasCompactFit}
													onChange={() => {
														toggleCompactFit()
													}}
												/>
												<Box paddingLeft="2">
													<Box>Use Compact Fit</Box>
													<Box fontSize="sm" color="muted">
														Move items upwards when space is available.
													</Box>
												</Box>
											</Box>
										</Box>
									</li>
									{canViewOpsTeam ? (
										<li>
											<Box
												as="label"
												cursor="pointer"
												paddingX="3"
												paddingY="2"
												display="block"
												fontWeight="normal"
											>
												<Box display="flex" alignItems="center">
													<CheckboxInput
														id="toggle_operations_team_visibility"
														checked={calendarUIPreferences.showOperationsTeam}
														onChange={() => {
															toggleOperationsTeamVisibility()
														}}
													/>
													<Box paddingLeft="2">
														<Box>Show/Hide Operations Team</Box>
													</Box>
												</Box>
											</Box>
										</li>
									) : null}
									<li>
										<Box
											as="label"
											cursor="pointer"
											paddingX="3"
											paddingY="2"
											display="block"
											fontWeight="normal"
										>
											<Box display="flex" alignItems="center">
												<SelectInput
													id="change_transition_window_size"
													value={calendarUIPreferences.transitionWindowSize}
													onChange={(e) => {
														changeTransitionWindowSize(e.currentTarget.value)
													}}
												>
													{TransitionWindowOptions.map((option) => (
														<option key={option} value={option}>
															{option} {pluralize("day", option)}
														</option>
													))}
												</SelectInput>
												<Box paddingLeft="2">
													<Box>Transition Window Size</Box>
													<Box fontSize="sm" color="muted">
														Days to skip on next/previous
													</Box>
												</Box>
											</Box>
										</Box>
									</li>
								</Dropdown.Menu>
							</Dropdown>
						</Inline>
					</Box>
				)}
			>
				{({ setSearchParams }) => (
					<SchedulesCalendar
						params={params}
						onChangeParams={setSearchParams}
						date={params.date}
						{...calendarUIPreferences}
					/>
				)}
			</Search>
		</Fragment>
	)
}

export function CabScheduleFilters() {
	return (
		<Stack gap="4">
			<DatePickerField label="Select A Date" name="date" clearable={false} />
			<SelectField
				select={SelectTripDestination}
				name="trip_destinations"
				multiple
				label="Trip Destinations"
				fetchOnMount
			/>
			<SelectField
				select={SelectTransporServiceProviders}
				name="transport_service_providers"
				multiple
				label="Service Providers"
				fetchOnMount
			/>
			<SelectField
				select={SelectDriver}
				name="drivers"
				multiple
				label="Drivers"
			/>
			<SelectField
				select={SelectTransportServiceLocationPoints}
				name="transport_service_location_points"
				label="Service Cities"
				multiple
			/>
			<SelectField
				select={SelectCabTypes}
				name="cab_types"
				label="Cab Types"
				multiple
				fetchOnMount
			/>
			<SelectField
				label="Team"
				select={SelectUsers}
				name="owners"
				multiple
				fetchOnMount
			/>
			<SelectField
				select={Select}
				name="status"
				label="Booking Status"
				options={BOOKING_STATUS}
			/>
			<SwitchInputField name="hide_past_trips" label="Hide Old Trips" />
		</Stack>
	)
}

function NavigationButton({
	onClick,
	days,
}: {
	onClick: (days: number) => void
	days: number
}) {
	const prev = days < 0
	const absDays = Math.abs(days)
	return (
		<Button
			type="button"
			onClick={() => onClick(days)}
			data-testid={prev ? "prev-view" : "next-view"}
		>
			{!prev ? (
				<Box as="sup" color="muted">
					{absDays}
				</Box>
			) : null}
			<Icons.ChevronDown rotate={prev ? "90" : "270"} />
			{prev ? (
				<Box as="sup" color="muted">
					{absDays}
				</Box>
			) : null}
		</Button>
	)
}
