import {
	Alert,
	Box,
	Icons,
	Stack,
	Text,
	joinAttributes,
	Heading,
	Spinner,
	Tip,
} from "@sembark-travel/ui/base"
import { useSearch, Search } from "@sembark-travel/ui/list"
import { ButtonLink, Link, queryToSearch } from "@sembark-travel/ui/router"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import useSWR from "swr"
import { ILocation } from "../Locations"
import { generatePath } from "../router-utils"
import { ITrip, getBasicInfoForQueryFromTrip } from "./store"

interface IQuote {
	id: number
	trip_id: number
	name: string
	start_date: string
	start_date_local?: string
	days: string
	locations?: string
	price: number
	no_of_adults: number
	no_of_children: number
	stay_nights: Array<{
		location: ILocation
		nights: number
	}>
	inclusions: Array<string>
	hotel_categories: Array<string>
}

export default function MatchingQuotes({ trip }: { trip: ITrip }) {
	const xhr = useXHR()
	const [params, setParams] = useSearch({ q: "" })
	const { data: quotes, error } = useSWR<Array<IQuote>>(
		`/trips/${trip.id}/matching-quotes${queryToSearch(params)}`,
		() =>
			xhr
				.get(`/trips/${trip.id}/matching-quotes`, { params })
				.then((resp) => resp.data.data)
	)
	const isFetching = !quotes && !error
	if (!params.q && !quotes?.length) return null
	return (
		<Stack gap="4">
			<Text fontWeight="semibold" fontSize="lg">
				Here are some quotes to start with.
			</Text>
			<Search
				initialParams={params}
				resetParams={{ q: "" }}
				onSearch={(params) => setParams(params)}
				placeholder="Search by trip id or guest name"
			/>
			{isFetching ? (
				<Spinner padding="4" alignCenter />
			) : quotes?.length ? (
				<Box as="ol" bgColor="default" rounded="md" borderWidth="1">
					{quotes?.map((quote, index) => (
						<Box as="li" key={quote.id} borderBottomWidth="1" padding="4">
							<Heading as="h4" fontSize="md">
								{quote.stay_nights.length ? (
									<Box as="span">
										{quote.stay_nights.map((s, i) => (
											<Box
												key={i}
												display="inlineBlock"
												marginRight="3"
												title={`${pluralize("Night", s.nights, true)} at ${
													s.location.name
												}`}
												as="span"
											>
												{s.location.short_name}
												<Box
													as="sup"
													color="muted"
													letterSpacing="wider"
													marginLeft="1"
												>
													{s.nights}N
												</Box>
											</Box>
										))}
									</Box>
								) : (
									quote.name
								)}
							</Heading>
							<Box display={{ sm: "flex" }} justifyContent="between">
								<Box marginRight={{ sm: "4" }}>
									<Box>
										{joinAttributes(
											<Box display="inline">
												<Icons.Calendar color="muted" /> {quote.days}D
											</Box>,
											<Box display="inline">
												<Icons.Users color="muted" /> {quote.no_of_adults}A
												{quote.no_of_children
													? `, ${quote.no_of_children}C`
													: ``}
											</Box>,
											quote.hotel_categories.length ? (
												<Box
													display="inlineFlex"
													fontSize="sm"
													marginTop="2"
													title="Hotel Categories"
												>
													{quote.hotel_categories.map((star) => (
														<Box key={star} marginRight="2">
															{star}
														</Box>
													))}
												</Box>
											) : null
										)}
									</Box>
									<Box marginTop="1">
										<Link
											fontSize="sm"
											color="muted"
											title="Click to view trip details"
											to={generatePath("/trips/:tripId", {
												tripId: quote.trip_id.toString(),
											})}
										>
											{quote.name}
										</Link>
									</Box>
								</Box>
								<Box marginTop="2">
									<ButtonLink
										to={`${generatePath("/trips/:tripId/new-quote", {
											tripId: trip.id.toString(),
										})}${queryToSearch(
											getBasicInfoForQueryFromTrip(trip, quote.id)
										)}&using=1`}
										data-testid={`use_quote_${index}`}
									>
										Use this
									</ButtonLink>
								</Box>
							</Box>
						</Box>
					))}
				</Box>
			) : error ? (
				<Alert status="error">{error.message}</Alert>
			) : null}
			{!isFetching && quotes?.length ? (
				<Tip>
					Start with one of these matching quotes and edit them as required to
					quickly create a quote.
				</Tip>
			) : null}
		</Stack>
	)
}
