import {
	Alert,
	Box,
	Button,
	Inline,
	Heading,
	Text,
	Stack,
	Grid,
	Col,
	AsyncSelect,
	AsyncSelectProps,
	Divider,
	Icons,
	RelativeTime,
	joinAttributes,
	Time,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { useNavigateToBack, ButtonLink } from "@sembark-travel/ui/router"
import { dateToUTCString, setUnit } from "@sembark-travel/datetime-utils"
import { IListResponse, useXHR } from "@sembark-travel/xhr"
import {
	Form,
	TextAreaInputField,
	SubmissionError,
	validateFormValues,
	SelectField,
	DatePickerField,
	withServerErrors,
	TextInputField,
} from "@sembark-travel/ui/form"
import { useMemo } from "react"
import * as Validator from "yup"
import { generatePath } from "../router-utils"
import { ITrip } from "./store"
import pluralize from "pluralize"

const validationSchema = validateFormValues(
	Validator.object().shape({
		reason: Validator.object().required(
			"Please provide a reason for cancellation"
		),
		contact_remind_at: Validator.date().nullable(),
		comments: Validator.string().nullable(),
	})
)

export default function CancelTrip({
	tripId,
	trip,
}: {
	tripId?: string
	trip: ITrip
}) {
	const navigateToBack = useNavigateToBack()
	const xhr = useXHR()
	const initialValues: {
		reason?: ITripCancellationReason
		contact_remind_at?: Date
		comments?: string
	} = useMemo(() => {
		return {
			reason: undefined,
			contact_remind_at: undefined,
			comments: "",
		}
	}, [])
	if (!tripId) return null
	return (
		<Box>
			<Stack gap="2">
				<Heading as="h2" color="warning">
					Mark Lead/Query as Canceled
				</Heading>
				<Text color="warning">
					Leads/Queries can be cancelled because of reasons like client not
					interested, plan changed or the client is already converted by some
					else.
				</Text>
			</Stack>
			<Divider />
			<Form<typeof initialValues>
				initialValues={initialValues}
				validate={validationSchema}
				onSubmit={withServerErrors(
					async ({ reason, contact_remind_at, ...values }) => {
						if (window.confirm("Are you sure you want to cancel this Trip ?")) {
							await xhr.post(`/canceled-trips/${trip.id}`, {
								...values,
								reason: reason ? reason.name : undefined,
								contact_remind_at: contact_remind_at
									? dateToUTCString(setUnit(contact_remind_at, "hours", 12))
									: undefined,
							})
							showSnackbar("Trip canceled successfully.")
							navigateToBack(
								generatePath("/trips/:tripId", { tripId: trip.id.toString() })
							)
						}
					}
				)}
				subscription={{ submitting: true }}
			>
				{({ submitting, handleSubmit }) => (
					<form noValidate onSubmit={handleSubmit}>
						<Stack gap="4">
							<SubmissionError />
							<Grid gap="4">
								<Col>
									<SelectField
										select={SelectTripCancellationReason}
										name="reason"
										label="Reason for Cancellation"
										required
										fullWidth
										placeholder="e.g. Plan Dropped"
										fetchOnMount
									/>
								</Col>
								<Col>
									<DatePickerField
										name="contact_remind_at"
										label="Contact Reminder On"
										secondaryLabel="optional"
										help="Setup a reminder for contacting incase of postponding or rescheduling"
									/>
								</Col>
							</Grid>
							<TextAreaInputField
								name="comments"
								label="Any Comments"
								secondaryLabel="optional"
								placeholder="Any comments/more information regarding the cancellation"
							/>
							<Divider sm />
							<Inline gap="4">
								<Button type="submit" disabled={submitting} status="warning">
									{submitting ? "Please wait..." : "Mark as Canceled"}
								</Button>
								<ButtonLink
									to={generatePath("/trips/:tripId", { tripId })}
									level="tertiary"
									toBack
								>
									Back
								</ButtonLink>
							</Inline>
						</Stack>
					</form>
				)}
			</Form>
		</Box>
	)
}

export interface ITripCancellationReason {
	id: number
	name: string
	description?: string
}

export function SelectTripCancellationReason({
	forDrop,
	...props
}: Omit<AsyncSelectProps, "fetch"> & {
	forDrop?: boolean
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			creatable
			{...props}
			fetch={(q) =>
				xhr
					.get<IListResponse<ITripCancellationReason>>(
						"/trip-query-cancellation-reasons",
						{
							params: { q, drop: forDrop ? 1 : null },
						}
					)
					.then((resp) => resp.data.data)
			}
		/>
	)
}

export function RevertTripCancellationInDialog({
	trip,
	children,
	onSuccess,
}: {
	trip: ITrip
	children: (props: { onCancel: () => void }) => React.ReactNode
	onSuccess: () => void
}) {
	const [isOpen, open, close] = useDialog()
	const xhr = useXHR()
	if (!trip.cancellation_reason) return null
	const { cancellation_reason } = trip
	const nextStage = trip.latest_given_quote ? "In-Progress" : "New Query"
	return (
		<>
			{children({ onCancel: open })}
			{isOpen ? (
				<Dialog open sm onClose={close} title="Revert Canceled Trip">
					<Form<{ confirmed_trip_id: string }>
						initialValues={{ confirmed_trip_id: "" }}
						validate={validateFormValues(
							Validator.object().shape({
								confirmed_trip_id: Validator.string()
									.equals(
										[trip.id.toString()],
										`Please type "${trip.id}" to confirm the reversal`
									)
									.required("Please type the Trip ID to confirm the reversal"),
							})
						)}
						onSubmit={withServerErrors(async () => {
							await xhr.delete(`/canceled-trips/${trip.id}`)
							close()
							onSuccess()
							showSnackbar(
								`Trip cancellation reversed and moved to "${nextStage}" stage.`
							)
						})}
						subscription={{ submitting: true }}
					>
						{({ submitting, handleSubmit }) => (
							<form noValidate onSubmit={handleSubmit}>
								<Dialog.Body>
									<Stack gap="4">
										<Heading as="h5">
											Please verify trip details before reversal
										</Heading>
										<Stack borderWidth="1" rounded="md" padding="4" gap="6">
											<Inline flexWrap="wrap" gap="6">
												<Stack gap="1">
													<Box
														fontWeight="semibold"
														textTransform="uppercase"
														fontSize="sm"
														color="muted"
													>
														<Icons.LocationMarker /> Destination
													</Box>
													<Box>
														<Text fontWeight="semibold">
															{trip.destinations.map((d) => d.name).join(", ")}
														</Text>
														<Text fontSize="sm">
															{joinAttributes(
																trip.id,
																trip.trip_source.short_name
															)}
														</Text>
													</Box>
												</Stack>
												<Stack gap="1">
													<Box
														fontWeight="semibold"
														textTransform="uppercase"
														fontSize="sm"
														color="muted"
													>
														<Icons.UserGroup /> Guest
													</Box>
													<Box>
														<Text fontWeight="semibold">
															{trip.contact.name}
														</Text>
														<Text fontSize="sm">
															{joinAttributes(
																pluralize("Adult", trip.no_of_adults, true),
																trip.children.length
																	? `${pluralize(
																			"Child",
																			trip.no_of_children,
																			true
																		)}`
																	: ""
															)}
														</Text>
													</Box>
												</Stack>
												<Stack gap="1">
													<Box
														fontWeight="semibold"
														textTransform="uppercase"
														fontSize="sm"
														color="muted"
													>
														<Icons.Calendar /> Dates
													</Box>
													<Box>
														<Box fontWeight="semibold">
															<Time
																timestamp={trip.start_date}
																localTimestamp={trip.start_date_local}
															/>
														</Box>
														<Text fontSize="sm">
															{trip.nights}N, {trip.days}D
														</Text>
													</Box>
												</Stack>
											</Inline>
											<Inline gap="6">
												<Stack gap="1">
													<Box
														fontWeight="semibold"
														textTransform="uppercase"
														fontSize="sm"
														color="muted"
													>
														<Icons.Ban /> Reason
													</Box>
													<Box>
														<Box fontWeight="semibold">
															{cancellation_reason.reason}
														</Box>
														<Text fontSize="sm">
															{cancellation_reason.comments}
														</Text>
													</Box>
												</Stack>
												<Stack gap="1">
													<Box
														fontWeight="semibold"
														textTransform="uppercase"
														fontSize="sm"
														color="muted"
													>
														<Icons.User /> By
													</Box>
													<Box>
														<Text fontWeight="semibold">
															{cancellation_reason.created_by.name}
														</Text>
														<Text fontSize="sm">
															<RelativeTime
																timestamp={cancellation_reason.created_at}
															/>
														</Text>
													</Box>
												</Stack>
											</Inline>
										</Stack>
										<Alert
											status="info"
											title={`Trip will be moved to "${nextStage}" stage`}
										>
											After reversal, you will be able to create quotes, track
											followups and work on this trip.
										</Alert>
										<Divider sm />
										<TextInputField
											label={`Type ${trip.id} to confirm`}
											name="confirmed_trip_id"
											type="number"
											placeholder={`${trip.id}`}
											aria-label="Type in the trip id to confirm that you want revert the cancellation"
											required
										/>
										<SubmissionError />
									</Stack>
								</Dialog.Body>
								<Dialog.Footer>
									<Button disabled={submitting} type="submit">
										{submitting ? (
											"Please wait.."
										) : (
											<>
												<Icons.Ok /> I understand. Move to {nextStage} stage.
											</>
										)}
									</Button>
									<Button onClick={close} disabled={submitting}>
										No
									</Button>
								</Dialog.Footer>
							</form>
						)}
					</Form>
				</Dialog>
			) : null}
		</>
	)
}
