import { Button, Inline, Stack } from "@sembark-travel/ui/base"
import {
	Form,
	TextInputField,
	PhoneInputField,
	validateFormValues,
	SubmissionError,
	PhoneNumberValidator,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { useMemo } from "react"
import * as Validator from "yup"
import { Divider, Col, Grid } from "@sembark-travel/ui/base"
import { IContact } from "./store"

interface AddContactSchema {
	name: string
	email?: string
	phone_numbers?: Array<{ country_code: string; number: string }>
}

const InitialValues: AddContactSchema = {
	name: "",
	email: "",
	phone_numbers: [],
}

interface AddContactFormProps {
	onCreate: (data: AddContactSchema) => Promise<IContact>
	onCancel?: () => void
	initialValues?: AddContactSchema
	phoneNumberRequired?: boolean
	emailRequired?: boolean
}

export function AddContactForm({
	onCreate,
	onCancel,
	phoneNumberRequired,
	emailRequired,
	initialValues = InitialValues,
}: AddContactFormProps) {
	const addContactValidationSchema = useMemo(() => {
		const emailValidator = Validator.string().email(
			"Email should be a valid email address"
		)
		return validateFormValues(
			Validator.object()
				.shape({
					name: Validator.string().required("Contact name is required"),
					email: emailRequired
						? emailValidator.required("Please provide an email address")
						: emailValidator,
					phone_numbers: phoneNumberRequired
						? Validator.array()
								.of(PhoneNumberValidator())
								.min(1, "Please provide atleast one phone number")
						: Validator.array().of(PhoneNumberValidator()),
				})
				.required("Contact data is required")
		)
	}, [phoneNumberRequired, emailRequired])

	return (
		<Form<AddContactSchema>
			initialValues={initialValues}
			validate={addContactValidationSchema}
			onSubmit={withServerErrors(async (values) => {
				await onCreate(values)
				onCancel && onCancel()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<Grid gap="4">
							<Col>
								<TextInputField
									name="name"
									label="Name"
									type="text"
									required
									placeholder="Johhny Dep"
								/>
							</Col>
							<Col>
								<TextInputField
									name="email"
									label="Email"
									type="email"
									placeholder="user@domain.com"
									secondaryLabel={emailRequired ? undefined : "optional"}
								/>
							</Col>
						</Grid>
						<PhoneInputField
							name="phone_numbers"
							label={`Phone Number(s) ${
								!phoneNumberRequired ? ` (optional)` : ""
							}`}
							multi
							required={phoneNumberRequired}
						/>
					</Stack>
					<Divider />
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button disabled={submitting} type="submit">
								{submitting ? "Please wait..." : "Save"}
							</Button>
							{onCancel ? (
								<Button
									onClick={onCancel}
									level="tertiary"
									disabled={submitting}
								>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

export default AddContactForm
