import { Box, Container, Tip } from "@sembark-travel/ui/base"
import { Helmet } from "react-helmet-async"
import { NewMealPlanForm } from "../../../MealPlans"
import { useNavigate, generatePath } from "../../../router-utils"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function NewMealPlanPage() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>New Meal Plan</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.UPLOAD_BULK_HOTELS}>
				<Breadcrumbs
					title="New Meal Plan"
					items={[
						[generatePath("/hotels"), "Hotels"],
						[generatePath("/meal-plans"), "Meal Plans"],
						["", "New"],
					]}
				/>
				<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
					<Box maxWidth="4xl" marginX="auto">
						<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
							<Container paddingY="6">
								<NewMealPlanForm
									onSuccess={() => navigate("/meal-plans")}
									onCancel={() => navigate("/meal-plans")}
								/>
							</Container>
						</Box>
					</Box>
					<Tip>
						Using an existing name will modify the details of that existing meal
						plan.
					</Tip>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
