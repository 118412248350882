import {
	RelativeTime,
	TabContent,
	TabItem,
	Table,
	Tabs,
	TabsList,
	Time,
	Dropdown,
	Icons,
	Badge,
	Text,
	Stack,
	Box,
} from "@sembark-travel/ui/base"
import { IListResponse } from "@sembark-travel/xhr"
import { IComment } from "./store"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"
import {
	ListView,
	Search,
	TSearchParams,
	areAdvancedFiltersAppliedDefault,
	useSearch,
} from "@sembark-travel/ui/list"
import { useEffect } from "react"
import {
	dateToUTCString,
	endOf,
	startOf,
	subtractUnit,
} from "@sembark-travel/datetime-utils"
import { Optional } from "utility-types"
import { SelectField } from "@sembark-travel/ui/form"
import { SelectUsers } from "../Users"
import {
	IUser,
	PERMISSIONS,
	useAuthManagedUsers,
	useCheckPermissions,
} from "../Auth"

type TFilters = TSearchParams & {
	activeTab: "today" | "yesterday" | "overdue" | "upcoming" | "all"
	assigned_to?: Array<IUser>
}

type TFiltersInQuery = Omit<Optional<TFilters, "activeTab">, "assigned_to"> & {
	assigned_to?: Array<string>
}

function queryToFilteres(query: TFiltersInQuery): TFilters {
	const { activeTab, assigned_to, ...others } = query
	const filters: TFilters = {
		activeTab: activeTab || "today",
		...others,
	}
	if (assigned_to) {
		filters.assigned_to = assigned_to.map((s) => {
			const [id, ...name] = s.split("#")
			return {
				id,
				name: name.join("#"),
			} as never as IUser
		})
	}
	return filters
}

function filtersToQuery(filters: TFilters): TFiltersInQuery {
	const { assigned_to, ...others } = filters
	const query: TFiltersInQuery = {
		...others,
	}
	if (assigned_to) {
		query.assigned_to = assigned_to.map((s) => `${s.id}#${s.name}`)
	}

	return query
}

export function CommentsList() {
	const [query, setQuery] = useLocationQuery({
		fromQuery: queryToFilteres,
		toQuery: filtersToQuery,
	})
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	const today = new Date()
	const { hasPermission } = useCheckPermissions()
	const managed_users = useAuthManagedUsers()
	const canViewAssignedToOthers =
		hasPermission(PERMISSIONS.MANAGE_USERS) || managed_users.length
	return (
		<>
			<Search
				initialParams={params}
				onSearch={(params) => setParams({ ...params, page: 1 })}
				title="Follow Ups / Comments"
				areAdvancedFiltersApplied={(params) => {
					const { activeTab, ...others } = params
					return areAdvancedFiltersAppliedDefault(others)
				}}
				Filters={canViewAssignedToOthers ? Filters : undefined}
			>
				{({ searchParams, setSearchParams }) => (
					<>
						<Tabs>
							<TabsList>
								<TabItem
									active={searchParams.activeTab === "today"}
									onClick={() =>
										setSearchParams({
											activeTab: "today",
										})
									}
								>
									Today
								</TabItem>
								<TabItem
									active={searchParams.activeTab === "yesterday"}
									onClick={() =>
										setSearchParams({
											activeTab: "yesterday",
										})
									}
								>
									Yesterday
								</TabItem>
								<TabItem
									active={searchParams.activeTab === "overdue"}
									onClick={() => {
										setSearchParams({
											activeTab: "overdue",
										})
									}}
								>
									Overdue
								</TabItem>
								<TabItem
									active={searchParams.activeTab === "upcoming"}
									onClick={() => {
										setSearchParams({
											activeTab: "upcoming",
										})
									}}
								>
									Upcoming
								</TabItem>
								<TabItem
									active={searchParams.activeTab === "all"}
									onClick={() => {
										setSearchParams({
											activeTab: "all",
										})
									}}
								>
									All
								</TabItem>
							</TabsList>
							<TabContent>
								<ListView
									pageKey="follow-ups-comments"
									params={params}
									fetch={(xhr, { activeTab, assigned_to, ...params }) =>
										xhr
											.get<IListResponse<IComment>>("/comments", {
												params: {
													...params,
													status:
														activeTab === "today" || activeTab === "yesterday"
															? "all"
															: activeTab,
													due_after:
														activeTab === "today"
															? dateToUTCString(startOf(new Date(), "day"))
															: activeTab === "yesterday"
																? dateToUTCString(
																		startOf(
																			subtractUnit(new Date(), 1, "day"),
																			"day"
																		)
																	)
																: null,
													due_before:
														activeTab === "today"
															? dateToUTCString(endOf(today, "day"))
															: activeTab === "yesterday"
																? dateToUTCString(
																		endOf(
																			subtractUnit(new Date(), 1, "day"),
																			"day"
																		)
																	)
																: null,
													assigned_to: assigned_to?.map((s) => s.id),
												},
											})
											.then((resp) => resp.data)
									}
									onPageChange={(page) => setParams({ ...params, page })}
								>
									{({ items, xhr, refresh }) => (
										<Table
											bordered
											hover
											responsive
											headers={["Description", "Due", "For", "Created", ""]}
											alignCols={{ 4: "right" }}
											rows={items.map((d) => [
												<Stack>
													<Text
														maxWidth="sm"
														color={d.resolved_at ? "success" : undefined}
														whiteSpace="preserveLine"
													>
														{d.body}
													</Text>
													{canViewAssignedToOthers ? (
														<Text fontSize={"sm"} color="muted">
															<Icons.User size="3" /> {d.created_by?.name}
														</Text>
													) : null}
												</Stack>,
												d.due_at ? <RelativeTime timestamp={d.due_at} /> : null,
												d.commentable_type === "trips" ? (
													<Link
														to={generatePath("/trips/:tripId", {
															tripId: String(d.commentable_id),
														})}
														color="accent"
														anchored
													>
														Trip#{d.commentable_id}
													</Link>
												) : d.commentable_type === "instalments" ? (
													<Link
														to={generatePath(
															"/payments/instalments/:instalmentId",
															{
																instalmentId: String(d.commentable_id),
															}
														)}
														anchored
														color="accent"
													>
														Instalment#{d.commentable_id}
													</Link>
												) : d.commentable_type === "tenants" ? (
													<Link
														to={generatePath(
															"/admin/tenants/:tenantId/comments",
															{
																tenantId: String(d.commentable_id),
															}
														)}
														anchored
														color="accent"
													>
														Tenant#{d.commentable_id}
													</Link>
												) : null,
												<Time timestamp={d.created_at} format="DD MMM" />,
												d.needs_resolution ? (
													<Dropdown alignRight noCaret>
														<Dropdown.ToggleButton size="sm" level="tertiary">
															<Icons.DotsVertical />
														</Dropdown.ToggleButton>
														<Dropdown.Menu>
															<Dropdown.MenuItem
																onClick={() => {
																	if (
																		window.confirm(
																			"Please confirm that this is resolved.\n\nThis action CAN NOT be reverted."
																		)
																	) {
																		xhr
																			.post("/resolved-comments", {
																				items: [d.id],
																			})
																			.then(() => refresh())
																			.catch((error) => {
																				const e = error as Error
																				alert(
																					e.message ||
																						"Something went wrong. Please try after sometime"
																				)
																			})
																	}
																}}
															>
																Mark as Resolved
															</Dropdown.MenuItem>
														</Dropdown.Menu>
													</Dropdown>
												) : (
													<Stack gap="px">
														<Box>
															<Time timestamp={d.resolved_at}>
																<Badge success>Done</Badge>
															</Time>
														</Box>
														{canViewAssignedToOthers && d.resolved_by ? (
															<Text fontSize={"sm"} color="muted">
																by {d.resolved_by?.name}
															</Text>
														) : null}
													</Stack>
												),
											])}
										/>
									)}
								</ListView>
							</TabContent>
						</Tabs>
					</>
				)}
			</Search>
		</>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<SelectField
				select={SelectUsers}
				name="assigned_to"
				label="Assigned To"
				fetchOnMount
				multiple
			/>
		</Stack>
	)
}
