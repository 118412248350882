import { useEffect, useMemo, useRef, useReducer, useCallback } from "react"
import { $PropertyType, Required } from "utility-types"
import {
	TViews,
	IDateTimeState,
	IDateTimeHelpers,
	IDateTimeConfig,
	TDuration,
} from "./types"
import {
	startOf,
	parseDate,
	isSame,
	getUnit,
	setUnit,
	formatDate,
	isBetween,
	isSameOrBefore,
	isSameOrAfter,
	addUnit,
	subtractUnit,
} from "@sembark-travel/datetime-utils"
import { alwaysValidDate, VIEWS, getDateTimeFormats } from "./utils"
import { DateTimeProvider, useDateTimeContext } from "./DateTimeContext"
import DaysView from "./DaysView"
import MonthsView from "./MonthsView"
import YearsView from "./YearsView"
import TimesView from "./TimesView"
import { datetimeClassName } from "./datetime.css"

type TDateTimeActions =
	| { type: "SET_VIEW"; payload: TViews }
	| { type: "SET_VIEW_DATE"; payload: Date }
	| {
			type: "NAVIGATE_FORWARD"
			payload: { amount: number; type: TDuration }
	  }
	| {
			type: "NAVIGATE_BACK"
			payload: { amount: number; type: TDuration }
	  }

function dateTimeReducer(
	state: IDateTimeState,
	action: TDateTimeActions
): IDateTimeState {
	switch (action.type) {
		case "SET_VIEW":
			return { ...state, view: action.payload }
		case "SET_VIEW_DATE":
			return { ...state, viewDate: action.payload }
		case "NAVIGATE_FORWARD":
			return {
				...state,
				viewDate: addUnit(
					state.viewDate,
					action.payload.amount,
					action.payload.type
				),
			}
		case "NAVIGATE_BACK":
			return {
				...state,
				viewDate: subtractUnit(
					state.viewDate,
					action.payload.amount,
					action.payload.type
				),
			}
		default:
			return state
	}
}

export function useDateTime(config: IDateTimeConfig) {
	const {
		timeFormat: configTimeFormat,
		dateFormat: configDateFormat = true,
		children,
		onChange,
		value: configValue,
		clearable = true,
		min,
		max,
		isValidDate: configIsValidDate,
		...props
	} = config

	// get the value from the props value
	// make sure we don't update if the string values is not updated
	const configValueStr = useMemo(
		() =>
			configValue instanceof Date
				? configValue.toISOString()
				: (configValue || "").toString(),
		[configValue]
	)
	const value = useMemo(() => {
		return !configValueStr ? undefined : parseDate(configValueStr)
	}, [configValueStr])
	const { timeFormat, dateFormat, dateTimeFormat } = useMemo(
		() => getDateTimeFormats(configDateFormat, configTimeFormat),
		[configTimeFormat, configDateFormat]
	)

	const initialView = dateFormat ? VIEWS["DAYS"] : VIEWS["TIME"]

	const initialViewDate = useRef(
		startOf(value ? parseDate(value) : new Date(), "month")
	).current

	const [state, dispatch] = useReducer<
		React.Reducer<IDateTimeState, TDateTimeActions>
	>(dateTimeReducer, {
		view: initialView,
		viewDate: initialViewDate,
	})

	const setViewDate = useCallback<
		$PropertyType<IDateTimeHelpers, "setViewDate">
	>((viewDate) => {
		dispatch({ type: "SET_VIEW_DATE", payload: viewDate })
	}, [])

	const showView = useCallback<$PropertyType<IDateTimeHelpers, "showView">>(
		(view) => {
			dispatch({ type: "SET_VIEW", payload: view })
		},
		[]
	)

	const navigateForward = useCallback<
		$PropertyType<IDateTimeHelpers, "navigateForward">
	>((amount, type) => {
		dispatch({ type: "NAVIGATE_FORWARD", payload: { amount, type } })
	}, [])

	const navigateBack = useCallback<
		$PropertyType<IDateTimeHelpers, "navigateBack">
	>((amount, type) => {
		dispatch({ type: "NAVIGATE_BACK", payload: { amount, type } })
	}, [])

	const handleChange = useCallback(
		(date?: Date) => {
			if (!onChange) {
				return
			}
			if (clearable && value && date && !configTimeFormat) {
				if (isSame(parseDate(value), date, "day")) {
					onChange()
					return
				}
			}
			onChange(date)
		},
		[onChange, value, configTimeFormat, clearable]
	)
	// automatic change the viewDate if current select date
	// is not in the current month
	const formattedValue = value ? formatDate(value, dateTimeFormat) : value
	const stateRef = useRef(state)
	stateRef.current = state
	useEffect(() => {
		const { view, viewDate } = stateRef.current
		const value = formattedValue
			? parseDate(formattedValue, dateTimeFormat)
			: undefined
		if (
			view === "days" &&
			value &&
			getUnit(viewDate, "month") !== getUnit(value, "month")
		) {
			setViewDate(setUnit(viewDate, "month", getUnit(value, "month")))
		}
	}, [formattedValue, setViewDate, dateTimeFormat])

	const isValidDate: $PropertyType<
		Required<IDateTimeConfig>,
		"isValidDate"
	> = useCallback(
		(currentDay, selected) => {
			if (configIsValidDate) {
				return configIsValidDate(currentDay, selected)
			}
			if (!min && !max) {
				return alwaysValidDate(currentDay, selected)
			}
			if (min && max) {
				return isBetween(currentDay, min, max, "day", "[]")
			}
			if (min) {
				return isSameOrAfter(currentDay, min, "day")
			}
			if (max) {
				return isSameOrBefore(currentDay, max, "day")
			}
			return true
		},
		[configIsValidDate, min, max]
	)

	// THE CONTEXT
	const ctx = {
		...props,
		isValidDate,
		...state,
		value,
		onChange: handleChange,
		dateFormat,
		timeFormat,
		dateTimeFormat,
		setViewDate,
		showView,
		navigateForward,
		navigateBack,
		clearable,
	}

	return ctx
}

function DateTimeContextProvider(props: IDateTimeConfig) {
	const { children } = props
	const dateTimeBag = useDateTime(props)
	return <DateTimeProvider value={dateTimeBag}>{children}</DateTimeProvider>
}

const uiForView = {
	days: DaysView,
	months: MonthsView,
	years: YearsView,
	time: TimesView,
}

function Container() {
	const { view } = useDateTimeContext()
	const View = uiForView[view]
	return <View />
}

export default function DateTime(props: IDateTimeConfig) {
	return (
		<DateTimeContextProvider {...props}>
			<div className={datetimeClassName}>
				<Container />
			</div>
		</DateTimeContextProvider>
	)
}
