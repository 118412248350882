import { configureDatetime } from "@sembark-travel/datetime-utils"
import { configureLogger, logInfo } from "@sembark-travel/logging"
import {
	bootstrap as bootstrapTracking,
	trackEvent,
	TrackingEvents,
	trackHotkey,
} from "@sembark-travel/tracking"
import { Icons, Spinner, VWProvider } from "@sembark-travel/ui/base"
import { NetworkStatusProvider } from "@sembark-travel/ui/network-status"
import {
	ErrorBoundary,
	bootstrap as bootstrapErrorBoundary,
} from "@sembark-travel/ui/error-boundary"
import { SnackbarProvider, showSnackbar } from "@sembark-travel/ui/snackbar"
import {
	addGlobalResponseErrorInterceptor,
	addGlobalResponseSuccessInterceptor,
	configureXHR,
	xhr,
	XHRContext,
} from "@sembark-travel/xhr"
import raf from "raf"
import { createRoot } from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import { Provider } from "react-redux"
import { combineReducers } from "redux"
import { routes } from "./routes"
import { AuthUserProvider, authStore } from "./Auth"
import config from "./config"
import configureStore from "./configureStore"
import { CSRFTokenProvider } from "./CSRFToken"
import * as serviceWorker from "./serviceWorkerRegistration"
import { IAppState, TRootAction } from "./types"
import { StrictMode } from "react"
import {
	setAppUpdateAvailable,
	setCurrentSWRegistration,
	showAppUpdateRequiredNotification,
} from "./AppInstaller"
import { SWRConfig, SWRConfiguration } from "swr"
import { setCurrentSWRegistrationForNotications } from "./Notifications"
import "./main.css"
import { configureHotkey } from "@sembark-travel/ui/hotkey"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

// polyfill the global with requestAnimationFrame
raf.polyfill()

// polyfill the array.at
if (!Array.prototype.at) {
	// eslint-disable-next-line
	Array.prototype.at = function arrayAtShim(n) {
		let i = Math.trunc(n) || 0

		if (i < 0) i += this.length

		if (i < 0 || i >= this.length) return undefined

		return this[i]
	}
}

// polyfill Object.hasOwn
/* eslint-disable-next-line no-prototype-builtins */
if (Object.hasOwnProperty("hasOwn") === false || !Object.hasOwn) {
	// @ts-expect-error - TS doesn't know about this polyfill
	Object.hasOwn = Object.call.bind(Object.hasOwnProperty)
}

configureXHR({
	apiBaseUrl: config.apiBaseUrl,
	serverBase: config.serverBase,
	appVersion: config.appVersion,
	appTimezone: config.timezone,
	appTimezoneOffset: config.timezoneOffset,
})

configureLogger({
	env: config.appEnv,
	dsn: config.errorReporting.sentry.dsn,
	appName: config.appName,
	appVersion: config.appVersion,
})

bootstrapTracking({
	channels:
		config.appEnv === "development"
			? ["console", "ga"]
			: config.appEnv === "production"
				? ["posthog"]
				: ["ga"],
	config: {
		appVersion: config.appVersion,
		appEnv: config.appEnv,
		ga: {
			gaMeasurementId: config.gaMeasurementId,
		},
		posthog: config.services.posthog,
	},
})

bootstrapErrorBoundary({
	appVersion: config.appVersion,
})

configureDatetime({
	dateDisplayFormat: config.dateDisplayFormat,
	timeDisplayFormat: config.timeDisplayFormat,
	timestampDateFormat: config.timestampDateFormat,
	timeFormat: config.timeFormat,
	dateTimeDisplayFormat: config.dateTimeDisplayFormat,
	dateTimeDayDisplayFormat: config.dateTimeDayDisplayFormat,
})

configureHotkey({
	tracker: trackHotkey,
})

const rootReducer = combineReducers({
	[authStore.key]: authStore.reducer,
})

const store = configureStore<IAppState, TRootAction>(rootReducer as never, {
	thunkExtraAgrs: { xhr },
})

addGlobalResponseErrorInterceptor((error) => {
	const statusCode = parseInt(
		String(
			(error.response?.data as { status_code?: string } | undefined)
				?.status_code ||
				error.response?.status ||
				error.code ||
				"0"
		)
	)
	if (statusCode === 401) {
		store.dispatch(authStore.actions.sessionExpired())
	}
	return error
})

const networkSnackBarId =
	"snackbar" + parseInt((Math.random() * 1000).toString())
addGlobalResponseErrorInterceptor(function networkErrorInterceptor(error) {
	if (error && error.message?.toLowerCase() === "network error") {
		let url = error.config?.url || "/"
		const method = error.config?.method
		const queryIndex = url.indexOf("?")
		if (queryIndex !== -1) {
			url = url.substring(0, queryIndex)
		}
		logInfo("Network Error", {
			tags: {
				url,
				method: method || "get",
			},
		})
		showSnackbar("There is a network issue. Please try again.", {
			id: networkSnackBarId,
			duration: 6000,
			icon: <Icons.AttentionSolid color="danger" />,
		})
	}
	return error
})

addGlobalResponseSuccessInterceptor((response) => {
	const responseHeaders = response.headers || {}
	const updateRequired = responseHeaders["x-sembark-webapp-update-required"]
	if (updateRequired) {
		showAppUpdateRequiredNotification(updateRequired)
	}
	return response
})

addGlobalResponseErrorInterceptor((error) => {
	const responseHeaders = error.response?.headers || {}
	const updateRequired = responseHeaders["x-sembark-webapp-update-required"]
	if (updateRequired) {
		showAppUpdateRequiredNotification(String(updateRequired))
	}
	return error
})

const defaultSWRConfig: SWRConfiguration = {
	// timeout to trigger the onLoadingSlow event in milliseconds
	loadingTimeout: 5000,
	// max error retry count
	errorRetryCount: 2,
	// callback function when a request takes too long to load (see loadingTimeout)
	onLoadingSlow: (__key) => {
		// logInfo("Slow Loading", {
		// 	tags: {
		// 		key: key.indexOf("?") === -1 ? key : key.slice(0, key.indexOf("?")),
		// 	},
		// })
	},
	isPaused: () => {
		const state = store.getState()[authStore.key]
		return Boolean(
			state.status !== authStore.AuthUserStatus.AUTHENTICATED ||
				state.sessionExpired
		)
	},
	revalidateOnFocus: false,
}

// We are statically creating router to ensure the data loading is separated from rendering
// Reference: https://reactrouter.com/en/main/routers/router-provider#routerprovider
//
// The placement of this code snippet is very important as this will affect what is and is not
// available to the router loader. Here we have placed after all the configuration so that
// all configurations will be available specifically xhr and redux store
const router = createBrowserRouter(routes)

const app = (
	<StrictMode>
		<ErrorBoundary>
			<NetworkStatusProvider>
				<SWRConfig value={defaultSWRConfig}>
					<CSRFTokenProvider>
						<Provider store={store}>
							<XHRContext.Provider value={xhr}>
								<VWProvider>
									<AuthUserProvider>
										<HelmetProvider>
											<RouterProvider
												router={router}
												fallbackElement={
													<>
														<Spinner /> Loading routing data...
													</>
												}
											/>
											<SnackbarProvider position="bottom-left" />
										</HelmetProvider>
									</AuthUserProvider>
								</VWProvider>
							</XHRContext.Provider>
						</Provider>
					</CSRFTokenProvider>
				</SWRConfig>
			</NetworkStatusProvider>
		</ErrorBoundary>
	</StrictMode>
)

const container = document.getElementById("root")
if (container) {
	const root = createRoot(container)
	root.render(app)
}

// Learn more about service workers: https://cra.link/PWA
serviceWorker.register({
	currentRegistration: (registration) => {
		setCurrentSWRegistration(registration)
		setCurrentSWRegistrationForNotications(registration)
	},
	onSuccess: (__registration) => undefined,
	onUpdate: (registration) => {
		setAppUpdateAvailable(registration)
	},
})

// NOTE: Keep it synced with manifest.json->start_url
const WEB_APP_MANIFEST_UTM_SOURCE = "pwa"

function getWebAppOpenedViaInstall(): false | "pwa" | "ms" {
	try {
		if (typeof document === "undefined") return false
		if (document.referrer === "app-info://platform/microsoft-store") {
			return "ms"
		}
		const location = window.location
		const search = location.search
		const searchParams = new URLSearchParams(search)
		const utmSource = searchParams.get("utm_source")
		// const referrer = document.referrer or similar
		if (utmSource === WEB_APP_MANIFEST_UTM_SOURCE) {
			return "pwa"
		}
	} catch (e) {
		return false
	}
	return false
}

function trackWebAppStartupMethods() {
	const via = getWebAppOpenedViaInstall()
	if (!via) return
	try {
		window.sessionStorage.setItem("app_opened_via", via)
	} catch (e) {
		console.log(e)
	}
	switch (via) {
		case "pwa":
			trackEvent(TrackingEvents.web_app_opened_via_pwa_install)
			break
		case "ms":
			trackEvent(TrackingEvents.web_app_opened_via_ms_install)
			break
	}
}
trackWebAppStartupMethods()
