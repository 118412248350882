import {
	Box,
	Button,
	Inline,
	Icons,
	Stack,
	Heading,
	Text,
	Col,
	Component,
	Divider,
	Grid,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	timeToUTCString,
	parseDate,
	formatDate,
} from "@sembark-travel/datetime-utils"
import {
	DatePickerField,
	SelectField,
	TextInputField,
	withServerErrors,
	SubmissionError,
	Form,
	FieldArray,
	arrayMutators,
	validateFormValues,
	PincodeValidator,
	EmptyNumberValidator,
	getEditablePhoneNumber,
} from "@sembark-travel/ui/form"
import { useXHR } from "@sembark-travel/xhr"
import { useCallback, useRef } from "react"
import { Optional, Required } from "utility-types"
import * as Validator from "yup"
import { AddressInputField, TAddressInputFieldValue } from "../Addresses"
import config from "../config"
import { SelectHotelGroup } from "../HotelGroups"
import { IHotelGroup } from "../HotelGroups"
import {
	SelectHotelPaymentPreferences,
	IHotelPaymentPreference,
} from "../HotelPaymentPreferences"
import { SelectMealPlans, IMealPlan } from "./../MealPlans"
import { SelectRoomTypes, IRoomType } from "./../RoomTypes"
import { IHotel, THotelLocation } from "./store"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import { useAuthUser } from "../Auth"
import { SelectHotelLocation } from "./Locations"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

interface NewItemCredentials {
	name: string
	location?: THotelLocation
	group?: IHotelGroup
	stars?: string
	extra_bed_child_age_start: number
	extra_bed_child_age_end: number
	meal_plans: IMealPlan[]
	room_types?: {
		room_types: IRoomType[]
		allowed_extra_beds: number
		allowed_adults_with_extra_bed: number
		allowed_children_with_extra_bed: number
		allowed_children_without_extra_bed?: number
		no_of_rooms?: number
	}[]
	address: TAddressInputFieldValue
	payment_preference?: IHotelPaymentPreference
	checkin_at: string | Date
	checkout_at: string | Date
	trip_destinations?: Array<TTripDestination>
	url?: string
}
const defaultInitialValues: Required<NewItemCredentials, "room_types"> = {
	name: "",
	location: undefined,
	group: undefined,
	stars: undefined,
	extra_bed_child_age_start: 6,
	extra_bed_child_age_end: 12,
	meal_plans: [],
	room_types: [
		{
			room_types: [],
			allowed_extra_beds: 1,
			allowed_adults_with_extra_bed: 1,
			allowed_children_with_extra_bed: 1,
			allowed_children_without_extra_bed: undefined,
			no_of_rooms: undefined,
		},
	],
	address: {
		line_1: "",
		line_2: "",
		landmark: "",
		postcode: "",
		phone_numbers: [
			{
				country_code: "IN",
				phone_number: "+91",
				number: "",
			},
		],
		email: "",
		location: undefined,
		city: undefined,
		state: undefined,
		country: undefined,
		type: "",
	},
	checkin_at: parseDate(config.defaultCheckinAt, config.timeFormat),
	checkout_at: parseDate(config.defaultCheckoutAt, config.timeFormat),
	trip_destinations: [],
	url: "",
}

export interface NewItemProps {
	onSubmit: (values: unknown) => Promise<IHotel>
	onCancel?: () => void
	initialValues?: Optional<NewItemCredentials>
	showFullAddress?: boolean
}

export function CreateHotel({
	onSuccess,
	...props
}: Omit<NewItemProps, "onSubmit"> & {
	onSuccess: (hotel: IHotel) => void
}) {
	const xhr = useXHR()
	const handleSubmit = useCallback(
		async (data: unknown): Promise<IHotel> => {
			const resp = await xhr.post("/hotels", data)
			const hotel = resp.data.data
			onSuccess && onSuccess(hotel)
			return hotel
		},
		[xhr, onSuccess]
	)
	return <NewHotelForm {...props} onSubmit={handleSubmit} />
}

export function NewHotelForm({
	onCancel,
	onSubmit,
	initialValues: propInitialValues = {},
	showFullAddress,
}: NewItemProps) {
	const { user } = useAuthUser()
	const can_attach_services_to_destinations = Boolean(
		user?.tenant?.feature_flags?.attach_services_to_destinations
	)
	const initialValues = useRef({
		...defaultInitialValues,
		...propInitialValues,
		trip_destinations: !can_attach_services_to_destinations
			? undefined
			: propInitialValues.trip_destinations ||
				defaultInitialValues.trip_destinations,
		address: {
			...defaultInitialValues.address,
			...propInitialValues.address,
			phone_numbers: propInitialValues.address?.phone_numbers
				? propInitialValues.address.phone_numbers.map((p) =>
						getEditablePhoneNumber(p.phone_number, p.country_code)
					)
				: defaultInitialValues.address.phone_numbers,
		},
	})
	const validationSchema = Validator.object().shape({
		name: Validator.string().required("Name field is required"),
		location: Validator.mixed()
			.nullable(false)
			.required("Please provide location for hotel"),
		group: Validator.mixed().nullable(true),
		stars: Validator.string()
			.nullable()
			.max(35, "Please use 35 or fewer characters"),
		extra_bed_child_age_start: Validator.number()
			.positive("Child start age should be a positive number")
			.integer("Child start age should be an integer")
			.required("Child start age is required"),
		extra_bed_child_age_end: Validator.number()
			.positive("Child end age should be a positive number")
			.integer("Child end age should be an integer")
			.required("Child end age is required"),
		meal_plans: Validator.array().min(1, "Please select atleast one meal plan"),
		room_types: Validator.array()
			.of(
				Validator.object().shape({
					room_types: Validator.array()
						.min(1, "Please select atleast one room type")
						.required("Please select a room type"),
					allowed_extra_beds: Validator.number()
						.typeError("Allowed extra bed must be a number")
						.integer("Allowed extra beds should be an integer")
						.min(0, "Allowed extra beds should not be negative")
						.max(200, "Can not be greater then 200")
						.required("Allowed extra beds field is required"),
					allowed_adults_with_extra_bed: EmptyNumberValidator()
						.integer("Allowed adult extra beds per room should be an integer")
						.min(0, "Allowed adult extra beds should not be negative")
						.max(200, "Can not be greater then 200"),
					allowed_children_with_extra_bed: EmptyNumberValidator()
						.integer("Allowed child extra beds per room should be an integer")
						.min(0, "Allowed child extra beds should not be negative")
						.max(200, "Can not be greater then 200"),
					allowed_children_without_extra_bed: EmptyNumberValidator()
						.integer("Allowed CNBs per room should be an integer")
						.min(0, "Allowed CNBs per room should not be negative")
						.max(200, "Can not be greater then 200"),
					no_of_rooms: EmptyNumberValidator()
						.nullable()
						.positive("Please provide a positive value.")
						.integer("Please provide a positive integer value"),
				})
			)
			.min(1, "Please select atleast one room type"),
		trip_destinations: can_attach_services_to_destinations
			? Validator.array()
					.required("Please select a Destinations")
					.min(1, "Please select atleast one Destinations")
			: Validator.array().nullable(),
		address: Validator.object().shape({
			line_1: Validator.string()
				.nullable(true)
				.max(100, "Please use 100 or less characters."),
			line_2: Validator.string()
				.nullable(true)
				.max(100, "Please use 100 or less characters."),
			landmark: Validator.string()
				.nullable(true)
				.max(100, "Please use 100 or less characters."),
			postcode: PincodeValidator().nullable(true),
			email: Validator.string().email("Please enter a valid email").nullable(),
		}),
		url: Validator.string()
			.nullable()
			.url("Please enter a valid url (starts with http:// or https://)"),
	})

	const validate = validateFormValues(validationSchema)
	return (
		<Form<NewItemCredentials>
			initialValues={initialValues.current}
			validate={validate}
			mutators={{ ...arrayMutators }}
			onSubmit={withServerErrors(async (values) => {
				const {
					meal_plans,
					room_types,
					address,
					payment_preference,
					checkin_at,
					checkout_at,
					group,
					trip_destinations,
					location,
					...otherData
				} = values
				const data = {
					...otherData,
					location: location?.name,
					group: group ? group.name : undefined,
					meal_plans: meal_plans.map((mealPlan) => mealPlan.name),
					room_types:
						room_types?.reduce(
							(
								rooms: Array<{
									name: string
									allowed_extra_beds: number
									allowed_adults_with_extra_bed: number
									allowed_children_with_extra_bed: number
									allowed_children_without_extra_bed?: number
									no_of_rooms?: number
								}>,
								{
									room_types,
									allowed_extra_beds,
									no_of_rooms,
									allowed_adults_with_extra_bed,
									allowed_children_with_extra_bed,
									allowed_children_without_extra_bed,
								}
							) =>
								rooms.concat(
									room_types.map((room_type) => ({
										name: room_type.name,
										allowed_extra_beds,
										allowed_adults_with_extra_bed,
										allowed_children_with_extra_bed,
										allowed_children_without_extra_bed,
										no_of_rooms,
									}))
								),
							[]
						) || [],
					address: {
						...address,
						phone_numbers: address?.phone_numbers
							?.filter((p) => p.number)
							.map((p) => ({
								...p,
								number: String(p.number).replace(/[^\d]/gi, ""),
							})),
						location: location?.name,
						city: address.city?.name,
						state: address.state?.name,
						country: address.country?.name,
					},
					payment_preference_id: payment_preference
						? payment_preference.id
						: undefined,
					checkin_at: timeToUTCString(checkin_at),
					checkin_at_local: formatDate(
						parseDate(checkin_at, config.timeFormat),
						config.timeFormat
					),
					checkout_at: timeToUTCString(checkout_at),
					checkout_at_local: formatDate(
						parseDate(checkout_at, config.timeFormat),
						config.timeFormat
					),
					trip_destinations: (trip_destinations || []).map((t) => t.id),
				}
				return await onSubmit(data)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => {
				return (
					<form noValidate onSubmit={handleSubmit}>
						<Grid gap="4">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading as="h3">Basic Details</Heading>
									<Text color="muted">
										Provide basic details e.g. name, star rating and address.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<Grid gap="4">
										<Col xs={12} sm={6} lg>
											<TextInputField
												label="Name"
												name="name"
												placeholder="Taj Hotel"
												required
												type="text"
											/>
										</Col>
										<Col>
											<SelectField
												select={SelectHotelGroup}
												label="Group Name"
												secondaryLabel="optional"
												name="group"
												creatable
											/>
										</Col>
										<Col>
											<TextInputField
												label="Stars"
												name="stars"
												type="text"
												required
											/>
										</Col>
									</Grid>
									<SelectField
										label="Location"
										placeholder="e.g. City, State, Name"
										name="location"
										select={SelectHotelLocation}
										help="Hotel's city location. Must match with uploaded data. This will used during filters and quote sharing"
										creatable
									/>
									<Component initialState={showFullAddress || false}>
										{({ state, setState }) => (
											<Stack gap="4">
												{!state ? (
													<Stack gap="4">
														<Stack gap="2">
															<Inline gap="4" alignItems="center">
																<Text fontWeight="semibold">Full Address</Text>
																<Button
																	onClick={() => setState(!state)}
																	size="sm"
																	level="tertiary"
																>
																	{state ? (
																		<Icons.ChevronDown
																			rotate="180"
																			title="Hide Address"
																		/>
																	) : (
																		<Icons.ChevronDown title="Show Address" />
																	)}
																</Button>
															</Inline>
															<Text color="muted">
																This will be used when generating vouchers.
															</Text>
														</Stack>
													</Stack>
												) : (
													<>
														<Text>
															Please provide the full address details of the
															hotel which will be used in vouchers.
														</Text>
														<AddressInputField name="address" />
													</>
												)}
											</Stack>
										)}
									</Component>
								</Stack>
							</Col>
						</Grid>
						<Divider />
						<Grid gap="4">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading as="h3">Services</Heading>
									<Text color="muted">
										Please provide services details available in this hotel e.g.
										meal plans and rooms along with checkin/checkout times and
										child's age range for extra bed.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<SelectField
										select={SelectMealPlans}
										name="meal_plans"
										creatable
										label="Meal Plans"
										fetchOnMount
										multiple
									/>
									<FieldArray name="room_types">
										{({ fields }) => (
											<Box marginY="8">
												<Box marginBottom="4">
													<Box fontWeight="semibold" fontSize="md">
														Room Types with Allowed Extra Bed(s)/Mattress(es)
													</Box>
													<Box color="muted">
														Please select room types available in this hotel
														along with allowed extra beds. Group room types that
														have same number of allowed extra beds
													</Box>
												</Box>
												<Box as="ul" borderLeftWidth="1" className="list">
													{fields.map((name, index) => (
														<Box as="li" key={name}>
															<Grid gap="4">
																<Col xs={12} md={4}>
																	<SelectField
																		select={SelectRoomTypes}
																		name={`${name}.room_types`}
																		label="Room Types"
																		creatable
																		fetchOnMount
																		multiple
																	/>
																</Col>
																<Col xs="auto">
																	<Stack gap="2">
																		<TextInputField
																			label="Allowed extra bed(s)"
																			type="number"
																			name={`${name}.allowed_extra_beds`}
																			min={0}
																			max={1000}
																			placeholder="e.g. 1"
																		/>
																		<Inline gap="2">
																			<TextInputField
																				label="AWEB(s)"
																				type="number"
																				name={`${name}.allowed_adults_with_extra_bed`}
																				min={0}
																				max={1000}
																				placeholder="e.g. 1"
																				size="sm"
																			/>
																			<TextInputField
																				label="CWEB(s)"
																				type="number"
																				name={`${name}.allowed_children_with_extra_bed`}
																				min={0}
																				max={1000}
																				placeholder="e.g. 1"
																				size="sm"
																			/>
																			<TextInputField
																				label="CNB(s)"
																				type="number"
																				name={`${name}.allowed_children_without_extra_bed`}
																				min={0}
																				max={1000}
																				placeholder="e.g. 0"
																				size="sm"
																			/>
																		</Inline>
																	</Stack>
																</Col>
																<Col xs="auto">
																	<TextInputField
																		label="No. of Rooms"
																		type="number"
																		name={`${name}.no_of_rooms`}
																		min={1}
																		max={1000}
																		placeholder="e.g. 25"
																	/>
																</Col>
																<Col xs="auto" paddingTop="6">
																	{Number(fields.length) > 1 ? (
																		<Button
																			onClick={() => fields.remove(index)}
																			size="sm"
																			level="tertiary"
																		>
																			<Icons.Cancel /> Remove
																		</Button>
																	) : null}
																</Col>
															</Grid>
														</Box>
													))}
													<Box as="li">
														<Button
															onClick={() =>
																fields.push({
																	room_type: undefined,
																	allowed_extra_beds: 1,
																})
															}
															size="sm"
														>
															<Icons.Plus /> Add Rooms with Different Extra Beds
														</Button>
													</Box>
												</Box>
											</Box>
										)}
									</FieldArray>
								</Stack>
							</Col>
						</Grid>
						<Divider />
						<Grid gap="4">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading as="h3">Configuration</Heading>
									<Text color="muted">
										Please provide the checkin/checkout time along with age of
										children for extra beds.
									</Text>
								</Stack>
							</Col>
							<Col>
								<Stack gap="4">
									<Grid marginBottom="6" gap="4">
										<Col>
											<DatePickerField
												label="Checkin Time"
												name="checkin_at"
												dateFormat={false}
												timeFormat={config.timeDisplayFormat}
												required
											/>
										</Col>
										<Col>
											<DatePickerField
												label="Checkout Time"
												name="checkout_at"
												dateFormat={false}
												timeFormat={config.timeDisplayFormat}
												required
											/>
										</Col>
									</Grid>
									<Stack gap="4">
										<Stack gap="1">
											<Box
												fontWeight="semibold"
												as="label"
												htmlFor="extra_bed_child_age_start"
												display="block"
											>
												Children Age range for Charges on Child No Bed / Child
												Extra Bed/Mattress
											</Box>
											<Inline gap="4">
												<TextInputField
													name="extra_bed_child_age_start"
													required
													type="number"
													min={1}
													max={1000}
												/>
												<TextInputField
													name="extra_bed_child_age_end"
													required
													type="number"
													min={1}
													max={1000}
												/>
											</Inline>
										</Stack>
										<Box fontSize="sm">
											<Stack
												as="ul"
												listStyleType="disc"
												color="muted"
												marginLeft="4"
												gap="1"
											>
												<Box as="li">
													Below this range, children are counted as{" "}
													<b>complementary kids</b>.
												</Box>
												<Box as="li">
													Above this range, children are counted as{" "}
													<b>Adults</b>.
												</Box>
												<Box as="li">
													Between this range, charges for{" "}
													<b>no extra beds, extra beds or meal</b> will be
													applicable
												</Box>
											</Stack>
										</Box>
									</Stack>
									{can_attach_services_to_destinations ? (
										<SelectField
											select={SelectTripDestination}
											name="trip_destinations"
											label="Trip Destinations"
											multiple
											fetchOnMount
										/>
									) : null}
								</Stack>
							</Col>
						</Grid>
						<Divider />
						<Grid gap="4">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading as="h3">Payment Preferences</Heading>
									<Text color="muted">
										Select / Create a payment preference for by which this hotel
										accepts the payments.
									</Text>
								</Stack>
							</Col>
							<Col>
								<SelectField
									select={SelectHotelPaymentPreferences}
									name="payment_preference"
									label="Payment Preference"
									secondaryLabel="optional"
									multiple={false}
									creatable
									fetchOnMount
								/>
							</Col>
						</Grid>
						<Divider />
						<Grid gap="4">
							<Col sm={12} md={4}>
								<Stack gap="1">
									<Heading as="h3">More Details</Heading>
									<Text color="muted">
										Attach more details such as hotel's details link etc.
									</Text>
								</Stack>
							</Col>
							<Col>
								<TextInputField
									name="url"
									label="Hotel Images / Details Link"
									secondaryLabel="optional"
									type="url"
									placeholder="e.g. https://www.hotelname.com/images"
								/>
							</Col>
						</Grid>
						<Divider />
						<Grid>
							<Col sm={12} md={{ offset: 4, span: 8 }}>
								<Stack gap="4">
									<SubmissionError />
									<Inline gap="4">
										<Button type="submit" disabled={submitting} size="lg">
											{submitting ? "Please wait..." : "Save Hotel Details"}
										</Button>
										{onCancel ? (
											<Button
												onClick={onCancel}
												level="tertiary"
												size="lg"
												disabled={submitting}
											>
												Cancel
											</Button>
										) : null}
									</Inline>
								</Stack>
							</Col>
						</Grid>
					</form>
				)
			}}
		</Form>
	)
}

export function AddHotelDialog({
	open,
	onClose,
	onSuccess,
	...props
}: Omit<React.ComponentProps<typeof CreateHotel>, "onCancel"> & {
	open: boolean
	onClose: () => void
}) {
	return (
		<Dialog open={open} onClose={onClose} lg>
			<Dialog.Header closeButton>
				<Dialog.Title>Add new Hotel</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<CreateHotel
					onSuccess={(hotel) => {
						onClose()
						onSuccess?.(hotel)
						showSnackbar(`Hotel ${hotel.name} successfully added.`)
					}}
					onCancel={onClose}
					{...props}
				/>
			</Dialog.Body>
		</Dialog>
	)
}
