import {
	utcTimestampToLocalDate,
	dateToUTCString,
	startOf,
	dateToQuery,
	parseDateFromQuery,
	endOf,
	subtractUnit,
} from "@sembark-travel/datetime-utils"
import {
	Badge,
	Box,
	Button,
	Icons,
	Table,
	Stack,
	RelativeTime,
	TabContent,
	Tabs,
	TabsList,
	Text,
	Select,
	Money,
} from "@sembark-travel/ui/base"
import {
	useLocationQuery,
	queryToSearch,
	Link,
} from "@sembark-travel/ui/router"
import {
	useSearch,
	areAdvancedFiltersAppliedDefault,
	TSearchParams,
	Search,
	ListView,
} from "@sembark-travel/ui/list"
import { IListResponse, useXHR } from "@sembark-travel/xhr"
import React, { Fragment, useEffect, useMemo } from "react"
import useSWR from "swr"
import { AddCommentInDialog, CommentableCommentItem } from "../Comments"
import { IInstalment, PAYMENTABLE_TYPES } from "./store"
import { InstalmentItemInDialog, VerifyPaymentInDialog } from "./InstalmentItem"
import { InstalmentContact } from "./InstalmentContact"
import { LogTransaction } from "./LogTransaction"
import {
	DateRangePickerField,
	SelectField,
	SwitchInputField,
	useFieldValue,
} from "@sembark-travel/ui/form"
import { generatePath } from "../router-utils"
import config from "../config"
import { useHasFeatureFlag } from "../Auth"
import { TemporaryStorage } from "../TemporaryStorage"

interface IPaymentsListProps {
	/**
	 * Show payments that are will get credited to the company account
	 */
	credit?: boolean
	/**
	 * Show payments that are will get debited to the company account
	 */
	debit?: boolean
	/**
	 * Title for the listing page
	 */
	title?: string
	/**
	 * Show the payments for given hotels only
	 */
	hotels?: Array<number> | Array<string>
	/**
	 * Show the payments for given transport service providers only
	 */
	transportServiceProviders?: Array<number> | Array<string>
	/**
	 * Hide contact details
	 */
	hideContact?: boolean
}

export function PaymentsList({
	credit,
	debit,
	title = "Payments",
	hotels,
	transportServiceProviders,
	hideContact,
}: IPaymentsListProps) {
	const [query, setQuery] = useLocationQuery<IFilters, IFiltersInLocationQuery>(
		{ toQuery: paramsToLocationQuery, fromQuery: locationQueryToParams }
	)
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	const extraParams = useMemo(
		() => ({
			is_credit: credit ? 1 : undefined,
			is_debit: debit ? 1 : undefined,
			hotels: hotels && hotels.length ? hotels : undefined,
			transportServiceProviders:
				transportServiceProviders && transportServiceProviders.length
					? transportServiceProviders
					: undefined,
		}),
		[credit, debit, hotels, transportServiceProviders]
	)
	return (
		<>
			<Search
				title={title}
				initialParams={params}
				Filters={Filters}
				onSearch={(newParams) => {
					setParams({ ...newParams, page: 1 })
				}}
				resetParams={(params) => ({
					q: "",
					status: params.status,
					page: 1,
				})}
				areAdvancedFiltersApplied={(params) => {
					const { status, is_refund, hide_on_hold, verified, ...otherParams } =
						params
					return Boolean(
						is_refund ||
							hide_on_hold ||
							(verified && status === "paid") ||
							areAdvancedFiltersAppliedDefault(otherParams)
					)
				}}
			>
				{({ searchParams, setSearchParams }) => (
					<>
						<Tabs>
							<TabsList>
								<TabLink
									params={searchParams}
									setParams={setSearchParams}
									extraParams={extraParams}
									status="future_due"
								>
									Upcoming
								</TabLink>
								<TabLink
									params={searchParams}
									setParams={setSearchParams}
									extraParams={extraParams}
									status="recent_overdue"
								>
									Past 7 Days
								</TabLink>
								<TabLink
									params={searchParams}
									setParams={setSearchParams}
									extraParams={extraParams}
									status="unverified"
								>
									Unverified
								</TabLink>
								<TabLink
									params={searchParams}
									setParams={setSearchParams}
									extraParams={extraParams}
									status="paid"
								>
									Paid
								</TabLink>
								<TabLink
									params={searchParams}
									setParams={setSearchParams}
									extraParams={extraParams}
									status="overdue"
								>
									Overdue
								</TabLink>
								<TabLink
									params={searchParams}
									setParams={setSearchParams}
									extraParams={extraParams}
									status="all"
								>
									All
								</TabLink>
							</TabsList>
							<TabContent overflow="auto" style={{ minHeight: "50vh" }}>
								<List
									params={params}
									pageKey={credit ? "incoming-payments" : "outgoing-payments"}
									setParams={setParams}
									extraParams={extraParams}
									hideContact={hideContact}
								/>
							</TabContent>
						</Tabs>
					</>
				)}
			</Search>
		</>
	)
}

type TStatus =
	| "all"
	| "overdue"
	| "recent_overdue"
	| "future_due"
	| "paid"
	| "unverified"

function TabLink({
	params,
	setParams,
	children,
	showCount,
	extraParams,
	status,
}: {
	params: IFilters
	setParams: (params: IFilters) => void
	children: React.ReactNode
	showCount?: boolean
	extraParams?: Record<string, unknown>
	status: TStatus
}) {
	return (
		<Box as="li" className={params.status === status ? "active" : ""}>
			<a
				href={`#${status}`}
				onClick={(e) => {
					e.preventDefault()
					setParams({
						...params,
						status,
					})
				}}
			>
				{children}{" "}
				{showCount ? (
					<Count {...params} {...extraParams} status={status} />
				) : null}
			</a>
		</Box>
	)
}

function List({
	extraParams,
	pageKey = "payments-list",
	params,
	setParams,
	hideContact,
}: {
	extraParams?: Record<string, unknown>
	pageKey?: string
	params: IFilters
	setParams: (params: IFilters) => void
	hideContact?: boolean
}) {
	const { status } = params
	const showDueStatus = status !== "paid"
	const showOverdueStatus = status !== "overdue" && status !== "recent_overdue"
	return (
		<ListView<IInstalment, IFilters>
			pageKey={pageKey + queryToSearch(extraParams)}
			params={params}
			fetch={(xhr, params) =>
				xhr
					.get("/instalments", {
						params: {
							...filtersToAPIParams(params),
							...(extraParams || {}),
						},
					})
					.then((resp) => resp.data)
			}
			onPageChange={(page) => setParams({ ...params, page })}
			actions={({ total }) =>
				total && total < 10000 ? (
					<TemporaryStorage
						initUrl={"/instalments/download"}
						initParams={{
							...filtersToAPIParams(params),
							...extraParams,
							timezone_offset: config.timezoneOffset,
						}}
					>
						{({ generate, url, message, reset, isGenerating }) =>
							url ? (
								<Button
									as="a"
									href={url}
									target="_blank"
									download
									level="primary"
									size="sm"
									onClick={() => reset()}
								>
									{message}
								</Button>
							) : (
								<Button size="sm" onClick={generate} disabled={isGenerating}>
									{message || (
										<>
											<Icons.DocumentDownload /> Download
										</>
									)}
								</Button>
							)
						}
					</TemporaryStorage>
				) : null
			}
		>
			{({ items: instalments, refresh }) =>
				status === "paid" || status === "unverified" ? (
					<PaidInstalmentsList instalments={instalments} onChange={refresh} />
				) : (
					<Table
						bordered
						striped
						responsive
						headers={["Amount", "Due Date"]
							.concat(hideContact ? [] : ["Contact"])
							.concat(["Comments", "Actions"])}
						rows={instalments.map((instalment) => {
							const {
								currency,
								amount,
								due_at,
								due_at_local,
								paid_at,
								payment,
								is_overdue,
								comments,
							} = instalment
							return (
								[
									<Box>
										<InstalmentItemInDialog
											key={instalment.id}
											instalmentId={instalment.id}
											onChange={() => refresh()}
											showSiblings
										>
											{({ show }) => (
												<Button inline onClick={() => show()}>
													<Text as="span" fontSize="md" color="accent">
														<Money
															amount={amount}
															currency={currency}
															showCurrency
														/>
													</Text>
												</Button>
											)}
										</InstalmentItemInDialog>
										{showDueStatus ? (
											<Box fontSize="sm">
												{paid_at ? (
													<Badge success outlined>
														Paid{" "}
														<RelativeTime
															value={utcTimestampToLocalDate(paid_at)}
														/>
													</Badge>
												) : is_overdue && showOverdueStatus ? (
													<Badge warning outlined>
														Overdue
													</Badge>
												) : null}
											</Box>
										) : null}
									</Box>,
									<Box>
										<RelativeTime
											timestamp={due_at}
											localTimestamp={due_at_local}
											minUnit="day"
										/>
									</Box>,
								] as Array<React.ReactNode>
							)
								.concat(
									hideContact
										? []
										: [
												payment ? (
													<InstalmentContact instalment={instalment} />
												) : null,
											]
								)
								.concat([
									<Box>
										{comments?.length ? (
											<Box fontSize="sm">
												{comments.map((c) => (
													<CommentableCommentItem
														key={c.id}
														comment={c}
														onChange={() => refresh()}
													/>
												))}
											</Box>
										) : null}
										<AddCommentInDialog
											commentableType="instalments"
											commentableId={instalment.id}
											onChange={() => refresh()}
											inline
											size="sm"
										>
											Add Comment
										</AddCommentInDialog>
									</Box>,
									<Box>
										{!paid_at ? (
											<LogTransaction
												instalment={instalment}
												onChange={() => refresh()}
											/>
										) : null}
									</Box>,
								])
						})}
					/>
				)
			}
		</ListView>
	)
}

function PaidInstalmentsList({
	instalments,
	onChange,
}: {
	instalments: Array<IInstalment>
	onChange?: () => void
}) {
	return (
		<Table
			bordered
			striped
			responsive
			headers={[
				"Amount",
				"Paid On",
				"Debit Acc.",
				"Credit Acc.",
				"Contact",
				"",
			]}
			rows={instalments.map((instalment) => {
				const {
					amount,
					paid_at,
					payment,
					currency,
					reference_id,
					debit_accounts,
					credit_accounts,
				} = instalment
				return [
					<Box>
						<InstalmentItemInDialog
							key={instalment.id}
							instalmentId={instalment.id}
							onChange={onChange}
							showSiblings
						>
							{({ show }) => (
								<Button inline onClick={() => show()}>
									<Text as="span" fontSize="md" color="accent">
										<Money amount={amount} currency={currency} showCurrency />
									</Text>
								</Button>
							)}
						</InstalmentItemInDialog>
					</Box>,
					<Box>
						{paid_at ? (
							<RelativeTime value={utcTimestampToLocalDate(paid_at)} />
						) : null}
						{reference_id ? (
							<Box
								style={{ maxWidth: "150px" }}
								marginTop="1"
								title={reference_id}
							>
								<Text fontSize="sm" color="muted" textOverflow="truncate">
									Ref: {reference_id}
								</Text>
							</Box>
						) : null}
					</Box>,
					debit_accounts?.map((a, i) => (
						<Fragment key={a.id}>
							{i > 0 ? " + " : null}
							<Link
								to={generatePath("/accounting/accounts/:accountId", {
									accountId: String(a.id),
								})}
								textDecoration={{ hover: "underline" }}
							>
								{a.name}
							</Link>
						</Fragment>
					)),
					credit_accounts?.map((a, i) => (
						<Fragment key={a.id}>
							{i > 0 ? " + " : null}
							<Link
								to={generatePath("/accounting/accounts/:accountId", {
									accountId: String(a.id),
								})}
								textDecoration={{ hover: "underline" }}
							>
								{a.name}
							</Link>
						</Fragment>
					)),
					payment ? <InstalmentContact instalment={instalment} /> : null,
					<Box key={instalment.id}>
						<Box>
							{instalment.verified_at ? (
								<Badge success outlined>
									<Icons.OkCircleSolid /> Verified
								</Badge>
							) : instalment.can_verify_payment ? (
								<VerifyPaymentInDialog
									instalment={instalment}
									onSuccess={onChange}
								>
									{({ verify }) => (
										<Button onClick={() => verify()} status="warning" size="sm">
											<Icons.BadgeCheckSolid /> Verify
										</Button>
									)}
								</VerifyPaymentInDialog>
							) : null}
						</Box>
					</Box>,
				]
			})}
		/>
	)
}

function Count({ limit, page, ...params }: IFilters) {
	const xhr = useXHR()
	const apiParams = filtersToAPIParams(params)
	const { data, error } = useSWR<IListResponse<IInstalment>>(
		`/instalments-count${queryToSearch(apiParams)}`,
		() =>
			xhr
				.get("/instalments", {
					params: {
						...apiParams,
						limit: 1,
					},
				})
				.then((resp) => resp.data)
	)
	if (!data || error) return null
	const { meta } = data
	return <Badge>{meta.total}</Badge>
}

const paymentableTypeOptions = Object.keys(PAYMENTABLE_TYPES).map((key) => {
	const id = key as keyof typeof PAYMENTABLE_TYPES
	return {
		id,
		name: PAYMENTABLE_TYPES[id],
	}
})

export interface IFilters extends TSearchParams {
	due_after?: string | Date
	due_before?: string | Date
	page?: number
	limit?: number
	status: TStatus
	is_refund?: boolean
	hide_on_hold?: boolean
	verified?: 1
	paymentable_types?: typeof paymentableTypeOptions
}

export interface IFiltersInLocationQuery extends TSearchParams {
	due_after?: string
	due_before?: string
	page?: number
	status: TStatus
	is_refund?: 1
	hide_on_hold?: 1
	verified?: 1
	paymentable_types?: Array<keyof typeof PAYMENTABLE_TYPES>
}

function paramsToLocationQuery(params: IFilters): IFiltersInLocationQuery {
	const {
		q,
		due_after,
		due_before,
		is_refund,
		hide_on_hold,
		verified,
		paymentable_types,
		...otherData
	} = params
	const filters: IFiltersInLocationQuery = {
		...otherData,
		status: otherData.status || "future_due",
	}
	if (q) {
		filters.q = q
	}
	if (due_after) {
		filters.due_after = dateToQuery(due_after)
	}
	if (due_before) {
		filters.due_before = dateToQuery(due_before)
	}
	if (is_refund) {
		filters.is_refund = 1
	}
	if (hide_on_hold) {
		filters.hide_on_hold = 1
	}
	if (verified) {
		filters.verified = 1
	}
	if (paymentable_types?.length) {
		filters.paymentable_types = paymentable_types.map((t) => t.id)
	}
	return filters
}

function locationQueryToParams(query: IFiltersInLocationQuery): IFilters {
	const {
		q,
		due_after,
		due_before,
		is_refund,
		hide_on_hold,
		verified,
		paymentable_types,
		...otherData
	} = query
	const filters: IFilters = {
		...otherData,
		status: otherData.status || "future_due",
	}
	if (q) {
		filters.q = q
	}
	if (due_after) {
		filters.due_after = parseDateFromQuery(due_after)
	}
	if (due_before) {
		filters.due_before = parseDateFromQuery(due_before)
	}
	if (is_refund) {
		filters.is_refund = true
	}
	if (hide_on_hold) {
		filters.hide_on_hold = true
	}
	if (verified) {
		filters.verified = 1
	}
	if (paymentable_types?.length) {
		filters.paymentable_types = paymentable_types
			.map((t) => ({
				id: t,
				name: PAYMENTABLE_TYPES[t],
			}))
			.filter((t) => t.name)
	}
	return filters
}

function Filters() {
	const { value: status } = useFieldValue<IFilters["status"]>("status")
	const hasHoldingEnabled = useHasFeatureFlag("hold_trips")
	return (
		<Stack gap="4">
			{hasHoldingEnabled ? (
				<SwitchInputField label="Hide On-Hold Bookings" name="hide_on_hold" />
			) : null}
			<DateRangePickerField
				label="Due Between"
				fromName="due_after"
				toName="due_before"
			/>
			<SelectField
				select={Select}
				label="Payment For"
				name="paymentable_types"
				multiple
				options={[
					{ id: "trips", name: "Trips" },
					{ id: "hotel_bookings", name: "Hotels" },
					{ id: "scheduled_cabs", name: "Cabs" },
					{ id: "travel_activity_bookings", name: "Activity/Ticket Bookings" },
				]}
			/>
			{status === "paid" ? (
				<SwitchInputField label="Verified Only" name="verified" />
			) : null}
			<SwitchInputField label="Refunds Only" name="is_refund" />
		</Stack>
	)
}

function filtersToAPIParams(params: IFilters) {
	const {
		due_after,
		due_before,
		status,
		is_refund,
		hide_on_hold,
		verified,
		paymentable_types,
		...otherData
	} = params
	const is_due_in_future = status === "future_due" ? 1 : undefined
	const is_overdue =
		status === "overdue" || status === "recent_overdue" ? 1 : undefined
	return {
		...otherData,
		due_after: due_after
			? dateToUTCString(startOf(due_after, "day"))
			: is_due_in_future
				? // set the due_after to start of today
					dateToUTCString(startOf(new Date(), "day"))
				: status === "recent_overdue"
					? dateToUTCString(endOf(subtractUnit(new Date(), 7, "day"), "day"))
					: undefined,
		due_before: due_before
			? dateToUTCString(endOf(due_before, "day"))
			: is_overdue
				? // set the due_before to end of yesterday
					dateToUTCString(endOf(subtractUnit(new Date(), 1, "day"), "day"))
				: undefined,
		is_due_in_future,
		is_overdue,
		is_due: is_due_in_future || is_overdue,
		is_paid: status === "paid" || status === "unverified" ? 1 : undefined,
		is_refund: is_refund ? 1 : undefined,
		hide_on_hold: hide_on_hold ? 1 : undefined,
		verified:
			status === "unverified"
				? 0
				: status === "paid" && verified
					? 1
					: undefined,
		paymentable_types: paymentable_types?.map((t) => t.id),
	}
}
