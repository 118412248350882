import { IAccount } from "../Accounting/store"
import { IAddress } from "./../Addresses"
import { IContact } from "./../Contacts"
import { IHotelGroup } from "./../HotelGroups"
import { IHotelPaymentPreference } from "./../HotelPaymentPreferences"
import { IMealPlan } from "./../MealPlans"
import { IRoomType } from "./../RoomTypes"
import { TMedia } from "./../Media"
import { TTripDestination } from "../TripDestinations"
import { IUser } from "../Auth"
import useSWR from "swr"
import {
	ISimpleListResponse,
	IListResponse,
	useXHR,
	XHRInstance,
} from "@sembark-travel/xhr"

export interface IPrice {
	id: number
	hotel_id: number
	base_price: number
	persons: number
	adult_with_extra_bed_price: number
	child_with_extra_bed_price: number
	child_without_extra_bed_price: number
	start_date: string
	end_date: string
	meal_plan_id: number
	room_type_id: number
	meal_plan: IMealPlan
	room_type: IRoomType
}

export interface IHotelRoomType extends IRoomType {
	allowed_extra_beds: number
	allowed_adults_with_extra_bed: number
	allowed_children_with_extra_bed: number
	allowed_children_without_extra_bed?: number
	no_of_rooms?: number
}
export type IHotelMealPlan = IMealPlan

export type IHotelAddress = IAddress

export type THotelLocation = {
	id: number
	name: string
	short_name: string
}

export interface IHotel {
	id: number
	name: string
	stars?: string | null
	no_of_stars?: number | null
	stars_string?: string | null
	extra_bed_child_age_start: number
	extra_bed_child_age_end: number
	meal_plans: IHotelMealPlan[]
	room_types: IHotelRoomType[]
	address: IHotelAddress
	prices?: IPrice[]
	contacts?: IContact[]
	payment_preference?: IHotelPaymentPreference
	checkin_at_local?: string
	checkin_at: string
	checkout_at_local?: string
	checkout_at: string
	accounting_accounts?: Array<IAccount>
	group?: IHotelGroup
	latest_media?: TMedia | null
	thumb_image_url?: string | null
	trip_destinations?: Array<TTripDestination>
	created_at: string
	created_by?: IUser
	updated_at: string | null
	updated_by?: IUser
	deleted_at: string | null
	deleted_by?: IUser
	url?: string
	location: THotelLocation
}

export function useHotel(hotelId: string | number) {
	const xhr = useXHR()
	const {
		data,
		mutate: revalidate,
		isValidating,
		error,
	} = useSWR(`/api/hotels/${hotelId}`, () =>
		xhr
			.get<{ data: IHotel }>(`/hotels/${hotelId}`)
			.then((resp) => resp.data.data)
	)
	const isFetching = !data && !error
	const isRefreshing = isValidating
	return {
		hotel: data,
		fetchHotel: revalidate,
		isFetching,
		isRefreshing,
		error,
	}
}

export function hotelsXHR(xhr: XHRInstance) {
	return {
		async getHotels(
			params?: Record<string, unknown>
		): Promise<ISimpleListResponse<IHotel>> {
			return xhr
				.get("/hotels", { params: { ...params, pagination: "simple" } })
				.then((resp) => resp.data)
		},
		async getHotelsWithTotal(params?: unknown): Promise<IListResponse<IHotel>> {
			return xhr.get("/hotels", { params }).then((resp) => resp.data)
		},
		async getHotelStarCategories(params?: unknown) {
			return xhr
				.get<{ data: Array<string> }>("/hotel-star-categories", { params })
				.then((resp) => resp.data.data.map((d) => ({ id: d, name: d })))
		},
		async getHotelLocations(params?: unknown) {
			return xhr
				.get<
					ISimpleListResponse<THotelLocation>
				>("/hotel-locations", { params })
				.then((resp) => resp.data.data)
		},
		async getHotelContacts(params?: unknown) {
			return xhr
				.get<ISimpleListResponse<IContact>>("/contacts", { params })
				.then((resp) => resp.data.data)
		},
		async createContact(
			id: string | number,
			contactData: object
		): Promise<IContact> {
			return xhr
				.post(`/hotel-contacts`, {
					hotel_id: id,
					...contactData,
				})
				.then((resp) => resp.data.data)
		},
	}
}
