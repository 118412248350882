import {
	Badge,
	Box,
	Button,
	Inline,
	Heading,
	Text,
	Icons,
	Stack,
	Container,
	joinAttributes,
	Spinner,
	Component,
	Money,
	Table,
	Divider,
	Time,
	Alert,
	TableFooterDataCell,
} from "@sembark-travel/ui/base"
import {
	ConfirmationDialog,
	ConfirmationDialogProvider,
	Dialog,
	useDialog,
} from "@sembark-travel/ui/dialog"
import {
	queryToSearch,
	useGetBackUrlFromLocation,
	Breadcrumbs,
	Link,
} from "@sembark-travel/ui/router"
import { Markdown } from "@sembark-travel/ui/markdown"
import {
	dateToQuery,
	getDiff,
	getDiffBetweenLocalAndUtcTimestamp,
	localOrUtcTimestampToLocalDate,
	formatDate,
} from "@sembark-travel/datetime-utils"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import React, { useCallback, useState } from "react"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath, joinPaths } from "../router-utils"
import { type TTravelActiviytBooking } from "./store"
import useSWR from "swr"
import { useXHR } from "@sembark-travel/xhr"
import { ITrip } from "../Trips"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { EditTravelActivityBookingItemInDialog } from "./EditItem"

function useBookingDetails(bookingId: number | string) {
	const xhr = useXHR()
	const { data, mutate } = useSWR(
		`/travel-activity-bookings/${bookingId}`,
		() =>
			xhr
				.get<{
					data: TTravelActiviytBooking & {
						trip: ITrip
					}
				}>(`/travel-activity-bookings/${bookingId}`)
				.then((resp) => resp.data)
	)
	return { data, mutate }
}

function useDeleteBooking(bookingId: number | string) {
	const xhr = useXHR()
	return async function () {
		return xhr
			.delete<{ message: string }>(`/travel-activity-bookings/${bookingId}`)
			.then((resp) => resp.data)
	}
}

export function TravelActivityBookingItem({
	bookingId: scheduleId,
}: {
	bookingId: string
}) {
	const { data, mutate: revalidate } = useBookingDetails(scheduleId)
	const backUrl =
		useGetBackUrlFromLocation() ||
		generatePath("/cab-schedules") +
			queryToSearch({
				date: data
					? dateToQuery(
							localOrUtcTimestampToLocalDate(
								data.data.start_date_local,
								data.data.start_date
							)
						)
					: undefined,
			})
	if (!data) return <Spinner padding="4" alignCenter />
	const { id, activity } = data.data
	return (
		<Box key={id}>
			<Breadcrumbs
				title="Travel Activity Booking Details"
				items={[
					[backUrl, "Travel Activity Bookings"],
					["", activity.name],
				]}
			/>
			<Container paddingY="6" bgColor="default">
				<TravelActivityBookingDetails
					booking={data.data}
					onRefresh={revalidate}
				/>
			</Container>
		</Box>
	)
}

export function TravelActivityBookingDetails({
	booking: propBooking,
	disableEdit,
	onRefresh,
	actions = null,
	onDeletion,
	onShare,
}: {
	booking: TTravelActiviytBooking
	disableEdit?: boolean
	onRefresh?: () => void
	actions?: React.ReactNode
	onDeletion?: () => void
	onShare?: () => void
}) {
	const { data, mutate } = useBookingDetails(propBooking.id)
	const revalidate = useCallback(() => {
		mutate()
		onRefresh?.()
	}, [mutate, onRefresh])
	if (!data) return <Spinner padding="4" alignCenter />
	const { data: booking } = data
	const {
		start_date,
		start_date_local,
		activity,
		ticket_type,
		ticket_tourist_configurations,
		trip,
		itinerary,
		supplier,
		supplier_contact,
		confirmation_details,
		remarks,
		comments,
		is_booked,
		confirmed_at,
		confirmed_by,
		currency,
		booked_price,
		duration_formatted,
		start_time_formatted,
		end_time_formatted,
		can_modify,
	} = booking
	disableEdit = disableEdit || !can_modify
	const timezoneDiff = getDiffBetweenLocalAndUtcTimestamp(
		trip.start_date_local,
		trip.start_date
	)
	return (
		<Stack gap="6">
			{timezoneDiff ? (
				<Alert status="warning" title="Trip details in Different Timezone">
					The Timezone of this trip differs from your local timezone by{" "}
					{timezoneDiff} mins. Please avoid modifying details of this trip.
				</Alert>
			) : null}
			<Inline
				collapseBelow="md"
				gap="6"
				justifyContent="between"
				flexWrap="wrap"
			>
				<Stack gap="2" flex="1">
					<Stack gap="px">
						<Heading as="h3">
							{activity.name}
							{ticket_type ? ` - ${ticket_type.name}` : null}
						</Heading>
						{itinerary?.description ? (
							<Component initialState={false}>
								{({ state, setState }) => (
									<Stack gap="1">
										<Box>
											<Button
												title={itinerary.name}
												inline
												onClick={() => setState(!state)}
											>
												<Text
													as="span"
													fontWeight="semibold"
													color="primary"
													fontSize="sm"
												>
													Itinerary
												</Text>
												<Text as="span">
													<Icons.ChevronDown
														rotate={state ? "180" : "270"}
														transition="1"
													/>
												</Text>
											</Button>
										</Box>
										{state ? (
											<Stack
												color="muted"
												fontSize="sm"
												gap="1"
												backgroundColor={"subtle"}
												borderWidth={"1"}
												padding="2"
												rounded="md"
											>
												<Markdown>{itinerary.description}</Markdown>
											</Stack>
										) : null}
									</Stack>
								)}
							</Component>
						) : null}
					</Stack>
					<Inline gap="2">
						<Box>
							<Icons.Calendar color="muted" size="6" />
						</Box>
						<Stack gap="1">
							<Inline gap="2">
								<Box fontSize="md" fontWeight="semibold">
									{formatDate(
										localOrUtcTimestampToLocalDate(
											start_date_local,
											start_date
										),
										"ddd Do MMM, YYYY"
									)}
								</Box>
								<Box>
									<Badge primary outlined>
										{withOrdinalSuffix(
											getDiff(
												localOrUtcTimestampToLocalDate(
													start_date_local,
													start_date
												),
												localOrUtcTimestampToLocalDate(
													trip.start_date_local,
													trip.start_date
												),
												"days"
											) + 1
										)}{" "}
										Day
									</Badge>
								</Box>
							</Inline>
							{start_time_formatted || duration_formatted ? (
								<Text fontSize="sm">
									{joinAttributes(
										start_time_formatted
											? `Starts: ${start_time_formatted}`
											: null,
										duration_formatted
											? `Duration: ${duration_formatted}${end_time_formatted ? ` (Ends: ${end_time_formatted})` : ``}`
											: null
									)}
								</Text>
							) : null}
						</Stack>
					</Inline>
				</Stack>
				<Inline gap="4" flex="1" flexWrap="wrap">
					<Inline gap="2" flex="1">
						<Box>
							<Icons.User color="muted" size="6" />
						</Box>
						<Stack gap="1">
							<Inline flexWrap="wrap" gap="2" alignItems="center">
								{trip.tourist ? (
									<Text fontWeight="semibold" fontSize="md">
										{trip.tourist.name}
									</Text>
								) : (
									<Text color="warning" fontSize="sm">
										<Icons.Attention /> Guest details missing!
									</Text>
								)}
								<Box color="muted" fontSize="sm">
									{joinAttributes(
										trip.tourist?.phone_numbers?.length ? (
											<PhoneNumber
												value={trip.contact.phone_numbers}
												iconOnly
											/>
										) : null,
										trip.tourist?.email ? (
											<Email value={trip.contact.email} iconOnly />
										) : null,
										`${trip.no_of_adults}A${
											trip.children.length ? `, ${trip.children.length}C` : ``
										}`
									)}
								</Box>
							</Inline>
							<Box color="muted">
								{joinAttributes(
									<Link
										to={joinPaths(
											generatePath("/trips/:tripId/services-bookings", {
												tripId: trip.id.toString(),
											}) + `/cabs`
										)}
										color="accent"
									>
										Trip {trip.id}
									</Link>,
									trip.trip_source.short_name,
									trip.trip_source_contact ? (
										<PhoneNumber
											value={trip.trip_source_contact.phone_numbers}
											iconOnly
										/>
									) : null
								)}
							</Box>
							{trip.due_payments_count ===
							undefined ? null : trip.due_payments_count > 0 ? (
								<Box>
									<Badge warning>Payments Due</Badge>
								</Box>
							) : (
								<Box>
									<Badge success title="Payments Received">
										Payments Clear
									</Badge>
								</Box>
							)}
						</Stack>
					</Inline>
					<Stack gap="1" flex="1">
						<Text fontSize="sm" color="muted" fontWeight="semibold">
							Operations Team
						</Text>
						{trip.operations_team?.length ? (
							<Stack gap="1">
								{trip.operations_team.map((t) => (
									<Text key={t.id}>{t.name}</Text>
								))}
							</Stack>
						) : (
							<Text fontSize="xs">N/A</Text>
						)}
					</Stack>
				</Inline>
			</Inline>
			<Inline flexWrap="wrap" collapseBelow="sm" gap="4">
				<Heading fontSize="md">Ticket Configurations</Heading>
				<Box flex="1">
					<Table
						headers={["Type", "Qty.", "Rate", "Total"]}
						alignCols={{ 1: "center", 2: "right", 3: "right" }}
						bordered
						responsive
						rows={ticket_tourist_configurations.map(
							({
								configuration,
								quantity,
								currency,
								per_quantity_booked_price,
								booked_price,
							}) => [
								configuration.name,
								quantity,
								<Money
									currency={currency}
									amount={per_quantity_booked_price || 0}
								/>,
								<Money
									currency={currency}
									amount={booked_price || 0}
									showCurrency
								/>,
							]
						)}
					>
						<tfoot>
							<tr>
								<TableFooterDataCell
									colSpan={3}
									textAlign={"right"}
									fontWeight="semibold"
								>
									Total:
								</TableFooterDataCell>
								<TableFooterDataCell textAlign={"right"} fontWeight="semibold">
									<Money
										currency={currency}
										amount={booked_price}
										showCurrency
									/>
								</TableFooterDataCell>
							</tr>
						</tfoot>
					</Table>
				</Box>
			</Inline>
			<Divider marginY="0" />
			<Inline gap="6" flexWrap={"wrap"} collapseBelow="sm">
				<Stack gap="1" flex="1">
					<Text color="muted" fontSize="sm" fontWeight="semibold">
						Booking Status
					</Text>
					{is_booked ? (
						<Stack gap="2">
							<Box>
								<Badge success>Booked</Badge>
							</Box>
							{confirmed_by || confirmed_at ? (
								<Stack gap="px">
									{confirmed_by ? (
										<Text fontSize="sm">{confirmed_by.name}</Text>
									) : null}
									{confirmed_at ? (
										<Text>
											<Time
												fontSize="sm"
												color="muted"
												timestamp={confirmed_at}
											/>
										</Text>
									) : null}
								</Stack>
							) : null}
						</Stack>
					) : (
						<Box>
							<Badge>Pending</Badge>
						</Box>
					)}
				</Stack>
				{supplier ? (
					<Stack gap="1" flex="1">
						<Text color="muted" fontSize="sm" fontWeight="semibold">
							Supplier Details
						</Text>
						<Box fontWeight="semibold">
							<Link
								color="accent"
								fontSize="md"
								to={generatePath(
									"/transport-service-providers/:transportServiceProviderId",
									{
										transportServiceProviderId: supplier.id.toString(),
									}
								)}
							>
								{supplier.name}
							</Link>
						</Box>
						{supplier.contacts?.length ? (
							<Inline gap="2">
								{supplier.contacts.map((contact) => (
									<Box fontSize="sm" key={contact.id}>
										{joinAttributes(
											contact.phone_numbers?.length ? (
												<PhoneNumber value={contact.phone_numbers} iconOnly />
											) : null,
											contact.email ? (
												<Email value={contact.email} iconOnly />
											) : null
										)}
									</Box>
								))}
							</Inline>
						) : null}
					</Stack>
				) : null}
				{supplier_contact ? (
					<Stack gap="1" flex="1">
						<Text color="muted" fontSize="sm" fontWeight="semibold">
							Supplier Contact
						</Text>
						<Box fontWeight="semibold">{supplier_contact.name}</Box>
						{supplier_contact.phone_numbers?.length ||
						supplier_contact.email ? (
							<Box fontSize="sm">
								{joinAttributes(
									supplier_contact.phone_numbers?.length ? (
										<PhoneNumber value={supplier_contact.phone_numbers} />
									) : null,
									supplier_contact.email ? (
										<Email value={supplier_contact.email} iconOnly />
									) : null
								)}
							</Box>
						) : null}
					</Stack>
				) : null}
			</Inline>
			<Divider marginY="0" />
			<Inline gap="6" collapseBelow="md">
				<Stack gap="1" flex="1">
					<Text color="muted" fontSize="sm" fontWeight="semibold">
						Confirmation Details
					</Text>
					{confirmation_details ? (
						<Box
							whiteSpace="preserveAndWrap"
							borderWidth="1"
							bgColor="subtle"
							padding="2"
							rounded="md"
						>
							{confirmation_details}
						</Box>
					) : is_booked ? (
						<Alert status="warning" inline>
							Missing confirmation details
						</Alert>
					) : (
						<Text color="muted">N/A</Text>
					)}
				</Stack>
				<Stack gap="1" flex="1">
					<Text color="muted" fontSize="sm" fontWeight="semibold">
						Remarks for Supplier and Customer
					</Text>
					{remarks ? (
						<Box
							whiteSpace="preserveAndWrap"
							borderWidth="1"
							bgColor="subtle"
							padding="2"
							rounded="md"
						>
							{remarks}
						</Box>
					) : (
						<Text color="muted">N/A</Text>
					)}
				</Stack>
				<Stack gap="1" flex="1">
					<Text color="muted" fontSize="sm" fontWeight="semibold">
						Internal Comments
					</Text>
					{comments ? (
						<Box
							whiteSpace="preserveAndWrap"
							borderWidth="1"
							bgColor="subtle"
							padding="2"
							rounded="md"
						>
							{comments}
						</Box>
					) : (
						<Text color="muted">N/A</Text>
					)}
				</Stack>
			</Inline>
			<Divider marginY="0" />
			<Inline gap="4" justifyContent="between" flexWrap="wrap">
				<Inline gap="4">
					{!disableEdit ? (
						<EditTravelActivityBookingItemInDialog
							bookingId={booking.id}
							onSuccess={() => revalidate()}
						>
							{({ onEdit }) => (
								<Button onClick={() => onEdit()}>
									<Icons.Pencil /> Edit
								</Button>
							)}
						</EditTravelActivityBookingItemInDialog>
					) : null}
					{onShare ? (
						<Button onClick={() => onShare()}>
							<Icons.Share /> Share
						</Button>
					) : null}
				</Inline>
				{onDeletion && !disableEdit && !is_booked ? (
					<Box>
						<DeleteTravelActivityBookingInDialog
							id={propBooking.id}
							onSuccess={onDeletion}
						>
							{({ onDelete, isDeleting }) => (
								<Button
									status="warning"
									disabled={isDeleting}
									onClick={() => {
										onDelete()
									}}
								>
									{isDeleting ? (
										"Deleting..."
									) : (
										<>
											<Icons.Trash /> Delete
										</>
									)}
								</Button>
							)}
						</DeleteTravelActivityBookingInDialog>
					</Box>
				) : null}
				{actions}
			</Inline>
		</Stack>
	)
}

export function DeleteTravelActivityBookingInDialog({
	id,
	onSuccess,
	children,
}: {
	id: number
	onSuccess?: () => void
	children: (props: {
		onDelete: () => void
		isDeleting: boolean
	}) => React.ReactNode
}) {
	const deleteSchedule = useDeleteBooking(id)
	const [isDeleting, setIsDeleting] = useState(false)
	return (
		<ConfirmationDialogProvider>
			{({ confirm }) => (
				<>
					{children({
						isDeleting,
						onDelete: async () => {
							if (isDeleting) return
							if (await confirm()) {
								setIsDeleting(true)
								deleteSchedule()
									.then(({ message }) => {
										showSnackbar(message)
										onSuccess?.()
									})
									.catch((error) => {
										alert(error.message || "Something went wrong")
									})
									.then(() => {
										setIsDeleting(false)
									})
							}
						},
					})}
					<ConfirmationDialog
						title="Confirmation"
						destructiveAction
						acceptActionLabel="I understand, Delete It"
					>
						<Stack gap="2">
							<Text fontWeight="semibold" color="warning">
								<Icons.Attention /> This action can not be reversed.
							</Text>
							<Text>
								Are you sure you want to delete this Travel Activity Booking ?
							</Text>
						</Stack>
					</ConfirmationDialog>
				</>
			)}
		</ConfirmationDialogProvider>
	)
}

export function TravelActivityBookingDetailsInDialog({
	children,
	...props
}: React.ComponentProps<typeof TravelActivityBookingDetails> & {
	children: (props: { onShow: () => void }) => React.ReactNode
}) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ onShow: open })}
			{isOpen ? (
				<Dialog open onClose={close} title="Travel Activity Booking Details" lg>
					<Dialog.Body paddingX="4">
						<TravelActivityBookingDetails
							{...props}
							onDeletion={() => {
								close()
								props.onRefresh?.()
							}}
						/>
					</Dialog.Body>
				</Dialog>
			) : null}
		</>
	)
}
