import {
	Badge,
	Box,
	Button,
	Inline,
	Icons,
	Table,
	Stack,
	TableDataCell,
	Col,
	Grid,
	Text,
	Time,
	Money,
	RelativeTime,
} from "@sembark-travel/ui/base"
import {
	fromNow,
	toISOString,
	isBefore,
	isBetween,
	startOf,
	endOf,
	addUnit,
	isAfter,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { Fragment, useMemo } from "react"
import { LogTransaction } from "./LogTransaction"
import { IPayment } from "./store"
import { UpdatePayment } from "./UpdateItem"
import { InstalmentItemInDialog, VerifyPaymentInDialog } from "./InstalmentItem"
import { AddCommentInDialog, CommentableCommentsList } from "../Comments"
import { Link } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"

function DueInstalmentStatus({
	dueDate,
	dueDateLocal,
}: {
	dueDate: string
	dueDateLocal?: string
}) {
	const due_date = localOrUtcTimestampToLocalDate(dueDateLocal, dueDate)
	const today = new Date()
	const dueDateString = toISOString(due_date)
	const state = useMemo(() => {
		let state: "Due" | "Overdue" = "Due"
		const today = new Date()
		if (isBefore(dueDateString, today)) {
			state = "Overdue"
		}
		return state
	}, [dueDateString])
	// attention is required if the due date is within next two days
	const requiresAttention = useMemo(() => {
		const today = new Date()
		return isBetween(
			dueDateString,
			startOf(today, "day"),
			addUnit(endOf(today, "day"), 2, "days")
		)
	}, [dueDateString])

	// no need to show status if the due date is after 7 days from now
	if (
		state === "Due" &&
		isAfter(due_date, addUnit(startOf(today, "day"), 7, "days"))
	) {
		return null
	}

	return (
		<Badge
			danger={state === "Overdue" || (state === "Due" && requiresAttention)}
			warning={state === "Due"}
		>
			{state} {isBefore(due_date, today) ? "by" : "in"}{" "}
			{fromNow(due_date, true)}
		</Badge>
	)
}

export function PaymentDetails({
	payment,
	onChange,
	readOnlyAmount,
	disableLogging,
}: {
	payment: IPayment
	onChange?: () => void
	readOnlyAmount?: boolean
	disableLogging?: boolean
}) {
	const {
		amount,
		comments,
		created_by,
		created_at,
		updated_by,
		updated_at,
		paid_amount,
		is_refund,
		currency,
	} = payment
	const totalPaidAmount = paid_amount
	return (
		<Box id={`${payment.id}payment`}>
			<Grid gap="6">
				<Col sm={12} md={4} lg={3}>
					{is_refund ? <Badge warning>Refund</Badge> : null}
					<Box marginY="2">
						<Text fontSize="md" fontWeight="semibold">
							{currency}
						</Text>
						<Box>
							<Box
								display="inline"
								fontSize="3xl"
								fontWeight="semibold"
								color="success"
							>
								<Box color="success" paddingRight="1" display="inline">
									+
								</Box>
								<Money amount={totalPaidAmount || 0} currency={currency} />
							</Box>
							<Box display="inline" paddingX="2" color="muted">
								/
							</Box>
							<Box display="inline" fontSize="2xl" fontWeight="semibold">
								<Money amount={amount} currency={currency} />
							</Box>
						</Box>
					</Box>
					{comments ? <blockquote>{comments}</blockquote> : null}
					<Stack fontSize="sm" color="muted" gap="1">
						<Text>
							Created by {created_by.name},{" "}
							<RelativeTime timestamp={created_at} />
						</Text>
						{updated_by && updated_at ? (
							<Text>
								Last Updated by {updated_by.name},{" "}
								<RelativeTime timestamp={updated_at} />
							</Text>
						) : null}
					</Stack>
				</Col>
				<Col>
					<Table
						headers={[
							<Box>Amount ({currency})</Box>,
							"Due Date",
							"Status",
							"Comments",
							"",
						]}
						bordered
						responsive
						rows={payment.instalments.map((instalment) => [
							<Box id={`${instalment.id}instalment`}>
								<Money
									currency={instalment.currency}
									amount={instalment.amount}
									fontSize="xl"
									fontWeight="semibold"
								/>
								{instalment.paid_at &&
								instalment.currency !== instalment.functional_currency &&
								instalment.amount_in_functional_currency_money ? (
									<Box>
										<Money
											fontSize="sm"
											money={instalment.amount_in_functional_currency_money}
											showCurrency
										/>
									</Box>
								) : null}
							</Box>,
							<Box>
								<Time
									timestamp={instalment.due_at}
									localTimestamp={instalment.due_at_local}
								/>
							</Box>,
							<Box>
								{instalment.paid_at ? (
									<Stack gap="2">
										<Box>
											<Badge success>
												Paid: <Time timestamp={instalment.paid_at} />
											</Badge>
										</Box>
										<Box fontSize="sm" title="Debit & Credit Accounts">
											{instalment.debit_accounts?.map((a, i) => (
												<Fragment key={a.id}>
													{i > 0 ? " + " : null}
													<Link
														to={generatePath(
															"/accounting/accounts/:accountId",
															{
																accountId: String(a.id),
															}
														)}
														textDecoration={{ hover: "underline" }}
													>
														{a.name}
													</Link>
												</Fragment>
											))}{" "}
											<Icons.ArrowLeft rotate="180" size="3" />{" "}
											{instalment.credit_accounts?.map((a, i) => (
												<Fragment key={a.id}>
													{i > 0 ? " + " : null}
													<Link
														to={generatePath(
															"/accounting/accounts/:accountId",
															{
																accountId: String(a.id),
															}
														)}
														textDecoration={{ hover: "underline" }}
													>
														{a.name}
													</Link>
												</Fragment>
											))}
										</Box>
									</Stack>
								) : (
									<DueInstalmentStatus
										dueDate={instalment.due_at}
										dueDateLocal={instalment.due_at_local}
									/>
								)}
							</Box>,
							<Stack gap="2">
								{instalment.comments?.length ? (
									<CommentableCommentsList
										comments={instalment.comments || []}
										onChange={() => onChange?.()}
									/>
								) : null}
								<Box>
									<AddCommentInDialog
										commentableId={instalment.id}
										commentableType="instalments"
										onChange={() => onChange?.()}
										level="tertiary"
									>
										{({ onAdd }) => (
											<Button onClick={() => onAdd()} inline>
												<Icons.Annotation opacity="50" /> Add
											</Button>
										)}
									</AddCommentInDialog>
								</Box>
							</Stack>,
							<Inline gap="2" justifyContent="end" alignItems="center">
								{instalment.can_log_payment && !disableLogging ? (
									<LogTransaction instalment={instalment} onChange={onChange}>
										{({ log }) => (
											<Button onClick={log} level="secondary" size="sm">
												Log Payment
											</Button>
										)}
									</LogTransaction>
								) : null}
								{!readOnlyAmount && instalment.can_verify_payment ? (
									<VerifyPaymentInDialog
										instalment={instalment}
										onSuccess={onChange}
									>
										{({ verify }) => (
											<Button
												status="warning"
												size="sm"
												onClick={() => verify()}
											>
												<Icons.BadgeCheckSolid /> Verify Payment
											</Button>
										)}
									</VerifyPaymentInDialog>
								) : null}
								{instalment.paid_at &&
								instalment.verified_at &&
								instalment.verified_by ? (
									<Time
										timestamp={instalment.verified_at}
										color="success"
										fontSize="sm"
										fontWeight="semibold"
									>
										<Icons.BadgeCheckSolid /> Verified by{" "}
										{instalment.verified_by.name}
									</Time>
								) : null}
								<InstalmentItemInDialog
									instalmentId={instalment.id}
									onChange={onChange}
									readOnly={readOnlyAmount}
									disableLogging={disableLogging}
								>
									{({ show }) => (
										<Button size="sm" level="tertiary" onClick={show}>
											<Icons.ArrowExpand />
										</Button>
									)}
								</InstalmentItemInDialog>
							</Inline>,
						])}
					>
						{!readOnlyAmount ? (
							<tfoot>
								<tr>
									<TableDataCell colSpan={5}>
										<UpdatePayment payment={payment} onChange={onChange} />
									</TableDataCell>
								</tr>
							</tfoot>
						) : null}
					</Table>
				</Col>
			</Grid>
		</Box>
	)
}
