import {
	dateToUTCString,
	startOf,
	endOf,
	addUnit,
	isBefore,
	duration,
	fromNow,
	utcTimestampToLocalDate,
	formatDate,
} from "@sembark-travel/datetime-utils"
import {
	Box,
	Button,
	Icons,
	Inline,
	Heading,
	Stack,
	Text,
	Col,
	Divider,
	Grid,
	Component,
	useTimeout,
	Alert,
	Table,
	TimeDuration,
	Time,
	joinAttributes,
	Money,
	RelativeTime,
} from "@sembark-travel/ui/base"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import * as Validator from "yup"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import {
	ITripSource,
	SelectTripSourceContact,
	SelectTripSources,
} from "./../TripSources"
import { ITrip } from "./store"
import {
	Form,
	Field,
	TextInputField,
	TextAreaInputField,
	SelectField,
	validateFormValues,
	withServerErrors,
	DatePickerField,
	SubmissionError,
	PhoneInputField,
	GetFieldValue,
	isTruthy,
	EmptyNumberValidator,
	PhoneNumberValidator,
	useFieldValue,
} from "@sembark-travel/ui/form"
import { useEffect, useMemo, useState } from "react"
import pluralize from "pluralize"
import { PhoneNumber, type IContact, Email } from "../Contacts"
import { type TChildrenArray, ChildrenInputField } from "./../Tourists"
import { SelectUsers } from "../Users"
import { IUser, useAuthUser } from "../Auth"
import {
	TAddressInputFieldValue,
	transformAddressToStorableValue,
} from "./../Addresses"
import { AddressInputField } from "../Addresses"

function XHR(xhr: XHRInstance) {
	return {
		async createTrip(data: unknown): Promise<ITrip> {
			return xhr.post("/trips", data).then((resp) => resp.data.data)
		},
	}
}

export function AddNewTrip({
	onSuccess,
	...props
}: Omit<React.ComponentProps<typeof TripDetailsForm>, "onSubmit"> & {
	onSuccess: (trip: ITrip) => void
}) {
	const xhr = useXHR()
	return (
		<TripDetailsForm
			showTeam
			{...props}
			onSubmit={async (data) => {
				const trip = await XHR(xhr).createTrip(data)
				// ignore the exception
				await Promise.resolve(onSuccess(trip)).catch(() => undefined)
			}}
		/>
	)
}

const validationSchema = Validator.object().shape({
	trip_id: Validator.string(),
	start_date: Validator.date().required("Start date is required"),
	no_of_days: EmptyNumberValidator()
		.positive("Number of days should be a positive integer e.g. 1, 2, 3 etc")
		.integer("Number of days should be a positive integer e.g. 1, 2, 3 etc.")
		.required("Number of days is required"),
	no_of_nights: EmptyNumberValidator()
		.integer("Number of nights should be an integer e.g. 0, 1, 2 etc.")
		.min(0, "Number of nights should not be negative")
		.required("Number of nights is required"),
	destinations: Validator.array().min(
		1,
		"Please select atleast one destination"
	),
	no_of_adults: EmptyNumberValidator()
		.positive("Number of adults should be a positive integer")
		.integer("Number of adults should be a positive integer")
		.required("Number of adults field is required"),
	children: Validator.array().of(
		Validator.object().shape({
			count: EmptyNumberValidator()
				.positive("Number of children should be positive integer")
				.integer("Number of children should be positive integer"),
			age: EmptyNumberValidator().positive(
				"Child age should a positive number"
			),
		})
	),
	trip_source: Validator.object().required("Query Source is required"),
	trip_source_contact: Validator.object().nullable(),
	tourist: Validator.object().when(["trip_source", "trip_source_contact"], ((
		tripSource: ITripSource | undefined,
		tripSourceContact: object | undefined,
		schema: Validator.AnyObjectSchema
	) => {
		const nameValidator = Validator.string().max(
			191,
			"Please use 191 or fewer character for name"
		)
		const emailValidator = Validator.string().email(
			"Please provide a valid email address"
		)
		const phoneValidator = Validator.array().when(
			"name",
			(
				touristName: string | undefined,
				schema: Validator.ArraySchema<Validator.AnySchema>
			) => {
				if (touristName) {
					return schema
						.of(PhoneNumberValidator("Phone Number", false))
						.min(1, "Please provide atleast one phone number")
				}
				return schema.of(PhoneNumberValidator("Phone Number", true))
			}
		)
		return schema.shape({
			name: tripSourceContact
				? nameValidator.nullable()
				: nameValidator.required(
						tripSource && isTruthy(tripSource.is_agent)
							? "Please provide guest details or select/add a contact from query source"
							: "Please provide the guest details"
					),
			email: emailValidator,
			phone_numbers: phoneValidator,
		})
	}) as never),
	comments: Validator.string().nullable(),
})

const validate = validateFormValues(validationSchema)

interface NewItemSchema {
	reference_id?: string
	start_date?: Date
	no_of_days: number
	no_of_nights: number
	destinations: Array<TTripDestination>
	no_of_adults: number
	trip_source?: ITripSource
	children: TChildrenArray
	comments?: string
	trip_source_contact?: IContact
	sales_team?: IUser[]
	tourist?: {
		name?: string
		email?: string
		phone_numbers: Array<{
			number: string
			country_code?: string
			is_primary?: boolean
		}>
		address?: TAddressInputFieldValue
	}
}

const defaultInitialValues: NewItemSchema = {
	reference_id: "",
	start_date: undefined,
	no_of_days: 2,
	no_of_nights: 1,
	destinations: [],
	no_of_adults: 1,
	children: [],
	trip_source: undefined,
	comments: "",
	tourist: {
		name: "",
		email: "",
		phone_numbers: [],
		address: undefined,
	},
	trip_source_contact: undefined,
	sales_team: [],
}

export function TripDetailsForm({
	onSubmit,
	onCancel,
	initialValues: initialValuesFromProps,
	readOnlySource,
	readOnlyDates,
	readOnlyPax,
	hideComments,
	showTeam,
	showDuplicateExceptId,
}: {
	onSubmit: (data: unknown) => Promise<void>
	onCancel?: () => void
	initialValues?: Partial<NewItemSchema>
	readOnlySource?: boolean
	readOnlyDates?: boolean
	readOnlyPax?: boolean
	hideComments?: boolean
	showTeam?: boolean
	showDuplicateExceptId?: number
}) {
	const { user } = useAuthUser()
	const [initialValues] = useState<NewItemSchema>(() => {
		const payload: NewItemSchema = {
			...defaultInitialValues,
			...initialValuesFromProps,
			sales_team:
				initialValuesFromProps?.sales_team || (user ? [user] : undefined),
			tourist: initialValuesFromProps?.tourist || defaultInitialValues.tourist,
			no_of_nights: initialValuesFromProps?.no_of_days
				? initialValuesFromProps?.no_of_days - 1
				: defaultInitialValues.no_of_nights,
		}
		return payload
	})

	return (
		<Form<NewItemSchema>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(async (values) => {
				const {
					start_date,
					no_of_adults,
					no_of_days,
					children = [],
					destinations,
					trip_source,
					reference_id,
					tourist,
					trip_source_contact,
					comments,
					sales_team,
				} = values
				if (
					start_date &&
					no_of_days &&
					no_of_adults &&
					destinations &&
					destinations.length
				) {
					const data = {
						start_date: dateToUTCString(startOf(start_date, "day")),
						start_date_local: formatDate(
							startOf(start_date, "day"),
							"YYYY-MM-DD"
						),
						end_date: dateToUTCString(
							endOf(addUnit(start_date, no_of_days - 1, "day"), "day")
						),
						end_date_local: formatDate(
							endOf(addUnit(start_date, no_of_days - 1, "day"), "day"),
							"YYYY-MM-DD"
						),
						no_of_adults,
						children: children,
						destinations: destinations.map((destination) => destination.id),
						reference_id: reference_id,
						trip_source: trip_source?.id,
						trip_source_contact: trip_source_contact?.id,
						comments,
						sales_team: sales_team?.map((s) => s.id),
						tourist: tourist?.name
							? {
									name: tourist.name,
									email: tourist.email || undefined,
									phone_numbers: tourist.phone_numbers.map((p) => ({
										...p,
										is_primary: isTruthy(p.is_primary) ? 1 : 0,
									})),
									address: tourist.address
										? transformAddressToStorableValue(tourist.address)
										: null,
								}
							: null,
					}
					await onSubmit(data)
				} else {
					throw Error("Please fill the required attributes")
				}
			})}
			subscription={{
				submitting: true,
			}}
		>
			{({ submitting, handleSubmit, form }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading as="h3">
									<Icons.InboxIn size="6" /> Query Source
								</Heading>
								<Text color="muted">
									Please provide query source details i.g. query came via B2B or
									from a source.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<Grid gap="4">
									<Col xs={12} sm>
										<SelectField
											select={SelectTripSources}
											name="trip_source"
											label="Query Source"
											includeContacts
											required
											multiple={false}
											fetchOnMount={!initialValues.trip_source}
											creatable
											disabled={readOnlySource}
											onChange={(value: ITripSource | undefined) => {
												form.change("trip_source", value)
												if (value?.contacts?.length === 1) {
													form.change(
														"trip_source_contact",
														value?.contacts?.at(0)
													)
												} else {
													form.change("trip_source_contact", undefined)
												}
											}}
											onCreateSuccess={(tripSource: ITripSource) => {
												form.change("trip_source", tripSource)
												if (tripSource?.contacts?.length === 1) {
													form.change(
														"trip_source_contact",
														tripSource?.contacts?.at(0)
													)
												} else {
													form.change("trip_source_contact", undefined)
												}
											}}
										/>
									</Col>
									<GetFieldValue<ITripSource | null> name="trip_source">
										{({ value }) =>
											value && isTruthy(value.is_agent) ? (
												<Col>
													<SelectField
														tripSourceId={value.id}
														select={SelectTripSourceContact}
														name="trip_source_contact"
														label="Contact / Enquiry Person"
														required
														creatable
														disabled={readOnlySource}
														onCreateSuccess={(contact) => {
															form.change("trip_source_contact", contact)
														}}
													/>
												</Col>
											) : null
										}
									</GetFieldValue>
								</Grid>
								<Grid gap="4">
									<Col>
										<Box>
											<TextInputField
												name="reference_id"
												type="text"
												label="Reference ID"
												secondaryLabel="optional"
												placeholder="1231231"
												help="A custom id for your reference regarding the query"
												maxWidth="xs"
											/>
										</Box>
									</Col>
									{showTeam ? (
										<Col>
											<Component initialState={false}>
												{({ state, setState }) => (
													<Box>
														{!state ? (
															<Box>
																<Stack gap="1">
																	<Text fontWeight="semibold">Sales Team</Text>
																	<Box>
																		<Button
																			onClick={() => setState(true)}
																			level="secondary"
																			title="Click to Edit"
																		>
																			You <Icons.Pencil opacity="50" />
																		</Button>
																	</Box>
																</Stack>
															</Box>
														) : (
															<SelectField
																name="sales_team"
																select={SelectUsers}
																label="Sales Team"
																multiple
																autoFocus
																fetchOnMount
															/>
														)}
													</Box>
												)}
											</Component>
										</Col>
									) : null}
								</Grid>
							</Stack>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading as="h3">
									<Icons.LocationMarker size="6" /> Destination and Duration
								</Heading>
								<Text color="muted">
									Please provide basic details such as destination, duration
									etc. along with number of adults and children with ages
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<Grid gap="4">
									<Col sm={12} md="auto">
										<SelectField
											name="destinations"
											label="Destinations"
											select={SelectTripDestination}
											multiple
											fetchOnMount={!initialValues.destinations?.length}
											closeOnSelect
										/>
									</Col>
									<Col md="auto">
										<Grid gap="4">
											<Col xs="auto">
												<DatePickerField
													name="start_date"
													label="Start Date"
													required
													disabled={readOnlyDates}
													help={
														<Field<Date | undefined>
															name="start_date"
															subscription={{ value: true }}
														>
															{({ input: { value: start_date } }) =>
																start_date ? (
																	<>
																		{isBefore(
																			start_date,
																			startOf(new Date(), "day")
																		) ? (
																			<Box color="warning" as="span">
																				<Icons.Attention /> You have selected a
																				past date.
																			</Box>
																		) : null}
																	</>
																) : null
															}
														</Field>
													}
												/>
											</Col>
											<Col xs="auto">
												<TextInputField
													name="no_of_nights"
													label="No. of Nights"
													type="number"
													min={0}
													required
													disabled={readOnlyDates}
													style={{ maxWidth: "140px" }}
													onChange={(e) => {
														form.batch(() => {
															form.change(
																"no_of_nights",
																e.currentTarget.value as never as number
															)
															form.change(
																"no_of_days",
																isNaN(parseInt(e.currentTarget.value))
																	? 0
																	: parseInt(e.currentTarget.value) + 1
															)
														})
													}}
													help={
														<Field<Date | undefined>
															name="start_date"
															subscription={{ value: true }}
														>
															{({ input: { value: start_date } }) => (
																<Field<number | undefined>
																	name="no_of_days"
																	subscription={{ value: true }}
																>
																	{({ input: { value: no_of_days } }) =>
																		no_of_days ? (
																			<Stack gap="1" flexWrap="wrap">
																				<Text
																					fontWeight="semibold"
																					fontSize="base"
																				>
																					{no_of_days > 1
																						? pluralize(
																								"Night",
																								no_of_days - 1,
																								true
																							) + ", "
																						: ""}
																					{pluralize("Day", no_of_days, true)}
																				</Text>
																				{start_date &&
																				isBefore(
																					addUnit(
																						start_date,
																						no_of_days,
																						"days"
																					),
																					startOf(new Date(), "day")
																				) ? (
																					<Box color="warning" as="span">
																						<Icons.Attention /> You have
																						selected a past date.
																					</Box>
																				) : null}
																			</Stack>
																		) : null
																	}
																</Field>
															)}
														</Field>
													}
												/>
											</Col>
										</Grid>
									</Col>
								</Grid>
								<Grid gap="4">
									<Col xs="auto">
										<TextInputField
											name="no_of_adults"
											label="No. of Adults"
											type="number"
											min={1}
											max={1000}
											required
											disabled={readOnlyPax}
											style={{ maxWidth: "100px" }}
										/>
									</Col>
									<Col xs="auto">
										<GetFieldValue<
											NewItemSchema["destinations"]
										> name="destinations">
											{({ value: tripDestinations }) => (
												<ChildrenInputField
													name="children"
													disabled={readOnlyPax}
													maxAge={
														tripDestinations.length
															? Math.max(
																	...tripDestinations.map(
																		(d) => d.max_child_age || 12
																	)
																)
															: undefined
													}
												/>
											)}
										</GetFieldValue>
									</Col>
								</Grid>
							</Stack>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={4}>
							<GetFieldValue<IContact | undefined> name="trip_source_contact">
								{({ value }) => (
									<Stack gap="1">
										<Heading as="h3">
											<Icons.Identification size="6" /> Guest Details
											{value ? " (optional)" : null}
										</Heading>
										<Text color="muted">
											Please provide name and phone number(s).
										</Text>
									</Stack>
								)}
							</GetFieldValue>
						</Col>
						<Col>
							<Grid gap="4">
								<Col xs={12} sm="auto">
									<TextInputField
										name={`tourist.name`}
										label="Name"
										required
										placeholder="Anoop Rai"
										type="text"
										maxWidth="xs"
									/>
								</Col>
								<Col sm="auto">
									<PhoneInputField
										name={`tourist.phone_numbers`}
										label="Phone Number(s)"
										required
										multi
									/>
								</Col>
								<Col sm="auto">
									<Component
										initialState={
											initialValuesFromProps?.tourist?.email ? true : false
										}
									>
										{({ state, setState }) =>
											state ? (
												<TextInputField
													name={`tourist.email`}
													label="Email"
													secondaryLabel="optional"
													type="email"
													placeholder="user@domain.com"
													maxWidth="xs"
												/>
											) : (
												<Stack gap="1">
													<Box display={{ xs: "none", sm: "block" }}>
														&nbsp;
													</Box>
													<Box>
														<Button
															onClick={() => setState(true)}
															title="Click to add Email Address"
														>
															<Icons.Mail />
														</Button>
													</Box>
												</Stack>
											)
										}
									</Component>
								</Col>
								<Col sm="auto">
									<Component
										initialState={
											initialValuesFromProps?.tourist?.address ? true : false
										}
									>
										{({ state, setState }) =>
											state ? (
												<AddressInputField
													name={`tourist.address`}
													label="Origin City/State"
													onlyLocation
													hidePreviewForLocation
												/>
											) : (
												<Stack gap="1">
													<Box display={{ xs: "none", sm: "block" }}>
														&nbsp;
													</Box>
													<Box>
														<Button
															onClick={() => setState(true)}
															title="Click to add Origin City/State"
														>
															<Icons.LocationMarker />
														</Button>
													</Box>
												</Stack>
											)
										}
									</Component>
								</Col>
							</Grid>
						</Col>
					</Grid>
					<ExistingTrips exceptId={showDuplicateExceptId}>
						{({ trips }) =>
							trips ? (
								<Box paddingTop="2">
									<Alert status="warning" title="Possible Duplicate Query">
										<Stack gap="4">
											<Text>
												There are some ({trips.meta.total}) existing queries
												with above phone numbers. Please review the details
												before proceeding further.
											</Text>
											<Box color="default">
												<QueriesTableView items={trips.data} />
											</Box>
										</Stack>
									</Alert>
								</Box>
							) : null
						}
					</ExistingTrips>
					{!hideComments ? (
						<>
							<Divider />
							<Grid gap="4">
								<Col sm={12} md={4}>
									<Stack gap="1">
										<Heading as="h3">
											<Icons.Annotation size="6" /> Comments or Notes
										</Heading>
										<Box color="muted">
											Please provide any comments or notes regarding this query
											which may be useful for sales process.
										</Box>
									</Stack>
								</Col>
								<Col>
									<TextAreaInputField
										name="comments"
										label="Comments"
										secondaryLabel="optional"
										placeholder="Only 5 star hotels"
									/>
								</Col>
							</Grid>
						</>
					) : null}
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={{ offset: 4, span: 8 }}>
							<Stack gap="4" paddingLeft={{ md: "4" }}>
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Saving..." : "Save Details"}
									</Button>
									{onCancel ? (
										<Button onClick={onCancel} level="tertiary">
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}

function ExistingTrips({
	children,
	exceptId,
}: {
	children: (props: {
		trips: IListResponse<ITrip> | undefined
	}) => React.ReactNode
	exceptId?: number
}) {
	const { value } = useFieldValue<null | Array<{
		number: string
		country_code?: string
		is_primary: boolean
	}>>("tourist.phone_numbers")
	const validPhoneNumbers = useMemo(() => {
		return (value || [])
			.map(function (p) {
				return p.number
			})
			.filter(Boolean)
	}, [value])
	const { set, clear } = useTimeout()
	const xhr = useXHR()
	const [existing, setExisting] = useState<IListResponse<ITrip> | undefined>(
		undefined
	)
	useEffect(() => {
		setExisting(undefined)
		if (!validPhoneNumbers.length) {
			return () => undefined
		}
		const abortController = new AbortController()
		set(() => {
			xhr
				.get<IListResponse<ITrip>>("/existing-trips", {
					params: {
						phone_numbers: validPhoneNumbers,
						with: ["given_price"],
						except_ids: exceptId ? [exceptId] : undefined,
					},
					signal: abortController.signal,
				})
				.then((resp) => {
					if (resp.data.data.length) {
						setExisting(resp.data)
					} else {
						setExisting(undefined)
					}
				})
				.catch(() => {
					return undefined
				})
		}, 500)
		return () => {
			clear()
			abortController.abort()
		}
	}, [validPhoneNumbers, set, clear, xhr, exceptId])
	return (
		<>{children({ trips: validPhoneNumbers?.length ? existing : undefined })}</>
	)
}

function QueriesTableView({ items }: { items: Array<ITrip> }) {
	return (
		<Table
			bordered
			responsive
			hover
			headers={["ID", "Guest", "Source", "Destination", "Quote", "Team"]}
			rows={items.map((trip) => {
				const {
					destinations,
					nights: no_of_nights,
					days,
					latest_given_quote,
					given_quotes_count,
					trip_source,
					trip_source_contact,
					team,
					reference_id,
					tourist,
					no_of_adults,
					no_of_children,
					created_at,
				} = trip
				return [
					<Stack gap="1">
						<Text fontWeight="semibold">{trip.id}</Text>
						<Box fontSize="sm">
							{latest_given_quote ? (
								<Box color="primary">In Progress</Box>
							) : (
								<Box color="muted">New Query</Box>
							)}
						</Box>
					</Stack>,
					tourist ? (
						<Stack gap="px">
							<Text fontWeight="semibold">{tourist.name}</Text>
							<Box fontSize="sm" color="muted">
								{joinAttributes(
									<Text as="span">
										{no_of_adults}A
										{no_of_children ? `, ${no_of_children}C` : null}
									</Text>,
									[
										tourist.phone_numbers?.length,
										<PhoneNumber value={tourist.phone_numbers} iconOnly />,
									],
									[tourist.email, <Email value={tourist.email} iconOnly />]
								)}
							</Box>
						</Stack>
					) : null,
					<Stack gap="px">
						<Text fontWeight="semibold">{trip_source.short_name}</Text>
						{reference_id ? (
							<Text fontSize="sm">Ref# {reference_id}</Text>
						) : null}
						{trip_source_contact ? (
							<Inline alignItems="center" flexWrap="wrap" gap="2" fontSize="sm">
								<Box color="muted">{trip_source_contact.name}</Box>
								<Box display="inlineBlock">
									{joinAttributes([
										trip_source_contact.phone_numbers?.length,
										<PhoneNumber
											value={trip_source_contact.phone_numbers}
											iconOnly
										/>,
									])}
								</Box>
							</Inline>
						) : null}
					</Stack>,
					<Stack gap="px">
						<Text style={{ maxWidth: "140px" }} textOverflow="truncate">
							{destinations.map((d) => d.name).join(", ")}
						</Text>
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								<Time timestamp={trip.start_date} />,
								<TimeDuration value={duration(days, "days")}>
									{no_of_nights}N
								</TimeDuration>
							)}
						</Box>
					</Stack>,
					latest_given_quote ? (
						<Box
							title={`Latest quote ${fromNow(
								utcTimestampToLocalDate(latest_given_quote.created_at)
							)}`}
						>
							<Text>{given_quotes_count} Q</Text>
							<Money
								amount={latest_given_quote.given_price}
								currency={latest_given_quote.given_currency}
								color="muted"
								fontSize="sm"
							/>
						</Box>
					) : (
						"-"
					),
					<Stack gap="px">
						{team?.length ? (
							<Stack style={{ width: "80px" }} gap="px">
								{team.map((t) => (
									<Text
										textOverflow="truncate"
										key={t.id}
										fontSize="sm"
										color="muted"
									>
										{t.name}
									</Text>
								))}
							</Stack>
						) : null}
						<RelativeTime fontSize="sm" timestamp={created_at} />
					</Stack>,
				]
			})}
		/>
	)
}
