import {
	ListView,
	Search,
	TSearchParams,
	areAdvancedFiltersAppliedDefault,
	useSearch,
} from "@sembark-travel/ui/list"
import { tripQuoteBookingsDiffXHR } from "./store"
import {
	Badge,
	Box,
	Icons,
	Inline,
	RelativeTime,
	Stack,
	TabContent,
	TabItem,
	Table,
	Tabs,
	TabsList,
	Text,
	Time,
	TimeDuration,
	joinAttributes,
} from "@sembark-travel/ui/base"
import { Link, useLocationQuery } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"
import { useEffect } from "react"
import { duration } from "@sembark-travel/datetime-utils"
import { collect } from "../utils"

type TFilters = TSearchParams & {
	trip_status?: "all" | "on_trip" | "converted" | "past"
}

export function TripQuoteBookingsDiffList() {
	const [query, setQuery] = useLocationQuery<TFilters>()
	const [params, setParams] = useSearch<TFilters>({
		...query,
		trip_status: query.trip_status || "converted",
	})

	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])

	return (
		<Search
			initialParams={params}
			title="Trip Quote & Booking Diffs"
			onSearch={(params) =>
				setParams({
					...params,
					page: 1,
				})
			}
			areAdvancedFiltersApplied={(params) => {
				const { trip_status, ...others } = params
				return areAdvancedFiltersAppliedDefault(others)
			}}
		>
			{({ searchParams, setSearchParamValue }) => (
				<Tabs gap="4">
					<TabsList>
						<TabItem
							active={searchParams.trip_status === "converted"}
							onClick={() => setSearchParamValue("trip_status", "converted")}
						>
							Upcoming
						</TabItem>
						<TabItem
							active={searchParams.trip_status === "on_trip"}
							onClick={() => setSearchParamValue("trip_status", "on_trip")}
						>
							On Trip
						</TabItem>
						<TabItem
							active={searchParams.trip_status === "past"}
							onClick={() => setSearchParamValue("trip_status", "past")}
						>
							Past
						</TabItem>
						<TabItem
							active={searchParams.trip_status === "all"}
							onClick={() => setSearchParamValue("trip_status", "all")}
						>
							All
						</TabItem>
					</TabsList>
					<TabContent>
						<ListView
							pageKey="trip-quote-bookings-diff"
							params={params}
							fetch={(xhr, params) => tripQuoteBookingsDiffXHR(xhr).get(params)}
							onPageChange={(page) => setParams({ ...params, page })}
						>
							{({ items }) => (
								<Table
									bordered
									striped
									headers={[
										"Trip ID",
										"Details",
										"Team",
										"Reservations",
										"Operations",
										"Last Change",
									]}
									rows={items.map(({ trip, ...i }) => [
										<Link
											to={generatePath("/trips/:tripId/services-bookings", {
												tripId: i.trip_id.toString(),
											})}
											color="accent"
										>
											<Inline as="span" gap="1" alignItems="center">
												<Box>{trip.id}</Box>
												<Icons.ChevronDown rotate="270" size="3" opacity="50" />
											</Inline>
										</Link>,
										<Stack gap="px">
											<Text
												style={{ maxWidth: "140px" }}
												textOverflow="truncate"
											>
												{trip.destinations.map((d) => d.name).join(", ")}
											</Text>
											<Box fontSize="sm" color="muted">
												{joinAttributes(
													<Time value={trip.start_date} format="DD MMM" />,
													<TimeDuration value={duration(trip.days, "days")}>
														{trip.nights}N
													</TimeDuration>,
													<Text as="span">
														{trip.no_of_adults}A
														{trip.no_of_children
															? `, ${trip.no_of_children}C`
															: null}
													</Text>
												)}
											</Box>
										</Stack>,
										trip.team?.length ? (
											<Stack style={{ width: "80px" }} gap="px">
												{collect(trip.team)
													.unique((t) => t.id)
													.toArray()
													.map((t, index) => (
														<Text
															textOverflow="truncate"
															key={index}
															fontSize="sm"
															color="muted"
														>
															{t.name}
														</Text>
													))}
											</Stack>
										) : null,
										i.hotels_changed ? (
											<Badge warning>Diff</Badge>
										) : (
											<Icons.Ok color="success" />
										),
										i.cabs_changed ? (
											<Badge warning>Diff</Badge>
										) : (
											<Icons.Ok color="success" />
										),
										<RelativeTime timestamp={i.updated_at || i.created_at} />,
									])}
								/>
							)}
						</ListView>
					</TabContent>
				</Tabs>
			)}
		</Search>
	)
}
