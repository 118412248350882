import {
	Stack,
	Text,
	Box,
	Button,
	Inline,
	Icons,
	Spinner,
	Col,
	Component,
	Divider,
	Grid,
	Money,
	joinAttributes,
	Table,
	TableFooterDataCell,
	TableDataCell,
	Heading,
	Time,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import { Link } from "@sembark-travel/ui/router"
import {
	CopyToClipboardButton,
	CopyToClipboard,
} from "@sembark-travel/ui/copy-to-clipboard"
import {
	formatDate,
	getDiff,
	localOrUtcTimeToLocalDate,
	localOrUtcTimestampToLocalDate,
	utcTimeToLocalTimeString,
} from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	withServerErrors,
	TextInputField,
	TextAreaInputField,
	SubmissionError,
	SelectField,
	SwitchInputField,
	isTruthy,
} from "@sembark-travel/ui/form"
import { Fragment, useCallback, useMemo, useRef, useState } from "react"
import useSWR from "swr"
import { AddressText } from "../Addresses"
import { useAuthUser } from "../Auth"
import config from "../config"
import { IHotel, SelectHotelContact, UpdateHotel } from "../Hotels"
import { IBooking } from "./store"
import { EditHotelBookingDetailsInDialog } from "./ItemForm"
import { TTripDestination } from "../TripDestinations"
import {
	numberToLocalString,
	withOrdinalSuffix,
} from "@sembark-travel/number-utils"
import { Required } from "utility-types"
import {
	HotelBookingCurrentStage,
	UpdateBookingTagInDialog,
} from "./BookingStatus"
import { PaymentDetails } from "../Payments"
import { ActivityLogs } from "../ActivityLogs"
import {
	EmailContent,
	type TEmailInclusionOptions,
} from "../shared/EmailContent"
import { generatePath } from "../router-utils"
import { IContact, PhoneNumber } from "../Contacts"

const defaultInclusionOptions: TEmailInclusionOptions = [
	{ name: "Price", included: 1 },
	{ name: "Billing", included: 1 },
]

export function ComposeHotelBookingEmail({
	booking,
	minimal,
	tripStartDate,
	tripEndDate,
	onChange,
	tripDestinations,
	children,
}: {
	booking: IBooking
	onChange: () => void
	minimal?: boolean
	tripStartDate: Date
	tripEndDate: Date
	tripDestinations: Array<TTripDestination>
	children?: (props: { compose: () => void }) => React.ReactNode
}) {
	const { user } = useAuthUser()
	const { can_modify, booking_stage } = booking
	const xhr = useXHR()
	const [isDialogOpen, openDialog, closeDialog] = useDialog()
	const bookingId = booking.id
	const [emailSubject, setEmailSubject] = useState("")
	const [hotelContacts, setHotelContacts] = useState<
		Array<IContact> | undefined
	>(undefined)
	const fetchContent = useCallback(
		async (params: object) => {
			return xhr
				.get<{
					subject: string
					content: string
					options: Array<{ name: string; included: 1 | 0 }>
					contacts: Array<IContact>
				}>(`/hotel-bookings/${bookingId}/as-email`, {
					params,
				})
				.then((resp) => {
					setEmailSubject(resp.data.subject)
					setHotelContacts(resp.data.contacts)
					return resp.data
				})
		},
		[xhr, bookingId, setEmailSubject]
	)
	const contentCacheKey = `/hotel-bookings/${booking.id}/as-email`
	const isWindowsOs = useMemo(() => {
		try {
			if (navigator.userAgent.indexOf("Win") !== -1) {
				return true
			}
		} catch (e) {
			// Ignore
		}
		return false
	}, [])
	if (
		!user ||
		!can_modify ||
		(booking_stage.state !== "initialized" &&
			booking_stage.state !== "in_progress" &&
			booking_stage.state !== "booked")
	)
		return null
	return (
		<Fragment>
			{children ? (
				<>{children({ compose: openDialog })}</>
			) : (
				<Button
					onClick={openDialog}
					title="Compose Email"
					{...(minimal
						? {
								inline: true,
							}
						: {})}
				>
					<Icons.Mail title="Compose Email" />
				</Button>
			)}
			<Dialog
				open={isDialogOpen}
				lg
				onClose={closeDialog}
				title="Hotel Booking Email"
			>
				<Dialog.Body paddingX="0" paddingTop="0">
					<Stack gap="6">
						<Stack padding="4" bgColor="subtle" borderBottomWidth="1" gap="3">
							<Text color="primary" fontWeight="semibold" fontSize="sm">
								<Link
									color="accent"
									to={generatePath("/hotels/:hotelId", {
										hotelId: String(booking.hotel_id),
									})}
								>
									Hotel Contacts <Icons.ChevronDown rotate="270" />
								</Link>
							</Text>
							{!hotelContacts ? (
								<Inline
									style={{ height: "52px" }}
									bgColor="subtle"
									rounded="md"
									alignItems="center"
									color="muted"
								>
									Loading contacts...
								</Inline>
							) : !hotelContacts.length ? (
								<Stack
									style={{ height: "52px" }}
									bgColor="subtle"
									rounded="md"
									justifyContent="center"
									gap="2"
								>
									<Text color="muted" fontSize="sm">
										No contacts available
									</Text>
									<Text fontSize="sm">
										<Link
											color="accent"
											to={generatePath("/hotels/:hotelId", {
												hotelId: String(booking.hotel_id),
											})}
										>
											Add hotel contacts
										</Link>{" "}
										to quickly open email client with prefilled email and
										subjects.
									</Text>
								</Stack>
							) : (
								<Inline gap="6" flexWrap="nowrap" overflow="auto">
									{hotelContacts
										.filter(
											(contact): contact is Required<typeof contact, "email"> =>
												Boolean(contact.email)
										)
										.map((contact, index, allContacts) => {
											const email = contact.email
											return (
												<Stack gap="px" key={contact.id}>
													<Box fontWeight="semibold">
														{contact.name}{" "}
														<PhoneNumber
															value={contact.phone_number}
															iconOnly
														/>
													</Box>
													<CopyToClipboard>
														{({ copyTextToClipboard }) => (
															<Inline gap="2" alignItems="center">
																<CopyToClipboardButton
																	size="sm"
																	inline
																	onClick={() => copyTextToClipboard(email)}
																	level="tertiary"
																	iconOnly
																>
																	<Icons.ClipboardCopy title="Copy to Clipboard" />
																</CopyToClipboardButton>
																<Box paddingY="1">
																	<Text title={email}>
																		{email.split("@")[0]}
																		<Text as="span" color="muted">
																			@
																		</Text>
																	</Text>
																</Box>
																<Button
																	as="a"
																	size="sm"
																	href={`mailto:${email}?subject=${encodeURIComponent(
																		emailSubject
																	)}&cc=${allContacts
																		.filter((_c, i) => i !== index)
																		.map((c) => c.email)
																		.join(isWindowsOs ? ";" : ",")}`}
																	title="Open Email Client with subject auto-filled"
																>
																	<Icons.ArrowTopRightOnSquare />
																</Button>
															</Inline>
														)}
													</CopyToClipboard>
												</Stack>
											)
										})}
								</Inline>
							)}
						</Stack>
						<Box paddingX="4">
							<Text as="p" fontSize="sm" color="muted">
								<Icons.LightBulb /> Copy the subject and body to your Email
								Composer and make changes if required before sending.
							</Text>
						</Box>
						<CopyToClipboard<HTMLDivElement>>
							{({ nodeToCopy, copyNodeTextToClipboard }) => (
								<Stack paddingX="4" gap="1">
									<Inline justifyContent="between" alignItems="center">
										<Text fontWeight="semibold">Subject</Text>
										<CopyToClipboardButton
											size="sm"
											level="primary"
											onClick={async () => {
												return copyNodeTextToClipboard()
											}}
										>
											<Icons.ClipboardCopy /> Copy Subject
										</CopyToClipboardButton>
									</Inline>
									<Box
										paddingY="2"
										paddingX="4"
										borderWidth="1"
										rounded="md"
										bgColor="subtle"
									>
										<Box ref={nodeToCopy}>{emailSubject || "..."}</Box>
									</Box>
								</Stack>
							)}
						</CopyToClipboard>
						<Box>
							<Box paddingX="4">
								<Text fontWeight="semibold">Body</Text>
							</Box>
							<EmailContent
								paddingY="4"
								fetchContent={fetchContent}
								contentCacheKey={contentCacheKey}
								defaultInclusionOptions={defaultInclusionOptions}
								inclusionOptionsCacheKey="hotel_booking_enquiry_email"
								copyTrackId="hotel_booking_enquiry"
								copyLabel="Copy Email"
								actions={({ refresh }) => (
									<>
										<EditHotelBookingDetailsInDialog
											tripDestinations={tripDestinations}
											booking={booking}
											startDate={tripStartDate}
											endDate={tripEndDate}
											onSuccess={() => {
												refresh()
												onChange()
											}}
										>
											{({ edit }) => (
												<Button size="sm" level="secondary" onClick={edit}>
													<Icons.Pencil /> Edit Booking
												</Button>
											)}
										</EditHotelBookingDetailsInDialog>
									</>
								)}
							/>
						</Box>
					</Stack>
				</Dialog.Body>
				<Dialog.Footer paddingX="4">
					<Button onClick={closeDialog}>Cancel</Button>
				</Dialog.Footer>
			</Dialog>
		</Fragment>
	)
}

export function GenerateVoucher({
	hotel,
	booking,
	bookingConfirmationId = "",
	onGenerate,
	children = "Create Voucher",
	minimal,
	isShared,
}: {
	hotel: IHotel
	booking: IBooking
	isBooked: boolean
	bookingConfirmationId?: string
	onGenerate?: () => void
	children?: React.ReactNode
	minimal?: boolean
	isShared?: boolean
}) {
	const xhr = useXHR()
	const bookingId = booking.id
	return (
		<Component initialState={false}>
			{({ state, setState }) => {
				return (
					<Fragment>
						<Button
							onClick={() => setState(true)}
							title="Generate Voucher"
							{...(minimal
								? {
										level: "tertiary",
										inline: true,
									}
								: {})}
						>
							{minimal ? (
								children
							) : (
								<>
									<Icons.Document /> {children}
								</>
							)}
						</Button>
						{state ? (
							<Dialog
								open={state}
								onClose={() => setState(false)}
								sm
								title={`Generate Voucher for ${hotel.name}`}
							>
								<Form<{
									hotel_confirmation_number: string
									notes: string
									hotel_contact?: IContact
									show_prices?: 0 | 1
								}>
									initialValues={{
										hotel_confirmation_number: bookingConfirmationId,
										notes: booking.voucher?.notes || "",
										hotel_contact: booking.hotel_contact,
										show_prices: booking.voucher?.show_prices ? 1 : 0,
									}}
									onSubmit={withServerErrors(async (values) => {
										const {
											hotel_confirmation_number: booking_confirmation_id,
											notes,
											hotel_contact,
											show_prices,
										} = values
										const data = {
											booking_id: bookingId,
											timezone_offset: config.timezoneOffset,
											booking_confirmation_id,
											notes,
											hotel_contact: hotel_contact?.id,
											show_prices: isTruthy(show_prices) ? 1 : 0,
										}
										const resp = await xhr.post(`/hotel-booking-vouchers`, data)
										onGenerate && onGenerate()
										setState(false)
										return resp
									})}
									subscription={{ submitting: true }}
								>
									{({ submitting, handleSubmit }) => (
										<form noValidate onSubmit={handleSubmit}>
											<Dialog.Body>
												{isShared ? (
													<Box>This is a shared booking.</Box>
												) : (
													<Stack gap="4">
														<TextInputField
															type="text"
															name="hotel_confirmation_number"
															label="Hotel Confirmation Details"
															placeholder="e.g. No. TSK123 by Contact Person"
														/>
														<SelectField
															label="Booking Confirmed by Hotel's Contact Person"
															secondaryLabel="optional"
															select={SelectHotelContact}
															name="hotel_contact"
															hotelId={booking.hotel_id}
															creatable
															fetchOnMount
															help="Contact details will be included in the Voucher PDF"
														/>
														<TextAreaInputField
															name="notes"
															label="Voucher Notes"
															secondaryLabel="optional"
															placeholder="Example: Please pay 50% at the time of checkin"
															rows={2}
														/>
														<Divider sm />
														<SwitchInputField
															label="Include Price Bifurcation"
															name="show_prices"
															value="1"
															help="Select the checkbox to include the booking price birfucation in the generated pdf"
														/>
														<Divider sm />
														<Stack gap="4">
															<Text color="muted">
																Please verify hotel details that will be used in
																voucher. Edit if required.
															</Text>
															<Grid gap="6">
																<Col xs={12} sm="auto">
																	<Box>
																		<Box
																			textTransform="uppercase"
																			letterSpacing="wider"
																			marginBottom="1"
																			fontWeight="semibold"
																			color="muted"
																		>
																			Hotel Address
																		</Box>
																		<Box>
																			<AddressText address={hotel.address} />
																		</Box>
																	</Box>
																</Col>
																<Col xs={12} sm="auto">
																	<Box>
																		<Box
																			textTransform="uppercase"
																			letterSpacing="wider"
																			marginBottom="1"
																			fontWeight="semibold"
																			color="muted"
																		>
																			Checkin Time
																		</Box>
																		<Time
																			format="HH:mm"
																			value={localOrUtcTimeToLocalDate(
																				hotel.checkin_at_local,
																				hotel.checkin_at
																			)}
																		/>
																	</Box>
																</Col>
																<Col xs={12} sm="auto">
																	<Box>
																		<Box
																			textTransform="uppercase"
																			letterSpacing="wider"
																			marginBottom="1"
																			fontWeight="semibold"
																			color="muted"
																		>
																			Checkout Time
																		</Box>
																		<Time
																			format="HH:mm"
																			value={localOrUtcTimeToLocalDate(
																				hotel.checkout_at_local,
																				hotel.checkout_at
																			)}
																		/>
																	</Box>
																</Col>
															</Grid>
															<Component initialState={false}>
																{({ state, setState }) => (
																	<Box>
																		<Button onClick={() => setState(true)}>
																			Edit Hotel details
																		</Button>
																		<Dialog
																			open={state}
																			onClose={() => setState(false)}
																			lg
																			title="Edit Hotel Details"
																		>
																			<Dialog.Body>
																				<UpdateHotel
																					hotel={hotel}
																					showFullAddress
																					onCancel={() => setState(false)}
																					onSuccess={() => {
																						onGenerate && onGenerate()
																						setState(false)
																					}}
																				/>
																			</Dialog.Body>
																		</Dialog>
																	</Box>
																)}
															</Component>
														</Stack>
													</Stack>
												)}
												<SubmissionError />
											</Dialog.Body>
											<Dialog.Footer>
												<Button type="submit" disabled={submitting}>
													{submitting ? "Generating..." : "Generate Voucher"}
												</Button>
												<Button
													level="secondary"
													onClick={() => setState(false)}
													disabled={submitting}
												>
													Close
												</Button>
											</Dialog.Footer>
										</form>
									)}
								</Form>
							</Dialog>
						) : null}
					</Fragment>
				)
			}}
		</Component>
	)
}

export function HotelBookingItem({
	hotelBookingId,
}: {
	hotelBookingId: number | string
	onChange: () => void
}) {
	const xhr = useXHR()
	const { data: booking, mutate } = useSWR<Required<IBooking, "trip">>(
		`/hotel-bookings/${hotelBookingId}`,
		() =>
			xhr
				.get(`/hotel-bookings/${hotelBookingId}`)
				.then((resp) => resp.data.data)
	)
	if (!booking) {
		return <Spinner padding="4" alignCenter />
	}
	const { can_view_prices, trip, can_modify } = booking
	const destinations = trip.destinations
	const tripStartDate = localOrUtcTimestampToLocalDate(
		trip.start_date_local,
		trip.start_date
	)
	const tripEndDate = localOrUtcTimestampToLocalDate(
		trip.end_date_local,
		trip.end_date
	)
	const nightsRelativeTo: Date = localOrUtcTimestampToLocalDate(
		booking.checkin_local,
		booking.checkin
	)
	return (
		<Stack gap="4">
			<Stack gap="8">
				<Inline gap="8" flexWrap="wrap">
					<TitleValue title="Hotel">
						<Link
							fontWeight="semibold"
							color="accent"
							to={generatePath("/hotels/:hotelId", {
								hotelId: booking.hotel.id.toString(),
							})}
						>
							{booking.hotel.name}
						</Link>
						<Text fontSize="sm">
							{joinAttributes(
								booking.hotel.address?.location?.name,
								booking.hotel.stars_string
							)}
						</Text>
					</TitleValue>
					<TitleValue title="Checkin">
						<Time
							timestamp={booking.checkin}
							localTimestamp={booking.checkin_local}
						/>
					</TitleValue>
					<TitleValue title="Checkout">
						<Time
							timestamp={booking.checkout}
							localTimestamp={booking.checkout_local}
						/>{" "}
						({booking.no_of_nights}N)
					</TitleValue>
					<TitleValue title="Status">
						<HotelBookingCurrentStage
							tripDestinations={destinations}
							booking={booking}
							tripStartDate={tripStartDate}
							tripEndDate={tripEndDate}
							onChangeSuccess={() => mutate()}
							minimal
						/>
					</TitleValue>
					<TitleValue
						title={
							<Inline gap="2" alignItems="center">
								<Text>Tag</Text>
								<UpdateBookingTagInDialog
									booking={booking}
									onSuccess={() => mutate()}
								>
									{({ onEdit }) => (
										<Button onClick={() => onEdit()} inline>
											<Icons.Pencil />
										</Button>
									)}
								</UpdateBookingTagInDialog>
							</Inline>
						}
					>
						<Text>{booking.booking_stage.tag || null}</Text>
					</TitleValue>
					{booking.booked ? (
						<TitleValue title="Confirmation Details">
							{booking.booking_confirmation_id || "N/A"}
						</TitleValue>
					) : null}
					{booking.hotel_contact ? (
						<TitleValue title="Confirmed By">
							{booking.hotel_contact?.name}{" "}
							<PhoneNumber
								value={booking.hotel_contact.phone_number}
								iconOnly
							/>
						</TitleValue>
					) : null}
				</Inline>

				{booking.details && booking.details.length ? (
					<Table
						headers={["Nights", "Meal", "Accommodation"].concat(
							can_view_prices
								? [`Price Breakups`, `Total (${booking.currency})`]
								: []
						)}
						responsive
						bordered
						alignCols={{ 4: "right" }}
						rows={booking.details.map((bookingDetail) => {
							const {
								meal_plan,
								room_type,
								no_of_rooms,
								adults_with_extra_bed,
								children_with_extra_bed,
								children_without_extra_bed,
								per_room_booked_price,
								per_adult_with_extra_bed_booked_price,
								per_child_with_extra_bed_booked_price,
								per_child_without_extra_bed_booked_price,
								room_configuration,
								booked_price,
								given_price,
								currency,
							} = bookingDetail
							const date = localOrUtcTimestampToLocalDate(
								bookingDetail.date_local,
								bookingDetail.date
							)
							const night = getDiff(date, nightsRelativeTo, "days") + 1
							return [
								<Stack key={`${bookingDetail.id}`} gap="1">
									<Text>{withOrdinalSuffix(night)} Night</Text>
									<Text fontSize="sm" color="muted">
										{formatDate(date, "DD, MMM")}
									</Text>
								</Stack>,
								<Text>{meal_plan.name}</Text>,
								<Box display="inline">
									<Stack gap="1">
										<Text>
											{no_of_rooms} {room_type.name}
										</Text>
										<Text fontSize="sm" whiteSpace="preserveLine" color="muted">
											({room_configuration?.description?.replace(/\+/g, "\n+ ")}
											)
										</Text>
									</Stack>
								</Box>,
							].concat(
								can_view_prices
									? [
											per_room_booked_price ? (
												<Stack fontSize="sm">
													<Inline>
														<Text>
															{numberToLocalString(per_room_booked_price)}
														</Text>
														<Box paddingX="1">/-</Box>
														<Text>
															Per Room {room_configuration?.per_room_details}
														</Text>
													</Inline>
													{adults_with_extra_bed ? (
														<Inline>
															<Text>
																{numberToLocalString(
																	per_adult_with_extra_bed_booked_price
																)}
															</Text>
															<Box paddingX="1">/-</Box>
															<Text>
																Per Adult with Extra Bed{" "}
																{
																	room_configuration?.per_adult_with_extra_bed_details
																}
															</Text>
														</Inline>
													) : null}
													{children_with_extra_bed ? (
														<Inline>
															<Text>
																{numberToLocalString(
																	per_child_with_extra_bed_booked_price
																)}
															</Text>
															<Box paddingX="1">/-</Box>
															<Text>
																Per Child with Extra Bed{" "}
																{
																	room_configuration?.per_child_with_extra_bed_details
																}
															</Text>
														</Inline>
													) : null}
													{children_without_extra_bed ? (
														<Inline>
															<Text>
																{numberToLocalString(
																	per_child_without_extra_bed_booked_price
																)}
															</Text>
															<Box paddingX="1">/-</Box>
															<Text>
																Per Child without Extra Bed{" "}
																{
																	room_configuration?.per_child_without_extra_bed_details
																}
															</Text>
														</Inline>
													) : null}
												</Stack>
											) : (
												<></>
											),
											<Box>
												<Money amount={booked_price} currency={currency} />
												<Box fontSize={"sm"} color="muted">
													<Text as="span">/ </Text>
													{given_price && Number(given_price) > 0 ? (
														<Money
															amount={given_price || 0}
															currency={currency}
														/>
													) : (
														<Text as="span">N/A</Text>
													)}
												</Box>
											</Box>,
										]
									: []
							)
						})}
					>
						{booking.extras && booking.extras.length ? (
							<tbody>
								<tr>
									<TableDataCell
										colSpan={5}
										fontWeight="semibold"
										bgColor="subtle"
									>
										Extra Services
									</TableDataCell>
								</tr>
								{booking.extras.map((extra) => {
									const { date, date_local, service, currency, booked_price } =
										extra
									const night = date
										? getDiff(
												localOrUtcTimestampToLocalDate(date_local, date),
												localOrUtcTimestampToLocalDate(
													booking.checkin_local,
													booking.checkin
												),
												"days"
											) + 1
										: undefined
									return (
										<tr key={`${extra.id}`}>
											<TableDataCell>
												{night ? (
													<Text>{withOrdinalSuffix(night)} Night</Text>
												) : null}
											</TableDataCell>
											<TableDataCell colSpan={3}>{service.name}</TableDataCell>
											<TableDataCell textAlign="right">
												{booked_price && can_view_prices ? (
													<Money currency={currency} amount={booked_price} />
												) : null}
											</TableDataCell>
										</tr>
									)
								})}
							</tbody>
						) : null}
						{can_view_prices ? (
							<tfoot>
								<tr>
									<TableFooterDataCell colSpan={4} textAlign="right">
										Total
									</TableFooterDataCell>
									<TableFooterDataCell textAlign="right">
										<Money
											amount={booking.booked_price || 0}
											currency={booking.currency}
											showCurrency
										/>
									</TableFooterDataCell>
								</tr>
							</tfoot>
						) : null}
					</Table>
				) : null}
				{can_modify ? (
					<Inline gap="4">
						<EditHotelBookingDetailsInDialog
							tripDestinations={destinations}
							booking={booking}
							startDate={tripStartDate}
							endDate={tripEndDate}
							onSuccess={() => {
								mutate()
							}}
						>
							{({ edit }) => (
								<Button onClick={edit} size="sm" status="primary">
									<Icons.Pencil /> Edit Details
								</Button>
							)}
						</EditHotelBookingDetailsInDialog>
						<ComposeHotelBookingEmail
							tripDestinations={destinations}
							booking={booking}
							tripStartDate={tripStartDate}
							tripEndDate={tripEndDate}
							onChange={() => mutate()}
						>
							{({ compose }) => (
								<Button onClick={compose} size="sm">
									<Icons.Mail /> Email
								</Button>
							)}
						</ComposeHotelBookingEmail>
					</Inline>
				) : null}
			</Stack>
			{can_modify ? (
				<>
					<Divider sm />
					<Stack gap="2">
						<Heading as="h4" fontSize="base" color="primary">
							Activities
						</Heading>
						<Box>
							<ActivityLogs
								subjectId={booking.id}
								subjectType="hotel_bookings"
							/>
						</Box>
					</Stack>
				</>
			) : null}
			{booking.payments?.length && can_view_prices ? (
				<>
					<Divider sm />
					<Stack gap="2">
						<Heading as="h4" fontSize="base" color="primary">
							Instalments
						</Heading>
						<Stack gap="4">
							{booking.payments.map((payment) => (
								<PaymentDetails
									payment={payment}
									onChange={() => mutate()}
									key={payment.id}
									disableLogging={true}
									readOnlyAmount={booking.booked}
								/>
							))}
						</Stack>
					</Stack>
				</>
			) : null}
		</Stack>
	)
}

function TitleValue({
	title,
	children,
}: {
	title: React.ReactNode
	children: React.ReactNode
}) {
	return (
		<Stack gap="1">
			<Box
				textTransform="uppercase"
				letterSpacing="wider"
				fontSize="sm"
				fontWeight="semibold"
				color="muted"
			>
				{title}
			</Box>
			<Box fontSize="md">{children}</Box>
		</Stack>
	)
}

export function HotelBookingItemInDialog({
	children,
	onChange,
	...props
}: Omit<React.ComponentProps<typeof HotelBookingItem>, "onChange"> & {
	children: (props: { showDetails: () => void }) => React.ReactNode
	onChange?: () => void
}) {
	const [isDialogOpen, openDialog, close] = useDialog()
	const onChangePending = useRef<boolean>(false)
	const closeDialog = useCallback(() => {
		if (onChangePending.current) {
			onChangePending.current = false
			onChange?.()
		}
		close()
	}, [close, onChange])
	return (
		<>
			{children({ showDetails: openDialog })}
			<Dialog
				open={isDialogOpen}
				onClose={closeDialog}
				title="Hotel Booking Details"
				lg
			>
				<Dialog.Body>
					<HotelBookingItem
						{...props}
						onChange={() => {
							onChangePending.current = true
							onChange?.()
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}
