import {
	Box,
	Button,
	Text,
	Icons,
	Stack,
	Table,
	Container,
	Heading,
	Col,
	Grid,
	joinAttributes,
	Spinner,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	Time,
	Money,
} from "@sembark-travel/ui/base"
import {
	useSearch,
	TSearchParams,
	Search,
	ListView,
} from "@sembark-travel/ui/list"
import {
	Breadcrumbs,
	useLocationQuery,
	NavLink,
	ButtonLink,
	Link,
	XHRLink,
} from "@sembark-travel/ui/router"
import {
	dateToQuery,
	dateToUTCString,
	endOf,
	parseDateFromQuery,
} from "@sembark-travel/datetime-utils"
import { isNumeric, numberToLocalString } from "@sembark-travel/number-utils"
import { IListResponse, useXHR, XHRInstance } from "@sembark-travel/xhr"
import { useEffect, useMemo } from "react"
import useSWR from "swr"
import { AccountsList } from "../Accounting"
import { IScheduledCab } from "../CabScheduling"
import config from "../config"
import {
	ContactsList,
	Email,
	IContact,
	PhoneNumber,
	SelectContact,
} from "../Contacts"
import { DriversList, TDriver } from "../Drivers"
import { PaymentsList } from "../Payments"
import { generatePath } from "./../router-utils"
import { ITransportServiceProvider } from "./store"
import { UpdateItemInDialog } from "./UpdateItem"
import { DatePickerField } from "@sembark-travel/ui/form"
import { PERMISSIONS, useCheckPermissions, useHasFeatureFlag } from "../Auth"
import { TTravelActiviytBooking } from "../TravelActivityBookings"
import { getStartDateTimeOfCabSchedule } from "../CabScheduling/utils"

function XHR(xhr: XHRInstance) {
	return {
		async getTransportServiceProvider(
			id: string
		): Promise<ITransportServiceProvider> {
			return xhr
				.get(`/transport-service-providers/${id}`)
				.then((resp) => resp.data.data)
		},
		async getBookings(params: unknown): Promise<IListResponse<IScheduledCab>> {
			return xhr.get(`/scheduled-cabs`, { params }).then((resp) => resp.data)
		},
		async getTravelActivityBookings(
			params: unknown
		): Promise<IListResponse<TTravelActiviytBooking>> {
			return xhr
				.get(`/travel-activity-bookings`, { params })
				.then((resp) => resp.data)
		},
		async createContact(
			id: string | number,
			contactData: object
		): Promise<IContact> {
			return xhr
				.post(`/transport-service-provider-contacts`, {
					transport_service_provider_id: id,
					...contactData,
				})
				.then((resp) => resp.data.data)
		},
	}
}

function useTransportServiceProvider(
	transportServiceProviderId?: string,
	shouldFetch = false
) {
	const xhr = useXHR()
	const {
		data: transportServiceProvider,
		mutate: fetchTransportServiceProvider,
		error,
	} = useSWR(
		shouldFetch && transportServiceProviderId
			? `/api/transport-service-providers/${transportServiceProviderId}`
			: null,
		() => XHR(xhr).getTransportServiceProvider(transportServiceProviderId || "")
	)
	const isFetching = !transportServiceProvider && !error
	return {
		isFetching,
		transportServiceProvider,
		error,
		fetchTransportServiceProvider,
	}
}

export function TransportServiceProviderDetails({
	transportServiceProviderId,
	children,
}: {
	transportServiceProviderId: string
	children?: (props: {
		transportServiceProvider: ITransportServiceProvider
		revalidate: () => void
	}) => React.ReactNode
}) {
	const {
		transportServiceProvider,
		isFetching,
		fetchTransportServiceProvider,
	} = useTransportServiceProvider(transportServiceProviderId.toString(), true)
	const { hasPermission } = useCheckPermissions()
	const hasTravelActivities = useHasFeatureFlag("travel_activities")
	if (isFetching && !transportServiceProvider)
		return <Spinner alignCenter padding="4" />
	if (!transportServiceProvider)
		return <Box>Transport service provider does not exists</Box>
	const { name, solo_driver, supplies_cabs, supplies_travel_activities } =
		transportServiceProvider
	return (
		<Box>
			<Breadcrumbs
				title="Supplier Details"
				items={[
					[generatePath("/transport-service-providers"), "Suppliers"],
					["", name],
				]}
				actions={
					<Box>
						<UpdateItemInDialog
							transportServiceProvider={transportServiceProvider}
							onSuccess={() => fetchTransportServiceProvider()}
						>
							{({ edit }) => (
								<Button onClick={() => edit()} size="sm">
									<Icons.Pencil /> Edit
								</Button>
							)}
						</UpdateItemInDialog>
					</Box>
				}
			/>
			<Container paddingY="4" bgColor="default" borderBottomWidth="1">
				<Stack gap="2">
					<Heading as="h2" fontSize="xl">
						{name}
					</Heading>
					<Text as="span" color="muted">
						{joinAttributes(
							solo_driver ? (
								<>
									<Icons.Driver title="Single Driver" /> Solo Driver
								</>
							) : null,
							supplies_cabs && hasTravelActivities ? (
								<>
									<Icons.Taxi title="Cab Supplier" /> Cabs
								</>
							) : null,
							supplies_travel_activities ? (
								<>
									<Icons.Ticket title="Travel Activity Supplier" /> Travel
									Activities
								</>
							) : null
						)}
					</Text>
				</Stack>
			</Container>
			<Box>
				<Tabs layout="col" gap="0">
					<TabsList>
						<NavLink
							to={generatePath(
								"/transport-service-providers/:transportServiceProviderId",
								{
									transportServiceProviderId,
								}
							)}
							end
						>
							Details
						</NavLink>
						{transportServiceProvider.supplies_cabs ? (
							<NavLink
								to={generatePath(
									"/transport-service-providers/:transportServiceProviderId/bookings",
									{
										transportServiceProviderId,
									}
								)}
							>
								Cab Bookings
							</NavLink>
						) : null}
						{transportServiceProvider.supplies_travel_activities ? (
							<NavLink
								to={generatePath(
									"/transport-service-providers/:transportServiceProviderId/travel-activity-bookings",
									{
										transportServiceProviderId,
									}
								)}
							>
								Activity Bookings
							</NavLink>
						) : null}
						<NavLink
							to={generatePath(
								"/transport-service-providers/:transportServiceProviderId/payments",
								{
									transportServiceProviderId,
								}
							)}
						>
							Payments
						</NavLink>
						{hasPermission(PERMISSIONS.MANAGE_ACCOUNTING) ? (
							<NavLink
								to={generatePath(
									"/transport-service-providers/:transportServiceProviderId/accounting",
									{
										transportServiceProviderId,
									}
								)}
							>
								Accounting
							</NavLink>
						) : null}
					</TabsList>
					<TabContent>
						{children
							? children({
									transportServiceProvider,
									revalidate: fetchTransportServiceProvider,
								})
							: null}
					</TabContent>
				</Tabs>
			</Box>
		</Box>
	)
}

export function TransportServiceProviderBasicDetails({
	transportServiceProvider,
	onChange,
}: {
	transportServiceProvider: ITransportServiceProvider
	onChange: (transportServiceProviderId: number) => void
}) {
	const { contacts, drivers, supplies_cabs } = transportServiceProvider
	const xhr = useXHR()
	return (
		<Box
			paddingY="8"
			paddingX="4"
			bgColor="default"
			roundedBottom="lg"
			borderLeftWidth="1"
			borderRightWidth="1"
			borderBottomWidth="1"
		>
			<Grid gap="6">
				<Col sm={12} md={6}>
					<ContactsList
						contacts={contacts}
						phoneNumberRequired
						onCreate={async (data) => {
							return XHR(xhr)
								.createContact(transportServiceProvider.id, data)
								.then((contact) => {
									onChange(transportServiceProvider.id)
									return contact
								})
						}}
						onChange={() => onChange(transportServiceProvider.id)}
					/>
				</Col>
				<Col>
					{supplies_cabs ? (
						<DriversList
							transportServiceProvider={transportServiceProvider}
							drivers={drivers}
							onChange={() => onChange(transportServiceProvider.id)}
						/>
					) : null}
				</Col>
			</Grid>
		</Box>
	)
}

type TBookingStatus = "all" | "booked" | "assigned_but_not_booked"

type TFilters = TSearchParams & {
	pickup_after?: Date
	pickup_before?: Date
	status: TBookingStatus
	drivers?: Array<TDriver>
}

type TFiltersInLocationUrl = TSearchParams & {
	pickup_after?: string
	pickup_before?: string
	status: TBookingStatus
	drivers?: Array<string>
}

function filtersToUrl(filters: TFilters): TFiltersInLocationUrl {
	const { pickup_after, pickup_before, page, q, status, drivers } = filters
	const filtersInLocation: TFiltersInLocationUrl = { status }
	if (q) {
		filtersInLocation.q = q
	}
	if (page) {
		filtersInLocation.page = page
	}
	if (pickup_after) {
		filtersInLocation.pickup_after = dateToQuery(pickup_after)
	}
	if (pickup_before) {
		filtersInLocation.pickup_before = dateToQuery(pickup_before)
	}
	if (drivers) {
		filtersInLocation.drivers = drivers.map((d) => `${d.id}#${d.name}`)
	}
	return filtersInLocation
}

function filtersFromUrl(filters: TFiltersInLocationUrl): TFilters {
	const { pickup_after, pickup_before, q, page, status, drivers } = filters
	const params: TFilters = { status }
	if (q) {
		params.q = q
	}
	if (page) {
		params.page = page
	}
	if (pickup_after) {
		params.pickup_after = parseDateFromQuery(pickup_after)
	}
	if (pickup_before) {
		params.pickup_before = parseDateFromQuery(pickup_before)
	}
	if (drivers) {
		params.drivers = drivers.map((d) => {
			const [id, ...name] = d.split("#")
			return {
				id,
				name: name.join("#"),
			} as never as TDriver
		})
	}
	return params
}

function filtersToRequestParams(filters: TFilters) {
	const { pickup_after, pickup_before, page, q, status, drivers } = filters
	const params: {
		status: string
		page?: number
		q?: string
		pickup_after?: string
		pickup_before?: string
		start_date?: string
		end_date?: string
		booked?: 0 | 1
		drivers?: Array<number>
	} = { status }
	if (page) {
		params.page = page
	}
	if (q) {
		params.q = q
	}
	if (pickup_after) {
		params.pickup_after = dateToUTCString(pickup_after)
		params.start_date = dateToUTCString(pickup_after)
	}
	if (pickup_before) {
		params.pickup_before = dateToUTCString(endOf(pickup_before, "day"))
		params.end_date = dateToUTCString(endOf(pickup_before, "day"))
	}
	if (drivers?.length) {
		params.drivers = drivers.map((d) => d.id)
	}
	return params
}

export function TransportServiceProviderBookings({
	transportServiceProvider,
}: {
	transportServiceProvider: ITransportServiceProvider
}) {
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToUrl,
		fromQuery: filtersFromUrl,
	})
	const [params, setParams] = useSearch<TFilters>({
		...query,
		status: query.status || "booked",
	})
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Box paddingTop="8">
			<Search
				initialParams={params}
				placeholder="Search by Guest, Services etc..."
				title="Cab Schedules"
				resetParams={(params) => ({
					q: "",
					status: params.status,
				})}
				Filters={Filters}
				actions={
					<>
						<Button
							as={XHRLink}
							href="/scheduled-cabs/export"
							title="Download Report as Excel/CSV File"
							query={{
								...filtersToRequestParams(params),
								transportServiceProviders: [transportServiceProvider.id],
								timezone_offset: config.timezoneOffset,
							}}
							download
						>
							<Icons.DocumentDownload /> Excel/CSV
						</Button>
					</>
				}
				areAdvancedFiltersApplied={(filters) => {
					const { status, ...otherParams } = filters
					return Object.keys(otherParams).length > 0
				}}
				onSearch={(newParams) => {
					setParams({ ...newParams, page: 1 })
				}}
			>
				{({ searchParams, setSearchParams }) => (
					<Tabs>
						<TabsList>
							<TabItem
								active={searchParams.status === "booked"}
								onClick={() => {
									setSearchParams({ ...searchParams, status: "booked" })
								}}
							>
								Booked
							</TabItem>
							<TabItem
								active={searchParams.status === "assigned_but_not_booked"}
								onClick={() => {
									setSearchParams({
										...searchParams,
										status: "assigned_but_not_booked",
									})
								}}
							>
								Not Booked
							</TabItem>
							<TabItem
								active={searchParams.status === "all"}
								onClick={() => {
									setSearchParams({ ...searchParams, status: "all" })
								}}
							>
								All
							</TabItem>
						</TabsList>
						<TabContent>
							<ListView<IScheduledCab, TFilters>
								pageKey={`transport-service-providers/${transportServiceProvider.id}/bookings`}
								fetch={(xhr, filters) =>
									XHR(xhr).getBookings({
										...filtersToRequestParams(filters),
										transportServiceProviders: [transportServiceProvider.id],
									})
								}
								onPageChange={(page) => setParams({ ...params, page })}
								params={params}
							>
								{({ items }) => (
									<Table
										bordered
										hover
										responsive
										headers={[
											"Service",
											"Date",
											"Cab Type",
											"Cab",
											"Driver",
											"Guest",
											"Amount",
										]}
										alignCols={{ 6: "right" }}
										rows={items.map(
											({
												currency,
												booked_price,
												cab_type,
												cab,
												driver,
												cab_schedule,
												trip,
												latest_booking_stage,
												paid_payments_amount,
												total_payments_amount,
												refunding_payments_amount,
												paid_refunding_payments_amount,
											}) => [
												cab_schedule ? (
													<Box>
														<Box>
															<Link
																color="accent"
																fontWeight="semibold"
																to={generatePath("/cab-schedules/:scheduleId", {
																	scheduleId: cab_schedule.id.toString(),
																})}
																anchored
															>
																{cab_schedule.transport_service.short_name}
															</Link>
														</Box>
														<Box fontSize="sm" color="muted">
															{cab_schedule.transport_service.service}
														</Box>
													</Box>
												) : null,
												cab_schedule ? (
													<Box>
														<Box>
															<Time
																timestamp={cab_schedule.start_date}
																localTimestamp={cab_schedule.start_date_local}
															/>
														</Box>
														<Box fontSize="sm" color="muted">
															{getStartDateTimeOfCabSchedule(cab_schedule) ? (
																<Time
																	value={getStartDateTimeOfCabSchedule(
																		cab_schedule
																	)}
																	format="HH:mm"
																/>
															) : null}
														</Box>
													</Box>
												) : null,
												<Box>
													<Box>{cab_type.name}</Box>
													<Box fontSize="sm" color="muted">
														{latest_booking_stage.title}
													</Box>
												</Box>,
												cab ? (
													<Box>
														<Box>{cab.name}</Box>
														<Box fontSize="sm" color="muted">
															{cab.number_plate}
														</Box>
													</Box>
												) : null,
												driver ? (
													<Box>
														<Box>{driver.name}</Box>
														<Box fontSize="sm" color="muted">
															{joinAttributes(
																driver.phone_number ? (
																	<PhoneNumber
																		value={driver.phone_number}
																		iconOnly
																	/>
																) : null,
																driver.email ? (
																	<Email value={driver.email} iconOnly />
																) : null
															)}
														</Box>
													</Box>
												) : null,
												trip ? (
													<Box>
														<Box>
															<Link
																to={generatePath("/trips/:tripId", {
																	tripId: trip.id.toString(),
																})}
																color="accent"
															>
																{trip.contact.name}
															</Link>
														</Box>
														<Box fontSize="sm" color="muted">
															{joinAttributes(
																trip.id,
																trip.contact.phone_numbers?.length ? (
																	<PhoneNumber
																		value={trip.contact.phone_numbers}
																		iconOnly
																	/>
																) : null,
																trip.contact.email ? (
																	<Email value={trip.contact.email} iconOnly />
																) : null
															)}
														</Box>
													</Box>
												) : null,
												booked_price !== undefined && booked_price !== null ? (
													<Box>
														<Box>
															<Box fontWeight="semibold">
																{latest_booking_stage.is_same_or_after_booked ? (
																	<Money
																		showCurrency
																		amount={total_payments_amount || 0}
																		currency={currency}
																	/>
																) : isNumeric(booked_price) ? (
																	<Box>
																		<Money
																			showCurrency
																			amount={booked_price}
																			currency={currency}
																		/>
																	</Box>
																) : null}
															</Box>
															{isNumeric(paid_payments_amount) ? (
																<Box
																	fontSize="sm"
																	title={`${numberToLocalString(
																		paid_payments_amount
																	)} Paid`}
																	marginTop="1"
																	color="success"
																>
																	<Icons.Ok />
																	<Box display="inlineBlock" marginLeft="px">
																		<Money
																			amount={paid_payments_amount}
																			currency={currency}
																			showCurrency
																		/>
																	</Box>
																</Box>
															) : null}
															{isNumeric(refunding_payments_amount) ? (
																<Box
																	fontSize="sm"
																	title={`Refund: ${numberToLocalString(
																		paid_refunding_payments_amount || 0
																	)}/${numberToLocalString(
																		refunding_payments_amount
																	)} Paid`}
																	marginTop="1"
																	color="warning"
																>
																	<Icons.Refresh color="warning" />
																	<Box display="inlineBlock" marginLeft="px">
																		<Money
																			amount={refunding_payments_amount}
																			currency={currency}
																			showCurrency
																		/>
																	</Box>
																</Box>
															) : null}
														</Box>
													</Box>
												) : null,
											]
										)}
									/>
								)}
							</ListView>
						</TabContent>
					</Tabs>
				)}
			</Search>
		</Box>
	)
}

export function TransportServiceProviderTravelActivityBookings({
	transportServiceProvider,
}: {
	transportServiceProvider: ITransportServiceProvider
}) {
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToUrl,
		fromQuery: filtersFromUrl,
	})
	const [params, setParams] = useSearch<TFilters>({
		...query,
		status: query.status || "booked",
	})
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<Box paddingTop="8">
			<Search
				initialParams={params}
				placeholder="Search by Guest, Services etc..."
				title="Travel Activity Bookings"
				resetParams={(params) => ({
					q: "",
					status: params.status,
				})}
				Filters={Filters}
				actions={
					<>
						<Button
							as={XHRLink}
							href="/travel-activity-bookings/download"
							title="Download Report as Excel/CSV File"
							query={{
								...filtersToRequestParams(params),
								suppliers: [transportServiceProvider.id],
								timezone_offset: config.timezoneOffset,
							}}
							download
						>
							<Icons.DocumentDownload /> Excel/CSV
						</Button>
					</>
				}
				areAdvancedFiltersApplied={(filters) => {
					const { status, ...otherParams } = filters
					return Object.keys(otherParams).length > 0
				}}
				onSearch={(newParams) => {
					setParams({ ...newParams, page: 1 })
				}}
			>
				{({ searchParams, setSearchParams }) => (
					<Tabs>
						<TabsList>
							<TabItem
								active={searchParams.status === "booked"}
								onClick={() => {
									setSearchParams({ ...searchParams, status: "booked" })
								}}
							>
								Booked
							</TabItem>
							<TabItem
								active={searchParams.status === "assigned_but_not_booked"}
								onClick={() => {
									setSearchParams({
										...searchParams,
										status: "assigned_but_not_booked",
									})
								}}
							>
								Not Booked
							</TabItem>
							<TabItem
								active={searchParams.status === "all"}
								onClick={() => {
									setSearchParams({ ...searchParams, status: "all" })
								}}
							>
								All
							</TabItem>
						</TabsList>
						<TabContent>
							<ListView<TTravelActiviytBooking, TFilters>
								pageKey={`transport-service-providers/${transportServiceProvider.id}/travel-activity-bookings`}
								fetch={(xhr, filters) =>
									XHR(xhr).getTravelActivityBookings({
										...filtersToRequestParams(filters),
										suppliers: [transportServiceProvider.id],
									})
								}
								onPageChange={(page) => setParams({ ...params, page })}
								params={params}
							>
								{({ items }) => (
									<Table
										bordered
										hover
										responsive
										headers={["Service", "Date", "Ticket", "Guest", "Amount"]}
										alignCols={{ 4: "right" }}
										rows={items.map(
											({
												currency,
												booked_price,
												trip,
												paid_payments_amount,
												total_payments_amount,
												refunding_payments_amount,
												paid_refunding_payments_amount,
												activity,
												ticket_type,
												ticket_tourist_configurations_short_name,
												start_date,
												start_date_local,
												start_time_formatted,
												duration_formatted,
												is_booked,
												dropped_at,
											}) => [
												<Box>
													<Link
														to={generatePath(
															"/travel-activities/:travelActivityId",
															{
																travelActivityId: activity.id.toString(),
															}
														)}
														color="accent"
														anchored
													>
														{activity.name}
														{ticket_type ? ` - ${ticket_type.name}` : ``}
													</Link>
												</Box>,
												<Box>
													<Box>
														<Time
															timestamp={start_date}
															localTimestamp={start_date_local}
														/>
													</Box>
													{start_time_formatted || duration_formatted ? (
														<Box fontSize="sm" color="muted">
															{joinAttributes(
																start_time_formatted,
																duration_formatted
															)}
														</Box>
													) : null}
												</Box>,
												<Box>
													<Box>{ticket_tourist_configurations_short_name}</Box>
													<Box fontSize="sm" color="muted">
														{is_booked ? (
															<>
																<Icons.OkCircleSolid color="success" /> Booked
															</>
														) : dropped_at ? (
															<>
																<Icons.Ban color="warning" /> Dropped
															</>
														) : (
															"Pending"
														)}
													</Box>
												</Box>,
												trip ? (
													<Box>
														<Box>
															<Link
																to={generatePath("/trips/:tripId", {
																	tripId: trip.id.toString(),
																})}
																color="accent"
															>
																{trip.contact.name}
															</Link>
														</Box>
														<Box fontSize="sm" color="muted">
															{joinAttributes(
																`Trip#` + trip.id,
																trip.contact.phone_numbers?.length ? (
																	<PhoneNumber
																		value={trip.contact.phone_numbers}
																		iconOnly
																	/>
																) : null,
																trip.contact.email ? (
																	<Email value={trip.contact.email} iconOnly />
																) : null
															)}
														</Box>
													</Box>
												) : null,
												booked_price !== undefined && booked_price !== null ? (
													<Box>
														<Box>
															<Box fontWeight="semibold">
																{is_booked ? (
																	<Money
																		amount={total_payments_amount || 0}
																		currency={currency}
																		showCurrency
																	/>
																) : isNumeric(booked_price) ? (
																	<Box>
																		<Money
																			amount={booked_price}
																			currency={currency}
																			showCurrency
																		/>
																	</Box>
																) : null}
															</Box>
															{isNumeric(paid_payments_amount) ? (
																<Box
																	fontSize="sm"
																	title={`${numberToLocalString(
																		paid_payments_amount
																	)} Paid`}
																	marginTop="1"
																	color="success"
																>
																	<Icons.Ok />
																	<Box display="inlineBlock" marginLeft="px">
																		<Money
																			amount={paid_payments_amount}
																			currency={currency}
																		/>
																	</Box>
																</Box>
															) : null}
															{isNumeric(refunding_payments_amount) ? (
																<Box
																	fontSize="sm"
																	title={`Refund: ${numberToLocalString(
																		paid_refunding_payments_amount || 0
																	)}/${numberToLocalString(
																		refunding_payments_amount
																	)} Paid`}
																	marginTop="1"
																	color="warning"
																>
																	<Icons.Refresh color="warning" />
																	<Box display="inlineBlock" marginLeft="px">
																		<Money
																			amount={refunding_payments_amount}
																			currency={currency}
																		/>
																	</Box>
																</Box>
															) : null}
														</Box>
													</Box>
												) : null,
											]
										)}
									/>
								)}
							</ListView>
						</TabContent>
					</Tabs>
				)}
			</Search>
		</Box>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<DatePickerField label="Start Date" name="pickup_after" />
			<DatePickerField label="End Date" name="pickup_before" />
		</Stack>
	)
}

export function TransportServiceProviderPayments({
	transportServiceProviderId,
}: {
	transportServiceProviderId: number | string
}) {
	const transportServiceProviders = useMemo(() => {
		if (!transportServiceProviderId) return []
		return [Number(transportServiceProviderId)]
	}, [transportServiceProviderId])
	if (!transportServiceProviderId) return null
	return (
		<Box paddingTop="8">
			<PaymentsList
				title="Payments"
				transportServiceProviders={transportServiceProviders}
				debit
				hideContact
			/>
		</Box>
	)
}

export function TransportServiceProviderAccountings({
	transportServiceProvider,
}: {
	transportServiceProvider: ITransportServiceProvider
}) {
	return (
		<Box marginY="8">
			{transportServiceProvider.accounting_accounts?.length ? (
				<Container>
					<Box textAlign="right" marginBottom="2">
						<ButtonLink
							to={generatePath(
								"/transport-service-providers/:transportServiceProviderId/payments",
								{
									transportServiceProviderId: String(
										transportServiceProvider.id
									),
								}
							)}
						>
							View Due Payments <Icons.ChevronDown rotate="270" />
						</ButtonLink>
					</Box>
					<AccountsList
						accounts={transportServiceProvider.accounting_accounts}
					/>
				</Container>
			) : (
				<Container>
					<Stack gap="4">
						<Text fontSize="md" fontWeight="semibold">
							No accounting activities for this service provider
						</Text>
						<Text color="muted">
							Log payments regarding bookings or services of this service
							provider to manage the accounting.
						</Text>
						<Box>
							<ButtonLink
								to={generatePath(
									"/transport-service-providers/:transportServiceProviderId/payments",
									{
										transportServiceProviderId: String(
											transportServiceProvider.id
										),
									}
								)}
								status="primary"
							>
								View Due Payments <Icons.ChevronDown rotate="270" />
							</ButtonLink>
						</Box>
					</Stack>
				</Container>
			)}
		</Box>
	)
}

export function SelectSupplierContact({
	supplierId,
	onCreateSuccess,
	...props
}: Omit<React.ComponentProps<typeof SelectContact>, "onCreate" | "options"> & {
	supplierId: number | string
	onCreateSuccess?: (contact: IContact) => void
}) {
	const xhr = useXHR()
	const { data, mutate: revalidate } = useSWR(
		`/transport-service-providers/${supplierId}?select-contact`,
		() => XHR(xhr).getTransportServiceProvider(supplierId.toString())
	)
	return (
		<>
			<SelectContact
				{...props}
				phoneNumberRequired
				options={data?.contacts || []}
				onCreate={async (data) => {
					const contact = await XHR(xhr).createContact(supplierId, data)
					revalidate()
					onCreateSuccess?.(contact)
					return contact
				}}
			/>
		</>
	)
}
