import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import {
	Button,
	Inline,
	Stack,
	Divider,
	Col,
	Grid,
	Component,
	Box,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { useXHR } from "@sembark-travel/xhr"
import React, { useCallback, useState } from "react"
import { TTourist } from "./store"
import {
	Form,
	TextInputField,
	PhoneInputField,
	validateFormValues,
	SubmissionError,
	PhoneNumberValidator,
	withServerErrors,
	TextAreaInputField,
	getEditablePhoneNumber,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"

import {
	AddressInputField,
	TAddressInputFieldValue,
	transformAddressToEditableValue,
	transformAddressToStorableValue,
} from "../Addresses"

interface IUpdateTouristProps
	extends Omit<
		React.ComponentProps<typeof TouristForm>,
		"onSubmit" | "initialValues"
	> {
	tourist: TTourist
	onSuccess?: (tourist: TTourist) => void
}

function AddTourist({
	onSuccess,
	tripId,
	...props
}: Omit<IUpdateTouristProps, "tourist"> & {
	tripId: number | string
}) {
	const xhr = useXHR()
	const handleCreate = useCallback(
		async (data: object) => {
			const resp = await xhr.post(`/tourists`, { ...data, trip_id: tripId })
			onSuccess?.(resp.data.data)
		},
		[tripId, xhr, onSuccess]
	)
	return <TouristForm onSubmit={handleCreate} {...props} />
}

export function AddTouristInDialog({
	onSuccess,
	children,
	...props
}: Omit<React.ComponentProps<typeof AddTourist>, "onSuccess" | "onCancel"> & {
	onSuccess: (contact: TTourist) => void
	children: (props: { add: () => void }) => React.ReactNode
}) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ add: open })}
			<Dialog open={isOpen} onClose={close} sm title="Add Guest Details">
				<Dialog.Body>
					<AddTourist
						{...props}
						onCancel={close}
						onSuccess={(data) => {
							close()
							showSnackbar("Guest details added")
							onSuccess?.(data)
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function UpdateTourist({
	tourist,
	onSuccess,
	...props
}: IUpdateTouristProps) {
	const xhr = useXHR()
	const [initialValues] = useState(() => {
		return {
			name: tourist.name || "",
			email: tourist.email || "",
			phone_numbers: tourist.phone_numbers.map((p) =>
				getEditablePhoneNumber(p.phone_number, p.country_code)
			),
			address: transformAddressToEditableValue(tourist.address),
		}
	})
	const handleUpdate = useCallback(
		async (data: object) => {
			const resp = await xhr.patch(`/tourists/${tourist.id}`, data)
			onSuccess?.(resp.data.data)
		},
		[tourist, xhr, onSuccess]
	)
	return (
		<TouristForm
			onSubmit={handleUpdate}
			initialValues={initialValues}
			{...props}
		/>
	)
}

export function UpdateTouristInDialog({
	onSuccess,
	children,
	...props
}: Omit<IUpdateTouristProps, "onSuccess" | "onCancel"> & {
	onSuccess: (contact: TTourist) => void
	children: (props: { update: () => void }) => React.ReactNode
}) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ update: open })}
			<Dialog open={isOpen} onClose={close} sm title="Edit Guest Details">
				<Dialog.Body>
					<UpdateTourist
						{...props}
						onCancel={close}
						onSuccess={(data) => {
							close()
							showSnackbar("Guest details updated")
							onSuccess?.(data)
						}}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

interface TTouristFormData {
	name: string
	email?: string
	phone_numbers?: Array<{
		phone_number: number | string
		country_code: string
		number: number | string
	}>
	address?: TAddressInputFieldValue
}

const InitialValues: TTouristFormData = {
	name: "",
	email: "",
	phone_numbers: [],
	address: undefined,
}

interface AddTouristFormProps {
	onSubmit: (data: object) => Promise<void>
	onCancel?: () => void
	initialValues?: TTouristFormData
}

const addContactValidationSchema = validateFormValues(
	Validator.object()
		.shape({
			name: Validator.string().required("Contact name is required"),
			email: Validator.string().email("Email should be a valid email address"),
			phone_numbers: Validator.array()
				.of(PhoneNumberValidator())
				.min(1, "Please provide atleast one phone number"),
			address: Validator.object()
				.nullable()
				.shape({
					billing_details: Validator.string()
						.nullable()
						.max(100, "Please use 100 or fewer characters"),
				}),
		})
		.required("Contact data is required")
)

function TouristForm({
	onSubmit,
	onCancel,
	initialValues = InitialValues,
}: AddTouristFormProps) {
	return (
		<Form<TTouristFormData>
			initialValues={initialValues}
			validate={addContactValidationSchema}
			onSubmit={withServerErrors(async ({ address, ...values }) => {
				if (address && address.billing_details && !address.location) {
					throw new Error("Origin City is required for billing details.")
				}
				const payload = {
					...values,
					address: address?.location
						? {
								...transformAddressToStorableValue(address),
								addressing_name: values.name,
								billing_details: address.billing_details,
							}
						: null,
				}
				await onSubmit(payload)
				onCancel && onCancel()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<Grid gap="4">
							<Col>
								<TextInputField
									name="name"
									label="Name"
									type="text"
									required
									placeholder="Johhny Dep"
								/>
							</Col>
							<Col>
								<TextInputField
									name="email"
									label="Email"
									type="email"
									placeholder="user@domain.com"
									secondaryLabel="optional"
								/>
							</Col>
						</Grid>
						<PhoneInputField
							name="phone_numbers"
							label={`Phone Number(s)`}
							multi
							required
						/>
						<Component
							initialState={
								initialValues.address?.billing_details ? true : false
							}
						>
							{({ state, setState }) => (
								<Stack gap={!state ? "1" : "4"}>
									<AddressInputField
										name="address"
										label="Origin City / Location"
										onlyLocation
										hidePreviewForLocation
									/>
									{!state ? (
										<Box>
											<Button onClick={() => setState(true)} inline>
												Add Billing Details
											</Button>
										</Box>
									) : (
										<TextAreaInputField
											name="address.billing_details"
											label="Guest Billing Details"
											placeholder="e.g. GST: 12GYR123KHS"
										/>
									)}
								</Stack>
							)}
						</Component>
					</Stack>
					<Divider sm />
					<Stack gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button disabled={submitting} type="submit">
								{submitting ? "Please wait..." : "Save"}
							</Button>
							{onCancel ? (
								<Button
									onClick={onCancel}
									level="tertiary"
									disabled={submitting}
								>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
