import {
	AsyncSelect,
	AsyncSelectProps,
	Button,
	Icons,
	Table,
} from "@sembark-travel/ui/base"
import {
	ISimpleListResponse,
	IListResponse,
	useXHR,
	XHRInstance,
} from "@sembark-travel/xhr"
import { Omit } from "utility-types"
import { IMealPlan } from "./store"
import { TTripDestination } from "../TripDestinations"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { Link } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

function XHR(xhr: XHRInstance) {
	return {
		async getMealPlans(
			params?: unknown
		): Promise<ISimpleListResponse<IMealPlan>> {
			return xhr.get("/meal-plans", { params }).then((resp) => resp.data)
		},
		async getMealPlansWithPagination(
			params?: Record<string, unknown>
		): Promise<IListResponse<IMealPlan>> {
			return xhr
				.get("/meal-plans", { params: { ...params, pagination: "page" } })
				.then((resp) => resp.data)
		},
		async destroy(id: number | string) {
			return xhr
				.delete<{ message: string }>(`/meal-plans/${id}`)
				.then((resp) => resp.data)
		},
	}
}

export function MealPlansList({ actions }: { actions: React.ReactNode }) {
	const [params, setParams] = useSearch()
	return (
		<Search
			title="Meal Plans"
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			actions={actions}
		>
			<ListView
				params={params}
				pageKey={`meal-plans-list-page`}
				fetch={(xhr, params) =>
					XHR(xhr).getMealPlansWithPagination({
						...params,
						include: "hotels_count",
					})
				}
				onPageChange={(page) => setParams({ ...params, page })}
			>
				{({ items, refresh, xhr }) => (
					<Table
						headers={["Name", "Description", "Hotels", ""]}
						bordered
						striped
						alignCols={{ 2: "center", 3: "right" }}
						rows={items.map((item) => [
							item.name,
							item.description,
							item.hotels_count ? (
								<Link
									to={generatePath("/hotels")}
									query={{
										meal_plans: [item.id + "_" + item.name],
									}}
								>
									{item.hotels_count}
								</Link>
							) : (
								0
							),
							<Button
								status="warning"
								size="sm"
								level="tertiary"
								onClick={() => {
									if (
										window.confirm(
											"Are you sure you want to delete this meal plan?\n\nThis will be deleted and removed from all hotels. Existing quotation and bookings will not be affected.\n\nThis action CAN NOT be reverted."
										)
									) {
										XHR(xhr)
											.destroy(item.id)
											.then((resp) => {
												showSnackbar(
													resp.message || "Meal Plan deleted successfully."
												)
												refresh()
											})
											.catch((e) => {
												const error: Error = e
												window.alert(error.message || "Something went wrong")
											})
									}
								}}
							>
								<Icons.Trash />
							</Button>,
						])}
					/>
				)}
			</ListView>
		</Search>
	)
}

export function SelectMealPlans({
	tripDestinations,
	...props
}: Omit<AsyncSelectProps, "fetch"> & {
	tripDestinations?: Array<TTripDestination>
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			multiple
			placeholder="Select meals..."
			{...props}
			cacheKey={`meal-plans-${tripDestinations?.map((t) => t.id).join("-")}`}
			fetch={(q) =>
				XHR(xhr)
					.getMealPlans({
						q,
						trip_destinations: tripDestinations?.length
							? tripDestinations.map((t) => t.id)
							: null,
					})
					.then((resp) => resp.data)
			}
		/>
	)
}
