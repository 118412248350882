import {
	Alert,
	Box,
	Button,
	Inline,
	Stack,
	Icons,
	Input,
	InputGroup,
	Table,
	Heading,
	Text,
	useId,
	Col,
	TableDataCell,
	Component,
	Divider,
	Grid,
	joinAttributes,
	RelativeTime,
	Spinner,
	Stars,
	Time,
	TimeDuration,
	Container,
	Money,
	MoneySum,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Dialog } from "@sembark-travel/ui/dialog"
import { useNavigateToBack, ButtonLink } from "@sembark-travel/ui/router"
import {
	useForm,
	Form,
	SelectField,
	TextInputField,
	TextAreaInputField,
	withServerErrors,
	SubmissionError,
	EmptyNumberValidator,
	arrayMutators,
	validateFormValues,
	Field,
	GetFieldValue,
	FormSpy,
	useFieldValue,
} from "@sembark-travel/ui/form"
import {
	utcTimestampToLocalDate,
	getDiff,
	duration,
} from "@sembark-travel/datetime-utils"
import { isNumeric, withOrdinalSuffix } from "@sembark-travel/number-utils"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import { useCallback, useEffect, useMemo } from "react"
import useSWR from "swr"
import * as Validator from "yup"
import { type IScheduledCab } from "../CabScheduling"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath } from "../router-utils"
import {
	type ITripCancellationReason,
	SelectTripCancellationReason,
} from "./CancelTrip"
import { type IBooking } from "./../HotelBookings"
import { type ITrip } from "./store"
import {
	addMoney,
	addMoneyFromDifferentCurrencies,
	makeCurrencyConverter,
	moneyParseByDecimal,
	type TMoney,
} from "@sembark-travel/money"
import { TTravelActiviytBooking } from "../TravelActivityBookings"

const dataValidatorSchema = Validator.object().shape({
	hotel_bookings: Validator.array().of(
		Validator.object().shape({
			cancellation_charges: EmptyNumberValidator().min(
				0,
				"Cancellation charges must be a positive number"
			),
		})
	),
	travel_activity_bookings: Validator.array().of(
		Validator.object().shape({
			cancellation_charges: EmptyNumberValidator().min(
				0,
				"Cancellation charges must be a positive number"
			),
		})
	),
	cab_bookings: Validator.array().of(
		Validator.object().shape({
			cancellation_charges: EmptyNumberValidator().min(
				0,
				"Cancellation charges must be a positive number"
			),
		})
	),
	cancellation_charges: EmptyNumberValidator().min(
		0,
		"Cancellation charges must be a positive number"
	),
	reason: Validator.object().required(
		"Please provide a reason of cancellation"
	),
	comments: Validator.string().required(
		"Please provide more details regarding this cancellation"
	),
})

type TDropTripParams = {
	hotel_bookings: Array<IBooking & { cancellation_charges: number }>
	cab_bookings: Array<IScheduledCab & { cancellation_charges: number }>
	travel_activity_bookings: Array<
		TTravelActiviytBooking & { cancellation_charges: number }
	>
	cancellation_charges: number
	reason: ITripCancellationReason | undefined
	comments: string
	total_hotel_bookings_cancellation_charges: Array<TMoney>
	total_cab_bookings_cancellation_charges: Array<TMoney>
	total_travel_activity_bookings_cancellation_charges: Array<TMoney>
	total_bookings_cancellation_charges: TMoney
}

export default function DropTrip({ tripId }: { tripId: string }) {
	const navigateToBack = useNavigateToBack()
	const xhr = useXHR()
	const uid = useId()
	const { data: trip, error } = useSWR<
		ITrip & {
			hotel_bookings: Array<IBooking & { cancellation_charges: number }>
			cab_bookings: Array<IScheduledCab>
			travel_activity_bookings: Array<TTravelActiviytBooking>
		}
	>(`/dropped-trips/${tripId}/edit?${uid}`, async () =>
		xhr.get(`/dropped-trips/${tripId}/edit`).then((resp) => resp.data.data)
	)
	const backToTripDetails = useCallback(() => {
		if (tripId) {
			navigateToBack(generatePath("/trips/:tripId", { tripId: tripId }))
		}
	}, [navigateToBack, tripId])
	const {
		totalPaidPaymentsForAllBookings,
		totalPaidPaymentsForAllBookingsInPackageCurrency,
	} = useMemo(() => {
		function getTotalPaidAmount(
			bookings: Array<{ currency: string; paid_payments_amount?: number }>
		) {
			return addMoneyFromDifferentCurrencies(
				bookings.map(({ currency, paid_payments_amount }) =>
					moneyParseByDecimal(paid_payments_amount || 0, currency)
				)
			)
		}
		const [
			totalPaidPaymentsForHotelBooking,
			totalPaidPaymentsForCabBookings,
			totalPaidPaymentsForTravelActivityBookings,
		] = [
			trip?.hotel_bookings,
			trip?.cab_bookings,
			trip?.travel_activity_bookings,
		].map((bookings) => (!bookings ? [] : getTotalPaidAmount(bookings)))
		const totalPaidPaymentsForAllBookings = addMoneyFromDifferentCurrencies(
			totalPaidPaymentsForHotelBooking.concat(totalPaidPaymentsForCabBookings)
		)
		const totalPaidPaymentsForAllBookingsInPackageCurrency =
			makeCurrencyConverter(trip?.latest_given_quote?.currency_pairs || [])(
				trip?.latest_given_quote?.given_currency || "INR",
				totalPaidPaymentsForAllBookings
			)
		return {
			totalPaidPaymentsForHotelBooking,
			totalPaidPaymentsForCabBookings,
			totalPaidPaymentsForTravelActivityBookings,
			totalPaidPaymentsForAllBookings,
			totalPaidPaymentsForAllBookingsInPackageCurrency,
		}
	}, [trip])
	const hasServicesInBookedStage = useMemo(() => {
		return Boolean(
			trip?.hotel_bookings?.some((b) => b.booked) ||
				trip?.cab_bookings?.some((c) => c.booked) ||
				trip?.travel_activity_bookings?.some((c) => c.is_booked)
		)
	}, [trip])
	const currency_pairs = trip?.latest_given_quote?.currency_pairs
	const convertCurrency: ReturnType<typeof makeCurrencyConverter> = useCallback(
		(...args) => makeCurrencyConverter(currency_pairs || [])(...args),
		[currency_pairs]
	)
	if (!tripId) {
		return (
			<Box>
				<Alert status="error">Please select a trip for cancellation</Alert>
			</Box>
		)
	}
	if (!trip) {
		if (error) {
			return (
				<Box>
					<Alert status="error">{error.message}</Alert>
				</Box>
			)
		}
		return <Spinner alignCenter padding="4" />
	}
	const {
		id,
		contact,
		trip_source,
		start_date,
		start_date_local,
		days,
		no_of_adults,
		no_of_children,
		reference_id,
		latest_given_quote,
		converted_by,
		payments_amount,
		paid_payments_amount,
		hotel_bookings,
		cab_bookings,
		travel_activity_bookings,
		converted_at,
	} = trip
	const packageCurrency = latest_given_quote?.given_currency || "INR"
	const initialValues: TDropTripParams = {
		hotel_bookings: hotel_bookings
			.sort((a, b) => (a.checkin > b.checkin ? 1 : -1))
			.map((b) => ({
				...b,
				cancellation_charges: b.cancellation_charges || 0,
			})),
		cab_bookings: cab_bookings.map((b) => ({
			...b,
			cancellation_charges: b.cancellation_charges || 0,
		})),
		travel_activity_bookings: travel_activity_bookings.map((b) => ({
			...b,
			cancellation_charges: b.cancellation_charges || 0,
		})),
		cancellation_charges: 0,
		reason: undefined as ITripCancellationReason | undefined,
		comments: "",
		total_hotel_bookings_cancellation_charges: addMoneyFromDifferentCurrencies(
			hotel_bookings.map((b) =>
				moneyParseByDecimal(b.cancellation_charges || 0, b.currency || "INR")
			)
		),
		total_cab_bookings_cancellation_charges: addMoneyFromDifferentCurrencies(
			cab_bookings.map((b) =>
				moneyParseByDecimal(b.cancellation_charges || 0, b.currency || "INR")
			)
		),
		total_travel_activity_bookings_cancellation_charges:
			addMoneyFromDifferentCurrencies(
				travel_activity_bookings.map((b) =>
					moneyParseByDecimal(b.cancellation_charges || 0, b.currency || "INR")
				)
			),
		total_bookings_cancellation_charges: moneyParseByDecimal(
			0,
			packageCurrency
		),
	}
	if (hasServicesInBookedStage) {
		return (
			<Container paddingY="8">
				<Alert title="Can not drop the Trip" status="error">
					<Stack gap="4">
						<Text>
							Some services (hotels / cabs / activities) are in booked stage.
							Please revert the bookings of these services before dropping this
							trip.
						</Text>
						<Box>
							<ButtonLink
								to={generatePath("/trips/:tripId/services-bookings", {
									tripId: trip.id.toString(),
								})}
							>
								View Bookings
							</ButtonLink>
						</Box>
					</Stack>
				</Alert>
			</Container>
		)
	}
	return (
		<Box>
			<Container paddingY="8" bgColor="primary">
				<Grid gap="4">
					<Col md={12} lg={3}>
						<Stack gap="1">
							<Heading as="h3">Trip details</Heading>
							<Text color="muted">
								Please review the trip details to make sure you are dropping the
								correct booking.
							</Text>
						</Stack>
					</Col>
					<Col>
						<Box>
							<Box
								borderWidth="2"
								boxShadow="lg"
								bgColor="default"
								borderColor="primary"
								rounded="lg"
								display="flex"
								flexWrap="wrap"
							>
								<Box margin="4">
									<Box fontSize="sm" color="muted" marginBottom="1">
										Guest
									</Box>
									<Box fontWeight="semibold" fontSize="lg">
										{contact.name}
									</Box>
									<Box fontSize="sm">
										{pluralize("Adult", no_of_adults, true)}
										{no_of_children > 0
											? `, ${pluralize("Child", no_of_children, true)}`
											: ``}
									</Box>
								</Box>
								<Box margin="4">
									<Box fontSize="sm" color="muted" marginBottom="1">
										Query Source
									</Box>
									<Box fontWeight="semibold" fontSize="lg">
										{trip_source.name}
									</Box>
									<Box fontSize="sm">{reference_id ? reference_id : null}</Box>
								</Box>
								<Box margin="4">
									<Box fontSize="sm" color="muted" marginBottom="1">
										Date and Duration
									</Box>
									<Box fontWeight="semibold" fontSize="lg">
										<Time
											timestamp={start_date}
											localTimestamp={start_date_local}
										/>
									</Box>
									<Box fontSize="sm">
										<TimeDuration days={days}>
											{pluralize("Night", days - 1, true)},{" "}
											{pluralize("Days", days, true)}
										</TimeDuration>
									</Box>
								</Box>
								<Box margin="4">
									<Box fontSize="sm" color="muted" marginBottom="1">
										Conversion
									</Box>
									<Box fontWeight="semibold" fontSize="lg">
										{converted_at ? (
											<RelativeTime
												value={utcTimestampToLocalDate(converted_at)}
											/>
										) : null}
									</Box>
									<Box fontSize="sm">
										{converted_by ? `by ${converted_by.name}` : null}
									</Box>
								</Box>
								<Box margin="4">
									<Box fontSize="sm" color="muted" marginBottom="1">
										Package
									</Box>
									<Box fontWeight="semibold" fontSize="xl">
										{payments_amount ? (
											<Money
												showCurrency
												amount={payments_amount}
												currency={packageCurrency}
											/>
										) : null}
									</Box>
								</Box>
							</Box>
						</Box>
					</Col>
				</Grid>
			</Container>
			<Form<TDropTripParams>
				validate={validateFormValues(dataValidatorSchema)}
				initialValues={initialValues}
				onSubmit={withServerErrors(
					async ({
						total_bookings_cancellation_charges,
						total_hotel_bookings_cancellation_charges,
						total_cab_bookings_cancellation_charges,
						total_travel_activity_bookings_cancellation_charges,
						...values
					}) => {
						const data = {
							...values,
							reason: values.reason?.name,
							hotel_bookings: values.hotel_bookings.map((b) => ({
								id: b.id,
								cancellation_charges: b.cancellation_charges,
							})),
							cab_bookings: values.cab_bookings.map((b) => ({
								id: b.id,
								cancellation_charges: b.cancellation_charges,
							})),
							travel_activity_bookings: values.travel_activity_bookings.map(
								(b) => ({
									id: b.id,
									cancellation_charges: b.cancellation_charges,
								})
							),
						}
						await xhr.post(`/dropped-trips/${trip.id}`, data)
						showSnackbar("Trip marked as dropped")
						backToTripDetails()
					}
				)}
				subscription={{ submitting: true }}
				mutators={{ ...arrayMutators }}
			>
				{({ submitting, form, handleSubmit }) => {
					return (
						<form noValidate onSubmit={handleSubmit}>
							<Container paddingY="8" bgColor="warning">
								<Grid gap="4">
									<Col md={12} lg={3} position="relative">
										<Stack gap="1" position="sticky" top="0">
											<Heading fontSize="md" as="h3">
												Booking/Outgoing Payments
											</Heading>
											<Text color="muted">
												Please verify the bookings and payments. Carefully
												provide applicable cancellation charges for each booked
												and holded services.
											</Text>
										</Stack>
									</Col>
									<Col>
										{initialValues.hotel_bookings.length ? (
											<Stack gap="4">
												<Heading as="h4">
													<Icons.Bed size="6" /> Hotels
												</Heading>
												<Table
													responsive
													bordered
													headers={[
														"Hotel",
														"Room Nights",
														"Status",
														"Amount",
														"Cancellation",
													]}
													alignCols={{ 3: "right" }}
													rows={initialValues.hotel_bookings.map(
														(booking, index) => {
															const name = "hotel_bookings"
															const {
																checkin: bookingStartDate,
																no_of_nights,
																is_shared,
															} = booking
															const checkin =
																utcTimestampToLocalDate(bookingStartDate)
															const stay_nights = [
																getDiff(
																	checkin,
																	utcTimestampToLocalDate(start_date),
																	"days"
																) + 1,
															]
															for (let i = 1; i < no_of_nights; i++) {
																stay_nights.push(stay_nights[i - 1] + 1)
															}
															return [
																<Box>
																	<Box fontWeight="semibold">
																		{booking.hotel.name}
																	</Box>
																	<Box fontSize="sm">
																		{joinAttributes(
																			booking.hotel.location.short_name,
																			booking.hotel.stars ? (
																				<Stars stars={booking.hotel.stars} />
																			) : null,
																			<PhoneNumber
																				value={
																					booking.hotel.address.phone_numbers
																				}
																				iconOnly
																			/>
																		)}
																	</Box>
																</Box>,
																<Box>
																	<Box>
																		<Box>
																			{stay_nights
																				.map((s) => withOrdinalSuffix(s))
																				.join(",")}{" "}
																			N
																		</Box>
																	</Box>
																	{booking.details?.length ? (
																		<Box marginTop="1" fontSize="xs">
																			{booking.details.map(
																				(bookingDetail, i) => {
																					const {
																						date,
																						meal_plan,
																						room_type,
																						no_of_rooms,
																						comments,
																						adults_with_extra_bed,
																						children_with_extra_bed,
																						children_without_extra_bed,
																					} = bookingDetail
																					const night =
																						getDiff(
																							utcTimestampToLocalDate(date),
																							checkin,
																							"days"
																						) + 1
																					return (
																						<Box
																							key={`${bookingDetail.id}-${id}-${i}`}
																							title={`${withOrdinalSuffix(
																								night
																							)} Night Services`}
																						>
																							{stay_nights.length > 1 ? (
																								<Box display="inline">
																									{night}N{" - "}
																								</Box>
																							) : null}
																							<Box display="inline">
																								{joinAttributes(
																									meal_plan.name,
																									`${no_of_rooms} ${room_type.name}`,
																									[
																										!!adults_with_extra_bed,
																										`${adults_with_extra_bed} AWEB`,
																									],
																									[
																										!!children_with_extra_bed,
																										`${children_with_extra_bed} CWEB`,
																									],
																									[
																										!!children_without_extra_bed,
																										`${children_without_extra_bed} CNB`,
																									]
																								)}
																							</Box>
																							{!is_shared && comments ? (
																								<Box maxWidth="sm">
																									<blockquote>
																										{comments}
																									</blockquote>
																								</Box>
																							) : null}
																						</Box>
																					)
																				}
																			)}
																		</Box>
																	) : (
																		<Box
																			fontSize="xs"
																			color="muted"
																			marginTop="1"
																		>
																			No Room Services
																		</Box>
																	)}
																	{booking.extras?.length ? (
																		<Box marginTop="2">
																			<Box fontWeight="semibold">Extras</Box>
																			<Box
																				fontSize="xs"
																				marginTop="1"
																				maxWidth="xs"
																			>
																				{booking.extras.map((extra, i) => {
																					const { date, service, comments } =
																						extra
																					return (
																						<Box key={`${extra.id}-${id}-${i}`}>
																							{stay_nights.length > 1 &&
																							date ? (
																								<Box display="inline">
																									{getDiff(
																										date,
																										booking.checkin,
																										"days"
																									) + 1}
																									N{" - "}
																								</Box>
																							) : null}
																							<Box display="inline">
																								{joinAttributes(service.name)}
																							</Box>
																							{!is_shared && comments ? (
																								<blockquote>
																									{comments}
																								</blockquote>
																							) : null}
																						</Box>
																					)
																				})}
																			</Box>
																		</Box>
																	) : null}
																</Box>,
																<Box>
																	<Box fontWeight="semibold">
																		{booking.booking_stage.title}
																	</Box>
																	<Box fontSize="sm">
																		{booking.booking_stage.tag}
																	</Box>
																</Box>,
																<Box>
																	{isNumeric(booking.booked_price) &&
																	booking.currency ? (
																		<Box>
																			<Money
																				showCurrency
																				currency={booking.currency}
																				amount={booking.booked_price}
																			/>
																		</Box>
																	) : null}
																	{isNumeric(booking.paid_payments_amount) ||
																	booking.booked ? (
																		<Box
																			fontSize="sm"
																			color="danger"
																			title="Paid Amount"
																		>
																			<Box
																				display="inlineBlock"
																				marginRight="1"
																			>
																				<Icons.Ok />
																			</Box>
																			<Money
																				amount={
																					booking.paid_payments_amount || 0
																				}
																				currency={booking.currency || "INR"}
																			/>
																		</Box>
																	) : null}
																</Box>,
																<Box>
																	<TextInputField
																		name={`${name}[${index}].cancellation_charges`}
																		type="number"
																		placeholder="1000"
																		min={0}
																	/>
																</Box>,
															]
														}
													)}
												/>
												<Box textAlign="right">
													<Box
														display="inlineBlock"
														bgColor="warning"
														borderWidth="1"
														rounded="md"
														color="warning"
														paddingX="6"
														paddingY="3"
														fontWeight="semibold"
														fontSize="md"
													>
														Total Hotel Charges:{" "}
														<GetFieldValue<
															TDropTripParams["total_hotel_bookings_cancellation_charges"]
														> name="total_hotel_bookings_cancellation_charges">
															{({ value }) =>
																value ? <MoneySum money={value} /> : null
															}
														</GetFieldValue>
													</Box>
												</Box>
											</Stack>
										) : null}
										{initialValues.cab_bookings.length ? (
											<Stack gap="4">
												<Heading as="h4">
													<Icons.Bus size="6" /> Cabs
												</Heading>
												<Table
													responsive
													bordered
													headers={[
														"Day",
														"Service",
														"Supplier",
														"Status",
														"Amount",
														"Cancellation",
													]}
													alignCols={{ 4: "right" }}
													rows={initialValues.cab_bookings.map(
														(booking, index) => {
															const name = "cab_bookings"
															const day = booking.cab_schedule?.start_date
																? getDiff(
																		utcTimestampToLocalDate(
																			booking.cab_schedule?.start_date
																		),
																		utcTimestampToLocalDate(trip.start_date),
																		"day"
																	) + 1
																: index + 1
															return [
																<Box>{withOrdinalSuffix(day)}</Box>,
																<Box>
																	<Box fontWeight="semibold">
																		{
																			booking.cab_schedule?.transport_service
																				.from_to
																		}
																	</Box>
																	<Box fontSize="sm">
																		{joinAttributes(
																			booking.cab_schedule?.transport_service
																				.service,
																			booking.cab_type.name
																		)}
																	</Box>
																</Box>,
																<Box>
																	{booking.transport_service_provider ? (
																		<Box>
																			<Box fontWeight="semibold">
																				{
																					booking.transport_service_provider
																						.name
																				}
																			</Box>
																			<Box fontSize="sm">
																				{joinAttributes(
																					...(
																						booking.transport_service_provider
																							.contacts || []
																					).map((c) => (
																						<Box key={c.id}>
																							{c.name}{" "}
																							<PhoneNumber
																								value={c.phone_numbers}
																								iconOnly
																							/>
																						</Box>
																					))
																				)}
																			</Box>
																		</Box>
																	) : (
																		<Box color="muted" fontSize="sm">
																			Not Set
																		</Box>
																	)}
																</Box>,
																<Box>
																	<Box fontWeight="semibold">
																		{booking.booked ? "Booked" : "Initialized"}
																	</Box>
																</Box>,
																<Box>
																	{isNumeric(booking.booked_price) ? (
																		<Box>
																			<Money
																				amount={booking.booked_price}
																				currency={booking.currency}
																				showCurrency
																			/>
																		</Box>
																	) : null}
																	{booking.paid_payments_amount !== undefined &&
																	booking.paid_payments_amount !== null ? (
																		<Box
																			fontSize="sm"
																			color="danger"
																			title="Paid Amount"
																		>
																			<Box
																				marginRight="1"
																				display="inlineBlock"
																			>
																				<Icons.Ok />
																			</Box>
																			<Money
																				amount={booking.paid_payments_amount}
																				currency={booking.currency}
																			/>
																		</Box>
																	) : null}
																</Box>,
																<Box>
																	<TextInputField
																		name={`${name}[${index}].cancellation_charges`}
																		type="number"
																		placeholder="10000"
																		min="0"
																		disabled={
																			!booking.transport_service_provider
																		}
																		title={
																			!booking.transport_service_provider
																				? "To add cancellation, please assign a service provider first"
																				: undefined
																		}
																	/>
																</Box>,
															]
														}
													)}
												/>
												<Box textAlign="right">
													<Box
														display="inlineBlock"
														bgColor="warning"
														borderWidth="1"
														rounded="md"
														color="warning"
														paddingX="6"
														paddingY="3"
														fontWeight="semibold"
														fontSize="md"
													>
														Total Cab Charges:{" "}
														<GetFieldValue<
															TDropTripParams["total_cab_bookings_cancellation_charges"]
														> name="total_cab_bookings_cancellation_charges">
															{({ value }) =>
																value ? <MoneySum money={value} /> : null
															}
														</GetFieldValue>
													</Box>
												</Box>
											</Stack>
										) : null}
										{initialValues.travel_activity_bookings.length ? (
											<Stack gap="4">
												<Heading as="h4">
													<Icons.Ticket size="6" /> Travel Activity Bookings
												</Heading>
												<Table
													responsive
													bordered
													headers={[
														"Day",
														"Service",
														"Supplier",
														"Status",
														"Amount",
														"Cancellation",
													]}
													alignCols={{ 4: "right" }}
													rows={initialValues.travel_activity_bookings.map(
														(booking, index) => {
															const name = "travel_activity_bookings"
															const day = booking?.start_date
																? getDiff(
																		utcTimestampToLocalDate(booking.start_date),
																		utcTimestampToLocalDate(trip.start_date),
																		"day"
																	) + 1
																: index + 1
															return [
																<Box>{withOrdinalSuffix(day)}</Box>,
																<Box>
																	<Box fontWeight="semibold">
																		{booking.title}
																	</Box>
																	<Box fontSize="sm">
																		{joinAttributes(
																			booking.ticket_tourist_configurations_short_name,
																			booking.start_time_formatted,
																			booking.end_time_formatted
																		)}
																	</Box>
																</Box>,
																<Box>
																	{booking.supplier ? (
																		<Box>
																			<Box fontWeight="semibold">
																				{booking.supplier.name}
																			</Box>
																			<Box fontSize="sm">
																				{joinAttributes(
																					...(
																						booking.supplier.contacts || []
																					).map((c) => (
																						<Box key={c.id}>
																							{c.name}{" "}
																							<PhoneNumber
																								value={c.phone_numbers}
																								iconOnly
																							/>
																						</Box>
																					))
																				)}
																			</Box>
																		</Box>
																	) : (
																		<Box color="muted" fontSize="sm">
																			Not Set
																		</Box>
																	)}
																</Box>,
																<Box>
																	<Box fontWeight="semibold">
																		{booking.booked ? "Booked" : "Initialized"}
																	</Box>
																</Box>,
																<Box>
																	{isNumeric(booking.booked_price) ? (
																		<Box>
																			<Money
																				amount={booking.booked_price}
																				currency={booking.currency}
																				showCurrency
																			/>
																		</Box>
																	) : null}
																	{booking.paid_payments_amount !== undefined &&
																	booking.paid_payments_amount !== null ? (
																		<Box
																			fontSize="sm"
																			color="danger"
																			title="Paid Amount"
																		>
																			<Box
																				marginRight="1"
																				display="inlineBlock"
																			>
																				<Icons.Ok />
																			</Box>
																			<Money
																				amount={booking.paid_payments_amount}
																				currency={booking.currency}
																			/>
																		</Box>
																	) : null}
																</Box>,
																<Box>
																	<TextInputField
																		name={`${name}[${index}].cancellation_charges`}
																		type="number"
																		placeholder="10000"
																		min="0"
																		disabled={!booking.supplier}
																		title={
																			!booking.supplier
																				? "To add cancellation, please assign a service provider first"
																				: undefined
																		}
																	/>
																</Box>,
															]
														}
													)}
												/>
												<Box textAlign="right">
													<Box
														display="inlineBlock"
														bgColor="warning"
														borderWidth="1"
														rounded="md"
														color="warning"
														paddingX="6"
														paddingY="3"
														fontWeight="semibold"
														fontSize="md"
													>
														Total Travel Activity Charges:{" "}
														<GetFieldValue<
															TDropTripParams["total_travel_activity_bookings_cancellation_charges"]
														> name="total_travel_activity_bookings_cancellation_charges">
															{({ value }) =>
																value ? <MoneySum money={value} /> : null
															}
														</GetFieldValue>
													</Box>
												</Box>
											</Stack>
										) : null}
									</Col>
								</Grid>
							</Container>
							<CalculateTotalBookingsCancellationCharges
								packageCurrency={packageCurrency}
								convertCurrency={convertCurrency}
							/>
							<Container paddingY="8" bgColor="success">
								<Grid gap="4">
									<Col md={12} lg={3}>
										<Stack gap="1">
											<Heading fontSize="md" as="h3">
												Cancellation Reason and Charges
											</Heading>
											<Text color="muted">
												Please review the the payments and provide the
												cancellation charges for this trip.
											</Text>
										</Stack>
									</Col>
									<Col>
										<Grid gap="6">
											<Col sm={12} md>
												<TripCancellationChargesField
													packageCurrency={packageCurrency}
													paidPaymentsAmount={paid_payments_amount}
													paymentsAmount={payments_amount}
													totalPaidPaymentsForAllBookingsInPackageCurrency={
														totalPaidPaymentsForAllBookingsInPackageCurrency
													}
												/>
											</Col>
											<Col>
												<Box
													padding="4"
													rounded="lg"
													bgColor="default"
													borderWidth="1"
												>
													<Stack gap="4">
														<SelectField
															select={SelectTripCancellationReason}
															name="reason"
															label="Reason"
															forDrop
															fetchOnMount
														/>
														<TextAreaInputField
															name="comments"
															label="More Details/Comments"
															placeholder="Any more information regarding the trip drop"
														/>
													</Stack>
												</Box>
											</Col>
										</Grid>
									</Col>
								</Grid>
							</Container>
							<Container paddingY="6">
								<Grid>
									<Col md={12} lg={{ offset: 3, span: 9 }}>
										<Stack gap="4">
											<SubmissionError />
											<Inline gap="4">
												<Component
													initialState={{
														showDialog: false,
														confirmedTripId: "" as string,
													}}
												>
													{({ state, setState }) => {
														function openDialog() {
															setState({
																showDialog: true,
																confirmedTripId: "",
															})
														}
														function closeDialog() {
															setState({
																showDialog: false,
																confirmedTripId: "",
															})
														}
														return (
															<>
																<Button
																	type="button"
																	level="primary"
																	status="primary"
																	size="lg"
																	onClick={() => {
																		const errors = form.getState().errors || {}
																		if (Object.keys(errors).length > 0) {
																			Object.keys(errors).forEach((field) =>
																				form.blur(field as never)
																			)
																			// alert('Please provide all required fields before dropping.')
																		} else {
																			openDialog()
																		}
																	}}
																	disabled={submitting}
																>
																	{submitting ? "Dropping..." : "Drop Trip"}
																</Button>
																<Dialog
																	open={state.showDialog}
																	onClose={() => closeDialog()}
																	title="Trip Drop Confirmation"
																>
																	<FormSpy<TDropTripParams>>
																		{({ values, form }) => (
																			<>
																				<Dialog.Body>
																					<Stack gap="4">
																						<Stack gap="2" marginBottom="8">
																							<Heading as="h4" fontSize="md">
																								Cancellation summary
																							</Heading>
																							<Text color="muted">
																								Please review these details
																								carefully and make sure
																								everything is as per the
																								requirements.
																							</Text>
																							<Stack
																								borderWidth="1"
																								rounded="lg"
																								overflow="hidden"
																							>
																								<Box borderBottomWidth="1">
																									<Inline
																										flexWrap="wrap"
																										bgColor="subtle"
																										gap="6"
																										padding="4"
																									>
																										<Box>
																											<Box
																												fontSize="sm"
																												color="muted"
																												fontWeight="semibold"
																											>
																												Guest Details
																											</Box>
																											<Box
																												fontSize="lg"
																												fontWeight="semibold"
																											>
																												{trip.contact.name}
																											</Box>
																											<Box
																												fontSize="sm"
																												color="muted"
																												marginTop="1"
																											>
																												{joinAttributes(
																													trip.contact
																														.phone_numbers
																														?.length ? (
																														<PhoneNumber
																															value={
																																trip.contact
																																	.phone_numbers
																															}
																														/>
																													) : null,
																													trip.contact.email ? (
																														<Email
																															value={
																																trip.contact
																																	.email
																															}
																															iconOnly
																														/>
																													) : null
																												)}
																											</Box>
																										</Box>
																										<Box>
																											<Box
																												fontSize="sm"
																												color="muted"
																												fontWeight="semibold"
																											>
																												Trip Details
																											</Box>
																											<Box
																												fontSize="lg"
																												fontWeight="semibold"
																											>
																												{joinAttributes(
																													trip.destinations
																														.map(
																															(d) =>
																																d.short_name
																														)
																														.join(","),
																													<Time
																														value={utcTimestampToLocalDate(
																															trip.start_date
																														)}
																													/>,
																													<TimeDuration
																														value={duration(
																															days,
																															"days"
																														)}
																													>
																														{trip.nights}N
																													</TimeDuration>
																												)}
																											</Box>
																											<Box
																												fontSize="sm"
																												color="muted"
																												marginTop="1"
																											>
																												{joinAttributes(
																													`Trip ID: ${trip.id}`,
																													`${
																														trip.trip_source
																															.short_name
																													}${
																														trip.reference_id
																															? `-${trip.reference_id}`
																															: ``
																													}`
																												)}
																											</Box>
																										</Box>
																									</Inline>
																								</Box>
																								<Inline
																									flexWrap="wrap"
																									gap="6"
																									padding="4"
																								>
																									{isNumeric(
																										payments_amount
																									) ? (
																										<Box>
																											<Box
																												fontSize="sm"
																												color="muted"
																												fontWeight="semibold"
																											>
																												Package Amount (
																												{packageCurrency})
																											</Box>
																											<Box>
																												<Money
																													amount={
																														payments_amount
																													}
																													currency={
																														packageCurrency
																													}
																													fontSize="2xl"
																													color="success"
																													fontWeight="semibold"
																												/>
																											</Box>
																										</Box>
																									) : null}
																									<Box>
																										<Box
																											fontSize="sm"
																											color="muted"
																											fontWeight="semibold"
																										>
																											Trip Cancellation
																										</Box>
																										<Box>
																											<Money
																												amount={
																													values.cancellation_charges ||
																													0
																												}
																												currency={
																													packageCurrency
																												}
																												fontSize="2xl"
																												color="success"
																												fontWeight="semibold"
																											/>
																											<Box
																												display="flex"
																												marginTop="1"
																												fontSize="sm"
																											>
																												<Box title="Already Paid">
																													<Box
																														display="inlineBlock"
																														marginRight="1"
																													>
																														<Icons.Ok />
																													</Box>
																													<Money
																														amount={
																															paid_payments_amount ||
																															0
																														}
																														currency={
																															packageCurrency
																														}
																													/>
																												</Box>
																												{Number(
																													paid_payments_amount
																												) ||
																												0 >=
																													values.cancellation_charges ? (
																													<Box
																														fontWeight="semibold"
																														title="Refund"
																														color="warning"
																														marginLeft="3"
																													>
																														<Box
																															display="inlineBlock"
																															marginRight="1"
																														>
																															<Icons.Refresh />
																														</Box>
																														<Money
																															amount={
																																Number(
																																	paid_payments_amount ||
																																		0
																																) -
																																Number(
																																	values.cancellation_charges ||
																																		0
																																)
																															}
																															currency={
																																packageCurrency
																															}
																														/>
																													</Box>
																												) : null}
																											</Box>
																										</Box>
																									</Box>
																									<Box>
																										<Box
																											fontSize="sm"
																											color="muted"
																											fontWeight="semibold"
																										>
																											Services Cancellation
																										</Box>
																										<Box>
																											<Money
																												money={
																													values.total_bookings_cancellation_charges
																												}
																												fontSize="2xl"
																												color="danger"
																												fontWeight="semibold"
																											/>
																											{totalPaidPaymentsForAllBookings ? (
																												<Box
																													display="flex"
																													marginTop="1"
																													fontSize="sm"
																												>
																													<Box title="Already Paid">
																														<Box
																															display="inlineBlock"
																															marginRight="1"
																														>
																															<Icons.Ok />
																														</Box>
																														<Money
																															money={
																																totalPaidPaymentsForAllBookingsInPackageCurrency
																															}
																														/>
																													</Box>
																													{totalPaidPaymentsForAllBookingsInPackageCurrency.amount >=
																													values
																														.total_bookings_cancellation_charges
																														.amount ? (
																														<Box
																															fontWeight="semibold"
																															title="Refund"
																															color="success"
																															marginLeft="3"
																														>
																															<Box
																																display="inlineBlock"
																																marginRight="1"
																															>
																																<Icons.Refresh />
																															</Box>
																															<Money
																																money={{
																																	currency:
																																		values
																																			.total_bookings_cancellation_charges
																																			.currency,
																																	amount:
																																		totalPaidPaymentsForAllBookingsInPackageCurrency.amount -
																																		values
																																			.total_bookings_cancellation_charges
																																			.amount,
																																}}
																															/>
																														</Box>
																													) : null}
																												</Box>
																											) : null}
																										</Box>
																									</Box>
																									<Box>
																										<Box
																											fontSize="sm"
																											color="muted"
																											fontWeight="semibold"
																										>
																											Profit ({packageCurrency})
																										</Box>
																										<Box>
																											<Money
																												money={{
																													amount:
																														moneyParseByDecimal(
																															values.cancellation_charges ||
																																0,
																															values
																																.total_bookings_cancellation_charges
																																.currency
																														).amount -
																														values
																															.total_bookings_cancellation_charges
																															.amount,
																													currency:
																														values
																															.total_bookings_cancellation_charges
																															.currency,
																												}}
																												fontSize="2xl"
																												color="success"
																												fontWeight="semibold"
																											/>
																										</Box>
																									</Box>
																								</Inline>
																								<Stack
																									borderTopWidth="1"
																									padding="4"
																									gap="1"
																								>
																									<Heading
																										fontSize="sm"
																										color="muted"
																										fontWeight="semibold"
																									>
																										Reason
																									</Heading>
																									<Text
																										fontWeight="semibold"
																										fontSize="lg"
																									>
																										{values.reason?.name}
																									</Text>
																									<Text
																										fontSize="sm"
																										color="muted"
																										whiteSpace="preserveAndWrap"
																									>
																										{values.comments}
																									</Text>
																								</Stack>
																							</Stack>
																						</Stack>
																						<Stack
																							gap="2"
																							padding="4"
																							rounded="lg"
																							bgColor="primary"
																							borderWidth="1"
																							borderColor="primary"
																						>
																							<Heading as="h4">
																								<Icons.InfoSolid size="8" />{" "}
																								What happens when you drop this
																								trip
																							</Heading>
																							<Text
																								color="danger"
																								fontWeight="semibold"
																							>
																								Please read these actions
																								carefully.
																							</Text>
																							<Stack
																								as="ul"
																								listStyleType="disc"
																								marginLeft="6"
																								gap="2"
																							>
																								<Box as="li">
																									This trip will moved to the{" "}
																									<b>Dropped</b> stage.
																								</Box>
																								<Box as="li">
																									All services bookings
																									(hotels/cabs) will be moved to
																									the <b>Trip Dropped</b> stage
																									and <b>can NOT be updated</b>.
																								</Box>
																								<Box as="li">
																									All paid payments will be
																									untouched. However, unpaid
																									payments will be updated to
																									reflect the{" "}
																									<b>
																										cancellation/refund amounts
																									</b>
																									.
																								</Box>
																							</Stack>
																						</Stack>
																					</Stack>
																					<Divider />
																					<InputGroup
																						hasError={
																							!state.confirmedTripId ||
																							state.confirmedTripId.toString() !==
																								trip.id.toString()
																						}
																					>
																						<Stack gap="1">
																							<label htmlFor="confirmedTripId">
																								Please Type &ldquo;{trip.id}
																								&rdquo; to confirm
																							</label>
																							<Input
																								size="lg"
																								id="confirmedTripId"
																								name="confirmedTripId"
																								value={state.confirmedTripId}
																								type="text"
																								onChange={(e) => {
																									setState({
																										showDialog: true,
																										confirmedTripId:
																											e.currentTarget.value,
																									})
																								}}
																							/>
																						</Stack>
																					</InputGroup>
																				</Dialog.Body>
																				<Dialog.Footer>
																					<Stack gap="6">
																						<Alert
																							status="warning"
																							title="Once dropped, it can not be reversed"
																						>
																							This action can not be reversed.
																							Are you sure you want to drop this
																							trip ?
																						</Alert>
																						<Inline gap="4">
																							<Button
																								level="primary"
																								status="warning"
																								size="lg"
																								disabled={
																									(
																										state.confirmedTripId || ""
																									).toString() !==
																									trip.id.toString()
																								}
																								onClick={() => {
																									form.submit()
																									closeDialog()
																								}}
																							>
																								I understand. Drop this Trip
																							</Button>
																							<Button
																								onClick={() => closeDialog()}
																								size="lg"
																							>
																								Go Back
																							</Button>
																						</Inline>
																					</Stack>
																				</Dialog.Footer>
																			</>
																		)}
																	</FormSpy>
																</Dialog>
															</>
														)
													}}
												</Component>
												<Button
													disabled={submitting}
													onClick={() => backToTripDetails()}
													size="lg"
												>
													Back
												</Button>
											</Inline>
										</Stack>
									</Col>
								</Grid>
							</Container>
						</form>
					)
				}}
			</Form>
		</Box>
	)
}

function CalculateTotalBookingsCancellationCharges({
	packageCurrency,
	convertCurrency,
}: {
	packageCurrency: string
	convertCurrency: ReturnType<typeof makeCurrencyConverter>
}) {
	const { value: hotel_bookings } =
		useFieldValue<TDropTripParams["hotel_bookings"]>("hotel_bookings")
	const { value: cab_bookings } =
		useFieldValue<TDropTripParams["cab_bookings"]>("cab_bookings")
	const { value: travel_activity_bookings } = useFieldValue<
		TDropTripParams["travel_activity_bookings"]
	>("travel_activity_bookings")
	const form = useForm<TDropTripParams>()

	const totalHotelBookingCancellationCharges = useMemo(() => {
		return addMoneyFromDifferentCurrencies(
			hotel_bookings.map((b) =>
				moneyParseByDecimal(b.cancellation_charges || 0, b.currency || "INR")
			)
		)
	}, [hotel_bookings])

	useEffect(() => {
		form.change(
			"total_hotel_bookings_cancellation_charges",
			totalHotelBookingCancellationCharges
		)
	}, [form, totalHotelBookingCancellationCharges])

	const totalCabBookingCancellationCharges = useMemo(() => {
		return addMoneyFromDifferentCurrencies(
			cab_bookings.map((b) =>
				moneyParseByDecimal(b.cancellation_charges || 0, b.currency || "INR")
			)
		)
	}, [cab_bookings])

	useEffect(() => {
		form.change(
			"total_cab_bookings_cancellation_charges",
			totalCabBookingCancellationCharges
		)
	}, [form, totalCabBookingCancellationCharges])

	const totalTravelActivityBookingCancellationCharges = useMemo(() => {
		return addMoneyFromDifferentCurrencies(
			travel_activity_bookings.map((b) =>
				moneyParseByDecimal(b.cancellation_charges || 0, b.currency || "INR")
			)
		)
	}, [travel_activity_bookings])

	useEffect(() => {
		form.change(
			"total_travel_activity_bookings_cancellation_charges",
			totalTravelActivityBookingCancellationCharges
		)
	}, [form, totalTravelActivityBookingCancellationCharges])

	const totalBookingCancellationChargesInPackageCurrency = useMemo(() => {
		const totalHotelBookingCancellationChargesInPackageCurrency =
			convertCurrency(packageCurrency, totalHotelBookingCancellationCharges)

		const totalCabBookingCancellationChargesInPackageCurrency = convertCurrency(
			packageCurrency,
			totalCabBookingCancellationCharges
		)

		const totalTravelActivityBookingCancellationChargesInPackageCurrency =
			convertCurrency(
				packageCurrency,
				totalTravelActivityBookingCancellationCharges
			)
		return addMoney(
			totalHotelBookingCancellationChargesInPackageCurrency,
			totalCabBookingCancellationChargesInPackageCurrency,
			totalTravelActivityBookingCancellationChargesInPackageCurrency
		)
	}, [
		convertCurrency,
		totalHotelBookingCancellationCharges,
		totalCabBookingCancellationCharges,
		totalTravelActivityBookingCancellationCharges,
		packageCurrency,
	])
	useEffect(() => {
		const handler = setTimeout(() => {
			form.change(
				"total_bookings_cancellation_charges",
				totalBookingCancellationChargesInPackageCurrency
			)
		}, 100)
		return () => clearTimeout(handler)
	}, [form, totalBookingCancellationChargesInPackageCurrency])
	return null
}

function TripCancellationChargesField({
	packageCurrency,
	paymentsAmount: payments_amount,
	paidPaymentsAmount: paid_payments_amount,
	totalPaidPaymentsForAllBookingsInPackageCurrency,
}: {
	packageCurrency: string
	paymentsAmount?: number
	paidPaymentsAmount?: number
	totalPaidPaymentsForAllBookingsInPackageCurrency: TMoney
}) {
	const { value: totalBookingCancellationChargesInPackageCurrency } =
		useFieldValue<TDropTripParams["total_bookings_cancellation_charges"]>(
			"total_bookings_cancellation_charges"
		)
	return (
		<Stack gap="4">
			<Table
				headers={["", `Amount (${packageCurrency})`, "Paid"]}
				bordered
				hover
			>
				<tbody>
					<tr>
						<TableDataCell fontWeight="semibold">Package</TableDataCell>
						<TableDataCell>
							<Money amount={payments_amount || 0} currency={packageCurrency} />
						</TableDataCell>
						<TableDataCell>
							<Money
								amount={paid_payments_amount || 0}
								currency={packageCurrency}
								color="success"
								fontWeight="semibold"
							/>
						</TableDataCell>
					</tr>
					<tr>
						<TableDataCell fontWeight="semibold">
							Hotel/Cab Cancellation
						</TableDataCell>
						<TableDataCell>
							<Money
								money={totalBookingCancellationChargesInPackageCurrency}
								fontSize="xl"
							/>
						</TableDataCell>
						<TableDataCell>
							<Money money={totalPaidPaymentsForAllBookingsInPackageCurrency} />
						</TableDataCell>
					</tr>
					<tr>
						<TableDataCell fontWeight="semibold">
							Trip Cancellation
						</TableDataCell>
						<TableDataCell>
							<TextInputField
								name="cancellation_charges"
								placeholder="0"
								type="number"
								size="sm"
								min={0}
								max={payments_amount}
							/>
						</TableDataCell>
						<TableDataCell></TableDataCell>
					</tr>
				</tbody>
				<GetFieldValue<
					TDropTripParams["cancellation_charges"]
				> name="cancellation_charges">
					{({ value: cancellation_charges }) => (
						<Box
							as="tfoot"
							borderTopWidth="1"
							bgColor={
								totalBookingCancellationChargesInPackageCurrency.amount >
								cancellation_charges
									? "danger"
									: (paid_payments_amount || 0) < cancellation_charges
										? "warning"
										: "success"
							}
						>
							<Box as="tr">
								<TableDataCell
									textAlign="right"
									fontSize="lg"
									fontWeight="semibold"
								>
									Profit
								</TableDataCell>
								<TableDataCell>
									<Money
										fontSize="lg"
										fontWeight="semibold"
										showCurrency
										money={{
											currency: packageCurrency,
											amount:
												moneyParseByDecimal(
													cancellation_charges || 0,
													packageCurrency
												).amount -
												totalBookingCancellationChargesInPackageCurrency.amount,
										}}
									/>
								</TableDataCell>
								<TableDataCell></TableDataCell>
							</Box>
						</Box>
					)}
				</GetFieldValue>
			</Table>
			<Field<
				TDropTripParams["cancellation_charges"]
			> name="cancellation_charges">
				{({
					input: { value: cancellation_charges },
					meta: cancellation_charges_field_meta,
				}) => (
					<Field name="reason">
						{({ meta: reason_field_meta }) =>
							cancellation_charges_field_meta.touched ||
							reason_field_meta.touched ? (
								<>
									{totalBookingCancellationChargesInPackageCurrency.amount >
									moneyParseByDecimal(
										cancellation_charges || 0,
										packageCurrency
									).amount ? (
										<Alert
											status="error"
											title="Higher Booking Cancellation Charges"
										>
											Booking charges of hotels/cabs (
											<Money
												money={totalBookingCancellationChargesInPackageCurrency}
											/>{" "}
											/-) are higher than the cancellation charges(
											<Money
												amount={cancellation_charges || 0}
												currency={packageCurrency}
											/>
											). This signals an overall loss.
										</Alert>
									) : null}
									{(paid_payments_amount || 0) < cancellation_charges ? (
										<Alert
											status="warning"
											title="Insufficient paid payments from guest/source"
										>
											Guest/Source has only paid{" "}
											<Money
												amount={paid_payments_amount || 0}
												currency={packageCurrency}
												showCurrency
											/>{" "}
											/- amount for this trip. Getting more payments might not
											be possible after cancellation.
										</Alert>
									) : null}
								</>
							) : null
						}
					</Field>
				)}
			</Field>
		</Stack>
	)
}
