import { Alert } from "@sembark-travel/ui/base"
import { localOrUtcTimeToLocalDate } from "@sembark-travel/datetime-utils"
import { Spinner } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { useMemo } from "react"
import { $PropertyType } from "utility-types"
import { NewHotelForm, NewItemProps } from "./NewItem"
import { IHotel, useHotel } from "./store"

export function UpdateHotel({
	hotel: initialHotel,
	...props
}: Omit<NewItemProps, "onSubmit"> & {
	hotel: { id: number }
	onSuccess?: (hotel: IHotel) => void
}) {
	const { hotel, isFetching, error } = useHotel(initialHotel.id)
	if (isFetching) {
		return <Spinner padding="4" alignCenter />
	}
	if (!hotel && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!hotel) {
		return null
	}
	return <UpdateHotelForm {...props} hotel={hotel} />
}

function UpdateHotelForm({
	hotel,
	onSuccess,
	...props
}: Omit<NewItemProps, "onSubmit"> & {
	hotel: IHotel
	onSuccess?: (hotel: IHotel) => void
}) {
	const xhr = useXHR()
	const { room_types } = hotel
	const transformedRoomTypes = useMemo(
		() => groupRoomTypesWithSimilarConfigs(room_types),
		[room_types]
	)
	const initialValues = useMemo(() => {
		const {
			name,
			stars,
			address,
			location: hotelLocation,
			payment_preference,
			meal_plans,
			extra_bed_child_age_start,
			extra_bed_child_age_end,
			checkin_at,
			checkin_at_local,
			checkout_at,
			checkout_at_local,
			group,
			trip_destinations,
			url,
		} = hotel
		const { id, text, location, email, ...otherAddressData } = address
		return {
			name,
			location: hotelLocation,
			group,
			stars: stars || undefined,
			payment_preference,
			meal_plans,
			room_types: transformedRoomTypes,
			extra_bed_child_age_start,
			extra_bed_child_age_end,
			address: {
				...otherAddressData,
				location: location,
				city: location?.city,
				state: location?.state,
				country: location?.country,
				email: email?.email || "",
			},
			checkin_at: localOrUtcTimeToLocalDate(checkin_at_local, checkin_at),
			checkout_at: localOrUtcTimeToLocalDate(checkout_at_local, checkout_at),
			trip_destinations,
			url,
		}
	}, [hotel, transformedRoomTypes])
	return (
		<NewHotelForm
			{...props}
			onSubmit={async (data) => {
				const resp = await xhr.patch(`/hotels/${hotel.id}`, data)
				const newHotel = resp.data.data
				onSuccess && onSuccess(newHotel)
				return newHotel
			}}
			initialValues={initialValues}
		/>
	)
}

function groupRoomTypesWithSimilarConfigs(
	roomTypes: $PropertyType<IHotel, "room_types">
) {
	const roomTypesByConfig = roomTypes.reduce<{
		[key: string]: typeof roomTypes
	}>((carry, roomType) => {
		const key =
			roomType.allowed_extra_beds + "-" + (roomType.no_of_rooms || "#")
		if (!carry[key]) {
			carry[key] = []
		}
		carry[key].push(roomType)
		return carry
	}, {})
	return Object.keys(roomTypesByConfig).map((key) => {
		const roomTypes = roomTypesByConfig[key]
		const {
			allowed_extra_beds,
			no_of_rooms,
			allowed_adults_with_extra_bed,
			allowed_children_with_extra_bed,
			allowed_children_without_extra_bed,
		} = roomTypes[0]
		return {
			allowed_extra_beds,
			no_of_rooms,
			allowed_adults_with_extra_bed,
			allowed_children_with_extra_bed,
			allowed_children_without_extra_bed,
			room_types: roomTypes.map(
				({ allowed_extra_beds, no_of_rooms, ...roomType }) => roomType
			),
		}
	})
}
