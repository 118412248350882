import {
	Box,
	Button,
	Inline,
	Icons,
	Table,
	Stack,
	Text,
	SwitchInput,
} from "@sembark-travel/ui/base"
import { Required } from "utility-types"
import { collect } from "../utils"
import { SelectCabTypes, ICabType } from "./../CabTypes"
import {
	Form,
	TextInputField,
	SelectField,
	withServerErrors,
	arrayMutators,
	FieldArray,
	SubmissionError,
	useFieldValue,
} from "@sembark-travel/ui/form"
import { type TTripDestination } from "../TripDestinations"
import { TableFooterDataCell } from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { useState } from "react"

export type TUsedCabTypes = Array<{
	cab_type: ICabType
	no_of_cabs: number
}>

export function UsedCabTypesInputField({
	tripDestinations,
	name,
	getInitialCabs,
}: {
	tripDestinations: Array<TTripDestination>
	name: string
	getInitialCabs: TGetInitialCabs
}) {
	const { value, onChange } = useFieldValue<TUsedCabTypes>(name)
	const [isDialogOpen, openDialog, closeDialog] = useDialog()
	return (
		<Box>
			<Inline gap="4" alignItems="center">
				<Inline as="label" gap="2" alignItems="center">
					<SwitchInput
						id={`field_${name}`}
						data-testid={`field_${name}`}
						checked={Boolean(value?.length)}
						onChange={(checked) => {
							if (!checked) {
								onChange([])
							} else {
								openDialog()
							}
						}}
					/>
					<Text as="span">Same Cab Type for All</Text>
				</Inline>
				{value?.length ? (
					<Button
						onClick={() => openDialog()}
						status="primary"
						level="secondary"
						title="Selected cab types. Click to Edit"
					>
						<Text fontWeight="semibold" color="primary">
							{value
								.map((v) => `${v.no_of_cabs}-${v.cab_type.name}`)
								.join(" + ")}
						</Text>
						<Icons.Pencil />
					</Button>
				) : null}
			</Inline>
			<Dialog
				open={isDialogOpen}
				onClose={() => closeDialog()}
				title="Select Cab Types"
				sm
			>
				<SelectUsedCabTypesForm
					initialCabTypes={value}
					getInitialCabs={getInitialCabs}
					onSubmit={async (values) => {
						onChange(values)
						closeDialog()
					}}
					tripDestinations={tripDestinations}
					onCancel={closeDialog}
				/>
			</Dialog>
		</Box>
	)
}

type TGetInitialCabs = () => Array<{
	cabs?: Array<{ cab_type?: ICabType; no_of_cabs?: number }>
}>

function SelectUsedCabTypesForm({
	initialCabTypes: value,
	getInitialCabs,
	onSubmit,
	onCancel,
	tripDestinations,
}: {
	initialCabTypes?: TUsedCabTypes
	tripDestinations: Array<TTripDestination>
	getInitialCabs: TGetInitialCabs
	onSubmit: (data: TUsedCabTypes) => Promise<void>
	onCancel: () => void
}) {
	const [initialValues] = useState(() => {
		return {
			data: (value?.length
				? value
				: getCabTypesFromExistingData(getInitialCabs(), [
						{
							cab_type: undefined,
							no_of_cabs: 1,
						},
					])
			).map((v) => ({
				...v,
				// ensure to clone the data, otherwise prices will not be updated
				cab_type: v.cab_type ? { ...v.cab_type } : undefined,
			})),
		}
	})
	return (
		<Form<typeof initialValues>
			initialValues={initialValues}
			onSubmit={withServerErrors(async ({ data }) => {
				return onSubmit(
					collect(
						(data || []).filter(
							(value): value is Required<TUsedCabTypes[number]> =>
								Boolean(value.cab_type) && Number(value.no_of_cabs) > 0
						)
					)
						// remove duplicates
						.unique((v) => v.cab_type.name)
						.toArray()
				)
			})}
			subscription={{}}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Dialog.Body>
						<Stack gap="4">
							<FieldArray<TUsedCabTypes[number]> name="data">
								{({ fields }) => (
									<Table
										headers={["Type", "Quantity", ""]}
										hover
										bordered
										rows={fields.map((name, index) => [
											<SelectField
												name={`${name}.cab_type`}
												select={SelectCabTypes}
												tripDestinations={tripDestinations}
												fetchOnMount
												required
												creatable
											/>,
											<TextInputField
												name={`${name}.no_of_cabs`}
												type="number"
												min={1}
												required
												style={{
													maxWidth: "100px",
												}}
											/>,
											<Button
												level="tertiary"
												size="sm"
												onClick={() => fields.remove(index)}
											>
												<Icons.Cancel />
											</Button>,
										])}
									>
										<tfoot>
											<tr>
												<TableFooterDataCell colSpan={3}>
													<Button
														size="sm"
														onClick={() =>
															fields.push({
																cab_type: undefined as never,
																no_of_cabs: 1,
															})
														}
													>
														<Icons.Plus /> Add More
													</Button>
												</TableFooterDataCell>
											</tr>
										</tfoot>
									</Table>
								)}
							</FieldArray>
							<SubmissionError />
						</Stack>
					</Dialog.Body>
					<Dialog.Footer>
						<Button type="submit">Set Cab Types</Button>
						<Button onClick={() => onCancel()}>Cancel</Button>
					</Dialog.Footer>
				</form>
			)}
		</Form>
	)
}

type TCab = {
	cab_type?: ICabType
	no_of_cabs: number
}

function getCabTypesFromExistingData(
	data: ReturnType<TGetInitialCabs>,
	fallback: Array<TCab>
): Array<TCab> {
	if (data.length) {
		const cabs = Object.values(
			data.reduce<{ [key: string]: TCab }>((x, { cabs }) => {
				;(cabs || []).forEach((cab) => {
					const { cab_type } = cab
					if (cab_type) {
						if (!x[cab_type.name]) {
							x[cab_type.name] = {
								cab_type,
								no_of_cabs: cab.no_of_cabs || 1,
							}
						}
					}
				})
				return x
			}, {})
		)
		if (cabs?.length) return cabs
	}
	return fallback
}
