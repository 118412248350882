import {
	Alert,
	Badge,
	Box,
	Button,
	Stack,
	Heading,
	Inline,
	Icons,
	CheckboxInput,
	useId,
	Text,
	Col,
	Divider,
	Grid,
	joinAttributes,
	Spinner,
	Money,
	Component,
	Time,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import {
	addUnit,
	createDuration,
	dateToQuery,
	dateToUTCString,
	endOf,
	formatDate,
	isSame,
	localOrUtcTimestampToLocalDate,
	parseDate,
	setTimeFrom,
	startOf,
} from "@sembark-travel/datetime-utils"
import { IListResponse, useXHR } from "@sembark-travel/xhr"
import {
	Form,
	EmptyNumberValidator,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	arrayMutators,
	CheckboxInputField,
	SelectField,
	GetFieldValue,
	TextAreaInputField,
} from "@sembark-travel/ui/form"
import React, { useMemo, useState } from "react"
import useSWR from "swr"
import { Optional } from "utility-types"
import * as Validator from "yup"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { SelectCabs, ICab } from "../Cabs"
import { SelectDriver, TDriver } from "../Drivers"
import {
	SelectTransporServiceProviders,
	ITransportServiceProvider,
} from "../TransportServiceProviders"
import { ICabSchedule, IScheduledCab, ITripCabSchedule } from "./store"
import { MoneyInputWithTenantCurrenciesField } from "../Currencies"
import { Link, queryToSearch } from "@sembark-travel/ui/router"
import { generatePath } from "../router-utils"
import { ScheduleDetailsInDialog } from "./Item"
import {
	getEndDateTimeOfCabSchedule,
	getStartDateTimeOfCabSchedule,
} from "./utils"

const assignServiceProvidersToSchedulesValidationSchema =
	Validator.object().shape({
		booked: Validator.boolean().nullable(),
		transport_service_provider: Validator.mixed().nullable(),
		driver: Validator.mixed().nullable(),
		cab: Validator.mixed().nullable(),
		// TODO: Validate that atleast one cab is selected
		schedules: Validator.array().of(
			Validator.object().shape({
				booked_price: EmptyNumberValidator()
					.nullable()
					.min(0, "Booking price should be a non-negative value."),
			})
		),
	})

type TAssignServiceProvidersSchema = {
	transport_service_provider?: ITransportServiceProvider
	driver?: TDriver
	cab?: ICab
	schedules: Array<{
		schedule: ICabSchedule
		cab: IScheduledCab
		cab_id: number
		currency: string
		booked_price?: number
		selected: boolean
		given_price?: number
		remarks?: string
	}>
	booked: boolean
}

type AssignServiceProvidersProps = {
	tripId: number
	onSuccess: (data: ICabSchedule["cabs"]) => void
	onCancel: () => void
}

function AssignServiceProviders({
	tripId,
	onSuccess,
	onCancel,
}: AssignServiceProvidersProps) {
	const xhr = useXHR()
	const id = useId()
	const { data } = useSWR<ITripCabSchedule>(
		`/trips/${tripId}/cab-schedules?${id}`,
		() =>
			xhr.get(`/trip-cab-schedules/${tripId}`).then((resp) => resp.data.data)
	)
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const { schedules } = data
	if (!schedules.length) {
		return (
			<Box textAlign="center" padding="4">
				No cab schedules for this trip
			</Box>
		)
	}
	return (
		<AssignServiceProvidersToSchedulesForm
			schedules={schedules}
			onCancel={onCancel}
			onSubmit={async (d) => {
				const resp = await xhr.post(
					"/scheduled-cabs/assign-service-providers",
					{
						transport_service_provider: d.transport_service_provider?.id,
						driver: d.driver?.id,
						cab: d.cab?.id,
						booked: d.booked ? 1 : 0,
						remarks: d.remarks,
						schedules: d.schedules.map(
							({ selected, schedule, cab, ...otherData }) => ({
								...otherData,
								selected: selected ? 1 : 0,
							})
						),
					}
				)
				onSuccess(resp.data.data)
			}}
		/>
	)
}

export function AssignServiceProvidersInDialog({
	children,
	onSuccess,
	...props
}: { children: (props: { assign: () => void }) => React.ReactNode } & Optional<
	Omit<AssignServiceProvidersProps, "onCancel">,
	"onSuccess"
>) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ assign: () => open() })}
			<Dialog
				open={isOpen}
				onClose={close}
				title="Assign Service Provider to Schedules"
			>
				<Dialog.Body>
					<AssignServiceProviders
						onSuccess={(...args) => {
							onSuccess?.(...args)
							close()
						}}
						onCancel={close}
						{...props}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function AssignServiceProvidersToSchedulesForm({
	schedules,
	onCancel,
	onSubmit,
}: {
	schedules: Array<ICabSchedule>
	onSubmit: (
		values: TAssignServiceProvidersSchema & {
			remarks: Array<{ cab_schedule_id: number; remarks: string | undefined }>
		}
	) => Promise<void>
	onCancel: () => void
}) {
	const initialValues: TAssignServiceProvidersSchema = useMemo(() => {
		return {
			transport_service_provider: undefined,
			driver: undefined,
			cab: undefined,
			schedules: schedules.reduce<TAssignServiceProvidersSchema["schedules"]>(
				(schedules, s) =>
					schedules.concat(
						s.cabs.map((cab) => ({
							currency: cab.currency,
							schedule: s,
							cab: cab,
							cab_id: cab.id,
							booked_price: cab.booked_price,
							selected: false,
							given_price: cab.given_price,
							remarks: s.remarks,
						}))
					),
				[]
			),
			booked: false,
		}
	}, [schedules])
	const [step, changeStep] = useState(0)
	const [
		openedRemarsForSelectedCabByScheduleId,
		setOpenedRemarksForSelectedCabByScheduleId,
	] = useState<{ [id: string]: number }>({})
	const { hasPermission } = useCheckPermissions()
	return (
		<Form<TAssignServiceProvidersSchema>
			initialValues={initialValues}
			validate={validateFormValues(
				assignServiceProvidersToSchedulesValidationSchema
			)}
			onSubmit={withServerErrors(async (values) => {
				const payload = {
					...values,
					remarks: values.schedules
						.filter(
							(s) =>
								openedRemarsForSelectedCabByScheduleId[s.schedule.id] ===
								s.cab.id
						)
						.map((s) => ({
							cab_schedule_id: s.schedule.id,
							remarks: s.remarks,
						})),
				}
				return onSubmit(payload)
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, form, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Box>
						{step === 0 ? (
							<Stack gap="4">
								<Stack gap="2">
									<Heading as="h4">Select Cab Service</Heading>
									<Text color="muted">
										Please select applicable services which you want to assign a
										provider to.
									</Text>
								</Stack>
								<Box as="ol">
									<GetFieldValue<TAssignServiceProvidersSchema["schedules"]>
										name={`schedules`}
									>
										{({
											value: schedulesFieldValue,
											onChange: changeSchedulesFieldValue,
										}) => {
											// This is the schedules index in field array
											// we have pushed this state into the initial
											// values and now we are only updating the
											// selected key in the list
											// we start with -1 and increment it for every cab
											let index = -1
											const selectedSchedules = schedulesFieldValue.filter(
												(s) => s.selected
											)
											return (
												<Box>
													<Box
														as="label"
														padding="4"
														marginBottom="4"
														display="flex"
														alignItems="center"
														bgColor={{ default: "subtle", hover: "inset" }}
														rounded="lg"
														cursor="pointer"
													>
														<CheckboxInput
															checked={
																selectedSchedules.length ===
																schedulesFieldValue.length
															}
															onChange={(e) => {
																if (e.currentTarget.checked) {
																	// select all the cabs
																	changeSchedulesFieldValue(
																		schedulesFieldValue.map((s) => ({
																			...s,
																			selected: true,
																		}))
																	)
																} else {
																	// unselect all the cabs
																	changeSchedulesFieldValue(
																		schedulesFieldValue.map((s) => ({
																			...s,
																			selected: false,
																		}))
																	)
																}
															}}
														/>
														<Box marginLeft="2" fontWeight="semibold">
															Select All Cabs
														</Box>
													</Box>
													{schedules.map((schedule) => {
														return (
															<Box
																key={schedule.id}
																as="li"
																paddingX="4"
																paddingY="2"
																marginBottom="3"
																rounded="lg"
																borderWidth="1"
																backgroundColor={{ hover: "subtle" }}
																opacity={schedule.cabs_booked ? "70" : "100"}
															>
																<Box
																	marginBottom="2"
																	display={{ sm: "flex" }}
																	justifyContent="between"
																>
																	<Inline gap="2" as="label">
																		<Box>
																			<CheckboxInput
																				checked={schedule.cabs.every((cab) =>
																					selectedSchedules.find(
																						(s) => s.cab.id === cab.id
																					)
																				)}
																				onChange={(e) => {
																					if (e.currentTarget.checked) {
																						// select all the cabs
																						changeSchedulesFieldValue(
																							schedulesFieldValue.map((s) => ({
																								...s,
																								selected: schedule.cabs.find(
																									(cab) => s.cab.id === cab.id
																								)
																									? true
																									: s.selected,
																							}))
																						)
																					} else {
																						// unselect all the cabs
																						changeSchedulesFieldValue(
																							schedulesFieldValue.map((s) => ({
																								...s,
																								selected: schedule.cabs.find(
																									(cab) => s.cab.id === cab.id
																								)
																									? false
																									: s.selected,
																							}))
																						)
																					}
																				}}
																			/>
																		</Box>
																		<Box>
																			<Box
																				fontWeight="semibold"
																				display="inlineBlock"
																			>
																				{schedule.transport_service.from_to}
																			</Box>
																			<Box display="inline" paddingX="2">
																				-
																			</Box>
																			<Box display="inlineBlock">
																				{schedule.transport_service.service}
																			</Box>
																		</Box>
																	</Inline>
																	<Box>
																		{formatDate(
																			localOrUtcTimestampToLocalDate(
																				schedule.start_date_local,
																				schedule.start_date
																			),
																			"ddd, D MMM"
																		)}
																	</Box>
																</Box>
																<Box as="ul" paddingLeft="3">
																	{schedule.cabs.map((cab) => {
																		// here we increment the index in field array
																		index += 1
																		return (
																			<Box
																				key={cab.id}
																				as="li"
																				marginBottom="2"
																				display={{ sm: "flex" }}
																				alignItems="center"
																			>
																				<Box display="flex">
																					<CheckboxInputField
																						label={cab.cab_type.name}
																						name={`schedules[${index}].selected`}
																					/>
																					{cab.booked ? (
																						<Box marginLeft="2">
																							<Badge success>Booked</Badge>
																						</Box>
																					) : cab.transport_service_provider ? (
																						<Box marginLeft="2">
																							<Badge outlined warning>
																								Assigned
																							</Badge>
																						</Box>
																					) : null}
																				</Box>
																				{cab.transport_service_provider ? (
																					<Box
																						marginLeft="4"
																						display="flex"
																						flexWrap="wrap"
																						fontSize="sm"
																						marginTop={{ xs: "1", sm: "0" }}
																					>
																						{joinAttributes(
																							<Box>
																								{
																									cab.transport_service_provider
																										.name
																								}
																							</Box>,
																							cab.driver ? (
																								<Box>{cab.driver.name}</Box>
																							) : null,
																							cab.cab ? (
																								<Box>{cab.cab.name}</Box>
																							) : null,
																							cab.cab ? (
																								<Box>
																									{cab.cab.number_plate}
																								</Box>
																							) : null
																						)}
																					</Box>
																				) : null}
																			</Box>
																		)
																	})}
																</Box>
															</Box>
														)
													})}
												</Box>
											)
										}}
									</GetFieldValue>
									<Divider sm />
									<Inline gap="4" flexDirection="rowReverse">
										<GetFieldValue<
											TAssignServiceProvidersSchema["schedules"]
										> name="schedules">
											{({ value: schedules }) => (
												<GetFieldValue<
													TAssignServiceProvidersSchema["transport_service_provider"]
												> name="transport_service_provider">
													{({ value: transport_service_provider }) => (
														<Button
															level="primary"
															disabled={!schedules.some((s) => s.selected)}
															onClick={() => {
																if (!transport_service_provider) {
																	// get the first service provider from the list
																	const selected_cab_with_tsp = schedules.find(
																		(s) =>
																			s.selected &&
																			s.cab.transport_service_provider
																	)
																	if (selected_cab_with_tsp) {
																		form.change(
																			`transport_service_provider`,
																			selected_cab_with_tsp.cab
																				.transport_service_provider
																		)
																		form.change(
																			`driver`,
																			selected_cab_with_tsp.cab.driver
																		)
																		form.change(
																			`cab`,
																			selected_cab_with_tsp.cab.cab
																		)
																	}
																}
																// set the booked attribute if all are booked
																if (
																	schedules
																		.filter((s) => s.selected)
																		.every((s) => s.cab.booked)
																) {
																	form.change(`booked`, true)
																}
																setOpenedRemarksForSelectedCabByScheduleId(
																	schedules.reduce<{ [id: string]: number }>(
																		(openedRemarsForCabSchedules, s) => {
																			if (
																				s.selected &&
																				s.schedule.remarks &&
																				!openedRemarsForCabSchedules[
																					s.schedule.id
																				]
																			) {
																				// opend the remarks for this cab
																				openedRemarsForCabSchedules[
																					s.schedule.id
																				] = s.cab.id
																			}
																			return openedRemarsForCabSchedules
																		},
																		{}
																	)
																)
																changeStep(1)
															}}
														>
															Next: Assign Provider{" "}
															<Icons.ChevronDown rotate="270" />
														</Button>
													)}
												</GetFieldValue>
											)}
										</GetFieldValue>
										<Button onClick={onCancel} level="tertiary">
											Cancel
										</Button>
									</Inline>
								</Box>
							</Stack>
						) : null}
						{step === 1 ? (
							<Box>
								<Stack gap="4">
									<Stack gap="1">
										<Heading as="h4">Service Provider and Cab </Heading>
										<Box as="p" color="muted">
											Please provide the service provider with driver and cab
											details if available.
										</Box>
									</Stack>
									<Inline gap="4" flexWrap="wrap">
										<Box maxWidth="sm">
											<SelectField
												label="Service Provider"
												fullWidth
												select={SelectTransporServiceProviders}
												name={`transport_service_provider`}
												creatable
												multiple={false}
												placeholder="Select or Add new..."
												onChange={(
													value: ITransportServiceProvider | undefined
												) => {
													form.batch(() => {
														form.change("transport_service_provider", value)
														if (value?.solo_driver) {
															form.change(`driver`, value.drivers?.[0])
														} else {
															form.change(`driver`, undefined)
														}
														// deselect the cab
														form.change(`cab`, undefined)
													})
												}}
											/>
										</Box>
										<GetFieldValue<
											TAssignServiceProvidersSchema["transport_service_provider"]
										> name="transport_service_provider">
											{({ value: transport_service_provider }) =>
												transport_service_provider ? (
													<>
														{!transport_service_provider.solo_driver ? (
															<Box maxWidth="sm">
																<SelectField
																	label="Driver"
																	select={SelectDriver}
																	name={`driver`}
																	transportServiceProvider={
																		transport_service_provider
																	}
																	options={
																		transport_service_provider.drivers || []
																	}
																	creatable
																/>
															</Box>
														) : null}
														<Box maxWidth="sm">
															<SelectField
																select={SelectCabs}
																label="Cab Details"
																name={`cab`}
																placeholder="RJ18 CC 1212"
																options={transport_service_provider.cabs || []}
																creatable
															/>
														</Box>
													</>
												) : null
											}
										</GetFieldValue>
									</Inline>
								</Stack>
								<Divider />
								<Stack gap="2">
									<Stack gap="1">
										<Heading as="h4">Preview Services</Heading>
										<Box as="p" color="muted">
											Please preview the selected services that will be assigned
											to this provider and set the available booking price.
										</Box>
									</Stack>
									<GetFieldValue<
										TAssignServiceProvidersSchema["schedules"]
									> name="schedules">
										{({ value: schedules }) => (
											<Box as="ol">
												{schedules.map((scheduledCab, i) => {
													if (!scheduledCab.selected) return null
													return (
														<Stack
															gap="4"
															as="li"
															key={scheduledCab.cab_id}
															paddingY="4"
															borderWidth="1"
															rounded="lg"
															marginBottom="4"
														>
															<Grid>
																<Col xs={12} sm={6}>
																	<Stack gap="1">
																		<Box fontWeight="semibold">
																			{
																				scheduledCab.schedule.transport_service
																					.name
																			}
																		</Box>
																		<Box fontSize="sm">
																			{scheduledCab.cab.cab_type.name}
																		</Box>
																		<Component
																			initialState={
																				openedRemarsForSelectedCabByScheduleId[
																					scheduledCab.schedule.id
																				] === scheduledCab.cab.id
																					? true
																					: false
																			}
																		>
																			{({ state, setState }) => (
																				<>
																					<Box fontSize="sm" color="muted">
																						{joinAttributes(
																							`ID: ${scheduledCab.cab.id}`,
																							scheduledCab.cab.booked ? (
																								<Badge success>Booked</Badge>
																							) : null,
																							!state ? (
																								!openedRemarsForSelectedCabByScheduleId[
																									scheduledCab.schedule.id
																								] ? (
																									<Button
																										inline
																										onClick={() => {
																											setOpenedRemarksForSelectedCabByScheduleId(
																												{
																													...openedRemarsForSelectedCabByScheduleId,
																													[scheduledCab.schedule
																														.id]:
																														scheduledCab.cab.id,
																												}
																											)
																											setState(true)
																										}}
																									>
																										Add Remarks
																									</Button>
																								) : null
																							) : null
																						)}
																					</Box>
																					{state ? (
																						<Box maxWidth={"sm"}>
																							<TextAreaInputField
																								name={`schedules[${i}].remarks`}
																								label="Customer Remarks"
																								secondaryLabel="optional"
																								rows={1}
																								placeholder="Any service related remarks and notes"
																							/>
																						</Box>
																					) : null}
																				</>
																			)}
																		</Component>
																	</Stack>
																</Col>
																<Col xs={6} sm={3}>
																	<Box fontWeight="semibold">
																		{formatDate(
																			localOrUtcTimestampToLocalDate(
																				scheduledCab.schedule.start_date_local,
																				scheduledCab.schedule.start_date
																			),
																			"ddd, D MMM"
																		)}
																	</Box>
																	{hasPermission(
																		PERMISSIONS.MANAGE_TRIP_OWNERS
																	) &&
																	scheduledCab.given_price !== undefined &&
																	scheduledCab.given_price !== null ? (
																		<Box
																			color="muted"
																			fontSize="sm"
																			marginTop="1"
																		>
																			Selling Price:{" "}
																			<Money
																				amount={scheduledCab.given_price}
																				currency={scheduledCab.currency}
																			/>
																		</Box>
																	) : null}
																</Col>
																<Col xs={6} sm={3}>
																	<MoneyInputWithTenantCurrenciesField
																		label={`Price`}
																		style={{ maxWidth: "200px" }}
																		name={`schedules[${i}].booked_price`}
																		currencyFieldName={`schedules[${i}].currency`}
																	/>
																</Col>
															</Grid>
															<GetFieldValue<
																TAssignServiceProvidersSchema["driver"]
															> name="driver">
																{({ value: driver }) => (
																	<GetFieldValue<
																		TAssignServiceProvidersSchema["cab"]
																	> name="driver">
																		{({ value: cab }) =>
																			driver || cab ? (
																				schedules.some(
																					(s) =>
																						s.selected &&
																						s.cab_id !== scheduledCab.cab_id &&
																						s.schedule.id ===
																							scheduledCab.schedule.id
																				) ? (
																					<Alert
																						status="warning"
																						title="Single driver/cab assigned to multiple cabs for single service."
																						rounded="none"
																					>
																						<Box as="p">
																							You are assigning a single
																							driver/cab (
																							{[driver?.name, cab?.number_plate]
																								.filter(Boolean)
																								.join(", ")}
																							) to multiple cabs for a single
																							service (
																							{
																								scheduledCab.schedule
																									.transport_service.name
																							}
																							).
																						</Box>
																						<Box as="p">
																							A driver/cab should be assigned to
																							a single cab service for a given
																							transport service.
																						</Box>
																					</Alert>
																				) : null
																			) : null
																		}
																	</GetFieldValue>
																)}
															</GetFieldValue>
															<GetFieldValue<
																TAssignServiceProvidersSchema["transport_service_provider"]
															> name="transport_service_provider">
																{({ value: supplier }) => (
																	<GetFieldValue<
																		TAssignServiceProvidersSchema["driver"]
																	> name="driver">
																		{({ value: driver }) =>
																			!supplier ? null : (
																				<WarnForExistingScheduledCabsForSupplier
																					tripId={scheduledCab.schedule.trip_id}
																					scheduledCabId={scheduledCab.cab.id}
																					startDate={localOrUtcTimestampToLocalDate(
																						scheduledCab.schedule
																							.start_date_local,
																						scheduledCab.schedule.start_date
																					)}
																					startTime={
																						scheduledCab.schedule
																							.start_time_local
																							? parseDate(
																									scheduledCab.schedule
																										.start_time_local,
																									"HH:mm:ss"
																								)
																							: undefined
																					}
																					duration={createDuration(
																						scheduledCab.schedule.duration_iso
																					).asMinutes()}
																					supplier={supplier}
																					driver={driver}
																				/>
																			)
																		}
																	</GetFieldValue>
																)}
															</GetFieldValue>
														</Stack>
													)
												})}
											</Box>
										)}
									</GetFieldValue>
								</Stack>
								<Divider sm />
								<Box marginTop="4" padding="6" bgColor="subtle" rounded="lg">
									<CheckboxInputField
										name={`booked`}
										label="Confirmed by Supplier"
										help="Mark attached cabs as booked"
									/>
								</Box>
								<Divider sm />
								<Stack gap="4">
									<SubmissionError />
									<Box
										display={{ sm: "flex" }}
										flexDirection="rowReverse"
										justifyContent="between"
										gap="4"
									>
										<Inline flexDirection="rowReverse" gap="4">
											<Button type="submit" disabled={submitting}>
												{submitting ? "Assigning" : "Assign"}
											</Button>
											<Button
												onClick={() => onCancel()}
												level="tertiary"
												disabled={submitting}
											>
												Cancel
											</Button>
										</Inline>
										<Box>
											<Button onClick={() => changeStep(0)}>
												<Icons.ChevronDown rotate="90" /> Edit Services
											</Button>
										</Box>
									</Box>
								</Stack>
							</Box>
						) : null}
					</Box>
				</form>
			)}
		</Form>
	)
}

export function WarnForExistingScheduledCabsForSupplier({
	startDate,
	startTime,
	duration,
	tripId,
	scheduledCabId: existingId,
	supplier,
	driver,
}: {
	supplier: ITransportServiceProvider
	driver?: TDriver
	startDate: Date
	startTime?: Date | string
	duration?: number
	tripId?: number | string
	scheduledCabId?: number
}) {
	const xhr = useXHR()
	const pickupDateStartOfDay = useMemo(() => {
		return startOf(startDate, "day")
	}, [startDate])

	const startDateTimeParsed = useMemo(() => {
		if (startTime) {
			const time = parseDate(startTime, "HH:mm")
			return setTimeFrom(startDate, time)
		}
		if (formatDate(startDate, "HH:mm") !== "00:01") {
			return startDate
		}
		return undefined
	}, [startDate, startTime])

	const endDateTimeParsed = useMemo(() => {
		if (!duration || !startTime || !startDateTimeParsed)
			return startDateTimeParsed
		return addUnit(startDateTimeParsed, Number(duration), "minute")
	}, [startTime, duration, startDateTimeParsed])

	const dropDateEndOfDay = useMemo(() => {
		return endOf(endDateTimeParsed || pickupDateStartOfDay, "day")
	}, [pickupDateStartOfDay, endDateTimeParsed])

	const { data } = useSWR(
		supplier &&
			pickupDateStartOfDay &&
			dropDateEndOfDay &&
			(!supplier.solo_driver ? driver : true)
			? `/existing-scheduled-cabs-for-driver${queryToSearch({
					pickup_after: pickupDateStartOfDay.toISOString(),
					pickup_before: dropDateEndOfDay.toISOString(),
					supplier_id: supplier.id,
					existing_id: existingId,
					driver_id: driver?.id,
				})}`
			: null,
		function () {
			if (!supplier || !pickupDateStartOfDay || !dropDateEndOfDay) {
				return undefined
			}
			return xhr
				.get<IListResponse<IScheduledCab>>("/scheduled-cabs", {
					params: {
						pickup_after: dateToUTCString(pickupDateStartOfDay),
						pickup_before: dateToUTCString(dropDateEndOfDay),
						except_schedules: existingId ? [existingId] : undefined,
						drivers: driver ? [driver.id] : undefined,
						transportServiceProviders: [supplier.id],
						limit: 5,
					},
				})
				.then((resp) => resp.data)
		}
	)

	if (!data || !data.data.length) {
		return null
	}

	const { data: scheduledCabs, meta } = data
	const pickupAndDropSameDay = isSame(
		pickupDateStartOfDay,
		dropDateEndOfDay,
		"day"
	)
	return (
		<Alert
			status="warning"
			title={`Multiple bookings for "${supplier?.name}" ${driver ? ` with driver "${driver.name}"` : null} for this date`}
		>
			<Stack gap="4">
				<Text>
					Following schedules have already been assigned to this driver{" "}
					{pickupAndDropSameDay ? (
						<>
							on <Time value={pickupDateStartOfDay} />
						</>
					) : (
						<>
							during <Time value={pickupDateStartOfDay} /> and{" "}
							<Time value={dropDateEndOfDay} />
						</>
					)}
				</Text>
				<Stack
					as="ol"
					gap="1"
					bgColor="default"
					color="default"
					rounded="md"
					borderWidth="1"
				>
					{scheduledCabs.map((s) => {
						const { cab_schedule, cab_type, cab } = s
						const startDateAndTime = cab_schedule
							? getStartDateTimeOfCabSchedule(cab_schedule)
							: undefined
						const endDateAndTime = cab_schedule
							? getEndDateTimeOfCabSchedule(cab_schedule)
							: undefined
						return (
							<Box as="li" key={s.id} paddingX="4" paddingY="2">
								<Inline gap="4" justifyContent="between">
									<Stack gap="px">
										<Text fontWeight="semibold">
											{cab_schedule?.transport_service.name} - [
											{cab ? cab.full_name : cab_type.name}] - #{s.id}{" "}
										</Text>
										{cab_schedule ? (
											<Box fontSize={"sm"} color="muted">
												{joinAttributes(
													String(tripId) === String(cab_schedule.trip_id) ? (
														`This Trip# ${tripId}`
													) : (
														<Link
															to={generatePath(
																"/trips/:tripId/services-bookings/:serviceType",
																{
																	tripId: cab_schedule.trip_id.toString(),
																	serviceType: "operations",
																}
															)}
														>
															Trip# {cab_schedule.trip_id}
														</Link>
													),
													startDateAndTime ? (
														<>
															{formatDate(startDateAndTime, "DD MMM HH:mm")} hrs
															{endDateAndTime &&
															!isSame(startDateAndTime, endDateAndTime)
																? ` - ${formatDate(endDateAndTime, "DD MMM HH:mm")} hrs`
																: ``}
														</>
													) : null
												)}
											</Box>
										) : null}
									</Stack>
									{cab_schedule ? (
										<Box>
											<ScheduleDetailsInDialog
												schedule={cab_schedule}
												disableEdit
											>
												{({ onShow }) => (
													<Button
														size="sm"
														onClick={() => onShow()}
														status="primary"
													>
														<Icons.ArrowExpand />
													</Button>
												)}
											</ScheduleDetailsInDialog>
										</Box>
									) : null}
								</Inline>
							</Box>
						)
					})}
				</Stack>
				<Text fontWeight="semibold">
					{joinAttributes(
						<>Total: {meta.total}</>,
						supplier && pickupDateStartOfDay ? (
							<Link
								target="_blank"
								to={generatePath("/operational-bookings/date/:date", {
									date: dateToQuery(pickupDateStartOfDay),
								})}
								query={{
									tsps: [`${supplier.id}_${supplier.name}`],
									drivers: driver ? [`${driver.id}_${driver.name}`] : [],
								}}
							>
								View All <Icons.ArrowTopRightOnSquare />
							</Link>
						) : null
					)}
				</Text>
			</Stack>
		</Alert>
	)
}
