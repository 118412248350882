import { AsyncSelect, Divider } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { taxTypesXHR, TTaxType, useTaxTypes } from "./store"
import {
	InlineSelectInputField,
	useFieldValue,
	Form,
	SubmissionError,
	TextInputField,
	withServerErrors,
	PercentageInputField,
} from "@sembark-travel/ui/form"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
	ListView,
	Search,
	TSearchParams,
	useSearch,
} from "@sembark-travel/ui/list"
import {
	Button,
	Component,
	Inline,
	Table,
	Stack,
	RelativeTime,
	Box,
	Text,
	Icons,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

function useFreshId() {
	const [freshId, setKey] = useState(1)
	const refreshId = useCallback(() => {
		setKey((id) => id + 1)
	}, [])
	return [freshId, refreshId] as const
}

export function TaxTypesList() {
	const [params, setParams] = useSearch<TSearchParams>()
	const xhr = useXHR()
	const [freshKey, refreshKey] = useFreshId()
	return (
		<Search
			initialParams={params}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			title="Tax Types"
			actions={() => (
				<Inline>
					<Component initialState={false}>
						{({ state, setState }) => (
							<>
								<Button status="primary" onClick={() => setState(true)}>
									Add Tax Type
								</Button>
								<Dialog
									open={state}
									onClose={() => setState(false)}
									title="Set/Update Tax Types"
									sm
								>
									<Dialog.Body>
										<AddTaxTypeForm
											onCancel={() => setState(false)}
											onSubmit={async (values) => {
												await taxTypesXHR(xhr).store(values)
												showSnackbar("Tax type saved successfully")
												setState(false)
												refreshKey()
											}}
										/>
									</Dialog.Body>
								</Dialog>
							</>
						)}
					</Component>
				</Inline>
			)}
		>
			{() => (
				<ListView<TTaxType>
					params={params}
					onPageChange={(page) => setParams({ ...params, page })}
					pageKey={`tax-types-list-${freshKey}`}
					fetch={(xhr, params) =>
						taxTypesXHR(xhr).get({
							...params,
							include: "created_by",
						})
					}
				>
					{({ items, refresh }) => (
						<Table
							responsive
							bordered
							hover
							headers={[
								"Name",
								"Display Name",
								"Default Value",
								"Last Updated",
								"",
							]}
							alignCols={{ 2: "center", 5: "right" }}
							rows={items.map((pair) => [
								pair.name,
								pair.display_name,
								pair.default_percentage_value !== null ? (
									<Text as="span">
										{pair.default_percentage_value}
										<Box as="span" fontSize="xs" color="muted">
											%
										</Box>
									</Text>
								) : (
									<Text color="muted">N/A</Text>
								),
								<Stack>
									<Box>
										<RelativeTime
											timestamp={pair.updated_at || pair.created_at}
										/>
									</Box>
									<Text color="muted" fontSize="sm">
										by {(pair.updated_by || pair.created_by)?.name || "You"}
									</Text>
								</Stack>,
								<Inline gap="4" justifyContent="end">
									<Button
										level="tertiary"
										size="sm"
										status="warning"
										onClick={async () => {
											if (
												window.confirm(
													"Are you sure you want to delete this tax type ? Existing data will NOT be affected."
												)
											) {
												taxTypesXHR(xhr)
													.destroy(pair.id)
													.then(({ message }) => {
														refresh()
														showSnackbar(
															message || "Tax type deleted successfully."
														)
													})
													.catch((e) => {
														const error: Error = e
														window.alert(
															error.message ||
																"Something went wrong. Please try after sometime"
														)
													})
											}
										}}
									>
										<Icons.Trash />
									</Button>
								</Inline>,
							])}
						/>
					)}
				</ListView>
			)}
		</Search>
	)
}

type TTaxTypeFormData = {
	name: string
	display_name: string
	description?: string
	default_percentage_value?: number
}

function AddTaxTypeForm({
	initialValues: propInitialValues,
	onSubmit,
	onCancel,
}: {
	initialValues?: Partial<TTaxTypeFormData>
	onSubmit: (payload: TTaxTypeFormData) => Promise<void>
	onCancel: () => void
}) {
	const [initialValues] = useState<TTaxTypeFormData>(() => ({
		name: "",
		display_name: "",
		description: "",
		default_percentage_value: undefined,
		...propInitialValues,
	}))
	return (
		<Form
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				await onSubmit(values)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<Inline gap="4">
							<TextInputField
								name="name"
								label="Name"
								type="text"
								placeholder="e.g. VAT"
								secondaryLabel="Internal Label"
							/>
							<PercentageInputField
								label="Default Value"
								name="default_percentage_value"
							/>
						</Inline>
						<TextInputField
							name="display_name"
							label="Display Name"
							type="text"
							placeholder="e.g. Taxes"
							help="Used when sharing the taxation details with clients. Leave blank to hide tax details from clients"
							secondaryLabel="optional"
						/>
						<Divider marginY="0" />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Tax Type"}
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

export function SelectTaxType(
	props: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={async (q) => {
				return taxTypesXHR(xhr)
					.get({ q })
					.then((resp) => resp.data)
			}}
		/>
	)
}

export function InlineSelectTaxTypeInputField({
	clearable,
	onChangeDefaultValue,
	onDisabledTaxValueSelection,
	...props
}: Omit<React.ComponentProps<typeof InlineSelectInputField>, "children"> & {
	clearable?: boolean
	onChangeDefaultValue?: (value: number) => void
	onDisabledTaxValueSelection?: (disabled: boolean) => void
}) {
	const { data: taxTypes } = useTaxTypes()
	const { value, onChange } = useFieldValue<string | undefined | "">(props.name)
	const defaultValue = taxTypes?.length ? taxTypes[0].name : undefined
	const lastSelectedTaxType = useRef(value)
	useEffect(() => {
		if (!value && defaultValue && !clearable) {
			const h = setTimeout(() => {
				onChange(defaultValue)
			})
			return () => clearTimeout(h)
		}
	}, [value, defaultValue, clearable, onChange])

	const taxTypeValue = useMemo(() => {
		if (!value) return undefined
		const taxValue = taxTypes?.find((t) => t.name === value)
		if (taxValue) {
			return taxValue
		}
		return {
			name: value,
			display_name: value,
			default_percentage_value: null,
			deleted_at: new Date().toISOString(),
		} as TTaxType
	}, [value, taxTypes])

	const onDisabledTaxValueSelectionFnRef = useRef(onDisabledTaxValueSelection)
	onDisabledTaxValueSelectionFnRef.current = onDisabledTaxValueSelection

	useEffect(() => {
		const h = setTimeout(() => {
			if (taxTypeValue && onDisabledTaxValueSelectionFnRef.current) {
				onDisabledTaxValueSelectionFnRef.current?.(!taxTypeValue.display_name)
			}
		}, 300)
		return () => clearTimeout(h)
	}, [taxTypeValue])

	useEffect(() => {
		if (
			onChangeDefaultValue &&
			taxTypeValue &&
			lastSelectedTaxType.current !== taxTypeValue.name
		) {
			if (!taxTypeValue.display_name) {
				// tax is disabled
				onChangeDefaultValue(0)
			} else {
				if (taxTypeValue.default_percentage_value !== null) {
					onChangeDefaultValue(Number(taxTypeValue.default_percentage_value))
				}
			}
			lastSelectedTaxType.current = taxTypeValue.name
		}
	}, [taxTypeValue, onChangeDefaultValue])

	const isValueDisabled = Boolean(taxTypeValue?.deleted_at)

	const disabled = useMemo(
		() =>
			!clearable &&
			taxTypes &&
			taxTypes
				.map((t) => t.name)
				.filter((t) => t !== value)
				.concat(value ? [value] : []).length <= 1,
		[taxTypes, clearable, value]
	)
	return (
		<InlineSelectInputField
			disabled={disabled}
			{...props}
			noCaret={props.noCaret || disabled}
		>
			{clearable ? <option value="">-</option> : null}
			{isValueDisabled && value ? (
				<option value={value} title={value} disabled>
					{value} [DISABLED]
				</option>
			) : null}
			{taxTypes?.map((c) => (
				<option key={c.id} value={c.name} title={c.description}>
					{c.name} ({c.display_name || c.name})
				</option>
			))}
		</InlineSelectInputField>
	)
}
