import {
	Stack,
	Box,
	Button,
	Badge,
	Icons,
	Select,
	Table,
	Tooltip,
	Inline,
	Col,
	Component,
	Divider,
	Grid,
	joinAttributes,
	Stars,
	DateTimeInput,
	useTimeout,
	SROnly,
	Text,
	Heading,
	TableHeaderDataCell,
	TableDataCell,
	TableFooterDataCell,
	MoneySum,
} from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { isNumeric, numberToLocalString } from "@sembark-travel/number-utils"
import {
	addUnit,
	startOf,
	dateToUTCString,
	utcTimestampToLocalDate,
	isSame,
	parseDate,
	formatDate,
	isAfter,
	isBefore,
} from "@sembark-travel/datetime-utils"
import { isAbortError, useXHR } from "@sembark-travel/xhr"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { $PropertyType, Optional, Required } from "utility-types"
import * as Validator from "yup"
import { IDaywiseHotelPrice } from "../utils"
import {
	SelectHotels,
	SelectHotelStarCategory,
	type IHotel,
	type IHotelMealPlan,
	type IHotelRoomType,
	SelectHotelLocation,
	THotelLocation,
} from "./../Hotels"
import { SelectMealPlans } from "./../MealPlans"
import {
	Form,
	validateFormValues,
	FieldGroup,
	TextInputField,
	SwitchInputField,
	SelectField,
	GetFieldValue,
	FormSpy,
	useFieldValue,
	withServerErrors,
	useForm,
	useFormState,
	arrayMutators,
	FieldArray,
	EmptyNumberValidator,
	isTruthy,
} from "@sembark-travel/ui/form"
import { TTripDestination } from "../TripDestinations"
import { IHotelPrice } from "./store"
import { useHasFeatureFlag } from "../Auth"
import { Alert, Money } from "@sembark-travel/ui/base"
import {
	TMoney,
	addMoney,
	addMoneyFromDifferentCurrencies,
	formatMoneyByDecimal,
	moneyParseByDecimal,
} from "@sembark-travel/money"
import { useGetBookingDates } from "./utils"
import pluralize from "pluralize"
import { type TChildrenArray } from "../Tourists"
import {
	InlineSelectTenantCurrencyInputField,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"

export interface CalculatePriceParams {
	hotels?: {
		dates?: Array<Date>
		// this is only used when we don't have date range to select from
		no_of_nights?: number
		hotel?: IHotel
		meal_plan?: IHotelMealPlan
		room_type?: IHotelRoomType
		adults_with_extra_bed: number
		children_with_extra_bed: number
		children_without_extra_bed: number
		no_of_rooms: number
		persons_per_room: number
		comments?: string
		fetching_prices?: 0 | 1
		date_wise_prices?: Array<IDaywiseHotelPrice>
		similar_hotel_options?: Array<
			Omit<
				Required<CalculatePriceParams>["hotels"][number],
				"similar_hotel_options"
			>
		>
	}[]
}

const validationSchema = Validator.object().shape({
	hotels: Validator.array().of(
		Validator.object().shape({
			dates: Validator.array()
				.required("Nights field is required")
				.min(1, "Nights field is required"),
			no_of_nights: EmptyNumberValidator()
				.positive("Nights should be a positive integer")
				.typeError("Nights should be a number"),
			hotel: Validator.object().required("Hotel field is required"),
			meal_plan: Validator.object().required("Meal Plan field is required"),
			room_type: Validator.object().required("Room type field is required"),
			adults_with_extra_bed: EmptyNumberValidator()
				.integer("Adult with extra bed should be an interger")
				.required("Adult with extra bed is required"),
			children_with_extra_bed: EmptyNumberValidator()
				.integer("Child with extra bed should be an integer")
				.required("Child with extra bed is required"),
			children_without_extra_bed: EmptyNumberValidator()
				.integer("Child without extra bed should be an integer")
				.required("Child without extra bed is required"),
			no_of_rooms: EmptyNumberValidator()
				.positive("Number of rooms should be a positive number")
				.integer("Number of room should be an integer")
				.required("Number of rooms is required"),
			persons_per_room: EmptyNumberValidator()
				.positive("Field should be positive")
				.integer("Field should be positive number")
				.required("Persons / Room Required"),
		})
	),
})

const validate = validateFormValues(validationSchema)

const INITIAL_VALUES: Required<CalculatePriceParams, "hotels"> = {
	hotels: [
		{
			dates: [],
			no_of_nights: 1,
			hotel: undefined,
			meal_plan: undefined,
			room_type: undefined,
			adults_with_extra_bed: 0,
			children_with_extra_bed: 0,
			children_without_extra_bed: 0,
			no_of_rooms: 0,
			persons_per_room: 2,
			comments: "",
			date_wise_prices: [],
			similar_hotel_options: [],
		},
	],
}

interface CalculatePriceFormProps {
	initialValues?: CalculatePriceParams
	onChange?: (hotels: $PropertyType<CalculatePriceParams, "hotels">) => void
	bookingFrom?: string
	bookingTo?: string
	shouldEmptyInitialValues?: boolean
	disableHotels?: boolean
	showBookedPrices?: boolean
	disableNights?: boolean
	canAddSimilarOptions?: boolean
	disableComments?: boolean
	tripDestinations: Array<TTripDestination>
	noOfAdults: number | null
	children: TChildrenArray | null
}
export function CalculatePriceForm({
	initialValues: initialValuesProp,
	shouldEmptyInitialValues = false,
	onChange,
	bookingFrom,
	bookingTo,
	disableHotels,
	disableNights = false,
	showBookedPrices,
	canAddSimilarOptions = false,
	disableComments = false,
	tripDestinations,
	noOfAdults,
	children,
}: CalculatePriceFormProps) {
	const functional_currency = useFunctionalCurrencyOfTenant()
	const currency = useMemo(
		() => tripDestinations.map((d) => d.currency).at(0) || functional_currency,
		[tripDestinations, functional_currency]
	)
	const [initialValues] = useState<Required<CalculatePriceParams, "hotels">>(
		() => ({
			hotels: [],
			...(initialValuesProp ||
				(shouldEmptyInitialValues ? { hotels: [] } : INITIAL_VALUES)),
		})
	)
	const notifyOnChange = useCallback(
		(flattenValues: CalculatePriceParams) => {
			onChange &&
				onChange(
					flattenValues.hotels?.filter(
						(h) =>
							h.dates?.length &&
							h.hotel &&
							h.meal_plan &&
							h.room_type &&
							h.no_of_rooms &&
							h.persons_per_room &&
							h.date_wise_prices &&
							h.date_wise_prices.length
					) || []
				)
		},
		[onChange]
	)
	const bookingDates = useGetBookingDates(bookingFrom, bookingTo)
	return (
		<Form<CalculatePriceParams>
			initialValues={initialValues}
			validate={validate}
			onSubmit={withServerErrors(console.log)}
			mutators={{
				...arrayMutators,
			}}
			subscription={{}}
		>
			{({ handleSubmit, form }) => {
				return (
					<form noValidate onSubmit={handleSubmit}>
						<FieldArray<
							Required<CalculatePriceParams>["hotels"][number]
						> name="hotels">
							{({ fields }) => (
								<Box>
									{fields.map((name, index) => {
										return (
											<Box
												key={name}
												borderBottomWidth="4"
												borderColor="default"
											>
												<Grid>
													<Col
														sm={12}
														md={showBookedPrices ? 7 : 8}
														paddingY="3"
														paddingLeft="0"
													>
														<Grid gap="4">
															<Col md={12}>
																<Grid gap="4">
																	{!disableNights ? (
																		<DateNightsField
																			name={name}
																			bookingDates={
																				bookingFrom && bookingTo
																					? bookingDates
																					: undefined
																			}
																		/>
																	) : null}
																	{!disableHotels ? (
																		<Col xs={12} sm>
																			<SelectHotelField
																				tripDestinations={tripDestinations}
																				name={name}
																			/>
																		</Col>
																	) : null}
																	<Col style={{ maxWidth: "150px" }}>
																		<GetFieldValue<
																			Required<
																				CalculatePriceParams,
																				"hotels"
																			>["hotels"][number]["hotel"]
																		>
																			name={`${name}.hotel`}
																		>
																			{({ value: hotel }) => (
																				<SelectField
																					select={Select}
																					name={`${name}.meal_plan`}
																					label="Meal Plan"
																					multiple={false}
																					options={
																						hotel ? hotel.meal_plans : []
																					}
																				/>
																			)}
																		</GetFieldValue>
																	</Col>
																	<Col style={{ maxWidth: "200px" }}>
																		<GetFieldValue<
																			Required<
																				CalculatePriceParams,
																				"hotels"
																			>["hotels"][number]["hotel"]
																		>
																			name={`${name}.hotel`}
																		>
																			{({ value: hotel }) => (
																				<SelectField
																					select={Select}
																					name={`${name}.room_type`}
																					label="Room Type"
																					options={
																						hotel ? hotel.room_types : []
																					}
																					multiple={false}
																					onChange={(value: IHotelRoomType) => {
																						form.change(
																							`${name}.room_type` as never,
																							value as never
																						)
																					}}
																				/>
																			)}
																		</GetFieldValue>
																	</Col>
																</Grid>
															</Col>
															<Col md={12}>
																<Inline gap="4" flexWrap="wrap">
																	<TextInputField
																		label="Pax/room"
																		secondaryLabel="WoEB"
																		name={`${name}.persons_per_room`}
																		type="number"
																		min={1}
																		max={10}
																		placeholder="2"
																		style={{ width: "120px" }}
																	/>
																	<NoOfRoomsInputField name={name} />
																	<AdultsWithExtraBedInputField name={name} />
																	<ChildrenWithExtraBedInputField name={name} />
																	<ChildrenWithoutExtraBedInputField
																		name={name}
																	/>
																	<GetFieldValue<
																		Required<
																			CalculatePriceParams,
																			"hotels"
																		>["hotels"][number]["hotel"]
																	>
																		name={`${name}.hotel`}
																	>
																		{({ value: hotel }) => {
																			const complimentary_children =
																				hotel && children?.length
																					? children?.filter(
																							(c) =>
																								c.age <
																								hotel.extra_bed_child_age_start
																						)
																					: []
																			return !hotel ? null : (
																				<Stack
																					gap="1"
																					title="Complimentary Child Age"
																				>
																					<Text fontWeight="semibold">
																						Comp Child
																					</Text>
																					<Box paddingY="1">
																						<b>
																							Upto{" "}
																							{hotel.extra_bed_child_age_start -
																								1}
																							y
																						</b>{" "}
																						({complimentary_children.length}C
																						{complimentary_children.length
																							? `: ${complimentary_children
																									.map((c) => c.age)
																									.join(",")}`
																							: null}
																						)
																					</Box>
																				</Stack>
																			)
																		}}
																	</GetFieldValue>
																</Inline>
															</Col>
														</Grid>
														<SelectedSimilarHotelOptions name={name} />
														{canAddSimilarOptions ? (
															<AddSimilarHotelOptionsField
																tripDestinations={tripDestinations}
																name={name}
																bookingDates={bookingDates}
																bookingFrom={bookingFrom}
																bookingTo={bookingTo}
																noOfAdults={noOfAdults}
																children={children}
															/>
														) : null}
													</Col>
													<Col
														sm={12}
														md={showBookedPrices ? 5 : 4}
														paddingY="3"
														paddingRight="0"
														borderLeftWidth={{ xs: "0", md: "1" }}
														paddingLeft={{ xs: "0", md: "4" }}
													>
														<PricesInput
															name={name}
															defaultCurrency={currency}
															showBookedPrices={showBookedPrices}
															disableComments={disableComments}
														/>
														<Divider sm />
														<Inline gap="2">
															<FormSpy<CalculatePriceParams>
																subscription={{ values: true }}
															>
																{({ values }) => {
																	if (!values.hotels) return null
																	let remainingDates: Array<Date> = []
																	const hotel = values.hotels[index]
																	if (
																		bookingTo &&
																		values.hotels[index].dates?.length
																	) {
																		remainingDates = getRemainingDates(
																			bookingDates.map((d) => d.value),
																			values.hotels.reduce<Array<Date>>(
																				(dates, hotel) =>
																					dates.concat(hotel.dates || []),
																				[]
																			)
																		)
																	}
																	return hotel.dates?.length &&
																		(!bookingTo || remainingDates.length) &&
																		!disableNights ? (
																		<Button
																			status="primary"
																			size="sm"
																			onClick={() => {
																				fields.push({
																					...hotel,
																					comments: "",
																					similar_hotel_options: [],
																					date_wise_prices: [],
																					hotel: !disableHotels
																						? undefined
																						: hotel.hotel,
																					dates: [
																						!bookingTo && hotel.dates
																							? startOf(
																									addUnit(
																										hotel.dates[
																											hotel.dates.length - 1
																										],
																										1,
																										"day"
																									),
																									"day"
																								)
																							: remainingDates[0],
																					],
																				})
																			}}
																		>
																			<Icons.Plus /> Next Night
																		</Button>
																	) : null
																}}
															</FormSpy>
															<GetFieldValue<CalculatePriceParams["hotels"]>
																name={"hotels"}
															>
																{({ value: hotels }) => (
																	<GetFieldValue<
																		Required<
																			CalculatePriceParams,
																			"hotels"
																		>["hotels"][number]
																	>
																		name={name}
																	>
																		{({ value: hotel }) => (
																			<Button
																				size="sm"
																				onClick={() => {
																					const addAt = index + 1
																					const newHotels = [...(hotels || [])]
																					// can not use insert
																					// https://github.com/final-form/final-form-arrays/issues/44
																					newHotels.splice(addAt, 0, {
																						...hotel,
																					})
																					form.change("hotels", newHotels)
																				}}
																			>
																				<Icons.Duplicate /> Duplicate
																			</Button>
																		)}
																	</GetFieldValue>
																)}
															</GetFieldValue>
															<Button
																onClick={() => fields.remove(index)}
																level="tertiary"
																size="sm"
															>
																<Icons.Cancel /> Remove
															</Button>
														</Inline>
													</Col>
												</Grid>
											</Box>
										)
									})}
									{!disableHotels && !fields.length ? (
										<Box paddingTop="4">
											<Button
												level="primary"
												status="primary"
												data-testid="add_hotels"
												onClick={() => {
													if (initialValues?.hotels.length && disableNights) {
														fields.push({
															...initialValues.hotels[0],
															hotel: undefined,
															date_wise_prices: [],
															similar_hotel_options: [],
														})
													} else {
														fields.push(INITIAL_VALUES.hotels[0])
													}
												}}
											>
												<Icons.Plus /> Add Hotel
											</Button>
										</Box>
									) : null}
								</Box>
							)}
						</FieldArray>
						<SubmitOnChange onChange={notifyOnChange} />
						<SROnly>
							<Button type="submit">Get Prices</Button>
						</SROnly>
					</form>
				)
			}}
		</Form>
	)
}

function DateNightsField({
	name,
	bookingDates,
}: {
	name: string
	bookingDates?: Array<{ id: string; name: string; value: Date }>
}) {
	const { value: selectedDates, onChange: setSelectedDates } = useFieldValue<
		Required<CalculatePriceParams>["hotels"][number]["dates"]
	>(`${name}.dates`)
	const { value: no_of_nights, onChange: changeNoOfNights } =
		useFieldValue<number>(`${name}.no_of_nights`)
	return bookingDates?.length ? (
		<Col>
			<FieldGroup<typeof selectedDates>
				name={`${name}.dates`}
				label="Stay Nights"
			>
				{({ input }) => (
					<Select
						{...input}
						options={bookingDates}
						searchable={false}
						placeholder="Select night(s)..."
						required
						multiple
						value={bookingDates.filter((d) =>
							input.value
								? input.value.findIndex((dd) => isSame(dd, d.value, "day")) !==
									-1
								: false
						)}
						onChange={(stayNights: typeof bookingDates | undefined) => {
							const nights =
								stayNights && stayNights.length
									? stayNights.map((s) => s.value)
									: []
							nights.sort((a, b) =>
								isAfter(a, b) ? 1 : isBefore(a, b) ? -1 : 0
							)
							input.onChange(nights)
						}}
					/>
				)}
			</FieldGroup>
		</Col>
	) : (
		<>
			<Col>
				<FieldGroup<typeof selectedDates>
					label="Checkin Date"
					name={`${name}.dates`}
				>
					{({ input }) => (
						<DateTimeInput
							{...input}
							value={
								input.value?.length ? parseDate(input.value[0]) : undefined
							}
							onChange={(value) => {
								setSelectedDates(
									value
										? createDatesFromStartDateAndNights(
												value,
												no_of_nights || 1
											)
										: []
								)
							}}
						/>
					)}
				</FieldGroup>
			</Col>
			<Col>
				<TextInputField
					label="No of Nights"
					type="number"
					min={1}
					max={1000}
					name={`${name}.no_of_nights`}
					onChange={(e) => {
						const value = e.currentTarget.value
						changeNoOfNights(value as unknown as number)
						if (selectedDates?.length && value) {
							setSelectedDates(
								createDatesFromStartDateAndNights(
									selectedDates[0],
									parseInt(value)
								)
							)
						}
					}}
				/>
			</Col>
		</>
	)
}

function SelectHotelField({
	name,
	tripDestinations,
}: {
	name: string
	tripDestinations?: Array<TTripDestination>
}) {
	type QuoteHotel = Required<CalculatePriceParams>["hotels"][number]
	const { value: selectedHotel, onChange: changeHotel } = useFieldValue<
		QuoteHotel["hotel"]
	>(`${name}.hotel`)
	const { value: dates } = useFieldValue<QuoteHotel["dates"]>(`${name}.dates`)
	const { value: oldMealPlanValue, onChange: changeMealPlan } = useFieldValue<
		QuoteHotel["meal_plan"]
	>(`${name}.meal_plan`)
	const { onChange: changeRoomType } = useFieldValue<QuoteHotel["room_type"]>(
		`${name}.room_type`
	)
	const canUseAdvancedSelection = useHasFeatureFlag(
		"advanced_hotel_selection_during_quote"
	)
	return (
		<Inline gap="1">
			<Box flex="1">
				<SelectField
					select={SelectHotels}
					name={`${name}.hotel`}
					tripDestinations={tripDestinations}
					creatable
					label="Hotel"
					multiple={false}
					onChange={(value: IHotel | undefined) => {
						changeHotel(value)
						if (!value) {
							changeMealPlan(undefined)
							changeRoomType(undefined)
							return
						}
						// compare against the existing value
						// change the meal plan if it's not from the list of available meal plans
						let mealPlanIndex = value.meal_plans.findIndex(
							(mp) => oldMealPlanValue && mp.id === oldMealPlanValue.id
						)
						if (mealPlanIndex === -1) {
							mealPlanIndex = 0
						}
						changeMealPlan(value.meal_plans[mealPlanIndex])
						changeRoomType(value.room_types[0]) // always the first one
					}}
					help={
						selectedHotel?.deleted_at ? (
							<Alert status="warning" inline>
								Hotel disabled. Please update.
							</Alert>
						) : null
					}
				/>
			</Box>
			{canUseAdvancedSelection && dates?.length && dates.length < 10 ? (
				<Component initialState={false}>
					{({ state, setState }) => (
						<GetFieldValue<QuoteHotel> name={name}>
							{({ value, onChange }) => (
								<>
									<Stack gap="1">
										<Text>&nbsp;</Text>
										<Button onClick={() => setState(true)}>
											<Icons.Sliders />
										</Button>
									</Stack>
									<Dialog
										open={state}
										onClose={() => setState(false)}
										title="Advanced Search"
										lg
									>
										<Dialog.Body>
											<HotelAdvancedSearch
												dates={dates}
												tripDestinations={tripDestinations}
												onCancel={() => setState(false)}
												value={value}
												onChange={(value) => {
													onChange(value)
													setState(false)
												}}
											/>
										</Dialog.Body>
									</Dialog>
								</>
							)}
						</GetFieldValue>
					)}
				</Component>
			) : null}
		</Inline>
	)
}

type THotelWithPrices = Array<
	Omit<IHotel, "prices"> & {
		prices: [
			{
				meal_plan: IHotelMealPlan
				room_type: IHotelRoomType
				persons: number
				total: number
				date_wise_prices: Array<
					Omit<
						Optional<IHotelPrice>,
						"hotel" | "meal_plan" | "room_type" | "persons"
					> & { date: string }
				>
			},
		]
	}
>

function sortHotelsWithPrices(
	hotelWithPrices: THotelWithPrices,
	sortBy: "price" | "-price"
) {
	return hotelWithPrices.concat([]).sort((a, b) => {
		if (!a.prices.length && !b.prices.length) {
			return a.name > b.name ? 1 : -1
		}
		if (a.prices.length && b.prices.length) {
			const aTotal = a.prices.reduce<number>((total, p) => total + p.total, 0)
			const bTotal = b.prices.reduce<number>((total, p) => total + p.total, 0)
			if (aTotal === bTotal) {
				return a.name > b.name ? 1 : -1
			}
			if (sortBy === "price") {
				// ascending order of price
				return aTotal > bTotal ? 1 : -1
			}
			// descending order of price
			return aTotal > bTotal ? -1 : 1
		}
		if (!a.prices.length) return 1
		if (!b.prices.length) return -1
		return 0
	})
}

function HotelAdvancedSearch({
	dates,
	onCancel,
	tripDestinations,
	value,
	onChange,
}: {
	dates: Array<Date>
	onCancel: () => void
	tripDestinations?: Array<TTripDestination>
	value: Required<CalculatePriceParams>["hotels"][number]
	onChange: (value: Required<CalculatePriceParams>["hotels"][number]) => void
}) {
	const [hotelWithPrices, setHotelWithPrices] = useState<
		THotelWithPrices | undefined
	>(undefined)
	const xhr = useXHR()
	const [sortBy] = useState<"price" | "-price">("price")
	const [isFetching, setIsFetching] = useState(false)
	const initialValues = useRef<TParams>({
		meal_plan: value.meal_plan ? value.meal_plan : undefined,
		locations: value.hotel ? [value.hotel.location] : [],
		// room_types: value.room_type ? [value.room_type] : [],
		room_types: [],
		star_categories: value.hotel?.stars
			? [{ id: value.hotel.stars, name: value.hotel.stars }]
			: [],
		uncategorized: (value.hotel && !value.hotel.stars ? 1 : undefined) as
			| 1
			| undefined,
		persons_per_room: value.persons_per_room || 2,
		no_of_rooms: value.no_of_rooms || 1,
		adults_with_extra_bed: value.adults_with_extra_bed || 0,
		children_with_extra_bed: value.children_with_extra_bed || 0,
		children_without_extra_bed: value.children_without_extra_bed || 0,
	})
	type TParams = {
		meal_plan?: IHotelMealPlan
		locations?: Array<THotelLocation>
		room_types?: Array<IHotelRoomType>
		star_categories?: Array<{ id: string; name: string }>
		uncategorized?: 1
		persons_per_room: number
		no_of_rooms: number
		adults_with_extra_bed: number
		children_with_extra_bed: number
		children_without_extra_bed: number
	}
	const fetchHotels = useCallback(
		async (params: TParams, abortController?: AbortController) => {
			setIsFetching(true)
			const {
				meal_plan,
				locations,
				room_types,
				star_categories,
				uncategorized,
				persons_per_room,
				no_of_rooms,
				adults_with_extra_bed,
				children_with_extra_bed,
				children_without_extra_bed,
			} = params
			try {
				const resp = await xhr
					.get<{ data: THotelWithPrices }>("/search-hotels-with-prices", {
						params: {
							trip_destinations: tripDestinations?.length
								? tripDestinations.map((m) => m.id)
								: [],
							meal_plans: meal_plan ? [meal_plan.id] : [],
							room_types: room_types?.length ? room_types.map((m) => m.id) : [],
							locations: locations?.length ? locations.map((m) => m.id) : [],
							star_categories: star_categories?.length
								? star_categories.map((m) => m.name)
								: [],
							uncategorized: isTruthy(uncategorized) ? 1 : undefined,
							dates: dates.map((d) => dateToUTCString(startOf(d, "day"))),
							persons_per_room,
							no_of_rooms,
							adults_with_extra_bed,
							children_with_extra_bed,
							children_without_extra_bed,
						},
						signal: abortController?.signal,
					})
					.then((resp) => resp.data)
				setHotelWithPrices(sortHotelsWithPrices(resp.data, sortBy))
				return resp
			} finally {
				setIsFetching(false)
			}
		},
		[dates, sortBy, tripDestinations, xhr]
	)
	return (
		<Stack>
			<Inline gap="6" flexWrap="wrap">
				{tripDestinations?.length ? (
					<Inline gap="1">
						<Text fontWeight="semibold">Destinations :</Text>
						<Text>{tripDestinations.map((t) => t.name).join(", ")}</Text>
					</Inline>
				) : null}
				<Inline gap="1">
					<Text fontWeight="semibold">Dates :</Text>
					<Text>
						{dates
							.map((d) => formatDate(startOf(d, "day"), "D MMM"))
							.join(", ")}
					</Text>
				</Inline>
			</Inline>
			<Divider sm />
			<Box>
				<Form<TParams>
					initialValues={initialValues.current}
					validate={validateFormValues(
						Validator.object().shape({
							meal_plan: Validator.mixed().required(
								"Please select a meal plan"
							),
							locations: Validator.array().min(
								1,
								"Please select atleast one location"
							),
							star_categories: Validator.array().nullable(),
							persons_per_room: EmptyNumberValidator()
								.required("Please provide person per room")
								.integer("Please provide an integer value")
								.positive("Please provide a positive integer"),
							no_of_rooms: EmptyNumberValidator()
								.required("Please provide number of rooms")
								.integer("Please provide an integer value")
								.positive("Please provide a positive integer"),
							adults_with_extra_bed: EmptyNumberValidator()
								.required("Please provide number of adults with extra beds")
								.integer("Please provide an integer value")
								.min(0, "The value can not be negative."),
							children_with_extra_bed: EmptyNumberValidator()
								.required("Please provide number of children with extra beds")
								.integer("Please provide an integer value")
								.min(0, "The value can not be negative."),
							children_without_extra_bed: EmptyNumberValidator()
								.required(
									"Please provide number of children without extra beds"
								)
								.integer("Please provide an integer value")
								.min(0, "The value can not be negative."),
						})
					)}
					onSubmit={async (params) => {
						return await fetchHotels(params)
					}}
					subscription={{ submitting: true }}
				>
					{({ handleSubmit, submitting }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Inline gap="4" flexWrap="wrap">
								<TextInputField
									name="persons_per_room"
									label="Pax/Room"
									type="number"
									placeholder="2"
									style={{ width: "100px" }}
									min={0}
								/>
								<TextInputField
									name="no_of_rooms"
									label="No. of Rooms"
									type="number"
									placeholder="1"
									style={{ width: "100px" }}
									min={1}
								/>
								<TextInputField
									name="adults_with_extra_bed"
									label="AwEB"
									type="number"
									placeholder="0"
									style={{ width: "70px" }}
									min={0}
								/>
								<TextInputField
									name="children_with_extra_bed"
									label="CwEB"
									type="number"
									placeholder="0"
									style={{ width: "70px" }}
									min={0}
								/>
								<TextInputField
									name="children_without_extra_bed"
									label="CNB"
									type="number"
									placeholder="0"
									style={{ width: "70px" }}
									min={0}
								/>
							</Inline>
							<Divider sm />
							<Grid gap="8">
								<Col xs={12} sm={4} md={3}>
									<Stack gap="4">
										<SelectField
											select={SelectHotelLocation}
											name="locations"
											label="City Locations"
											tripDestinations={tripDestinations}
											multiple
										/>
										<SelectField
											select={SelectMealPlans}
											name="meal_plan"
											label="Meal Plan"
											tripDestinations={tripDestinations}
											fetchOnMount
										/>
										<SelectField
											select={SelectHotelStarCategory}
											name="star_categories"
											label="Star Categories"
											tripDestinations={tripDestinations}
											multiple
											fetchOnMount
											secondaryLabel="optional"
										/>
										<SwitchInputField
											name="uncategorized"
											label="Include Uncategoried"
										/>
										<Box>
											<SearchForHotelsOnParamsChange onChange={fetchHotels} />
											<Button type="submit" disabled={submitting || isFetching}>
												{submitting || isFetching ? "Searching..." : "Search"}
											</Button>
										</Box>
									</Stack>
								</Col>
								<Col>
									{hotelWithPrices && hotelWithPrices.length ? (
										<Table
											responsive
											bordered
											headers={["Hotel", "Rates"]}
											rows={hotelWithPrices.map((hotel) => [
												<Stack>
													<Text fontWeight="semibold">{hotel.name}</Text>
													<Text fontSize="sm" color="muted">
														{hotel.stars_string}
													</Text>
												</Stack>,
												<Stack>
													{!hotel.prices.length ? (
														<Inline gap="4">
															<Box flex="1">
																<Text color="muted">Rates not available</Text>
															</Box>
															<FormSpy<typeof value>
																subscription={{ values: true }}
															>
																{({ values }) => (
																	<Button
																		size="sm"
																		onClick={() => {
																			onChange({
																				...value,
																				hotel: hotel as never,
																				meal_plan: hotel.meal_plans[0],
																				room_type: hotel.room_types[0],
																				persons_per_room:
																					values.persons_per_room ||
																					value.persons_per_room,
																				no_of_rooms: values.no_of_rooms || 1,
																				adults_with_extra_bed:
																					values.adults_with_extra_bed || 0,
																				children_with_extra_bed:
																					values.children_with_extra_bed || 0,
																				children_without_extra_bed:
																					values.children_without_extra_bed ||
																					0,
																			})
																		}}
																	>
																		<Icons.Ok />
																	</Button>
																)}
															</FormSpy>
														</Inline>
													) : (
														<Stack gap="1">
															{hotel.prices.map(
																(room_meal_wise_price, key, arr) => (
																	<Inline gap="4" key={key} flexWrap="wrap">
																		<Stack flex="1">
																			<Box>
																				{arr.length > 1
																					? `${room_meal_wise_price.meal_plan.name} - `
																					: null}
																				{room_meal_wise_price.room_type.name} (
																				{room_meal_wise_price.persons}P)
																			</Box>
																			<Stack gap="px" fontSize="sm">
																				{room_meal_wise_price.date_wise_prices.map(
																					(price, index, arr) => (
																						<Inline
																							key={index}
																							gap="1"
																							flexWrap="wrap"
																						>
																							{arr.length > 1 ? (
																								<Text>
																									{formatDate(
																										utcTimestampToLocalDate(
																											price.date
																										),
																										"D MMM"
																									)}
																									:{" "}
																								</Text>
																							) : null}
																							{price.base_price ? (
																								<FormSpy<typeof value>
																									subscription={{
																										values: true,
																									}}
																								>
																									{({ values }) => (
																										<Text>
																											{[
																												`${
																													values.no_of_rooms ||
																													1
																												}x${price.base_price}`,
																												values.adults_with_extra_bed
																													? `${values.adults_with_extra_bed}x${price.adult_with_extra_bed_price}`
																													: ``,
																												values.children_with_extra_bed
																													? `${values.children_with_extra_bed}x${price.child_with_extra_bed_price}`
																													: ``,
																												values.children_without_extra_bed
																													? `${values.children_without_extra_bed}x${price.child_without_extra_bed_price}`
																													: ``,
																											]
																												.filter(Boolean)
																												.join(" + ")}
																										</Text>
																									)}
																								</FormSpy>
																							) : (
																								<Text color="muted">
																									Not available
																								</Text>
																							)}
																						</Inline>
																					)
																				)}
																			</Stack>
																		</Stack>
																		<Box>
																			<Text fontWeight="semibold">
																				{numberToLocalString(
																					room_meal_wise_price.total
																				)}
																			</Text>
																		</Box>
																		<FormSpy<typeof value>
																			subscription={{ values: true }}
																		>
																			{({ values }) => (
																				<Button
																					size="sm"
																					onClick={() => {
																						onChange({
																							...value,
																							hotel: hotel as never,
																							meal_plan:
																								room_meal_wise_price.meal_plan,
																							room_type:
																								room_meal_wise_price.room_type,
																							persons_per_room:
																								values.persons_per_room ||
																								value.persons_per_room,
																							no_of_rooms:
																								values.no_of_rooms || 1,
																							adults_with_extra_bed:
																								values.adults_with_extra_bed ||
																								0,
																							children_with_extra_bed:
																								values.children_with_extra_bed ||
																								0,
																							children_without_extra_bed:
																								values.children_without_extra_bed ||
																								0,
																						})
																					}}
																				>
																					<Icons.Ok />
																				</Button>
																			)}
																		</FormSpy>
																	</Inline>
																)
															)}
														</Stack>
													)}
												</Stack>,
											])}
										/>
									) : !hotelWithPrices ? (
										<Stack
											borderWidth="1"
											rounded="md"
											padding="6"
											textAlign="center"
											bgColor="primary"
										>
											<Heading as="h4" fontSize="md">
												Search and Select Hotels with Price Comparision
											</Heading>
											<Text>
												Please select your desired filters and click on{" "}
												<b>Search</b> to fetch the prices.
											</Text>
										</Stack>
									) : (
										<Box
											borderWidth="1"
											rounded="md"
											textAlign="center"
											padding="6"
										>
											No results founds.
										</Box>
									)}
								</Col>
							</Grid>
							<Divider sm />
							<Button onClick={onCancel}>Cancel</Button>
						</form>
					)}
				</Form>
			</Box>
		</Stack>
	)
}

function SearchForHotelsOnParamsChange<T extends object>({
	onChange,
}: {
	onChange: (params: T, abortController?: AbortController) => void
}) {
	const { values, hasValidationErrors, dirty } = useFormState<T>({
		subscription: {
			values: true,
			hasValidationErrors: true,
			dirty: true,
		},
	})
	const previousValuesRef = useRef<T | null>(values)
	const { set: setSubmitterTimeout, clear: clearSubmitterTimeout } =
		useTimeout()

	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange

	// fetch prices
	useEffect(() => {
		const previousValues = { ...previousValuesRef.current }
		const abortController = new AbortController()
		if (!hasValidationErrors && dirty) {
			setSubmitterTimeout(() => {
				previousValuesRef.current = values
				const previousKeys = Object.keys(previousValues || ({} as T))
				const newKeys = Object.keys(values || ({} as T))
				if (
					previousKeys.length !== newKeys.length ||
					previousKeys.some(
						(k) => !Object.is(previousValues[k as never], values[k as never])
					)
				) {
					onChangeRef.current(values, abortController)
				}
			}, 1000)
		}
		return () => {
			clearSubmitterTimeout()
			abortController.abort()
		}
	}, [
		values,
		setSubmitterTimeout,
		clearSubmitterTimeout,
		hasValidationErrors,
		dirty,
	])
	return null
}

function NoOfRoomsInputField({ name }: { name: string }) {
	const { value: room_type } = useFieldValue<
		Required<CalculatePriceParams>["hotels"][number]["room_type"]
	>(`${name}.room_type`)
	const {
		value: children_with_extra_bed,
		onChange: changeChildrenWithExtraBed,
	} = useFieldValue<number>(`${name}.children_with_extra_bed`)
	const { value: adults_with_extra_bed, onChange: changeAdultsWithExtraBed } =
		useFieldValue<number>(`${name}.adults_with_extra_bed`)
	const {
		value: children_without_extra_bed,
		onChange: changeChildrenWithoutExtraBed,
	} = useFieldValue<number>(`${name}.children_without_extra_bed`)
	const form = useForm<CalculatePriceParams>()
	const allowed_extra_beds = Number(room_type?.allowed_extra_beds || 0)
	const allowed_adults_with_extra_bed = Number(
		room_type?.allowed_adults_with_extra_bed || 0
	)
	const allowed_children_with_extra_bed = Number(
		room_type?.allowed_children_with_extra_bed || 0
	)
	const allowed_children_without_extra_bed =
		room_type?.allowed_children_without_extra_bed
	const { value: no_of_rooms } = useFieldValue<number | "">(
		`${name}.no_of_rooms`
	)
	useEffect(() => {
		// make sure that only valid extra beds are present for adults
		const validAdultsWithExtraBed =
			Number(allowed_adults_with_extra_bed) * Number(no_of_rooms || 0)
		if (adults_with_extra_bed > validAdultsWithExtraBed) {
			changeAdultsWithExtraBed(
				Math.min(validAdultsWithExtraBed, adults_with_extra_bed)
			)
		}
	}, [
		no_of_rooms,
		allowed_extra_beds,
		allowed_adults_with_extra_bed,
		adults_with_extra_bed,
		changeAdultsWithExtraBed,
	])
	useEffect(() => {
		// make sure that only valid extra beds are present for children
		const validChildrenWithExtraBeds =
			Number(allowed_children_with_extra_bed) * Number(no_of_rooms || 0)
		if (Number(children_with_extra_bed) > validChildrenWithExtraBeds) {
			changeChildrenWithExtraBed(
				Math.min(validChildrenWithExtraBeds, children_with_extra_bed)
			)
		}
	}, [
		no_of_rooms,
		allowed_children_with_extra_bed,
		children_with_extra_bed,
		changeChildrenWithExtraBed,
	])

	useEffect(() => {
		if (!isNumeric(allowed_children_without_extra_bed)) {
			return
		}
		// make sure that only valid extra beds are present for CNB
		const validChildrenWithoutExtraBeds =
			Number(allowed_children_without_extra_bed) * Number(no_of_rooms || 0)
		if (Number(children_without_extra_bed) > validChildrenWithoutExtraBeds) {
			changeChildrenWithoutExtraBed(
				Math.min(validChildrenWithoutExtraBeds, children_without_extra_bed)
			)
		}
	}, [
		no_of_rooms,
		allowed_children_without_extra_bed,
		children_without_extra_bed,
		changeChildrenWithoutExtraBed,
	])
	return (
		<TextInputField
			name={`${name}.no_of_rooms`}
			label="No. of rooms"
			type="number"
			min={1}
			max={1000}
			style={{ width: "100px" }}
			help={
				<GetFieldValue<number | undefined> name={`${name}.no_of_rooms`}>
					{({ value }) =>
						value && room_type?.no_of_rooms && value > room_type.no_of_rooms ? (
							<Alert inline status="error">
								Total {pluralize("Room", room_type.no_of_rooms, true)} Only.
							</Alert>
						) : null
					}
				</GetFieldValue>
			}
			onChange={(e) => {
				const value = parseInt(e.currentTarget.value, 10)
				if (isNaN(value) || !room_type) {
					form.change(e.currentTarget.name as never, undefined as never)
					return
				}
				form.change(e.currentTarget.name as never, value as never)
			}}
		/>
	)
}

function AdultsWithExtraBedInputField({ name }: { name: string }) {
	const { value: room_type } = useFieldValue<
		Required<CalculatePriceParams>["hotels"][number]["room_type"]
	>(`${name}.room_type`)
	const { value: no_of_rooms } = useFieldValue<number>(`${name}.no_of_rooms`)
	const { value: children_with_extra_bed } = useFieldValue<number>(
		`${name}.children_with_extra_bed`
	)
	const form = useForm<CalculatePriceParams>()
	return (
		<TextInputField
			name={`${name}.adults_with_extra_bed`}
			label="AWEB"
			type="number"
			min={0}
			style={{ width: "70px" }}
			max={100}
			disabled={!room_type || !room_type?.allowed_adults_with_extra_bed}
			onChange={(e) => {
				const { name } = e.currentTarget
				const value = parseInt(e.currentTarget.value, 10)
				if (isNaN(value) || !room_type) {
					form.change(name as never, undefined as never)
					return
				}
				const { allowed_extra_beds, allowed_adults_with_extra_bed } = room_type
				form.change(
					name as never,
					Math.min(
						value,
						allowed_adults_with_extra_bed * no_of_rooms,
						allowed_extra_beds * no_of_rooms - children_with_extra_bed
					) as never
				)
			}}
		/>
	)
}

function ChildrenWithExtraBedInputField({ name }: { name: string }) {
	const form = useForm<CalculatePriceParams>()
	const { value: room_type } = useFieldValue<
		Required<CalculatePriceParams>["hotels"][number]["room_type"]
	>(`${name}.room_type`)
	const { value: no_of_rooms } = useFieldValue<number>(`${name}.no_of_rooms`)
	const { value: adults_with_extra_bed } = useFieldValue<number>(
		`${name}.adults_with_extra_bed`
	)
	return (
		<TextInputField
			name={`${name}.children_with_extra_bed`}
			label="CWEB"
			type="number"
			min={0}
			style={{ width: "70px" }}
			max={100}
			disabled={!room_type || !room_type?.allowed_children_with_extra_bed}
			onChange={(e) => {
				const { name, value: rawValue } = e.currentTarget
				const value = parseInt(rawValue, 10)
				if (isNaN(value) || !room_type) {
					form.change(name as never, undefined as never)
					return
				}
				const { allowed_extra_beds, allowed_children_with_extra_bed } =
					room_type
				form.change(
					name as never,
					Math.min(
						allowed_extra_beds * no_of_rooms - adults_with_extra_bed,
						allowed_children_with_extra_bed * no_of_rooms,
						value
					) as never
				)
			}}
		/>
	)
}

function ChildrenWithoutExtraBedInputField({ name }: { name: string }) {
	const form = useForm<CalculatePriceParams>()
	const { value: room_type } = useFieldValue<
		Required<CalculatePriceParams>["hotels"][number]["room_type"]
	>(`${name}.room_type`)
	const { value: no_of_rooms } = useFieldValue<number>(`${name}.no_of_rooms`)
	return (
		<TextInputField
			name={`${name}.children_without_extra_bed`}
			label="CNB"
			min={0}
			max={100}
			type="number"
			style={{ width: "70px" }}
			disabled={
				!room_type ||
				(isNumeric(room_type.allowed_children_without_extra_bed) &&
					!room_type.allowed_children_without_extra_bed)
			}
			onChange={(e) => {
				const { name, value: rawValue } = e.currentTarget
				const value = parseInt(rawValue, 10)
				if (isNaN(value) || !room_type) {
					form.change(name as never, undefined as never)
					return
				}
				const { allowed_children_without_extra_bed } = room_type
				form.change(
					name as never,
					Math.min(
						isNumeric(allowed_children_without_extra_bed)
							? allowed_children_without_extra_bed * no_of_rooms
							: value,
						value
					) as never
				)
			}}
		/>
	)
}

function SelectedSimilarHotelOptions({ name }: { name: string }) {
	const { value: hotel } = useFieldValue<
		Required<CalculatePriceParams>["hotels"][number]
	>(`${name}`)
	return hotel.similar_hotel_options?.length ? (
		<Box marginTop="4">
			<Box rounded="lg">
				<Box fontWeight="semibold" marginBottom="1">
					Similar Hotels
				</Box>
				<FieldArray<(typeof hotel.similar_hotel_options)[number]>
					name={`${name}.similar_hotel_options`}
					subscription={{ value: true }}
				>
					{({ fields }) => (
						<Table
							hover
							bordered
							responsive
							alignCols={{
								2: "right",
								3: "right",
							}}
							rows={fields.map((_, index) => {
								const option = fields.value[index]
								return [
									<Box>
										<Box fontWeight="semibold">{option.hotel?.name}</Box>
										<Inline fontSize="sm" flexWrap="wrap">
											{joinAttributes(
												option.hotel?.location?.name,
												option.hotel?.stars ? (
													<Stars stars={option.hotel?.stars} />
												) : null
											)}
										</Inline>
										{option.hotel?.location?.name !==
										hotel?.hotel?.location?.name ? (
											<Box>
												<Badge danger>
													<Icons.AttentionSolid /> City Mismatch
												</Badge>
											</Box>
										) : null}
									</Box>,
									<Box>
										<Box fontWeight="semibold">
											{option.no_of_rooms} {option.room_type?.name}{" "}
											<span title="Number of Adutls">
												(
												{(option.persons_per_room || 0) *
													(option.no_of_rooms || 0)}
												A)
											</span>
										</Box>
										<Box fontSize="sm" whiteSpace="preserve">
											{joinAttributes(
												option.meal_plan?.name,
												option.adults_with_extra_bed
													? `${option.adults_with_extra_bed} AWEB`
													: null,
												option.children_with_extra_bed
													? `${option.children_with_extra_bed} CWEB`
													: null,
												option.children_without_extra_bed
													? `${option.children_without_extra_bed} CNB`
													: null
											)}
											{option.meal_plan?.id !== hotel?.meal_plan?.id ? (
												<Box>
													<Badge danger>
														<Icons.AttentionSolid /> Different Meal
													</Badge>
												</Box>
											) : null}
										</Box>
									</Box>,
									<Box>
										{option.date_wise_prices?.map(
											({ date, currency, given_price }, indx) => (
												<Box key={indx} whiteSpace="preserve">
													{given_price ? (
														<Money
															showCurrency
															amount={given_price || 0}
															currency={currency}
														/>
													) : (
														<Badge warning>
															<Icons.AttentionSolid /> 0
														</Badge>
													)}{" "}
													({formatDate(date, "D MMM")})
												</Box>
											)
										)}
									</Box>,
									<Box>
										<Button size="sm" onClick={() => fields.remove(index)}>
											<Icons.Cancel />
										</Button>
									</Box>,
								]
							})}
						/>
					)}
				</FieldArray>
			</Box>
		</Box>
	) : null
}

function SubmitOnChange({
	onChange,
}: {
	onChange: (values: CalculatePriceParams) => void
}) {
	const { values } = useFormState<CalculatePriceParams>({
		subscription: { values: true, valid: true },
	})
	const { set: setNotifyParentTimeout, clear: clearNotifyParentTimeout } =
		useTimeout()

	const onChangeRef = useRef(onChange)
	onChangeRef.current = onChange

	// notify parent
	useEffect(() => {
		setNotifyParentTimeout(() => {
			onChangeRef.current(values)
		}, 500)
		return () => clearNotifyParentTimeout()
	}, [values, setNotifyParentTimeout, clearNotifyParentTimeout])

	// notify parent
	useEffect(() => {
		setNotifyParentTimeout(() => {
			onChangeRef.current(values)
		}, 500)
		return () => clearNotifyParentTimeout()
	}, [values, setNotifyParentTimeout, clearNotifyParentTimeout])

	return null
}

function PricesInput({
	name: hotelFieldName,
	defaultCurrency,
	showBookedPrices,
	disableComments,
}: {
	name: string
	defaultCurrency: string
	showBookedPrices?: boolean
	disableComments?: boolean
}) {
	const { value: quoteHotel } =
		useFieldValue<Required<CalculatePriceParams>["hotels"][number]>(
			hotelFieldName
		)
	const {
		dates,
		date_wise_prices,
		no_of_rooms,
		persons_per_room,
		adults_with_extra_bed,
		children_with_extra_bed,
		children_without_extra_bed,
		hotel,
		meal_plan,
		room_type,
	} = quoteHotel
	const previousHotelRef = useRef({
		hotel,
		meal_plan,
		room_type,
		date_wise_prices,
		persons_per_room,
		// on-mount, if we have dates but not date-wise-prices,
		// we set it to empty to ensure that we generate the date_wise_prices
		dates: dates?.length && !quoteHotel.date_wise_prices?.length ? [] : dates,
	})
	const [showComments, setShowComments] = useState(
		quoteHotel.comments?.length ? true : false
	)
	const form = useForm()
	useEffect(() => {
		const previousHotel = { ...previousHotelRef.current }
		previousHotelRef.current = {
			hotel,
			meal_plan,
			room_type,
			date_wise_prices,
			persons_per_room,
			dates,
		}
		if (
			previousHotel?.hotel?.id !== hotel?.id ||
			previousHotel?.meal_plan?.id !== meal_plan?.id ||
			previousHotel?.room_type?.id !== room_type?.id ||
			previousHotel?.persons_per_room !== persons_per_room ||
			previousHotel?.dates?.length !== dates?.length
		) {
			// reset the given prices
			const handler = setTimeout(() => {
				form.change(
					`${hotelFieldName}.date_wise_prices`,
					(dates || []).map((date) => ({
						...(date_wise_prices?.find((data) =>
							isSame(data.date, date, "day")
						) || {
							currency: defaultCurrency,
							given_price: 0,
							price: 0,
						}),
						date,
						given_price: showBookedPrices ? undefined : 0,
						per_room_given_price: showBookedPrices ? undefined : 0,
						per_adult_given_price: showBookedPrices ? undefined : 0,
						per_adult_with_extra_bed_given_price: showBookedPrices
							? undefined
							: 0,
						per_child_with_extra_bed_given_price: showBookedPrices
							? undefined
							: 0,
						per_child_without_extra_bed_given_price: showBookedPrices
							? undefined
							: 0,
						edited_given_price: false,
						booked_price: 0,
						per_room_booked_price: 0,
						per_adult_booked_price: 0,
						per_adult_with_extra_bed_booked_price: 0,
						per_child_with_extra_bed_booked_price: 0,
						per_child_without_extra_bed_booked_price: 0,
						edited_booked_price: false,
					}))
				)
			})
			return () => clearTimeout(handler)
		}
	}, [
		defaultCurrency,
		hotel,
		meal_plan,
		room_type,
		form,
		hotelFieldName,
		date_wise_prices,
		persons_per_room,
		dates,
		showBookedPrices,
	])

	const [refreshCounter, setRefreshCounter] = useState<number>(0)

	return (
		<Stack gap="2">
			<Stack>
				<Box
					display="flex"
					justifyContent="between"
					alignItems="center"
					height="12"
				>
					<Text fontSize="md" fontWeight="semibold">
						Prices
					</Text>
					<Inline alignItems="center" gap="2">
						{!showComments ? (
							<Button
								level="tertiary"
								size="sm"
								onClick={() => setShowComments(true)}
								title="Add Comments"
							>
								<Icons.Annotation />
							</Button>
						) : null}
						<GetFieldValue<
							Required<
								Required<CalculatePriceParams>["hotels"][number]
							>["fetching_prices"]
						>
							name={`${hotelFieldName}.fetching_prices`}
						>
							{({ value }) => {
								const fetchingPrices = isTruthy(value)
								return (
									<Button
										inline
										size="sm"
										title="Refresh Prices"
										onClick={() => setRefreshCounter((counter) => counter + 1)}
									>
										<Icons.Refresh
											spin={fetchingPrices}
											opacity={fetchingPrices ? "50" : undefined}
										/>
									</Button>
								)
							}}
						</GetFieldValue>
					</Inline>
				</Box>
				{date_wise_prices && date_wise_prices.length ? (
					<Table
						bordered
						responsive
						alignCols={{
							1: "center",
						}}
						headers={[
							"Date",
							<Inline flexWrap="wrap" gap="1" justifyContent="center">
								<Text>Rate</Text>
								<Text fontSize="sm">
									<MoneySum
										money={(date_wise_prices || []).reduce<Array<TMoney>>(
											(total, { currency, price }) =>
												addMoneyFromDifferentCurrencies(
													total.concat([
														moneyParseByDecimal(price || 0, currency),
													])
												),
											[]
										)}
									/>
								</Text>
							</Inline>,
							"", // for sync button
							<Inline flexWrap="wrap" gap="1">
								<Text>Given</Text>
								<Text fontSize="sm">
									<MoneySum
										money={(date_wise_prices || []).reduce<Array<TMoney>>(
											(total, { currency, given_price }) =>
												addMoneyFromDifferentCurrencies(
													total.concat([
														moneyParseByDecimal(given_price || 0, currency),
													])
												),
											[]
										)}
									/>
								</Text>
							</Inline>,
						].concat(
							showBookedPrices
								? [
										<Inline flexWrap="wrap" gap="1" justifyContent="center">
											<Text>Booked</Text>
											<Text fontSize="sm">
												<MoneySum
													money={(date_wise_prices || []).reduce<Array<TMoney>>(
														(total, { currency, booked_price }) =>
															addMoneyFromDifferentCurrencies(
																total.concat([
																	moneyParseByDecimal(
																		booked_price || 0,
																		currency
																	),
																])
															),
														[]
													)}
												/>
											</Text>
										</Inline>,
									]
								: []
						)}
						rows={(date_wise_prices || []).map(
							(
								{
									date,
									price,
									how,
									currency,
									booked_price,
									edited_given_price,
								},
								j
							) =>
								[
									<Stack>
										<Box whiteSpace="preserve">{formatDate(date, "D MMM")}</Box>
										<Box whiteSpace="preserve" fontSize="xs" color="muted">
											{formatDate(date, "dddd")}
										</Box>
									</Stack>,
									!price ? (
										<Box>
											<Box
												as="span"
												color="muted"
												fontSize="xs"
												verticalAlign="super"
											>
												<InlineSelectTenantCurrencyInputField
													name={`${hotelFieldName}.date_wise_prices.${j}.currency`}
													noCaret
													defaultValue={defaultCurrency}
												/>
											</Box>{" "}
											<Text color="warning" title="Not Available" as="span">
												N/A
											</Text>
										</Box>
									) : (
										<Box key={j} title={how} as="span">
											<Money amount={price} currency={currency} showCurrency />
										</Box>
									),
									price &&
									((!showBookedPrices && edited_given_price) ||
										(showBookedPrices &&
											Number(price) !== Number(booked_price))) ? (
										<Button
											size="sm"
											title="Match with Rate"
											onClick={() => {
												form.batch(() => {
													if (!showBookedPrices) {
														form.change(
															`${hotelFieldName}.date_wise_prices.${j}.edited_given_price`,
															false
														)
														form.change(
															`${hotelFieldName}.date_wise_prices.${j}.given_price`,
															price
														)
														form.change(
															`${hotelFieldName}.date_wise_prices.${j}.currency`,
															currency
														)
													} else {
														form.change(
															`${hotelFieldName}.date_wise_prices.${j}.edited_booked_price`,
															false
														)
														form.change(
															`${hotelFieldName}.date_wise_prices.${j}.booked_price`,
															price
														)
													}
												})
											}}
											level="tertiary"
										>
											<Icons.ArrowLeft rotate="180" />
										</Button>
									) : null,
									showBookedPrices ? (
										<Box textAlign={"center"}>
											<ReadOnlyGivenPriceDuringBooking
												noOfRooms={no_of_rooms}
												personsPerRoom={persons_per_room}
												adultsWithExtraBed={adults_with_extra_bed}
												childrenWithExtraBed={children_with_extra_bed}
												childrenWithoutExtraBed={children_without_extra_bed}
												name={`${hotelFieldName}.date_wise_prices[${j}]`}
											/>
										</Box>
									) : (
										<GivenPriceInputField
											dates={date_wise_prices.map((p) => p.date)}
											name={`${hotelFieldName}.date_wise_prices[${j}]`}
											noOfRooms={no_of_rooms}
											personsPerRoom={persons_per_room}
											adultsWithExtraBed={adults_with_extra_bed}
											childrenWithExtraBed={children_with_extra_bed}
											childrenWithoutExtraBed={children_without_extra_bed}
											defaultCurrency={currency}
											onChange={(values, keepSameForOthers) => {
												let indexes = [j]
												if (keepSameForOthers) {
													indexes = Array.from(
														Array(date_wise_prices.length).keys()
													)
												}
												form.batch(() => {
													indexes.forEach((index) => {
														const fieldNamePrefix = `${hotelFieldName}.date_wise_prices.${index}`
														form.change(
															`${fieldNamePrefix}.currency`,
															values.currency
														)
														form.change(
															`${fieldNamePrefix}.per_room_given_price`,
															values.per_room_given_price
														)
														form.change(
															`${fieldNamePrefix}.per_room_given_price`,
															values.per_room_given_price
														)
														form.change(
															`${fieldNamePrefix}.per_adult_given_price`,
															values.per_adult_given_price
														)
														form.change(
															`${fieldNamePrefix}.per_adult_with_extra_bed_given_price`,
															values.per_adult_with_extra_bed_given_price
														)
														form.change(
															`${fieldNamePrefix}.per_child_with_extra_bed_given_price`,
															values.per_child_with_extra_bed_given_price
														)
														form.change(
															`${fieldNamePrefix}.per_child_without_extra_bed_given_price`,
															values.per_child_without_extra_bed_given_price
														)
														form.change(
															`${fieldNamePrefix}.given_price`,
															values.total
														)
														form.change(
															`${fieldNamePrefix}.edited_given_price`,
															values.edited_given_price
														)
													})
												})
											}}
										/>
									),
								].concat(
									showBookedPrices
										? [
												<BookedPriceInputField
													dates={date_wise_prices.map((p) => p.date)}
													name={`${hotelFieldName}.date_wise_prices[${j}]`}
													noOfRooms={no_of_rooms}
													personsPerRoom={persons_per_room}
													adultsWithExtraBed={adults_with_extra_bed}
													childrenWithExtraBed={children_with_extra_bed}
													childrenWithoutExtraBed={children_without_extra_bed}
													defaultCurrency={currency}
													onChange={(values, keepSameForOthers) => {
														let indexes = [j]
														if (keepSameForOthers) {
															indexes = Array.from(
																Array(date_wise_prices.length).keys()
															)
														}
														form.batch(() => {
															indexes.forEach((index) => {
																const fieldNamePrefix = `${hotelFieldName}.date_wise_prices.${index}`
																form.change(
																	`${fieldNamePrefix}.per_room_booked_price`,
																	values.per_room_booked_price
																)
																form.change(
																	`${fieldNamePrefix}.per_adult_booked_price`,
																	values.per_adult_booked_price
																)
																form.change(
																	`${fieldNamePrefix}.per_adult_with_extra_bed_booked_price`,
																	values.per_adult_with_extra_bed_booked_price
																)
																form.change(
																	`${fieldNamePrefix}.per_child_with_extra_bed_booked_price`,
																	values.per_child_with_extra_bed_booked_price
																)
																form.change(
																	`${fieldNamePrefix}.per_child_without_extra_bed_booked_price`,
																	values.per_child_without_extra_bed_booked_price
																)
																form.change(
																	`${fieldNamePrefix}.booked_price`,
																	values.total
																)
																form.change(
																	`${fieldNamePrefix}.edited_booked_price`,
																	values.edited_booked_price
																)
															})
														})
													}}
												/>,
											]
										: []
								)
						)}
					/>
				) : (
					<Text color="muted" fontSize="sm">
						Please fill all required details
					</Text>
				)}
			</Stack>
			<TotalGivenPriceForHotel name={hotelFieldName} />
			{!disableComments && showComments ? (
				<TextInputField
					type="text"
					label="Comments"
					name={`${hotelFieldName}.comments`}
					maxLength={191}
					placeholder="Regarding pricing difference or any other"
				/>
			) : null}
			<RatesForHotel
				hotelFieldName={hotelFieldName}
				key={refreshCounter}
				isBooking={Boolean(showBookedPrices)}
			/>
		</Stack>
	)
}

function RatesForHotel({
	hotelFieldName,
	isBooking,
}: {
	hotelFieldName: string
	isBooking: boolean
}) {
	const { value: quoteHotel } =
		useFieldValue<Required<CalculatePriceParams>["hotels"][number]>(
			hotelFieldName
		)
	const {
		dates,
		no_of_rooms,
		persons_per_room,
		adults_with_extra_bed,
		children_with_extra_bed,
		children_without_extra_bed,
		hotel,
		meal_plan,
		room_type,
	} = quoteHotel

	const form = useForm()

	const xhr = useXHR()
	const abortController = useRef<AbortController>()
	const { set: setTimer, clear: clearTimer } = useTimeout()
	useEffect(() => {
		// abort any pending requests
		abortController.current = new AbortController()
		if (
			!hotel ||
			!meal_plan ||
			!room_type ||
			!persons_per_room ||
			!no_of_rooms ||
			!dates?.length
		) {
			abortController.current?.abort()
			clearTimer()
			return () => undefined
		}
		setTimer(() => {
			// fetch the prices and update
			Promise.resolve()
				.then(async () => {
					form.change(`${hotelFieldName}.fetching_prices`, 1)
					const prices = await Promise.all(
						dates.map((date) =>
							xhr
								.get<{
									data: {
										price: number | null
										currency: string | null
										per_person_price?: null | {
											room: number
											adult: number
											adult_with_extra_bed: number
											child_with_extra_bed: number
											child_without_extra_bed: number
										}
									}
								}>(`/hotel-prices/calculate`, {
									params: {
										date: dateToUTCString(date),
										date_local: formatDate(date, "YYYY-MM-DD"),
										hotel_id: hotel.id,
										meal_plan_id: meal_plan.id,
										room_type_id: room_type.id,
										persons_per_room,
										no_of_rooms,
										adults_with_extra_bed,
										children_with_extra_bed,
										children_without_extra_bed,
										is_booking: isBooking ? 1 : 0,
									},
									signal: abortController.current?.signal,
								})
								.then((resp) => resp.data.data)
						)
					)
					abortController.current = undefined
					form.batch(() => {
						dates.forEach((date, index) => {
							const total_price = prices[index].price
							const per_person_price = prices[index].per_person_price
							const currency = prices[index].currency

							form.change(
								`${hotelFieldName}.date_wise_prices[${index}].date`,
								date
							)

							// if we have currency/rates, then only we update the currency
							if (currency) {
								form.change(
									`${hotelFieldName}.date_wise_prices[${index}].per_person_price`,
									per_person_price || undefined
								)
								form.change(
									`${hotelFieldName}.date_wise_prices[${index}].currency`,
									currency
								)
								form.change(
									`${hotelFieldName}.date_wise_prices[${index}].price`,
									total_price !== undefined && total_price !== null
										? formatMoneyByDecimal(
												moneyParseByDecimal(total_price, currency)
											)
										: undefined
								)
							} else {
								// otherwise, we reset the prices and currency remains the same
								form.change(
									`${hotelFieldName}.date_wise_prices[${index}].price`,
									undefined
								)
								form.change(
									`${hotelFieldName}.date_wise_prices[${index}].per_person_price`,
									per_person_price || undefined
								)
							}
						})
					})
				})
				.then(() => {
					form.change(`${hotelFieldName}.fetching_prices`, 0)
				})
				.catch((e) => {
					if (!isAbortError(e)) {
						form.change(`${hotelFieldName}.fetching_prices`, 0)
						throw e
					}
				})
		}, 300)
		return () => {
			// Cancel any pending fetch
			abortController.current?.abort()
			clearTimer()
		}
	}, [
		hotel,
		meal_plan,
		room_type,
		no_of_rooms,
		persons_per_room,
		adults_with_extra_bed,
		children_with_extra_bed,
		children_without_extra_bed,
		form,
		dates,
		hotelFieldName,
		xhr,
		setTimer,
		clearTimer,
		isBooking,
	])

	return null
}

function GivenPriceInputField({
	name,
	noOfRooms,
	personsPerRoom,
	adultsWithExtraBed,
	childrenWithExtraBed,
	childrenWithoutExtraBed,
	dates,
	onChange,
	defaultCurrency,
}: {
	name: string
	noOfRooms?: number
	personsPerRoom?: number
	adultsWithExtraBed?: number
	childrenWithExtraBed?: number
	childrenWithoutExtraBed?: number
	dates: Array<Date>
	defaultCurrency: string
	onChange: (
		values: {
			currency: string
			per_room_given_price: number
			per_adult_given_price: number
			per_adult_with_extra_bed_given_price: number
			per_child_with_extra_bed_given_price: number
			per_child_without_extra_bed_given_price: number
			total: number
			edited_given_price: boolean
		},
		keepSameForOthers: boolean
	) => void
}) {
	const { value: date_wise_price } = useFieldValue<IDaywiseHotelPrice>(name)

	const form = useForm()
	const [isOpen, open, close] = useDialog()

	const {
		date,
		edited_given_price = false,
		currency,
		per_person_price,
		given_price,
		per_room_given_price,
		per_adult_with_extra_bed_given_price,
		per_child_with_extra_bed_given_price,
		per_child_without_extra_bed_given_price,
	} = date_wise_price

	// synchronize the given price if using rates and prices haven't been modified manually
	useEffect(() => {
		if (!edited_given_price) {
			// given prices prices should be synced with per_person_price
			const perRoomGivenPrice: number = per_person_price?.room || 0
			const perAdultGivenPrice: number = per_person_price?.adult || 0
			const perAdultWithExtraBedGivenPrice: number = adultsWithExtraBed
				? per_person_price?.adult_with_extra_bed || 0
				: 0
			const perChildWithExtraBedGivenPrice = childrenWithExtraBed
				? per_person_price?.child_with_extra_bed || 0
				: 0
			const perChildWithoutExtraBedGivenPrice = childrenWithoutExtraBed
				? per_person_price?.child_without_extra_bed || 0
				: 0

			form.batch(() => {
				form.change(`${name}.per_room_given_price`, perRoomGivenPrice)
				form.change(`${name}.per_adult_given_price`, perAdultGivenPrice)
				form.change(
					`${name}.per_adult_with_extra_bed_given_price`,
					perAdultWithExtraBedGivenPrice
				)
				form.change(
					`${name}.per_child_with_extra_bed_given_price`,
					perChildWithExtraBedGivenPrice
				)
				form.change(
					`${name}.per_child_without_extra_bed_given_price`,
					perChildWithoutExtraBedGivenPrice
				)
				form.change(
					`${name}.given_price`,
					Number(noOfRooms) * Number(perRoomGivenPrice) +
						Number(adultsWithExtraBed) *
							Number(perAdultWithExtraBedGivenPrice) +
						Number(childrenWithExtraBed) *
							Number(perChildWithExtraBedGivenPrice) +
						Number(childrenWithoutExtraBed) *
							Number(perChildWithoutExtraBedGivenPrice)
				)
			})
		}
	}, [
		edited_given_price,
		per_person_price,
		noOfRooms,
		adultsWithExtraBed,
		personsPerRoom,
		childrenWithExtraBed,
		childrenWithoutExtraBed,
		name,
		form,
	])

	// auto-calculate the per-person prices if pax changes and we have per-person
	useEffect(() => {
		// if not edited, given prices will be synced auto-synced with per_person_price(s)
		if (!edited_given_price) return
		const existingPrices = {
			per_room_given_price,
			per_adult_with_extra_bed_given_price,
			per_child_with_extra_bed_given_price,
			per_child_without_extra_bed_given_price,
			given_price,
		}
		if (existingPrices.per_room_given_price) {
			form.batch(() => {
				const per_room_given_price = existingPrices.per_room_given_price
				let per_adult_with_extra_bed_given_price =
					existingPrices.per_adult_with_extra_bed_given_price
				let per_child_with_extra_bed_given_price =
					existingPrices.per_child_with_extra_bed_given_price
				let per_child_without_extra_bed_given_price =
					existingPrices.per_child_without_extra_bed_given_price

				// reset the per-person prices if values have been reset
				if (Number(adultsWithExtraBed) === 0) {
					per_adult_with_extra_bed_given_price = 0
					if (
						Number(per_adult_with_extra_bed_given_price) !==
						Number(existingPrices.per_adult_with_extra_bed_given_price)
					) {
						form.change(
							`${name}.per_adult_with_extra_bed_given_price`,
							per_adult_with_extra_bed_given_price
						)
					}
				}
				if (Number(childrenWithExtraBed) === 0) {
					per_child_with_extra_bed_given_price = 0
					if (
						Number(per_child_with_extra_bed_given_price) !==
						Number(existingPrices.per_child_with_extra_bed_given_price)
					) {
						form.change(
							`${name}.per_child_with_extra_bed_given_price`,
							per_child_with_extra_bed_given_price
						)
					}
				}
				if (Number(childrenWithoutExtraBed) === 0) {
					per_child_without_extra_bed_given_price = 0
					if (
						Number(per_child_without_extra_bed_given_price) !==
						Number(existingPrices.per_child_without_extra_bed_given_price)
					) {
						form.change(
							`${name}.per_child_without_extra_bed_given_price`,
							per_child_without_extra_bed_given_price
						)
					}
				}
				let given_price = 0
				// now reset the given_price if one of the components is missing but required to calculate the price
				if (
					(!per_room_given_price && (noOfRooms || personsPerRoom)) ||
					(!per_adult_with_extra_bed_given_price && adultsWithExtraBed) ||
					(!per_child_with_extra_bed_given_price && childrenWithExtraBed) ||
					(!per_child_without_extra_bed_given_price && childrenWithoutExtraBed)
				) {
					given_price = 0
				} else {
					given_price =
						Number(noOfRooms || 0) *
							Number(existingPrices.per_room_given_price) +
						Number(adultsWithExtraBed || 0) *
							Number(per_adult_with_extra_bed_given_price || 0) +
						Number(childrenWithExtraBed || 0) *
							Number(per_child_with_extra_bed_given_price || 0) +
						Number(childrenWithoutExtraBed || 0) *
							Number(per_child_without_extra_bed_given_price || 0)
				}
				if (existingPrices.given_price !== given_price) {
					form.change(`${name}.given_price`, given_price)
				}
			})
		}
	}, [
		noOfRooms,
		personsPerRoom,
		adultsWithExtraBed,
		childrenWithExtraBed,
		childrenWithoutExtraBed,
		form,
		name,
		edited_given_price,
		per_room_given_price,
		per_adult_with_extra_bed_given_price,
		per_child_with_extra_bed_given_price,
		per_child_without_extra_bed_given_price,
		given_price,
	])

	const invalidGivenPrices = !date_wise_price.given_price

	return (
		<>
			<Box style={{ width: "100px" }}>
				<Button
					size="sm"
					fullWidth
					onClick={open}
					status={invalidGivenPrices ? "warning" : undefined}
					level={invalidGivenPrices ? "primary" : undefined}
				>
					<Inline gap="2" alignItems="center">
						{invalidGivenPrices ? (
							<Box>
								<Icons.AttentionSolid />
							</Box>
						) : null}
						{numberToLocalString(date_wise_price.given_price || 0)}
					</Inline>
				</Button>
			</Box>
			<Dialog
				sm
				open={isOpen}
				onClose={close}
				title={`Given Price - ${formatDate(date, "D MMM")}`}
			>
				<PerRoomPriceForm
					date={date}
					dates={dates}
					initialValues={{
						currency: currency || defaultCurrency,
						per_room: Number(date_wise_price.per_room_given_price || 0),
						per_adult: Number(date_wise_price.per_adult_given_price || 0),
						per_adult_with_extra_bed: Number(
							date_wise_price.per_adult_with_extra_bed_given_price || 0
						),
						per_child_with_extra_bed: Number(
							date_wise_price.per_child_with_extra_bed_given_price || 0
						),
						per_child_without_extra_bed: Number(
							date_wise_price.per_child_without_extra_bed_given_price || 0
						),
					}}
					noOfRooms={noOfRooms}
					personsPerRoom={personsPerRoom}
					adultsWithExtraBed={adultsWithExtraBed}
					childrenWithExtraBed={childrenWithExtraBed}
					childrenWithoutExtraBed={childrenWithoutExtraBed}
					onSubmit={(values, keep_same_for_other_nights) => {
						const per_room_given_price = Number(values.per_room)
						const per_adult_given_price = Number(values.per_adult)
						const per_adult_with_extra_bed_given_price = Number(
							values.per_adult_with_extra_bed
						)
						const per_child_with_extra_bed_given_price = Number(
							values.per_child_with_extra_bed
						)
						const per_child_without_extra_bed_given_price = Number(
							values.per_child_without_extra_bed
						)
						const total = Number(values.total)

						onChange(
							{
								total,
								currency: values.currency,
								per_room_given_price,
								per_adult_given_price,
								per_adult_with_extra_bed_given_price,
								per_child_with_extra_bed_given_price,
								per_child_without_extra_bed_given_price,
								edited_given_price:
									!per_person_price ||
									values.currency !== currency ||
									Number(per_person_price.room) !== per_room_given_price ||
									Number(per_person_price.adult_with_extra_bed) !==
										per_adult_with_extra_bed_given_price ||
									Number(per_person_price.child_with_extra_bed) !==
										per_child_with_extra_bed_given_price ||
									Number(per_person_price.child_without_extra_bed) !==
										per_child_without_extra_bed_given_price,
							},
							Boolean(keep_same_for_other_nights)
						)
						close()
					}}
					onCancel={close}
				/>
			</Dialog>
		</>
	)
}

function ReadOnlyGivenPriceDuringBooking({
	name,
	noOfRooms,
	personsPerRoom,
	adultsWithExtraBed,
	childrenWithExtraBed,
	childrenWithoutExtraBed,
}: {
	name: string
	noOfRooms?: number
	personsPerRoom?: number
	adultsWithExtraBed?: number
	childrenWithExtraBed?: number
	childrenWithoutExtraBed?: number
}) {
	const { value: date_wise_price, onChange: setDateWisePrice } =
		useFieldValue<IDaywiseHotelPrice>(name)

	const { currency, given_price } = date_wise_price

	const dateWisePriceRef = useRef(date_wise_price)
	dateWisePriceRef.current = date_wise_price

	const currentRoomConfigurationRef = useRef({
		currency,
		noOfRooms,
		personsPerRoom,
		adultsWithExtraBed,
		childrenWithExtraBed,
		childrenWithoutExtraBed,
	})

	// reset the the given price if room configuration changes
	useEffect(() => {
		const lastRoomConfiguration = { ...currentRoomConfigurationRef.current }
		if (
			lastRoomConfiguration.noOfRooms !== noOfRooms ||
			lastRoomConfiguration.personsPerRoom !== personsPerRoom ||
			lastRoomConfiguration.adultsWithExtraBed !== adultsWithExtraBed ||
			lastRoomConfiguration.childrenWithExtraBed !== childrenWithExtraBed ||
			lastRoomConfiguration.childrenWithoutExtraBed !==
				childrenWithoutExtraBed ||
			lastRoomConfiguration.currency !== currency
		) {
			// reset the given prices
			const handler = setTimeout(() => {
				setDateWisePrice({
					...dateWisePriceRef.current,
					given_price: undefined,
					per_room_given_price: undefined,
					per_adult_given_price: undefined,
					per_adult_with_extra_bed_given_price: undefined,
					per_child_with_extra_bed_given_price: undefined,
					per_child_without_extra_bed_given_price: undefined,
					edited_given_price: true,
				})
				currentRoomConfigurationRef.current = {
					noOfRooms,
					personsPerRoom,
					adultsWithExtraBed,
					childrenWithExtraBed,
					childrenWithoutExtraBed,
					currency,
				}
			})
			return () => clearTimeout(handler)
		}
	}, [
		setDateWisePrice,
		currentRoomConfigurationRef,
		noOfRooms,
		personsPerRoom,
		adultsWithExtraBed,
		childrenWithExtraBed,
		childrenWithoutExtraBed,
		currency,
	])

	return (
		<>
			{isNumeric(given_price) && Number(given_price) > 0 ? (
				<Money currency={currency} amount={given_price} />
			) : (
				<Text color="muted">N/A</Text>
			)}
		</>
	)
}

function BookedPriceInputField({
	name,
	noOfRooms,
	personsPerRoom,
	adultsWithExtraBed,
	childrenWithExtraBed,
	childrenWithoutExtraBed,
	dates,
	onChange,
	defaultCurrency,
}: {
	name: string
	noOfRooms?: number
	personsPerRoom?: number
	adultsWithExtraBed?: number
	childrenWithExtraBed?: number
	childrenWithoutExtraBed?: number
	dates: Array<Date>
	onChange: (
		values: {
			currency: string
			per_room_booked_price: number
			per_adult_booked_price: number
			per_adult_with_extra_bed_booked_price: number
			per_child_with_extra_bed_booked_price: number
			per_child_without_extra_bed_booked_price: number
			edited_booked_price: boolean
			total: number
		},
		keepSameForOthers: boolean
	) => void
	defaultCurrency: string
}) {
	const { value: date_wise_price } = useFieldValue<IDaywiseHotelPrice>(name)
	const [isOpen, open, close] = useDialog()
	const form = useForm()

	const {
		date,
		edited_booked_price = false,
		currency,
		per_person_price,
		booked_price,
		per_room_booked_price,
		per_adult_with_extra_bed_booked_price,
		per_child_with_extra_bed_booked_price,
		per_child_without_extra_bed_booked_price,
	} = date_wise_price

	// synchronize the booked price if using rates and prices haven't changed
	useEffect(() => {
		if (edited_booked_price || !per_person_price) return
		const perRoomBookedPrice = per_person_price.room
		const perAdultBookedPrice = per_person_price.adult
		const perAdultWithExtraBedBookedPrice = adultsWithExtraBed
			? per_person_price.adult_with_extra_bed
			: 0
		const perChildWithExtraBedBookedPrice = childrenWithExtraBed
			? per_person_price.child_with_extra_bed
			: 0
		const perChildWithoutExtraBedBookedPrice = childrenWithoutExtraBed
			? per_person_price.child_without_extra_bed
			: 0

		form.batch(() => {
			form.change(`${name}.per_room_booked_price`, perRoomBookedPrice)
			form.change(`${name}.per_adult_booked_price`, perAdultBookedPrice)
			form.change(
				`${name}.per_adult_with_extra_bed_booked_price`,
				perAdultWithExtraBedBookedPrice
			)
			form.change(
				`${name}.per_child_with_extra_bed_booked_price`,
				perChildWithExtraBedBookedPrice
			)
			form.change(
				`${name}.per_child_without_extra_bed_booked_price`,
				perChildWithoutExtraBedBookedPrice
			)
			form.change(
				`${name}.booked_price`,
				Number(noOfRooms) * Number(perRoomBookedPrice) +
					Number(adultsWithExtraBed) * Number(perAdultWithExtraBedBookedPrice) +
					Number(childrenWithExtraBed) *
						Number(perChildWithExtraBedBookedPrice) +
					Number(childrenWithoutExtraBed) *
						Number(perChildWithoutExtraBedBookedPrice)
			)
		})
	}, [
		edited_booked_price,
		per_person_price,
		noOfRooms,
		adultsWithExtraBed,
		personsPerRoom,
		childrenWithExtraBed,
		childrenWithoutExtraBed,
		name,
		form,
	])

	// auto-calculate the per-person prices if pax changes and we have per-person
	useEffect(() => {
		// if not edited, prices will be auto-calculated
		if (!edited_booked_price) return
		const existingPrices = {
			per_room_booked_price,
			per_adult_with_extra_bed_booked_price,
			per_child_with_extra_bed_booked_price,
			per_child_without_extra_bed_booked_price,
			booked_price,
		}
		if (existingPrices.per_room_booked_price) {
			form.batch(() => {
				const per_room_booked_price = existingPrices.per_room_booked_price
				let per_adult_with_extra_bed_booked_price =
					existingPrices.per_adult_with_extra_bed_booked_price
				let per_child_with_extra_bed_booked_price =
					existingPrices.per_child_with_extra_bed_booked_price
				let per_child_without_extra_bed_booked_price =
					existingPrices.per_child_without_extra_bed_booked_price

				// reset the per-person prices if values have been reset
				if (Number(adultsWithExtraBed) === 0) {
					per_adult_with_extra_bed_booked_price = 0
					if (
						Number(per_adult_with_extra_bed_booked_price) !==
						Number(existingPrices.per_adult_with_extra_bed_booked_price)
					) {
						form.change(
							`${name}.per_adult_with_extra_bed_booked_price`,
							per_adult_with_extra_bed_booked_price
						)
					}
				}
				if (Number(childrenWithExtraBed) === 0) {
					per_child_with_extra_bed_booked_price = 0
					if (
						Number(per_child_with_extra_bed_booked_price) !==
						Number(existingPrices.per_child_with_extra_bed_booked_price)
					) {
						form.change(
							`${name}.per_child_with_extra_bed_booked_price`,
							per_child_with_extra_bed_booked_price
						)
					}
				}
				if (Number(childrenWithoutExtraBed) === 0) {
					per_child_without_extra_bed_booked_price = 0
					if (
						Number(per_child_without_extra_bed_booked_price) !==
						Number(existingPrices.per_child_without_extra_bed_booked_price)
					) {
						form.change(
							`${name}.per_child_without_extra_bed_booked_price`,
							per_child_without_extra_bed_booked_price
						)
					}
				}
				let booked_price = 0
				// now reset the booked_price if one of the components is missing but required to calculate the price
				if (
					(!per_room_booked_price && (noOfRooms || personsPerRoom)) ||
					(!per_adult_with_extra_bed_booked_price && adultsWithExtraBed) ||
					(!per_child_with_extra_bed_booked_price && childrenWithExtraBed) ||
					(!per_child_without_extra_bed_booked_price && childrenWithoutExtraBed)
				) {
					booked_price = 0
				} else {
					booked_price =
						Number(noOfRooms || 0) *
							Number(existingPrices.per_room_booked_price) +
						Number(adultsWithExtraBed || 0) *
							Number(per_adult_with_extra_bed_booked_price || 0) +
						Number(childrenWithExtraBed || 0) *
							Number(per_child_with_extra_bed_booked_price || 0) +
						Number(childrenWithoutExtraBed || 0) *
							Number(per_child_without_extra_bed_booked_price || 0)
				}
				if (existingPrices.booked_price !== booked_price) {
					form.change(`${name}.booked_price`, booked_price)
				}
			})
		}
	}, [
		noOfRooms,
		personsPerRoom,
		adultsWithExtraBed,
		childrenWithExtraBed,
		childrenWithoutExtraBed,
		form,
		name,
		edited_booked_price,
		per_room_booked_price,
		per_adult_with_extra_bed_booked_price,
		per_child_with_extra_bed_booked_price,
		per_child_without_extra_bed_booked_price,
		booked_price,
	])

	const invalidBookedPrices = !date_wise_price.booked_price

	return (
		<>
			<Box style={{ width: "100px" }}>
				<Button
					size="sm"
					fullWidth
					onClick={open}
					status={invalidBookedPrices ? "warning" : undefined}
					level={invalidBookedPrices ? "primary" : undefined}
				>
					<Inline gap="2" alignItems="center">
						{invalidBookedPrices ? (
							<Box>
								<Icons.AttentionSolid />
							</Box>
						) : null}
						{numberToLocalString(date_wise_price.booked_price || 0)}
					</Inline>
				</Button>
			</Box>
			<Dialog
				sm
				open={isOpen}
				onClose={close}
				title={`Booked Price - ${formatDate(date, "D MMM")}`}
			>
				<PerRoomPriceForm
					date={date}
					dates={dates}
					initialValues={{
						currency: currency || defaultCurrency,
						per_room: Number(date_wise_price.per_room_booked_price || 0),
						per_adult: Number(date_wise_price.per_adult_booked_price || 0),
						per_adult_with_extra_bed: Number(
							date_wise_price.per_adult_with_extra_bed_booked_price || 0
						),
						per_child_with_extra_bed: Number(
							date_wise_price.per_child_with_extra_bed_booked_price || 0
						),
						per_child_without_extra_bed: Number(
							date_wise_price.per_child_without_extra_bed_booked_price || 0
						),
					}}
					noOfRooms={noOfRooms}
					personsPerRoom={personsPerRoom}
					adultsWithExtraBed={adultsWithExtraBed}
					childrenWithExtraBed={childrenWithExtraBed}
					childrenWithoutExtraBed={childrenWithoutExtraBed}
					onSubmit={(values, keep_same_for_other_nights) => {
						const per_room_booked_price = Number(values.per_room)
						const per_adult_booked_price = Number(values.per_adult)
						const per_adult_with_extra_bed_booked_price = Number(
							values.per_adult_with_extra_bed
						)
						const per_child_with_extra_bed_booked_price = Number(
							values.per_child_with_extra_bed
						)
						const per_child_without_extra_bed_booked_price = Number(
							values.per_child_without_extra_bed
						)
						const total = Number(values.total)
						onChange(
							{
								total,
								currency: values.currency,
								per_room_booked_price,
								per_adult_booked_price,
								per_adult_with_extra_bed_booked_price,
								per_child_with_extra_bed_booked_price,
								per_child_without_extra_bed_booked_price,
								edited_booked_price:
									!per_person_price ||
									Number(per_person_price.room) !== per_room_booked_price ||
									Number(per_person_price.adult_with_extra_bed) !==
										per_adult_with_extra_bed_booked_price ||
									Number(per_person_price.child_with_extra_bed) !==
										per_child_with_extra_bed_booked_price ||
									Number(per_person_price.child_without_extra_bed) !==
										per_child_without_extra_bed_booked_price,
							},
							Boolean(keep_same_for_other_nights)
						)
						close()
					}}
					onCancel={close}
				/>
			</Dialog>
		</>
	)
}

function PerRoomPriceForm({
	noOfRooms,
	personsPerRoom,
	adultsWithExtraBed,
	childrenWithExtraBed,
	childrenWithoutExtraBed,
	initialValues,
	onSubmit,
	date,
	dates,
	onCancel,
}: {
	noOfRooms: number | undefined
	personsPerRoom: number | undefined
	adultsWithExtraBed: number | undefined
	childrenWithExtraBed: number | undefined
	childrenWithoutExtraBed: number | undefined
	initialValues: {
		currency: string
		per_room: number
		per_adult: number
		per_adult_with_extra_bed: number
		per_child_with_extra_bed: number
		per_child_without_extra_bed: number
	}
	onSubmit: (
		data: {
			currency: string
			total: number
			per_room: number
			per_adult: number
			per_adult_with_extra_bed: number
			per_child_with_extra_bed: number
			per_child_without_extra_bed: number
		},
		keep_same_for_other_nights: boolean
	) => void
	date: Date
	dates: Array<Date>
	onCancel: () => void
}) {
	const perRoomPriceFormDataValidator = useMemo(() => {
		const requiredAmountValidator = EmptyNumberValidator()
			.min(0, "Enter non-negative value")
			.required("Required")
		const optionalAmountValidator = EmptyNumberValidator().nullable(true)
		return validateFormValues(
			Validator.object().shape({
				per_room: requiredAmountValidator.clone(),
				per_adult_with_extra_bed: adultsWithExtraBed
					? requiredAmountValidator.clone()
					: optionalAmountValidator.clone(),
				per_child_with_extra_bed: childrenWithExtraBed
					? requiredAmountValidator.clone()
					: optionalAmountValidator.clone(),
				per_child_without_extra_bed: childrenWithoutExtraBed
					? requiredAmountValidator.clone()
					: optionalAmountValidator.clone(),
			})
		)
	}, [adultsWithExtraBed, childrenWithExtraBed, childrenWithoutExtraBed])
	return (
		<Form<
			typeof initialValues & {
				currency: string
				keep_same_for_other_nights: boolean
			}
		>
			initialValues={{
				...initialValues,
				keep_same_for_other_nights: false,
			}}
			validate={perRoomPriceFormDataValidator}
			onSubmit={({ keep_same_for_other_nights, currency, ...values }) => {
				const per_room = Number(values.per_room)
				const per_adult = Number(values.per_adult)
				const per_adult_with_extra_bed = Number(values.per_adult_with_extra_bed)
				const per_child_with_extra_bed = Number(values.per_child_with_extra_bed)
				const per_child_without_extra_bed = Number(
					values.per_child_without_extra_bed
				)
				const total =
					Number(noOfRooms) * per_room +
					Number(adultsWithExtraBed) * per_adult_with_extra_bed +
					Number(childrenWithExtraBed) * per_child_with_extra_bed +
					Number(childrenWithoutExtraBed) * per_child_without_extra_bed
				onSubmit(
					{
						currency,
						total,
						per_room,
						per_adult,
						per_adult_with_extra_bed,
						per_child_with_extra_bed,
						per_child_without_extra_bed,
					},
					Boolean(keep_same_for_other_nights)
				)
			}}
			subscription={{}}
		>
			{({ handleSubmit, form }) => (
				<form onSubmit={handleSubmit} noValidate>
					<GetFieldValue<string> name="currency">
						{({ value: currency }) => (
							<Dialog.Body>
								<Stack gap="6">
									<Table responsive bordered hover>
										<thead>
											<tr>
												<TableHeaderDataCell></TableHeaderDataCell>
												<TableHeaderDataCell>
													Price ({currency})
												</TableHeaderDataCell>
												<TableHeaderDataCell textAlign="center">
													Quantity
												</TableHeaderDataCell>
												<TableHeaderDataCell textAlign="right">
													Total
												</TableHeaderDataCell>
											</tr>
										</thead>
										<tbody>
											<tr>
												<TableDataCell fontWeight="semibold">
													/Room ({personsPerRoom}P)
												</TableDataCell>
												<TableDataCell>
													<Box style={{ maxWidth: "120px" }}>
														<TextInputField
															type="number"
															name={`per_room`}
															size="sm"
															onChange={(e) => {
																const value = e.currentTarget.value
																form.change("per_room", value as never)
																if (!value) {
																	form.change("per_adult", undefined)
																	return
																}
																form.change(
																	"per_adult",
																	Number(
																		formatMoneyByDecimal(
																			moneyParseByDecimal(
																				Number(value) / (personsPerRoom || 1),
																				currency
																			)
																		)
																	)
																)
															}}
														/>
													</Box>
												</TableDataCell>
												<TableDataCell textAlign="center">
													{Number(noOfRooms)}
												</TableDataCell>
												<TableDataCell textAlign="right">
													<GetFieldValue<number | undefined> name="per_room">
														{({ value }) =>
															value ? (
																<Text>
																	<Money
																		currency={currency}
																		amount={Number(value) * Number(noOfRooms)}
																	/>
																</Text>
															) : (
																<Text>-</Text>
															)
														}
													</GetFieldValue>
												</TableDataCell>
											</tr>
											<tr>
												<TableDataCell fontWeight="semibold">
													/AWEB
												</TableDataCell>
												<TableDataCell>
													<Box style={{ maxWidth: "120px" }}>
														{adultsWithExtraBed ? (
															<TextInputField
																type="number"
																name={`per_adult_with_extra_bed`}
																size="sm"
															/>
														) : (
															"-"
														)}
													</Box>
												</TableDataCell>
												<TableDataCell textAlign="center">
													{adultsWithExtraBed
														? Number(adultsWithExtraBed)
														: "-"}
												</TableDataCell>
												<TableDataCell textAlign="right">
													<GetFieldValue<
														number | undefined
													> name="per_adult_with_extra_bed">
														{({ value }) =>
															value && adultsWithExtraBed ? (
																<Text>
																	<Money
																		currency={currency}
																		amount={
																			Number(value) * Number(adultsWithExtraBed)
																		}
																	/>
																</Text>
															) : (
																<Text>-</Text>
															)
														}
													</GetFieldValue>
												</TableDataCell>
											</tr>
											<tr>
												<TableDataCell fontWeight="semibold">
													/CWEB
												</TableDataCell>
												<TableDataCell>
													<Box style={{ maxWidth: "120px" }}>
														{childrenWithExtraBed ? (
															<TextInputField
																type="number"
																name={`per_child_with_extra_bed`}
																size="sm"
															/>
														) : (
															"-"
														)}
													</Box>
												</TableDataCell>
												<TableDataCell textAlign="center">
													{childrenWithExtraBed
														? Number(childrenWithExtraBed)
														: "-"}
												</TableDataCell>
												<TableDataCell textAlign="right">
													<GetFieldValue<
														number | undefined
													> name="per_child_with_extra_bed">
														{({ value }) =>
															value && childrenWithExtraBed ? (
																<Text>
																	<Money
																		currency={currency}
																		amount={
																			Number(value) *
																			Number(childrenWithExtraBed)
																		}
																	/>
																</Text>
															) : (
																<Text>-</Text>
															)
														}
													</GetFieldValue>
												</TableDataCell>
											</tr>
											<tr>
												<TableDataCell fontWeight="semibold">
													/CNB
												</TableDataCell>
												<TableDataCell>
													<Box style={{ maxWidth: "120px" }}>
														{childrenWithoutExtraBed ? (
															<TextInputField
																type="number"
																name={`per_child_without_extra_bed`}
																size="sm"
															/>
														) : (
															"-"
														)}
													</Box>
												</TableDataCell>
												<TableDataCell textAlign="center">
													{childrenWithoutExtraBed
														? Number(childrenWithoutExtraBed)
														: "-"}
												</TableDataCell>
												<TableDataCell textAlign="right">
													<GetFieldValue<
														number | undefined
													> name="per_child_without_extra_bed">
														{({ value }) =>
															value && childrenWithoutExtraBed ? (
																<Text>
																	<Money
																		currency={currency}
																		amount={
																			Number(value) *
																			Number(childrenWithoutExtraBed)
																		}
																	/>
																</Text>
															) : (
																<Text>-</Text>
															)
														}
													</GetFieldValue>
												</TableDataCell>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<TableFooterDataCell
													colSpan={3}
													textAlign="right"
													fontWeight="semibold"
												>
													Total
												</TableFooterDataCell>
												<TableFooterDataCell textAlign="right">
													<FormSpy<typeof initialValues>
														subscription={{ values: true }}
													>
														{({ values }) => {
															return (
																<Text>
																	<Money
																		showCurrency
																		currency={currency}
																		amount={
																			Number(noOfRooms || 0) *
																				Number(values.per_room) +
																			Number(adultsWithExtraBed || 0) *
																				Number(
																					values.per_adult_with_extra_bed || 0
																				) +
																			Number(childrenWithExtraBed || 0) *
																				Number(
																					values.per_child_with_extra_bed || 0
																				) +
																			Number(childrenWithoutExtraBed || 0) *
																				Number(
																					values.per_child_without_extra_bed ||
																						0
																				)
																		}
																	/>
																</Text>
															)
														}}
													</FormSpy>
												</TableFooterDataCell>
											</tr>
										</tfoot>
									</Table>
									{dates.length > 1 ? (
										<Box>
											<SwitchInputField
												name="keep_same_for_other_nights"
												label={`Keep same prices for other nights: ${dates
													.filter((d) => !isSame(d, date, "day"))
													.map((date) => formatDate(date, "D MMM"))
													.join(", ")}`}
											/>
										</Box>
									) : null}
								</Stack>
							</Dialog.Body>
						)}
					</GetFieldValue>
					<Dialog.Footer>
						<Inline gap="4">
							<Button type="submit">Save</Button>
							<Button type="button" onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Dialog.Footer>
				</form>
			)}
		</Form>
	)
}

function createDatesFromStartDateAndNights(
	from: Date,
	nights = 1
): Array<Date> {
	const dates = []
	for (let i = 0; i < nights; i++) {
		dates.push(startOf(addUnit(from, i, "days"), "day"))
	}
	return dates
}

function getRemainingDates(
	allDates: Array<Date>,
	selectedDates: Array<Date>
): Array<Date> {
	return allDates.filter(
		(date) => !selectedDates.find((d) => isSame(d, date, "day"))
	)
}

function AddSimilarHotelOptionsField({
	name,
	bookingDates,
	bookingFrom,
	bookingTo,
	...props
}: Omit<CalculatePriceFormProps, "onChange"> & {
	name: string
	bookingDates: Array<{ id: string; name: string; value: Date }>
}) {
	const { value: hotel } =
		useFieldValue<Required<CalculatePriceParams>["hotels"][number]>(name)
	const form = useForm<CalculatePriceParams>()
	return hotel.dates?.length && hotel.hotel ? (
		<Component
			initialState={{
				showAddOptionsScreen: false,
				similarHotelOptions: hotel.similar_hotel_options,
			}}
		>
			{({ state, setState }) => {
				const primaryHotel = hotel.hotel
				if (!primaryHotel) return null
				return (
					<Box marginTop="4">
						<Button
							onClick={() =>
								setState({
									showAddOptionsScreen: true,
									similarHotelOptions: hotel.similar_hotel_options,
								})
							}
						>
							{hotel.similar_hotel_options?.length ? "Edit" : "Add"} Similar
							Hotels
						</Button>
						{state.showAddOptionsScreen ? (
							<Dialog
								xl
								open
								onClose={() =>
									setState({
										showAddOptionsScreen: false,
										similarHotelOptions: state.similarHotelOptions,
									})
								}
								title={`Add Similar Hotels`}
							>
								<Dialog.Body>
									<Box
										bgColor="primary"
										padding="4"
										borderWidth="1"
										borderColor="primary"
										rounded="lg"
										marginBottom="8"
									>
										<Grid>
											<Col xs="auto">
												<Box marginBottom="4">
													<Box fontSize="sm" color="muted" marginBottom="px">
														Nights
													</Box>
													<Box fontWeight="semibold">
														{bookingDates
															.filter((d) =>
																hotel.dates?.find((dd) =>
																	isSame(dd, d.value, "day")
																)
															)
															.map((d) => d.name)
															.join(", ")}
													</Box>
												</Box>
											</Col>
											<Col xs="auto">
												<Box marginBottom="4">
													<Box fontSize="sm" color="muted" marginBottom="px">
														Hotel Name
													</Box>
													<Box fontWeight="semibold">{primaryHotel.name}</Box>
													<Box display="flex" fontSize="sm">
														{joinAttributes(
															primaryHotel.location.short_name,
															primaryHotel.stars ? (
																<Stars stars={primaryHotel.stars} />
															) : null
														)}
													</Box>
												</Box>
											</Col>
											<Col xs="auto">
												<Box marginBottom="4">
													<Box fontSize="sm" color="muted" marginBottom="px">
														Meal Plan
													</Box>
													<Box fontWeight="semibold">
														{hotel.meal_plan?.description} (
														{hotel.meal_plan?.name})
													</Box>
												</Box>
											</Col>
											<Col xs="auto">
												<Box marginBottom="4">
													<Box fontSize="sm" color="muted" marginBottom="px">
														Rooms
													</Box>
													<Box fontWeight="semibold">
														{hotel.no_of_rooms} {hotel.room_type?.name}
													</Box>
													<Box fontSize="sm">
														{hotel.persons_per_room} Pax/room
													</Box>
												</Box>
											</Col>
											{hotel.adults_with_extra_bed ||
											hotel.children_with_extra_bed ||
											hotel.children_without_extra_bed ? (
												<Col xs="auto">
													<Box marginBottom="4">
														<Box fontSize="sm" color="muted" marginBottom="px">
															Extra Beds
														</Box>
														<Box fontWeight="semibold">
															{joinAttributes(
																hotel.adults_with_extra_bed
																	? `${hotel.adults_with_extra_bed} AWEB`
																	: null,
																hotel.children_with_extra_bed
																	? `${hotel.children_with_extra_bed} CWEB`
																	: null,
																hotel.children_without_extra_bed
																	? `${hotel.children_without_extra_bed} CNB`
																	: null
															)}
														</Box>
													</Box>
												</Col>
											) : null}
										</Grid>
									</Box>
									<CalculatePriceForm
										{...props}
										bookingFrom={bookingFrom}
										bookingTo={bookingTo}
										disableNights
										disableComments
										onChange={(hotels) => {
											setState({
												showAddOptionsScreen: true,
												// remove the current hotel if selected again
												similarHotelOptions:
													hotels?.filter(
														(h) => h.hotel?.id !== hotel.hotel?.id
													) || [],
											})
										}}
										initialValues={{
											hotels: state.similarHotelOptions?.length
												? state.similarHotelOptions
												: [
														{
															...hotel,
															hotel: undefined,
															date_wise_prices: [],
														},
													],
										}}
									/>
									<Divider />
									<Inline gap="4">
										<Button
											status="primary"
											level="primary"
											size="lg"
											onClick={() => {
												form.change(
													`${name}.similar_hotel_options` as never,
													state.similarHotelOptions as never
												)
												setState({
													showAddOptionsScreen: false,
													similarHotelOptions: state.similarHotelOptions,
												})
											}}
										>
											Save
										</Button>
										<Button
											level="tertiary"
											size="lg"
											onClick={() =>
												setState({
													showAddOptionsScreen: false,
													similarHotelOptions: state.similarHotelOptions,
												})
											}
										>
											Cancel
										</Button>
									</Inline>
								</Dialog.Body>
							</Dialog>
						) : null}
					</Box>
				)
			}}
		</Component>
	) : null
}

function TotalGivenPriceForHotel({ name }: { name: string }) {
	const { value: hotel } =
		useFieldValue<Required<CalculatePriceParams>["hotels"][number]>(name)
	const {
		hotel: selectedHotel,
		date_wise_prices,
		similar_hotel_options,
	} = hotel
	const dateWiseMaxPrices = useMemo(() => {
		if (
			!selectedHotel ||
			!date_wise_prices?.length ||
			!similar_hotel_options?.length
		)
			return []
		return date_wise_prices.map((p, index) => {
			const maxPrice = {
				hotel: selectedHotel,
				currency: p.currency,
				given_price: p.given_price || 0,
				date: p.date,
				fromBaseHotel: true,
			}
			similar_hotel_options?.forEach((option) => {
				const { hotel, date_wise_prices } = option
				if (!hotel || !date_wise_prices?.length) {
					return
				}
				const given_price = date_wise_prices[index]?.given_price || 0
				if (maxPrice.given_price < given_price) {
					maxPrice.given_price = given_price
					maxPrice.hotel = hotel
					maxPrice.fromBaseHotel = false
				}
			})
			return maxPrice
		})
	}, [selectedHotel, date_wise_prices, similar_hotel_options])
	if (
		!hotel.hotel ||
		!hotel.date_wise_prices?.length ||
		!hotel.similar_hotel_options?.length
	)
		return null
	const dateWiseMaxPricesMoney = dateWiseMaxPrices.map((m) =>
		moneyParseByDecimal(m.given_price, m.currency)
	)
	const totalPrice = dateWiseMaxPricesMoney.length
		? addMoney(dateWiseMaxPricesMoney[0], ...dateWiseMaxPricesMoney.slice(1))
		: undefined
	return (
		<Box textAlign="right">
			<Tooltip
				interactive
				content={
					<Stack gap="1">
						<Box paddingBottom="1" borderBottomWidth="1">
							<Text>Total price considering hotel options</Text>
						</Box>
						<table>
							<tbody>
								{dateWiseMaxPrices.map((p) => (
									<tr key={String(p.date)}>
										<td>
											<Box marginTop="1">{p.hotel.name}</Box>
											<Box fontSize="xs">
												{joinAttributes(
													formatDate(p.date, "DD MMM"),
													!p.fromBaseHotel ? "(Option)" : null
												)}
											</Box>
										</td>
										<Box
											as="td"
											textAlign="right"
											paddingLeft="4"
											verticalAlign="top"
										>
											<Box fontSize="2xl" fontWeight="semibold">
												<Money currency={p.currency} amount={p.given_price} />
											</Box>
										</Box>
									</tr>
								))}
							</tbody>
						</table>
					</Stack>
				}
			>
				<Button size="sm">
					Total Price:{" "}
					{totalPrice ? <Money money={totalPrice} showCurrency /> : "N/A"}
				</Button>
			</Tooltip>
		</Box>
	)
}
