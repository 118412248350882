/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react"
import { Omit } from "utility-types"
import { contains, ownerDocument, listen } from "../dom-helpers"
import classNames from "classnames"
import { Input, InputProps } from "../Input"
import { Box } from "../Box"
import {
	getSelectClassName,
	groupClassName,
	listBoxClassName,
	getOptionClassName,
	loaderClassName,
} from "./select.css"

import createCache from "./cache"

const Cache = createCache()

function Loader() {
	return <Box className={loaderClassName} />
}

function defaultCreateOptionLabel(label: string): React.ReactNode {
	return `Add "${label}"`
}

function OptionItemRenderer({
	option,
	labelKey,
	created,
	createOptionLabel,
}: {
	option: any
	labelKey: string
	created?: boolean
	createOptionLabel: typeof defaultCreateOptionLabel
}) {
	const label = getLabelForOption(option, labelKey)
	return <Box as="span">{created ? createOptionLabel(label) : label}</Box>
}

type TOption = any

interface SelectOwnProps {
	className?: string
	creatable?: boolean
	disabled?: boolean
	fetchOnMount?: boolean
	label?: React.ReactNode
	labelKey?: string
	multiple?: boolean
	name?: string
	onBlur?: (e: any) => void
	onChange?: (value: TOption | Array<TOption>, name: string) => void
	onFocus?: (e: any) => void
	onQuery?: (query: string) => void
	onQueryMore?: (query: string) => void
	options?: Array<TOption>
	placeholder?: string
	menuPlaceholder?: string
	query?: string
	required?: boolean
	searchable?: boolean
	value?: TOption | Array<TOption>
	isLoading?: boolean
	optionRenderer?: typeof OptionItemRenderer
	inline?: boolean
	onCreateNew?: (query: string) => void | Promise<any> | any
	createOptionLabel?: (query: string) => React.ReactNode
	filterOptions?: (
		options?: Array<TOption>,
		query?: string
	) => Array<TOption> | undefined
	fullWidth?: boolean
	minWidth?: string
	/**
	 * Close the dropdown on select,
	 * Defaults to true for single value select
	 */
	closeOnSelect?: boolean
	clearable?: boolean
	multiSelectValueRenderer?: typeof DefaultMultiSelectValueRenderer
}

export interface SelectProps
	extends SelectOwnProps,
		Omit<InputProps, keyof SelectOwnProps> {}

function BaseSelect({
	className = "",
	creatable = false,
	disabled = false,
	fetchOnMount,
	label,
	labelKey = "name",
	multiple,
	name: propName,
	onBlur,
	onChange,
	onFocus,
	onQuery,
	onQueryMore,
	options = [],
	placeholder = "Type to search...",
	menuPlaceholder = "Type to search...",
	query = "",
	required,
	searchable = true,
	value,
	isLoading,
	optionRenderer: OptionRenderer = OptionItemRenderer,
	inline = false,
	onCreateNew,
	createOptionLabel = defaultCreateOptionLabel,
	filterOptions,
	fullWidth,
	closeOnSelect,
	minWidth = "150px",
	clearable = true,
	multiSelectValueRenderer:
		MultiSelectValueRenderer = DefaultMultiSelectValueRenderer,
	...props
}: SelectProps) {
	const groupRef = useRef<HTMLDivElement>(null)
	const inputRef = useRef<HTMLInputElement>(null)
	const [isFocused, changeFocusState] = useState<boolean>(false)
	if (closeOnSelect === undefined && !multiple) {
		closeOnSelect = true
	}
	useEffect(() => {
		if (fetchOnMount) {
			onQuery && onQuery(query || "")
		}
	}, [fetchOnMount])
	const [focusedOption, changeFocusedOption] = useState<number | undefined>(
		undefined
	)
	const name: string = propName || (multiple ? "select[]" : "select")
	if (filterOptions && searchable) {
		options = filterOptions(options, query) || []
	}
	if (creatable && query && query.trim() && !isLoading) {
		// check if we have an exact match
		const exactMatch = (options || []).some((option: any) => {
			return (
				String(getLabelForOption(option, labelKey)).toLowerCase() ===
				query.trim().toLowerCase()
			)
		})
		if (!exactMatch) {
			options = (options || []).concat([
				{
					id: query.trim(),
					name: query.trim(),
					[labelKey]: query.trim(),
					__created: true,
				},
			])
		}
	}
	if (value) {
		let moreOptions = []
		if (Array.isArray(value)) {
			moreOptions = value
		} else {
			moreOptions = [value]
		}
		// only push the more options if they are not already present in
		// the options list
		moreOptions = moreOptions.filter(
			(moreOption) =>
				!options.some((option) => matchOptions(option, moreOption, labelKey))
		)
		options = options.concat(moreOptions)
	}
	const readOnly = props.readOnly

	const setIsFouced = React.useCallback(
		(isFocused: boolean) => {
			if (!readOnly) {
				changeFocusState(isFocused)
				if (!isFocused) {
					onBlur && onBlur({ target: { name } })
				}
			}
		},
		[onBlur, changeFocusState, readOnly]
	)
	// handle the focused state
	useEffect(() => {
		const document = ownerDocument()
		if (!document || disabled || !groupRef.current) {
			return () => undefined
		}
		function handleClick(e: any) {
			const container = groupRef.current
			if (contains(container, e.target)) {
				switch (e.key) {
					case undefined:
					case "Tab":
					case "ArrowDown":
					case "ArrowUp":
						if (!isFocused) {
							setIsFouced(true)
						}
				}
			} else if (isFocused) {
				setIsFouced(false)
			}
		}
		const removeListeners = [
			listen(document, "click", handleClick),
			listen(document, "keyup", handleClick),
			listen(document, "focus", handleClick),
		]
		return () => {
			removeListeners.forEach((fn) => fn())
		}
	}, [isFocused, groupRef.current])
	// handle the keyboad navigation
	useEffect(() => {
		const document = ownerDocument()
		// handle the base cases where no need to manage the keyboad focus stuff
		if (!isFocused || !document || disabled || !options || !options.length) {
			changeFocusedOption(undefined)
			return () => undefined
		}
		// if no option is focused
		// focus the first selected option or first option if no option is selected
		if (focusedOption === undefined) {
			if (!value || (Array.isArray(value) && !value.length)) {
				changeFocusedOption(0)
			} else {
				const selectedOptionIndex = options.findIndex((o) =>
					Array.isArray(value)
						? value.some((v) => matchOptions(v, o, labelKey))
						: matchOptions(value, o, labelKey)
				)
				changeFocusedOption(selectedOptionIndex)
			}
		}
		function handleKeyDown(e: any) {
			const { key } = e
			const numberOfOptions = options.length
			let option: (typeof options)[number]
			let checked = false
			switch (key) {
				case "ArrowDown":
					changeFocusedOption(((focusedOption || 0) + 1) % numberOfOptions)
					break
				case "ArrowUp":
					changeFocusedOption(
						(() => {
							const x = ((focusedOption || 0) - 1) % numberOfOptions
							return x < 0 ? x + numberOfOptions : x
						})()
					)
					break
				case "Enter":
					e.preventDefault()
					e.stopPropagation()
					option = options[focusedOption || 0]
					checked = value
						? Array.isArray(value)
							? value.some((v) => matchOptions(v, option, labelKey))
							: matchOptions(value, option, labelKey)
						: false
					handleOptionClick(option, !checked)
					break
				default:
					break
			}
		}
		return listen(document, "keydown", handleKeyDown)
	}, [isFocused, focusedOption, options, value])

	// handle the option click
	const handleOptionClick = React.useCallback(
		(option: any, checked: boolean) => {
			Promise.resolve()
				.then(() => {
					if (onCreateNew && option && option.__created) {
						const newOption = onCreateNew(option.name)
						return Promise.resolve(newOption)
					}
					return option
				})
				.then((option: any) => {
					if (!option) return
					if (onChange) {
						const newValues = checked
							? multiple
								? (value || []).concat([option])
								: option
							: multiple
								? (value || []).filter(
										(v: any) => !matchOptions(v, option, labelKey)
									)
								: clearable
									? undefined
									: option
						onChange(newValues, name)
						if (closeOnSelect && newValues) {
							setTimeout(() => {
								setIsFouced(false)
							})
						}
					}
				})
		},
		[onChange, multiple, value, setIsFouced, closeOnSelect, clearable]
	)
	return (
		<Box
			display="block"
			className={classNames(
				getSelectClassName({
					display: inline ? "inline" : undefined,
					searchable: !searchable ? false : undefined,
					focused: isFocused,
				}),
				className
			)}
			data-focused={isFocused}
		>
			<Box
				role="group"
				ref={groupRef}
				position="relative"
				className={groupClassName}
				style={{ minWidth }}
				maxWidth={fullWidth ? "full" : "sm"}
			>
				{label ? (
					<Box as="label" fontSize="base" htmlFor={name}>
						{label}
					</Box>
				) : null}
				{inline && !searchable ? null : (
					<Input
						{...props}
						type="search"
						width="full"
						value={
							(isFocused
								? query
								: !multiple && value
									? getLabelForOption(value, labelKey)
									: "") || ""
						}
						disabled={disabled}
						onChange={(e) => {
							onQuery && onQuery(e.currentTarget.value)
						}}
						id={props.id || name}
						onFocus={onFocus}
						required={required}
						readOnly={!searchable || readOnly}
						placeholder={placeholder}
						aria-haspopup={true}
						aria-autocomplete={searchable ? "inline" : "list"}
						autoComplete="no_please"
						ref={inputRef}
					/>
				)}
				{isLoading ? !searchable && options.length ? null : <Loader /> : null}
				<Box
					as="ol"
					role="listbox"
					className={listBoxClassName}
					aria-multiselectable={multiple}
					width="full"
					maxWidth="full"
					left="0"
					margin="0"
					padding="0"
					zIndex="10"
					borderColor="default"
					overflow="auto"
					rounded="md"
					display={!inline && !isFocused ? "none" : "block"}
					position={inline ? "relative" : "absolute"}
					boxShadow={!searchable && inline ? undefined : "base"}
					bgColor={!searchable && inline ? "transparent" : "default"}
					borderWidth={!searchable && inline ? undefined : "1"}
				>
					{isFocused && options.length === 0 && menuPlaceholder ? (
						<Option
							as="li"
							role="option"
							className={getOptionClassName}
							disabled
							color="default"
							aria-readonly={true}
						>
							{menuPlaceholder}
						</Option>
					) : null}
					{options.map((option, i) => {
						const checked = value
							? multiple
								? (value || []).some((v: any) =>
										matchOptions(v, option, labelKey)
									)
								: matchOptions(value, option)
							: false
						const optionDisabled =
							disabled || typeof option === "object" ? option.disabled : false
						return (
							<Option
								key={getMatcherValueForOption(option)}
								data-testid={`${name}_option_${i}`}
								checked={checked}
								focused={i === focusedOption}
								title={
									typeof option === "object"
										? option.title || option.description
										: getLabelForOption(option, labelKey)
								}
								disabled={optionDisabled}
								onClick={(checked) => {
									if (optionDisabled) return
									handleOptionClick(option, checked)
								}}
								onMouseOver={() => {
									changeFocusedOption(i)
								}}
							>
								<Box display="flex" alignItems="center">
									<Box
										as="input"
										readOnly
										type={multiple ? "checkbox" : "radio"}
										checked={checked}
										disabled={optionDisabled}
										marginRight="2"
										tabIndex={-1}
										id={`${props.id || name}_option_${i}`}
										data-testid={`${props.id || name}_option_${i}`}
									/>
									<Box flex="1" minWidth="0" pointerEvents="none">
										<OptionRenderer
											option={option}
											created={option.__created}
											labelKey={labelKey}
											createOptionLabel={createOptionLabel}
										/>
									</Box>
								</Box>
							</Option>
						)
					})}
					{options.length && onQueryMore ? (
						<Option
							as="li"
							role="option"
							className={getOptionClassName}
							color="default"
							disabled={isLoading}
							onClick={() => onQueryMore(query)}
						>
							{isLoading ? "Loading..." : "Show more"}
						</Option>
					) : null}
				</Box>
			</Box>
			{multiple && !inline && value && value.length ? (
				<Box
					as="ul"
					className="selected-list"
					listStyleType="none"
					padding="0"
					display="flex"
					flexWrap="wrap"
					gap="2"
					margin="0"
					marginTop="2"
				>
					{value.map((v: any, i: number) => {
						const optionDisabled =
							disabled || typeof v === "object" ? v.disabled : false
						return (
							<Box
								as="li"
								key={getMatcherValueForOption(v)}
								title="Click to unselect"
								role="button"
								paddingY="1"
								paddingX="2"
								rounded="md"
								fontSize="sm"
								cursor={disabled ? "disabled" : "pointer"}
								bgColor="primary"
								borderWidth="1"
								borderColor={{ default: "primary", hover: "primary_emphasis" }}
								onClick={() =>
									!optionDisabled &&
									onChange &&
									onChange(
										value.filter(
											(val: any) => !matchOptions(val, v, labelKey)
										) as any,
										name
									)
								}
							>
								<Box display="flex" alignItems="center">
									<Box
										as="input"
										readOnly
										type="checkbox"
										checked
										marginRight="2"
										tabIndex={-1}
										id={`${props.id || name}_value_${i}`}
										data-testid={`${props.id || name}_value_${i}`}
									/>
									<Box>
										<MultiSelectValueRenderer value={v} labelKey={labelKey} />
									</Box>
								</Box>
							</Box>
						)
					})}
				</Box>
			) : null}
		</Box>
	)
}

function DefaultMultiSelectValueRenderer({
	value,
	labelKey,
}: {
	value: TOption
	labelKey: string
}) {
	return <>{getLabelForOption(value, labelKey)}</>
}

interface OptionProps
	extends Omit<React.ComponentProps<typeof Box>, "onClick"> {
	focused?: boolean
	checked?: boolean
	disabled?: boolean
	onClick?: (checked: boolean) => void
}

function Option({
	focused,
	checked,
	onClick,
	disabled,
	...props
}: OptionProps) {
	const ref = useRef<HTMLLIElement>(null)
	useEffect(() => {
		if (ref.current && focused) {
			ref.current.scrollIntoView &&
				ref.current.scrollIntoView({
					behavior: "smooth",
					block: "nearest",
				})
		}
	}, [focused, ref.current])
	return (
		<Box
			as="li"
			ref={ref}
			className={getOptionClassName({
				selected: checked,
				focused: focused,
			})}
			role="option"
			aria-selected={checked}
			data-focused={focused}
			opacity={disabled ? "70" : undefined}
			bgColor={{
				default: disabled ? undefined : focused ? "inset" : undefined,
				hover: disabled ? undefined : "inset",
			}}
			tabIndex={-1}
			display="block"
			paddingY="2"
			paddingX="4"
			cursor={!disabled ? "pointer" : "disabled"}
			onClick={() => !disabled && onClick && onClick(!checked)}
			{...props}
		/>
	)
}

export interface AsyncSelectProps
	extends Omit<SelectProps, "onQuery" | "onQueryMore" | "options" | "query">,
		Partial<
			Pick<SelectOwnProps, "onQuery" | "onQueryMore" | "options" | "query">
		> {
	fetch: (query: string, params: { page: number }) => Promise<any[]>
	perFetchLimit?: number
	debounceBy?: number
	cacheKey?: string
	/** Options to be passed when there is not serach */
	defaultOptions?: Array<any>
}

export function AsyncSelect({
	fetch,
	debounceBy = 300,
	cacheKey,
	defaultOptions,
	perFetchLimit,
	...otherProps
}: AsyncSelectProps) {
	const [state, setState] = useState<{
		query: string
		page: number
		isLoading: boolean
		options: Array<any>
		hasMoreOptions: boolean
	}>(() => ({
		query: "",
		page: 1,
		isLoading: false,
		options: cacheKey ? Cache.get(cacheKey) || [] : defaultOptions || [],
		hasMoreOptions: false,
	}))
	const lastDeboundeHandler = useRef<number>()
	useEffect(() => {
		return () => {
			typeof window !== "undefined" &&
				window.clearTimeout(lastDeboundeHandler.current)
		}
	}, [lastDeboundeHandler])

	const fetchOptionsForPage = useCallback(
		(query: string, page: number) => {
			const cacheKeyWithQuery = cacheKey
				? query.trim() || page > 1
					? `${cacheKey}?q=${query.trim()}&page=${page}`
					: cacheKey
				: undefined
			setState((state) => ({
				...state,
				isLoading: true,
				options:
					cacheKeyWithQuery && page === 1
						? Cache.get(cacheKeyWithQuery)
						: state.options,
				query,
				page,
			}))
			clearTimeout(lastDeboundeHandler.current)
			lastDeboundeHandler.current = window.setTimeout(() => {
				Promise.resolve()
					.then(() => {
						const fetcher = () => fetch(query, { page })
						if (cacheKeyWithQuery) {
							return Cache.resource(
								cacheKeyWithQuery,
								fetcher,
								query.length > 0
							)
						} else {
							return fetcher()
						}
					})
					.then((options) => {
						setState((state) => ({
							...state,
							isLoading: false,
							hasMoreOptions: Boolean(
								perFetchLimit && options.length >= perFetchLimit
							),
							options: state.page > 1 ? state.options.concat(options) : options,
						}))
					})
					.catch((error) => {
						setState((state) => ({
							...state,
							isLoading: false,
						}))
						return Promise.reject(error)
					})
			}, debounceBy)
		},
		[fetch, lastDeboundeHandler, debounceBy, perFetchLimit]
	)

	const { options, query, page, isLoading, hasMoreOptions } = state

	const fetchOptions = useCallback(
		(query: string) => {
			return fetchOptionsForPage(query, 1)
		},
		[fetchOptionsForPage]
	)

	const fetchMoreOptions = useCallback(() => {
		fetchOptionsForPage(query, page + 1)
	}, [fetch, page, query, fetchOptionsForPage])

	return (
		<BaseSelect
			options={options}
			query={query}
			isLoading={isLoading}
			onQuery={fetchOptions}
			onQueryMore={hasMoreOptions ? fetchMoreOptions : undefined}
			filterOptions={undefined}
			{...otherProps}
		/>
	)
}

function withFilterManagement(Select: React.ComponentType<SelectProps>) {
	return function WithQuery(props: SelectProps) {
		const { searchable, labelKey = "name" } = props
		const filterOptions = useCallback(
			(options?: Array<TOption>, query?: string) => {
				if (!options || !query || searchable === false) return options
				const searchQuery: string = query.toLowerCase().trim()
				return options.filter((o) =>
					(
						getLabelForOption(o, labelKey) ||
						getLabelForOption(o, "name") ||
						getLabelForOption(o, "description") ||
						getLabelForOption(o, "title") ||
						""
					)
						.toLowerCase()
						.includes(searchQuery)
				)
			},
			[searchable, labelKey]
		)
		return <Select filterOptions={filterOptions} {...props} />
	}
}

function withQueryManagement(Select: React.ComponentType<SelectProps>) {
	return function WithQuery(props: SelectProps) {
		const [query, setQuery] = useState<string>("")
		if (props.searchable !== false) {
			props = {
				query,
				onQuery: setQuery,
				...props,
			}
		}
		return <Select {...props} />
	}
}

export const Select = withQueryManagement(withFilterManagement(BaseSelect))

/**
 * Match two options and verify if they are equal or not
 */
function matchOptions(
	optionA?: any,
	optionB?: any,
	labelKey: string = "name"
): boolean {
	if (!optionA || !optionB) return false
	return (
		getMatcherValueForOption(optionA, labelKey) ===
		getMatcherValueForOption(optionB, labelKey)
	)
}

/**
 * Get the matcher value for option
 * option.id is given the highest priority and then we will simply get the label
 */
function getMatcherValueForOption(
	option: any,
	labelKey: string = "name"
): string {
	if (!option) return ""
	const optionType = typeof option
	if (optionType === "object" && option.id) return String(option.id)
	return getLabelForOption(option, labelKey)
}

/**
 * Get the label for option
 */
function getLabelForOption(option?: any, labelKey: string = "name"): string {
	if (!option) return ""
	const optionType = typeof option
	switch (optionType) {
		case "string":
			return String(option)
		case "number":
			return String(option)
		case "object":
			return String((option as any)[labelKey])
		default:
			return ""
	}
}
