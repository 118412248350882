import {
	Box,
	Button,
	Inline,
	Icons,
	Stack,
	Heading,
	Col,
	Divider,
	Grid,
	joinAttributes,
	Text,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import { Markdown } from "@sembark-travel/ui/markdown"
import {
	clone,
	formatDate,
	humanizeDuration,
	setUnit,
} from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	TextInputField,
	MarkdownInputField,
	withServerErrors,
	SubmissionError,
	validateFormValues,
	SelectField,
	FormSpy,
	EmptyNumberValidator,
	DatePickerField,
} from "@sembark-travel/ui/form"
import { Optional } from "utility-types"
import * as Validator from "yup"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import { useAuthUser } from "../Auth"
import { useRef } from "react"
import config from "../config"
import { SelectTransportServiceLocationPoints } from "./Points"
import { ITransportService, TTransportServiceLocationPoint } from "./store"

interface NewServiceCredentials {
	from?: TTransportServiceLocationPoint
	to?: TTransportServiceLocationPoint
	service: string
	distance?: number
	start_time?: Date
	duration?: number
	service_title?: string
	service_details?: string
	short_code?: string
	trip_destinations?: Array<TTripDestination>
}

const INITIAL_VALUES: NewServiceCredentials = {
	from: undefined,
	to: undefined,
	service: "",
	distance: 0,
	start_time: undefined,
	duration: undefined,
	service_title: "",
	service_details: "",
	short_code: "",
	trip_destinations: [],
}

export function NewServicesForm({
	initialValues: propInitialValues = {},
	onSuccess,
	onCancel,
}: {
	initialValues?: Optional<NewServiceCredentials>
	onCancel?: () => void
	onSuccess: (transportService: ITransportService) => void
}) {
	const xhr = useXHR()
	const { user } = useAuthUser()
	const can_attach_services_to_destinations = Boolean(
		user?.tenant?.feature_flags?.attach_services_to_destinations
	)
	const validationSchema = validateFormValues(
		Validator.object().shape({
			from: Validator.object().required(
				"Please provide the pickup / start location of service."
			),
			to: Validator.object().nullable(),
			service: Validator.string()
				.required("Please provide the name or type of the service.")
				.max(
					191,
					"Please keep the service name withing 191 character for better readability."
				),
			distance: EmptyNumberValidator()
				.typeError("Distance should be a number")
				.integer("Distance should be an integer")
				.min(0, "Please use a non negative number"),
			duration: EmptyNumberValidator()
				.typeError("Duration should be a number")
				.integer("Duration should be an integer"),
			service_details: Validator.string()
				.nullable()
				.max(10000, "Please use less than 10000 characters"),
			trip_destinations: can_attach_services_to_destinations
				? Validator.array()
						.required("Please select a Destinations")
						.min(1, "Please select atleast one Destinations")
				: Validator.array().nullable(),
		})
	)

	const initialValues = useRef<NewServiceCredentials>({
		...INITIAL_VALUES,
		...propInitialValues,
	})

	return (
		<Form<NewServiceCredentials>
			initialValues={initialValues.current}
			validate={validationSchema}
			onSubmit={withServerErrors(async (values) => {
				const {
					from,
					to,
					distance,
					short_code,
					trip_destinations,
					start_time,
					...otherData
				} = values
				const {
					data: { data: transportService },
				} = await xhr.post<{ data: ITransportService }>("/transport-services", {
					...otherData,
					short_code: short_code ? String(short_code) : undefined,
					distance: distance || 0,
					from: from?.name,
					to: to?.name,
					start_time: start_time
						? formatDate(
								setUnit(clone(start_time), "seconds", 0),
								config.timeFormat
							)
						: null,
					trip_destinations:
						trip_destinations?.length && can_attach_services_to_destinations
							? trip_destinations.map((t) => t.id)
							: [],
				})
				onSuccess(transportService)
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Inline gap="2">
								<Box>
									<Icons.LocationMarker size="6" />
								</Box>
								<Stack>
									<Heading as="h3">Locations</Heading>
									<Text color="muted">
										Add pickup-drop location along with a short code for quick
										identification.
									</Text>
								</Stack>
							</Inline>
						</Col>
						<Col>
							<Stack gap="4">
								<Grid gap="4">
									<Col>
										<SelectField
											select={SelectTransportServiceLocationPoints}
											label="From/Pickup/Start City"
											name="from"
											required
											multiple={false}
											creatable
										/>
									</Col>
									<Col>
										<SelectField
											select={SelectTransportServiceLocationPoints}
											label="To/Drop/End City"
											name="to"
											secondaryLabel="optional"
											multiple={false}
											creatable
										/>
									</Col>
								</Grid>
								<Grid gap="4">
									<Col>
										<TextInputField
											type="text"
											label="Short Code"
											name="short_code"
											secondaryLabel="optional"
											placeholder="125"
											maxWidth="xs"
										/>
									</Col>
									{can_attach_services_to_destinations ? (
										<Col>
											<SelectField
												select={SelectTripDestination}
												name="trip_destinations"
												label="Trip Destinations"
												multiple
												closeOnSelect
											/>
										</Col>
									) : null}
								</Grid>
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Inline gap="2">
								<Box>
									<Icons.Tags size="6" />
								</Box>
								<Stack gap="1">
									<Heading as="h3">Service Details</Heading>
									<Text color="muted">
										Please provide the service name with inclusions, distance
										and duration.
									</Text>
								</Stack>
							</Inline>
						</Col>
						<Col>
							<Stack gap="4">
								<TextInputField
									type="text"
									label="Service Name"
									name="service"
									placeholder="Onway sightseeing"
								/>
								<Grid gap="4">
									<Col>
										<TextInputField
											name="distance"
											type="number"
											label="Distance (kms)"
											secondaryLabel="optional"
											placeholder="420"
											style={{ maxWidth: "200px" }}
										/>
									</Col>
									<Col>
										<DatePickerField
											label="Start Time"
											name="start_time"
											dateFormat={false}
											timeFormat={config.timeDisplayFormat}
											secondaryLabel="optional"
											help="Default start time for the service"
										/>
									</Col>
									<Col>
										<TextInputField
											name="duration"
											type="number"
											label="Duration (in mins)"
											secondaryLabel="optional"
											placeholder="60"
											style={{ maxWidth: "200px" }}
										/>
									</Col>
								</Grid>
							</Stack>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Inline gap="2">
								<Box>
									<Icons.Cog size="6" />
								</Box>
								<Stack gap="1">
									<Heading as="h3">Itinerary</Heading>
									<Text color="muted">
										You can (optionally) provide details for this service. This
										will allow autofilling day-wise schedule in an itinerary
										when this service is provided in a quote.
									</Text>
								</Stack>
							</Inline>
						</Col>
						<Col>
							<MarkdownInputField
								label="Description"
								name="service_details"
								placeholder="Upon your arrival at Railway Station, your driver will escort you to your hotel..."
							/>
						</Col>
					</Grid>
					<Divider />
					<Box
						padding="4"
						boxShadow="lg"
						rounded="lg"
						borderWidth="1"
						bgColor="subtle"
						marginY="4"
					>
						<Box
							textTransform="uppercase"
							fontSize="sm"
							color="muted"
							marginBottom="4"
							fontWeight="semibold"
							letterSpacing="wider"
						>
							Summary
						</Box>
						<FormSpy<NewServiceCredentials>>
							{({ values }) => {
								const {
									short_code,
									from,
									to,
									service,
									distance,
									start_time,
									duration,
								} = values
								return (
									<Stack gap="1">
										<Box>
											{!from ? (
												<Heading color="muted">[Title]</Heading>
											) : (
												<Box>
													{short_code ? (
														<Box as="code" marginRight="1" display="block">
															Code {short_code}
														</Box>
													) : null}
													<Heading>
														{from.name}
														{to ? (
															<Box as="span" marginLeft="1">
																to {to.name}
															</Box>
														) : null}
														{service ? (
															<Box as="span" marginLeft="1">
																- {service}
															</Box>
														) : null}
													</Heading>
												</Box>
											)}
										</Box>
										<Text>
											{joinAttributes(
												distance ? `${distance} kms` : null,
												start_time
													? formatDate(start_time, "HH:mm") + " hrs"
													: null,
												duration
													? `${humanizeDuration(duration * 60 * 1000, {
															short: false,
														})}`
													: null
											)}
										</Text>
										<Box color="muted">
											{values.service_details ? (
												<Markdown>{values.service_details}</Markdown>
											) : (
												"[Itinerary]"
											)}
										</Box>
									</Stack>
								)
							}}
						</FormSpy>
					</Box>
					<Divider sm />
					<Grid>
						<Col sm={12} md={{ offset: 4, span: 8 }}>
							<Stack gap="4">
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting} size="lg">
										{submitting ? "Saving..." : "Save Service"}
									</Button>
									{onCancel ? (
										<Button
											onClick={onCancel}
											level="tertiary"
											size="lg"
											disabled={submitting}
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}

export function AddTransportServiceDialog({
	open,
	onClose,
	onSuccess,
	...props
}: Optional<
	Omit<React.ComponentProps<typeof NewServicesForm>, "onCancel">,
	"onSuccess"
> & {
	open: boolean
	onClose: () => void
}) {
	return (
		<Dialog open={open} onClose={onClose} lg>
			<Dialog.Header closeButton>
				<Dialog.Title>Add new Transport Service</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<NewServicesForm
					onSuccess={(...args) => {
						onClose()
						onSuccess?.(...args)
					}}
					onCancel={onClose}
					{...props}
				/>
			</Dialog.Body>
		</Dialog>
	)
}
