import { Navigate } from "../../router-utils"
import { PERMISSIONS, useAuthUser, useCheckPermissions } from "../../Auth"
import { Container, Heading } from "@sembark-travel/ui/base"
import { Helmet } from "react-helmet-async"
import config from "../../config"

export default function HomePage() {
	const { hasPermission, hasAnyPermission } = useCheckPermissions()
	const { user } = useAuthUser()
	if (
		hasAnyPermission(PERMISSIONS.MANAGE_TENANTS, PERMISSIONS.VIEW_TENANTS) ||
		!user
	) {
		return <Navigate to={"/admin/tenants"} replace />
	}
	if (hasPermission(PERMISSIONS.MANAGE_USERS)) {
		return <Navigate to={"/dashboard"} replace />
	}
	if (hasPermission(PERMISSIONS.VIEW_QUERIES)) {
		return <Navigate to={"/trips"} replace />
	}
	if (hasPermission(PERMISSIONS.VIEW_HOTEL_BOOKINGS)) {
		return <Navigate to={"/hotel-bookings"} replace />
	}
	if (hasPermission(PERMISSIONS.VIEW_CAB_SCHEDULES)) {
		return <Navigate to={"/operational-bookings/calendar"} replace />
	}
	if (hasPermission(PERMISSIONS.VIEW_INCOMING_PAYMENTS)) {
		return <Navigate to={"/payments/incoming"} replace />
	}
	if (hasPermission(PERMISSIONS.VIEW_OUTGOING_PAYMENTS)) {
		return <Navigate to={"/payments/outgoing"} replace />
	}
	const { name } = user
	return (
		<Container paddingY="6">
			<Helmet>
				<title>Dashboard</title>
			</Helmet>
			<Heading as="h1">
				Welcome to {config.appTitle}, {name}
			</Heading>
		</Container>
	)
}
