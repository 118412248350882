import {
	Button,
	Inline,
	Stack,
	Divider,
	Grid,
	Col,
	Heading,
	Text,
} from "@sembark-travel/ui/base"
import {
	Form,
	PhoneInputField,
	PhoneNumberValidator,
	SelectField,
	SubmissionError,
	TextInputField,
	getEditablePhoneNumber,
	isTruthy,
	validateFormValues,
	withServerErrors,
} from "@sembark-travel/ui/form"
import { useState } from "react"
import * as Validator from "yup"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import { useXHR } from "@sembark-travel/xhr"
import { TTenantBrand } from "./store"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { SelectBrandColorTheme, TBrandColorTheme } from "./BrandColorThemes"

export function EditTenantBrand({
	tenantBrand,
	onSuccess,
	...props
}: Omit<React.ComponentProps<typeof EditTenantBrandForm>, "onSubmit"> & {
	tenantBrand: TTenantBrand
	onSuccess: (brand: TTenantBrand) => unknown
}) {
	const xhr = useXHR()
	const {
		name,
		short_name,
		phone_numbers,
		color_theme,
		helpline_remarks,
		trip_destinations,
	} = tenantBrand
	const [initialValues] = useState(() => ({
		name,
		short_name: short_name || name,
		color_theme: color_theme
			? {
					id: color_theme,
					name: color_theme,
				}
			: undefined,
		helpline_remarks,
		phone_numbers: phone_numbers
			? phone_numbers.map((p) =>
					getEditablePhoneNumber(p.phone_number, p.country_code)
				)
			: [
					{
						country_code: "IN",
						phone_number: "+91",
						number: "",
					},
				],
		trip_destinations,
	}))
	return (
		<EditTenantBrandForm
			initialValues={initialValues}
			skipDestinations={tenantBrand.is_primary}
			{...props}
			onSubmit={async (payload) => {
				const { data } = await xhr.patch<{ data: TTenantBrand }>(
					`/tenant-brands/${tenantBrand.id}`,
					payload
				)
				showSnackbar("Brand details updated successfully.")
				await Promise.resolve(onSuccess(data.data))
			}}
		/>
	)
}

type TEditTenantBrandFormData = {
	name: string
	short_name: string
	profile_image?: FileList
	color_theme?: TBrandColorTheme
	helpline_remarks?: string
	trip_destinations?: Array<TTripDestination>
	phone_numbers: Array<{
		country_code: string
		phone_number: string
		number: string
		is_primary?: boolean
	}>
}

function EditTenantBrandForm({
	skipDestinations,
	onSubmit,
	onCancel,
	initialValues: propInitialValues,
}: {
	skipDestinations?: boolean
	onSubmit: (data: unknown) => Promise<unknown>
	onCancel: () => unknown
	initialValues?: Partial<TEditTenantBrandFormData>
}) {
	const [initialValues] = useState<TEditTenantBrandFormData>(() => ({
		name: "",
		short_name: "",
		profile_image: undefined,
		color_theme: undefined,
		trip_destinations: [],
		...propInitialValues,
		phone_numbers: propInitialValues?.phone_numbers || [
			{
				country_code: "IN",
				phone_number: "+91",
				number: "",
			},
		],
	}))
	return (
		<Form<TEditTenantBrandFormData>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object().shape({
					name: Validator.string()
						.required("Please provide the registered name of the Brand")
						.max(100, "Please use 100 or less characters"),
					short_name: Validator.string()
						.required("Please provide the brand display name")
						.max(100, "Please use 100 or less characters"),
					phone_numbers: Validator.array()
						.of(PhoneNumberValidator("Phone Number", true))
						.nullable(),
					trip_destinations: !skipDestinations
						? Validator.array()
								.required("Please select atleast one destination")
								.min(1, "Please select atleast one destination for this brand")
						: Validator.array().nullable(true),
					helpline_remarks: Validator.string()
						.required("Please provide remarks/timing for helpline in vouchers")
						.max(100, "Please use 100 or less characters"),
				})
			)}
			onSubmit={withServerErrors(async (values) => {
				const phone_numbers = values.phone_numbers
					?.filter((p) => String(p.number).trim())
					.map((p) => ({
						...p,
						is_primary: isTruthy(p.is_primary) ? 1 : 0,
					}))

				await onSubmit({
					...values,
					color_theme: values.color_theme?.id || null,
					trip_destinations: values.trip_destinations?.map((t) => t.id),
					phone_numbers,
				})
			})}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="6">
						<Col sm={12} md={4}>
							<Stack>
								<Heading fontSize="md">Branding Details</Heading>
								<Text color="muted">
									Please provide registered namd and brand name details
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<TextInputField
									name="name"
									label="Official/Registered Name"
									type="text"
									placeholder="e.g. ABC Pvt Ltd"
								/>
								<TextInputField
									name="short_name"
									label="Short/Display/Brand Name"
									type="text"
									placeholder="e.g. ABC Travel"
								/>
								<SelectField
									select={SelectBrandColorTheme}
									label="Branding Color Theme"
									name="color_theme"
									fetchOnMount
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Grid gap="6">
						<Col sm={12} md={4}>
							<Stack>
								<Heading fontSize="md">
									{skipDestinations
										? "Support Contact"
										: "Destinations and Support"}
								</Heading>
								<Text color="muted">
									Associated{!skipDestinations ? " Trip Destinations and" : ""}{" "}
									general support contact details.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								{!skipDestinations ? (
									<SelectField
										name="trip_destinations"
										label="Trip Destinations"
										select={SelectTripDestination}
										multiple
									/>
								) : null}
								<PhoneInputField
									name="phone_numbers"
									multi
									label="Support Contact Number(s)"
								/>
								<TextInputField
									name="helpline_remarks"
									label="Helpline Remarks"
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Grid gap="0">
						<Col sm={12} md={{ offset: 4, span: 8 }}>
							<Stack gap="4" paddingLeft={{ md: "6" }}>
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Updating..." : "Update Details"}
									</Button>
									<Button onClick={() => onCancel()} level="tertiary">
										Cancel
									</Button>
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}
