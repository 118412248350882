import {
	ActionType,
	createAction,
	createAsyncAction,
	getType,
} from "typesafe-actions"
import { IRole } from "../Roles"
import { ITenant } from "./../Admin/Tenants"
import { INotification } from "./../Notifications"
import { setAnanlyticsIdentity } from "@sembark-travel/tracking"
import { ThunkAction } from "./../types"
import { broadcastMessageToStorage } from "../storage"
import { type XHRInstance, refreshCSRFToken } from "@sembark-travel/xhr"
import { useThunkDispatch } from "./../utils"
import { useCallback, useMemo } from "react"
import { trackLogin, trackLogout } from "@sembark-travel/tracking"
import { useSelector } from "react-redux"
import type { TFeatureFlags } from "../Admin/Tenants"
import { IPhoneNumber } from "../Contacts"

export const key = "AUTHENTICATED_USER_STATE"

export function AuthXHR(xhr: XHRInstance) {
	return {
		async getUser(): Promise<IUser> {
			return xhr.get("/me").then((resp: { data: { data: IUser } }) => {
				return resp.data.data
			})
		},
	}
}

export enum AuthUserStatus {
	DEFAULT = "DEFAULT",
	CHECKING = "CHECKING",
	UN_AUTHENTICATED = "UN_AUTHENTICATED",
	AUTHENTICATING = "AUTHENTICATING",
	AUTHENTICATED = "AUTHENTICATED",
}

export interface IUser {
	id: number
	uid: string
	name: string
	email: string
	tenant?: ITenant
	tenant_id?: number | null
	roles?: Array<IRole>
	permissions: Array<string>
	notifications?: Array<INotification>
	email_verified_at: string | null
	is_open_signup: boolean
	profile_image_url?: string | null
	profile_thumb_image_url?: string | null
	password_login_disabled_at?: string | null
	manages_users?: Array<IUser>
	phone_numbers?: Array<IPhoneNumber<Omit<IUser, "phone_numbers">>>
	can_disable_2fa?: boolean
	country?: {
		id: number
		name: string
		short_name: string
	}
}

export interface IState {
	readonly data?: IUser
	readonly status: AuthUserStatus
	readonly sessionExpired?: boolean
}

export interface IStateWithKey {
	readonly [key]: IState
}

export interface IAuthToken {
	access_token: string
	expires_in: number
}

/**
 * ================ State ======================== *
 */
// Initial state
const INITIAL_STATE: IState = {
	data: undefined,
	status: AuthUserStatus.DEFAULT,
	sessionExpired: false,
}
// Redux actions
export const actions = {
	checkAuth: createAsyncAction(
		"@AUTH/CHECK_AUTH_REQUEST",
		"@AUTH/CHECK_AUTH_SUCCESS",
		"@AUTH/CHECK_AUTH_FAILED"
	)<undefined, IUser, Error>(),
	login: createAsyncAction(
		"@AUTH/LOGIN_REQUEST",
		"@AUTH/LOGIN_SUCCESS",
		"@AUTH/LOGIN_FAILED"
	)<undefined, IUser, Error>(),
	logout: createAsyncAction(
		"@AUTH/LOGOUT_REQUEST",
		"@AUTH/LOGOUT_SUCCESS",
		"@AUTH/LOGOUT_FAILED"
	)<undefined, undefined, Error>(),
	sessionExpired: createAction("@AUTH/SESSION_EXPIRED")<undefined>(),
	reauthenticate: createAsyncAction(
		"@AUTH/REAUTHENTICATE_REQUEST",
		"@AUTH/REAUTHENTICATE_SUCCESS",
		"@AUTH/REAUTHENTICATE_FAILED"
	)<undefined, IUser, Error>(),
}

export type TActions = ActionType<typeof actions>

// state reducer
export function reducer(
	state: IState = INITIAL_STATE,
	action: TActions
): IState {
	switch (action.type) {
		case getType(actions.checkAuth.request):
			return { ...state, status: AuthUserStatus.CHECKING }
		case getType(actions.login.request):
			return { ...state, status: AuthUserStatus.AUTHENTICATING }
		case getType(actions.checkAuth.success):
		case getType(actions.login.success):
		case getType(actions.reauthenticate.success):
			return {
				...state,
				status: AuthUserStatus.AUTHENTICATED,
				sessionExpired: false,
				data: action.payload,
			}
		case getType(actions.checkAuth.failure):
		case getType(actions.login.failure):
			return { ...state, status: AuthUserStatus.UN_AUTHENTICATED }
		case getType(actions.logout.success):
			return {
				...state,
				status: AuthUserStatus.UN_AUTHENTICATED,
				data: undefined,
			}
		case getType(actions.sessionExpired):
			return {
				...state,
				sessionExpired: true,
			}
		default:
			return state
	}
}

export const getUserAction =
	(noCheckRequest?: boolean): ThunkAction<Promise<IUser>> =>
	async (dispatch, _, { xhr }) => {
		if (!noCheckRequest) {
			dispatch(actions.checkAuth.request())
		}
		return AuthXHR(xhr)
			.getUser()
			.then((user) => {
				dispatch(actions.checkAuth.success(user))
				setAnanlyticsIdentity(user)
				broadcastMessageToStorage("LOGGED_IN")
				return user
			})
			.catch((error) => {
				if (!noCheckRequest) {
					dispatch(actions.checkAuth.failure(error))
				}
				return Promise.reject(error)
			})
	}

// actions
function LoginXHR(xhr: XHRInstance) {
	return {
		async login(data: unknown) {
			// always refresh the token before login
			await refreshCSRFToken()
			return xhr
				.post<{
					next?: "two_factor"
					message?: string
				}>("/login", data)
				.then((resp) => resp.data)
		},
		async webAuthLogin(data: unknown) {
			// always refresh the token before login
			await refreshCSRFToken()
			return xhr
				.post<{
					next?: "two_factor"
					message?: string
				}>("/login-webauthn", data)
				.then(({ data }) => data)
		},
		async verifyTwoFactorCode(code: string): Promise<unknown> {
			return xhr.post("/verify-two-factor", { code }).then(({ data }) => data)
		},
		async refresh(): Promise<IAuthToken> {
			return xhr
				.patch("/refresh")
				.then(({ data }: { data: IAuthToken }) => data)
		},
	}
}

// schemas
export interface ILoginCredentials {
	email: string
	password: string
}

async function handleTwoFactor(handler: ReturnType<typeof LoginXHR>) {
	let code
	do {
		code = window.prompt(
			"Please enter the verification code from your Authenticator App"
		)
		if (!code) throw new Error("Please provide the code to proceed")
		try {
			await handler.verifyTwoFactorCode(code)
			break
		} catch (e) {
			const error: Error = e as Error
			window.alert(error.message || "Something went wrong. Please try again.")
			continue
		}
	} while (code)
}

const loginAction =
	(
		method: "password" | "webauthn",
		data: unknown
	): ThunkAction<Promise<IUser>> =>
	async (dispatch, _, { xhr }) => {
		actions.login.request()
		const handler = LoginXHR(xhr)
		return (
			method === "password" ? handler.login(data) : handler.webAuthLogin(data)
		)
			.then(async (resp) => {
				if (resp.next === "two_factor") {
					await handleTwoFactor(handler)
				}
			})
			.then(() => {
				trackLogin()
				return dispatch(getUserAction())
			})
			.catch((error) => {
				actions.login.failure(error)
				return Promise.reject(error)
			})
	}

const reauthenticateAction =
	(
		method: "password" | "webauthn",
		data: unknown
	): ThunkAction<Promise<IUser>> =>
	async (dispatch, _, { xhr }) => {
		actions.reauthenticate.request()
		const handler = LoginXHR(xhr)
		return (
			method === "password" ? handler.login(data) : handler.webAuthLogin(data)
		)
			.then(async (resp) => {
				if (resp.next === "two_factor") {
					await handleTwoFactor(handler)
				}
			})
			.then(() => {
				trackLogin("re_authenticate")
				return dispatch(getUserAction(true))
			})
			.catch((error) => {
				actions.reauthenticate.failure(error)
				return Promise.reject(error)
			})
	}

export function useLogin() {
	const dispatch = useThunkDispatch()
	return useCallback(
		(method: "password" | "webauthn", data: unknown) =>
			dispatch(loginAction(method, data)),
		[dispatch]
	)
}

export function useReauthenticate() {
	const dispatch = useThunkDispatch()
	return useCallback(
		(method: "password" | "webauthn", data: unknown) =>
			dispatch(reauthenticateAction(method, data)),
		[dispatch]
	)
}

export const logoutAction =
	(): ThunkAction<Promise<void>> =>
	async (dispatch, _, { xhr }) =>
		xhr.delete("/logout").then(() => {
			localStorage.removeItem("access_token")
			dispatch(actions.logout.success())
			trackLogout()
		})

export function useLogout() {
	const dispatch = useThunkDispatch()
	return useCallback(() => dispatch(logoutAction()), [dispatch])
}

interface StateProps {
	user?: IUser
	wait: boolean
	isAuthenticating: boolean
	isAuthenticated: boolean
	noRequestYet: boolean
	needsToReauthenticate: boolean
}
export function useAuthUserState(): StateProps {
	const user = useSelector((state: IStateWithKey) => state[key].data)
	const status = useSelector((state: IStateWithKey) => state[key].status)
	const sessionExpired = useSelector((state: IStateWithKey) =>
		Boolean(state[key].sessionExpired)
	)
	const noRequestYet = status === AuthUserStatus.DEFAULT
	const isAuthenticating =
		status === AuthUserStatus.AUTHENTICATING ||
		status === AuthUserStatus.CHECKING
	const isAuthenticated = status === AuthUserStatus.AUTHENTICATED
	const needsToReauthenticate = Boolean(isAuthenticated && sessionExpired)
	const wait = isAuthenticating || noRequestYet
	return {
		user,
		wait,
		noRequestYet,
		isAuthenticating,
		isAuthenticated,
		needsToReauthenticate,
	}
}

export function useAuthUserFetch() {
	const dispatch = useThunkDispatch()
	return useCallback(
		async (noCheckRequest = false) => dispatch(getUserAction(noCheckRequest)),
		[dispatch]
	)
}

export function useAuthUser() {
	return {
		...useAuthUserState(),
		fetchUser: useAuthUserFetch(),
	}
}

export function useHasFeatureFlag(flag: keyof TFeatureFlags) {
	const { user } = useAuthUser()
	return Boolean(user?.tenant?.feature_flags?.[flag])
}

export function useAuthManagedUsers() {
	const { user } = useAuthUser()
	const manages_users = user?.manages_users
	return useMemo(() => {
		return manages_users || []
	}, [manages_users])
}

export function useAuthCountryCode() {
	const { user } = useAuthUser()
	return user?.country?.short_name || "IN"
}
