import {
	Inline,
	Text,
	Stack,
	Table,
	Money,
	Box,
	Time,
	Dropdown,
	Icons,
} from "@sembark-travel/ui/base"
import {
	formatDate,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import {
	ButtonLink,
	useLocationQuery,
	NavLink,
} from "@sembark-travel/ui/router"
import { CursorListView, Search, useSearch } from "@sembark-travel/ui/list"
import { ICursorListResponse, XHRInstance } from "@sembark-travel/xhr"
import { Fragment, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { PERMISSIONS, useCheckPermissions } from "../Auth"
import { TTravelActivityPrice } from "./store"
import { generatePath } from "../router-utils"

function XHR(xhr: XHRInstance) {
	return {
		async get(
			params?: unknown
		): Promise<ICursorListResponse<TTravelActivityPrice>> {
			return xhr
				.get("/travel-activity-prices", { params })
				.then((resp) => resp.data)
		},
	}
}

export function TravelActivityPricesList() {
	const { hasPermission } = useCheckPermissions()
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [setQuery, params])
	return (
		<Fragment>
			<Helmet>
				<title>Travel Activity Prices</title>
			</Helmet>
			<Search
				initialParams={params}
				onSearch={(params) => {
					setParams({ ...params, cursor: null })
				}}
				title="Travel Activity Prices"
				actions={
					hasPermission(PERMISSIONS.UPLOAD_BULK_TRANSPORT_SERVICES) ? (
						<Inline gap="4">
							<ButtonLink
								to={generatePath("/travel-activity-prices/upload-prices")}
								level="primary"
							>
								Upload Prices
							</ButtonLink>
							<Dropdown alignRight="sm">
								<Dropdown.ToggleButton level="tertiary">
									<Icons.DotsVertical />
								</Dropdown.ToggleButton>
								<Dropdown.Menu>
									<NavLink
										to={generatePath("/travel-activity-prices/calculate-price")}
									>
										Calculate Price
									</NavLink>
								</Dropdown.Menu>
							</Dropdown>
						</Inline>
					) : null
				}
			/>
			<CursorListView<TTravelActivityPrice>
				pageKey="travel-activity-prices-list"
				fetch={(xhr, params) => XHR(xhr).get(params)}
				params={params}
				onCursorChange={(cursor) => {
					setParams({ ...params, cursor })
				}}
			>
				{({ items: transportServicePrices }) => (
					<Table
						bordered
						responsive
						hover
						headers={[
							"Start Date",
							"End Date",
							"Name",
							"Service",
							"Config",
							"Price",
							"Added On",
						]}
						alignCols={{
							5: "right",
						}}
						rows={transportServicePrices.map(
							({
								start_date,
								start_date_local,
								end_date,
								end_date_local,
								activity,
								ticket_type,
								ticket_tourist_configuration,
								duration_string,
								currency,
								price,
								created_at,
								created_by,
								is_booking_price,
							}) => [
								formatDate(
									localOrUtcTimestampToLocalDate(start_date_local, start_date),
									"DD MMM, YYYY"
								),
								formatDate(
									localOrUtcTimestampToLocalDate(end_date_local, end_date),
									"DD MMM, YYYY"
								),
								<Stack>
									<Text maxWidth="xs">{activity.name}</Text>
								</Stack>,
								ticket_type ? (
									<Stack>
										<Text maxWidth="xs">{ticket_type.name}</Text>
										{duration_string ? (
											<Text fontSize="sm">{duration_string}</Text>
										) : null}
									</Stack>
								) : null,
								ticket_tourist_configuration.name,
								<Stack>
									<Money currency={currency} amount={price} />
									<Text fontSize="xs" color="muted">
										{is_booking_price ? <>(Ops) </> : null}
										{currency}
									</Text>
								</Stack>,
								<Stack>
									<Box>
										<Time timestamp={created_at} />
									</Box>
									{created_by ? (
										<Text fontSize="sm" color="muted">
											by {created_by.name}
										</Text>
									) : null}
								</Stack>,
							]
						)}
					/>
				)}
			</CursorListView>
		</Fragment>
	)
}
