import {
	Box,
	Table,
	Text,
	Heading,
	Stack,
	Money,
	Time,
} from "@sembark-travel/ui/base"
import { joinAttributes, Spinner } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { IUser } from "../Users"
import { IQuoteFlight, ITrip } from "./store"

interface IFlightBooking extends IQuoteFlight {
	guest_name: string
	pnr?: string
	booked_price: number
	booked_at?: string
	booked_by?: IUser
	dropped_at?: string
	dropped_by?: IUser
	drop_reason?: string
}

interface ITripFlightBooking
	extends Pick<
		ITrip,
		| "id"
		| "children"
		| "destinations"
		| "start_date"
		| "start_date_local"
		| "end_date"
		| "end_date_local"
		| "days"
		| "nights"
		| "trip_source"
		| "no_of_adults"
		| "tourist"
		| "reference_id"
		| "operations_team"
	> {
	converted_at: string
	converted_by: IUser
	package_price: number
	flights: Array<IFlightBooking>
	is_shared?: boolean
	can_modify?: boolean
}

type IFlightBookingsProps = { tripId: string | number }

export default function FlightBookings({ tripId }: IFlightBookingsProps) {
	const xhr = useXHR()
	const { data: trip } = useSWR<ITripFlightBooking>(
		tripId ? `/trips/${tripId}/flight-bookings` : null,
		() =>
			xhr.get(`/trip-flight-bookings/${tripId}`).then((resp) => resp.data.data)
	)
	if (!tripId) return null
	if (!trip) return <Spinner alignCenter padding="4" />
	const { flights, is_shared } = trip
	return (
		<Stack gap="4">
			<Heading>Flight Bookings</Heading>
			{!flights.length ? (
				<Text>Flights not provided.</Text>
			) : (
				<Table
					bordered
					responsive
					headers={["From", "Destination", "Flight"].concat(
						is_shared ? [] : ["Booking Price"]
					)}
					alignCols={{ 3: "right" }}
					rows={flights.map(
						({
							source,
							destination,
							departs_at,
							departs_at_local,
							arrives_at,
							arrives_at_local,
							airline,
							category_class,
							number_plate,
							no_of_adults,
							children,
							infants,
							currency,
							booked_price,
							guest_name,
							comments,
						}) =>
							[
								<Box whiteSpace="preserve">
									<Box fontWeight="semibold">{source.name}</Box>
									<Time
										timestamp={departs_at}
										localTimestamp={departs_at_local}
										format="DD MMM, YYYY [at] HH:mm [hrs]"
									/>
								</Box>,
								<Box whiteSpace="preserve">
									<Box fontWeight="semibold">{destination.name}</Box>
									<Time
										timestamp={arrives_at}
										localTimestamp={arrives_at_local}
										format="DD MMM, YYYY [at] HH:mm [hrs]"
									/>
								</Box>,
								<Box>
									<Box fontWeight="semibold">
										{airline.name}-{category_class}-{number_plate}
									</Box>
									{joinAttributes(
										guest_name,
										`${no_of_adults}A`,
										[infants, `${infants}Inf`],
										[children, `${children}C`]
									)}
								</Box>,
							].concat(
								is_shared
									? []
									: [
											<Box>
												<Box>
													<Money
														amount={booked_price}
														currency={currency}
														showCurrency
													/>
												</Box>
												{comments ? <blockquote>{comments}</blockquote> : null}
											</Box>,
										]
							)
					)}
				/>
			)}
		</Stack>
	)
}
