import {
	Badge,
	Box,
	Button,
	Stack,
	Heading,
	Inline,
	Icons,
	CheckboxInput,
	Text,
	Col,
	Divider,
	Grid,
	Table,
	Money,
	TableFooterDataCell,
	joinAttributes,
} from "@sembark-travel/ui/base"
import { useDialog, Dialog } from "@sembark-travel/ui/dialog"
import {
	formatDate,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	EmptyNumberValidator,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	arrayMutators,
	CheckboxInputField,
	SelectField,
	GetFieldValue,
	TextAreaInputField,
	TextInputField,
	SwitchInputField,
} from "@sembark-travel/ui/form"
import React, { useMemo, useState } from "react"
import { Optional } from "utility-types"
import * as Validator from "yup"
import {
	SelectTravelActivitySuppliers,
	ITransportServiceProvider,
	TTransportServiceProviderContact,
	SelectSupplierContact,
} from "../TransportServiceProviders"
import { TTravelActiviytBooking } from "./store"
import { InlineSelectTenantCurrencyInputField } from "./../Currencies"
import { collect } from "../utils"

const assignTravelActivityServiceProvidersToSchedulesValidationSchema =
	Validator.object().shape({
		booked: Validator.boolean().nullable(),
		supplier: Validator.mixed().nullable(),
		bookings: Validator.array().of(
			Validator.object().shape({
				booked_price: EmptyNumberValidator()
					.nullable()
					.min(0, "Booking price should be a non-negative value."),
			})
		),
	})

type TAssignTravelActivityBookingProvidersSchema = {
	supplier?: ITransportServiceProvider
	supplier_contact?: TTransportServiceProviderContact
	bookings: Array<{
		booking: TTravelActiviytBooking
		ticket_tourist_configurations: TTravelActiviytBooking["ticket_tourist_configurations"]
		confirmation_details?: string
		remarks?: string
		currency: string
	}>
	booked: boolean
}

type AssignTravelActivityBookingProvidersProps = {
	bookings: Array<TTravelActiviytBooking>
	onSuccess: (data: unknown) => void
	onCancel: () => void
}

function AssignTravelActivityBookingProviders({
	bookings,
	onSuccess,
	onCancel,
}: AssignTravelActivityBookingProvidersProps) {
	const xhr = useXHR()
	if (!bookings.length) {
		return (
			<Box textAlign="center" padding="4">
				No Travel Activity Bookings for this trip
			</Box>
		)
	}
	return (
		<AssignTravelActivityBookingProvidersToSchedulesForm
			bookings={bookings}
			onCancel={onCancel}
			onSubmit={async (d) => {
				const resp = await xhr.post(
					"/travel-activity-bookings/assign-suppliers",
					{
						supplier: d.supplier?.id,
						supplier_contact: d.supplier ? d.supplier_contact?.id : undefined,
						booked: d.booked ? 1 : 0,
						bookings: d.bookings.map(
							({
								booking,
								currency,
								confirmation_details,
								ticket_tourist_configurations,
							}) => ({
								id: booking.id,
								confirmation_details,
								currency,
								ticket_tourist_configurations:
									ticket_tourist_configurations.map((c) => ({
										id: c.id,
										currency,
										per_quantity_booked_price: c.per_quantity_booked_price,
									})),
							})
						),
					}
				)
				onSuccess(resp.data.data)
			}}
		/>
	)
}

export function AssignTravelActivityBookingProvidersInDialog({
	children,
	onSuccess,
	...props
}: { children: (props: { assign: () => void }) => React.ReactNode } & Optional<
	Omit<AssignTravelActivityBookingProvidersProps, "onCancel">,
	"onSuccess"
>) {
	const [isOpen, open, close] = useDialog()
	return (
		<>
			{children({ assign: () => open() })}
			<Dialog
				open={isOpen}
				onClose={close}
				title="Assign Service Provider to Bookings"
			>
				<Dialog.Body>
					<AssignTravelActivityBookingProviders
						onSuccess={(...args) => {
							onSuccess?.(...args)
							close()
						}}
						onCancel={close}
						{...props}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function AssignTravelActivityBookingProvidersToSchedulesForm({
	bookings: bookingsProp,
	onCancel,
	onSubmit,
}: {
	bookings: Array<TTravelActiviytBooking>
	onSubmit: (
		values: TAssignTravelActivityBookingProvidersSchema
	) => Promise<void>
	onCancel: () => void
}) {
	const [
		{ step, bookings, selectedBookings, lastAssignedBookings },
		changeStep,
	] = useState(() => ({
		step: 0,
		lastAssignedBookings: {
			supplier: undefined,
			booked: false,
			bookings: [],
		} as TAssignTravelActivityBookingProvidersSchema,
		bookings: bookingsProp,
		selectedBookings: [] as typeof bookingsProp,
	}))

	function moveStepToSelectBookings(
		values: TAssignTravelActivityBookingProvidersSchema
	) {
		changeStep(({ step, ...other }) => ({
			...other,
			step: 0,
			lastAssignedBookings: values,
		}))
	}

	function moveStepToAssignSupplier(
		selectedBookings: Array<TTravelActiviytBooking>
	) {
		changeStep(({ step, lastAssignedBookings, ...other }) => {
			let selectedSupplier = lastAssignedBookings.supplier
			let selectedSupplierContact = lastAssignedBookings.supplier_contact
			if (!selectedSupplier) {
				// get the first service provider from the list
				const selected_booking_with_supplier = selectedBookings.find(
					(s) => s.supplier
				)
				if (
					selected_booking_with_supplier &&
					selected_booking_with_supplier.supplier
				) {
					selectedSupplier = selected_booking_with_supplier.supplier
					selectedSupplierContact =
						selected_booking_with_supplier.supplier_contact
				}
			}
			const allBooked = selectedBookings.every((s) => Boolean(s.is_booked))
			const lastAssignedBookingsById = collect(
				lastAssignedBookings.bookings
			).keyBy((b) => b.booking.id)
			return {
				...other,
				step: 1,
				selectedBookings,
				lastAssignedBookings: {
					supplier: selectedSupplier,
					supplier_contact: selectedSupplierContact,
					booked: allBooked,
					bookings: selectedBookings.map((s) => {
						const existingValues = lastAssignedBookingsById[s.id]
						if (existingValues) return existingValues
						return {
							booking: s,
							ticket_tourist_configurations: [
								...s.ticket_tourist_configurations,
							],
							currency: s.ticket_tourist_configurations[0].currency,
							confirmation_details: s.confirmation_details,
							selected: true,
						}
					}),
				},
			}
		})
	}
	return (
		<>
			{step === 0 ? (
				<SelectTravelActivityBookingsForm
					bookings={bookings}
					initialSelectedBookings={selectedBookings}
					onCancel={() => onCancel()}
					onSubmit={async (bookings) => {
						moveStepToAssignSupplier(bookings)
					}}
				/>
			) : step === 1 ? (
				<Form<TAssignTravelActivityBookingProvidersSchema>
					initialValues={lastAssignedBookings}
					validate={validateFormValues(
						assignTravelActivityServiceProvidersToSchedulesValidationSchema
					)}
					onSubmit={withServerErrors(onSubmit)}
					subscription={{ submitting: true }}
					mutators={{ ...arrayMutators }}
				>
					{({ submitting, form, handleSubmit }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Stack gap="4">
								<Stack gap="1">
									<Heading as="h4">Service Provider</Heading>
									<Box as="p" color="muted">
										Please provide the service provider and confirmation details
										for tickets.
									</Box>
								</Stack>
								<Inline gap="4" flexWrap="wrap">
									<Box flex="1">
										<SelectField
											label="Service Provider"
											fullWidth
											select={SelectTravelActivitySuppliers}
											name={`supplier`}
											creatable
											multiple={false}
											placeholder="Select or Add new..."
										/>
									</Box>
									<Box flex="1">
										<GetFieldValue<
											ITransportServiceProvider | undefined
										> name="supplier">
											{({ value }) =>
												!value ? null : (
													<SelectField
														label="Point of Contact"
														fullWidth
														select={SelectSupplierContact}
														supplierId={value.id}
														name={`supplier_contact`}
														creatable
														multiple={false}
														placeholder="Select or Add new..."
													/>
												)
											}
										</GetFieldValue>
									</Box>
								</Inline>
							</Stack>
							<Divider />
							<Stack gap="2">
								<Stack gap="1">
									<Heading as="h4">
										Set and Preview Confirmation Details
									</Heading>
									<Box as="p" color="muted">
										Please preview the selected services that will be assigned
										to this provider and set the available confirmation details.
									</Box>
								</Stack>
								<GetFieldValue<
									TAssignTravelActivityBookingProvidersSchema["bookings"]
								> name="bookings">
									{({ value: bookings }) => (
										<Stack as="ol" gap="4">
											{bookings.map((selectedBooking, i) => {
												return (
													<Box
														as="li"
														key={selectedBooking.booking.id}
														padding="4"
														borderWidth="1"
														rounded="lg"
													>
														<Grid gap={"4"}>
															<Col xs={12} sm={4} marginBottom="2">
																<Stack gap="2">
																	<Box
																		fontWeight="semibold"
																		color="primary"
																		fontSize="sm"
																	>
																		{formatDate(
																			localOrUtcTimestampToLocalDate(
																				selectedBooking.booking
																					.start_date_local,
																				selectedBooking.booking.start_date
																			),
																			"dddd, D MMMM"
																		)}
																	</Box>
																	<Text fontWeight="semibold" fontSize="md">
																		{selectedBooking.booking.activity.name} -{" "}
																		{selectedBooking.booking.ticket_type?.name}
																	</Text>
																	<Divider marginY="0" />
																	<TextAreaInputField
																		name={`bookings[${i}].confirmation_details`}
																		label="Confirmation Details"
																		placeholder="Enter the ticket confirmation details here"
																	/>
																	<Text fontSize="sm" color="muted">
																		ID: {selectedBooking.booking.id}{" "}
																		{selectedBooking.booking.is_booked ? (
																			<Badge success>Booked</Badge>
																		) : null}
																	</Text>
																</Stack>
															</Col>
															<Col>
																<Table
																	bordered
																	hover
																	headers={[
																		"Type",
																		"Qty.",
																		<Text as="span">
																			Price{" "}
																			<Text as="span" color="muted">
																				(
																				<InlineSelectTenantCurrencyInputField
																					name={`bookings[${i}].currency`}
																				/>
																				)
																			</Text>
																		</Text>,
																	]}
																	rows={selectedBooking.ticket_tourist_configurations.map(
																		(c, j) => {
																			return [
																				c.configuration.name,
																				c.quantity,
																				<TextInputField
																					size="sm"
																					type="number"
																					placeholder="1"
																					style={{ maxWidth: "100px" }}
																					name={`bookings[${i}].ticket_tourist_configurations[${j}].per_quantity_booked_price`}
																					help={
																						<GetFieldValue<
																							TTravelActiviytBooking["ticket_tourist_configurations"][number]
																						>
																							name={`bookings[${i}].ticket_tourist_configurations[${j}]`}
																						>
																							{({ value }) =>
																								value.per_quantity_booked_price &&
																								Number(c.quantity || 0) > 1 ? (
																									<Text>
																										x {c.quantity} ={" "}
																										<Money
																											currency={value.currency}
																											amount={
																												Number(
																													value.per_quantity_booked_price ||
																														0
																												) * Number(c.quantity)
																											}
																										/>
																									</Text>
																								) : null
																							}
																						</GetFieldValue>
																					}
																				/>,
																			]
																		}
																	)}
																>
																	<GetFieldValue<TTravelActiviytBooking>
																		name={`bookings[${i}]`}
																	>
																		{({ value }) => {
																			const total =
																				value.ticket_tourist_configurations.reduce<number>(
																					(total, n) =>
																						total +
																						Number(
																							n.per_quantity_booked_price || 0
																						) *
																							n.quantity,
																					0
																				)
																			if (!total) return null
																			return (
																				<tfoot>
																					<tr>
																						<TableFooterDataCell
																							colSpan={2}
																							textAlign="right"
																							fontWeight="semibold"
																						>
																							Total Booked Price:
																						</TableFooterDataCell>
																						<TableFooterDataCell>
																							<Money
																								showCurrency
																								amount={total}
																								currency={value.currency}
																							/>
																						</TableFooterDataCell>
																					</tr>
																				</tfoot>
																			)
																		}}
																	</GetFieldValue>
																</Table>
															</Col>
														</Grid>
													</Box>
												)
											})}
										</Stack>
									)}
								</GetFieldValue>
							</Stack>
							<Divider sm />
							<Box marginTop="4" padding="6" bgColor="subtle" rounded="lg">
								<CheckboxInputField
									name={`booked`}
									label="Confirmed by Supplier"
									help="Mark attached bookings as booked"
								/>
							</Box>
							<Divider sm />
							<Stack gap="4">
								<SubmissionError />
								<Box
									display={{ sm: "flex" }}
									flexDirection="rowReverse"
									justifyContent="between"
									gap="4"
								>
									<Inline flexDirection="rowReverse" gap="4">
										<Button type="submit" disabled={submitting}>
											{submitting ? "Assigning" : "Assign"}
										</Button>
										<Button
											onClick={() => onCancel()}
											level="tertiary"
											disabled={submitting}
										>
											Cancel
										</Button>
									</Inline>
									<Box>
										<Button
											onClick={() => {
												moveStepToSelectBookings(form.getState().values)
											}}
										>
											<Icons.ChevronDown rotate="90" /> Edit Services
										</Button>
									</Box>
								</Box>
							</Stack>
						</form>
					)}
				</Form>
			) : null}
		</>
	)
}

type TSelectTravelActivityBookingsFormValues = {
	bookings: Array<{
		booking: TTravelActiviytBooking
		selected: boolean
	}>
}
function SelectTravelActivityBookingsForm({
	bookings,
	initialSelectedBookings,
	onCancel,
	onSubmit,
}: {
	bookings: Array<TTravelActiviytBooking>
	initialSelectedBookings: Array<TTravelActiviytBooking>
	onSubmit: (selectedBookings: Array<TTravelActiviytBooking>) => Promise<void>
	onCancel: () => void
}) {
	const initialValues: TSelectTravelActivityBookingsFormValues = useMemo(() => {
		const selectedBookingsById = collect(initialSelectedBookings).keyBy(
			(b) => b.id
		)
		return {
			bookings: bookings.map((booking) => ({
				booking,
				selected: Boolean(selectedBookingsById[booking.id]),
			})),
		}
	}, [bookings, initialSelectedBookings])
	return (
		<Form<TSelectTravelActivityBookingsFormValues>
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				onSubmit(
					values.bookings
						.filter((b) => Boolean(b.selected))
						.map((b) => b.booking)
				)
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<Stack gap="2">
							<Heading as="h4">Select Travel Activity Booking Service</Heading>
							<Text color="muted">
								Please select applicable services which you want to assign a
								provider to.
							</Text>
						</Stack>
						<Box>
							<Stack gap="4">
								<GetFieldValue<
									TSelectTravelActivityBookingsFormValues["bookings"]
								>
									name={`bookings`}
								>
									{({
										value: schedulesFieldValue,
										onChange: changeSchedulesFieldValue,
									}) => {
										return (
											<Box
												as="label"
												padding="4"
												display="flex"
												alignItems="center"
												bgColor={{ default: "subtle", hover: "primary" }}
												borderWidth={"1"}
												rounded="lg"
												cursor="pointer"
											>
												<CheckboxInput
													name="select_all"
													checked={
														schedulesFieldValue.filter((s) => s.selected)
															.length === schedulesFieldValue.length
													}
													onChange={(e) => {
														if (e.currentTarget.checked) {
															// select all the bookings
															changeSchedulesFieldValue(
																schedulesFieldValue.map((s) => ({
																	...s,
																	selected: true,
																}))
															)
														} else {
															// unselect all the bookings
															changeSchedulesFieldValue(
																schedulesFieldValue.map((s) => ({
																	...s,
																	selected: false,
																}))
															)
														}
													}}
												/>
												<Box marginLeft="2" fontWeight="semibold">
													Select All Activity Bookings
												</Box>
											</Box>
										)
									}}
								</GetFieldValue>
								<Stack gap="3" as="ol">
									{bookings.map((booking, index) => {
										return (
											<Box
												as="li"
												key={booking.id}
												paddingX="4"
												paddingY="2"
												rounded="md"
												borderWidth="1"
												backgroundColor={{ hover: "subtle" }}
												opacity={booking.is_booked ? "70" : "100"}
												title={
													booking.is_booked
														? "Already Booked"
														: "Click to select"
												}
											>
												<Inline justifyContent="between" collapseBelow="md">
													<Inline gap="2" as="label">
														<Box>
															<SwitchInputField
																name={`bookings[${index}].selected`}
															/>
														</Box>
														{booking.is_booked ? (
															<Box>
																<Icons.OkCircleSolid color="success" />
															</Box>
														) : null}
														<Box>
															<Text fontWeight="semibold">
																{booking.activity.name} -{" "}
																{booking.ticket_type?.name} - (
																{
																	booking.ticket_tourist_configurations_full_name
																}
																)
															</Text>
															{booking.start_time_formatted ||
															booking.duration_formatted ? (
																<Text fontSize="sm">
																	{joinAttributes(
																		booking.start_time_formatted,
																		booking.duration_formatted
																	)}
																</Text>
															) : null}
														</Box>
													</Inline>
													<Box>
														{formatDate(
															localOrUtcTimestampToLocalDate(
																booking.start_date_local,
																booking.start_date
															),
															"ddd, D MMM"
														)}
													</Box>
												</Inline>
											</Box>
										)
									})}
								</Stack>
							</Stack>
							<Divider sm />
							<Inline gap="4" flexDirection="rowReverse">
								<GetFieldValue<
									TSelectTravelActivityBookingsFormValues["bookings"]
								> name="bookings">
									{({ value }) => {
										const hasSelectedSomething = value.some((s) => s.selected)
										return (
											<Button
												level="primary"
												disabled={!hasSelectedSomething}
												type="submit"
												status={hasSelectedSomething ? "primary" : "warning"}
											>
												{!hasSelectedSomething ? (
													<>
														<Icons.Attention /> Please Select Bookings
													</>
												) : (
													<>
														Next: Assign Provider{" "}
														<Icons.ChevronDown rotate="270" />
													</>
												)}
											</Button>
										)
									}}
								</GetFieldValue>
								<Button onClick={onCancel} level="tertiary">
									Cancel
								</Button>
							</Inline>
						</Box>
					</Stack>
				</form>
			)}
		</Form>
	)
}
