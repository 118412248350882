import { Helmet } from "react-helmet-async"
import { TripCheckInOutReportsList } from "../../../TripCheckInOutReports"

export default function TripCheckInOutReportsListPage() {
	return (
		<>
			<Helmet>
				<title>Trip Check In/Out Report</title>
			</Helmet>
			<TripCheckInOutReportsList />
		</>
	)
}
