import { Helmet } from "react-helmet-async"
import { TaxTypesList } from "../../../../TaxTypes"

export default function OrganizationTaxTypesSettingsPage() {
	return (
		<>
			<Helmet>
				<title>Tax Types</title>
			</Helmet>
			<TaxTypesList />
		</>
	)
}
