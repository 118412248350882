import {
	Box,
	Button,
	Inline,
	Stack,
	Text,
	Icons,
	joinAttributes,
	Grid,
	Col,
	Select,
	Time,
	Alert,
	Tabs,
	TabsList,
	TabItem,
	Heading,
	Money,
	TimeDuration,
	RelativeTime,
	Badge,
	Component,
	TabContent,
} from "@sembark-travel/ui/base"
import {
	useSearch,
	Search,
	ListView,
	areAdvancedFiltersAppliedDefault,
	TSortOptions,
} from "@sembark-travel/ui/list"
import { useLocationQuery, ButtonLink, Link } from "@sembark-travel/ui/router"
import {
	localOrUtcTimestampToLocalDate,
	utcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { useEffect } from "react"
import { SelectCabTypes } from "../CabTypes"
import { generatePath } from "../router-utils"
import { TTripOperationalBooking } from "./store"
import { DayBookingServicesOverview } from "./Item"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import {
	ReorderServicesInDialog,
	TripOperationalBookingsItemForDateInDialog,
} from "./ForDateItem"
import { FeatureFlag, PERMISSIONS, useCheckPermissions } from "../Auth"
import {
	BOOKING_STATUS,
	IOperationalBookingFilters,
	IOperationalBookingFiltersInLocationQuery,
	filtersToRequestParams,
	operationBookingLocationQueryToParams,
	operationalBookingFilterParamsToLocationQuery,
} from "./utils"
import { SelectTripDestination } from "../TripDestinations"
import { GetFieldValue, SelectField } from "@sembark-travel/ui/form"
import { SelectTransporServiceProviders } from "../TransportServiceProviders"
import { SelectTransportServiceLocationPoints } from "../TransportServices"
import { SelectActivity } from "../TravelActivities"
import { SelectUsers } from "../Users"
import { Dialog } from "@sembark-travel/ui/dialog"
import { EditTripOwners } from "../TripOwners"

function XHR(xhr: XHRInstance) {
	return {
		async getSchedules(params: Record<string, unknown>) {
			return xhr
				.get<IListResponse<TTripOperationalBooking>>(
					"/trip-operational-bookings",
					{
						params: {
							...params,
							include: "conversion_info",
						},
					}
				)
				.then((resp) => resp.data)
		},
	}
}

const sortOptions: TSortOptions = [
	{
		label: "Start Date",
		name: "start_date",
		type: "date",
		order: "asc",
	},
]

export function TripOperationalBookingsList() {
	const [queryFilters, setQueryFilters] = useLocationQuery<
		IOperationalBookingFilters,
		IOperationalBookingFiltersInLocationQuery
	>({
		toQuery: operationalBookingFilterParamsToLocationQuery,
		fromQuery: operationBookingLocationQueryToParams,
	})
	const [params, setParams] = useSearch<IOperationalBookingFilters>({
		q: "",
		...queryFilters,
		trip_operational_status: queryFilters.trip_operational_status || "new",
	})
	const { hasPermission } = useCheckPermissions()
	useEffect(() => {
		setQueryFilters(params)
	}, [params, setQueryFilters])
	return (
		<Search
			title={"Operational Bookings"}
			initialParams={params}
			placeholder="Search by id, destination, guest..."
			Filters={OperationalBookingFilters}
			onSearch={(params) => setParams({ ...params, page: 1 })}
			actions={
				<ButtonLink
					title="Go to Calendar"
					to={generatePath("/operational-bookings/calendar")}
				>
					<Icons.Calendar /> Calendar
				</ButtonLink>
			}
			areAdvancedFiltersApplied={(params) => {
				const { trip_operational_status, ...others } = params
				return areAdvancedFiltersAppliedDefault(others)
			}}
		>
			{({ searchParams, setSearchParamValue }) => (
				<Tabs>
					<TabsList>
						<TabItem
							onClick={() => {
								setSearchParamValue("trip_operational_status", "new")
							}}
							active={
								!searchParams.trip_operational_status ||
								searchParams.trip_operational_status === "new"
							}
						>
							New
						</TabItem>
						<TabItem
							onClick={() => {
								setSearchParamValue("trip_operational_status", "in_progress")
							}}
							active={searchParams.trip_operational_status === "in_progress"}
						>
							In Progress
						</TabItem>
						<TabItem
							onClick={() => {
								setSearchParamValue("trip_operational_status", "booked")
								setSearchParamValue("sort", undefined)
							}}
							active={searchParams.trip_operational_status === "booked"}
						>
							Booked
						</TabItem>
						<TabItem
							onClick={() => {
								setSearchParamValue("trip_operational_status", "on_trip")
								setSearchParamValue("sort", undefined)
							}}
							active={searchParams.trip_operational_status === "on_trip"}
						>
							On Trip
						</TabItem>
						<TabItem
							onClick={() => {
								setSearchParamValue("trip_operational_status", "past")
								setSearchParamValue("sort", undefined)
							}}
							active={searchParams.trip_operational_status === "past"}
						>
							Past
						</TabItem>
						<TabItem
							onClick={() => {
								setSearchParamValue("trip_operational_status", "dropped")
								setSearchParamValue("sort", undefined)
							}}
							active={searchParams.trip_operational_status === "dropped"}
						>
							Dropped
						</TabItem>
						<TabItem
							onClick={() => {
								setSearchParamValue("trip_operational_status", "all")
								setSearchParamValue("sort", undefined)
							}}
							active={searchParams.trip_operational_status === "all"}
						>
							All
						</TabItem>
					</TabsList>
					<TabContent>
						<ListView<TTripOperationalBooking, IOperationalBookingFilters>
							pageKey="trip-operational-bookings-list"
							params={params}
							sortOptions={
								searchParams.trip_operational_status === "new" ||
								searchParams.trip_operational_status === "in_progress"
									? sortOptions
									: undefined
							}
							onPageChange={(page, sort) =>
								setParams({ ...params, page, sort })
							}
							fetch={(xhr, params) =>
								XHR(xhr).getSchedules(filtersToRequestParams(params))
							}
						>
							{({ items, refresh }) => {
								return (
									<Stack gap="6">
										{items.map((trip) => {
											return (
												<Box
													bgColor="default"
													borderWidth="1"
													rounded="md"
													key={trip.id}
													paddingX="4"
													paddingY="2"
												>
													<Grid gap="4">
														<Col sm={12} md={3} lg={2}>
															<Stack gap="1">
																<Heading as="h4" fontSize="md">
																	<Link
																		to={generatePath(
																			"/trips/:tripId/services-bookings/:serviceType",
																			{
																				tripId: String(trip.id),
																				serviceType: "operational",
																			}
																		)}
																		anchored
																		color="accent"
																	>
																		{trip.tourist
																			? trip.tourist.name
																			: "Guest Details Pending"}{" "}
																		•{" "}
																		<Box display="inline" as="span">
																			{trip.destinations
																				.map((l) => l.short_name)
																				.join(", ")}
																		</Box>
																	</Link>
																</Heading>
																<Box fontSize="sm">
																	{joinAttributes(
																		<Box display="inline">
																			<Box
																				display="inline"
																				marginRight="1"
																				color="muted"
																			>
																				#
																			</Box>
																			{trip.id}
																		</Box>,
																		<Box
																			display="inline"
																			title={trip.trip_source.name}
																		>
																			{trip.trip_source.short_name}
																			{trip.reference_id
																				? `-${trip.reference_id}`
																				: ``}
																		</Box>,
																		trip.total_payments_amount ? (
																			<Money
																				amount={trip.total_payments_amount}
																				currency={
																					trip.total_payments_amount_currency
																				}
																				showCurrency
																			/>
																		) : null
																	)}
																</Box>
																<Box>
																	{joinAttributes(
																		<Time
																			timestamp={trip.start_date}
																			localTimestamp={trip.start_date_local}
																		/>,
																		<TimeDuration days={trip.days}>
																			{trip.days}D
																		</TimeDuration>,
																		`${trip.no_of_adults}A${
																			trip.children.length
																				? `, ${trip.children.length}C`
																				: ``
																		}`
																	)}
																</Box>
																{trip.converted_at && trip.converted_by ? (
																	<Box fontSize="sm" color="muted">
																		<Icons.ChevronDown
																			rotate="270"
																			title="Conversion"
																		/>{" "}
																		<RelativeTime
																			value={utcTimestampToLocalDate(
																				trip.converted_at
																			)}
																		/>{" "}
																		by {trip.converted_by.name}
																	</Box>
																) : trip.on_hold_at && trip.on_hold_by ? (
																	<Box fontSize="sm" color="muted">
																		<Badge>On-Hold Trip</Badge>{" "}
																		<RelativeTime
																			value={utcTimestampToLocalDate(
																				trip.on_hold_at
																			)}
																		/>{" "}
																		by {trip.on_hold_by.name}
																	</Box>
																) : (
																	<Box>
																		<Badge>On-Hold</Badge>
																	</Box>
																)}
																<Inline
																	marginTop="4"
																	gap="2"
																	alignItems="center"
																>
																	<Box>
																		<Icons.Users title="Operations Team" />
																	</Box>
																	<Box fontSize="sm">
																		{trip.operations_team?.length ? (
																			<Text>
																				{trip.operations_team
																					.map((user) => user.name)
																					.join(", ")}
																			</Text>
																		) : (
																			<Text color="muted">Not Set</Text>
																		)}
																	</Box>
																	{hasPermission(
																		PERMISSIONS.MANAGE_TRIP_OWNERS
																	) ? (
																		<Component initialState={false}>
																			{({
																				state: isEditing,
																				setState: setIsEditing,
																			}) => (
																				<>
																					{!isEditing ? (
																						<Button
																							title="Edit operation teams"
																							inline
																							onClick={() => {
																								setIsEditing(true)
																							}}
																						>
																							<Icons.Pencil />
																						</Button>
																					) : null}
																					<Dialog
																						title="Edit Operations Team"
																						open={isEditing}
																						onClose={() => setIsEditing(false)}
																						sm
																					>
																						<Dialog.Body>
																							<EditTripOwners
																								type="operations_team"
																								users={trip.operations_team}
																								itemId={trip.id}
																								onSuccess={() => {
																									setIsEditing(false)
																									refresh()
																								}}
																								onCancel={() => {
																									setIsEditing(false)
																								}}
																							/>
																						</Dialog.Body>
																					</Dialog>
																				</>
																			)}
																		</Component>
																	) : null}
																</Inline>
															</Stack>
														</Col>
														<Col>
															<Stack gap="2">
																{!trip.daywise_services.length ? (
																	<Text>
																		No Operational Services for this Trip
																	</Text>
																) : (
																	trip.daywise_services.map(
																		({
																			date,
																			date_local,
																			day,
																			services,
																			services_needs_reordering,
																			services_ordered_by_start_time,
																		}) => (
																			<Inline
																				position="relative"
																				gap="4"
																				collapseBelow="sm"
																				key={date}
																			>
																				<Box
																					position="sticky"
																					top="0"
																					zIndex="10"
																				>
																					<Box
																						position="sticky"
																						top="0"
																						zIndex="10"
																					>
																						<TripOperationalBookingsItemForDateInDialog
																							tripStartDateUtc={trip.start_date}
																							tripId={trip.id}
																							day={day}
																							date={localOrUtcTimestampToLocalDate(
																								date_local,
																								date
																							)}
																							onRefresh={() => refresh()}
																						>
																							{({ show }) => (
																								<Button
																									inline
																									onClick={() => show()}
																									fullWidth
																								>
																									<Box
																										bgColor="default"
																										textAlign="center"
																										width={"full"}
																										paddingX="4"
																										paddingY="1"
																										rounded="md"
																										borderWidth={"1"}
																										style={{
																											minWidth: "110px",
																										}}
																									>
																										<Text
																											fontWeight="semibold"
																											color="accent"
																										>
																											{withOrdinalSuffix(day)}{" "}
																											Day
																										</Text>
																										<Time
																											timestamp={date}
																											localTimestamp={
																												date_local
																											}
																											format="ddd, D MMM"
																											whiteSpace="preserve"
																											fontSize="sm"
																										/>
																									</Box>
																								</Button>
																							)}
																						</TripOperationalBookingsItemForDateInDialog>
																					</Box>
																				</Box>
																				<Box flex="1">
																					<Stack gap="4">
																						{services_needs_reordering ? (
																							<Alert
																								status="warning"
																								title="Services needs Re-Ordering"
																							>
																								<Stack gap="1">
																									<Text>
																										Some services are not
																										aligned with the provided
																										timings of the services.
																									</Text>
																									<Box>
																										<ReorderServicesInDialog
																											tripId={trip.id}
																											services={services}
																											initialOrdering={
																												services_ordered_by_start_time
																											}
																											onSuccess={() => {
																												refresh()
																											}}
																										>
																											{({ onReorder }) => (
																												<Button
																													onClick={() =>
																														onReorder()
																													}
																													status="warning"
																													size="sm"
																													level="primary"
																												>
																													<Icons.SwitchHorizontal rotate="90" />{" "}
																													Re-Order Now
																												</Button>
																											)}
																										</ReorderServicesInDialog>
																									</Box>
																								</Stack>
																							</Alert>
																						) : null}
																						<DayBookingServicesOverview
																							services={services}
																							onRefresh={() => refresh()}
																							showPrices={true}
																							compactView
																						/>
																					</Stack>
																				</Box>
																			</Inline>
																		)
																	)
																)}
															</Stack>
														</Col>
													</Grid>
												</Box>
											)
										})}
									</Stack>
								)
							}}
						</ListView>
					</TabContent>
				</Tabs>
			)}
		</Search>
	)
}

export function OperationalBookingFilters() {
	return (
		<Stack gap="4">
			<SelectField
				select={SelectTripDestination}
				name="trip_destinations"
				multiple
				label="Trip Destinations"
				fetchOnMount
			/>
			<SelectField
				select={SelectTransporServiceProviders}
				name="transport_service_providers"
				multiple
				label="Service Providers"
				fetchOnMount
			/>
			<SelectField
				select={SelectTransportServiceLocationPoints}
				name="transport_service_location_points"
				label="Service Cities"
				multiple
			/>
			<SelectField
				select={SelectCabTypes}
				name="cab_types"
				label="Cab Types"
				multiple
				fetchOnMount
			/>
			<FeatureFlag flag="travel_activities">
				<SelectField
					select={SelectActivity}
					name="travel_activities"
					label="Travel Activity Name"
					multiple
				/>
				<GetFieldValue<IOperationalBookingFilters["travel_activities"]>
					name={`travel_activities`}
				>
					{({ value: activities }) => {
						if (!activities) return null
						const ticket_types = activities.flatMap((a) => a.ticket_types || [])
						if (!ticket_types.length) return null
						return (
							<Box flex="1" maxWidth="sm">
								<SelectField
									label="Ticket/Package Type"
									select={Select}
									name={"travel_activity_ticket_types"}
									options={ticket_types}
									multiple
								/>
							</Box>
						)
					}}
				</GetFieldValue>
			</FeatureFlag>
			<SelectField
				label="Team"
				select={SelectUsers}
				name="owners"
				multiple
				fetchOnMount
			/>
			<SelectField
				select={Select}
				name="status"
				label="Booking Status"
				options={BOOKING_STATUS}
			/>
		</Stack>
	)
}
