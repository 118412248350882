import { Helmet } from "react-helmet-async"
import { MealPlansList } from "./../../../MealPlans"
import { ButtonLink } from "@sembark-travel/ui/router"
import { generatePath } from "./../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function MealPlansPage() {
	return (
		<>
			<Helmet>
				<title>Meal Plans</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.UPLOAD_BULK_HOTELS}>
				<MealPlansList
					actions={
						<ButtonLink to={generatePath("/meal-plans/new")} status="primary">
							New Meal Plan
						</ButtonLink>
					}
				/>
			</ForbidUnlessAuthorized>
		</>
	)
}
