import React from "react"
import toast, { type ToastOptions } from "react-hot-toast"
import { Icons, Inline, Box, Button } from "@sembark-travel/ui/base"
import { snackbarAnimation } from "./snackbar.css"
import classNames from "classnames"

type TAction =
	| React.ReactNode
	| ((props: { hide: () => void }) => React.ReactNode)

export function hideSnackbar(id: string) {
	toast.dismiss(id)
}

/**
 * Open a snackbar with an id
 */
export function showSnackbar(
	message: React.ReactNode | ((props: { hide: () => void }) => React.ReactNode),
	c?: ToastOptions & {
		actions?: Array<TAction>
	}
) {
	const { actions, ...config } = c || {}
	const id = toast.custom(
		(t) => {
			const hide = () => hideSnackbar(t.id)
			return (
				<Inline
					data-color-theme="dark"
					id={t.id}
					padding="4"
					bgColor="default"
					color="default"
					borderWidth="1"
					rounded="md"
					gap="4"
					alignItems="center"
					className={classNames(
						t.visible ? snackbarAnimation.enter : snackbarAnimation.exit
					)}
				>
					<Box flex="1" minWidth="0">
						{typeof message === "function" ? <>{message({ hide })}</> : message}
					</Box>
					<Inline alignItems="center" gap="4">
						{actions
							? actions.map((a, id) => {
									return (
										<React.Fragment key={id}>
											{typeof a === "function" ? <>{a({ hide })}</> : a}
										</React.Fragment>
									)
								})
							: null}
						<Button
							size="sm"
							inline
							type="button"
							onClick={() => {
								toast.dismiss(t.id)
							}}
						>
							<Icons.Cancel />
						</Button>
					</Inline>
				</Inline>
			)
		},
		{
			...config,
			ariaProps: {
				...config?.ariaProps,
				role: "status",
				"aria-live": "polite",
			},
		}
	)
	return () => {
		toast.dismiss(id)
	}
}
