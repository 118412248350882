import {
	Form,
	withServerErrors,
	arrayMutators,
	SubmissionError,
	addKeyToFieldArrayItem,
	SelectField,
	TextAreaInputField,
	isTruthy,
	SwitchInputField,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import {
	TTravelActivityDatewisePricesInputFieldValue,
	TValidTravelActivityDatewisePricesInputFieldValue,
	TravelActivityDatewisePricesInputField,
	getValidTravelActivityData,
} from "../TravelActivityPrices"
import { ITrip } from "../Trips"
import { useXHR } from "@sembark-travel/xhr"
import useSWR from "swr"
import { TTravelActiviytBooking } from "./store"
import { useId, useMemo } from "react"
import {
	Spinner,
	Stack,
	Inline,
	Button,
	Divider,
	Grid,
	Col,
	Box,
} from "@sembark-travel/ui/base"
import { TChildrenArray } from "../Tourists"
import {
	createDuration,
	dateToUTCString,
	formatDate,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { TTripDestination } from "../TripDestinations"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { Optional } from "utility-types"
import { getStartEndDateTimesFromDateAndSlot } from "./utils"
import {
	ITransportServiceProvider,
	SelectSupplierContact,
	SelectTravelActivitySuppliers,
	TTransportServiceProviderContact,
} from "../TransportServiceProviders"
import { TTravelActivityDuration } from "../TravelActivities"

function useBookingDetails(bookingId: number | string) {
	const xhr = useXHR()
	const id = useId()
	const { data } = useSWR(`/travel-activity-bookings/${bookingId}?${id}`, () =>
		xhr
			.get<{
				data: TTravelActiviytBooking & {
					trip: ITrip
				}
			}>(`/travel-activity-bookings/${bookingId}`)
			.then((resp) => resp.data.data)
	)
	return { data }
}

export function EditTravelActivityBookingItemInDialog({
	children,
	...props
}: Optional<
	Omit<React.ComponentProps<typeof EditTravelActivityBookingItem>, "onCancel">,
	"onSuccess"
> & {
	children: (props: { onEdit: () => void }) => React.ReactNode
}) {
	const [isOpen, openDialog, closeDialog] = useDialog()
	return (
		<>
			{children({ onEdit: openDialog })}
			<Dialog
				open={isOpen}
				onClose={closeDialog}
				title="Edit Travel Activity Booking"
				xl
			>
				<Dialog.Body>
					<EditTravelActivityBookingItem
						{...props}
						onSuccess={() => {
							closeDialog()
							props.onSuccess?.()
						}}
						onCancel={closeDialog}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function EditTravelActivityBookingItem({
	bookingId,
	onSuccess,
	...props
}: {
	bookingId: number | string
	onSuccess: () => unknown
} & Pick<React.ComponentProps<typeof EditForm>, "onCancel">) {
	const xhr = useXHR()
	const { data } = useBookingDetails(bookingId)
	const dates = useMemo(() => {
		if (!data?.start_date) return []
		return [
			localOrUtcTimestampToLocalDate(data.start_date_local, data.start_date),
		]
	}, [data?.start_date, data?.start_date_local])
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const {
		activity,
		trip,
		ticket_type,
		duration,
		slot,
		ticket_tourist_configurations,
		comments,
		supplier,
		supplier_contact,
		confirmation_details,
		remarks,
		is_booked,
	} = data
	const { no_of_adults, children, destinations } = trip
	let durationOption: TTravelActivityDuration | undefined =
		ticket_type?.durations?.find((d) => d.iso === duration)
	if (duration && !durationOption) {
		const parseDuration = createDuration(duration)
		durationOption = {
			name: parseDuration.asMinutes() + " Mins",
			iso: duration,
			slots: slot ? [slot] : [],
		}
	}
	return (
		<EditForm
			noOfAdults={no_of_adults}
			children={children}
			tripDestinations={destinations}
			dates={dates}
			initialValues={{
				supplier,
				supplier_contact,
				confirmation_details,
				remarks,
				booked: isTruthy(is_booked),
				data: {
					dates,
					activity,
					ticket_type,
					duration: durationOption,
					slot,
					comments,
					ticket_tourist_configurations: ticket_tourist_configurations.map(
						({
							quantity,
							configuration,
							booked_price,
							currency,
							per_quantity_booked_price,
						}) =>
							addKeyToFieldArrayItem({
								quantity,
								configuration,
								date_wise_prices: [
									addKeyToFieldArrayItem({
										date: dates[0],
										currency,
										per_quantity_booked_price,
										booked_price,
										edited_booked_price: true,
									}),
								],
							})
					),
				},
			}}
			onSubmit={async ({
				supplier,
				supplier_contact,
				confirmation_details,
				remarks,
				booked,
				data,
			}) => {
				const {
					activity,
					dates,
					ticket_type,
					slot,
					ticket_tourist_configurations,
					comments,
				} = data
				const { start_date, end_date, start_time, end_time, duration } =
					getStartEndDateTimesFromDateAndSlot(
						dates[0],
						slot,
						data.duration?.iso
					)
				const payload = {
					supplier: supplier?.id,
					supplier_contact: supplier ? supplier_contact?.id : undefined,
					confirmation_details,
					remarks,
					booked: isTruthy(booked) ? 1 : 0,
					start_date: dateToUTCString(start_date),
					end_date: dateToUTCString(end_date),
					start_date_local: formatDate(start_date, "YYYY-MM-DD"),
					start_time_local: start_time,
					end_date_local: formatDate(end_date, "YYYY-MM-DD"),
					end_time_local: end_time,
					duration: duration,
					activity_id: activity.id,
					ticket_type_id: ticket_type?.id,
					comments,
					ticket_tourist_configurations: ticket_tourist_configurations.map(
						({ configuration, quantity, date_wise_prices }) => {
							const {
								price,
								per_quantity_given_price,
								given_price,
								per_quantity_booked_price,
								booked_price,
								currency,
							} = date_wise_prices?.at(0) ||
								date_wise_prices?.at(0) || {
									currency: "INR",
									price: 0,
									given_price: 0,
									per_quantity_given_price: 0,
									booked_price: 0,
									per_quantity_booked_price: 0,
								}
							return {
								configuration_id: configuration.id,
								quantity,
								currency,
								calculated_price: price,
								per_quantity_given_price: per_quantity_given_price || 0,
								given_price: given_price || 0,
								booked_price: booked_price || 0,
								per_quantity_booked_price: per_quantity_booked_price || 0,
							}
						}
					),
				}
				await xhr.patch(`/travel-activity-bookings/${bookingId}`, payload)
				onSuccess()
			}}
			{...props}
		/>
	)
}

type TTravelActivityBookingFormValue = {
	supplier?: ITransportServiceProvider
	supplier_contact?: TTransportServiceProviderContact
	confirmation_details?: string
	remarks?: string
	booked: boolean
	data: TTravelActivityDatewisePricesInputFieldValue
}

type TValidTravelActivityBookingFormValue = Omit<
	TTravelActivityBookingFormValue,
	"data"
> & {
	data: TValidTravelActivityDatewisePricesInputFieldValue
}

function EditForm({
	noOfAdults,
	children,
	dates,
	tripDestinations,
	initialValues,
	onSubmit,
	onCancel,
}: {
	noOfAdults: number
	children: TChildrenArray
	dates: Array<Date>
	tripDestinations: Array<TTripDestination>
	initialValues: TValidTravelActivityBookingFormValue
	onSubmit: (data: TValidTravelActivityBookingFormValue) => Promise<unknown>
	onCancel: () => void
}) {
	return (
		<Form<TValidTravelActivityBookingFormValue>
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				const data = getValidTravelActivityData(values.data)
				if (!data) {
					throw new Error("Please provide valid data")
				}
				await onSubmit({
					...values,
					data,
				})
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit, submitting }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<TravelActivityDatewisePricesInputField
							name="data"
							noOfAdults={noOfAdults}
							children={children}
							dates={dates}
							tripDestinations={tripDestinations}
							showBookedPrices
							canCustomizeDuration
						/>
						<Grid gap="4">
							<Col xs={12} sm={6}>
								<Stack gap="4">
									<Inline gap="4" flexWrap={"wrap"}>
										<Box flex="1">
											<SelectField
												label="Service Provider"
												fullWidth
												select={SelectTravelActivitySuppliers}
												name={`supplier`}
												creatable
												multiple={false}
												placeholder="Select or Add new..."
											/>
										</Box>
										<GetFieldValue<
											ITransportServiceProvider | undefined
										> name="supplier">
											{({ value }) =>
												!value ? null : (
													<Box flex="1">
														<SelectField
															label="Point of Contact"
															fullWidth
															select={SelectSupplierContact}
															supplierId={value.id}
															name={`supplier_contact`}
															creatable
															multiple={false}
															placeholder="Select or Add new..."
														/>
													</Box>
												)
											}
										</GetFieldValue>
									</Inline>
									<Box
										padding="2"
										bgColor="subtle"
										borderWidth="1"
										rounded="md"
									>
										<SwitchInputField
											name="booked"
											label="Confirmed by Supplier"
											help="This will generate instalments for this booking for the supplier"
										/>
									</Box>
								</Stack>
							</Col>
							<Col>
								<Inline gap="4" flexWrap="wrap" collapseBelow="sm">
									<Box flex="1" style={{ minWidth: "200px" }}>
										<TextAreaInputField
											name="confirmation_details"
											label="Confirmation Details"
											placeholder="Enter the booking confirmation details here..."
											rows={3}
										/>
									</Box>
									<Box flex="1" style={{ minWidth: "200px" }}>
										<TextAreaInputField
											name="remarks"
											label="Remarks for Supplier and Customer"
											secondaryLabel="optional"
											placeholder="Notes and remarks to be shared with supplier and customer..."
											rows={3}
										/>
									</Box>
								</Inline>
							</Col>
						</Grid>
						<Divider sm />
						<SubmissionError />
						<Inline gap="4">
							<Button disabled={submitting} type="submit">
								{submitting ? "Please wait..." : "Save Details"}
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
