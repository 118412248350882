import { useXHR } from "@sembark-travel/xhr"
import {
	AuthenticationResponseJSON,
	PublicKeyCredentialRequestOptionsJSON,
} from "@simplewebauthn/types"
import { startAuthentication } from "@simplewebauthn/browser"
import { Icons, Box, Inline, Pulse } from "@sembark-travel/ui/base"
import { Form, withServerErrors } from "@sembark-travel/ui/form"
import { IUser } from "./store"

export function AutoWebAuthn({
	userId,
	onSubmit,
}: {
	userId: number | string
	onSubmit: (payload: AuthenticationResponseJSON) => Promise<IUser>
}) {
	const xhr = useXHR()
	return (
		<Form<{ user_ids: Array<number | string> }>
			initialValues={{ user_ids: [userId] }}
			onSubmit={withServerErrors(async (values) => {
				try {
					const options = await xhr
						.get<{ data: PublicKeyCredentialRequestOptionsJSON }>(
							"web-authn/allowed-credentials",
							{
								params: {
									user_ids: values.user_ids,
								},
							}
						)
						.then((resp) => resp.data.data)
					// Pass the options to the authenticator and wait for a response
					const asseResp = await startAuthentication(options)
					await onSubmit(asseResp)
				} catch (e) {
					const error = e as Error
					alert(
						error.message || "Something went wrong. Please try after sometime"
					)
				}
			})}
			subscription={{ submitting: true }}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit} title="Login via WebAuthn">
					<Inline
						gap="6"
						alignItems="start"
						justifyContent="center"
						position="relative"
					>
						<Box
							as="button"
							type="submit"
							disabled={submitting}
							bgColor={
								submitting ? "subtle" : { default: "default", hover: "subtle" }
							}
							padding="2"
							rounded="full"
							position="relative"
							overflow="hidden"
						>
							{submitting ? (
								<Pulse bgColor="primary" position="absolute" inset="0" />
							) : null}
							<Icons.FingerPrint size="12" />
						</Box>
					</Inline>
				</form>
			)}
		</Form>
	)
}
