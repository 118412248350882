import { $PropertyType } from "utility-types"
import {
	utcTimestampToLocalDate,
	isBetween,
	localOrUtcTimestampToLocalDate,
	setTimeFrom,
	parseDate,
	formatDate,
} from "@sembark-travel/datetime-utils"
import { IUser } from "../Users"
import { TTripDestination } from "../TripDestinations"
import { TTransportServiceLocationPoint } from "../TransportServices"
import { ILocation } from "../Locations"
import { ICabType } from "../CabTypes"
import { dateToQuery, parseDateFromQuery } from "@sembark-travel/datetime-utils"
import { TSearchParams } from "@sembark-travel/ui/list"
import { ITransportServiceProvider } from "../TransportServiceProviders"
import Storage from "../storage"
import { useCallback, useEffect, useMemo, useReducer } from "react"
import { ICabSchedule } from "./store"

export function arrangeSchedulesInCalendar<
	TSchedule extends {
		id: number
		start_date: string
		end_date: string
		trip: { id: number; start_date: string }
	},
>(schedules: Array<TSchedule>, interval: Array<Date>, compact?: boolean) {
	const tripsById = schedules.reduce<{
		[key: string]: $PropertyType<TSchedule, "trip">
	}>((tripIds, s) => {
		tripIds[s.trip.id] = s.trip
		return tripIds
	}, {})
	const trips = Object.keys(tripsById).map((id: string) => tripsById[id])
	// sort the trips with start date
	trips.sort((a, b) =>
		a.start_date > b.start_date
			? 1
			: a.start_date === b.start_date
				? a.id - b.id
				: -1
	)
	// lets construct a 3D array which will hold schedules
	// X -> rows
	// Y -> Slots
	// Z -> Schedules
	const schedulesPerTripPerSlot: Array<Array<Array<TSchedule>>> = []
	let currentRow = 0
	if (!compact) {
		for (const trip of trips) {
			const schedulesForThisTripPerSlot: Array<Array<TSchedule>> = []
			for (const slot of interval) {
				schedulesForThisTripPerSlot.push(
					schedules.filter(
						(schedule) =>
							schedule.trip.id === trip.id &&
							isBetween(
								slot,
								utcTimestampToLocalDate(schedule.start_date),
								utcTimestampToLocalDate(schedule.end_date),
								"day",
								"[]"
							)
					)
				)
			}
			schedulesPerTripPerSlot[currentRow] = schedulesForThisTripPerSlot
			currentRow += 1
		}
		return schedulesPerTripPerSlot
	} else {
		for (const trip of trips) {
			const schedulesForThisTripPerSlot: Array<Array<TSchedule>> = []
			for (const slot of interval) {
				schedulesForThisTripPerSlot.push(
					schedules.filter(
						(schedule) =>
							schedule.trip.id === trip.id &&
							isBetween(
								slot,
								utcTimestampToLocalDate(schedule.start_date),
								utcTimestampToLocalDate(schedule.end_date),
								"day",
								"[]"
							)
					)
				)
			}
			// see if we need to move to the next row or we can push it to an earlier row
			// Steps:
			// get the first none empty slot in for this trip
			const firstNonEmptyCell = schedulesForThisTripPerSlot.findIndex(
				(schedules) => schedules.length !== 0
			)
			// which row to push (default to current row)
			let pushToRow = currentRow
			if (firstNonEmptyCell !== -1 && currentRow > 0) {
				// now search in the existing rows, in the reverse order, where we have no schedules in the slot
				for (let j = pushToRow - 1; j >= 0; j--) {
					// check if all the slots are empty after the firstNonEmptyCell
					const allSlotsEmptyAfter = schedulesPerTripPerSlot[j]
						.slice(firstNonEmptyCell)
						.every((schedules) => !schedules.length)
					if (allSlotsEmptyAfter) {
						pushToRow = j
					}
				}
			}
			if (!schedulesPerTripPerSlot[pushToRow]) {
				schedulesPerTripPerSlot[pushToRow] = schedulesForThisTripPerSlot
			} else {
				// merge the schedules
				schedulesPerTripPerSlot[pushToRow] = schedulesPerTripPerSlot[
					pushToRow
				].map((schedules, i) =>
					schedules.concat(schedulesForThisTripPerSlot[i])
				)
			}
			// if it was pushed to the current row, move to next row
			// otherwise continue with this row
			if (pushToRow === currentRow) {
				currentRow += 1
			}
		}
		return schedulesPerTripPerSlot
	}
}

export const BOOKING_STATUS = [
	["not_assigned", "TSP Not Assigned"],
	["assigned_but_not_booked", "Assigned but Not Booked"],
	["booked", "Marked as Booked"],
	["missing_cab_details", "Booked but Missing Cab"],
].map(([id, name]) => ({ id: id, name }))

export interface ICabSchedulesFilters extends TSearchParams {
	date: Date
	transport_locations?: ILocation[]
	transport_service_providers?: Array<ITransportServiceProvider>
	transport_service_location_points?: Array<TTransportServiceLocationPoint>
	trip_destinations?: Array<TTripDestination>
	cab_types?: Array<ICabType>
	hide_past_trips?: boolean
	status?: (typeof BOOKING_STATUS)[number]
	owners?: Array<IUser>
}

export interface ICabSchedulesFiltersInLocationQuery extends TSearchParams {
	date?: string | null
	tls?: string[]
	tsps?: Array<string>
	tslp?: Array<string>
	ct?: Array<string>
	tds?: Array<string>
	hpt?: 0 | 1
	status?: string
	owners?: string[]
}

export function cabScheduleFilterParamsToLocationQuery(
	params: ICabSchedulesFilters
): ICabSchedulesFiltersInLocationQuery {
	const {
		date,
		q,
		transport_locations,
		transport_service_providers,
		transport_service_location_points,
		trip_destinations,
		cab_types,
		hide_past_trips,
		status,
		owners,
	} = params
	const filters: ICabSchedulesFiltersInLocationQuery = {}
	if (q) filters.q = q
	if (date) filters.date = dateToQuery(date)
	if (transport_locations && transport_locations.length)
		filters.tls = transport_locations.map((t) => `${t.id}_${t.name}`)
	if (transport_service_providers && transport_service_providers.length)
		filters.tsps = transport_service_providers.map((t) => `${t.id}_${t.name}`)
	if (
		transport_service_location_points &&
		transport_service_location_points.length
	)
		filters.tslp = transport_service_location_points.map(
			(t) => `${t.id}_${t.name}`
		)
	if (cab_types && cab_types.length)
		filters.ct = cab_types.map((t) => `${t.id}_${t.name}`)
	if (trip_destinations && trip_destinations.length)
		filters.tds = trip_destinations.map((t) => `${t.id}_${t.name}`)
	if (hide_past_trips) {
		filters.hpt = 1
	}
	if (owners && owners.length) {
		filters.owners = owners.map((t) => `${t.id}_${t.name}`)
	}
	if (status) filters.status = status.name

	return filters
}

export function cabSchedulesLocationQueryToParams(
	query: ICabSchedulesFiltersInLocationQuery
): ICabSchedulesFilters {
	const { date, q, tls, hpt, tsps, tslp, ct, tds, status, owners } = query
	const filters: ICabSchedulesFilters = {
		date: new Date(),
	}
	if (date) {
		filters.date = parseDateFromQuery(date)
	}
	if (q) filters.q = q
	if (tls && tls.length)
		filters.transport_locations = tls.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as ILocation
		})
	if (tsps && tsps.length)
		filters.transport_service_providers = tsps.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as ITransportServiceProvider
		})
	if (tslp && tslp.length)
		filters.transport_service_location_points = tslp.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as TTransportServiceLocationPoint
		})
	if (ct && ct.length)
		filters.cab_types = ct.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as ICabType
		})
	if (tds && tds.length)
		filters.trip_destinations = tds.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as TTripDestination
		})
	if (owners && owners.length)
		filters.owners = owners.map((t) => {
			const [id, ...names] = t.split("_")
			return {
				id: parseInt(id),
				name: names.join("_"),
			} as unknown as IUser
		})
	if (hpt && Number(hpt) === 1) {
		filters.hide_past_trips = true
	}
	if (status) {
		filters.status = BOOKING_STATUS.find((s) => s.id === status)
	}
	return filters
}

const CAB_SCHEDULE_HAS_COMPACT_FIT_KEY = "cs_icf"
const CAB_SCHEDULE_TRANSITION_WINDOW_SIZE_KEY = "cs_tws"
const CAB_SCHEDULE_SHOW_OPERATIONS_TEAM_KEY = "cs_sot"

function storeCompactFitStateToStorage(value: boolean) {
	Storage.put(CAB_SCHEDULE_HAS_COMPACT_FIT_KEY, Number(value))
}

function storeShowOperationsTeamToStorage(value: boolean) {
	Storage.put(CAB_SCHEDULE_SHOW_OPERATIONS_TEAM_KEY, Number(value))
}

function storeTransitionWindowSizeToStorage(value: number) {
	Storage.put(CAB_SCHEDULE_TRANSITION_WINDOW_SIZE_KEY, value)
}

function getHasCompactFitFromStorage(): boolean {
	return castStorageValueToBoolean(
		Storage.get(CAB_SCHEDULE_HAS_COMPACT_FIT_KEY, 1),
		true
	)
}

function getShowOperationsTeamFromStorage(): boolean {
	return castStorageValueToBoolean(
		Storage.get(CAB_SCHEDULE_SHOW_OPERATIONS_TEAM_KEY, 1),
		true
	)
}

function castStorageValueToBoolean(value: unknown, defaultValue = false) {
	if (["0", "1"].indexOf(value as string) === -1) {
		// default or when invalid value
		return defaultValue
	}
	return Boolean(Number(value))
}

type TAllowedWindowSizes = 1 | 3 | 5 | 7 | 10

export const TransitionWindowOptions: Array<TAllowedWindowSizes> = [
	1, 3, 5, 7, 10,
]

function ensureValueInAllowedOptions(value: unknown): TAllowedWindowSizes {
	if (TransitionWindowOptions.indexOf(Number(value) as never) === -1) {
		return 1
	}
	return Number(value) as TAllowedWindowSizes
}

function getTransitionWindowSizeFromStorage(): TAllowedWindowSizes {
	const size = Storage.get(CAB_SCHEDULE_TRANSITION_WINDOW_SIZE_KEY, 1)
	return ensureValueInAllowedOptions(size)
}

export function useCalendarUIPreferences() {
	const initialPreferences = useMemo(() => {
		return {
			hasCompactFit: getHasCompactFitFromStorage(),
			transitionWindowSize: getTransitionWindowSizeFromStorage(),
			showOperationsTeam: getShowOperationsTeamFromStorage(),
		}
	}, [])
	const [calendarUIPreferences, dispatch] = useReducer<
		React.Reducer<
			typeof initialPreferences,
			| {
					type: "TOGGLE_COMPACT_FIT" | "TOGGLE_OPERATIONS_TEAM_VISIBILITY"
			  }
			| { type: "CHANGE_TRANSITION_WINDOW_SIZE"; payload: TAllowedWindowSizes }
		>
	>((state, action) => {
		switch (action.type) {
			case "TOGGLE_COMPACT_FIT":
				return {
					...state,
					hasCompactFit: !state.hasCompactFit,
				}
			case "TOGGLE_OPERATIONS_TEAM_VISIBILITY":
				return {
					...state,
					showOperationsTeam: !state.showOperationsTeam,
				}
			case "CHANGE_TRANSITION_WINDOW_SIZE":
				return {
					...state,
					transitionWindowSize: action.payload,
				}
			default:
				return state
		}
	}, initialPreferences)

	const toggleCompactFit = useCallback(() => {
		dispatch({ type: "TOGGLE_COMPACT_FIT" })
	}, [])
	const toggleOperationsTeamVisibility = useCallback(() => {
		dispatch({ type: "TOGGLE_OPERATIONS_TEAM_VISIBILITY" })
	}, [])
	const changeTransitionWindowSize = useCallback((value: unknown) => {
		const size = ensureValueInAllowedOptions(value)
		dispatch({
			type: "CHANGE_TRANSITION_WINDOW_SIZE",
			payload: size,
		})
	}, [])
	// store the preferences into local storage
	useEffect(() => {
		storeCompactFitStateToStorage(calendarUIPreferences.hasCompactFit)
		storeTransitionWindowSizeToStorage(
			calendarUIPreferences.transitionWindowSize
		)
		storeShowOperationsTeamToStorage(calendarUIPreferences.showOperationsTeam)
	}, [calendarUIPreferences])
	return {
		calendarUIPreferences,
		toggleCompactFit,
		changeTransitionWindowSize,
		toggleOperationsTeamVisibility,
	}
}

export function getStartDateTimeOfCabSchedule(
	cabSchedule: Pick<
		ICabSchedule,
		"start_date" | "start_time_local" | "start_date_local"
	>
) {
	let startDateTime = utcTimestampToLocalDate(cabSchedule.start_date)
	if (cabSchedule.start_time_local) {
		startDateTime = setTimeFrom(
			localOrUtcTimestampToLocalDate(
				cabSchedule.start_date_local,
				cabSchedule.start_date
			),
			parseDate(cabSchedule.start_time_local, "HH:mm:ss")
		)
	} else if (cabSchedule.start_date_local) {
		return undefined
	}

	if (formatDate(startDateTime, "HH:mm") === "00:01") {
		return undefined
	}

	return startDateTime
}

export function getEndDateTimeOfCabSchedule(
	cabSchedule: Pick<
		ICabSchedule,
		"end_date" | "end_date_local" | "end_time_local"
	>
) {
	const { end_time_local, end_date_local, end_date } = cabSchedule
	if (end_time_local) {
		return setTimeFrom(
			localOrUtcTimestampToLocalDate(end_date_local, end_date),
			parseDate(end_time_local, "HH:mm:ss")
		)
	}
	if (end_date_local) {
		return undefined
	}
	return utcTimestampToLocalDate(end_date)
}
