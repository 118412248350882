import { useSearch } from "@sembark-travel/ui/list"
import { useLocationQuery, ButtonLink } from "@sembark-travel/ui/router"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { RoomTypesList } from "./../../../RoomTypes"
import { generatePath } from "./../../../router-utils"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function RoomTypes() {
	const [query, setQuery] = useLocationQuery()
	const [params, setParams] = useSearch(query)
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	return (
		<>
			<Helmet>
				<title>Room Types</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.UPLOAD_BULK_HOTELS}>
				<RoomTypesList
					params={params}
					setParams={setParams}
					actions={
						<ButtonLink to={generatePath("/room-types/new")} status="primary">
							New Room Type
						</ButtonLink>
					}
				/>
			</ForbidUnlessAuthorized>
		</>
	)
}
