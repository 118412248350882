import {
	Box,
	Stack,
	Button,
	Heading,
	Text,
	Icons,
	Inline,
	Popover,
	Container,
	Table,
	joinAttributes,
	RelativeTime,
	Time,
	TimeDuration,
	SelectInput,
	MoneySum,
	Spinner,
	Money,
	Tabs,
	TabsList,
	TabItem,
	TabContent,
	Badge,
	Alert,
} from "@sembark-travel/ui/base"
import { Link, useLocationQuery, XHRLink } from "@sembark-travel/ui/router"
import {
	TSearchParams,
	useSearch,
	Search,
	areAdvancedFiltersAppliedDefault,
	ListView,
} from "@sembark-travel/ui/list"
import {
	utcTimestampToLocalDate,
	dateToQuery,
	dateToUTCString,
	parseDateFromQuery,
	startOf,
	endOf,
	subtractUnit,
	formatDate,
	addUnit,
	isAfter,
	utcTimestampToLocalDateTimeString,
	isBetween,
} from "@sembark-travel/datetime-utils"
import { IListResponse, XHRInstance } from "@sembark-travel/xhr"
import { useEffect, useMemo } from "react"
import { $PropertyType } from "utility-types"
import config from "../config"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath } from "../router-utils"
import { SelectTripDestination } from "../TripDestinations"
import { TTripDestination } from "../TripDestinations"
import { SelectTripOwners } from "../Trips"
import { SelectTripSources } from "../TripSources"
import { ITripSource } from "../TripSources"
import { IUser } from "../Users"
import {
	TTripSalesReport,
	TTripSalesReportByType,
	useTripSalesReportStats,
	TStats,
} from "./types"
import { SelectField } from "@sembark-travel/ui/form"
import { formatMoneyByIntl, isZeroMoney } from "@sembark-travel/money"
import { useFunctionalCurrencyOfTenant } from "../Currencies"
import { PERMISSIONS, useCheckPermissions } from "../Auth"

function XHR(xhr: XHRInstance) {
	return {
		async get(params?: unknown) {
			return xhr
				.get<IListResponse<TTripSalesReport>>("/trip-sales-reports", { params })
				.then(({ data }) => data)
		},
		async getByType(type: TFilters["type"], params: unknown) {
			return xhr
				.get<
					IListResponse<TTripSalesReportByType>
				>(`/trip-sales-reports/by-${type}`, { params })
				.then(({ data }) => data)
		},
	}
}

type TFilters = TSearchParams & {
	after: Date
	before: Date
	destinations?: Array<TTripDestination>
	trip_sources?: Array<ITripSource>
	sales_team?: Array<Pick<IUser, "id" | "name">>
	intervalType: "month" | "week" | "day"
	type: "all" | "users" | "destinations" | "sources"
}

type TFiltersInQuery = TSearchParams & {
	after?: string
	before?: string
	destinations?: Array<string>
	trip_sources?: Array<string>
	sales_team?: Array<string>
	intervalType: "month" | "week" | "day"
	type?: "all" | "users" | "destinations" | "sources"
}

function filtersToQuery(filters: TFilters): TFiltersInQuery {
	const {
		q,
		after,
		before,
		page,
		destinations,
		trip_sources,
		sales_team,
		intervalType,
		type,
	} = filters
	const query: TFiltersInQuery = {
		after: dateToQuery(after || startOf(new Date(), "month")),
		before: dateToQuery(before || endOf(new Date(), "month")),
		intervalType: intervalType || "month",
		type: type || "all",
	}
	if (q) {
		query.q = q
	}
	if (page) query.page = page
	if (destinations) {
		query.destinations = destinations.map((d) => `${d.id}_${d.name}`)
	}
	if (trip_sources) {
		query.trip_sources = trip_sources.map((d) => `${d.id}_${d.name}`)
	}
	if (sales_team) {
		query.sales_team = sales_team.map((d) => `${d.id}_${d.name}`)
	}
	return query
}

function queryToFilters(query: TFiltersInQuery): TFilters {
	const {
		q,
		page,
		destinations,
		trip_sources,
		sales_team,
		after,
		before,
		intervalType,
		type,
	} = query
	const filters: TFilters = {
		after: after
			? startOf(parseDateFromQuery(after), "day")
			: startOf(new Date(), intervalType || "month"),
		before: before
			? endOf(parseDateFromQuery(before), "day")
			: endOf(new Date(), intervalType || "month"),
		intervalType: intervalType || "month",
		type: type || "all",
	}
	if (q) {
		filters.q = q
	}
	if (page) filters.page = page
	if (destinations) {
		filters.destinations = destinations.map((d) => {
			const [id, ...name] = d.split("_")
			return {
				id,
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<TFilters, "destinations">
	}
	if (trip_sources) {
		filters.trip_sources = trip_sources.map((d) => {
			const [id, ...name] = d.split("_")
			return {
				id,
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<TFilters, "trip_sources">
	}
	if (sales_team) {
		filters.sales_team = sales_team.map((d) => {
			const [id, ...name] = d.split("_")
			return {
				id,
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<TFilters, "sales_team">
	}
	return filters
}

export function TripSalesReportsList() {
	const [query, setQuery] = useLocationQuery({
		toQuery: filtersToQuery,
		fromQuery: queryToFilters,
	})
	const [params, setParams] = useSearch<TFilters>(query)
	const requestParams = useMemo(() => {
		return {
			q: params.q || null,
			destinations: (params.destinations || []).map((d) => d.id),
			sources: (params.trip_sources || []).map((d) => d.id),
			sales_team: (params.sales_team || []).map((d) => d.id),
			after: dateToUTCString(params.after),
			before: dateToUTCString(params.before),
		}
	}, [params])
	useEffect(() => {
		setQuery(params)
	}, [params, setQuery])
	const { stats, error: errorInStats } = useTripSalesReportStats({
		params: requestParams,
	})
	const { hasAnyPermission } = useCheckPermissions()
	return (
		<Box>
			<Search
				title="Sales Report"
				initialParams={params}
				onSearch={setParams}
				Filters={Filters}
				resetParams={(params) => ({
					q: "",
					after: params.after,
					before: params.before,
					intervalType: params.intervalType,
					type: params.type,
				})}
				areAdvancedFiltersApplied={(params) => {
					const { after, before, intervalType, type, ...others } = params
					return areAdvancedFiltersAppliedDefault(others)
				}}
				actions={({ setSearchParams, searchParams }) => (
					<Inline alignItems="center">
						<Button
							onClick={() => {
								const after = startOf(
									subtractUnit(
										searchParams.after,
										1,
										searchParams.intervalType
									),
									searchParams.intervalType
								)
								setSearchParams({
									after,
									before: endOf(after, searchParams.intervalType),
								})
							}}
						>
							<Icons.ChevronDown rotate="90" />
						</Button>
						<Box paddingX="2">
							<SelectInput
								value={searchParams.intervalType}
								onChange={(e) => {
									const type = (e.currentTarget.value ||
										"month") as typeof searchParams.intervalType
									setSearchParams({
										intervalType: type,
										after: startOf(new Date(), type),
										before: endOf(new Date(), type),
									})
								}}
							>
								<option value="month">Month</option>
								<option value="week">Week</option>
								<option value="day">Day</option>
							</SelectInput>
						</Box>
						<Button
							disabled={isAfter(searchParams.before, new Date())}
							onClick={() => {
								const after = startOf(
									addUnit(searchParams.after, 1, searchParams.intervalType),
									searchParams.intervalType
								)
								setSearchParams({
									after,
									before: endOf(after, searchParams.intervalType),
								})
							}}
						>
							<Icons.ChevronDown rotate="270" />
						</Button>
					</Inline>
				)}
			>
				{({ searchParams, setSearchParamValue }) => (
					<Stack gap="6" paddingY="4">
						<Container>
							<Stack gap="4">
								<IntervalTitle
									after={params.after}
									before={params.before}
									intervalType={params.intervalType}
								/>
								{errorInStats ? (
									<Alert status="error">
										{errorInStats.message || "Something went wrong."}
									</Alert>
								) : null}
								{stats ? (
									<TripSalesReportStats stats={stats} />
								) : (
									<Box
										paddingY="8"
										bgColor="default"
										width="full"
										textAlign="center"
										rounded="md"
									>
										<Spinner alignCenter />
									</Box>
								)}
							</Stack>
						</Container>
						{stats && (stats.total_amount?.length || stats.total_queries) ? (
							<Tabs>
								{hasAnyPermission(PERMISSIONS.MANAGE_USERS) ? (
									<TabsList>
										<TabItem
											active={searchParams.type === "all"}
											onClick={() => {
												setSearchParamValue("type", "all")
											}}
										>
											Trips
										</TabItem>
										<TabItem
											active={searchParams.type === "users"}
											onClick={() => {
												setSearchParamValue("type", "users")
											}}
										>
											Sales Team
										</TabItem>
										<TabItem
											active={searchParams.type === "destinations"}
											onClick={() => {
												setSearchParamValue("type", "destinations")
											}}
										>
											Destinations
										</TabItem>
										<TabItem
											active={searchParams.type === "sources"}
											onClick={() => {
												setSearchParamValue("type", "sources")
											}}
										>
											Trip Sources
										</TabItem>
									</TabsList>
								) : null}
								<TabContent>
									{params.type === "all" ? (
										<TripsListView
											params={params}
											setParams={setParams}
											requestParams={requestParams}
										/>
									) : params.type === "users" ||
									  params.type === "destinations" ||
									  params.type === "sources" ? (
										<TypeWiseListView
											type={params.type}
											params={params}
											setParams={setParams}
											requestParams={requestParams}
											setSearchParamValue={setSearchParamValue}
										/>
									) : null}
								</TabContent>
							</Tabs>
						) : null}
					</Stack>
				)}
			</Search>
		</Box>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<SelectField
				select={SelectTripDestination}
				name="destinations"
				label="Destinations"
				multiple
				fetchOnMount
			/>
			<SelectField
				select={SelectTripSources}
				name="trip_sources"
				label="Trip Sources"
				multiple
				fetchOnMount
			/>
			<SelectField
				select={SelectTripOwners}
				name="sales_team"
				label="Sales Team"
				multiple
				fetchOnMount
			/>
		</Stack>
	)
}

function TripsListView({
	params,
	setParams,
	requestParams,
}: {
	params: TFilters
	setParams: (params: TFilters) => void
	requestParams: Record<string, unknown>
}) {
	return (
		<ListView<TTripSalesReport, TFilters>
			pageKey={`trip-sales-reports-all`}
			params={params}
			onPageChange={(page) => setParams({ ...params, page })}
			fetch={(xhr, { trip_sources, ...params }) => {
				return XHR(xhr).get({
					...params,
					destinations: (params.destinations || []).map((d) => d.id),
					sources: (trip_sources || []).map((d) => d.id),
					sales_team: (params.sales_team || []).map((d) => d.id),
					after: dateToUTCString(params.after),
					before: dateToUTCString(params.before),
				})
			}}
			actions={
				<Button
					as={XHRLink}
					href="/trip-sales-reports/download"
					title="Download Report as Excel/CSV File"
					level="primary"
					size="sm"
					query={{
						...requestParams,
						timezone_offset: config.timezoneOffset,
					}}
					download
				>
					<Icons.DocumentDownload /> Full Report
				</Button>
			}
		>
			{({ items }) => {
				return (
					<Table
						hover
						responsive
						headers={[
							"Id",
							"Guest",
							"Basic Details",
							"Sales Team",
							"Date",
							"Amount",
							"Tax",
						]}
						bordered
						alignCols={{ 4: "right", 5: "right" }}
						rows={items.map((trip) => [
							<Link
								color="accent"
								to={generatePath("/trips/:tripId", {
									tripId: trip.id.toString(),
								})}
							>
								{trip.id}
							</Link>,
							trip.tourist ? (
								<Box>
									<Box display="inlineBlock" style={{ maxWidth: "150px" }}>
										<Text title={trip.tourist.name} textOverflow="truncate">
											{trip.tourist.name}
										</Text>
									</Box>
									<PhoneNumber
										value={trip.tourist.phone_numbers}
										iconOnly
										marginLeft="2"
									/>
									<Email value={trip.tourist.email} iconOnly marginLeft="2" />
								</Box>
							) : (
								"N/A"
							),
							<Box>
								{joinAttributes(
									trip.destinations.map((d) => d.short_name).join(", "),
									<Box as="span" title={trip.trip_source.name}>
										{trip.trip_source.short_name}
									</Box>,
									<Time
										timestamp={trip.start_date}
										localTimestamp={trip.start_date_local}
										format="DD MMM"
									/>,
									<TimeDuration days={trip.days}>{trip.days}D</TimeDuration>
								)}
							</Box>,
							trip.sales_team?.length ? (
								<Box>{trip.sales_team.map((s) => s.name).join(", ")}</Box>
							) : null,
							trip.converted_at ? (
								<Time
									value={utcTimestampToLocalDate(trip.converted_at)}
									format="D MMM"
								/>
							) : null,
							<SalesAmount trip={trip} />,
							trip.latest_given_quote ? (
								<Box
									title={
										trip.tax_amount
											? formatMoneyByIntl(trip.tax_amount, {
													showCurrency: true,
												})
											: undefined
									}
								>
									{trip.latest_given_quote.gst_included ? (
										trip.latest_given_quote.tax_percentage ? (
											<Percentage
												value={trip.latest_given_quote.tax_percentage}
												fixedPoint={false}
											/>
										) : (
											"inc"
										)
									) : (
										"exc"
									)}
								</Box>
							) : null,
						])}
					/>
				)
			}}
		</ListView>
	)
}

function TypeWiseListView({
	type,
	params,
	setParams,
	setSearchParamValue,
	requestParams,
}: {
	type: TFilters["type"]
	params: TFilters
	setParams: (params: TFilters) => void
	requestParams: Record<string, unknown>
	setSearchParamValue: <K extends keyof TFilters>(
		param: K,
		value: TFilters[K]
	) => void
}) {
	return (
		<ListView<TTripSalesReportByType, TFilters>
			pageKey={`trip-sales-reports-${type}`}
			params={params}
			onPageChange={(page) => setParams({ ...params, page })}
			fetch={(xhr, { trip_sources, ...params }) => {
				return XHR(xhr).getByType(type, {
					...params,
					destinations: (params.destinations || []).map((d) => d.id),
					sources: (trip_sources || []).map((d) => d.id),
					sales_team: (params.sales_team || []).map((d) => d.id),
					after: dateToUTCString(params.after),
					before: dateToUTCString(params.before),
				})
			}}
			actions={
				type !== "sources" ? (
					<Button
						as={XHRLink}
						href={`/trip-sales-reports/by-${type}/download`}
						title="Download Report as Excel/CSV File"
						level="primary"
						size="sm"
						query={{
							...requestParams,
							timezone_offset: config.timezoneOffset,
						}}
						download
					>
						<Icons.DocumentDownload /> Full Report
					</Button>
				) : null
			}
		>
			{({ items }) => {
				return (
					<Table
						bordered
						hover
						responsive
						headers={["Name", "Leads"]
							.concat(type === "users" ? ["Total Quotes"] : [])
							.concat([
								"Conversions",
								"Conv. %",
								"Drops",
								"Pax",
								"Revenue",
								"Profit",
								"Pft %",
							])}
						alignCols={{
							1: "right",
							2: "right",
							3: "right",
							4: "right",
							5: "right",
							6: "right",
							7: "right",
							8: "right",
							9: "right",
						}}
						rows={items.map(
							({
								id,
								name,
								deleted_at,
								total_queries,
								total_quotes,
								average_quotes,
								total_conversion,
								total_dropped,
								total_pax,
								total_amount,
								total_profit,
								profit_percentage,
								conversion_percentage,
							}) => {
								function applyTypeFilter() {
									switch (type) {
										case "users":
											setSearchParamValue("sales_team", [
												{
													id,
													name,
												},
											])
											break
										case "destinations":
											setSearchParamValue("destinations", [
												{
													id,
													name,
												} as TTripDestination,
											])
											break
										case "sources":
											setSearchParamValue("trip_sources", [
												{
													id,
													name,
												} as ITripSource,
											])
											break
									}
								}
								return (
									[
										<Stack>
											<Link
												to={
													type === "users"
														? generatePath("/users/:userId", {
																userId: String(id),
															})
														: type === "destinations"
															? generatePath(
																	"/trip-destinations/:tripDestinationId",
																	{
																		tripDestinationId: String(id),
																	}
																)
															: generatePath("/trip-sources/:tripSourceId", {
																	tripSourceId: String(id),
																})
												}
											>
												{name}
											</Link>
											{deleted_at &&
											isBetween(
												utcTimestampToLocalDate(deleted_at),
												params.after,
												params.before
											) ? (
												<Box>
													<Badge
														danger
														title={utcTimestampToLocalDateTimeString(
															deleted_at
														)}
													>
														Disabled
													</Badge>
												</Box>
											) : null}
										</Stack>,
										<Link
											to={generatePath("/trips")}
											query={{
												// TODO: Use the filtersToQuery function from trips modules
												ca: dateToQuery(params.after),
												cb: dateToQuery(params.before),
												status: "all",
												owners: type === "users" ? [`${id}_${name}`] : [],
												destinations:
													type === "destinations" ? [`${id}_${name}`] : [],
												sources: type === "sources" ? [`${id}_${name}`] : [],
											}}
										>
											{total_queries}
										</Link>,
									] as Array<React.ReactNode>
								)
									.concat(
										type === "users"
											? [
													total_quotes !== undefined &&
													total_quotes !== null ? (
														<Text
															title={
																average_quotes
																	? `Avg. Quotes: ${average_quotes}`
																	: undefined
															}
														>
															{total_quotes}
														</Text>
													) : null,
												]
											: []
									)
									.concat([
										total_conversion ? (
											<Button
												inline
												onClick={() => {
													applyTypeFilter()
													setSearchParamValue("type", "all")
												}}
											>
												{total_conversion}
											</Button>
										) : (
											0
										),
										conversion_percentage !== null &&
										conversion_percentage !== undefined ? (
											<Percentage value={conversion_percentage} />
										) : null,
										total_dropped ? (
											<Button
												inline
												onClick={() => {
													applyTypeFilter()
													setSearchParamValue("type", "all")
												}}
											>
												{total_dropped}
											</Button>
										) : (
											0
										),
										total_pax,
										total_amount?.length ? (
											<MoneySum
												money={total_amount}
												roundOff
												separator={
													<>
														<br />+{" "}
													</>
												}
											/>
										) : (
											"-"
										),
										total_profit?.length ? (
											<MoneySum
												money={total_profit}
												roundOff
												separator={
													<>
														<br />+{" "}
													</>
												}
											/>
										) : (
											"-"
										),
										profit_percentage !== null &&
										profit_percentage !== undefined ? (
											<Percentage value={profit_percentage} />
										) : null,
									])
							}
						)}
					/>
				)
			}}
		</ListView>
	)
}

function SalesAmount({ trip }: { trip: TTripSalesReport }) {
	const {
		cancellation_charges,
		cancellation_reason,
		refunding_payments_amount,
		latest_given_quote,
		revenue_amount,
		converted_at,
		flights_amount,
	} = trip
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	return (
		<Inline gap="2" alignItems="center" inline>
			{cancellation_reason ? (
				<Popover
					placement="left"
					trigger="click"
					content={
						<Box maxWidth="sm">
							<Stack
								bgColor="warning"
								color="warning"
								paddingY="2"
								paddingX="4"
								gap="1"
							>
								<Heading fontSize="md">Trip Canceled</Heading>
								<Text>
									by {cancellation_reason.created_by.name}
									{" - "}
									<RelativeTime
										value={utcTimestampToLocalDate(
											cancellation_reason.created_at
										)}
									/>
								</Text>
								{converted_at ? (
									<Text fontSize="sm">
										Converted on <Time timestamp={converted_at} />
									</Text>
								) : null}
							</Stack>
							<Box>
								<Inline gap="6" flexWrap="wrap" padding="4">
									{latest_given_quote ? (
										<Stack gap="1">
											<Text fontSize="sm" color="muted">
												Package
											</Text>
											<Money
												amount={latest_given_quote.given_price}
												currency={latest_given_quote.currency}
												fontWeight="semibold"
												fontSize="lg"
											/>
										</Stack>
									) : null}
									{cancellation_charges ? (
										<Stack gap="1">
											<Text fontSize="sm" color="muted">
												Chargable
											</Text>
											<Money
												amount={cancellation_charges}
												currency={
													latest_given_quote?.currency || functionalCurrency
												}
												fontWeight="semibold"
												fontSize="lg"
												color="success"
											/>
										</Stack>
									) : null}
									{refunding_payments_amount ? (
										<Stack gap="1">
											<Text fontSize="sm" color="muted">
												Refund
											</Text>
											<Money
												amount={refunding_payments_amount}
												currency={
													latest_given_quote?.currency || functionalCurrency
												}
												fontWeight="semibold"
												fontSize="lg"
												color="danger"
											/>
										</Stack>
									) : null}
								</Inline>
								<Stack gap="4" padding="4" borderTopWidth="1">
									<Stack gap="1">
										<Text fontSize="sm" color="muted">
											Reason
										</Text>
										<Text fontWeight="semibold" color="warning">
											{cancellation_reason.reason}
										</Text>
									</Stack>
									{cancellation_reason.comments ? (
										<Stack gap="1">
											<Text fontSize="sm" color="muted">
												Comments
											</Text>
											<Text whiteSpace="preserveLine">
												{cancellation_reason.comments}
											</Text>
										</Stack>
									) : null}
								</Stack>
							</Box>
						</Box>
					}
				>
					<Button level="tertiary" status="warning" inline>
						<Icons.Ban color="warning" />
					</Button>
				</Popover>
			) : null}
			<Stack gap="1">
				<Money showCurrency money={revenue_amount} roundOff />
				{flights_amount && !isZeroMoney(flights_amount) ? (
					<Box fontSize="sm" color="muted">
						<Icons.Airplane size="3" />{" "}
						<Money money={flights_amount} roundOff />
					</Box>
				) : null}
			</Stack>
		</Inline>
	)
}

export function TripSalesReportStats({ stats }: { stats: TStats }) {
	const {
		total_amount,
		total_conversion,
		total_dropped,
		total_queries,
		total_quotes,
	} = stats
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	return (
		<Inline
			bgColor="default"
			roundedRight="lg"
			borderLeftWidth="4"
			borderColor="accent"
			paddingY="2"
			justifyContent="between"
			alignItems="stretch"
			collapseBelow="md"
			gap="4"
		>
			<Inline alignItems="stretch" collapseBelow="sm">
				<Box
					paddingX={{ xs: "4", sm: "8" }}
					paddingY="4"
					borderRightWidth={{ sm: "4" }}
					borderBottomWidth={{ xs: "4", sm: "0" }}
					borderColor="default"
				>
					<Box fontWeight="semibold" color="muted">
						Revenue
					</Box>
					<Box fontWeight="semibold" fontSize="3xl">
						{total_amount?.length ? (
							<MoneySum
								money={total_amount}
								separator={
									<>
										{" "}
										+<br />
									</>
								}
								roundOff
							/>
						) : (
							<Money amount={0} currency={functionalCurrency} />
						)}
					</Box>
				</Box>
				<Inline
					gap={{ xs: "4", sm: "8" }}
					paddingY="4"
					paddingX={{ xs: "4", sm: "8" }}
				>
					<Box>
						<Box fontWeight="semibold" color="muted">
							Queries
						</Box>
						<Box fontWeight="semibold" fontSize="3xl">
							{total_queries}
						</Box>
					</Box>
					<Box>
						<Box fontWeight="semibold" color="muted">
							Quotes
						</Box>
						<Box fontWeight="semibold" fontSize="3xl">
							{total_quotes}
						</Box>
					</Box>
					<Box>
						<Box fontWeight="semibold" color="muted">
							Conversion
						</Box>
						<Box fontWeight="semibold" fontSize="3xl">
							{total_conversion}
						</Box>
					</Box>
					{total_dropped ? (
						<Box>
							<Box fontWeight="semibold" color="muted">
								Dropped
							</Box>
							<Box fontWeight="semibold" fontSize="3xl">
								{total_dropped}
							</Box>
						</Box>
					) : null}
				</Inline>
			</Inline>
		</Inline>
	)
}

function IntervalTitle({
	after,
	before,
	intervalType,
}: Pick<TFilters, "after" | "before" | "intervalType">) {
	const title = useMemo(() => {
		switch (intervalType) {
			case "day":
				return formatDate(after, "ddd D MMM, YYYY")
			case "week":
				return `${formatDate(after, "ddd D MMM")} - ${formatDate(
					before,
					"ddd D MMM"
				)}`
			case "month":
				return `${formatDate(after, "MMMM YYYY")}`
		}
	}, [after, before, intervalType])
	return (
		<Text
			fontWeight="semibold"
			fontSize="xl"
			title={`${formatDate(after, "ddd D MMM, YYYY")} - ${formatDate(
				before,
				"ddd D MMM, YYYY"
			)}`}
		>
			{title}
		</Text>
	)
}

function Percentage({
	value,
	fixedPoint = true,
}: {
	value: number
	fixedPoint?: boolean
}) {
	value = Number(value)
	return (
		<Text as="span">
			{fixedPoint ? value.toFixed(2) : value}
			<Box as="span" fontSize="xs" color="muted">
				%
			</Box>
		</Text>
	)
}
