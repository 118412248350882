import { Alert, useId, Spinner } from "@sembark-travel/ui/base"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import {
	addUnit,
	localOrUtcTimestampToLocalDate,
	startOf,
} from "@sembark-travel/datetime-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import React from "react"
import { ScheduleForm } from "./NewItem"
import { ICabSchedule, useScheduleDetails } from "./store"
import { ITrip } from "../Trips"
import {
	getEndDateTimeOfCabSchedule,
	getStartDateTimeOfCabSchedule,
} from "./utils"

function XHR(xhr: XHRInstance) {
	return {
		async editSchedule(
			scheduleId: string | number,
			data: unknown
		): Promise<Array<ICabSchedule>> {
			return xhr
				.put(`/cab-schedules/${scheduleId}`, data)
				.then((resp) => resp.data.data)
		},
	}
}

type EditScheduleProps = {
	scheduleId: number | string
	onCancel: () => void
	onSuccess?: () => void
	readOnlyTrip?: boolean
}

export function EditSchedule({
	scheduleId,
	onCancel,
	onSuccess,
	readOnlyTrip,
}: EditScheduleProps) {
	// ID for always fetch
	const uuid = useId()
	const xhr = useXHR()
	const { data, error } = useScheduleDetails(
		scheduleId,
		undefined,
		{
			revalidateOnFocus: false,
		},
		`${scheduleId}_${uuid}`
	)
	if (!data && error) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!data) {
		return <Spinner padding="4" alignCenter />
	}
	const {
		id,
		start_date,
		start_date_local,
		start_time_local,
		end_date,
		end_date_local,
		end_time_local,
		transport_service,
		trip,
		cabs,
		remarks,
		comments,
	} = data.data
	const pickup = (() => {
		const startDateTime = getStartDateTimeOfCabSchedule({
			start_date,
			start_date_local,
			start_time_local,
		})
		return (
			startDateTime ||
			addUnit(
				startOf(
					localOrUtcTimestampToLocalDate(start_date_local, start_date),
					"day"
				),
				1,
				"minute"
			)
		).toISOString()
	})()
	const drop = (() => {
		const endDateTime = getEndDateTimeOfCabSchedule({
			end_date,
			end_date_local,
			end_time_local,
		})
		return (
			endDateTime || localOrUtcTimestampToLocalDate(end_date_local, end_date)
		).toISOString()
	})()
	return (
		<ScheduleForm
			remarks={remarks}
			comments={comments}
			pickup={pickup}
			drop={drop}
			transportService={transport_service}
			trip={trip as never as ITrip}
			cabs={cabs}
			onSubmit={(...args) => XHR(xhr).editSchedule(id, ...args)}
			onSuccess={onSuccess}
			onCancel={onCancel}
			readOnlyTrip={readOnlyTrip}
		/>
	)
}

export function EditScheduleInDialog({
	children,
	...props
}: {
	children: (props: { onEdit: () => void }) => React.ReactNode
} & Omit<EditScheduleProps, "onCancel">) {
	const [isOpen, show, hide] = useDialog()
	return (
		<>
			{children({ onEdit: () => show() })}
			<Dialog open={isOpen} onClose={hide} title="Edit Schedule" xl>
				<Dialog.Body>
					<EditSchedule
						readOnlyTrip
						{...props}
						onSuccess={() => {
							hide()
							props.onSuccess?.()
						}}
						onCancel={hide}
					/>
				</Dialog.Body>
			</Dialog>
		</>
	)
}

export function EditSchedulePage({
	scheduleId,
	...props
}: { scheduleId: string } & Omit<EditScheduleProps, "schedule">) {
	return <EditSchedule scheduleId={scheduleId} readOnlyTrip {...props} />
}
