import { Box, Text, Container, Button, Icons } from "@sembark-travel/ui/base"

import { AppUpdater } from "./AppInstaller"
import config from "./config"

const YEAR = new Date().getFullYear()

export function AppFooter() {
	return (
		<Box as="footer" borderTopWidth="1" color="muted">
			<Container fluid>
				<Box paddingY="4">
					<Text as="span">
						&copy; 2019-{YEAR} {config.appTitle}. All rights reserved
					</Text>
					{" • "}
					<Text as="span">
						v{config.appVersion}
						{config.appEnv !== "production" ? (
							<Box
								as="sub"
								bgColor="warning"
								borderWidth="1"
								borderColor="warning"
								rounded="md"
								paddingX="1"
								color="warning"
							>
								{config.appEnv}-{config.sha}
							</Box>
						) : null}
					</Text>
					{" • "}
					<Text as="span">
						Tz: {config.timezone} [{config.timezoneOffsetString}]
					</Text>
					{" • "}
					<AppUpdater>
						{({ updateApp, isUpdating, isDownloadingUpdate }) => (
							<Button
								title="Force Update"
								size="sm"
								level="tertiary"
								onClick={() => updateApp()}
								disabled={isUpdating || isDownloadingUpdate}
							>
								{isUpdating || isDownloadingUpdate ? (
									<Icons.Refresh spin />
								) : (
									<Icons.Upload />
								)}
							</Button>
						)}
					</AppUpdater>
				</Box>
			</Container>
		</Box>
	)
}
