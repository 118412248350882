import { number as validateNumber, string as validateString } from "yup"
import pluralize from "pluralize"
import { parsePhoneNumber as parsePhoneNumberInput } from "react-phone-number-input/input"

/**
 * Check for a file size (KB)
 */
export function checkFileSize(max: number) {
	return function checkFilesForSize(files?: unknown | File | [File]): boolean {
		let valid = true
		if (files) {
			if (!Array.isArray(files)) {
				files = [files]
			}
			if (Array.isArray(files)) {
				files.every((file) => {
					const size = file.size / 1024
					if (size > max) {
						valid = false
					}
					return valid
				})
			}
		}
		return valid
	}
}

export function checkFileTypes(types: Array<string>) {
	return function checkTypesForFiles(files?: unknown | File | [File]): boolean {
		let valid = true
		if (files) {
			if (!Array.isArray(files)) {
				files = [files]
			}
			if (Array.isArray(files)) {
				files.every((file) => {
					if (!types.includes(file.type)) {
						valid = false
					}
					return valid
				})
			}
		}
		return valid
	}
}

/**
 * Helper to validate the numbers that can be empty string
 */
export function EmptyNumberValidator(message = "Please enter a valid number") {
	return validateNumber()
		.transform(function (value, originalValue) {
			if (this.isType(value)) return value
			if (!originalValue || !originalValue.trim()) {
				return null
			}
			return originalValue
		})
		.nullable(true)
		.typeError(message)
}

export function PincodeValidator(label = "Pincode Number") {
	return validateString().typeError(`${label} should be a numeric value`)
}

export function DigitsValidator(digits: number, label = "Field Value") {
	return EmptyNumberValidator(`${label} should be a numeric value`)
		.typeError(`${label} should be a numeric value`)
		.test({
			name: `${digits}-digits-${label}`,
			test: (value: number | string | null | undefined) => {
				const currentLength = value ? String(value).trim().length : 0
				if (currentLength === 0) {
					// required attribute should handle the empty case
					return true
				}
				return currentLength === digits
			},
			message: function test(params) {
				const currentLength = params.value
					? String(params.value).trim().length
					: 0
				return `${label} must of ${digits} ${pluralize("digit", digits)}${
					currentLength > 0
						? ` (entered ${currentLength} ${pluralize("digit", currentLength)})`
						: ""
				}`
			},
		})
}

export function getEditablePhoneNumber(
	phone_number: string,
	defaultCountryCode = "IN"
) {
	try {
		const n = parsePhoneNumberInput(phone_number)
		if (!n) throw new Error(`Can not parse phone number ${phone_number}`)
		return {
			number: n.nationalNumber,
			phone_number: n.number.toString(),
			country_code: n.country ? n.country.toString() : defaultCountryCode,
		}
	} catch (e) {
		const error = e as Error
		console.warn(
			error.message ||
				`Something went wrong while parsing the phone number ${phone_number}`
		)
		return {
			number: phone_number,
			phone_number: phone_number,
			country_code: defaultCountryCode || "IN",
		}
	}
}
