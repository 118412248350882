import {
	Button,
	Icons,
	Heading,
	Text,
	Stack,
	Alert,
	Table,
	Inline,
	Box,
	RelativeTime,
	Component,
	Divider,
	Badge,
	Spinner,
	Container,
	Time,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import { useEffect, useState } from "react"
import {
	PublicKeyCredentialCreationOptionsJSON,
	PublicKeyCredentialRequestOptionsJSON,
} from "@simplewebauthn/types"
import {
	browserSupportsWebAuthn,
	browserSupportsWebAuthnAutofill,
	platformAuthenticatorIsAvailable,
	startAuthentication,
	startRegistration,
} from "@simplewebauthn/browser"
import { ListView, Search, useSearch } from "@sembark-travel/ui/list"
import { Dialog, useDialog } from "@sembark-travel/ui/dialog"
import { IUser, useAuthUser } from "../Auth"
import { useSynedStorageState } from "../storage"
import useSWR from "swr"
import {
	Form,
	SubmissionError,
	TextInputField,
	withServerErrors,
} from "@sembark-travel/ui/form"
import {
	CopyToClipboard,
	CopyToClipboardButton,
} from "@sembark-travel/ui/copy-to-clipboard"

type TMFACredentials = {
	id: string
	type: string
	name: string
	created_at: string
	last_used_at?: string
	confirmed_at?: string
	recovery_codes?: Array<string>
}

export function TwoFactorAuth({ user }: { user: IUser }) {
	const xhr = useXHR()
	const { data, mutate } = useSWR("two-factor-auth-credentials", () =>
		xhr
			.get<{
				data: null | TMFACredentials
			}>("/two-factor-authentication")
			.then((resp) => resp.data.data)
	)
	const [is2FADialogOpen, open2FADialog, close2FADialog] = useDialog()
	const [
		isRecoveryCodesDialogOpen,
		_openRecoveryCodesDialogOpen, // eslint-disable-line
		closeRecoveryCodesDialogOpen,
	] = useDialog()
	return (
		<>
			<Container paddingY="8">
				<Stack gap="4">
					<Heading>Two Factor Authentication</Heading>
					{!data ? (
						<Box bgColor="default" borderWidth="1" rounded="md" padding="4">
							<Stack gap="4">
								<Text fontSize="sm" color="muted">
									Two-factor authentication adds an additional layer of security
									to your account by requiring more than just a password to log
									in.
								</Text>
								<Box>
									<Button
										onClick={() => open2FADialog()}
										size="sm"
										status="primary"
									>
										Setup Two-Factor Authentication
									</Button>
								</Box>
							</Stack>
						</Box>
					) : (
						<Box bgColor="default" borderWidth="1" rounded="md" padding="4">
							<Stack gap="4">
								<Text fontSize="sm" color="muted">
									You have added an additional layer of security to your account
									by requiring more than just a password to log in.
								</Text>
								<Box>
									<Inline justifyContent="between">
										<Box>
											<Badge success>Enabled</Badge> on{" "}
											{data.confirmed_at ? (
												<Time timestamp={data.confirmed_at} />
											) : null}{" "}
											• Last Used:{" "}
											{data.last_used_at ? (
												<Time timestamp={data.last_used_at} />
											) : (
												"Never"
											)}
										</Box>
										<Inline gap="4">
											{user.can_disable_2fa ? (
												<Button
													level="secondary"
													status="warning"
													size="sm"
													onClick={() => {
														if (
															window.confirm(
																"Are you sure you want to disable 2FA from your account?"
															)
														) {
															xhr
																.delete("two-factor-authentication")
																.then(() => {
																	mutate()
																})
														}
													}}
												>
													<Icons.Trash /> Disable
												</Button>
											) : null}
										</Inline>
									</Inline>
								</Box>
							</Stack>
						</Box>
					)}
				</Stack>
			</Container>
			<Dialog
				open={is2FADialogOpen}
				onClose={close2FADialog}
				title="Setup Two-factor Authentication"
			>
				<Dialog.Body>
					<SetupTwoFactorAuth
						onSuccess={() => {
							close2FADialog()
							mutate()
						}}
						onCancel={() => {
							close2FADialog()
						}}
					/>
				</Dialog.Body>
			</Dialog>
			{data?.recovery_codes?.length ? (
				<Dialog
					open={isRecoveryCodesDialogOpen}
					onClose={closeRecoveryCodesDialogOpen}
					title="Recovery Codes"
				>
					<Dialog.Body>
						<Stack gap="4">
							<Text>
								These codes can be used to log in if you lose access to your
								device and can't receive two-factor authentication codes.
							</Text>
							<Alert status="warning">
								Save these codes! If you lose your device and don't have these
								recovery codes, you will lose access to your account.
							</Alert>
							<Stack gap="2" padding="8" bgColor="subtle" textAlign="center">
								{data.recovery_codes.map((code) => (
									<Box key={code} fontFamily="mono">
										<Text>{code}</Text>
									</Box>
								))}
							</Stack>
						</Stack>
					</Dialog.Body>
					<Dialog.Footer>
						<CopyToClipboard>
							{({ copyTextToClipboard }) => (
								<CopyToClipboardButton
									status="primary"
									onClick={async () => {
										if (data.recovery_codes?.length) {
											return copyTextToClipboard(
												data.recovery_codes?.join("\n")
											)
										}
										return false
									}}
								>
									<Icons.ClipboardCopy /> Copy to Clipboard
								</CopyToClipboardButton>
							)}
						</CopyToClipboard>
						<Button
							onClick={() => closeRecoveryCodesDialogOpen()}
							level="tertiary"
						>
							Close
						</Button>
					</Dialog.Footer>
				</Dialog>
			) : null}
		</>
	)
}

function SetupTwoFactorAuth({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel: () => void
}) {
	const xhr = useXHR()
	const { data } = useSWR("two-factor-setup", () =>
		xhr
			.get<{
				data: {
					svg: string
					url: string
				}
			}>("/two-factor-authentication/create")
			.then((resp) => resp.data)
	)

	return (
		<Form
			initialValues={{ code: "" }}
			onSubmit={withServerErrors(async ({ code }) => {
				await xhr.post("/two-factor-authentication", { code })
				onSuccess()
			})}
		>
			{({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Stack gap="4">
						<Inline gap="4">
							<Box paddingTop="1">
								<Badge primary>1</Badge>
							</Box>
							<Box>
								<Stack gap="4">
									<Stack gap="2">
										<Heading as="h4">Setup Authenticator App</Heading>
										<Text>
											Use any authenticator app such as{" "}
											<Text
												as="a"
												color="accent"
												textDecoration="underline"
												href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
												target="_blank"
												rel="noopener noreferrer"
											>
												Google Authenticator
											</Text>
											,{" "}
											<Text
												as="a"
												color="accent"
												textDecoration="underline"
												href="https://www.microsoft.com/en-in/security/mobile-authenticator-app"
												target="_blank"
												rel="noopener noreferrer"
											>
												Microsoft Authenticator
											</Text>
											,{" "}
											<Text
												as="a"
												color="accent"
												textDecoration="underline"
												href="https://support.1password.com/one-time-passwords/"
												target="_blank"
												rel="noopener noreferrer"
											>
												1Password
											</Text>{" "}
											to scan the QR code below.
										</Text>
									</Stack>
									<Inline>
										{!data ? (
											<Box>
												<Spinner />
											</Box>
										) : (
											<Box>
												<Box
													padding="4"
													bgColor="subtle"
													borderWidth="1"
													rounded="md"
												>
													<Box
														dangerouslySetInnerHTML={{ __html: data.data.svg }}
													></Box>
												</Box>
											</Box>
										)}
									</Inline>
								</Stack>
							</Box>
						</Inline>
						<Divider sm />
						<Inline gap="4">
							<Box paddingTop="1">
								<Badge primary>2</Badge>
							</Box>
							<Box flex="1">
								<Stack gap="4">
									<Stack gap="2">
										<Heading as="h4">Enter security code</Heading>
										<Text>
											Enter the code generated by your authenticator app.
										</Text>
									</Stack>
									<TextInputField
										name="code"
										maxWidth="sm"
										label="Verification Code"
										placeholder="e.g. XXXXXX"
										type="text"
									/>
									<Divider marginY="0" />
									<SubmissionError />
									<Inline gap="4">
										<Button type="submit" disabled={submitting}>
											{submitting ? "Please wait..." : "Verify Code"}
										</Button>
										<Button onClick={() => onCancel()} disabled={submitting}>
											Cancel
										</Button>
									</Inline>
								</Stack>
							</Box>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

export function WebAuths() {
	const { user } = useAuthUser()
	const passkeysSupported = useSupportsPasskeys()
	const register = useRegisterPasskey()
	const [searchParam, setSearchParams] = useSearch()
	const xhr = useXHR()
	const [deviceWebAuthnUserId, setDeviceWebAuthnUserId] =
		useSynedStorageState<number>(
			"webauthn_uid",
			0,
			(val) => String(val),
			(val) => (val ? Number(val) : 0)
		)
	return (
		<Search
			title="Password-Less Authenticators"
			onSearch={(params) => setSearchParams({ ...params, page: 1 })}
			actions={
				user?.password_login_disabled_at
					? null
					: () => (
							<Inline gap="2">
								{deviceWebAuthnUserId ? (
									<Button
										size="sm"
										onClick={async () => {
											const options = await xhr
												.get<{ data: PublicKeyCredentialRequestOptionsJSON }>(
													"web-authn/allowed-credentials",
													{
														params: {
															user_ids: deviceWebAuthnUserId
																? [deviceWebAuthnUserId]
																: [],
														},
													}
												)
												.then((resp) => resp.data.data)

											try {
												// Pass the options to the authenticator and wait for a response
												const asseResp = await startAuthentication(options)
												await xhr.post("web-authn/verify", asseResp)
												alert("Congrats. WebAuthn on this device is working.")
											} catch (e) {
												const error = e as Error
												alert(error.message)
												// Some basic error handling
												return error
											}
										}}
									>
										Test Login
									</Button>
								) : null}
								<Component initialState={false}>
									{({ state, setState }) => (
										<>
											<Button
												size="sm"
												level="primary"
												onClick={async () => {
													setState(true)
												}}
											>
												Add Passkey
											</Button>
											<Dialog
												open={state}
												onClose={() => setState(false)}
												title="Add New Passkey"
											>
												<Dialog.Body>
													{!passkeysSupported ? (
														<Alert
															status="warning"
															title="This device/browser doesn't support WebAuthn (passkeys)"
														>
															<Text>
																Refer to{" "}
																<Text
																	as="a"
																	href="https://passkeys.dev/device-support/"
																	target="_blank"
																	rel="noreferrer noopener"
																	color="accent"
																>
																	Device Support - passkeys.dev{" "}
																	<Icons.ArrowTopRightOnSquare />
																</Text>{" "}
																to learn what combination of browsers and an
																operating systems support creating a passkey.
															</Text>
														</Alert>
													) : (
														<Stack gap="4">
															<Stack gap="2">
																<Text fontSize="md" fontWeight="semibold">
																	Setup passkeys and streamline your sign-in,
																	without passwords with next-generation account
																	security
																</Text>
																<Button
																	level="primary"
																	onClick={async () => {
																		try {
																			const status = await register()
																			if (status) {
																				alert("Device registered for WebAuthn")
																				if (user) {
																					setDeviceWebAuthnUserId(user.id)
																				}
																			}
																			setState(false)
																		} catch (e) {
																			const error = e as Error
																			alert(error.message)
																		}
																	}}
																	size="lg"
																>
																	Get Started with Passkeys
																</Button>
															</Stack>
															<Divider />
															<Stack gap="2">
																<Component initialState={false}>
																	{({ state, setState }) => (
																		<>
																			<Inline gap="4" justifyContent="between">
																				<Heading fontSize="md">
																					What are Passkeys?
																				</Heading>
																				<Button
																					onClick={() => setState(!state)}
																					size="sm"
																				>
																					Watch Video <Icons.ChevronDown />
																				</Button>
																			</Inline>
																			{state ? (
																				<Box
																					style={{
																						width: "100%",
																						aspectRatio: "16/9",
																					}}
																					overflow="hidden"
																					position="relative"
																				>
																					<iframe
																						allowFullScreen
																						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
																						title="Understand passkeys in 4 minutes"
																						width="640"
																						height="360"
																						src="https://www.youtube.com/embed/2xdV-xut7EQ?video-id=2xdV-xut7EQ&enablejsapi=1&widgetid=1"
																						style={{
																							width: "100%",
																							height: "100%",
																						}}
																					></iframe>
																				</Box>
																			) : null}
																		</>
																	)}
																</Component>
																<Stack gap="2">
																	<Text>
																		A passkey is a digital credential, tied to a
																		user account and a website or application.
																		Passkeys allow users to authenticate without
																		having to enter a username or password, or
																		provide any additional authentication
																		factor. This technology aims to replace
																		legacy authentication mechanisms such as
																		passwords.
																	</Text>
																	<Text
																		as="a"
																		href="https://developers.google.com/identity/passkeys"
																		target="_blank"
																		rel="noopener noreferrer"
																		color="accent"
																	>
																		Reference document from Google Developers{" "}
																		<Icons.ArrowTopRightOnSquare />
																	</Text>
																</Stack>
															</Stack>
															<Stack gap="2">
																<Heading fontSize="md">
																	Private and Secure
																</Heading>
																<Text>
																	Passkeys have been designed with user privacy
																	and security in mind.
																</Text>
																<Stack
																	as="ul"
																	gap="1"
																	listStyleType="disc"
																	paddingLeft="4"
																>
																	<li>
																		With passkeys, your biometric information is
																		never revealed to Sembark Software.
																		Biometric material never leaves the your's
																		personal device
																	</li>
																	<li>
																		Passkeys on their own don't allow tracking
																		users or devices between sites. The same
																		passkey is never used with more than one
																		site. Passkey protocols are carefully
																		designed so that no information shared with
																		sites can be used as a tracking vector.
																	</li>
																	<li>
																		Passkey managers protect passkeys from
																		unauthorized access and use. Only you can
																		access and use them, and even though they're
																		backed up to managers, Managers can't use
																		them to impersonate users.
																	</li>
																</Stack>
															</Stack>
														</Stack>
													)}
												</Dialog.Body>
											</Dialog>
										</>
									)}
								</Component>
							</Inline>
						)
			}
		>
			{() => (
				<Box paddingY="4">
					<ListView<TMFACredentials>
						pageKey="web-authns-page"
						params={searchParam}
						onPageChange={(page) => setSearchParams({ ...searchParam, page })}
						fetch={(xhr, params) =>
							xhr.get("web-authn", { params }).then((resp) => resp.data)
						}
					>
						{({ items, refresh }) => (
							<Stack gap="4">
								{deviceWebAuthnUserId === user?.id ? (
									<Box>
										<Alert title="Login via Authenticator enabled on this Device">
											<Stack gap="4">
												<Text>
													This device can use an{" "}
													<Text
														as="a"
														href="https://webauthn-doc.spomky-labs.com/webauthn-in-a-nutshell/authenticators"
														rel="noopener noreferrer"
														target="_blank"
														textDecoration="underline"
													>
														Authenticator
													</Text>{" "}
													to login into your account. Please ensure that this is
													your trusted device. If not, then remove this device
													from Authenticators.
												</Text>
												<Box>
													<Button
														size="sm"
														onClick={() => {
															if (
																window.confirm(
																	"Are you sure you want to disable WebAuthn Login from this device ?"
																)
															) {
																setDeviceWebAuthnUserId(0)
															}
														}}
													>
														Remove This Authenticator Device
													</Button>
												</Box>
											</Stack>
										</Alert>
									</Box>
								) : null}
								<Table
									headers={["Type", "Name", "Created On", "Last Used", ""]}
									bordered
									hover
									responsive
									rows={items.map((i) => [
										i.type,
										i.name,
										<RelativeTime timestamp={i.created_at} />,
										i.last_used_at ? (
											<RelativeTime timestamp={i.last_used_at} />
										) : (
											"-"
										),
										<Button
											level="tertiary"
											status="warning"
											size="sm"
											onClick={async () => {
												if (
													!window.confirm(
														`Are you sure you want to delete this authenticator ${i.name} ? This action CAN NOT be reverted.`
													)
												) {
													return
												}
												await xhr.delete(`web-authn/${i.id}`)
												refresh()
											}}
										>
											<Icons.Trash />
										</Button>,
									])}
								/>
							</Stack>
						)}
					</ListView>
				</Box>
			)}
		</Search>
	)
}

function useRegisterPasskey() {
	const xhr = useXHR()
	return async function register() {
		const publicKeyCredentialCreationOptionsJSON = await xhr
			.get<{ data: PublicKeyCredentialCreationOptionsJSON }>("web-authn/create")
			.then((resp) => resp.data.data)

		let attResp
		try {
			// Pass the options to the authenticator and wait for a response
			attResp = await startRegistration(publicKeyCredentialCreationOptionsJSON)
		} catch (e) {
			const error = e as Error
			// Some basic error handling
			if (error.name === "InvalidStateError") {
				alert("Authenticator was probably already registered by user")
				return
			}
			if (error.name === "NotAllowedError") {
				return
			}
			throw error
		}

		let name: string | null = ""

		do {
			name = window.prompt(
				"Please given this authenticator a name e.g. PC - Chrome"
			)
		} while (!String(name || "").trim())

		// store the registration
		const verificationResp = await xhr
			.post<{ data: { verified: boolean; message?: string } }>("web-authn", {
				...attResp,
				name,
			})
			.then((resp) => resp.data.data)
		if (!verificationResp.verified) {
			throw new Error(
				verificationResp.message ||
					"Something went wrong. Please try after sometime"
			)
		}
		return true
	}
}

function useSupportsPasskeys() {
	const [isSupported, setSupportStatus] = useState(false)
	useEffect(() => {
		featureDetection().then((supported) => {
			setSupportStatus(supported)
		})
	}, [])

	return isSupported
}

async function featureDetection() {
	const s = browserSupportsWebAuthn()
	if (s) return true
	try {
		return await Promise.all(
			[browserSupportsWebAuthnAutofill, platformAuthenticatorIsAvailable].map(
				(fn) =>
					fn().then((y) => {
						if (y) return y
						throw new Error("N") // throw on failure
					})
			)
		).then(() => true)
	} catch (e) {
		return false
	}
}
