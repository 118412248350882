import { Button, Inline, Divider, Stack } from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	TextInputField,
	withServerErrors,
	validateFormValues,
	SubmissionError,
	TextAreaInputField,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"

const validationSchema = Validator.object().shape({
	name: Validator.string().required("Name field is required"),
	description: Validator.string().required("Description field is required"),
})
const initialValues = {
	name: "",
	description: "",
}

type NewItemCredentials = typeof initialValues

export function NewMealPlanForm({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	return (
		<Form<NewItemCredentials>
			initialValues={initialValues}
			validate={validateFormValues(validationSchema)}
			onSubmit={withServerErrors(async (values) => {
				await xhr.post("/meal-plans", values)
				onSuccess()
			})}
			subscription={{ submitting: true }}
		>
			{({ submitting, handleSubmit }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Stack gap="4">
						<TextInputField
							label="Name"
							name="name"
							placeholder="MAP"
							type="text"
						/>
						<TextAreaInputField
							label="Description"
							name="description"
							placeholder="Dinner + Breakfast"
							type="text"
						/>
						<Divider sm />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Saving..." : "Save Meal Plan"}
							</Button>
							{onCancel ? (
								<Button onClick={onCancel} disabled={submitting}>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}
