import {
	Alert,
	Box,
	Container,
	Button,
	Inline,
	Icons,
	Heading,
	Stack,
	Text,
	Col,
	DeferRender,
	Divider,
	Grid,
	Spinner,
	Table,
	Money,
	SwitchInput,
	MoneySum,
	Component,
	Select,
	joinAttributes,
} from "@sembark-travel/ui/base"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { queryToSearch, searchToQuery } from "@sembark-travel/ui/router"
import {
	utcTimestampToLocalDate,
	dateToUTCString,
	startOf,
	formatDate,
	isSame,
	addUnit,
	endOf,
	subtractUnit,
	clone,
	getDiff,
	toISOString,
	parseDate,
	localOrUtcTimestampToLocalDate,
	dateToTimestampFormat,
} from "@sembark-travel/datetime-utils"
import { useXHR, XHRInstance } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	Fragment,
} from "react"
import { $PropertyType, $Values, Required } from "utility-types"
import config from "../config"
import { TTransportServiceLocation } from "../TransportServices"
import {
	collect,
	getTotalPriceForExtras,
	getTotalPriceForServices,
} from "../utils"
import {
	FlightServicesForm as FlightServices,
	IFlightServicesParams,
} from "./../Flights"
import {
	CalculatePriceForm as CalculateHotelPrice,
	ExtraServicesForm as ExtraHotelServices,
	allocatePaxInHotels,
} from "./../HotelPrices"
import {
	CalculatePriceForm as CalculateCabPrice,
	ExtraServicesForm as ExtraTransportServices,
	ICalculateCabExtraPriceParams,
} from "./../TransportServicePrices"
import {
	MarginsAndTaxInputField,
	PackagePricePreview,
	SelectGivenCurrency,
} from "./GiveQuote"
import {
	calculateMarginFromTotalPrice,
	calculateMarginPercentageFromTotalPrice,
} from "./utils"
import {
	ExtraServicesForm as ExtraQuoteServices,
	OtherExtraServicesParams,
} from "./QuoteExtras"
import {
	QuoteExtras,
	QuoteFlights,
	QuoteHotels,
	QuoteTransportsAndActivityTickets,
} from "./Quotes"
import {
	IQuote,
	IQuoteCab,
	IQuoteOtherExtras,
	IQuoteTransportExtras,
	ITrip,
	ITripQuote,
	IGivenQuote,
	IQuoteTravelActivity,
	useFetchEditableQuote,
	TPricePerPersonAllocation,
	useTripRefresh,
	getEditableHotels,
	IQuoteHotelParams,
	getEditableHotelExtras,
	IQuoteHotelExtraParams,
	normalizeHotels,
	normalizeHotelExtras,
	transformHotelsToRequestData,
	transformHotelExtrasToRequestData,
} from "./store"
import { getTripQuoteDetailsDiff } from "./utils"
import {
	childrenFromQueryParams,
	childrenToQueryParams,
	TChildrenArray,
} from "./../Tourists"
import { useLocation, useNavigate } from "./../router-utils"
import { IBasicInfo, EditBasicInfo } from "./EditQuoteBasicInfo"
import {
	Form,
	TextAreaInputField,
	SwitchInputField,
	withServerErrors,
	useFieldValue,
	useForm,
	GetFieldValue,
	validateFormValues,
	SubmissionError,
	isTruthy,
	EmptyNumberValidator,
	TextInputField,
	SelectInputField,
	FieldArray,
	arrayMutators,
	getFormValueForKey,
	SelectField,
	RadioInputField,
	addKeyToFieldArrayItem,
	getFieldArrayItemKey,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import { TTripDestination } from "../TripDestinations"
import {
	addMoneyFromDifferentCurrencies,
	makeCurrencyConverter,
	currencyPairsArrayToObject,
	currencyPairsObjectToArray,
	divideMoneyBy,
	formatMoneyByDecimal,
	createMoney,
	moneyParseByDecimal,
	subtractMoney,
	TCurrencyPair,
	TMoney,
	addMoney,
	TCurrencyPairsObject,
	multipleMoneyBy,
} from "@sembark-travel/money"
import {
	CalculateTravelActivityPriceForm,
	TValidTravelActivityDatewisePricesInputFieldValue,
} from "../TravelActivityPrices"
import { ErrorBoundary } from "@sembark-travel/ui/error-boundary"
import { CurrencyPairInputFieldWithRates } from "../Currencies"
import { Dialog } from "@sembark-travel/ui/dialog"
import { IHotel, IHotelMealPlan, SelectHotels } from "../Hotels"
import { TValidTransportServiceDatewisePricesFieldValue } from "../TransportServicePrices/TransportServiceDatewisePricesInputField"

type IQuoteCabParams = Parameters<
	Required<React.ComponentProps<typeof CalculateCabPrice>>["onChange"]
>[0]

type IQuoteTravelActivityParams = Parameters<
	Required<
		React.ComponentProps<typeof CalculateTravelActivityPriceForm>
	>["onChange"]
>[0]

type IQuoteCabExtraParams = Array<
	Required<
		Required<
			ICalculateCabExtraPriceParams,
			"transport_extras"
		>["transport_extras"][number],
		"service"
	>
>
type IQuoteOtherExtraParams = Array<
	Required<
		Required<OtherExtraServicesParams, "other_extras">["other_extras"][number],
		"service"
	>
>
type IQuoteFlightParams = Array<
	Required<
		$PropertyType<Required<IFlightServicesParams, "flights">, "flights">[0],
		| "source"
		| "destination"
		| "departs_at"
		| "arrives_at"
		| "airline"
		| "category_class"
		| "number_plate"
		| "no_of_adults"
		| "given_price"
	>
>

const emptyArray: TChildrenArray = []

export default function NewQuote({ trip }: { trip: ITrip }) {
	const location = useLocation()
	const navigate = useNavigate()
	const locationSearch = location.search
	const locationQuery = useMemo(() => {
		const locationQuery = searchToQuery<{
			startDate?: string
			days?: string
			adults?: string
			children?: string
			quoteId?: string
			editing?: number
		}>(locationSearch)
		const startDate = locationQuery.startDate
			? startOf(locationQuery.startDate, "day")
			: undefined
		const days = locationQuery.days ? Number(locationQuery.days) : undefined
		const adults = locationQuery.adults
			? Number(locationQuery.adults)
			: undefined
		const children =
			locationQuery.children === ""
				? []
				: locationQuery.children
					? childrenFromQueryParams(locationQuery.children)
					: undefined
		const quoteId = locationQuery.quoteId
			? Number(locationQuery.quoteId)
			: undefined
		const editing = locationQuery.editing
			? Number(locationQuery.editing)
			: undefined
		return { startDate, days, quoteId, adults, children, editing }
	}, [locationSearch])
	const { quoteId } = locationQuery
	const { data: tripQuote } = useFetchEditableQuote(quoteId)
	const { quote } = tripQuote || {}
	const onCancel = useCallback(() => {
		if (tripQuote) {
			navigate(`/trips/:tripId/quotes/:quoteId`, {
				params: { tripId: String(trip.id), quoteId: String(tripQuote.id) },
				replace: true,
			})
		} else {
			navigate(`/trips/:tripId`, {
				params: { tripId: String(trip.id) },
				replace: true,
			})
		}
	}, [navigate, trip, tripQuote])
	const defaultBasicDetailsRef = useRef({
		startDate: localOrUtcTimestampToLocalDate(
			trip.start_date_local,
			trip.start_date
		),
		days: trip.days,
		adults: trip.no_of_adults,
		children: JSON.parse(
			JSON.stringify(trip.children || emptyArray)
		) as TChildrenArray,
	})
	const startDate =
		locationQuery.startDate || defaultBasicDetailsRef.current.startDate
	const days = locationQuery.days || defaultBasicDetailsRef.current.days
	const adults = locationQuery.adults || defaultBasicDetailsRef.current.adults
	const children =
		locationQuery.children || defaultBasicDetailsRef.current.children
	const pathname = location?.pathname
	const { owner_quote_request } = trip
	const setQuoteBasicInfo = useCallback(
		({
			startDate,
			days,
			children,
			adults,
		}: {
			startDate: Date
			days: number
			adults: number
			children?: TChildrenArray
		}) => {
			if (pathname) {
				navigate(
					{
						pathname: pathname as "/",
						search: queryToSearch({
							...locationQuery,
							startDate: formatDate(startDate, "YYYY-MM-DD"),
							days,
							children: childrenToQueryParams(children || []),
							adults,
						}),
					},
					{ replace: true }
				)
			}
		},
		[locationQuery, navigate, pathname]
	)
	const onSuccess = useCallback(
		(quote: ITripQuote) => {
			if (navigate) {
				if (!quote.itinerary) {
					navigate("/trips/:tripId/quotes/:quoteId/edit-itinerary", {
						params: { tripId: String(trip.id), quoteId: String(quote.id) },
						replace: true,
					})
				} else {
					navigate("/trips/:tripId/quotes/:quoteId", {
						params: { tripId: String(trip.id), quoteId: String(quote.id) },
						replace: true,
					})
				}
			}
			showSnackbar("Quote created")
		},
		[navigate, trip]
	)
	const commonProps = {
		startDate,
		days,
		tripId: trip.id,
		onChangeBasicInfo: setQuoteBasicInfo,
		adults,
		children,
		navigate,
		trip,
		baseStartDate: localOrUtcTimestampToLocalDate(
			(quote || trip).start_date_local,
			(quote || trip).start_date
		),
		baseNoOfAdults: (quote || trip).no_of_adults,
		baseChildren: (quote || trip).children,
		onCancel,
		onSuccess,
		isEditing: locationQuery.editing ? true : false,
	}
	return (
		<Box>
			{owner_quote_request ? (
				<Box padding="8">
					<Stack gap="2">
						<Text fontWeight="semibold">
							You are creating this quote for {owner_quote_request.from?.name}
						</Text>
						{owner_quote_request.comments ? (
							<Text
								as="blockquote"
								title={`Commented by ${owner_quote_request.from?.name}`}
								whiteSpace="preserveLine"
							>
								{owner_quote_request.comments}
							</Text>
						) : null}
					</Stack>
					<Divider />
				</Box>
			) : null}
			<Fragment key={`${formatDate(startDate, "YYYY-MM-DD")}-${days}`}>
				{!quoteId ? (
					<NewQuoteForm {...commonProps} />
				) : !quote ? (
					<Spinner padding="4" alignCenter />
				) : (
					<NewQuoteForm quote={tripQuote} {...commonProps} />
				)}
			</Fragment>
		</Box>
	)
}

const newQuoteValidationSchema = Validator.object().shape({
	tax_percentage: EmptyNumberValidator()
		.nullable()
		.min(0, "Tax percentage should not be negative"),
})

const validate = validateFormValues(newQuoteValidationSchema)

interface NewQuoteFormProps {
	trip: ITrip
	tripId: number
	quote?: ITripQuote
	startDate: Date
	baseStartDate: Date
	baseNoOfAdults: number
	baseChildren: TChildrenArray
	days: number
	adults: number
	children: TChildrenArray
	isEditing: boolean
	onChangeBasicInfo: (data: IBasicInfo) => void
	onSuccess: (quote: ITripQuote) => void
	onCancel: () => void
}

function NewQuoteForm({
	tripId,
	quote: tripQuote,
	baseStartDate,
	baseNoOfAdults,
	baseChildren,
	onCancel,
	onChangeBasicInfo,
	onSuccess,
	trip,
	isEditing,
	...props
}: NewQuoteFormProps) {
	const xhr = useXHR()
	const refreshTrip = useTripRefresh()
	const { quote } = tripQuote || {}
	const { startDate, days, adults: no_of_adults, children } = props
	const containerRef = useRef<HTMLDivElement>(null)
	const refId = quote?.id
	const bookingFrom = formatDate(startDate, "YYYY-MM-DD HH:mm:ss")
	const bookingTo = formatDate(
		endOf(addUnit(startDate, days - 1, "days"), "day"),
		"YYYY-MM-DD HH:mm:ss"
	)
	const tripDestinations = trip.destinations
	const destinationCostingCurrency = useMemo(
		() => tripDestinations.map((d) => d.currency).at(0) || "INR",
		[tripDestinations]
	)
	const [initialNewQuoteValues] = useState<INewQuoteState>(() => {
		return getQuoteInitialServicesValues({
			tripQuote,
			baseStartDate,
			baseNoOfAdults,
			baseChildren,
			startDate,
			noOfAdults: no_of_adults,
			children,
			days,
			defaultCostingCurrency: destinationCostingCurrency,
			isEditing,
			hasMultiQuoteFeature: true,
		})
	})
	return (
		<Form<typeof initialNewQuoteValues>
			initialValues={initialNewQuoteValues}
			validate={validate}
			validateOnBlur
			onSubmit={withServerErrors(
				async ({
					startDate,
					days,
					no_of_adults,
					children,
					options,
					hotel_options,
					cabs,
					transportExtras,
					flights,
					otherExtras,
					cabPrice,
					transportExtrasPrice,
					flightPrice,
					otherExtrasPrice,
					include_per_person_price,
					comments,
					tax_name,
					gst_included,
					tax_percentage,
					costing_currency,
					given_currency,
					currency_pairs_obj,
					functional_currency,
				}) => {
					const currency_pairs = currencyPairsObjectToArray(
						currency_pairs_obj || {}
					)
					try {
						const data = {
							startDate,
							refId,
							days,
							no_of_adults,
							children,
							cabs,
							transportExtras,
							flights,
							otherExtras,
							cabPrice,
							transportExtrasPrice,
							flightPrice,
							otherExtrasPrice,
							comments,
							tax_name,
							gst_included: gst_included,
							tax_percentage: Number(gst_included) ? tax_percentage : null,
							include_per_person_price,
							currency_pairs,
							costing_currency,
							given_currency,
							functional_currency,
							// option specific data
							options: options.map(
								({
									given_price,
									per_person_cost_price_components,
									cost_prices_per_person,
									given_prices_per_person,
									...o
								}) => {
									if (!given_price) {
										throw Error("Please provide a selling price")
									}
									return {
										...o,
										per_person_cost_price_components: include_per_person_price
											? per_person_cost_price_components
											: undefined,
										cost_prices_per_person: include_per_person_price
											? cost_prices_per_person
											: undefined,
										given_price: given_price, // selling price
										given_prices_per_person:
											include_per_person_price && given_price
												? given_prices_per_person
												: undefined,
									}
								}
							),
							hotel_options,
						}
						const quote = await XHR(xhr).saveQuote(tripId, data)
						refreshTrip(tripId.toString())
						onSuccess(quote)
					} catch (e) {
						const error = e as Error
						if (typeof window !== "undefined" && error.message) {
							window.alert(error.message)
						}
						const document = containerRef.current
						if (document) {
							submitAllForms(document)
							setTimeout(() => {
								showErrorsFromForms(document, error.message)
							}, 300)
						}
					}
				}
			)}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ form, submitting }) => {
				return (
					<Box data-testid="quote_form" ref={containerRef}>
						<GetFieldValue<string> name="costing_currency">
							{({ value: costingCurrency }) => (
								<BasicDetails
									trip={trip}
									onChange={onChangeBasicInfo}
									baseStartDate={baseStartDate}
									baseNoOfAdults={baseNoOfAdults}
									baseChildren={baseChildren}
									tripQuote={tripQuote}
									defaultCostingCurrency={costingCurrency}
									isEditingQuote={isEditing}
								/>
							)}
						</GetFieldValue>
						{trip.on_hold_at || trip.converted_at ? null : (
							<GetFieldValue<INewQuoteState["options"]> name="options">
								{({ value: options }) => (
									<Container fluid marginBottom="8">
										<Inline
											gap="4"
											paddingY="2"
											paddingX="4"
											bgColor="default"
											borderWidth="1"
											rounded="md"
										>
											<Stack gap="1">
												<Inline gap="1" fontSize="md" fontWeight="semibold">
													Package Types/Categories:{" "}
													{pluralize("Option", options.length, true)}
												</Inline>
												{options.length > 1 ? (
													<Text>
														{options
															.map((o, index) => `${index + 1}: ${o.name}`)
															.join(" • ")}
													</Text>
												) : null}
											</Stack>
											<Component initialState={false}>
												{({ state, setState }) => (
													<>
														<Button size="sm" onClick={() => setState(true)}>
															<Icons.Pencil />
														</Button>
														<Dialog
															open={state}
															onClose={() => setState(false)}
															title="Package Types/Options"
															sm
														>
															<Dialog.Body>
																<PackageOptionsForm
																	initialOptions={options}
																	hotelOptions={
																		form.getState().values.hotel_options
																	}
																	onCancel={() => setState(false)}
																	onSubmit={async ({
																		options,
																		hotelOptions,
																	}) => {
																		form.batch(() => {
																			form.change("options", options)
																			// reset the hotel options as they are using refs
																			form.change("hotel_options", [])
																		})
																		showSnackbar(
																			"Package Types/Options updated."
																		)
																		// and then set the hotel options
																		setTimeout(() => {
																			form.change(
																				"hotel_options",
																				hotelOptions.length
																					? hotelOptions
																					: [
																							addKeyToFieldArrayItem({
																								option_index: 0,
																								hotels: [],
																								hotelExtras: [],
																								hotelPrice: [],
																								hotelExtrasPrice: [],
																							}),
																						]
																			)
																		}, 1000)
																		setState(false)
																	}}
																/>
															</Dialog.Body>
														</Dialog>
													</>
												)}
											</Component>
										</Inline>
									</Container>
								)}
							</GetFieldValue>
						)}
						<GetFieldValue<Date> name="startDate">
							{({ value: startDate }) => (
								<GetFieldValue<number> name="days">
									{({ value: days }) => (
										<DeferRender>
											<Stack gap="8">
												<Box paddingY="4" bgColor="default" id="quote_hotels">
													<NewQuoteHotelsOptionsArray
														noOfAdults={no_of_adults}
														children={children}
														startDate={bookingFrom}
														endDate={bookingTo}
														tripDestinations={tripDestinations}
														initialValues={initialNewQuoteValues.hotel_options}
													/>
												</Box>
												<Container fluid paddingY="4" bgColor="default">
													<NewQuoteCabs
														startDate={bookingFrom}
														endDate={bookingTo}
														noOfAdults={no_of_adults}
														children={children}
														tripDestinations={tripDestinations}
														cabsInitialValue={initialNewQuoteValues.cabs}
														extrasInitialValue={
															initialNewQuoteValues.transportExtras
														}
													/>
													<TotalCabsAndActivitiesPrice />
												</Container>
												<Container fluid paddingY="4" bgColor="default">
													<NewQuoteFlights
														startDate={bookingFrom}
														endDate={bookingTo}
														tripDestinations={tripDestinations}
														flights={
															form.getFieldState("flights")?.value ||
															initialNewQuoteValues.flights
														}
														no_of_adults={no_of_adults}
														children={children}
														onChange={(services, price) => {
															form.batch(() => {
																form.change("flights", services)
																form.change("flightPrice", price)
															})
														}}
													/>
													<TotalFlightsPrice />
												</Container>
												<Container fluid paddingY="4" bgColor="default">
													<NewQuoteOtherExtras
														startDate={bookingFrom}
														endDate={bookingTo}
														tripDestinations={tripDestinations}
														otherExtras={
															form.getFieldState("otherExtras")?.value ||
															initialNewQuoteValues.otherExtras
														}
														onChange={(services, price) => {
															form.batch(() => {
																form.change("otherExtras", services)
																form.change("otherExtrasPrice", price)
															})
														}}
													/>
													<TotalOtherExtrasPrice />
												</Container>
												<Container fluid paddingY="4" bgColor="default">
													<TextAreaInputField
														label="Any comments for this quote"
														secondaryLabel="optional"
														name="comments"
														placeholder="Any comments regarding customer request or anything special about this quote or anything else..."
													/>
												</Container>
											</Stack>
											<DeferRender by={5000}>
												<ErrorBoundary>
													<PreviewData
														startDate={startDate}
														days={days}
														adults={no_of_adults}
														children={children}
													/>
												</ErrorBoundary>
												<DeferRender by={3000}>
													<Container fluid paddingY="6" bgColor="default">
														<ErrorBoundary>
															<CostingPricesBifurcation />
														</ErrorBoundary>
													</Container>
													<Container
														bgColor="default"
														paddingY="6"
														borderTopWidth="1"
													>
														<Stack
															borderWidth="2"
															borderColor="primary"
															rounded="md"
															padding="4"
															bgColor="accent"
															gap="4"
														>
															<Inline gap="2" as="header">
																<Box>
																	<StepIcon>
																		<Icons.BankNotes size="6" />
																	</StepIcon>
																</Box>
																<Stack>
																	<Heading as="h4" id="quote_selling_price">
																		Preview Final Package Price
																	</Heading>
																	<Text color="muted">
																		Here are the final prices for this quote.
																	</Text>
																</Stack>
															</Inline>
															<GetFieldValue<
																INewQuoteState["options"]
															> name="options">
																{({ value: options }) => (
																	<Stack gap="6">
																		{options.map((o, index, arr) => (
																			<Grid
																				key={o.__id}
																				gap="4"
																				position="relative"
																			>
																				{arr.length > 1 ? (
																					<Col
																						style={{ position: "sticky" }}
																						xs={12}
																						sm={4}
																						md={3}
																						top="0"
																						zIndex="10"
																					>
																						<Box
																							position="sticky"
																							top="0"
																							zIndex="10"
																							bgColor="emphasis"
																							color="on_emphasis"
																							paddingY="2"
																							paddingX="4"
																							rounded="md"
																						>
																							<Text fontSize="sm">
																								Option {index + 1}
																							</Text>
																							<Heading fontSize="md">
																								{o.name}
																							</Heading>
																						</Box>
																					</Col>
																				) : null}
																				<Col>
																					<PackagePricePreviewForOption
																						optionIndex={index}
																					/>
																				</Col>
																			</Grid>
																		))}
																	</Stack>
																)}
															</GetFieldValue>
														</Stack>
														<Stack gap="4">
															<Divider sm />
															<SubmissionError />
															<GetFieldValue<
																INewQuoteState["fetching_prices"]
															> name="fetching_prices">
																{({ value: isFetchingPrices }) => (
																	<Stack
																		gap="4"
																		cursor={
																			isFetchingPrices ? "wait" : undefined
																		}
																	>
																		{isFetchingPrices ? (
																			<Alert
																				status="warning"
																				title="Please wait... Some configuration is pending."
																			>
																				<Text>
																					System rates are being retrieved.
																					Please wait or preview the quote
																					details.
																				</Text>
																			</Alert>
																		) : null}
																		<Inline gap="4">
																			<Button
																				disabled={
																					submitting || isFetchingPrices
																				}
																				type="button"
																				level="primary"
																				status="primary"
																				onClick={() => {
																					form.submit()
																				}}
																				size="lg"
																			>
																				{submitting
																					? "Saving..."
																					: "Save Quote"}
																			</Button>
																			<Button
																				onClick={onCancel}
																				size="lg"
																				disabled={submitting}
																			>
																				Cancel
																			</Button>
																		</Inline>
																	</Stack>
																)}
															</GetFieldValue>
														</Stack>
													</Container>
												</DeferRender>
											</DeferRender>
										</DeferRender>
									)}
								</GetFieldValue>
							)}
						</GetFieldValue>
						<SyncIsFetchingPricesField />
					</Box>
				)
			}}
		</Form>
	)
}

function BasicDetails({
	onChange,
	trip,
	tripQuote,
	baseStartDate,
	baseNoOfAdults,
	baseChildren,
	defaultCostingCurrency,
	isEditingQuote,
}: {
	trip: ITrip
	tripQuote?: ITripQuote
	baseStartDate: Date
	baseNoOfAdults: number
	baseChildren: TChildrenArray
	onChange: React.ComponentProps<typeof EditBasicInfo>["onChange"]
	defaultCostingCurrency: string
	isEditingQuote: boolean
}) {
	const { value: startDate } = useFieldValue<Date>("startDate")
	const { value: days } = useFieldValue<number>("days")
	const { value: no_of_adults } = useFieldValue<number>("no_of_adults")
	const { value: children } = useFieldValue<TChildrenArray | undefined>(
		"children"
	)
	const form = useForm<INewQuoteState>()
	const mismatchBasicDetails = useMemo(() => {
		const diff = getTripQuoteDetailsDiff(trip, {
			start_date: startDate.toISOString(),
			days,
			no_of_adults,
			children: children || [],
		})
		return diff
	}, [trip, startDate, days, no_of_adults, children])

	const handleBasicDetailsChange: React.ComponentProps<
		typeof EditBasicInfo
	>["onChange"] = useCallback(
		(newValues) => {
			// Reset the form if we get a change in start_date or days
			if (
				toISOString(startOf(startDate, "day")) !==
					toISOString(startOf(newValues.startDate, "day")) ||
				days !== newValues.days
			) {
				// reset the form
				const initialQuote = getQuoteInitialServicesValues({
					tripQuote,
					baseStartDate,
					baseNoOfAdults,
					baseChildren,
					startDate: newValues.startDate,
					days: newValues.days,
					noOfAdults: newValues.adults,
					children: newValues.children || [],
					defaultCostingCurrency,
					isEditing: isEditingQuote,
					hasMultiQuoteFeature: true,
				})
				form.initialize((values) => {
					return {
						...values,
						...initialQuote,
						startDate: newValues.startDate,
						days: newValues.days,
						no_of_adults: newValues.adults,
						children: newValues.children,
					}
				})
			} else {
				// update the no_of_adults and children values
				form.batch(() => {
					form.change("no_of_adults", newValues.adults)
					form.change("children", newValues.children)
				})
			}
			onChange({
				startDate: newValues.startDate,
				days: newValues.days,
				adults: newValues.adults,
				children: newValues.children,
			})
		},
		[
			onChange,
			form,
			startDate,
			days,
			baseStartDate,
			tripQuote,
			baseChildren,
			baseNoOfAdults,
			defaultCostingCurrency,
			isEditingQuote,
		]
	)
	const { destinations } = trip
	return (
		<Container fluid paddingY="4">
			<Stack gap="3">
				<Stack gap="px">
					<Heading as="h3">Basic Details</Heading>
					<Text color="muted">
						Please review basic details for this quote. You can edit these
						details to provide a quote with different configuration, without
						changing the trip details.
					</Text>
				</Stack>
				<Inline collapseBelow="md" gap={{ xs: "4", md: "8" }}>
					<Box>
						<Grid gap={{ xs: "4", md: "6" }}>
							<Col>
								<KeyValueDetails label="Destination">
									{destinations.map((d) => d.name).join(", ")}
								</KeyValueDetails>
							</Col>
							<Col>
								<KeyValueDetails label="Start Date">
									{formatDate(startDate, "DD MMM, YYYY")}
								</KeyValueDetails>
							</Col>
							<Col>
								<KeyValueDetails label="Duration">
									{days > 1
										? `${pluralize("Nights", days - 1, true)}, ${pluralize(
												"Day",
												days,
												true
											)}`
										: pluralize("Day", days, true)}
								</KeyValueDetails>
							</Col>
							<Col>
								<KeyValueDetails label="Pax">
									{pluralize("Adult", no_of_adults, true)}
									{children?.length ? (
										<span>
											{" "}
											with {pluralize("Children", children.length, true)} (
											{childrenToQueryParams(children)})
										</span>
									) : null}
								</KeyValueDetails>
							</Col>
						</Grid>
					</Box>
					<Stack gap="1">
						<Box>
							<EditBasicInfo
								destinations={destinations}
								startDate={startDate}
								days={days}
								adults={no_of_adults}
								children={children}
								onChange={handleBasicDetailsChange}
							/>
						</Box>
					</Stack>
				</Inline>
				{mismatchBasicDetails ? (
					<Alert status="warning" title="Mismatch details with trip details">
						<Stack gap="2">
							<Text>
								These basic details do not match with the trip's basic details.{" "}
								<b>
									Please ignore if you are providing a quote with different
									details.
								</b>
							</Text>
							<Box>
								<Button
									size="sm"
									status="warning"
									onClick={() => {
										handleBasicDetailsChange({
											startDate: localOrUtcTimestampToLocalDate(
												trip.start_date_local,
												trip.start_date
											),
											days: trip.days,
											adults: trip.no_of_adults,
											children: trip.children,
										})
									}}
								>
									Match with Trip Details
								</Button>
							</Box>
						</Stack>
					</Alert>
				) : null}
			</Stack>
		</Container>
	)
}

function getNewOptions(
	{
		options,
		hotelOptions,
	}: {
		options: INewQuoteState["options"]
		hotelOptions: INewQuoteState["hotel_options"]
	},
	params: { options: INewQuoteState["options"] }
) {
	const oldOptionsById = collect(options).keyBy((_, index) => String(index))
	const oldOptionNewOptionIdMapping: {
		[oldId: string]: number
	} = {}
	const newOptions: typeof options = params.options.map((o, index) => {
		const oldOption = oldOptionsById[String(o.id)]
		const newId = index
		if (oldOption) {
			oldOptionNewOptionIdMapping[o.id] = newId
		}
		return {
			...(oldOption || options[0]),
			...o,
			id: newId,
		}
	})
	const newHotelOptions = hotelOptions
		.filter((ho) => oldOptionNewOptionIdMapping[ho.option_index] !== undefined)
		.map((ho) => ({
			...ho,
			option_index: oldOptionNewOptionIdMapping[ho.option_index], // update the id
		}))

	return {
		options: newOptions,
		hotelOptions: newHotelOptions,
	}
}

const MAX_QUOTE_OPTIONS = 6

function PackageOptionsForm({
	initialOptions: options,
	hotelOptions,
	onCancel,
	onSubmit,
}: {
	initialOptions: INewQuoteState["options"]
	hotelOptions: INewQuoteState["hotel_options"]
	onCancel: () => void
	onSubmit: (payload: {
		options: INewQuoteState["options"]
		hotelOptions: INewQuoteState["hotel_options"]
	}) => Promise<void>
}) {
	type TPackageOptionFormData = {
		options: Array<{
			__id: number
			/**
			 * ID for the option,
			 * During editing, we will set it to the index of an option
			 * and on add new, we will generate a new id. this way we
			 * will track the changes in the existing options
			 */
			id: number
			name: string
		}>
	}
	const initialValues: TPackageOptionFormData = {
		options: options.map((o, index) =>
			addKeyToFieldArrayItem({
				id: index,
				name: o.name,
			})
		),
	}
	return (
		<Form<TPackageOptionFormData>
			initialValues={initialValues}
			validate={validateFormValues(
				Validator.object().shape({
					options: Validator.array()
						.min(1, "Options should be atleast one")
						.max(MAX_QUOTE_OPTIONS, "Options can not be greater than four")
						.of(
							Validator.object().shape({
								name: Validator.string()
									.required("Please provide a name/title for the option")
									.max(50, "Please use 50 or fewer characters"),
							})
						),
				})
			)}
			onSubmit={withServerErrors(async (params) => {
				const options_count = params.options.length
				if (
					options_count < options.length &&
					!window.confirm(
						`Are you sure you want to reduce the options from ${options.length} to ${options_count}? Data associated with removed options will be removed.`
					)
				) {
					return
				}
				await onSubmit(
					getNewOptions({ options, hotelOptions }, { options: params.options })
				)
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<FieldArray<
							TPackageOptionFormData["options"][number]
						> name="options">
							{({ fields }) => (
								<Stack gap="2">
									<Table
										headers={["#", "Name", ""]}
										bordered
										hover
										alignCols={{ 2: "right" }}
										rows={fields.map((name, index) => [
											<Box>{index + 1}</Box>,
											<TextInputField
												name={`${name}.name`}
												type="text"
												placeholder="e.g. Premium Package"
											/>,
											<Button
												onClick={() => fields.remove(index)}
												level="tertiary"
											>
												<Icons.Cancel />
											</Button>,
										])}
									/>
									{(fields.length || 0) < MAX_QUOTE_OPTIONS ? (
										<Box>
											<Button
												size="sm"
												level="secondary"
												onClick={() =>
													fields.push(
														addKeyToFieldArrayItem({
															id: Math.random() * 1000, // generate a new ID
															name: "",
														})
													)
												}
											>
												Add More
											</Button>
										</Box>
									) : null}
								</Stack>
							)}
						</FieldArray>
						<Divider marginY="0" />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								Save
							</Button>
							<Button onClick={() => onCancel()} disabled={submitting}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

function NewQuoteHotelsOptionsArray({
	initialValues,
	startDate,
	...props
}: {
	initialValues: INewQuoteState["hotel_options"]
} & Pick<
	React.ComponentProps<typeof NewQuoteHotelsOption>,
	"noOfAdults" | "children" | "startDate" | "endDate" | "tripDestinations"
>) {
	const form = useForm<INewQuoteState>()
	return (
		<Stack gap="4">
			<Container fluid>
				<Inline as="header" gap="2">
					<Box>
						<StepIcon>
							<Icons.Bed size="6" />
						</StepIcon>
					</Box>
					<Stack gap="1">
						<Heading as="h4">Hotels</Heading>
						<Text color="muted">
							Please add hotels details (if included in package) with services
							provided for each hotels and the selling cost price.
						</Text>
						<Text fontSize="sm" color="muted">
							<Icons.LightBulb /> Tip: To speed up the process of adding
							multiple hotels, use <strong>Next Night</strong> or{" "}
							<strong>Duplicate</strong> actions.
						</Text>
					</Stack>
				</Inline>
			</Container>
			<FieldArray<INewQuoteState["hotel_options"][number]> name="hotel_options">
				{({ fields }) => (
					<Stack gap="4" position="relative">
						{fields.map(function (name, index) {
							const key = getFieldArrayItemKey(form as never, name)
							return (
								<Stack gap="4" key={key}>
									<GetFieldValue<INewQuoteState["options"]> name="options">
										{({ value: quoteOptions }) =>
											quoteOptions.length > 1 ? (
												<Box
													paddingY="2"
													bgColor="emphasis"
													position="sticky"
													zIndex="10"
													top="0"
												>
													<Container fluid>
														<Inline
															gap="4"
															alignItems="center"
															justifyContent="between"
														>
															<Inline>
																<SelectInputField name={`${name}.option_index`}>
																	{quoteOptions.map((o, index) => (
																		<option key={o.__id} value={index}>
																			Option {index + 1}: {o.name}
																		</option>
																	))}
																</SelectInputField>
															</Inline>
															<Inline gap="4">
																<GetFieldValue<
																	INewQuoteState["hotel_options"][number]
																>
																	name={name}
																>
																	{({ value }) =>
																		value.hotels?.length ||
																		value.hotelExtras?.length ? (
																			<Component initialState={false}>
																				{({ state, setState }) => (
																					<>
																						<Button
																							size="sm"
																							onClick={() => {
																								setState(true)
																							}}
																						>
																							<Icons.Duplicate /> Copy
																						</Button>
																						<Dialog
																							title="Copy Hotels for new Option"
																							open={state}
																							onClose={() => setState(false)}
																						>
																							<Dialog.Body>
																								<DuplicateHotelsToNewOption
																									startDate={startDate}
																									hotelOption={value}
																									quoteOptions={quoteOptions}
																									tripDestinations={
																										props.tripDestinations
																									}
																									onCancel={() => {
																										setState(false)
																									}}
																									onSave={(hotelOption) => {
																										const existingHotelOptions =
																											form.getState().values
																												.hotel_options
																										const existingCopy =
																											existingHotelOptions.find(
																												(o) =>
																													String(
																														o.option_index
																													) ===
																													String(
																														hotelOption.option_index
																													)
																											)
																										if (!existingCopy) {
																											// simply push it to the end
																											fields.push(hotelOption)
																										} else {
																											form.change(
																												"hotel_options",
																												[]
																											)
																											setTimeout(() => {
																												// replace the existing one(s)
																												form.change(
																													"hotel_options",
																													existingHotelOptions.map(
																														(h) =>
																															String(
																																h.option_index
																															) ===
																															String(
																																hotelOption.option_index
																															)
																																? hotelOption
																																: h
																													)
																												)
																											}, 300)
																										}
																										setState(false)
																									}}
																								/>
																							</Dialog.Body>
																						</Dialog>
																					</>
																				)}
																			</Component>
																		) : null
																	}
																</GetFieldValue>
																{Number(fields.length || 0) > 1 ? (
																	<Button
																		size="sm"
																		onClick={() => {
																			if (
																				window.confirm(
																					"Are you sure you want to remove this hotel option?"
																				)
																			) {
																				const hotel_options =
																					form.getState().values.hotel_options
																				const hotel_option =
																					hotel_options[index]
																				form.change("hotel_options", [])
																				setTimeout(() => {
																					const newData = getNewOptions(
																						{
																							options: quoteOptions,
																							hotelOptions: hotel_options,
																						},
																						{
																							// remove the option option_index
																							options: quoteOptions.filter(
																								(_, index) =>
																									String(index) !==
																									String(
																										hotel_option.option_index
																									)
																							),
																						}
																					)
																					// now set the data back
																					form.batch(() => {
																						form.change(
																							"options",
																							newData.options
																						)
																						form.change(
																							"hotel_options",
																							newData.hotelOptions
																						)
																					})
																				}, 300)
																			}
																		}}
																	>
																		<Icons.Cancel />
																	</Button>
																) : null}
															</Inline>
														</Inline>
													</Container>
												</Box>
											) : null
										}
									</GetFieldValue>
									<Container fluid>
										<NewQuoteHotelsOption
											{...props}
											startDate={startDate}
											optionName={name}
											hotelsInitialValue={initialValues[index]?.hotels}
											hotelExtrasInitialValue={
												initialValues[index]?.hotelExtras
											}
										/>
									</Container>
								</Stack>
							)
						})}
						<GetFieldValue<INewQuoteState["options"]> name="options">
							{({ value: quoteOptions }) =>
								fields.length !== quoteOptions.length ? (
									<DeferRender by={500}>
										<Container>
											<Alert
												status="error"
												title="Some options are missing hotels details!!"
											>
												Please use the copy/duplicate [<Icons.Duplicate />]
												button from an option to add hotels for other option(s).
											</Alert>
										</Container>
									</DeferRender>
								) : null
							}
						</GetFieldValue>
					</Stack>
				)}
			</FieldArray>
		</Stack>
	)
}

function DuplicateHotelsToNewOption({
	startDate,
	hotelOption,
	quoteOptions,
	onSave,
	onCancel,
	tripDestinations,
}: {
	startDate: string
	hotelOption: INewQuoteState["hotel_options"][number]
	quoteOptions: INewQuoteState["options"]
	tripDestinations: Array<TTripDestination>
	onCancel: () => void
	onSave: (newOption: INewQuoteState["hotel_options"][number]) => void
}) {
	const uniqueQuoteHotelsIndexedByHotel = useMemo(() => {
		return collect(hotelOption.hotels)
			.groupBy((h) => h.hotel.id)
			.toArray()
	}, [hotelOption])

	const copyFromOption = quoteOptions[Number(hotelOption.option_index)]

	const commonMealPlans = useMemo(() => {
		const hotels = uniqueQuoteHotelsIndexedByHotel.map((h) => h[0].hotel)
		if (!hotels.length) return []
		const firstHotelMealPlans = hotels[0].meal_plans
		return firstHotelMealPlans.filter((mealPlan) =>
			hotels.every((hotel) =>
				hotel.meal_plans.find((m) => m.id === mealPlan.id)
			)
		)
	}, [uniqueQuoteHotelsIndexedByHotel])
	return (
		<Form<{
			option_index?: string
			change_type?: "meal_plan" | "hotels" | "none"
			meal_plan?: IHotelMealPlan
			hotels_replacements?: { [hotelId: string]: IHotel | undefined }
		}>
			initialValues={{
				option_index: undefined,
				change_type: "hotels",
				meal_plan: undefined,
				hotels_replacements: undefined,
			}}
			validate={validateFormValues(
				Validator.object().shape({
					option_index: EmptyNumberValidator().required(
						"Please select the Target Option to copy to."
					),
				})
			)}
			onSubmit={withServerErrors(
				async ({ meal_plan, hotels_replacements, ...values }) => {
					const option_index = Number(String(values.option_index))
					if (String(option_index) === String(hotelOption.option_index)) {
						throw new Error("Please change the Target Option to copy.")
					}
					const newHotelOption: INewQuoteState["hotel_options"][number] =
						addKeyToFieldArrayItem({
							...hotelOption,
							option_index,
						})
					switch (values.change_type) {
						case "meal_plan":
							if (meal_plan) {
								newHotelOption.hotels = newHotelOption.hotels.map((h) => ({
									...h,
									meal_plan,
									date_wise_prices: h.date_wise_prices.map((p) => ({
										...p,
										edited_given_price: false,
									})),
									comments: "",
									similar_hotel_options: h.similar_hotel_options?.map((h) => ({
										...h,
										meal_plan:
											h.hotel.meal_plans.find((m) => m.id === meal_plan.id) ||
											h.hotel.meal_plans[0],
										comments: "",
										date_wise_prices: h.date_wise_prices.map((p) => ({
											...p,
											given_price: 0,
											edited_given_price: false,
										})),
									})),
								}))
							} else {
								throw new Error("Please select a meal plan for Target Option.")
							}
							break
						case "hotels":
							newHotelOption.hotels = newHotelOption.hotels.map((h) => {
								const mappedHotel = hotels_replacements?.[h.hotel.id]
								if (!mappedHotel) return h
								return {
									...h,
									hotel: mappedHotel,
									room_type: mappedHotel.room_types[0], // base category
									meal_plan:
										mappedHotel.meal_plans.find(
											(m) => m.id === h.meal_plan.id
										) || mappedHotel.meal_plans[0],
									comments: "",
									date_wise_prices: h.date_wise_prices.map((p) => ({
										...p,
										edited_given_price: false,
									})),
									similar_hotel_options: undefined,
								}
							})
							newHotelOption.hotelExtras = newHotelOption.hotelExtras.map(
								(h) => {
									const mappedHotel = hotels_replacements?.[h.hotel.id]
									if (!mappedHotel) return h
									return {
										...h,
										hotel: mappedHotel,
									}
								}
							)
							break
						default:
							break
					}
					onSave(newHotelOption)
				}
			)}
			subscription={{
				submitting: true,
			}}
		>
			{({ submitting, handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<Stack gap="4">
						<Inline
							gap="2"
							padding="4"
							borderWidth="1"
							bgColor="subtle"
							rounded="md"
						>
							<Heading fontSize="md">Source Option:</Heading>
							<Text fontSize="md" fontWeight="semibold">
								{copyFromOption.name}
							</Text>
						</Inline>
						<Divider sm />
						<Inline
							gap="4"
							collapseBelow="sm"
							padding="4"
							borderWidth="1"
							rounded="md"
							bgColor="primary"
							borderColor="primary"
						>
							<Stack gap="1">
								<Heading fontSize="md">Target Option</Heading>
								<Text color="muted">The option where you want to copy to.</Text>
							</Stack>
							<Inline>
								<SelectInputField name="option_index">
									<option>---</option>
									{quoteOptions.map((o, index) => (
										<option
											key={o.id}
											value={index}
											disabled={
												index.toString() === hotelOption.option_index.toString()
											}
										>
											Option {index + 1}: {o.name}
										</option>
									))}
								</SelectInputField>
							</Inline>
						</Inline>
						<Stack borderWidth="1" rounded="md">
							<Box paddingX="4" paddingY="2" bgColor="inset">
								<Text fontWeight="semibold">
									What would you like to change in Hotels for the Target Option
									?
								</Text>
							</Box>
							<Stack padding="4" gap="4" borderTopWidth="1">
								<RadioInputField
									name="change_type"
									value="hotels"
									label="Hotels"
									help="Add replacements for hotels to copy to Target Option"
								/>
								<GetFieldValue<string> name="change_type">
									{({ value }) =>
										value !== "hotels" ? null : (
											<Table
												headers={["Exisiting Hotel", "Replacement Hotel"]}
												bordered
												hover
												rows={uniqueQuoteHotelsIndexedByHotel.map(
													(quoteHotels) => {
														const hotel = quoteHotels[0].hotel
														return [
															<Stack>
																<Text fontWeight="semibold">{hotel.name}</Text>
																<Text color="muted">
																	{joinAttributes(
																		hotel?.location?.short_name,
																		hotel.stars_string,
																		quoteHotels
																			.flatMap((qh) =>
																				qh.dates.map(
																					(d) =>
																						getDiff(
																							d,
																							parseDate(startDate),
																							"days"
																						) + 1
																				)
																			)
																			.join(", ") + " N"
																	)}
																</Text>
															</Stack>,
															<SelectField
																select={SelectHotels}
																multiple={false}
																name={`hotels_replacements.${hotel.id}`}
																tripDestinations={tripDestinations}
															/>,
														]
													}
												)}
											/>
										)
									}
								</GetFieldValue>
							</Stack>
							{commonMealPlans.length ? (
								<Stack padding="4" borderTopWidth="1" gap="4">
									<RadioInputField
										name="change_type"
										value="meal_plan"
										label="Meal Plan"
										help="Change the meal plan and copy"
									/>
									<GetFieldValue<string> name="change_type">
										{({ value }) =>
											value !== "meal_plan" ? null : (
												<SelectField
													select={Select}
													multiple={false}
													options={commonMealPlans}
													name="meal_plan"
													label="Select Meal Plan for Target Option"
												/>
											)
										}
									</GetFieldValue>
								</Stack>
							) : null}
							<Stack padding="4" borderTopWidth="1" gap="4">
								<RadioInputField
									name="change_type"
									value="none"
									label="Custom Change (Occupancy, Room Type etc.)"
									help="You should update details after copying."
								/>
							</Stack>
						</Stack>
						<Divider sm />
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								Copy Over
							</Button>
							<Button disabled={submitting} onClick={() => onCancel()}>
								Cancel
							</Button>
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

function NewQuoteHotelsOption({
	startDate,
	endDate,
	tripDestinations,
	noOfAdults,
	children,
	optionName,
	hotelsInitialValue: propHotelsInitialValues,
	hotelExtrasInitialValue: propHotelExtrasInitialValues,
}: {
	startDate: string
	endDate: string
	tripDestinations: Array<TTripDestination>
	noOfAdults: number
	children: TChildrenArray
	optionName: string
	hotelsInitialValue?: IQuoteHotelParams
	hotelExtrasInitialValue?: IQuoteHotelExtraParams
}) {
	const bookingFrom = startDate
	const bookingTo = useMemo(
		() => formatDate(subtractUnit(endDate, 1, "day"), "YYYY-MM-DD HH:mm:ss"),
		[endDate]
	)
	const form = useForm<INewQuoteState>()

	const [hotelsInitialValue] = useState(() => {
		const hotels =
			getFormValueForKey(form.getState().values, `${optionName}.hotels`) ||
			propHotelsInitialValues
		return hotels?.length ? { hotels } : undefined
	})

	const [extrasInitialValue] = useState(() => {
		const extras =
			getFormValueForKey(form.getState().values, `${optionName}.hotelExtras`) ||
			propHotelExtrasInitialValues
		return { hotel_extras: extras || [] }
	})
	return (
		<Stack>
			<Box>
				<CalculateHotelPrice
					noOfAdults={noOfAdults}
					children={children}
					bookingFrom={bookingFrom}
					bookingTo={bookingTo}
					initialValues={hotelsInitialValue}
					tripDestinations={tripDestinations}
					onChange={(services) => {
						form.batch(() => {
							form.change(`${optionName}.hotels` as never, services as never)
							form.change(
								`${optionName}.hotelPrice` as never,
								getTotalPriceForServices(services) as never
							)
						})
					}}
					shouldEmptyInitialValues
					canAddSimilarOptions
				/>
			</Box>
			<Divider sm />
			<Stack gap="4">
				<Stack gap="1">
					<Heading as="h5" id="quote_hotel_extras" fontSize="md">
						Any special inclusions in hotels
					</Heading>
					<Text color="muted">
						Add any extra services for hotels e.g. special dinner, honeymoon
						cake etc.
					</Text>
				</Stack>
				<ExtraHotelServices
					bookingFrom={bookingFrom}
					bookingTo={bookingTo}
					initialValues={extrasInitialValue}
					tripDestinations={tripDestinations}
					onChange={(services) => {
						form.batch(() => {
							form.change(
								`${optionName}.hotelExtras` as never,
								services as never
							)
							form.change(
								`${optionName}.hotelExtrasPrice` as never,
								getTotalPriceForExtras(services) as never
							)
						})
					}}
				/>
			</Stack>
			<TotalHotelsPrice optionName={optionName} />
		</Stack>
	)
}

function useHotelsOption(optionIndex: number) {
	const { value: hotelOptions } =
		useFieldValue<INewQuoteState["hotel_options"]>(`hotel_options`)
	const hotelOption = hotelOptions.find(
		(o) => Number(o.option_index) === optionIndex
	)
	return hotelOption
}

function useTotalHotelPrice(optionIndex: number) {
	const hotelOption = useHotelsOption(optionIndex)
	if (!hotelOption) return []
	return addMoneyFromDifferentCurrencies([
		...hotelOption.hotelPrice,
		...hotelOption.hotelExtrasPrice,
	])
}

function TotalHotelsPrice({ optionName }: { optionName: string }) {
	const { value: hotelPrice } = useFieldValue<
		INewQuoteState["hotel_options"][number]["hotelPrice"]
	>(`${optionName}.hotelPrice`)
	const { value: hotelExtrasPrice } = useFieldValue<
		INewQuoteState["hotel_options"][number]["hotelExtrasPrice"]
	>(`${optionName}.hotelExtrasPrice`)
	const totalPrice = addMoneyFromDifferentCurrencies([
		...hotelPrice,
		...hotelExtrasPrice,
	])
	return (
		<Box as="footer" textAlign="right">
			<Box
				bgColor="warning"
				display="inlineBlock"
				paddingX="4"
				paddingY="2"
				fontWeight="semibold"
				borderWidth="1"
				borderColor="warning"
				color="warning"
				rounded="md"
			>
				Accommodation's Total: <MoneySum money={totalPrice} />
			</Box>
		</Box>
	)
}

function NewQuoteCabs({
	startDate,
	endDate,
	cabsInitialValue: propCabsInitialValue,
	extrasInitialValue: propExtrasInitialValue,
	tripDestinations,
	noOfAdults,
	children,
}: {
	startDate: string
	endDate: string
	noOfAdults: number
	children: TChildrenArray
	cabsInitialValue: INewQuoteState["cabs"]
	extrasInitialValue: INewQuoteState["transportExtras"]
	tripDestinations: Array<TTripDestination>
}) {
	const form = useForm<INewQuoteState>()

	const [cabsInitialValue] = useState(() => {
		const cabs =
			getFormValueForKey(form.getState(), "cabs") || propCabsInitialValue
		return cabs.daywise_services.length ? cabs : undefined
	})

	const [extrasInitialValue] = useState(() => {
		const extras =
			getFormValueForKey(form.getState(), "transportExtras") ||
			propExtrasInitialValue
		return { transport_extras: extras }
	})

	return (
		<Stack>
			<Stack gap="4">
				<Inline as="header" gap="2">
					<Box>
						<StepIcon>
							<Icons.Bus size="6" />
						</StepIcon>
					</Box>
					<Stack gap="1">
						<Heading as="h4" id="quote_cabs">
							Transports and Activities
						</Heading>
						<Text color="muted">
							Please add the transportation services and Activites (if included)
							details and the selling cost price for each service.
						</Text>
						<Text fontSize="sm" color="muted">
							<Icons.LightBulb /> Tip: To speed up the process of adding
							multiple services, use <strong>Next Day</strong> or{" "}
							<strong>Duplicate</strong> actions.
						</Text>
					</Stack>
				</Inline>
				<Box>
					<CalculateCabPrice
						noOfAdults={noOfAdults}
						children={children}
						bookingFrom={startDate}
						bookingTo={endDate}
						initialValues={cabsInitialValue}
						onChange={(services) =>
							form.batch(() => {
								form.change("cabs", services)
								form.change("cabPrice", services.given_price)
							})
						}
						shouldEmptyInitialValues
						tripDestinations={tripDestinations}
					/>
				</Box>
			</Stack>
			<Divider sm />
			<Stack gap="4">
				<Stack gap="1">
					<Heading as="h5" id="quote_cab_extras" fontSize="md">
						Any extra or sightseeing in transportation
					</Heading>
					<Text color="muted">
						Add any extra services like any side destination trip that is
						provided only per customer request
					</Text>
				</Stack>
				<ExtraTransportServices
					bookingFrom={startDate}
					bookingTo={endDate}
					initialValues={extrasInitialValue}
					tripDestinations={tripDestinations}
					onChange={(extras) =>
						form.batch(() => {
							form.change("transportExtras", extras as never)
							form.change(
								"transportExtrasPrice",
								getTotalPriceForExtras(extras)
							)
						})
					}
				/>
			</Stack>
		</Stack>
	)
}

function useTotalCabsAndTravelActivitesWithExtrasPrice() {
	const { value: cabsAndActivitiesPrices } =
		useFieldValue<INewQuoteState["cabPrice"]>("cabPrice")
	const { value: cabsPrice } = useFieldValue<
		INewQuoteState["cabs"]["given_price_of_cabs_only"]
	>("cabs.given_price_of_cabs_only")
	const { value: travelActivitiesPrice } = useFieldValue<
		INewQuoteState["cabs"]["given_price_of_cabs_only"]
	>("cabs.given_price_of_activities_only")
	const { value: transportExtrasPrice } = useFieldValue<
		INewQuoteState["transportExtrasPrice"]
	>("transportExtrasPrice")
	return [
		addMoneyFromDifferentCurrencies([
			...cabsAndActivitiesPrices,
			...transportExtrasPrice,
		]),
		{
			cabsPrice,
			travelActivitiesPrice,
			extrasPrice: transportExtrasPrice,
		},
	] as const
}

function TotalCabsAndActivitiesPrice() {
	const [total, { cabsPrice, travelActivitiesPrice, extrasPrice }] =
		useTotalCabsAndTravelActivitesWithExtrasPrice()
	const showTotal =
		[cabsPrice, travelActivitiesPrice, extrasPrice].filter((t) => t.length)
			.length > 1
	return (
		<Box as="footer" textAlign="right">
			<Box
				bgColor="warning"
				display="inlineBlock"
				paddingX="4"
				paddingY="2"
				fontWeight="semibold"
				borderWidth="1"
				borderColor="warning"
				color="warning"
				rounded="md"
			>
				<Stack gap="2">
					{showTotal ? (
						<Box>
							Total: <MoneySum money={total} />
						</Box>
					) : null}
					<Text fontSize={showTotal ? "sm" : undefined}>
						{joinAttributes(
							cabsPrice?.length ? (
								<>
									{" "}
									Cabs's: <MoneySum money={cabsPrice} />
								</>
							) : null,
							travelActivitiesPrice?.length ? (
								<>
									Activity/Ticket's: <MoneySum money={travelActivitiesPrice} />
								</>
							) : null,
							extrasPrice?.length ? (
								<>
									Other's: <MoneySum money={extrasPrice} />
								</>
							) : null
						)}
					</Text>
				</Stack>
			</Box>
		</Box>
	)
}

function NewQuoteFlights({
	startDate,
	endDate,
	flights,
	onChange,
	no_of_adults,
	children,
	tripDestinations,
}: {
	startDate: string
	endDate: string
	flights: INewQuoteState["flights"]
	onChange: (flights: INewQuoteState["flights"], price: Array<TMoney>) => void
} & Pick<
	React.ComponentProps<typeof FlightServices>,
	"no_of_adults" | "children" | "tripDestinations"
>) {
	const initialValue = useRef({ flights }).current
	return (
		<Stack>
			<Stack gap="4">
				<Inline as="header" gap="2">
					<Box>
						<StepIcon>
							<Icons.Airplane size="4" />
						</StepIcon>
					</Box>
					<Stack gap="1">
						<Heading as="h4" id="quote_flights">
							Flight Details
						</Heading>
						<Text color="muted">
							Please provide flight details for this quote if included.
						</Text>
					</Stack>
				</Inline>
				<FlightServices
					bookingFrom={startDate}
					bookingTo={endDate}
					onChange={(s) => onChange(s as never, getTotalPriceForExtras(s))}
					initialValues={initialValue}
					no_of_adults={no_of_adults}
					children={children}
					tripDestinations={tripDestinations}
				/>
			</Stack>
		</Stack>
	)
}

function TotalFlightsPrice() {
	const { value: flights } = useFieldValue<INewQuoteState["flights"]>("flights")
	const { value: flightPrice } =
		useFieldValue<INewQuoteState["flightPrice"]>("flightPrice")
	return flights.length ? (
		<Box as="footer" textAlign="right">
			<Box
				bgColor="warning"
				display="inlineBlock"
				paddingX="4"
				paddingY="2"
				fontWeight="semibold"
				borderWidth="1"
				borderColor="warning"
				color="warning"
				rounded="md"
			>
				Flight's Total: <MoneySum money={flightPrice} />
			</Box>
		</Box>
	) : null
}

function NewQuoteOtherExtras({
	startDate,
	endDate,
	otherExtras,
	onChange,
	tripDestinations,
}: {
	startDate: string
	endDate: string
	otherExtras: INewQuoteState["otherExtras"]
	onChange: (extras: INewQuoteState["otherExtras"], price: TMoney[]) => void
	tripDestinations: Array<TTripDestination>
}) {
	const initialValue = useRef({ other_extras: otherExtras }).current
	return (
		<Stack gap="4">
			<Inline as="header" gap="2">
				<Box>
					<StepIcon>
						<Icons.Star size="6" />
					</StepIcon>
				</Box>
				<Stack gap="1">
					<Heading as="h4" id="quote_other_extras">
						Any other special service for this trip
					</Heading>
					<Text color="muted">
						Add any extra services like off road dinner, side treking etc that
						are associated with overall trip package
					</Text>
				</Stack>
			</Inline>
			<ExtraQuoteServices
				bookingFrom={startDate}
				bookingTo={endDate}
				initialValues={initialValue}
				tripDestinations={tripDestinations}
				onChange={(extras) =>
					onChange(extras as never, getTotalPriceForExtras(extras))
				}
			/>
		</Stack>
	)
}

function TotalOtherExtrasPrice() {
	const { value: otherExtras } =
		useFieldValue<INewQuoteState["otherExtras"]>("otherExtras")
	const { value: otherExtrasPrice } =
		useFieldValue<INewQuoteState["otherExtrasPrice"]>("otherExtrasPrice")
	return otherExtras.length ? (
		<Box as="footer" textAlign="right">
			<Box
				bgColor="warning"
				display="inlineBlock"
				paddingX="4"
				paddingY="2"
				fontWeight="semibold"
				borderWidth="1"
				borderColor="warning"
				color="warning"
				rounded="md"
			>
				Extra Service's Total: <MoneySum money={otherExtrasPrice} />
			</Box>
		</Box>
	) : null
}

function SellingPrice({ optionIndex }: { optionIndex: number }) {
	const { totalPrice, flightPrice } =
		useTotalQuotePriceInCostingCurrency(optionIndex)
	const { value: perPersonCostPriceAllocation } = useFieldValue<
		INewQuoteState["options"][number]["cost_prices_per_person"]
	>(`options[${optionIndex}].cost_prices_per_person`)
	return (
		<Stack gap="4">
			<Box>
				<MarginsAndTaxInputField
					optionIndex={optionIndex}
					costPriceWithoutFlights={Number(
						formatMoneyByDecimal(subtractMoney(totalPrice, flightPrice))
					)}
					flightPriceInCostingCurrency={Number(
						formatMoneyByDecimal(flightPrice)
					)}
					perPersonCostPriceAllocation={
						perPersonCostPriceAllocation || undefined
					}
				/>
			</Box>
			<TextAreaInputField
				label="Any comments regarding selling price"
				secondaryLabel="optional"
				name={`options[${optionIndex}].given_price_comments`}
			/>
		</Stack>
	)
}

function allocatePax({
	noOfAdults,
	children,
	hotels,
	cabs,
	includeAdultsWithExtraBedInCabs,
	includeChildrenInCabsStartAge,
	// flights,
	totalExtrasPrice,
	includeChildrenInExtras,
	costingCurrency,
	currencyExchangePairs,
}: {
	noOfAdults: number
	children: TChildrenArray
	hotels: IQuoteHotelParams
	cabs: INewQuoteState["cabs"]
	includeAdultsWithExtraBedInCabs?: boolean
	includeChildrenInCabsStartAge: false | number
	flights: INewQuoteState["flights"]
	totalExtrasPrice?: Array<TMoney>
	includeChildrenInExtras?: boolean
	costingCurrency: string
	currencyExchangePairs: Array<TCurrencyPair>
}) {
	const pax: Array<{
		id: number
		type: "adult" | "child"
		accommodation?: "base" | "extra_bed" | "no_extra_bed" | "complimentary"
		room_sharing_type?: number
		price: TMoney[]
		hotels_price: TMoney[]
		cabs_price: TMoney[]
		travel_activities_price: TMoney[]
		flights_price: TMoney[]
		extras_price: TMoney[]
		age?: number
	}> = []

	const converter = makeCurrencyConverter(currencyExchangePairs, true)

	function convertMoneyToCostingCurrencyAndGetDecimalAmount(
		money: TMoney | Array<TMoney>
	): number {
		return Number(formatMoneyByDecimal(converter(costingCurrency, money)))
	}

	function addPax(type: "adult" | "child", age?: number) {
		pax.push({
			id: pax.length,
			type: type,
			price: [createMoney(0, costingCurrency)],
			age,
			hotels_price: [createMoney(0, costingCurrency)],
			cabs_price: [createMoney(0, costingCurrency)],
			travel_activities_price: [createMoney(0, costingCurrency)],
			flights_price: [createMoney(0, costingCurrency)],
			extras_price: [createMoney(0, costingCurrency)],
		})
	}
	for (let i = 0; i < noOfAdults; i++) {
		addPax("adult")
	}
	// sort the children in descending order of age
	children = children
		.concat([])
		.sort((a, b) => (a.age > b.age ? -1 : a.age === b.age ? 0 : 1))
	for (const child of children) {
		for (let i = 0; i < child.count; i++) {
			addPax("child", child.age)
		}
	}

	function addPriceToPax(
		p: (typeof pax)[0],
		type: "hotels" | "cabs" | "activities" | "flights" | "extras",
		amount: TMoney[] | undefined | null
	) {
		const price = amount ? amount : []
		p.price = addMoneyFromDifferentCurrencies(p.price.concat(price))
		switch (type) {
			case "hotels":
				p.hotels_price = addMoneyFromDifferentCurrencies(
					p.hotels_price.concat(price)
				)
				break
			case "cabs":
				p.cabs_price = addMoneyFromDifferentCurrencies(
					p.cabs_price.concat(price)
				)
				break
			case "activities":
				p.travel_activities_price = addMoneyFromDifferentCurrencies(
					p.travel_activities_price.concat(price)
				)
				break
			case "flights":
				p.flights_price = addMoneyFromDifferentCurrencies(
					p.flights_price.concat(price)
				)
				break
			case "extras":
				p.extras_price = addMoneyFromDifferentCurrencies(
					p.extras_price.concat(price)
				)
				break
		}
		return p
	}

	let warnings: Array<string> = []

	// group the hotels by nights
	collect(normalizeHotels(hotels))
		.groupBy((h) => h.date)
		.toArray()
		.forEach((hotels) => {
			const { warnings: warns } = allocatePaxInHotels(pax, hotels, {
				defaultCurrency: costingCurrency,
				addPriceToPax: (pax, price) => addPriceToPax(pax, "hotels", price),
			})
			warnings = warnings.concat(warns)
		})

	const includedPaxInCabs = pax.filter((p) => {
		if (
			p.type === "child" &&
			(includeChildrenInCabsStartAge === false ||
				(p.age || 0) < includeChildrenInCabsStartAge)
		) {
			return false
		}
		if (
			p.type === "adult" &&
			p.accommodation === "extra_bed" &&
			!includeAdultsWithExtraBedInCabs
		) {
			return false
		}
		return true
	})
	const normalizedDaywiseServices = normalizeDaywiseServices(cabs)
	if (isTruthy(cabs.add_cab_prices_at_once)) {
		const totalCabsPrice = cabs.given_price_of_cabs_only
		const perPaxPrice = totalCabsPrice.map((price) =>
			divideMoneyBy(price, includedPaxInCabs.length || 1, "ceil")
		)
		includedPaxInCabs.forEach((p) => {
			addPriceToPax(p, "cabs", perPaxPrice)
		})
	} else {
		collect(normalizedDaywiseServices.cabs)
			.groupBy((d) => d.date)
			.toArray()
			.forEach((cabs) => {
				const totalCabsPriceForThisDay = cabs.reduce<Array<TMoney>>(
					(total, { currency, given_price }) =>
						addMoneyFromDifferentCurrencies(
							total.concat([moneyParseByDecimal(given_price, currency)])
						),
					[]
				)
				const perPaxPrice = totalCabsPriceForThisDay.map((m) =>
					divideMoneyBy(m, includedPaxInCabs.length || 1, "ceil")
				)
				includedPaxInCabs.forEach((p) => {
					addPriceToPax(p, "cabs", perPaxPrice)
				})
			})
	}

	collect(normalizedDaywiseServices.travel_activities)
		.groupBy((d) => d.date)
		.toArray()
		.forEach((travelActivities) => {
			travelActivities.forEach((travelActivity) => {
				const allocatedPaxId: {
					[
						key: number
					]: (typeof travelActivity.ticket_tourist_configurations)[number]
				} = {}
				travelActivity.ticket_tourist_configurations.forEach((ticket) => {
					let assignedTo: (typeof pax)[0] | undefined = undefined
					const { configuration } = ticket
					const { age_start, age_end } = configuration
					let perPaxPriceForGroup = 0
					let availabePaxForGroup: typeof pax = []
					switch (ticket.configuration.tourist_type) {
						case "adult":
							for (let i = 0; i < ticket.quantity; i++) {
								assignedTo = pax.find(
									(p) => !allocatedPaxId[p.id] && p.type === "adult"
								)
								if (assignedTo) {
									addPriceToPax(assignedTo, "activities", [
										moneyParseByDecimal(
											ticket.per_quantity_given_price || 0,
											ticket.currency
										),
									])
									allocatedPaxId[assignedTo.id] = ticket
								}
							}
							break
						case "child":
							for (let i = 0; i < ticket.quantity; i++) {
								if (
									age_start !== undefined &&
									age_start !== null &&
									age_end !== undefined &&
									age_end !== null
								) {
									assignedTo = pax.find(
										(p) =>
											!allocatedPaxId[p.id] &&
											p.type === "child" &&
											p.age &&
											p.age >= age_start &&
											p.age <= age_end
									)
									if (assignedTo) {
										addPriceToPax(assignedTo, "activities", [
											moneyParseByDecimal(
												ticket.per_quantity_given_price || 0,
												ticket.currency
											),
										])
										allocatedPaxId[assignedTo.id] = ticket
									}
								}
							}
							break
						case "group":
							availabePaxForGroup = pax.filter((p) => {
								if (allocatedPaxId[p.id]) return false
								if (!p.age) return true
								if (!age_start && !age_end) {
									return !travelActivity.activity.complimentary_till_age
										? true
										: p.age > travelActivity.activity.complimentary_till_age
								}
								return (
									p.age >=
										(age_start ||
											travelActivity.activity.complimentary_till_age ||
											0) && p.age <= (age_end || Infinity)
								)
							})
							perPaxPriceForGroup = Math.ceil(
								(ticket.given_price || 0) /
									Math.min(
										availabePaxForGroup.length || 1,
										ticket.configuration.quantity * ticket.quantity
									)
							)
							for (
								let i = 0;
								i < ticket.quantity * ticket.configuration.quantity;
								i++
							) {
								assignedTo = availabePaxForGroup.find(
									(p) => !allocatedPaxId[p.id]
								)
								if (assignedTo) {
									addPriceToPax(assignedTo, "activities", [
										moneyParseByDecimal(perPaxPriceForGroup, ticket.currency),
									])
									allocatedPaxId[assignedTo.id] = ticket
								}
							}
							break
					}
				})
				const allocatedTickets = Object.values(allocatedPaxId)
				let unallocatedTickets = travelActivity.ticket_tourist_configurations
					.map((ticket) => {
						let allocatedQuantity = allocatedTickets.filter(
							(t) => t === ticket
						).length
						const perTicketQuantity = ticket.configuration.quantity
						const availableQuantity = ticket.quantity * perTicketQuantity
						// incase of group, convert the allocatedQuantity to allocated tickets
						const tickets = allocatedQuantity
							? Math.ceil(
									Math.max(allocatedQuantity, perTicketQuantity) /
										perTicketQuantity
								)
							: 0
						allocatedQuantity = tickets * perTicketQuantity
						return {
							ticket,
							unallocatedQuantity: Math.ceil(
								(availableQuantity - allocatedQuantity) / perTicketQuantity
							),
						}
					})
					.filter((ticket) => ticket.unallocatedQuantity > 0)
				if (unallocatedTickets.length) {
					// some tickets were configured but have not been allocated to pax
					// This happens if when children have been assigned the tickets of adults
					const unallocatedChildren = pax.filter(
						(p) => !allocatedPaxId[p.id] && p.type === "child"
					)
					unallocatedChildren.sort((a, b) =>
						!a.age || !b.age ? 0 : a.age > b.age ? -1 : 1
					)
					const unallocatedTicketsForAdults = unallocatedTickets.filter(
						({ ticket }) => ticket.configuration.tourist_type === "adult"
					)
					unallocatedChildren.forEach((assignedTo) => {
						const ticket = unallocatedTicketsForAdults.find(
							({ unallocatedQuantity }) => unallocatedQuantity > 0
						)
						if (ticket) {
							addPriceToPax(assignedTo, "activities", [
								moneyParseByDecimal(
									ticket.ticket.per_quantity_given_price || 0,
									ticket.ticket.currency
								),
							])
							allocatedPaxId[assignedTo.id] = ticket.ticket
							ticket.unallocatedQuantity = ticket.unallocatedQuantity - 1
						}
					})
					unallocatedTickets = unallocatedTickets.filter(
						({ unallocatedQuantity }) => {
							return unallocatedQuantity
						}
					)
					if (unallocatedTickets.length) {
						throw new Error(
							`Unable to allocate activity tickets: ${unallocatedTickets
								.map(
									({ ticket, unallocatedQuantity }) =>
										`${unallocatedQuantity} ${ticket.configuration.name}`
								)
								.join(", ")}`
						)
					}
				}
			})
		})

	if (totalExtrasPrice?.length) {
		const includeChildrenInExtrasStartAge = includeChildrenInExtras
			? 6
			: undefined
		const includedPaxInCabs = pax.filter((p) => {
			if (
				p.type === "child" &&
				(!includeChildrenInExtrasStartAge ||
					(p.age || 0) < includeChildrenInExtrasStartAge)
			) {
				return false
			}
			return true
		})
		const perPaxPrice = totalExtrasPrice.map((m) =>
			divideMoneyBy(m, includedPaxInCabs.length || 1, "ceil")
		)
		includedPaxInCabs.forEach((p) => {
			addPriceToPax(p, "extras", perPaxPrice)
		})
	}

	function getLabelForSharing(sharing: number) {
		switch (sharing) {
			case 1:
				return "Single Sharing"
			case 2:
				return "Double Sharing"
			case 3:
				return "Triple Sharing"
			case 4:
				return "Quad Sharing"
			default:
				return sharing + " pax/room"
		}
	}

	const allocation = collect(pax)
		.groupBy(
			(p) =>
				(p.accommodation || "") +
				"-" +
				p.price.map((t) => `${t.currency}${t.amount}`).join("+") +
				"-" +
				p.type +
				"-" +
				p.room_sharing_type
		)
		.toArray()
		.map((pax, index) => {
			const { type, accommodation, room_sharing_type } = pax[0]
			let perPaxLabel = type === "adult" ? "Adult" : "Child"
			switch (accommodation) {
				case "extra_bed":
					perPaxLabel += " with Extra Bed/Mattress"
					break
				case "no_extra_bed":
					perPaxLabel += " without Extra Bed/Mattress"
					break
				case "base":
					perPaxLabel =
						(type === "adult" ? "Person" : "Child") +
						(room_sharing_type
							? ` (${getLabelForSharing(room_sharing_type)})`
							: ``)
			}
			let totalPaxLabel = ""
			switch (type) {
				case "adult":
					totalPaxLabel = pax.length + " Pax"
					break
				case "child":
					totalPaxLabel = `${pluralize("Child", pax.length, true)} (${pax
						.map((p) => p.age)
						.filter(Boolean)
						.join("y, ")}y)`
					break
			}
			return {
				...pax[0],
				id: `${index}_` + perPaxLabel.replace(/[\W]/gi, "_"),
				count: pax.length,
				per_pax_label: perPaxLabel,
				total_pax_label: totalPaxLabel,
				individuals: pax,
			}
		})
		.map(
			({
				price,
				hotels_price,
				cabs_price,
				travel_activities_price,
				flights_price,
				extras_price,
				...others
			}) => {
				const hotels_price_decimal =
					convertMoneyToCostingCurrencyAndGetDecimalAmount(hotels_price)
				const cabs_price_decimal =
					convertMoneyToCostingCurrencyAndGetDecimalAmount(cabs_price)
				const travel_activities_price_decimal =
					convertMoneyToCostingCurrencyAndGetDecimalAmount(
						travel_activities_price
					)
				const flights_price_decimal =
					convertMoneyToCostingCurrencyAndGetDecimalAmount(flights_price)
				const extras_price_decimal =
					convertMoneyToCostingCurrencyAndGetDecimalAmount(extras_price)
				return {
					...others,
					currency: costingCurrency,
					price: addMoney(
						...[
							moneyParseByDecimal(hotels_price_decimal, costingCurrency),
							moneyParseByDecimal(cabs_price_decimal, costingCurrency),
							moneyParseByDecimal(
								travel_activities_price_decimal,
								costingCurrency
							),
							moneyParseByDecimal(flights_price_decimal, costingCurrency),
							moneyParseByDecimal(extras_price_decimal, costingCurrency),
						]
					),
					hotels_price: hotels_price_decimal,
					cabs_price: cabs_price_decimal,
					travel_activities_price: travel_activities_price_decimal,
					flights_price: flights_price_decimal,
					extras_price: extras_price_decimal,
					pax_count: others.individuals.length,
					ages: others.individuals
						.map((p) => p.age)
						.filter((age): age is number => Boolean(age)),
				}
			}
		)

	return {
		allocation,
		warnings,
	}
}

function useCurrenciesFromCosting() {
	const { value: hotelOptions } =
		useFieldValue<INewQuoteState["hotel_options"]>("hotel_options")
	const [totalCabsPrice] = useTotalCabsAndTravelActivitesWithExtrasPrice()
	const { value: flightPrice } =
		useFieldValue<INewQuoteState["flightPrice"]>("flightPrice")
	const { value: otherExtrasPrice } =
		useFieldValue<INewQuoteState["otherExtrasPrice"]>("otherExtrasPrice")

	return useMemo(
		() =>
			collect(
				[
					...hotelOptions.flatMap((h) => [
						...h.hotelPrice,
						...h.hotelExtrasPrice,
					]),
					...totalCabsPrice,
					...otherExtrasPrice,
					...flightPrice,
				]
					.filter((t) => t.amount)
					.map((t) => t.currency)
			)
				.unique()
				.toArray(),
		[hotelOptions, totalCabsPrice, otherExtrasPrice, flightPrice]
	)
}

function SelectCostingCurrency() {
	const currencies = useCurrenciesFromCosting()
	const { value: costingCurrency, onChange: changeCostingCurrency } =
		useFieldValue<INewQuoteState["costing_currency"]>("costing_currency")
	// ensure that the costing is from the list of all currencies
	useEffect(() => {
		if (currencies?.length && currencies.indexOf(costingCurrency) === -1) {
			changeCostingCurrency(currencies[0])
		}
	}, [currencies, costingCurrency, changeCostingCurrency])
	const form = useForm<INewQuoteState>()
	if (currencies.length <= 1) return null
	return (
		<Grid gap="4" borderBottomWidth="1" paddingBottom="4">
			<Col sm={12} md={4}>
				<Stack gap="1">
					<Heading fontSize="md">Costing Currency and Exchange Rates</Heading>
					<Text color="muted">
						The quote has multiple costing currencies. Please select a costing
						currency and provide the exchange rates.
					</Text>
				</Stack>
			</Col>
			<Col>
				<Stack gap="4">
					<Inline gap="6" flexWrap="wrap">
						<SelectInputField
							label="Costing Currency"
							name="costing_currency"
							onChange={(e) => {
								form.change("costing_currency", e.currentTarget.value)
								form.change("given_currency", e.currentTarget.value)
							}}
							size="sm"
						>
							{currencies.map((c) => (
								<option key={c} value={c}>
									{c}
								</option>
							))}
						</SelectInputField>
						<Inline gap="2">
							<Stack>
								<Heading fontSize="md" color="primary">
									Exchange Rates
								</Heading>
								<Box>
									<Icons.SwitchHorizontal />
								</Box>
							</Stack>
							<Inline gap="4" flexWrap="wrap">
								{currencies.map((baseCurrency) =>
									baseCurrency === costingCurrency ? null : (
										<CurrencyPairInputFieldWithRates
											key={baseCurrency}
											baseCurrency={baseCurrency}
											counterCurrency={costingCurrency}
											name={`currency_pairs_obj`}
											size="sm"
										/>
									)
								)}
							</Inline>
						</Inline>
					</Inline>
				</Stack>
			</Col>
		</Grid>
	)
}

function CostingPricesBifurcation() {
	const { value: options } = useFieldValue<INewQuoteState["options"]>("options")
	return (
		<Stack gap="8">
			<SelectCostingCurrency />
			<Inline
				gap="8"
				flexWrap="wrap"
				padding="4"
				bgColor="primary"
				borderWidth="1"
				borderColor="primary"
				rounded="default"
			>
				<Stack gap="2">
					<Heading fontSize="md">Per-Person Inclusion</Heading>
					<SwitchInputField
						name="include_per_person_price"
						label="Include Per-Person Price"
					/>
				</Stack>
				<SelectGivenCurrency />
			</Inline>
			{options.map((o, index, arr) => (
				<Stack key={o.__id} gap="4" position="relative">
					{arr.length > 1 ? (
						<Box style={{ position: "sticky" }} top="0" zIndex="10">
							<Box
								position="sticky"
								top="0"
								zIndex="10"
								bgColor="emphasis"
								color="on_emphasis"
								paddingY="2"
								paddingX="4"
								rounded="md"
							>
								<Inline
									justifyContent="between"
									gap="1"
									alignItems="baseline"
									collapseBelow="md"
								>
									<Heading fontSize="md">{o.name}</Heading>
									<Text fontSize="sm">Option {index + 1}</Text>
								</Inline>
							</Box>
						</Box>
					) : null}
					<PerPersonCostPrice optionIndex={o.id} />
				</Stack>
			))}
		</Stack>
	)
}

function QuotePriceInCostingCurrency({ optionIndex }: { optionIndex: number }) {
	const {
		totalPrice: total,
		totalHotelPrice: hotels,
		totalCabsPrice: cabs,
		otherExtrasPrice: otherExtras,
		flightPrice: flights,
	} = useTotalQuotePriceInCostingCurrency(optionIndex)

	const currencies = useCurrenciesFromCosting()

	if (currencies.length <= 1) return null

	return (
		<Grid gap="6">
			<Col sm={12} md={3} lg={2}>
				<Heading fontSize="md">Prices in Costing Currency</Heading>
				<Text color="accent" fontWeight="semibold">
					{total.currency}
				</Text>
			</Col>
			<Col>
				<Inline gap="4" collapseBelow="md">
					<Inline
						gap="2"
						paddingX="4"
						paddingY="2"
						rounded="md"
						bgColor="primary"
						borderWidth="1"
						borderColor="primary"
					>
						<Text fontWeight="semibold" color="primary">
							Total Cost Price:
						</Text>
						<Text fontSize="md">
							<Money money={total} showCurrency />
						</Text>
					</Inline>
					{total.amount ? (
						<Box
							paddingX="4"
							paddingY="2"
							rounded="md"
							borderWidth="1"
							overflow="auto"
						>
							<Inline gap="2">
								{([] as Array<[string, TMoney]>)
									.concat([
										["Hotels", hotels],
										["Transports/Activities", cabs],
										["Other Extras", otherExtras],
										["Flights", flights],
									])
									.filter(([_, money]) => money.amount)
									.map(([label, money], i) => (
										<Fragment key={label}>
											{i > 0 ? (
												<Stack
													padding="1"
													alignItems="center"
													justifyContent="center"
												>
													<Icons.Plus />
												</Stack>
											) : null}
											<Inline flexWrap="wrap" gap="1" whiteSpace="preserve">
												<Text fontSize="sm" color="muted">
													{label}
												</Text>
												<Text>
													<Money money={money} />
												</Text>
											</Inline>
										</Fragment>
									))}
							</Inline>
						</Box>
					) : null}
				</Inline>
			</Col>
		</Grid>
	)
}

function useCurrencyPairsArrayFieldValue() {
	const { value } =
		useFieldValue<INewQuoteState["currency_pairs_obj"]>("currency_pairs_obj")
	return useMemo(() => {
		if (!value) return []
		return currencyPairsObjectToArray(value)
	}, [value])
}

function PerPersonInclusionConfiguration({
	paxAllocation,
	optionIndex,
	totalExtrasPrice,
	hasCabs,
	children,
}: {
	paxAllocation: ReturnType<typeof allocatePax>["allocation"]
	optionIndex: number
	totalExtrasPrice: Array<TMoney>
	hasCabs: boolean
	children: TChildrenArray
}) {
	const hasAdultWithExtraBed = paxAllocation.some(
		(p) => p.type === "adult" && p.accommodation === "extra_bed"
	)

	const { value: include_children_in_cabs_start_age } = useFieldValue<boolean>(
		`options[${optionIndex}].per_person_cost_price_components.include_children_in_cabs_start_age`
	)

	const includeChildrenInCabsStartAge = !isNaN(
		parseInt(String(include_children_in_cabs_start_age))
	)
		? Number(include_children_in_cabs_start_age)
		: false

	const form = useForm()

	const childrenCountAboveFive = useMemo(
		() => children.filter((c) => c.age > 5).length,
		[children]
	)

	return (
		<>
			{hasCabs && (hasAdultWithExtraBed || children?.length) ? (
				<Stack gap="2">
					<Text>Cabs Inclusions</Text>
					{hasAdultWithExtraBed ? (
						<SwitchInputField
							name={`options[${optionIndex}].per_person_cost_price_components.include_adults_with_extra_bed_in_cabs`}
							label="Include AwEB in Cabs"
						/>
					) : null}
					{children?.length ? (
						<Inline gap="1" alignItems="center">
							<SwitchInput
								checked={includeChildrenInCabsStartAge !== false}
								onChange={(checked) => {
									if (checked) {
										form.change(
											`options[${optionIndex}].per_person_cost_price_components.include_children_in_cabs_start_age`,
											6
										)
									} else {
										form.change(
											`options[${optionIndex}].per_person_cost_price_components.include_children_in_cabs_start_age`,
											undefined
										)
									}
								}}
							/>
							<Text fontWeight="semibold">Include Child:</Text>
							<Inline gap="1" alignItems="center">
								<TextInputField
									name={`options[${optionIndex}].per_person_cost_price_components.include_children_in_cabs_start_age`}
									size="sm"
									type="number"
									style={{ maxWidth: "60px" }}
									placeholder="6"
									min={0}
								/>
								<Text>yrs and onwards</Text>
							</Inline>
						</Inline>
					) : null}
				</Stack>
			) : null}
			{totalExtrasPrice?.length && childrenCountAboveFive ? (
				<Stack gap="2">
					<Text>Extras Inclusions</Text>
					<SwitchInputField
						name={`options[${optionIndex}].per_person_cost_price_components.include_children_in_extras`}
						label="Include Child (6yr and above)"
					/>
				</Stack>
			) : null}
		</>
	)
}

function usePerPersonAllocation(optionIndex: number) {
	const { value: costPriceCurrency } =
		useFieldValue<INewQuoteState["costing_currency"]>("costing_currency")
	const currencyPairs = useCurrencyPairsArrayFieldValue()
	const { hotels, hotelExtrasPrice } = useHotelsOption(optionIndex) || {}
	const { value: noOfAdults } = useFieldValue<number>("no_of_adults")
	const { value: children } = useFieldValue<TChildrenArray>("children")
	const { value: transportExtrasPrice } = useFieldValue<
		INewQuoteState["transportExtrasPrice"]
	>("transportExtrasPrice")
	const { value: otherExtrasPrice } =
		useFieldValue<INewQuoteState["otherExtrasPrice"]>("otherExtrasPrice")
	let { value: includePerPersonPrice } = useFieldValue<boolean>(
		"include_per_person_price"
	)
	includePerPersonPrice = isTruthy(includePerPersonPrice)

	const { value: include_children_in_cabs_start_age } = useFieldValue<boolean>(
		`options[${optionIndex}].per_person_cost_price_components.include_children_in_cabs_start_age`
	)
	const { value: include_adults_with_extra_bed_in_cabs } =
		useFieldValue<boolean>(
			`options[${optionIndex}].per_person_cost_price_components.include_adults_with_extra_bed_in_cabs`
		)
	const { value: include_children_in_extras } = useFieldValue<boolean>(
		`options[${optionIndex}].per_person_cost_price_components.include_children_in_extras`
	)
	const { value: priceMismatchBetweenPerPersonAndTotal } =
		useFieldValue<boolean>(
			`options[${optionIndex}].price_mismatch_between_per_person_and_total`
		)
	const includeChildrenInCabsStartAge = !isNaN(
		parseInt(String(include_children_in_cabs_start_age))
	)
		? Number(include_children_in_cabs_start_age)
		: false

	const includeAdultsWithExtraBedInCabs = isTruthy(
		include_adults_with_extra_bed_in_cabs
	)
	const includeChildrenInExtras = isTruthy(include_children_in_extras)
	const { totalPrice, flightPrice } =
		useTotalQuotePriceInCostingCurrency(optionIndex)
	const totalCostPriceExcludingFlights = Number(
		formatMoneyByDecimal(subtractMoney(totalPrice, flightPrice))
	)
	const form = useForm()
	const { value: cabs } = useFieldValue<INewQuoteState["cabs"]>("cabs")
	const { value: flights } = useFieldValue<INewQuoteState["flights"]>("flights")
	const [
		{
			allocation: paxAllocation,
			error: paxAllocationError,
			warnings: paxAllocationWarnings,
		},
		setPaxAllocation,
	] = useState<{
		allocation: ReturnType<typeof allocatePax>["allocation"] | undefined
		warnings: Array<string> | undefined
		error: Error | undefined
	}>({ allocation: undefined, error: undefined, warnings: undefined })
	const totalExtrasPrice = useMemo(
		() =>
			addMoneyFromDifferentCurrencies(
				([] as Array<TMoney>)
					.concat(hotelExtrasPrice || [])
					.concat(transportExtrasPrice)
					.concat(otherExtrasPrice)
			),
		[hotelExtrasPrice, transportExtrasPrice, otherExtrasPrice]
	)

	useEffect(() => {
		try {
			const { allocation, warnings } = allocatePax({
				noOfAdults,
				children,
				hotels: hotels || [],
				cabs,
				includeAdultsWithExtraBedInCabs,
				includeChildrenInCabsStartAge,
				flights,
				totalExtrasPrice,
				includeChildrenInExtras,
				costingCurrency: costPriceCurrency,
				currencyExchangePairs: currencyPairs,
			})
			setPaxAllocation({ allocation, error: undefined, warnings })
		} catch (_e) {
			setPaxAllocation({
				error: _e as Error,
				allocation: undefined,
				warnings: undefined,
			})
		}
	}, [
		hotels,
		cabs,
		flights,
		noOfAdults,
		children,
		setPaxAllocation,
		includeAdultsWithExtraBedInCabs,
		includeChildrenInCabsStartAge,
		totalExtrasPrice,
		includeChildrenInExtras,
		costPriceCurrency,
		currencyPairs,
	])
	const totalPriceUsingAllocation = (paxAllocation || []).reduce<number>(
		(total, { price, pax_count }) =>
			total + Number(formatMoneyByDecimal(multipleMoneyBy(price, pax_count))),
		0
	)

	useEffect(() => {
		if (!includePerPersonPrice) {
			const h = setTimeout(() => {
				form.batch(() => {
					form.change("per_person_cost_price", undefined)
					form.change("per_person_given_price", undefined)
					form.change(
						`options[${optionIndex}].price_mismatch_between_per_person_and_total`,
						false
					)
					form.change(
						`options[${optionIndex}].per_person_cost_price_components.total`,
						undefined
					)
					form.change(
						`options[${optionIndex}].cost_prices_per_person`,
						undefined
					)
				})
			}, 100)
			return () => clearTimeout(h)
		}
	}, [includePerPersonPrice, form, optionIndex])

	// synchronise the total cost and selling per-person price
	useEffect(() => {
		if (includePerPersonPrice) {
			const h = setTimeout(() => {
				form.batch(() => {
					form.change(
						`options[${optionIndex}].per_person_cost_price_components.total`,
						!paxAllocation
							? undefined
							: formatMoneyByDecimal(
									moneyParseByDecimal(
										totalPriceUsingAllocation,
										costPriceCurrency
									)
								)
					)
					const priceMismatch = !paxAllocation
						? false
						: Number(
								formatMoneyByDecimal(
									moneyParseByDecimal(
										totalCostPriceExcludingFlights,
										costPriceCurrency
									)
								)
							) >
							Number(
								formatMoneyByDecimal(
									moneyParseByDecimal(
										totalPriceUsingAllocation,
										costPriceCurrency
									)
								)
							)
					form.change(
						`options[${optionIndex}].price_mismatch_between_per_person_and_total`,
						priceMismatch
					)
					form.change(
						`options[${optionIndex}].cost_prices_per_person`,
						!paxAllocation || priceMismatch
							? undefined
							: (paxAllocation.map(
									({
										id,
										price,
										type,
										per_pax_label,
										total_pax_label,
										accommodation,
										room_sharing_type,
										pax_count,
										currency,
										ages,
									}) => ({
										id,
										currency,
										price: formatMoneyByDecimal(price),
										type,
										per_pax_label,
										total_pax_label,
										pax_count,
										ages,
										accommodation,
										room_sharing_type,
									})
								) as Required<
									INewQuoteState["options"][number]
								>["cost_prices_per_person"])
					)
				})
			}, 100)

			return () => clearTimeout(h)
		}
	}, [
		optionIndex,
		paxAllocation,
		includePerPersonPrice,
		form,
		costPriceCurrency,
		totalPriceUsingAllocation,
		totalCostPriceExcludingFlights,
	])

	return {
		includePerPersonPrice,
		paxAllocation,
		paxAllocationError,
		paxAllocationWarnings,
		priceMismatchBetweenPerPersonAndTotal,
		totalCostPriceExcludingFlights,
		totalPriceUsingAllocation,
	}
}

function PerPersonCostPrice({ optionIndex }: { optionIndex: number }) {
	const { value: children } = useFieldValue<TChildrenArray>("children")
	const { value: costPriceCurrency } =
		useFieldValue<INewQuoteState["costing_currency"]>("costing_currency")
	const { hotelExtrasPrice } = useHotelsOption(optionIndex) || {}
	const { value: transportExtrasPrice } = useFieldValue<
		INewQuoteState["transportExtrasPrice"]
	>("transportExtrasPrice")
	const { value: otherExtrasPrice } =
		useFieldValue<INewQuoteState["otherExtrasPrice"]>("otherExtrasPrice")
	const { value: cabs } = useFieldValue<INewQuoteState["cabs"]>("cabs")

	const {
		includePerPersonPrice,
		paxAllocation,
		paxAllocationError,
		paxAllocationWarnings,
		priceMismatchBetweenPerPersonAndTotal,
		totalCostPriceExcludingFlights,
		totalPriceUsingAllocation,
	} = usePerPersonAllocation(optionIndex)

	const totalExtrasPrice = useMemo(
		() =>
			addMoneyFromDifferentCurrencies(
				([] as Array<TMoney>)
					.concat(hotelExtrasPrice || [])
					.concat(transportExtrasPrice)
					.concat(otherExtrasPrice)
			),
		[hotelExtrasPrice, transportExtrasPrice, otherExtrasPrice]
	)

	const hasCabs = useMemo(() => {
		return Boolean(
			cabs?.daywise_services?.some((s) =>
				s.services?.some((s) => s.type === "transport_service" && s.service)
			)
		)
	}, [cabs])

	return (
		<Stack gap="6">
			<QuotePriceInCostingCurrency optionIndex={optionIndex} />
			{includePerPersonPrice ? (
				<Grid gap="6">
					{paxAllocation?.length ? (
						<Col sm={12} lg={4} xl={3}>
							<Stack gap="4">
								<Heading fontSize="md" color="accent">
									Per-Person Settings
								</Heading>
								<PerPersonInclusionConfiguration
									children={children}
									paxAllocation={paxAllocation}
									optionIndex={optionIndex}
									totalExtrasPrice={totalExtrasPrice}
									hasCabs={hasCabs}
								/>
							</Stack>
						</Col>
					) : null}
					<Col>
						<Stack gap="4">
							{!paxAllocation ? (
								<Alert status="warning">
									<Stack gap="1">
										<Text>
											Per-person allocation is not possible with the given
											services and prices combination.
										</Text>
										{paxAllocationError?.message ? (
											<Text fontSize="sm">
												<b>Reason:</b> {paxAllocationError.message}
											</Text>
										) : null}
									</Stack>
								</Alert>
							) : (
								<>
									{paxAllocationWarnings?.length ? (
										<Alert
											status="warning"
											title="Below are the warnings when distributing prices amount pax"
										>
											<Stack
												gap="1"
												as="ul"
												listStyleType="decimal"
												marginLeft="4"
											>
												{paxAllocationWarnings.map((m, index) => (
													<Box as="li" key={index}>
														<Text fontSize="sm">{m}</Text>
													</Box>
												))}
											</Stack>
										</Alert>
									) : null}
									<Table
										headers={[
											"For",
											`Price (${costPriceCurrency})`,
											"Segregation",
										]}
										bordered
										responsive
										alignCols={{ 1: "center" }}
										rows={paxAllocation.map((p) => [
											<Text fontWeight="semibold">
												{p.per_pax_label} x {p.total_pax_label}
											</Text>,
											p.price ? (
												<Money money={p.price} fontWeight="semibold" />
											) : null,
											<Inline gap="4">
												{[
													p.hotels_price
														? joinAttributes(
																<Text>
																	<Text as="span" color="muted" fontSize="sm">
																		Hotels
																	</Text>
																	:{" "}
																	<Money
																		amount={p.hotels_price}
																		currency={costPriceCurrency}
																	/>
																</Text>
															)
														: null,
													p.cabs_price
														? joinAttributes(
																<Text>
																	<Text as="span" color="muted" fontSize="sm">
																		Cabs
																	</Text>
																	:{" "}
																	<Money
																		amount={p.cabs_price}
																		currency={costPriceCurrency}
																	/>
																</Text>
															)
														: null,
													p.travel_activities_price
														? joinAttributes(
																<Text>
																	<Text as="span" color="muted" fontSize="sm">
																		Activities
																	</Text>
																	:{" "}
																	<Money
																		amount={p.travel_activities_price}
																		currency={costPriceCurrency}
																	/>
																</Text>
															)
														: null,
													p.extras_price
														? joinAttributes(
																<Text>
																	<Text as="span" color="muted" fontSize="sm">
																		Extras
																	</Text>
																	:{" "}
																	<Money
																		amount={p.extras_price}
																		currency={costPriceCurrency}
																	/>
																</Text>
															)
														: null,
												]
													.filter(Boolean)
													.map((t, i) => (
														<Box key={i}>{t}</Box>
													))}
											</Inline>,
										])}
									/>
								</>
							)}
							{priceMismatchBetweenPerPersonAndTotal ? (
								<Alert title="Price Mismatch" status="error">
									<Stack gap="2">
										<Text>
											When adding per-person prices, the total is not equal to
											the Total of all cost prices. Total using Given Prices:{" "}
											<Money
												currency={costPriceCurrency}
												fontWeight="semibold"
												amount={totalCostPriceExcludingFlights}
												showCurrency
											/>
											. Total using Per Person:{" "}
											<Money
												currency={costPriceCurrency}
												fontWeight="semibold"
												amount={totalPriceUsingAllocation}
												showCurrency
											/>
										</Text>
										<Text>
											<Icons.LightBulb /> This generally happens when there are
											more rooms/beds than required.
										</Text>
									</Stack>
								</Alert>
							) : null}
						</Stack>
					</Col>
				</Grid>
			) : null}
			<Stack gap="4">
				<Heading fontSize="md" color="accent">
					Set Markup, Tax and Rounding
				</Heading>
				<SellingPrice optionIndex={optionIndex} />
			</Stack>
		</Stack>
	)
}

function KeyValueDetails({
	label,
	children,
}: {
	label: React.ReactNode
	children: React.ReactNode
}) {
	return (
		<Stack gap="1">
			<Box
				color="muted"
				textTransform="uppercase"
				letterSpacing="wider"
				fontSize="sm"
				fontWeight="semibold"
			>
				{label}
			</Box>
			<Text fontSize="md" fontWeight="semibold" whiteSpace="preserve">
				{children}
			</Text>
		</Stack>
	)
}

function StepIcon(props: React.ComponentProps<typeof Box>) {
	return (
		<Box
			display="inlineFlex"
			size="12"
			alignItems="center"
			justifyContent="center"
			bgColor="primary_emphasis"
			color="on_emphasis"
			rounded="full"
			{...props}
		/>
	)
}

function useTotalQuotePrice(optionIndex: number) {
	const totalHotelPrice = useTotalHotelPrice(optionIndex)
	const [
		totalCabsAndTravelActivitiesWithExtrasPrice,
		{ cabsPrice, travelActivitiesPrice, extrasPrice },
	] = useTotalCabsAndTravelActivitesWithExtrasPrice()
	const { value: flightPrice } =
		useFieldValue<INewQuoteState["flightPrice"]>("flightPrice")
	const { value: otherExtrasPrice } =
		useFieldValue<INewQuoteState["otherExtrasPrice"]>("otherExtrasPrice")

	const totalPrice = useMemo(
		() =>
			addMoneyFromDifferentCurrencies([
				...totalHotelPrice,
				...totalCabsAndTravelActivitiesWithExtrasPrice,
				...otherExtrasPrice,
				...flightPrice,
			]),
		[
			totalHotelPrice,
			totalCabsAndTravelActivitiesWithExtrasPrice,
			otherExtrasPrice,
			flightPrice,
		]
	)
	return {
		totalPrice,
		flightPrice,
		totalHotelPrice,
		totalCabsAndTravelActivitiesWithExtrasPrice:
			totalCabsAndTravelActivitiesWithExtrasPrice,
		cabsPrice,
		travelActivitiesPrice,
		extrasPrice,
		otherExtrasPrice,
	}
}
function useTotalQuotePriceInCostingCurrency(optionIndex: number) {
	const {
		flightPrice,
		totalHotelPrice,
		totalCabsAndTravelActivitiesWithExtrasPrice,
		otherExtrasPrice,
	} = useTotalQuotePrice(optionIndex)
	const { value: costingCurrency } =
		useFieldValue<INewQuoteState["costing_currency"]>("costing_currency")
	const currencyPairs = useCurrencyPairsArrayFieldValue()
	return useMemo(() => {
		const converter = makeCurrencyConverter(currencyPairs, true)

		const totalHotelPriceInCostingCurrency = converter(
			costingCurrency,
			totalHotelPrice
		)
		const totalCabsPriceInCostingCurrency = converter(
			costingCurrency,
			totalCabsAndTravelActivitiesWithExtrasPrice
		)
		const otherExtrasPriceInCostingCurrency = converter(
			costingCurrency,
			otherExtrasPrice
		)
		const flightPriceInCostingCurrency = converter(costingCurrency, flightPrice)
		return {
			totalPrice: addMoney(
				totalHotelPriceInCostingCurrency,
				totalCabsPriceInCostingCurrency,
				otherExtrasPriceInCostingCurrency,
				flightPriceInCostingCurrency
			),
			flightPrice: flightPriceInCostingCurrency,
			totalHotelPrice: totalHotelPriceInCostingCurrency,
			totalCabsPrice: totalCabsPriceInCostingCurrency,
			otherExtrasPrice: otherExtrasPriceInCostingCurrency,
		}
	}, [
		flightPrice,
		currencyPairs,
		costingCurrency,
		totalHotelPrice,
		totalCabsAndTravelActivitiesWithExtrasPrice,
		otherExtrasPrice,
	])
}

function PreviewData({
	startDate,
	days,
	adults,
	children,
}: {
	startDate: Date
	days: number
	adults: number
	children: TChildrenArray
}) {
	const { value: options } = useFieldValue<INewQuoteState["options"]>("options")
	// services
	const { value: cabs } = useFieldValue<INewQuoteState["cabs"]>("cabs")
	const { value: transportExtras } =
		useFieldValue<INewQuoteState["transportExtras"]>("transportExtras")
	const { value: otherExtras } =
		useFieldValue<INewQuoteState["otherExtras"]>("otherExtras")
	const { value: flights } = useFieldValue<INewQuoteState["flights"]>("flights")

	const id = useRef<number>(Math.random())
	const normalizedCabs = useMemo(() => normalizeDaywiseServices(cabs), [cabs])
	const quoteCabs = useMemo(
		() =>
			normalizedCabs.cabs
				.map((i) => ({
					...i,
					quote_id: id.current,
				}))
				.sort((a, b) => {
					return a.date > b.date ? 1 : a.date < b.date ? -1 : 0
				}),

		[normalizedCabs, id]
	)
	const givenPriceForCabs = normalizedCabs.given_price

	const quoteTravelActivities = useMemo(
		() =>
			normalizeDaywiseServices(cabs)
				.travel_activities.map((i) => ({
					...i,
					quote_id: id.current,
				}))
				.sort((a, b) => {
					return a.date > b.date ? 1 : a.date < b.date ? -1 : 0
				}),

		[cabs, id]
	)

	const quoteCabExtras = useMemo(
		() =>
			normalizeCabExtras(transportExtras)
				.map((i) => ({
					...i,
					quote_id: id.current,
				}))
				.sort((a, b) => {
					return a.date && b.date ? (a.date > b.date ? 1 : -1) : 0
				}),
		[transportExtras, id]
	)
	const quoteOtherExtras = useMemo(
		() =>
			normalizeOtherExtras(otherExtras)
				.map((i) => ({
					...i,
					quote_id: id.current,
				}))
				.sort((a, b) => {
					return a.date && b.date ? (a.date > b.date ? 1 : -1) : 0
				}),
		[otherExtras, id]
	)
	const quoteFlights = useMemo(
		() =>
			normalizeFlights(flights)
				.map((i) => ({
					...i,
					quote_id: id.current,
				}))
				.sort((a, b) => {
					return a.departs_at > b.departs_at ? 1 : -1
				}),
		[flights, id]
	)
	const errorsInCabs = useMemo(() => {
		const errorsInCabs: Array<{
			message: React.ReactNode
		}> = []
		if (
			quoteCabs.length &&
			(!givenPriceForCabs || !givenPriceForCabs.filter((t) => t.amount).length)
		) {
			errorsInCabs.push({
				message: "Total Transportation price is ZERO.",
			})
		}
		// now we will find if there is any misconfiguration with cab types
		// Here are some mis-configurations
		// - 1 Innova vs 2 Innova
		// - 1 Innova + 1 WagonR vs 2 Innova + 1 WagonR
		// Here are some valid configuration
		// - 1 Innova vs 2 WagonR
		// - 2 Innova vs 1 Innova + 1 WagonR

		// we will start by creating a group of cab types provided per tsp and date
		const cabConfigurationPerTransportService = quoteCabs.reduce<{
			[transportServiceIdPerDate: string]: {
				date: string
				transport_service: (typeof quoteCabs)[number]["transport_service"]
				cabs: Array<string>
				countPerCab: { [key: string]: number }
				noOfCabs: 0
			}
		}>((config, cab) => {
			if (cab.date && cab.transport_service && cab.cab_type && cab.no_of_cabs) {
				const key = `${cab.transport_service.id}_${String(cab.date)}`
				if (!config[key]) {
					config[key] = {
						date: cab.date,
						transport_service: cab.transport_service,
						cabs: [],
						countPerCab: {},
						noOfCabs: 0,
					}
				}
				config[key].cabs.push(cab.cab_type.name)
				config[key].countPerCab[cab.cab_type.name] = cab.no_of_cabs
				config[key].noOfCabs += cab.no_of_cabs
			}
			return config
		}, {})
		// now we will get unique cab types for each configuration
		// This will element these (possibly) valid cases
		// - 1 Innova vs 2 WagonR
		// - 2 Innova vs 1 Innova + 1 WagonR
		const cabTypesConfig = Object.values(
			Object.values(cabConfigurationPerTransportService).reduce<{
				[key: string]: Array<
					$Values<typeof cabConfigurationPerTransportService>
				>
			}>((u, config) => {
				const cabs = config.cabs.concat([])
				cabs.sort()
				const key = cabs.join("###")
				if (!u[key]) {
					u[key] = []
				}
				u[key].push({
					...config,
					cabs,
				})
				return u
			}, {})
		)
		// And we will find of we have some of these cases
		// - 1 Innova vs 2 Innova
		// - 1 Innova + 1 WagonR vs 2 Innova + 1 WagonR
		for (const cabs of cabTypesConfig) {
			// something might be wrong here
			// let create possible groups
			const groups = Object.values(
				cabs.reduce<{
					[key: string]: (typeof cabs)[number] & { services: typeof cabs }
				}>((groups, cab) => {
					const key = cab.cabs
						.map((c) => `${c}##${cab.countPerCab[c]}`)
						.join(",")
					if (!groups[key]) {
						groups[key] = { ...cab, services: [] }
					}
					groups[key].services.push(cab)
					return groups
				}, {})
			)
			if (groups.length > 1) {
				// now we will add misconfiguration messages
				const issues = []
				for (const cab of groups) {
					issues.push(
						<Text>
							Day:{" "}
							{cab.services
								.map(
									({ date }) =>
										getDiff(utcTimestampToLocalDate(date), startDate, "day") + 1
								)
								.join(",")}
							{" - "}
							<Text as="span" fontWeight="semibold">
								{cab.cabs.map((c) => `${cab.countPerCab[c]} ${c}`).join(" + ")}
							</Text>
						</Text>
					)
				}
				errorsInCabs.push({
					message: (
						<Box>
							<Text fontWeight="semibold">Possible Issue</Text>
							<Stack paddingLeft="4" as="ul" listStyleType="disc">
								{issues.map((issue, index) => (
									<Box key={index} as="li">
										{issue}
									</Box>
								))}
							</Stack>
						</Box>
					),
				})
			}
		}
		return errorsInCabs
	}, [quoteCabs, startDate, givenPriceForCabs])
	return (
		<Container fluid paddingY="8" bgColor="accent">
			<Stack gap="1">
				<Heading as="h3" id="quote_summary">
					Summary
				</Heading>
				<Text color="muted">
					Please review the quote's data before creating.
				</Text>
			</Stack>
			<Box>
				<Box
					padding="4"
					marginY="4"
					marginBottom="8"
					borderWidth="1"
					rounded="md"
					bgColor="default"
				>
					<Inline gap="8" flexWrap="wrap">
						<KeyValueDetails label="Start Date">
							{formatDate(startDate, "DD MMM, YYYY")}
						</KeyValueDetails>
						<KeyValueDetails label="Duration">
							{days > 1
								? `${pluralize("Nights", days - 1, true)}, ${pluralize(
										"Day",
										days,
										true
									)}`
								: pluralize("Day", days, true)}
						</KeyValueDetails>
						<KeyValueDetails label="Pax">
							{pluralize("Adult", adults, true)}
							{children.length ? (
								<span> with {childrenToQueryParams(children)} Children</span>
							) : null}
						</KeyValueDetails>
					</Inline>
				</Box>
				<Box marginBottom="8">
					<Stack gap="4">
						{options.map((o, index, arr) => (
							<Stack gap="2" key={o.__id}>
								{arr.length > 1 ? (
									<Heading color="accent">
										Option {index + 1}: {o.name}
									</Heading>
								) : null}
								<QuoteHotelsOptionPreview
									key={o.__id}
									optionIndex={index}
									id={id.current}
									startDate={startDate}
									adults={adults}
									children={children}
								/>
							</Stack>
						))}
					</Stack>
				</Box>
				<Box marginBottom="8">
					<QuoteTransportsAndActivityTickets
						transports={quoteCabs}
						transportExtras={quoteCabExtras}
						travelActivities={quoteTravelActivities}
						startDateUTC={dateToUTCString(startDate)}
						usedCabTypes={normalizedCabs.used_cab_types}
						cabsPriceCalculations={normalizedCabs.cabs_price_calculations}
						taxPercentage={normalizedCabs.cabs_tax_percentage}
					/>
					{errorsInCabs.length ? (
						<Box>
							<Alert
								status="warning"
								title="Misconfiguration in Transportation Services."
							>
								<Box as="p" marginBottom="2">
									There are some misconfiguration in transportation services.
									Please review them
								</Box>
								<Box
									as="ul"
									listStyleType="disc"
									marginLeft="4"
									marginBottom="4"
								>
									{errorsInCabs.map(({ message }, i) => (
										<Box as="li" key={i}>
											{message}
										</Box>
									))}
								</Box>
								<Box>
									<Button as="a" href="#quote_cabs" level="tertiary">
										<Icons.ChevronDown rotate="180" /> Back to transportation
									</Button>
								</Box>
								<Box fontSize="sm" marginTop="4">
									<Icons.Info /> You can skip these warnings if everything is as
									expected.
								</Box>
							</Alert>
						</Box>
					) : null}
				</Box>
				<Box marginBottom="8">
					<QuoteExtras
						extras={quoteOtherExtras}
						startDateUTC={dateToUTCString(startDate)}
					/>
				</Box>
				<QuoteFlights
					flights={quoteFlights}
					startDateUTC={dateToUTCString(startDate)}
				/>
			</Box>
			<Stack gap="2" alignItems="end" marginTop="4">
				<Inline gap="6" flexWrap="wrap">
					{options.map((o, index, arr) => (
						<Stack key={o.__id} gap="3">
							{arr.length > 1 ? (
								<Box>
									<Box
										display="inlineBlock"
										borderBottomWidth="2"
										borderColor="emphasis"
										paddingBottom="1"
									>
										<Text fontWeight="semibold">
											Option {index + 1}: {o.name}
										</Text>
									</Box>
								</Box>
							) : null}
							<TotalCostPrice optionIndex={index} />
						</Stack>
					))}
				</Inline>
				<Text fontSize="sm">
					It is the total cost price for all the provided services. This is NOT
					the selling price.
				</Text>
			</Stack>
		</Container>
	)
}

function QuoteHotelsOptionPreview({
	id,
	startDate,
	adults,
	children,
	optionIndex,
}: {
	id: number
	startDate: Date
	adults: number
	children: TChildrenArray
	optionIndex: number
}) {
	const { hotels, hotelExtras } = useHotelsOption(optionIndex) || {}

	const quoteHotels = useMemo(
		() =>
			normalizeHotels(hotels || [])
				.map((i) => ({
					...i,
					quote_id: id,
					similar_hotel_options: i.similar_hotel_options?.length
						? i.similar_hotel_options.map((s) => ({
								...s,
								quote_id: id + 10000,
							}))
						: undefined,
				}))
				.sort((a, b) => {
					return a.date > b.date ? 1 : a.date < b.date ? -1 : 0
				}),
		[hotels, id]
	)
	const quoteHotelExtras = useMemo(
		() =>
			normalizeHotelExtras(hotelExtras || [])
				.map((i) => ({
					...i,
					quote_id: id,
				}))
				.sort((a, b) => {
					return a.date && b.date ? (a.date > b.date ? 1 : -1) : 0
				}),
		[hotelExtras, id]
	)

	const errorsInHotels = useMemo(() => {
		const expectedAdultsCount = adults
		const errorsInHotels: Array<{
			date: Date
			messages: Array<string>
			isSimilarOption?: boolean
		}> = []
		collect(quoteHotels)
			.groupBy((h) => h.date)
			.toArray()
			.forEach((quoteHotelsForDate) => {
				function checkForErrors(
					quoteHotels: Array<
						Omit<(typeof quoteHotelsForDate)[number], "similar_hotel_options">
					>
				) {
					const messages: Array<string> = []
					const { totalAdultsInHotels, totalChildrenInHotels } =
						quoteHotels.reduce(
							(
								{ totalAdultsInHotels, totalChildrenInHotels },
								{
									no_of_rooms,
									persons_per_room,
									adults_with_extra_bed,
									children_with_extra_bed,
									children_without_extra_bed,
								}
							) => ({
								totalAdultsInHotels:
									totalAdultsInHotels +
									Number(no_of_rooms || 0) * Number(persons_per_room) +
									Number(adults_with_extra_bed || 0),
								totalChildrenInHotels:
									totalChildrenInHotels +
									Number(children_with_extra_bed || 0) +
									Number(children_without_extra_bed || 0),
							}),
							{ totalAdultsInHotels: 0, totalChildrenInHotels: 0 }
						)

					const complementaryChildren = children.filter((c) =>
						quoteHotels.find(
							(h) => Number(c.age) < Number(h.hotel.extra_bed_child_age_start)
						)
					)
					const expectedChildrenCount =
						children.length - complementaryChildren.length
					if (totalAdultsInHotels !== expectedAdultsCount) {
						messages.push(
							`Expected ${pluralize(
								"Adult",
								expectedAdultsCount,
								true
							)} but ${pluralize("Adult", totalAdultsInHotels, true)} provided`
						)
					}
					if (totalChildrenInHotels !== expectedChildrenCount) {
						messages.push(
							`Expected ${pluralize(
								"Child",
								expectedChildrenCount,
								true
							)} but ${pluralize(
								"Child",
								totalChildrenInHotels,
								true
							)} provided ${
								complementaryChildren.length > 0
									? `. ${pluralize(
											"Child",
											complementaryChildren.length,
											true
										)} (${childrenToQueryParams(
											complementaryChildren
										)}) counted as complementary`
									: ``
							}.`
						)
					}
					quoteHotels.forEach((h) => {
						if (
							h.room_type.no_of_rooms &&
							h.no_of_rooms &&
							h.no_of_rooms > h.room_type.no_of_rooms
						) {
							messages.push(
								`Only ${pluralize(
									"Room",
									h.room_type.no_of_rooms,
									true
								)} available but ${h.no_of_rooms} provided/required.`
							)
						}
						if (!h.given_price) {
							messages.push(`Missing given prices for ${h.hotel.name}`)
						}
					})
					return messages
				}
				const messages = checkForErrors(quoteHotelsForDate)
				const date = utcTimestampToLocalDate(quoteHotelsForDate[0].date)
				if (messages.length) {
					errorsInHotels.push({
						date,
						messages,
					})
				}
				const similar_hotel_options = quoteHotelsForDate.map((h) =>
					h.similar_hotel_options?.filter((s) => s.date === h.date)
				)
				similar_hotel_options.forEach((options) => {
					if (options && options.length) {
						const messages = checkForErrors(options)
						if (messages.length) {
							errorsInHotels.push({
								date,
								messages,
								isSimilarOption: true,
							})
						}
					}
				})
			})
		return errorsInHotels
	}, [quoteHotels, adults, children])
	return (
		<>
			<QuoteHotels
				hotels={quoteHotels}
				hotelExtras={quoteHotelExtras}
				startDateUTC={dateToUTCString(startDate)}
			/>
			{errorsInHotels.length ? (
				<Box>
					<Alert status="warning" title="Misconfiguration in Hotel Services.">
						<Box as="p" marginBottom="2">
							There are some misconfiguration in hotel services e.g. more pax,
							less rooms etc. Please review them
						</Box>
						<Stack as="ul" listStyleType="disc" marginBottom="4" gap="2">
							{errorsInHotels.map(({ date, messages, isSimilarOption }, i) => (
								<Inline gap="2" as="li" key={i}>
									<Text fontWeight="semibold">
										{formatDate(date, "DD MMM")}:
									</Text>
									<Stack>
										{isSimilarOption ? (
											<Text fontSize="sm" fontWeight="semibold">
												Similar Hotel/Option
											</Text>
										) : null}
										{messages.map((m, i) => (
											<Text key={i}>{m}</Text>
										))}
									</Stack>
								</Inline>
							))}
						</Stack>
						<Box>
							<Button as="a" href="#quote_hotels" level="tertiary">
								<Icons.ChevronDown rotate="180" /> Back to Hotels
							</Button>
						</Box>
						<Box fontSize="sm" marginTop="4">
							<Icons.Info /> You can skip these warnings if everything is as
							expected.
						</Box>
					</Alert>
				</Box>
			) : null}
		</>
	)
}

function TotalCostPrice({ optionIndex }: { optionIndex: number }) {
	const {
		totalPrice,
		flightPrice,
		totalHotelPrice,
		cabsPrice,
		travelActivitiesPrice,
		extrasPrice,
		otherExtrasPrice,
	} = useTotalQuotePrice(optionIndex)

	const sectionWiseTotalCostPrice = ([] as Array<[string, TMoney[]]>)
		.concat([
			["Hotels", totalHotelPrice],
			["Cabs", cabsPrice],
			["Activities/Tickets", travelActivitiesPrice],
			["Extras", extrasPrice],
			["Other Specials", otherExtrasPrice],
			["Flights", flightPrice],
		])
		.filter(([_label, money]) => money.length)
	return (
		<Inline gap="6" flexWrap="wrap" collapseBelow="sm">
			<Stack
				gap="1"
				padding="4"
				rounded="md"
				bgColor="primary"
				borderWidth="1"
				borderColor="primary"
			>
				<Text fontWeight="semibold" color="primary">
					Total Cost
				</Text>
				<Text fontSize="md">
					<MoneySum money={totalPrice} />
				</Text>
			</Stack>
			{sectionWiseTotalCostPrice.length ? (
				<Box overflow="auto" padding="4" rounded="md" borderWidth="1">
					<Inline gap="2" flexWrap="nowrap">
						{sectionWiseTotalCostPrice.map(([label, money], i) => (
							<Fragment key={label}>
								{i > 0 ? (
									<Stack
										padding="1"
										alignItems="center"
										justifyContent="center"
									>
										<Icons.Plus />
									</Stack>
								) : null}
								<Stack gap="1" whiteSpace="preserve">
									<Text fontWeight="semibold">{label}</Text>
									<Text fontSize="md">
										<MoneySum money={money} />
									</Text>
								</Stack>
							</Fragment>
						))}
					</Inline>
				</Box>
			) : null}
		</Inline>
	)
}

function PackagePricePreviewForOption({
	optionIndex,
}: {
	optionIndex: number
}) {
	const { flightPrice } = useTotalQuotePriceInCostingCurrency(optionIndex)
	return (
		<PackagePricePreview
			optionIndex={optionIndex}
			flightPriceInCostingCurrency={Number(formatMoneyByDecimal(flightPrice))}
		/>
	)
}

type INewQuoteState = {
	options: Array<{
		__id: number
		id: number
		name: string
		per_person_cost_price_components?: {
			include_children_in_cabs_start_age?: number
			include_children_in_cabs?: boolean | number
			include_adults_with_extra_bed_in_cabs?: boolean
			include_children_in_extras?: boolean
			total?: number
		}
		cost_prices_per_person?: Array<TPricePerPersonAllocation>
		price_mismatch_between_per_person_and_total?: boolean
		given_price?: number
		given_prices_per_person?: Array<TPricePerPersonAllocation>
		given_price_in_costing_currency?: number
		margins?: IGivenQuote["margins"]
		given_price_comments?: string
		existing_given_price_currency?: string
		existing_given_price?: number
	}>
	hotel_options: Array<{
		__id: number
		option_index: number | string
		hotels: IQuoteHotelParams
		hotelPrice: TMoney[]
		hotelExtras: IQuoteHotelExtraParams
		hotelExtrasPrice: TMoney[]
	}>
	cabs: IQuoteCabParams
	cabPrice: TMoney[]
	transportExtras: IQuoteCabExtraParams
	transportExtrasPrice: TMoney[]
	flights: IQuoteFlightParams
	flightPrice: TMoney[]
	otherExtras: ReturnType<typeof getEditableOtherExtras>
	otherExtrasPrice: TMoney[]
	comments: string
	isCreating: boolean
	errors?: null | { [key: string]: string | Array<string> | undefined }
	startDate: Date
	days: number
	no_of_adults: number
	children: TChildrenArray
	include_per_person_price?: boolean
	functional_currency: string
	currency_pairs: Array<TCurrencyPair>
	currency_pairs_obj?: TCurrencyPairsObject
	given_currency: string
	costing_currency: string
	tax_percentage: IGivenQuote["tax_percentage"]
	gst_included: IGivenQuote["gst_included"]
	tax_name: IGivenQuote["tax_name"]
	fetching_prices: boolean
}

function getQuoteInitialServicesValues({
	tripQuote,
	startDate,
	days,
	baseStartDate,
	baseNoOfAdults,
	baseChildren,
	noOfAdults,
	children,
	defaultCostingCurrency,
	isEditing,
	hasMultiQuoteFeature,
}: {
	tripQuote?: ITripQuote
	baseStartDate: Date
	baseNoOfAdults: number
	baseChildren: TChildrenArray
	startDate: Date
	days: number
	noOfAdults: number
	children: TChildrenArray
	defaultCostingCurrency: string
	isEditing: boolean
	hasMultiQuoteFeature: boolean
}): INewQuoteState {
	const tripQuotes: Array<Omit<ITripQuote, "options"> | undefined> = tripQuote
		? tripQuote.can_share_options && hasMultiQuoteFeature
			? [tripQuote].concat(tripQuote.options || [])
			: [tripQuote]
		: [undefined]
	const firstQuote = tripQuote?.quote
	const costingCurrency = tripQuote?.currency || defaultCostingCurrency
	const hasPaxChanged =
		Number(noOfAdults) !== Number(baseNoOfAdults) ||
		childrenToQueryParams(baseChildren) !== childrenToQueryParams(children)
	const hotelOptions = tripQuotes.reduce<INewQuoteState["hotel_options"]>(
		(hotelOptions, tripQuote, index) => {
			const { quote } = tripQuote || {}
			const hotels = quote
				? getEditableHotels(quote.hotels, clone(baseStartDate), startDate, days)
				: []
			const hotel_extras = quote
				? getEditableHotelExtras(
						quote.hotel_extras,
						clone(baseStartDate),
						startDate,
						days
					)
				: []

			const hotelPrice = getTotalPriceForServices(hotels)

			const hotelExtrasPrice = getTotalPriceForExtras(hotel_extras)
			return hotelOptions.concat([
				addKeyToFieldArrayItem({
					option_index: index,
					hotels,
					hotelPrice,
					hotelExtras: hotel_extras,
					hotelExtrasPrice,
				}),
			])
		},
		[]
	)
	const cabs = firstQuote
		? getEditableCabs(
				firstQuote,
				clone(baseStartDate),
				startDate,
				days,
				hasPaxChanged
			)
		: ({
				cabs: [],
				daywise_services: [],
				given_price: [moneyParseByDecimal(0, costingCurrency)],
				given_price_of_cabs_only: [moneyParseByDecimal(0, costingCurrency)],
				given_price_of_activities_only: [
					moneyParseByDecimal(0, costingCurrency),
				],
			} as IQuoteCabParams)
	const transport_extras = firstQuote
		? getEditableCabExtras(
				firstQuote.transport_extras,
				clone(baseStartDate),
				startDate,
				days
			)
		: []
	const flights = firstQuote
		? getEditableFlights(
				firstQuote.flights,
				clone(baseStartDate),
				startDate,
				days
			)
		: []
	const other_extras = firstQuote
		? getEditableOtherExtras(
				firstQuote.other_extras,
				clone(baseStartDate),
				startDate,
				days
			)
		: []

	const cabPrice = cabs.given_price
	const transportExtrasPrice = getTotalPriceForExtras(transport_extras)
	const flightPrice = getTotalPriceForExtras(flights)
	const otherExtrasPrice = getTotalPriceForExtras(other_extras)

	const tax_name = tripQuote?.given_quote ? tripQuote.given_quote.tax_name : ""

	const gstIncluded = tripQuote?.given_quote
		? tripQuote.given_quote.gst_included
			? "1"
			: "0"
		: "1"

	const tax_percentage =
		tripQuote?.given_quote?.tax_percentage === null ||
		tripQuote?.given_quote?.tax_percentage === undefined
			? 5
			: tripQuote?.given_quote?.tax_percentage

	const given_currency =
		tripQuote?.given_quote?.given_currency || costingCurrency
	const functional_currency =
		tripQuote?.given_quote?.functional_currency || costingCurrency
	const currency_pairs: TCurrencyPair[] = tripQuote?.given_quote
		?.currency_pairs || [
		{
			baseCurrency: costingCurrency,
			counterCurrency: costingCurrency,
			ratio: 1,
		},
	]

	const flightPriceInCostingCurrency = makeCurrencyConverter(currency_pairs)(
		costingCurrency,
		flightPrice
	)

	const tripQuoteOptions = tripQuotes // atleast one option
		.map(function (tripQuote, optionIndex) {
			const costPriceWithoutFlights = Number(
				formatMoneyByDecimal(
					subtractMoney(
						moneyParseByDecimal(tripQuote?.cost_price || 0, costingCurrency),
						flightPriceInCostingCurrency
					)
				)
			)

			const margin = tripQuote?.given_quote
				? calculateMarginFromTotalPrice({
						givenPrice: tripQuote.given_quote.given_price,
						costPriceWithoutFlights,
						flightPrice: Number(
							formatMoneyByDecimal(flightPriceInCostingCurrency)
						),
						gstIncluded: Boolean(Number(gstIncluded)),
						taxPercentage: tax_percentage,
					})
				: 0

			const margin_percentage = calculateMarginPercentageFromTotalPrice({
				costPriceWithoutFlights,
				margin,
			})

			const cost_prices_per_person =
				(tripQuote?.cost_prices_per_person as INewQuoteState["options"][number]["cost_prices_per_person"]) ||
				[]
			const margins = {
				total: margin,
				total_percentage: margin_percentage,
				use_percentage: 1,
				editing_total: tripQuote?.given_quote?.margins ? 0 : 1,
				rounding_point: isEditing ? "" : 10,
				...tripQuote?.given_quote?.margins,
			} as INewQuoteState["options"][number]["margins"]

			// migrate the old margins from old per_person_id to new one
			// TODO: Remove if after 2 months
			if (cost_prices_per_person.length) {
				const per_person_margins_allocation =
					margins?.per_person_allocation || {}
				cost_prices_per_person.forEach((c, index) => {
					// migration when we removed price from the ID
					per_person_margins_allocation[
						c.per_pax_label.replace(/[\W]/gi, "_")
					] = per_person_margins_allocation[c.id]
					// migration when we added serial number to the ID
					per_person_margins_allocation[
						`${index}_` + c.per_pax_label.replace(/[\W]/gi, "_")
					] = per_person_margins_allocation[c.id]
				})
			}

			const per_person_cost_price_components =
				(tripQuote?.per_person_cost_price_components as INewQuoteState["options"][number]["per_person_cost_price_components"]) ||
				{}

			if (
				isTruthy(per_person_cost_price_components.include_children_in_cabs) &&
				per_person_cost_price_components.include_children_in_cabs_start_age ===
					undefined
			) {
				// set the default start age
				per_person_cost_price_components.include_children_in_cabs_start_age = 6
			}

			return addKeyToFieldArrayItem({
				id: optionIndex,
				name: tripQuote?.name || `Deluxe Package`,
				given_price_comments: tripQuote?.given_quote?.comments || "",
				margins,
				per_person_cost_price_components: per_person_cost_price_components,
				cost_prices_per_person,
				given_prices_per_person:
					undefined as INewQuoteState["options"][number]["given_prices_per_person"],
				given_price: 0, // this will be auto calculated
				given_price_in_costing_currency: 0, // this will be auto calculate
				existing_given_price_currency: isEditing
					? tripQuote?.given_quote?.given_currency
					: undefined,
				existing_given_price: isEditing
					? tripQuote?.given_quote?.given_price
					: undefined,
			})
		})

	// only keep the last exchagne rates if editing
	const currency_pairs_obj = isEditing
		? currencyPairsArrayToObject(currency_pairs)
		: {}
	return {
		startDate,
		days,
		no_of_adults: noOfAdults,
		children,
		hotel_options: hotelOptions,
		cabs: cabs,
		cabPrice,
		transportExtras: transport_extras,
		transportExtrasPrice,
		flights: flights,
		flightPrice,
		otherExtras: other_extras,
		otherExtrasPrice,
		comments: firstQuote?.comments || "",
		options: tripQuoteOptions,
		include_per_person_price: Boolean(
			tripQuote?.cost_prices_per_person?.length
		),
		tax_name,
		gst_included: isTruthy(gstIncluded),
		tax_percentage,
		costing_currency: costingCurrency,
		given_currency,
		currency_pairs,
		functional_currency,
		currency_pairs_obj,
		isCreating: false,
		fetching_prices: true,
	}
}

function getEditableCabs(
	{
		cabs,
		travel_activities,
		used_cab_types,
		cabs_price_calculation_configurations,
		cabs_price_calculations,
		cabs_tax_percentage,
	}: Pick<
		IQuote,
		| "id"
		| "cabs"
		| "travel_activities"
		| "used_cab_types"
		| "cabs_price_calculation_configurations"
		| "cabs_price_calculations"
		| "cabs_tax_percentage"
	>,
	initialStartDate: Date,
	newStartDate: Date,
	durationInDays: number,
	clearTicketConfiguration: boolean
): IQuoteCabParams {
	let given_price_of_cabs_only: Array<TMoney> = []
	let given_price_of_activities_only: Array<TMoney> = []
	const daywise_services: IQuoteCabParams["daywise_services"] = []
	if (cabs.at(0)?.group_id || travel_activities.at(0)?.group_id) {
		// using the new process
		const transportServices = collect(cabs)
			.groupBy((c) => c.group_id || "")
			.toArray()
			.map((cabs) => {
				const group_id = cabs[0].group_id
				const uniqueUTCTimestamps = collect(
					cabs.map(
						(c) => [c.date, c.date_local] as [string, string | undefined]
					)
				)
					.unique((i) => i[0])
					.toArray()
				const utcTimestampToLocalDatesMap = uniqueUTCTimestamps.reduce(
					(dates, [utcTimestamp, localTimestamp]) => {
						const date = localOrUtcTimestampToLocalDate(
							localTimestamp,
							utcTimestamp
						)
						const stayDayDiff = getDiff(date, initialStartDate, "days")
						// remove the dates if it is not withing the duration range
						const newDate =
							stayDayDiff < durationInDays
								? startOf(addUnit(newStartDate, stayDayDiff, "days"), "day")
								: undefined
						dates[utcTimestamp] = newDate
						return dates
					},
					{} as Record<string, Date | undefined>
				)
				const newDates = uniqueUTCTimestamps
					.map((c) => utcTimestampToLocalDatesMap[c[0]])
					.filter((nd): nd is Date => Boolean(nd))
				const services = collect(cabs)
					.groupBy((c) => `${c.sort_order}-${c.transport_service_id}`)
					.toArray()
					.map(
						(
							cabs
						): {
							sort_order: number
							transport_service?: TValidTransportServiceDatewisePricesFieldValue & {
								id: number
							}
							travel_activity?: TValidTravelActivityDatewisePricesInputFieldValue & {
								id: number
							}
						} => {
							const { id, sort_order, transport_service, comments } = cabs[0]
							const transportServiceValidData = {
								id,
								dates: newDates,
								transport_service,
								transport_service_location: transport_service.locations,
								comments,
								cabs: collect(cabs)
									.groupBy(
										(c) =>
											`${c.no_of_cabs}-${c.cab_type_id}-${c.cab_locality?.id || "."}-${c.currency}`
									)
									.toArray()
									.map((cabs) => {
										const { no_of_cabs, cab_type, cab_locality } = cabs[0]
										return addKeyToFieldArrayItem({
											no_of_cabs,
											cab_type,
											cab_locality,
											date_wise_prices: cabs.flatMap(
												({
													date: utcTimestamp,
													date_local: localTimestamp,
													currency,
													calculated_price,
													given_price,
												}) => {
													const date = localOrUtcTimestampToLocalDate(
														localTimestamp,
														utcTimestamp
													)
													const newDate =
														utcTimestampToLocalDatesMap[utcTimestamp]
													let edited_given_price =
														calculated_price !== given_price
													// reset the given price if date has changed
													if (newDate && !isSame(newDate, date, "day")) {
														given_price = 0
														edited_given_price = false
													}
													return newDate
														? [
																{
																	currency,
																	price: Number(calculated_price || 0),
																	given_price: Number(given_price || 0),
																	per_quantity_given_price:
																		Number(given_price || 0) /
																		(no_of_cabs || 1),
																	edited_given_price,
																	date: newDate,
																},
															]
														: []
												}
											),
										})
									}),
							}
							return {
								sort_order: sort_order || 0,
								transport_service: transportServiceValidData,
								travel_activity: undefined,
							}
						}
					)
				return {
					group_id,
					dates: newDates,
					services,
				}
			})

		given_price_of_cabs_only = transportServices.reduce<Array<TMoney>>(
			(total, { services }) =>
				addMoneyFromDifferentCurrencies(
					total.concat(
						services
							.flatMap((s) => s.transport_service?.cabs || [])
							.reduce<
								Array<TMoney>
							>((total, { date_wise_prices }) => addMoneyFromDifferentCurrencies(total.concat((date_wise_prices || []).reduce<TMoney[]>((total, { given_price, currency }) => addMoneyFromDifferentCurrencies(total.concat([moneyParseByDecimal(given_price || 0, currency)])), []))), []),
						[]
					)
				),
			[]
		)
		// using the new process
		const travelActivities = collect(travel_activities)
			.groupBy((c) => c.group_id || "")
			.toArray()
			.map((travelActivities) => {
				const group_id = travelActivities[0].group_id
				const uniqueUTCTimestamps = collect(
					travelActivities.map(
						(c) => [c.date, c.date_local] as [string, string | undefined]
					)
				)
					.unique((s) => s[0])
					.toArray()
				const utcTimestampToLocaDatesMap = uniqueUTCTimestamps.reduce(
					(dates, [utcTimestamp, localUtcTimestamp]) => {
						const date = localOrUtcTimestampToLocalDate(
							localUtcTimestamp,
							utcTimestamp
						)
						const stayDayDiff = getDiff(date, initialStartDate, "days")
						// remove the dates if it is not withing the duration range
						const newDate =
							stayDayDiff < durationInDays
								? startOf(addUnit(newStartDate, stayDayDiff, "days"), "day")
								: undefined
						dates[utcTimestamp] = newDate
						return dates
					},
					{} as Record<string, Date | undefined>
				)
				const newDates = uniqueUTCTimestamps
					.map((c) => utcTimestampToLocaDatesMap[c[0]])
					.filter((nd): nd is Date => Boolean(nd))
				const services = collect(travelActivities)
					.groupBy((s) =>
						[
							s.sort_order,
							s.activity.id,
							s.ticket_type?.id || ".",
							s.duration || ".",
							s.slot || ".",
						].join("-")
					)
					.toArray()
					.map(
						(
							activities
						): {
							travel_activity?: TValidTravelActivityDatewisePricesInputFieldValue & {
								id: number
							}
							sort_order: number
							transport_service?: TValidTransportServiceDatewisePricesFieldValue & {
								id: number
							}
						} => {
							const {
								id,
								sort_order,
								activity,
								ticket_type,
								duration,
								slot,
								comments,
							} = activities[0]
							const ticket_tourist_configurations = clearTicketConfiguration
								? []
								: collect(
										activities.flatMap((a) =>
											a.ticket_tourist_configurations.map((c) => ({
												...c,
												date: a.date,
												date_local: a.date_local,
											}))
										)
									)
										.groupBy((c) =>
											[c.currency, c.quantity, c.configuration.id].join("-")
										)
										.toArray()
										.map((configs) => {
											const { currency, quantity, configuration } = configs[0]
											return addKeyToFieldArrayItem({
												quantity,
												configuration,
												date_wise_prices: configs.flatMap(
													({
														calculated_price,
														given_price,
														per_quantity_given_price,
														date: utcTimestamp,
														date_local: localTimestamp,
													}) => {
														const date = localOrUtcTimestampToLocalDate(
															localTimestamp,
															utcTimestamp
														)
														const newDate =
															utcTimestampToLocaDatesMap[utcTimestamp]
														let edited_given_price =
															calculated_price !== given_price
														// reset the given price if date has changed
														if (newDate && !isSame(newDate, date, "day")) {
															given_price = 0
															edited_given_price = false
															per_quantity_given_price = 0
														}
														return newDate
															? [
																	{
																		currency,
																		price: Number(calculated_price || 0),
																		given_price: Number(given_price || 0),
																		per_quantity_given_price: Number(
																			per_quantity_given_price || 0
																		),
																		edited_given_price,
																		date: newDate,
																	},
																]
															: []
													}
												),
											})
										})

							return {
								sort_order: sort_order || 0,
								transport_service: undefined,
								travel_activity: {
									id,
									dates: newDates,
									activity,
									ticket_type,
									duration: ticket_type?.durations?.find(
										(d) => d.iso === duration
									),
									slot,
									ticket_tourist_configurations,
									comments,
								},
							}
						}
					)
				return {
					group_id,
					dates: newDates,
					services,
				}
			})
		given_price_of_activities_only = travelActivities.reduce<Array<TMoney>>(
			(total, { services }) =>
				addMoneyFromDifferentCurrencies(
					total.concat(
						services
							.flatMap(
								(s) => s.travel_activity?.ticket_tourist_configurations || []
							)
							.reduce<
								Array<TMoney>
							>((total, { date_wise_prices }) => addMoneyFromDifferentCurrencies(total.concat((date_wise_prices || []).reduce<TMoney[]>((total, { given_price, currency }) => addMoneyFromDifferentCurrencies(total.concat([moneyParseByDecimal(given_price || 0, currency)])), []))), []),
						[]
					)
				),
			[]
		)
		const data = collect(
			(
				[] as Array<
					(typeof transportServices)[number] | (typeof travelActivities)[number]
				>
			)
				.concat(transportServices)
				.concat(travelActivities)
		)
			.groupBy((s) => s.group_id || "")
			.toArray()
		// sort by the group ordering
		data.sort((a, b) => {
			const aGroup = a[0]?.group_id || Infinity
			const bGroup = b[0]?.group_id || Infinity
			if (aGroup === bGroup) return 0
			return aGroup - bGroup
		})
		data.forEach((groupedServices) => {
			const { dates } = groupedServices[0]
			const services: (typeof groupedServices)[number]["services"] =
				groupedServices.flatMap((g) => g.services)
			services.sort((a, b) => a.sort_order - b.sort_order)
			daywise_services.push(
				addKeyToFieldArrayItem({
					dates,
					services: services
						.map((s) =>
							s.transport_service
								? addKeyToFieldArrayItem({
										type: "transport_service" as const,
										service: s.transport_service,
									})
								: s.travel_activity
									? addKeyToFieldArrayItem({
											type: "travel_activity" as const,
											service: s.travel_activity,
										})
									: undefined
						)
						.filter((s): s is Exclude<typeof s, undefined> => Boolean(s)),
				})
			)
		})
	} else {
		const cabParams = collect(cabs)
			.groupBy((c) => `${c.date}-${c.transport_service_id}`)
			.toArray()
			.map((cabs) => {
				const date = utcTimestampToLocalDate(cabs[0].date)
				const stayDayDiff = getDiff(date, initialStartDate, "days")
				// remove the dates if it is not withing the duration range
				const newDate =
					stayDayDiff < durationInDays
						? startOf(addUnit(newStartDate, stayDayDiff, "days"), "day")
						: undefined
				const cabParams = cabs.map(
					({
						currency,
						no_of_cabs,
						cab_locality,
						cab_type,
						given_price,
						calculated_price,
					}) => {
						let edited_given_price = calculated_price !== given_price
						// reset the given price if date has changed
						if (newDate && !isSame(newDate, date, "day")) {
							given_price = 0
							edited_given_price = false
						}
						return addKeyToFieldArrayItem({
							no_of_cabs,
							cab_type,
							cab_locality,
							date_wise_prices: newDate
								? [
										{
											currency,
											price: Number(calculated_price || 0),
											given_price: Number(given_price || 0),
											per_quantity_given_price:
												Number(given_price || 0) / (no_of_cabs || 1),
											edited_given_price,
											date: newDate,
										},
									]
								: [],
						})
					}
				)
				const { id, transport_service, comments } = cabs[0]

				return {
					id,
					dates: newDate ? [newDate] : [],
					transport_service,
					transport_service_location: transport_service.locations,
					cabs: cabParams,
					comments,
				}
			})
		const data = cabParams.reduce(
			(
				cabs: Array<
					(typeof cabParams)[number] & {
						travel_activities: Array<TValidTravelActivityDatewisePricesInputFieldValue>
					}
				>,
				cab
			) => {
				// Try to merge the cabs if sames dates and same service exists
				const foundIndex = cabs.findIndex(
					(c) =>
						c.transport_service.id === cab.transport_service.id &&
						c.comments === cab.comments &&
						c.dates.every((d) => cab.dates.find((a) => isSame(a, d, "day")))
				)
				if (foundIndex !== -1) {
					const existing = cabs[foundIndex]
					existing.cabs = existing.cabs.concat(cab.cabs)
				} else {
					cabs.push({
						...cab,
						travel_activities: [],
						transport_service_location: cab.transport_service.locations,
					})
				}
				return cabs
			},
			[]
		)
		given_price_of_activities_only = data.reduce<Array<TMoney>>(
			(total, { cabs }) =>
				addMoneyFromDifferentCurrencies(
					total.concat(
						(cabs || []).reduce<Array<TMoney>>(
							(total, { date_wise_prices }) =>
								addMoneyFromDifferentCurrencies(
									total.concat(
										(date_wise_prices || []).reduce<TMoney[]>(
											(total, { given_price, currency }) =>
												addMoneyFromDifferentCurrencies(
													total.concat([
														moneyParseByDecimal(given_price || 0, currency),
													])
												),
											[]
										)
									)
								),
							[]
						),
						[]
					)
				),
			[]
		)

		const travelActivities = getEditableTravelActivities(
			travel_activities || [],
			initialStartDate,
			newStartDate,
			durationInDays,
			clearTicketConfiguration
		)
		given_price_of_activities_only = travelActivities.reduce<Array<TMoney>>(
			(total, { travel_activities }) =>
				addMoneyFromDifferentCurrencies(
					total.concat(
						(travel_activities || [])
							.flatMap((t) => t.ticket_tourist_configurations)
							.reduce<
								Array<TMoney>
							>((total, { date_wise_prices }) => addMoneyFromDifferentCurrencies(total.concat((date_wise_prices || []).reduce<TMoney[]>((total, { given_price, currency }) => addMoneyFromDifferentCurrencies(total.concat([moneyParseByDecimal(given_price || 0, currency)])), []))), []),
						[]
					)
				),
			[]
		)
		const detachedTravelActivities = travelActivities.filter((activity) => {
			// put the activity to the first matching transport service
			const cab = data.find((cab) => {
				return String(activity.quote_cab_id || "0") === String(cab.id)
			})
			if (cab) {
				if (!cab.travel_activities) {
					cab.travel_activities = []
				}
				cab.travel_activities = cab.travel_activities.concat(
					activity.travel_activities
				)
				return false
			} else {
				return true
			}
		})
		const mergedSimilarDayActivities: typeof detachedTravelActivities = []
		detachedTravelActivities.forEach((a) => {
			const existing = mergedSimilarDayActivities.find(
				(d) =>
					d.dates.length === a.dates.length &&
					d.dates.every((date) => a.dates.find((d) => isSame(d, date, "day")))
			)
			if (existing) {
				existing.travel_activities = existing.travel_activities.concat(
					a.travel_activities
				)
			} else {
				mergedSimilarDayActivities.push(
					addKeyToFieldArrayItem({
						dates: a.dates,
						travel_activities: a.travel_activities,
					})
				)
			}
		})

		data.forEach(({ travel_activities, dates, ...service }) => {
			daywise_services.push(
				addKeyToFieldArrayItem({
					dates,
					services: (
						[] as IQuoteCabParams["daywise_services"][number]["services"]
					)
						.concat([
							addKeyToFieldArrayItem({
								type: "transport_service",
								service,
							}),
						])
						.concat(
							travel_activities.map((t) =>
								addKeyToFieldArrayItem({
									type: "travel_activity",
									service: t,
								})
							)
						),
				})
			)
		})
		// Now push the mergedSimilarDayActivities into daywise_services
		mergedSimilarDayActivities.forEach((activity) => {
			daywise_services.push(
				addKeyToFieldArrayItem({
					dates: activity.dates,
					services: activity.travel_activities.map((activity) =>
						addKeyToFieldArrayItem({
							type: "travel_activity",
							service: activity,
						})
					),
				})
			)
		})
	}

	const given_price = addMoneyFromDifferentCurrencies(
		given_price_of_cabs_only.concat(given_price_of_activities_only)
	)

	return {
		given_price,
		given_price_of_cabs_only,
		given_price_of_activities_only,
		used_cab_types,
		daywise_services,
		cabs_tax_percentage: cabs_tax_percentage
			? Number(cabs_tax_percentage)
			: undefined,
		cabs_price_calculation_configurations:
			cabs_price_calculation_configurations?.map((c) => ({
				...c,
				services: c.services.reduce(
					(services, s) => {
						if (!s.date) return services.concat([{ ...s, date: undefined }])
						const date = utcTimestampToLocalDate(s.date)
						const stayDayDiff = getDiff(date, initialStartDate, "days")
						// remove the dates if it is not withing the duration range
						const newDate =
							stayDayDiff < durationInDays
								? startOf(addUnit(newStartDate, stayDayDiff, "days"), "day")
								: undefined
						if (newDate) {
							return services.concat([
								{
									...s,
									date: newDate,
								},
							])
						}
						return services
					},
					[] as Required<IQuoteCabParams>["cabs_price_calculation_configurations"][number]["services"]
				),
			})),
		cabs_price_calculations: cabs_price_calculations?.map((c) => ({
			...c,
			metric_wise_prices: c.metric_wise_prices.map((m) => {
				const edited_given_quantity = m.quantity !== m.given_quantity
				const edited_given_price =
					m.per_quantity_given_price !== m.per_quantity_price
				return addKeyToFieldArrayItem({
					...m,
					per_quantity_given_price: Number(m.per_quantity_given_price),
					edited_given_quantity,
					edited_given_price,
				})
			}),
			services: c.services.reduce(
				(services, s) => {
					if (!s.date)
						return (services || []).concat([{ ...s, date: undefined }])
					const date = utcTimestampToLocalDate(s.date)
					const stayDayDiff = getDiff(date, initialStartDate, "days")
					// remove the dates if it is not withing the duration range
					const newDate =
						stayDayDiff < durationInDays
							? startOf(addUnit(newStartDate, stayDayDiff, "days"), "day")
							: undefined
					if (newDate) {
						return (services || []).concat([
							{
								...s,
								date: newDate,
							},
						])
					}
					return services
				},
				[] as Required<IQuoteCabParams>["cabs_price_calculations"][number]["services"]
			),
		})),
		add_cab_prices_at_once: cabs_price_calculations?.length ? true : false,
	}
}

function getEditableTravelActivities(
	travelActivities: $PropertyType<IQuote, "travel_activities">,
	initialStartDate: Date,
	newStartDate: Date,
	durationInDays: number,
	clearTicketConfiguration: boolean
): Array<
	IQuoteTravelActivityParams["travel_activities"][number] & {
		quote_cab_id?: number
	}
> {
	return collect(travelActivities)
		.groupBy((s) =>
			[
				s.activity.id,
				s.ticket_type?.id || ".",
				s.duration || ".",
				s.slot || ".",
				s.quote_cab_id || ".",
			].join("-")
		)
		.toArray()
		.map((travelActivities) => {
			const data = travelActivities.map(
				({
					date: utcDate,
					date_local: localDate,
					ticket_tourist_configurations,
				}) => {
					const date = localOrUtcTimestampToLocalDate(localDate, utcDate)
					const stayDayDiff = getDiff(date, initialStartDate, "days")
					// remove the dates if it is not withing the duration range
					const newDate =
						stayDayDiff < durationInDays
							? startOf(addUnit(newStartDate, stayDayDiff, "days"), "day")
							: undefined

					return addKeyToFieldArrayItem({
						dates: newDate ? [newDate] : [],
						ticket_tourist_configurations: clearTicketConfiguration
							? []
							: ticket_tourist_configurations.map(
									({
										calculated_price,
										given_price,
										currency,
										per_quantity_given_price,
										...config
									}) => {
										let edited_given_price = calculated_price !== given_price
										// reset the given price if date has changed
										if (newDate && !isSame(newDate, date, "day")) {
											given_price = 0
											edited_given_price = false
											per_quantity_given_price = 0
										}
										return addKeyToFieldArrayItem({
											...config,
											date_wise_prices: newDate
												? [
														{
															currency,
															price: Number(calculated_price || 0),
															given_price: Number(given_price || 0),
															per_quantity_given_price: Number(
																per_quantity_given_price || 0
															),
															edited_given_price,
															date: newDate,
														},
													]
												: [],
										})
									}
								),
					})
				}
			)
			const dates = data.flatMap((d) => d.dates)
			const { duration, ticket_type, quote_cab_id } = travelActivities[0]
			const ticket_tourist_configurations = collect(
				data.flatMap((d) => d.ticket_tourist_configurations)
			)
				.groupBy((s) => s.configuration.id)
				.toArray()
				.map((configs) => ({
					...configs[0],
					date_wise_prices: configs.flatMap((s) => s.date_wise_prices),
				}))
			return addKeyToFieldArrayItem({
				dates: dates,
				quote_cab_id,
				travel_activities: [
					addKeyToFieldArrayItem({
						...travelActivities[0],
						duration: ticket_type?.durations?.find((d) => d.iso === duration),
						dates,
						ticket_tourist_configurations,
					}),
				],
			})
		})
}

function getEditableCabExtras(
	extras: $PropertyType<IQuote, "transport_extras">,
	initialStartDate: Date,
	newStartDate: Date,
	durationInDays: number
): IQuoteCabExtraParams {
	return extras.map(
		({ given_price, date, date_local, comments, ...others }) => {
			let newDate: Date | undefined = undefined
			if (date) {
				const stayDayDiff = getDiff(
					localOrUtcTimestampToLocalDate(date_local, date),
					initialStartDate,
					"days"
				)
				// remove the dates if it is not withing the duration range
				newDate =
					stayDayDiff < durationInDays
						? startOf(addUnit(newStartDate, stayDayDiff, "days"), "day")
						: undefined
			}
			return {
				...others,
				price: given_price,
				date: date ? newDate : undefined,
				comments: comments || "",
			}
		}
	)
}

function getEditableOtherExtras(
	extras: $PropertyType<IQuote, "other_extras">,
	initialStartDate: Date,
	newStartDate: Date,
	durationInDays: number
): IQuoteOtherExtraParams {
	return extras.map(
		({ given_price, date, date_local, comments, ...others }) => {
			let newDate: Date | undefined = undefined
			if (date) {
				const stayDayDiff = getDiff(
					localOrUtcTimestampToLocalDate(date_local, date),
					initialStartDate,
					"days"
				)
				// remove the dates if it is not withing the duration range
				newDate =
					stayDayDiff < durationInDays
						? startOf(addUnit(newStartDate, stayDayDiff, "days"), "day")
						: undefined
			}
			return {
				...others,
				price: given_price,
				date: date ? newDate : undefined,
				comments: comments || "",
			}
		}
	)
}

function getEditableFlights(
	flights: $PropertyType<IQuote, "flights">,
	initialStartDate: Date,
	newStartDate: Date,
	durationInDays: number
): IQuoteFlightParams {
	return flights.map(
		({
			departs_at,
			departs_at_local,
			arrives_at,
			arrives_at_local,
			comments,
			...others
		}) => {
			const departsAtDaysDiff = getDiff(
				localOrUtcTimestampToLocalDate(departs_at_local, departs_at),
				initialStartDate,
				"days"
			)
			const arrivesAtDaysDiff = getDiff(
				localOrUtcTimestampToLocalDate(arrives_at_local, arrives_at),
				initialStartDate,
				"days"
			)
			// remove the dates if it is not withing the duration range
			const newDepartsAt =
				departsAtDaysDiff < durationInDays
					? parseDate(
							formatDate(
								addUnit(newStartDate, departsAtDaysDiff, "days"),
								"YYYY-MM-DD "
							) +
								formatDate(
									localOrUtcTimestampToLocalDate(departs_at_local, departs_at),
									"HH:mm:ss"
								),
							"YYYY-MM-DD HH:mm:ss"
						)
					: undefined
			const newArrivesAt =
				arrivesAtDaysDiff < durationInDays
					? parseDate(
							formatDate(
								addUnit(newStartDate, arrivesAtDaysDiff, "days"),
								"YYYY-MM-DD "
							) +
								formatDate(
									localOrUtcTimestampToLocalDate(arrives_at_local, arrives_at),
									"HH:mm:ss"
								),
							"YYYY-MM-DD HH:mm:ss"
						)
					: undefined
			return {
				...others,
				departs_at: newDepartsAt || clone(newStartDate),
				arrives_at: newArrivesAt || addUnit(newStartDate, 2, "hours"),
				comments: comments || "",
			}
		}
	)
}

function normalizeDaywiseServices(cabs: IQuoteCabParams) {
	const {
		daywise_services,
		cabs_price_calculations,
		cabs_price_calculation_configurations,
		...others
	} = cabs
	let sortOrder = 0
	const { transportServices, activities } = daywise_services.reduce(
		({ transportServices, activities }, { dates, services }, currentIndex) => {
			const groupId = currentIndex + 1
			services.forEach(({ type, service }) => {
				sortOrder++
				if (type === "transport_service") {
					const { cabs: selectedCabTypes, comments, ...otherData } = service
					dates.forEach((date, i) => {
						selectedCabTypes.forEach((cab) => {
							const {
								date_wise_prices,
								fetching_prices,
								cab_type,
								no_of_cabs,
								...otherCab
							} = cab
							if (!cab_type || !no_of_cabs) {
								return
							}
							const { price, given_price, booked_price, currency } =
								date_wise_prices?.at(i) ||
									date_wise_prices?.at(0) || {
										currency: "INR",
										price: 0,
										given_price: 0,
										booked_price: 0,
									}
							transportServices.push({
								...otherData,
								...otherCab,
								date: toISOString(startOf(date, "day")),
								cab_type,
								no_of_cabs,
								cab_type_id: cab_type.id,
								transport_service_id: otherData.transport_service.id,
								currency,
								calculated_price: price,
								given_price: given_price || 0,
								booked_price: booked_price || 0,
								comments: comments || "",
								fetching_prices: Boolean(fetching_prices),
								group_id: groupId,
								sort_order: sortOrder,
								id: Math.random() * 1000,
							})
						})
					})
				} else if (type === "travel_activity") {
					const { ticket_tourist_configurations, dates, ...activity } = service
					if (
						!activity.activity ||
						!ticket_tourist_configurations?.length ||
						!dates?.length
					)
						return
					dates.forEach((date, i) => {
						activities.push(
							addKeyToFieldArrayItem({
								...activity,
								group_id: groupId,
								sort_order: sortOrder,
								id: Math.random(),
								date: toISOString(startOf(date, "day")),
								duration: activity.duration?.iso,
								ticket_tourist_configurations:
									ticket_tourist_configurations.map(
										({ date_wise_prices, fetching_prices, ...config }) => {
											const {
												price,
												given_price,
												currency,
												per_quantity_given_price,
											} = date_wise_prices?.at(i) ||
												date_wise_prices?.at(0) || {
													currency: "INR",
													calculated_price: 0,
													given_price: 0,
													booked_price: 0,
													per_quantity_given_price: 0,
												}
											return addKeyToFieldArrayItem({
												...config,
												quantity: config.quantity || 0,
												currency,
												calculated_price: price,
												given_price: given_price || 0,
												per_quantity_given_price: per_quantity_given_price || 0,
												fetching_prices: Boolean(fetching_prices),
											})
										}
									),
							})
						)
					})
				}
			})
			return { transportServices, activities }
		},
		{
			transportServices: [] as Array<
				Omit<IQuoteCab, "quote_id"> & {
					transport_service_location: TTransportServiceLocation
					fetching_prices: boolean
				}
			>,
			activities: [] as Array<Omit<IQuoteTravelActivity, "quote_id">>,
		}
	)
	if (cabs_price_calculations?.length && transportServices?.length) {
		const total = cabs.given_price_of_cabs_only
		const currency =
			(total.length
				? total[0].currency
				: cabs_price_calculations[0].currency) || "INR"
		// remove the any given prices from cabs
		transportServices.forEach((t, index) => {
			transportServices[index] = {
				...t,
				currency,
				calculated_price: undefined,
				given_price: 0,
				booked_price: 0,
			}
		})
		// set the given price and current to last cab
		if (total.length <= 1) {
			transportServices[transportServices.length - 1].given_price =
				!total.length ? 0 : Number(formatMoneyByDecimal(total[0]))
		}
	}

	return {
		...others,
		cabs_price_calculations: cabs_price_calculations?.map((s) => ({
			...s,
			id: Math.random() * 1000,
			services: (s.services || []).map(({ date, ...s }) => ({
				...s,
				date: date ? toISOString(startOf(date, "day")) : undefined,
				id: Math.random() * 1000,
			})),
			metric_wise_prices: (s.metric_wise_prices || []).map((m) => ({
				...m,
				id: Math.random() * 1000,
				given_quantity: m.given_quantity || 0,
				per_quantity_given_price: m.per_quantity_given_price || 0,
			})),
			fetching_prices:
				s.metric_wise_prices?.some((m) => Boolean(m.fetching_prices)) || false,
		})),
		cabs_price_calculation_configurations:
			cabs.cabs_price_calculation_configurations?.map((s) => ({
				...s,
				services: s.services.map(({ date, ...s }) => ({
					id: Math.random() * 1000,
					...s,
					date: date ? toISOString(startOf(date, "day")) : undefined,
				})),
			})),
		cabs: transportServices,
		travel_activities: activities,
	}
}

function normalizeCabExtras(
	cabExtras: IQuoteCabExtraParams
): Array<Omit<IQuoteTransportExtras, "quote_id">> {
	return cabExtras.map(({ date, ...otherData }) => {
		return {
			...otherData,
			id: Math.random(),
			date: date ? toISOString(startOf(date, "day")) : "",
			given_price: otherData.price,
			comments: otherData.comments || "",
		}
	})
}

function normalizeOtherExtras(
	otherExtras: IQuoteOtherExtraParams
): Array<Omit<IQuoteOtherExtras, "quote_id">> {
	return otherExtras.map(({ date, ...otherData }) => {
		return {
			...otherData,
			id: Math.random(),
			date: date ? toISOString(startOf(date, "day")) : "",
			given_price: otherData.price,
			comments: otherData.comments || "",
		}
	})
}

function normalizeFlights(flights: IQuoteFlightParams) {
	return flights.map(({ departs_at, arrives_at, ...otherData }) => {
		return {
			...otherData,
			departs_at: toISOString(departs_at),
			arrives_at: toISOString(arrives_at),
			id: Math.random(),
		}
	})
}

function SyncIsFetchingPricesField() {
	const { onChange: setIsFetchingPrices } =
		useFieldValue<INewQuoteState["fetching_prices"]>("fetching_prices")
	const { value: hotelOptions } =
		useFieldValue<INewQuoteState["hotel_options"]>("hotel_options")
	const { value: transportServices } =
		useFieldValue<INewQuoteState["cabs"]>("cabs")
	useEffect(() => {
		setIsFetchingPrices(true)
		const h = setTimeout(() => {
			const isFetchingPrices =
				hotelOptions.some((v) =>
					v.hotels ? v.hotels.some((h) => Boolean(h.fetching_prices)) : false
				) ||
				transportServices.daywise_services?.some((v) =>
					v.services.some((v) =>
						Boolean(
							v.type === "transport_service"
								? v.service.cabs?.some((s) => s.fetching_prices)
								: v.service.ticket_tourist_configurations?.some(
										(s) => s.fetching_prices
									)
						)
					)
				) ||
				transportServices.cabs_price_calculations?.some((v) =>
					v.metric_wise_prices?.some((v) => Boolean(v.fetching_prices))
				)
			setIsFetchingPrices(isFetchingPrices)
		}, 1000)
		return () => clearTimeout(h)
	}, [hotelOptions, transportServices, setIsFetchingPrices])
	return null
}

function XHR(xhr: XHRInstance) {
	return {
		/**
		 * Create a new quote for a trip
		 */
		async saveQuote(
			tripId: string | number,
			{
				refId,
				startDate,
				days,
				no_of_adults,
				children,
				options,
				hotel_options,
				cabs,
				transportExtras,
				otherExtras,
				flights,
				comments,
				cabPrice,
				transportExtrasPrice,
				flightPrice,
				otherExtrasPrice,

				tax_name,
				gst_included,
				tax_percentage,
				currency_pairs,
				functional_currency,
				given_currency,
				costing_currency,
			}: {
				refId?: number

				// shared between options
				startDate: Date
				days: number
				no_of_adults: number
				children: TChildrenArray
				cabs: IQuoteCabParams
				transportExtras: IQuoteCabExtraParams
				flights: IQuoteFlightParams
				otherExtras: IQuoteOtherExtraParams

				cabPrice: Array<TMoney>
				transportExtrasPrice: Array<TMoney>
				flightPrice: Array<TMoney>
				otherExtrasPrice: Array<TMoney>

				comments?: string
				tax_name: string
				gst_included: "0" | "1" | boolean
				tax_percentage: INewQuoteState["tax_percentage"] | null
				currency_pairs: INewQuoteState["currency_pairs"]
				functional_currency: string
				given_currency: string
				costing_currency: string

				options: INewQuoteState["options"]
				hotel_options: INewQuoteState["hotel_options"]
			}
		): Promise<ITripQuote> {
			const configuration = {
				ref: refId,
				start_date: dateToUTCString(startOf(startDate, "day")),
				start_date_local: formatDate(startOf(startDate, "day"), "YYYY-MM-DD"),
				end_date: dateToUTCString(
					endOf(addUnit(startDate, days - 1, "days"), "day")
				),
				end_date_local: formatDate(
					endOf(addUnit(startDate, days - 1, "days"), "day"),
					"YYYY-MM-DD"
				),
				no_of_adults,
				children,
				timezone_offset: config.timezoneOffset,
				comments,
				tax_name,
				gst_included: Number(gst_included),
				tax_percentage: isTruthy(gst_included)
					? Number(tax_percentage || 0) || 0
					: null,
				currency_pairs,
				costing_currency,
				functional_currency,
				given_currency,
			}
			const normalizedDaywiseServices = normalizeDaywiseServices(cabs)

			const sharedServicesAmongOptions = {
				cabs: normalizedDaywiseServices.cabs.map(
					({
						id,
						sort_order,
						group_id,
						date,
						cab_type,
						transport_service,
						cab_locality,
						calculated_price,
						given_price,
						comments,
						no_of_cabs,
						currency,
					}) => ({
						id,
						sort_order,
						group_id,
						no_of_cabs,
						comments,
						calculated_price,
						given_price,
						date: dateToUTCString(date),
						date_local: formatDate(date, "YYYY-MM-DD"),
						cab_type: cab_type.name,
						transport_service_id: transport_service.id,
						cab_locality: cab_locality?.name,
						currency,
					})
				),
				used_cab_types: (normalizedDaywiseServices.used_cab_types || []).map(
					(c) => ({
						cab_type: c.cab_type.name,
						no_of_cabs: c.no_of_cabs,
					})
				),
				cabs_tax_percentage: normalizedDaywiseServices.cabs_tax_percentage,
				cabs_price_calculation_configurations: (
					normalizedDaywiseServices.cabs_price_calculation_configurations || []
				).map(({ services, metric_preset, id }) => ({
					id,
					metric_preset_id: metric_preset?.id,
					services: services.map((s) => ({
						date: s.date ? dateToUTCString(s.date) : undefined,
						date_local: s.date ? formatDate(s.date, "YYYY-MM-DD") : undefined,
						transport_service_id: s.transport_service.id,
						is_addon: isTruthy(s.is_addon) ? 1 : 0,
					})),
				})),
				cabs_price_calculations: (
					normalizedDaywiseServices.cabs_price_calculations || []
				).map(({ cab_type, services, metric_wise_prices, ...c }) => ({
					...c,
					tax_percentage: normalizedDaywiseServices.cabs_tax_percentage,
					cab_type_id: cab_type.id,
					services: services.map((s) => ({
						date: s.date ? dateToUTCString(s.date) : undefined,
						date_local: s.date ? formatDate(s.date, "YYYY-MM-DD") : undefined,
						transport_service_id: s.transport_service.id,
						is_addon: isTruthy(s.is_addon) ? 1 : 0,
					})),
					metric_wise_prices: metric_wise_prices.map(
						({
							metric,
							quantity,
							given_quantity,
							currency,
							per_quantity_price,
							per_quantity_given_price,
						}) => ({
							metric_id: metric.id,
							quantity,
							given_quantity,
							currency,
							per_quantity_price,
							per_quantity_given_price,
						})
					),
				})),
				travel_activities: normalizeDaywiseServices(cabs).travel_activities.map(
					({
						sort_order,
						group_id,
						quote_cab_id,
						date,
						activity,
						ticket_type,
						duration,
						ticket_tourist_configurations,
						comments,
						slot,
					}) => ({
						quote_cab_id,
						sort_order,
						group_id,
						date: dateToUTCString(date),
						date_local: formatDate(date, "YYYY-MM-DD"),
						activity_id: activity.id,
						ticket_type_id: ticket_type?.id,
						duration,
						slot,
						comments,
						ticket_tourist_configurations: ticket_tourist_configurations.map(
							({ configuration, ...config }) => ({
								configuration_id: configuration.id,
								...config,
							})
						),
					})
				),
				transport_extras: normalizeCabExtras(transportExtras).map(
					({
						date,
						service,
						given_price,
						calculated_price,
						currency,
						booked_price,
						comments,
					}) => ({
						date: date ? dateToUTCString(date) : "",
						date_local: date ? formatDate(date, "YYYY-MM-DD") : "",
						service: service.name,
						currency,
						calculated_price,
						price: given_price,
						booked_price,
						comments,
					})
				),
				flights: normalizeFlights(flights).map(
					({
						source,
						destination,
						departs_at,
						arrives_at,
						airline,
						children,
						no_of_adults,
						infants,
						currency,
						calculated_price,
						given_price,
						comments,
						category_class,
						number_plate,
					}) => ({
						source: source.name,
						destination: destination.name,
						departs_at: dateToUTCString(departs_at),
						departs_at_local: dateToTimestampFormat(departs_at),
						arrives_at: dateToUTCString(arrives_at),
						arrives_at_local: dateToTimestampFormat(arrives_at),
						airline: airline.name,
						category_class,
						number_plate,
						no_of_adults,
						children,
						infants,
						currency,
						calculated_price,
						given_price,
						comments,
					})
				),
				other_extras: normalizeOtherExtras(otherExtras).map(
					({
						date,
						service,
						given_price,
						currency,
						calculated_price,
						comments,
						booked_price,
					}) => ({
						date: date ? dateToUTCString(date) : "",
						date_local: date ? formatDate(date, "YYYY-MM-DD") : "",
						service: service.name,
						price: given_price,
						currency,
						calculated_price,
						comments,
						booked_price,
					})
				),
			}
			const tripQuoteOptions = options.map(
				(
					{
						name: optionName,
						cost_prices_per_person,
						per_person_cost_price_components,
						given_price_comments,
						margins,
						given_price,
						given_price_in_costing_currency,
						given_prices_per_person,
					},
					index
				) => {
					// cost price
					const hotelsOption = hotel_options.find(
						(o) => Number(o.option_index) === index
					)
					const hotels = transformHotelsToRequestData(
						hotelsOption?.hotels || []
					)
					const hotelPrice = hotelsOption?.hotelPrice || []
					const hotel_extras = transformHotelExtrasToRequestData(
						hotelsOption?.hotelExtras || []
					)
					const hotelExtrasPrice = hotelsOption?.hotelExtrasPrice || []

					const anythingAdded =
						hotels.length +
						hotel_extras.length +
						sharedServicesAmongOptions.cabs.length +
						(sharedServicesAmongOptions.travel_activities?.length || 0) +
						sharedServicesAmongOptions.transport_extras.length +
						sharedServicesAmongOptions.flights.length +
						sharedServicesAmongOptions.other_extras.length
					if (!anythingAdded) {
						throw Error(
							`Please select atleast one service to create the quote (${optionName})`
						)
					}

					if (options.length > 1 && !hotels.length) {
						throw Error(
							`Please select some hotels for quote option (${optionName})`
						)
					}

					const totalPrice = Number(
						formatMoneyByDecimal(
							makeCurrencyConverter(currency_pairs)(
								costing_currency,
								addMoneyFromDifferentCurrencies([
									...hotelPrice,
									...hotelExtrasPrice,
									...cabPrice,
									...transportExtrasPrice,
									...flightPrice,
									...otherExtrasPrice,
								])
							)
						)
					)
					if (!totalPrice) {
						throw Error(
							"Please provide cost price of service(s). You can add total cost price to any one of the services."
						)
					}
					return {
						...sharedServicesAmongOptions,
						name: optionName,
						cost_prices_per_person,
						per_person_cost_price_components,
						given_price_comments,
						margins,
						given_price,
						given_price_in_costing_currency,
						given_prices_per_person,
						hotels,
						hotel_extras,
						total_price: totalPrice,
					}
				}
			)

			const payload = {
				...configuration,
				options: tripQuoteOptions,
			}

			return xhr
				.post(`/trips/${tripId}/quotes`, payload)
				.then((resp) => resp.data.data)
		},
	}
}

/**
 * Submit the sub-forms to show errors
 */
function submitAllForms(document: HTMLDivElement) {
	const buttons =
		document.querySelectorAll<HTMLButtonElement>("[type='submit']")
	for (let i = 0; i < buttons.length; i++) {
		const button = buttons[i]
		if (typeof button.click === "function") button.click()
	}
}

/**
 * Show errors in the forms
 */
function showErrorsFromForms(document: HTMLDivElement, error: string) {
	if (document) {
		const errors: NodeListOf<HTMLButtonElement> =
			document.querySelectorAll("[role='alert']")
		if (errors.length) {
			if (typeof errors[0].scrollIntoView === "function") {
				errors[0].scrollIntoView({
					behavior: "smooth",
					block: "center",
				})
			} else {
				window.alert(error)
			}
		}
	}
}
