import {
	Button,
	Inline,
	Stack,
	Divider,
	Text,
	Alert,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import * as Validator from "yup"
import { IUser, XHR } from "./store"
import {
	Form,
	validateFormValues,
	SubmissionError,
	TextInputField,
	withServerErrors,
	FormSpy,
	PhoneInputField,
	getEditablePhoneNumber,
	SwitchInputField,
	isTruthy,
} from "@sembark-travel/ui/form"
import { useRef } from "react"
import {
	ConfirmationDialog,
	ConfirmationDialogProvider,
} from "@sembark-travel/ui/dialog"

const newUserSchema = Validator.object().shape({
	name: Validator.string()
		.required("Name is required")
		.min(4, "Minimum 4 characters required")
		.max(199, "Maximum 199 characters allowed"),
	email: Validator.string()
		.email("Please enter a valid email address")
		.nullable(),
})

interface EditUserProps {
	user: IUser
	onSuccess: (data: IUser) => void
	onCancel?: () => void
}

export function EditUserForm({ onSuccess, onCancel, user }: EditUserProps) {
	const xhr = useXHR()
	const { name, email, phone_numbers, can_disable_2fa } = user
	const initialValues = useRef({
		name,
		email,
		can_disable_2fa: isTruthy(can_disable_2fa) ? 1 : 0,
		should_delete_mfas: 0,
		phone_numbers: phone_numbers?.length
			? phone_numbers.map((p) =>
					getEditablePhoneNumber(p.phone_number, p.country_code)
				)
			: [
					{
						country_code: "IN",
						phone_number: "+91",
						number: "",
					},
				],
	}).current
	type UserCredentials = typeof initialValues
	return (
		<ConfirmationDialogProvider>
			{({ confirm }) => (
				<Form<UserCredentials>
					initialValues={initialValues}
					validate={validateFormValues(newUserSchema)}
					onSubmit={withServerErrors(
						async ({
							phone_numbers,
							can_disable_2fa,
							should_delete_mfas,
							...values
						}) => {
							if (
								values.email &&
								values.email.toLowerCase() !== initialValues.email.toLowerCase()
							) {
								if (!(await confirm())) {
									return
								}
							}
							const updatedData = await XHR(xhr).update(user.id, {
								...values,
								can_disable_2fa: isTruthy(can_disable_2fa) ? 1 : 0,
								should_delete_mfas: isTruthy(should_delete_mfas) ? 1 : 0,
								phone_numbers: phone_numbers
									?.filter((p) => p.number)
									.map((p) => ({
										...p,
										number: String(p.number).replace(/[^\d]/gi, ""),
									})),
							})
							onSuccess(updatedData)
						}
					)}
					subscription={{ submitting: true }}
				>
					{({ submitting, handleSubmit }) => (
						<form noValidate onSubmit={handleSubmit}>
							<Stack gap="4">
								<TextInputField
									label="Name"
									name="name"
									placeholder="Manager"
									required
									type="text"
								/>
								<TextInputField
									label="Email"
									name="email"
									placeholder="e.g. member@example.com"
									type="email"
								/>
								<PhoneInputField
									label="Contact Number(s)"
									name="phone_numbers"
									secondaryLabel="optional"
									help="Used for sharing contact details in Quotes"
								/>
								<SwitchInputField
									name="can_disable_2fa"
									label="Can disable 2FA"
									help="Whether or not this person can disable 2FA after enabling"
								/>
								<SwitchInputField
									name="should_delete_mfas"
									label="Remove Multi Factor Authenticators"
									help="Delete all Two Factor and Password-less Authenticators"
								/>
								<Divider sm />
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Please wait..." : "Save Details"}
									</Button>
									{onCancel ? (
										<Button
											onClick={onCancel}
											level="tertiary"
											disabled={submitting}
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
							<ConfirmationDialog
								title="Update Email Address"
								destructiveAction
							>
								<FormSpy<UserCredentials>>
									{({ values }) => (
										<Stack gap="4">
											<Text>
												You have changed the email address of this member.
											</Text>
											<Stack gap="2">
												<Inline gap="2">
													<Text fontWeight="semibold" fontFamily="mono">
														Old:
													</Text>
													<Text wordBreak="all">{initialValues.email}</Text>
												</Inline>
												<Inline gap="2">
													<Text fontWeight="semibold" fontFamily="mono">
														New:
													</Text>
													<Text wordBreak="all">{values.email}</Text>
												</Inline>
											</Stack>
											<Divider sm />
											<Text fontWeight="semibold" fontSize="md">
												Updation of email address will:
											</Text>
											<Stack
												as="ul"
												listStyleType="disc"
												paddingLeft="4"
												gap="1"
											>
												<li>
													Move all data associated with old email address to new
													email address
												</li>
												<li>
													Direct all further communication to new email address
												</li>
											</Stack>
											<Alert title="Are you changing the email to replace a Member Account?">
												<Text>
													If yes, then you should delete the existing member's
													account and invite the new member. That will keep the
													track of existing work from deleted account.
												</Text>
											</Alert>
											<Divider sm />
											<Text color="warning">
												Are you sure you want to update the email address ?
											</Text>
										</Stack>
									)}
								</FormSpy>
							</ConfirmationDialog>
						</form>
					)}
				</Form>
			)}
		</ConfirmationDialogProvider>
	)
}
