import {
	Alert,
	Box,
	Icons,
	Container,
	Text,
	Inline,
	Stack,
	Heading,
	joinAttributes,
	RelativeTime,
	Time,
} from "@sembark-travel/ui/base"
import { Link } from "@sembark-travel/ui/router"
import {
	getDiff,
	utcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import React from "react"
import { ResendInvitation } from "./List"
import { IUser } from "./store"
import { PhoneNumber } from "../Contacts"

export function UserDetails({
	user,
	onChange,
}: {
	user: IUser
	onChange: () => void
}) {
	const roles = user.roles || []
	const { profile_image_url } = user
	return (
		<Container paddingY="4">
			<Stack gap="8">
				<Inline gap="4">
					<Box>
						{profile_image_url ? (
							<Box
								as="img"
								src={profile_image_url}
								alt="Avatar"
								style={{ width: "150px", height: "150px" }}
								borderWidth="1"
								borderColor="default"
								rounded="lg"
							/>
						) : (
							<Inline
								style={{ width: "56px", height: "56px" }}
								borderWidth="1"
								borderColor="accent"
								rounded="lg"
								bgColor="accent"
								color="accent"
								justifyContent="center"
								alignItems="center"
							>
								<Icons.User size="6" />
							</Inline>
						)}
					</Box>
					<Stack gap="1">
						<Inline gap="4">
							<Heading as="h3" fontSize="2xl">
								{user.name}
							</Heading>
							{!user.deleted_at ? (
								<Box>
									<Link to="edit" title="Edit Name">
										<Icons.Pencil />
									</Link>
								</Box>
							) : null}
						</Inline>
						<Box>
							{joinAttributes(
								<Box display="inlineBlock">
									{user.email}{" "}
									{!user.email_verified_at ? (
										<Icons.Mail
											color="warning"
											title="Email Verification Pending"
										/>
									) : null}
								</Box>,
								user.phone_numbers?.length ? (
									<PhoneNumber value={user.phone_numbers} />
								) : null,
								user.can_disable_2fa ? "Can Disable 2FA" : "Can't Disable 2FA"
							)}
						</Box>
					</Stack>
				</Inline>
				{user.disabled_at ? (
					<Alert status="warning" title="Account Disabled">
						<Text>
							This account has been disabled{" "}
							<RelativeTime timestamp={user.disabled_at} /> by{" "}
							{user.disabled_by?.name || "You"}
						</Text>
					</Alert>
				) : null}
				<Box padding="4" bgColor="default" borderWidth="1" rounded="md">
					<Inline gap="8" flexWrap="wrap">
						<UserAttribute
							prop={
								<Inline gap="2" alignItems="center">
									Roles
									{!user.is_owner && !user.deleted_at ? (
										<Link to="edit-roles" title="Edit Roles">
											<Icons.Pencil size="3" />
										</Link>
									) : null}
								</Inline>
							}
						>
							{!roles.length ? (
								<Box color="muted">Not Assigned</Box>
							) : (
								joinAttributes(...roles.map((r) => r.name))
							)}
						</UserAttribute>
						{user.signup_at ? (
							<>
								<UserAttribute prop="Signup">
									<Box>
										<RelativeTime timestamp={user.signup_at} />
									</Box>
								</UserAttribute>
								<UserAttribute prop="Email Verified">
									{user.email_verified_at ? (
										<Box>
											<RelativeTime timestamp={user.email_verified_at} />
										</Box>
									) : (
										<Text color="warning">
											<Icons.AttentionSolid /> Pending
										</Text>
									)}
								</UserAttribute>
								<UserAttribute prop="Last Activity">
									{user.last_activity ? (
										<Box title={user.last_activity.description}>
											<RelativeTime
												value={utcTimestampToLocalDate(
													user.last_activity.created_at
												)}
											/>
										</Box>
									) : (
										"No activity"
									)}
								</UserAttribute>
								<UserAttribute prop="Last Login">
									{user.last_login_activity ? (
										<RelativeTime
											value={utcTimestampToLocalDate(
												user.last_login_activity.created_at
											)}
										/>
									) : (
										"Never logged in"
									)}
								</UserAttribute>
							</>
						) : null}
						{user.teams?.length ? (
							<UserAttribute prop="Teams">
								{user.teams.map((t) => t.name).join(", ")}
							</UserAttribute>
						) : null}
						{user.trip_destinations?.length ? (
							<UserAttribute prop="Destinations">
								{user.trip_destinations.map((t) => t.name).join(", ")}
							</UserAttribute>
						) : null}
						{user.email_verified_at ? (
							<UserAttribute prop="User Since">
								<Time value={utcTimestampToLocalDate(user.email_verified_at)} />
							</UserAttribute>
						) : null}
						<UserAttribute prop="Invited At">
							<Time value={utcTimestampToLocalDate(user.invited_at)} />
						</UserAttribute>
					</Inline>
				</Box>
				{!user.email_verified_at &&
				getDiff(
					new Date(),
					utcTimestampToLocalDate(user.invited_at),
					"minute"
				) >= 5 ? (
					<Alert status="warning" title="Email not verified">
						<Stack gap="2">
							{user.signup_at ? (
								<Text>
									Email verification request was sent{" "}
									<RelativeTime
										value={utcTimestampToLocalDate(user.invited_at)}
									/>{" "}
									but has not been verified yet.
								</Text>
							) : (
								<Text>
									{user.name} was invited{" "}
									<RelativeTime
										value={utcTimestampToLocalDate(user.invited_at)}
									/>{" "}
									but has not verified the email address.
								</Text>
							)}
							<Box>
								<ResendInvitation user={user} onChange={() => onChange()} />
							</Box>
						</Stack>
					</Alert>
				) : null}
			</Stack>
		</Container>
	)
}

function UserAttribute({
	prop,
	children,
}: {
	prop: React.ReactNode
	children: React.ReactNode
}) {
	return (
		<Stack gap="1">
			<Box
				fontSize="sm"
				textTransform="uppercase"
				letterSpacing="wider"
				fontWeight="semibold"
				color="muted"
				whiteSpace="preserve"
			>
				{prop}
			</Box>
			<Box fontSize="md" fontWeight="semibold">
				{children}
			</Box>
		</Stack>
	)
}
