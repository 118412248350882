import { Container, Heading, Spinner, Stack } from "@sembark-travel/ui/base"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { Helmet } from "react-helmet-async"
import { generatePath, redirect } from "../../../../router-utils"
import { UserDetails, UsersXHR, IUser } from "./../../../../Users"
import {
	LoaderFunctionArgs,
	useLoaderData,
	defer,
	Await,
	useRevalidator,
} from "react-router-dom"
import { xhr } from "@sembark-travel/xhr"
import { Suspense } from "react"
import { ActivityLogs } from "../../../../ActivityLogs"

export async function Loader({ params }: LoaderFunctionArgs) {
	if (!params.userId) {
		return redirect("/org/users")
	}
	const user = UsersXHR(xhr).getUser(params.userId)
	return defer({ user })
}

export default function User() {
	const { user } = useLoaderData() as { user: Promise<IUser> }
	const revalidator = useRevalidator()
	return (
		<>
			<Helmet>
				<title>User Details</title>
			</Helmet>
			<Suspense fallback={<Spinner padding="4" alignCenter />}>
				<Await resolve={user}>
					{(user: IUser) => (
						<>
							<Breadcrumbs
								title="User Details"
								items={[
									[
										user.tenant_id
											? generatePath("/org/users")
											: generatePath("/admin/users"),
										"Users",
									],
									["", "Details"],
								]}
							/>
							<Stack gap="4">
								<UserDetails
									user={user}
									onChange={() => revalidator.revalidate()}
								/>
								<Container>
									<Stack gap="2">
										<Heading>Security Logs</Heading>
										<ActivityLogs
											causerType="users"
											causerId={user.id}
											securityLogs
										/>
									</Stack>
								</Container>
							</Stack>
						</>
					)}
				</Await>
			</Suspense>
		</>
	)
}
