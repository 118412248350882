import {
	Button,
	Inline,
	Stack,
	Box,
	Divider,
	Heading,
	Text,
	Icons,
	Grid,
	Col,
} from "@sembark-travel/ui/base"
import { Dialog } from "@sembark-travel/ui/dialog"
import {
	arrayMutators,
	DurationInputField,
	FieldArray,
	Form,
	GetFieldValue,
	MarkdownInputField,
	SelectField,
	SubmissionError,
	TextInputField,
	validateFormValues,
	withServerErrors,
	EmptyNumberValidator,
} from "@sembark-travel/ui/form"
import { useXHR } from "@sembark-travel/xhr"
import { useRef } from "react"
import { Optional, Required } from "utility-types"
import * as Validator from "yup"
import { useAuthUser } from "../Auth"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import { TTravelActivity } from "./store"
import { parseTouristConfigurationsString } from "./utils"
import { showSnackbar } from "@sembark-travel/ui/snackbar"

export function AddActivityInDialog({
	onSuccess,
	onClose,
	open,
	...props
}: {
	open: boolean
	onClose: () => void
} & Omit<React.ComponentProps<typeof AddNewTravelActivityItem>, "onCancel">) {
	return (
		<Dialog open={open} onClose={onClose} title="Add Activity" lg>
			<Dialog.Body>
				<AddNewTravelActivityItem
					onSuccess={(activity) => {
						onClose()
						onSuccess?.(activity)
						showSnackbar(`Activity/Ticket ${activity.name} successfully added.`)
					}}
					onCancel={() => onClose()}
					{...props}
				/>
			</Dialog.Body>
		</Dialog>
	)
}

type TNewItemValue = {
	name: string
	description?: string
	complimentary_till_age?: number
	required_minimum_age?: number
	allowed_maximum_age?: number
	ticket_types?: Array<{
		name: string
		description?: string
		durations?: Array<{
			iso: string
			slots: string
		}>
	}>
	ticket_tourist_configurations: string
	trip_destinations?: Array<TTripDestination>
}

const INITIAL_VALUES: Required<TNewItemValue, "ticket_types"> = {
	name: "",
	description: "",
	complimentary_till_age: undefined,
	required_minimum_age: undefined,
	allowed_maximum_age: undefined,
	ticket_types: [
		{
			name: "",
			description: "",
			durations: [
				{
					iso: "",
					slots: "",
				},
			],
		},
	],
	ticket_tourist_configurations: "Adult, Child (6-12)",
}
export function AddNewTravelActivityItem({
	onSuccess,
	...props
}: {
	onSuccess: (activity: TTravelActivity) => void
} & Omit<
	React.ComponentProps<typeof AddNewTravelActivityItemForm>,
	"onSubmit"
>) {
	const xhr = useXHR()
	return (
		<AddNewTravelActivityItemForm
			{...props}
			onSubmit={async (data) => {
				const resp = await xhr.post<{ data: TTravelActivity }>(
					"/travel-activities",
					data
				)
				onSuccess(resp.data.data)
			}}
		/>
	)
}

export function AddNewTravelActivityItemForm({
	onSubmit,
	onCancel,
	initialValues: propInitialValues = {},
}: {
	onSubmit: (data: {
		name: string
		description?: string
		ticket_types: Array<{
			name: string
			description?: string
			durations: Array<{
				iso: string
				slots: Array<string>
			}>
		}>
		ticket_tourist_configurations: Array<string>
		trip_destinations: Array<number>
	}) => Promise<void>
	onCancel: () => void
	initialValues?: Optional<TNewItemValue>
}) {
	const { user } = useAuthUser()
	const can_attach_services_to_destinations = Boolean(
		user?.tenant?.feature_flags?.attach_services_to_destinations
	)
	const validationSchema = Validator.object().shape({
		name: Validator.string()
			.required("Please provide a name for the activity")
			.max(191, "Please use 191 or lesser characters for the name."),
		complimentary_till_age: EmptyNumberValidator()
			.integer("Please use an integer value")
			.min(0, "Please use a positive integer value e.g. 3"),
		required_minimum_age: EmptyNumberValidator()
			.integer("Please use an integer value")
			.min(0, "Please use a positive integer value e.g. 3"),
		allowed_maximum_age: EmptyNumberValidator()
			.integer("Please use an integer value")
			.min(1, "Please use a positive integer value e.g. 3"),
		description: Validator.string()
			.nullable()
			.max(10000, "Please use 10000 or less characters for the details."),
		ticket_types: Validator.array().of(
			Validator.object().shape({
				name: Validator.string()
					.required("Please provide a name for the type")
					.max(191, "Please use 191 or lesser characters for the name."),
				description: Validator.string()
					.nullable()
					.max(10000, "Please use 10000 or less characters for the details."),
			})
		),
		ticket_tourist_configurations: Validator.string().required(
			"Please provide tourist age and group configuration for this activity."
		),
		trip_destinations: can_attach_services_to_destinations
			? Validator.array()
					.required("Please select a Destinations")
					.min(1, "Please select atleast one Destinations")
			: Validator.array().nullable(),
	})
	const initialValues = useRef<TNewItemValue>({
		...INITIAL_VALUES,
		...propInitialValues,
	})
	return (
		<Form<TNewItemValue>
			initialValues={initialValues.current}
			validate={validateFormValues(validationSchema)}
			onSubmit={withServerErrors(async (values) => {
				const data = {
					name: values.name,
					description: values.description,
					ticket_types:
						values.ticket_types?.map((s) => {
							return {
								name: s.name,
								description: s.description,
								durations: s.durations
									? s.durations
											.map((d) => {
												if (!d.iso) return undefined
												return {
													iso: d.iso,
													slots: (d.slots || "")
														.split(",")
														.map((s) => s.trim())
														.filter(Boolean),
												}
											})
											.filter((d): d is { iso: string; slots: Array<string> } =>
												Boolean(d)
											)
									: [],
							}
						}) || [],
					ticket_tourist_configurations: values.ticket_tourist_configurations
						.split(",")
						.map((t) => t.trim())
						.filter(Boolean),
					trip_destinations:
						values.trip_destinations?.length &&
						can_attach_services_to_destinations
							? values.trip_destinations.map((t) => t.id)
							: [],
				}
				await onSubmit(data)
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit, submitting }) => (
				<form noValidate onSubmit={handleSubmit}>
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading fontSize="md">Activity Details</Heading>
								<Text color="muted">
									Please provide basic details regarding the activity.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<TextInputField
									name="name"
									label="Name"
									required
									placeholder="e.g. Port Blair To Havelock : Private Catamaran Ferry"
									maxWidth="md"
								/>
								<MarkdownInputField
									name="description"
									label="Itinerary/Details"
									secondaryLabel="optional"
									placeholder="Some details regarding the activity"
								/>
								{can_attach_services_to_destinations ? (
									<SelectField
										select={SelectTripDestination}
										name="trip_destinations"
										label="Trip Destinations"
										multiple
										closeOnSelect
									/>
								) : null}
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading fontSize="md">
									Tourist Age and Group Configurations
								</Heading>
								<Text color="muted">
									Please provide tourist age and group configuration for tickets
									available in this activity e.g. Adult, Child(3-5),
									Child(6-12), Group (8 Pax) (2y+).
								</Text>
							</Stack>
						</Col>
						<Col>
							<Stack gap="4">
								<TextInputField
									label="Tourist Age Configuration"
									name="ticket_tourist_configurations"
									placeholder="e.g. Adult, Child(3-5), Child(6-12), Group(8 Pax)"
									type="text"
									maxWidth="md"
									help={
										<GetFieldValue<string> name="ticket_tourist_configurations">
											{({ value }) => (
												<Text>
													{parseTouristConfigurationsString(value)
														.map((v) => v.name)
														.join(", ")}
												</Text>
											)}
										</GetFieldValue>
									}
								/>
								<TextInputField
									name="complimentary_till_age"
									label="Complimentary Age"
									type="number"
									min={0}
									placeholder="e.g. 4"
									style={{ width: "100px" }}
									help={
										<GetFieldValue<
											number | string
										> name="complimentary_till_age">
											{({ value }) => {
												const age = parseInt(String(value))
												if (isNaN(age)) return null
												if (!age) return <Text>No one</Text>
												return <Text>{age} yrs and below</Text>
											}}
										</GetFieldValue>
									}
								/>
							</Stack>
						</Col>
					</Grid>
					<Divider sm />
					<Grid gap="4">
						<Col sm={12} md={4}>
							<Stack gap="1">
								<Heading fontSize="md">Ticket Type/Packages</Heading>
								<Text color="muted">
									Please provide the ticket types or packages available for this
									activity.
								</Text>
							</Stack>
						</Col>
						<Col>
							<Box paddingY="2">
								<FieldArray<
									Required<
										TNewItemValue,
										"ticket_types"
									>["ticket_types"][number]
								> name="ticket_types">
									{({ fields }) => (
										<Stack gap="2">
											{fields.map((name, index) => (
												<Box
													key={name}
													paddingLeft="4"
													borderBottomWidth="1"
													borderLeftColor="emphasis"
													paddingBottom="2"
													borderLeftWidth="2"
												>
													<Inline gap="6" flexWrap="wrap" collapseBelow="sm">
														<Inline flex="1" maxWidth="md">
															<Box flex="1">
																<TextInputField
																	name={`${name}.name`}
																	label="Name"
																	placeholder="e.g.  Premium"
																	type="text"
																/>
															</Box>
															<Box paddingTop="6">
																<Button
																	onClick={() => fields.remove(index)}
																	title="Remove this ticket type from the activity"
																	level="tertiary"
																	size="sm"
																>
																	<Icons.Cancel />
																</Button>
															</Box>
														</Inline>
														<FieldArray<
															Required<
																Required<TNewItemValue>["ticket_types"][number]
															>["durations"][number]
														>
															name={`${name}.durations`}
														>
															{({ fields }) => (
																<Stack gap="4">
																	{fields.map((name, index) => (
																		<Inline gap="4" key={name}>
																			<Inline gap="4" flex="1" flexWrap="wrap">
																				<DurationInputField
																					name={`${name}.iso`}
																					label="Duration"
																					secondaryLabel="optional"
																				/>
																				<GetFieldValue name={`${name}.iso`}>
																					{({ value }) =>
																						value ? (
																							<TextInputField
																								label="Slots"
																								type="text"
																								name={`${name}.slots`}
																								placeholder="11:00, 13:00"
																								secondaryLabel="optional"
																							/>
																						) : null
																					}
																				</GetFieldValue>
																			</Inline>
																			<Inline gap="2" paddingTop="6">
																				{Number(fields.length || 0) > 1 ? (
																					<Button
																						onClick={() => fields.remove(index)}
																						size="sm"
																					>
																						<Icons.Cancel />
																					</Button>
																				) : null}
																			</Inline>
																		</Inline>
																	))}
																	<Box textAlign="right">
																		<Button
																			onClick={() =>
																				fields.push({
																					iso: "",
																					slots: "",
																				})
																			}
																			inline
																			title="Add More Duration for this type"
																		>
																			<Icons.Plus /> Add More Durations
																		</Button>
																	</Box>
																</Stack>
															)}
														</FieldArray>
													</Inline>
													<MarkdownInputField
														name={`${name}.description`}
														label="Itinerary/Details"
														secondaryLabel="optional"
														placeholder="Some details regarding this ticket type"
													/>
												</Box>
											))}
											<Box paddingY="2">
												<Button
													size="sm"
													onClick={() =>
														fields.push({
															name: "",
															durations: [
																{
																	iso: "",
																	slots: "",
																},
															],
														})
													}
												>
													Add More Ticket/Package Types
												</Button>
											</Box>
										</Stack>
									)}
								</FieldArray>
							</Box>
						</Col>
					</Grid>
					<Divider />
					<Grid gap="4">
						<Col sm={12} md={{ offset: 4, span: 8 }}>
							<Stack gap="4" paddingLeft={{ md: "4" }}>
								<SubmissionError />
								<Inline gap="4">
									<Button type="submit" disabled={submitting}>
										{submitting ? "Please wait" : "Save Details"}
									</Button>
									{onCancel ? (
										<Button
											onClick={() => onCancel()}
											disabled={submitting}
											level="tertiary"
										>
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Col>
					</Grid>
				</form>
			)}
		</Form>
	)
}
