import {
	Inline,
	Alert,
	Box,
	Button,
	Heading,
	Icons,
	Stack,
	Table,
	TableDataCell,
	TableHeaderDataCell,
	Col,
	Details,
	Divider,
	Grid,
	Spinner,
	Summary,
	DeferRender,
	Select,
} from "@sembark-travel/ui/base"
import { ButtonLink, Link, queryToSearch } from "@sembark-travel/ui/router"
import { showSnackbar } from "@sembark-travel/ui/snackbar"
import { Markdown } from "@sembark-travel/ui/markdown"
import { useXHR } from "@sembark-travel/xhr"
import {
	FieldArray,
	Form,
	arrayMutators,
	TextInputField,
	SelectField,
	withServerErrors,
	SubmissionError,
	TextAreaInputField,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import React, { Fragment } from "react"
import useSWR from "swr"
import { Omit } from "utility-types"
import config from "../config"
import { generatePath, useNavigate } from "../router-utils"
import { Cabs, DaywiseScheduleDay, Hotels } from "./ItineraryItem"
import type { ITripQuote } from "./store"
import type { ITermsAndCondition } from "./../TermsAndConditions"
import { SelectTermsAndConditions } from "./../TermsAndConditions"
import { useFetchQuote } from "./store"
import {
	dateToUTCString,
	formatDate,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import {
	SelectInclusionExclusionPreset,
	TInclusionExclusionPreset,
} from "../InclusionExclusionPresets"

interface IDaywiseSchedulePayload {
	date: Date
	title: string
	description: string
	transport_service_id?: number
	travel_activity_id?: number
	travel_activity_ticket_type_id?: number
	options?: Array<
		Omit<IDaywiseSchedulePayload, "title"> & {
			name: string
		}
	>
}

interface IItineraryInitialValues {
	daywise_schedules?: Array<IDaywiseSchedulePayload>
	inclusion_exclusions?: Array<{
		category?: string
		inclusion?: string | boolean
		exclusion?: string | boolean
		category_editable?: boolean
		is_boolean?: boolean
	}>
	inclusion_exclusion_comment: string
	terms_and_conditions?: ITermsAndCondition
	inclusion_exclusion_preset?: TInclusionExclusionPreset
	other_info?: string
}

interface CreateItineraryProps {
	quote: ITripQuote
	onCancel?: () => void
	onSuccess?: (quote: ITripQuote) => void
}

export function CreateItinerary({
	quote: tripQuote,
	onCancel,
	onSuccess,
}: CreateItineraryProps) {
	const { id, quote, shared_by_tenant } = tripQuote
	const xhr = useXHR()
	const {
		data: initialValues,
		isValidating,
		error,
	} = useSWR<IItineraryInitialValues>(
		`/quote-itineraries/${tripQuote.id}/edit`,
		() =>
			xhr
				.get(`/quote-itineraries/${tripQuote.id}/edit`)
				.then((resp) => resp.data)
				.then((data) => ({
					...data,
					daywise_schedules: data.daywise_schedules.map(
						(
							schedule: Omit<IDaywiseSchedulePayload, "date"> & {
								date: string
								date_local?: string
							}
						) => ({
							...schedule,
							date: localOrUtcTimestampToLocalDate(
								schedule.date_local,
								schedule.date
							),
							description: schedule.description || "",
						})
					),
				})),
		{
			revalidateOnFocus: false,
		}
	)
	if (error && !initialValues) {
		return <Alert status="error">{error.message}</Alert>
	}
	if (!quote || !initialValues || isValidating) {
		return <Spinner padding="4" alignCenter />
	}
	const { hotels, cabs, start_date, start_date_local } = quote
	const startDateLocal = localOrUtcTimestampToLocalDate(
		start_date_local,
		start_date
	)
	return (
		<Form<IItineraryInitialValues>
			initialValues={initialValues}
			onSubmit={withServerErrors(async (values) => {
				const data = {
					...values,
					inclusion_exclusion_preset: values.inclusion_exclusion_preset?.id,
					terms_and_conditions: values.terms_and_conditions?.id,
					timezone_offset: config.timezoneOffset,
					daywise_schedules: shared_by_tenant
						? undefined
						: values.daywise_schedules?.map(
								({ date, options, ...otherData }) => ({
									...otherData,
									date: dateToUTCString(date),
									date_local: formatDate(date, "YYYY-MM-DD"),
								})
							) || [],
					inclusion_exclusions: shared_by_tenant
						? undefined
						: values.inclusion_exclusions?.map(
								({ is_boolean, inclusion, exclusion, ...otherData }) => ({
									...otherData,
									is_boolean: is_boolean ? 1 : 0,
									inclusion: is_boolean ? (inclusion ? "Yes" : "") : inclusion,
									exclusion: is_boolean ? (inclusion ? "" : "Yes") : exclusion,
								})
							),
				}
				const {
					data: { data: quote },
				} = await xhr.post(`/quote-itineraries/${id}`, data)
				onSuccess && onSuccess(quote)
				return data
			})}
			subscription={{ submitting: true }}
			mutators={{ ...arrayMutators }}
		>
			{({ handleSubmit, submitting, form }) => (
				<form noValidate onSubmit={handleSubmit}>
					{shared_by_tenant ? (
						<Alert status="warning" title="Editting only Terms and Other Info">
							This package is received from <b>{shared_by_tenant.short_name}</b>
							. Hence, You can not update the Inclusion/Exclusions and Daywise
							Itinerary. If a change is required, please{" "}
							<b>request an update</b> from {shared_by_tenant.short_name}.
						</Alert>
					) : null}
					{!shared_by_tenant ? (
						<>
							<Details>
								<Summary>Hotels</Summary>
								{hotels.length ? (
									<Hotels quote={quote} />
								) : (
									<Box color="muted">No Hotels Provided</Box>
								)}
								<Box marginTop="2" color="muted">
									To edit hotels, please edit{" "}
									<Link
										to={
											generatePath("/trips/:tripId/new-quote", {
												tripId: tripQuote.trip_id.toString(),
											}) +
											queryToSearch({ quoteId: tripQuote.id }) +
											"#quote_hotels"
										}
									>
										quote services
									</Link>
								</Box>
							</Details>
							<Divider />
							<Details>
								<Summary>Cabs</Summary>
								{cabs.length ? (
									<Cabs quote={quote} />
								) : (
									<Box color="muted">No cabs Provided</Box>
								)}
								<Box marginTop="2" color="muted">
									To edit cabs, please edit{" "}
									<Link
										to={
											generatePath("/trips/:tripId/new-quote", {
												tripId: tripQuote.trip_id.toString(),
											}) +
											queryToSearch({ quoteId: tripQuote.id }) +
											"#quote_cabs"
										}
									>
										quote services
									</Link>
								</Box>
							</Details>
							<Divider />
							<Details open>
								<Summary>Inclusion/Exclusion</Summary>
								<Box marginTop="4">
									<SelectField
										name="inclusion_exclusion_preset"
										select={SelectInclusionExclusionPreset}
										fetchOnMount
										label="Select Inc/Exclusion Preset"
										onChange={(
											value: TInclusionExclusionPreset | undefined | null
										) => {
											form.change(
												"inclusion_exclusion_preset",
												value || undefined
											)
											if (value) {
												const newValues: IItineraryInitialValues["inclusion_exclusions"] =
													[]
												const len = Math.max(
													value.inclusions?.length || 0,
													value.exclusions?.length || 0,
													0
												)
												for (let i = 0; i < len; i++) {
													newValues.push({
														category: "",
														inclusion: value.inclusions?.[i],
														exclusion: value.exclusions?.[i],
														category_editable: false,
														is_boolean: false,
													})
												}
												form.change("inclusion_exclusions", newValues)
											}
										}}
									/>
									<Table
										hover
										responsive
										borderWidth="1"
										caption="Please provide inclusion/exclusion Details. Anything not in inclusion will be added to exclusion. Avoid adding redundant info like hotels, meal plans etc"
									>
										<FieldArray<
											Required<IItineraryInitialValues>["inclusion_exclusions"][number]
										> name="inclusion_exclusions">
											{({ fields }) => (
												<Fragment>
													{fields.length ? (
														<thead>
															<tr>
																<TableHeaderDataCell style={{ width: "150px" }}>
																	Category
																</TableHeaderDataCell>
																<TableHeaderDataCell
																	textAlign="center"
																	color="success"
																>
																	Included
																</TableHeaderDataCell>
																<TableHeaderDataCell
																	textAlign="center"
																	color="danger"
																>
																	Excluded
																</TableHeaderDataCell>
																<TableHeaderDataCell style={{ width: "50px" }}>
																	{fields.length ? (
																		<Button
																			title="Remove All"
																			inline
																			onClick={() =>
																				form.change("inclusion_exclusions", [])
																			}
																		>
																			<Icons.Cancel />
																		</Button>
																	) : null}
																</TableHeaderDataCell>
															</tr>
														</thead>
													) : null}
													<tbody>
														{fields.map((name, index) => (
															<tr key={index}>
																<GetFieldValue<boolean>
																	name={`${name}.category_editable`}
																>
																	{({ value: category_editable }) => (
																		<TableDataCell as="th">
																			{!category_editable ? (
																				<GetFieldValue<string | undefined>
																					name={`${name}.category`}
																				>
																					{({ value: category }) => (
																						<>{category || "-"}</>
																					)}
																				</GetFieldValue>
																			) : (
																				<TextInputField
																					name={`${name}.category`}
																					placeholder="Category Name"
																				/>
																			)}
																		</TableDataCell>
																	)}
																</GetFieldValue>
																<GetFieldValue<boolean>
																	name={`${name}.is_boolean`}
																>
																	{({ value: is_boolean, onChange }) => (
																		<>
																			<ButtonCell
																				isButton={is_boolean}
																				onClick={() => {
																					onChange(true)
																				}}
																			>
																				{is_boolean ? (
																					<Box textAlign="center">
																						<GetFieldValue<string | undefined>
																							name={`${name}.inclusion`}
																						>
																							{({ value: inclusion }) => (
																								<CheckIcon
																									inclusion
																									checked={Boolean(inclusion)}
																								/>
																							)}
																						</GetFieldValue>
																					</Box>
																				) : (
																					<TextInputField
																						name={`${name}.inclusion`}
																						type="text"
																					/>
																				)}
																			</ButtonCell>
																			<ButtonCell
																				isButton={is_boolean}
																				onClick={() => {
																					onChange(false)
																				}}
																			>
																				{is_boolean ? (
																					<Box textAlign="center">
																						<GetFieldValue<string | undefined>
																							name={`${name}.inclusion`}
																						>
																							{({ value: inclusion }) => (
																								<CheckIcon
																									inclusion={false}
																									checked={!inclusion}
																								/>
																							)}
																						</GetFieldValue>
																					</Box>
																				) : (
																					<TextInputField
																						name={`${name}.exclusion`}
																						type="text"
																					/>
																				)}
																			</ButtonCell>
																		</>
																	)}
																</GetFieldValue>
																<TableDataCell>
																	<Button
																		title="Remove Category"
																		inline
																		onClick={() => fields.remove(index)}
																	>
																		<Icons.Cancel />
																	</Button>
																</TableDataCell>
															</tr>
														))}
													</tbody>
													<tfoot>
														<tr>
															<TableDataCell colSpan={2}>
																<Button
																	size="sm"
																	onClick={() =>
																		fields.push({
																			category: "",
																			inclusion: "",
																			exclusion: "",
																			category_editable: true,
																		})
																	}
																>
																	<Icons.Plus /> Add Inclusion/Exclusion
																</Button>
															</TableDataCell>
															<TableDataCell colSpan={2}>
																<Box color="warning" fontWeight="normal">
																	{initialValues.inclusion_exclusion_comment}
																</Box>
															</TableDataCell>
														</tr>
													</tfoot>
												</Fragment>
											)}
										</FieldArray>
									</Table>
								</Box>
							</Details>
							<Divider />
							<FieldArray<
								Required<IItineraryInitialValues>["daywise_schedules"][number]
							> name="daywise_schedules">
								{({ fields }) =>
									fields.length ? (
										<Details open>
											<Summary>Day-wise Schedule</Summary>
											<Box
												as="ol"
												marginTop="2"
												bgColor="default"
												rounded="lg"
												borderWidth="1"
												className="list"
											>
												{fields.map((name) => {
													return (
														<Box
															as="li"
															key={name}
															bgColor={{ hover: "subtle" }}
														>
															<Grid>
																<Col xs={12} sm="auto">
																	<GetFieldValue<
																		IDaywiseSchedulePayload["date"]
																	>
																		name={`${name}.date`}
																	>
																		{({ value: date }) => (
																			<Box
																				marginBottom="4"
																				marginTop="2"
																				style={{ maxWidth: "150px" }}
																			>
																				<DaywiseScheduleDay
																					date={date}
																					from={startDateLocal}
																				/>
																			</Box>
																		)}
																	</GetFieldValue>
																</Col>
																<Col>
																	<Grid>
																		<Col sm={12} md={5} lg={4}>
																			<GetFieldValue<
																				IDaywiseSchedulePayload["options"]
																			>
																				name={`${name}.options`}
																			>
																				{({ value: options }) =>
																					options && options.length > 1 ? (
																						<Box>
																							<GetFieldValue
																								name={`${name}.title`}
																							>
																								{({
																									value: title,
																									onChange,
																								}) => {
																									const selectedOptions = (
																										options || []
																									).filter(
																										(o) => o.name === title
																									)
																									const value =
																										selectedOptions.length
																											? selectedOptions[0]
																											: undefined
																									return (
																										<Select
																											label="Title"
																											options={options}
																											value={value}
																											placeholder="Title of the Day visit"
																											creatable
																											onChange={(value) => {
																												onChange(
																													value
																														? value.name
																														: ""
																												)
																												if (
																													value &&
																													value.description
																												) {
																													form.change(
																														`${name}.description` as never,
																														value.description as never
																													)
																												}
																											}}
																										/>
																									)
																								}}
																							</GetFieldValue>
																						</Box>
																					) : (
																						<TextInputField
																							label="Title"
																							name={`${name}.title`}
																							type="text"
																							placeholder="Title of the Day visit"
																						/>
																					)
																				}
																			</GetFieldValue>
																		</Col>
																		<Col sm={12} md>
																			<TextAreaInputField
																				label="Description"
																				name={`${name}.description`}
																				placeholder="Please provide detailed description of the day visit..."
																				rows={8}
																			/>
																		</Col>
																	</Grid>
																</Col>
															</Grid>
														</Box>
													)
												})}
											</Box>
										</Details>
									) : (
										"No Day-wise Schedule provided"
									)
								}
							</FieldArray>
							<Divider />
						</>
					) : null}
					<Details open>
						<Summary>Terms and Conditions</Summary>
						<Stack gap="4" marginTop="4">
							<SelectField
								fetchOnMount
								select={SelectTermsAndConditions}
								name="terms_and_conditions"
								placeholder="Applied terms and conditions for this package"
							/>
							<GetFieldValue<
								IItineraryInitialValues["terms_and_conditions"]
							> name="terms_and_conditions">
								{({ value: terms_and_conditions }) =>
									terms_and_conditions ? (
										<Stack gap="2">
											{terms_and_conditions.deleted_at ? (
												<Alert
													status="warning"
													title="These terms have been archived"
												>
													Please select a diffent terms to align better with
													company policies.
												</Alert>
											) : null}
											<Box
												padding="4"
												borderWidth="1"
												rounded="md"
												style={{ maxHeight: "200px" }}
												overflow="auto"
												bgColor="subtle"
											>
												<DeferRender>
													<Markdown>
														{terms_and_conditions.description}
													</Markdown>
												</DeferRender>
											</Box>
										</Stack>
									) : null
								}
							</GetFieldValue>
						</Stack>
					</Details>
					<Divider />
					<Details initialOpen={Boolean(initialValues.other_info)}>
						<Summary>Other Information</Summary>
						<Box marginTop="4">
							<TextAreaInputField
								name="other_info"
								placeholder="Any other information like contact Details or guidelines"
							/>
						</Box>
					</Details>
					<Divider />
					<Stack as="footer" gap="4">
						<SubmissionError />
						<Inline gap="4">
							<Button type="submit" disabled={submitting}>
								{submitting ? "Please wait..." : "Save Details"}
							</Button>
							{onCancel ? (
								<Button onClick={onCancel} disabled={submitting}>
									Cancel
								</Button>
							) : null}
						</Inline>
					</Stack>
				</form>
			)}
		</Form>
	)
}

function ButtonCell({
	isButton,
	children,
	onClick,
}: {
	isButton?: boolean
	children: React.ReactNode
	onClick: () => void
}) {
	return (
		<TableDataCell
			role={isButton ? "button" : "gridcell"}
			tabIndex={isButton ? 0 : -1}
			onKeyDown={(e: React.KeyboardEvent<unknown>) => {
				if (isButton && (e.keyCode === 32 || e.keyCode === 13)) {
					e.preventDefault()
					onClick()
				}
			}}
			onClick={() => {
				if (isButton) {
					onClick()
				}
			}}
		>
			{children}
		</TableDataCell>
	)
}

function CheckIcon({
	checked,
	inclusion,
}: {
	checked: boolean
	inclusion: boolean
}) {
	return (
		<Box
			display="inlineFlex"
			alignItems="center"
			justifyContent="center"
			rounded="full"
			fontSize="xl"
			color={!checked ? "muted" : inclusion ? "success" : "danger"}
			opacity={!checked ? "20" : "100"}
		>
			{inclusion ? (
				<Icons.PlusCircle title="Click to Include" />
			) : (
				<Icons.MinusCircle title="Click to Exclude" />
			)}
		</Box>
	)
}

export function EditOrCreateItinerary({
	quoteId,
	...props
}: Omit<CreateItineraryProps, "quote"> & { quoteId: string | number }) {
	const navigate = useNavigate()
	const { data: tripQuote, error, mutate } = useFetchQuote(quoteId)
	const back = React.useCallback(() => {
		if (tripQuote) {
			navigate(`/trips/:tripId/quotes/:quoteId`, {
				params: {
					tripId: String(tripQuote.trip_id),
					quoteId: String(tripQuote.id),
				},
				replace: true,
			})
		}
	}, [navigate, tripQuote])
	if (!tripQuote) {
		return <Spinner padding="4" alignCenter />
	}
	const { itinerary } = tripQuote
	return (
		<Stack gap="4">
			<Inline
				gap="4"
				alignItems="center"
				borderBottomWidth="1"
				paddingBottom="4"
			>
				<ButtonLink
					to={generatePath("/trips/:tripId/quotes/:quoteId", {
						tripId: String(tripQuote.trip_id),
						quoteId: String(tripQuote.id),
					})}
				>
					<Icons.ArrowLeft />
				</ButtonLink>
				<Heading>{itinerary ? "Edit Itinerary" : "Create Itinerary"}</Heading>
			</Inline>
			{error ? (
				<Alert status="error">{error?.message || "Something went wrong"}</Alert>
			) : null}
			<CreateItinerary
				{...props}
				quote={tripQuote}
				onCancel={back}
				onSuccess={() => {
					back()
					showSnackbar("Itinerary created")
					mutate()
				}}
			/>
		</Stack>
	)
}
