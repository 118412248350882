import {
	Box,
	Button,
	Inline,
	Table,
	Stack,
	Container,
	Heading,
	Text,
	Icons,
} from "@sembark-travel/ui/base"
import { useXHR } from "@sembark-travel/xhr"
import {
	Form,
	FileInputField,
	withServerErrors,
	SubmissionError,
	validateFormValues,
	SelectField,
	GetFieldValue,
} from "@sembark-travel/ui/form"
import * as Validator from "yup"
import config from "../config"
import { SelectTripDestination, TTripDestination } from "../TripDestinations"
import { useHasFeatureFlag } from "../Auth"
import {
	SelectTenantCurrencyInputField,
	useFunctionalCurrencyOfTenant,
} from "../Currencies"
import { useRef } from "react"
import {
	CopyToClipboard,
	CopyToClipboardButton,
} from "@sembark-travel/ui/copy-to-clipboard"

type TFormData = {
	timezone_offset: number
	file: File | null
	trip_destinations?: Array<TTripDestination>
	currency: string
}

const THIS_YEAR = new Date().getFullYear()

export function UploadPricesForm({
	onSuccess,
	onCancel,
}: {
	onSuccess: () => void
	onCancel?: () => void
}) {
	const xhr = useXHR()
	const can_attach_services_to_destinations = useHasFeatureFlag(
		"attach_services_to_destinations"
	)
	const validationSchema = validateFormValues(
		Validator.object().shape({
			timezone_offset: Validator.string().required(),
			file: Validator.mixed().required("File field is required"),
			trip_destinations: can_attach_services_to_destinations
				? Validator.array()
						.required("Please select a Destinations")
						.min(1, "Please select atleast one Destinations")
				: Validator.array().nullable(),
			currency: Validator.string().required(
				"Please select a currency for price input."
			),
		})
	)
	const functionalCurrency = useFunctionalCurrencyOfTenant()
	const initialValues: TFormData = useRef({
		timezone_offset: config.timezoneOffset,
		file: null,
		trip_destinations: [],
		currency: functionalCurrency,
	}).current
	return (
		<>
			<Form<typeof initialValues>
				initialValues={initialValues}
				validate={validationSchema}
				onSubmit={withServerErrors(async (values) => {
					const data = new FormData()
					const trip_destinations = values.trip_destinations || []
					if (!values.file) {
						throw Error("Please select a file to upload!")
					}
					data.set("timezone_offset", values.timezone_offset.toString())
					data.set("file", values.file)
					data.set("currency", values.currency)
					trip_destinations.forEach((d, i) =>
						data.set(`trip_destinations[${i}]`, d.id.toString())
					)
					await xhr.post("/cab-prices", data, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					onSuccess()
				})}
				subscription={{ submitting: true, submitErrors: true }}
			>
				{({ submitting, handleSubmit, submitErrors, form }) => (
					<form
						noValidate
						encType="multipart/form-data"
						onSubmit={handleSubmit}
					>
						<Container paddingY="8" bgColor="default">
							<Stack gap="4">
								<SubmissionError />
								{submitErrors ? (
									<Stack gap="4">
										{submitErrors ? (
											<Box as="ul" listStyleType="disc">
												{Object.keys(submitErrors).map((field) => (
													<Box as="li" key={field}>
														<Text>
															{
																(submitErrors as { [field: string]: string })[
																	field
																]
															}
														</Text>
													</Box>
												))}
											</Box>
										) : null}
										<Text>
											Please verify your CSV File format with the format given
											below. Or contact our support if you need any assistant.
										</Text>
									</Stack>
								) : null}
								{can_attach_services_to_destinations ? (
									<SelectField
										select={SelectTripDestination}
										name="trip_destinations"
										label="Trip Destinations"
										multiple
										fetchOnMount
										closeOnSelect
										onChange={(
											destinations: Array<TTripDestination> | undefined
										) => {
											form.change("trip_destinations", destinations)
											if (destinations?.length) {
												form.change("currency", destinations[0].currency)
											}
										}}
									/>
								) : null}
								<Inline gap="4" collapseBelow="sm">
									<SelectTenantCurrencyInputField
										name="currency"
										label="Currency"
										style={{ maxWidth: "150px" }}
									/>
									<Box flex="1">
										<FileInputField
											label="Select a csv file"
											name="file"
											accept=".csv"
											help={
												<GetFieldValue<string> name="currency">
													{({ value: currency }) => (
														<Text>
															All prices will be saved in "{currency}" currency.
														</Text>
													)}
												</GetFieldValue>
											}
										/>
									</Box>
								</Inline>
								<Inline gap="4" flexWrap="wrap">
									<Button type="submit" disabled={submitting} size="lg">
										{submitting
											? "Uploading..."
											: "Upload Transportation Prices CSV"}
									</Button>
									{onCancel ? (
										<Button onClick={onCancel} level="tertiary" size="lg">
											Cancel
										</Button>
									) : null}
								</Inline>
							</Stack>
						</Container>
					</form>
				)}
			</Form>
			<Container paddingY="8">
				<CopyToClipboard<HTMLDivElement>>
					{({ copy, nodeToCopy }) => (
						<Stack gap="4">
							<Heading as="h4">CSV File Format</Heading>
							<Text maxWidth="4xl">
								When creating your CSV file for transport services's prices,
								please follow this format only. You can find more information
								about each column by hovering over the words with{" "}
								<b>
									<abbr title="You will get information like this. Please read each information carefully for best and accurate results.">
										dotted underlines
									</abbr>
								</b>
								. If you are unsure about what/why a column is, please feel free
								to contact our support team.
							</Text>
							<Box ref={nodeToCopy}>
								<Table
									aria-label="CSV file format for transport service prices"
									responsive
									className="excel-style-table"
								>
									<Box as="thead" whiteSpace="preserve">
										<tr>
											<th rowSpan={4}>
												<abbr title="Assign a duty code to quickly identify a service">
													Duty Code
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Starting/Pickup/Destination point (location) of the service">
													A
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="End/Drop point(location) of the service. Leave blank if not applicable for a service.">
													B
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="What kind of service is provided between A and B">
													Service
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Total distance (in kms) traveled for this service. This helps in auto generation of distance information for itinerary. Leave empty if you don't have this info.">
													Distance
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Start Time (HH:mm) of the service">
													Start Time
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Total duration (in mins) for this service. This helps in auto generation of travel time information for itinerary. Leave empty if you don't have this info.">
													Duration(mins)
												</abbr>
											</th>
											<th rowSpan={4}>
												<abbr title="Day schedule/Day Itinerary for this service. This will help in auto generation of daywise schedule from the selected transportation services during quote creation. Leave empty if you want to add it later.">
													Day Schedule
												</abbr>
											</th>
											<th colSpan={2}>
												Season 1{" "}
												<abbr title="(Optional) Add rates for Bookings">
													(Ops)
												</abbr>
											</th>
											<th colSpan={2}>
												<abbr title="Same season sales rates, used during quotation">
													Season 1
												</abbr>
											</th>
											<th colSpan={2}>
												<abbr title="Not adding any suffix (e.g. (Ops)) will use these rates for sales as well as for Bookings">
													Season 2
												</abbr>
											</th>
										</tr>
										<tr>
											<th colSpan={2}>
												<abbr title="These date range should follow the format of 'D MMM YYYY - D MMM YYYY' only otherwise you may loose some data">
													1 Jul {THIS_YEAR} - 30 Sep {THIS_YEAR}
												</abbr>
											</th>
											<th colSpan={2}>
												1 Jul {THIS_YEAR} - 30 Sep {THIS_YEAR}
											</th>
											<th colSpan={2}>
												13 Oct {THIS_YEAR} - 24 Oct {THIS_YEAR}{" "}
												<abbr title="You can also add week-day rates by specifying the week day names as shown.">
													(Sat,Sun)
												</abbr>
											</th>
										</tr>
										<tr>
											<th colSpan={2}>
												24 Dec {THIS_YEAR} - 4 Jan {THIS_YEAR + 1}
											</th>
											<th colSpan={2}>
												24 Dec {THIS_YEAR} - 4 Jan {THIS_YEAR + 1}
											</th>
											<th colSpan={2}>
												5 Jan {THIS_YEAR + 1} - 31 Mar {THIS_YEAR + 1}
											</th>
										</tr>
										<tr>
											<td>
												<abbr title="Here you should mention the cab type for which you want to upload prices.">
													Wagon R
												</abbr>
											</td>
											<td>Innova/Xylo</td>
											<td>Wagon R</td>
											<td>Innova/Xylo</td>
											<td>Wagon R</td>
											<td>Toyota</td>
										</tr>
									</Box>
									<tbody>
										<tr>
											<td>
												<abbr title="This will allow us to search with 101 to quickly identify this service">
													101
												</abbr>
											</td>
											<td>
												Bagdogra Airport{" "}
												<abbr title="This is the short Name for this destination point for easy scanning the cab scheduling calendar">
													(BDG Arpt)
												</abbr>
											</td>
											<td>Gangtok (GTK)</td>
											<td>Pickup</td>
											<td>
												<abbr title="90 Kms between Bagdogra Airport to Gangtok">
													90
												</abbr>
											</td>
											<td>
												<abbr title="HH:mm Format">08:30</abbr>
											</td>
											<td>
												<abbr title="Around 3 and half hours journey">210</abbr>
											</td>
											<td>
												<div style={{ maxWidth: "150px" }}>
													Upon your arrival at NJP Railway Station / Bagdogra
													(IXB) Airport, ......End the first day of your
													Gangtok-Darjeeling tour with a peaceful overnight stay
													at your hotel.
												</div>
											</td>
											<td>
												<abbr title="Purchase/Buying/Booking Price of single cab for a given service during between a date interval.">
													2000
												</abbr>
											</td>
											<td>3000</td>
											<td>
												<abbr title="Selling/Quotation Price of single cab for a given service during between a date interval.">
													2500
												</abbr>
											</td>
											<td>3500</td>
											<td>3000</td>
											<td>4000</td>
										</tr>
										<tr>
											<td></td>
											<td>Bagdogra Airport (BDG Arpt)</td>
											<td>Gangtok (GTK)</td>
											<td>Pickup via Namchi</td>
											<td>126</td>
											<td>
												<abbr title="HH:mm Format">14:30</abbr>
											</td>
											<td>240</td>
											<td></td>
											<td>2100</td>
											<td>3200</td>
											<td>2900</td>
											<td>3300</td>
											<td>3000</td>
											<td>4000</td>
										</tr>
										<tr>
											<td>102</td>
											<td>Gangtok (GTK)</td>
											<td></td>
											<td>Sightseeing</td>
											<td>40</td>
											<td>
												<abbr title="Leave empty if not applicable or varies during service">
													&nbsp;&nbsp;&nbsp;
												</abbr>
											</td>
											<td>90</td>
											<td></td>
											<td>4000</td>
											<td>2000</td>
											<td>4300</td>
											<td>2300</td>
											<td>3000</td>
											<td>4000</td>
										</tr>
										<tr>
											<td>103</td>
											<td>Gangtok (GTK) </td>
											<td>Pelling (PLG)</td>
											<td>Transfer</td>
											<td></td>
											<td>
												<abbr title="HH:mm Format">06:00</abbr>
											</td>
											<td></td>
											<td>
												<div style={{ maxWidth: "150px" }}>
													You can also visit the Yumesumdong (Zero Point)
													(Charges Extra, Directly Payable to Driver)
												</div>
											</td>
											<td>2500</td>
											<td>2800</td>
											<td>2800</td>
											<td>3200</td>
											<td>3000</td>
											<td>4000</td>
										</tr>
										<tr>
											<td></td>
											<td>Gangtok (GTK) </td>
											<td>Pelling (PLG)</td>
											<td>Sightseeing and Transfer via Namchi and Ravangla</td>
											<td></td>
											<td></td>
											<td></td>
											<td>
												<div style={{ maxWidth: "150px" }}>
													Your North East tour is bound to bring you two closer
													..... also visit the Singba Rhododendron Sanctuary
													famous for its sea of wild Rhododendrons.
												</div>
											</td>
											<td>2500</td>
											<td>3000</td>
											<td>3000</td>
											<td>3200</td>
											<td>3000</td>
											<td>4000</td>
										</tr>
									</tbody>
								</Table>
							</Box>
							<Box>
								<CopyToClipboardButton onClick={() => copy()} level="primary">
									<Icons.ClipboardCopy />
									Copy Template Format
								</CopyToClipboardButton>
							</Box>
						</Stack>
					)}
				</CopyToClipboard>
			</Container>
		</>
	)
}
