import { Box, Container, Tip } from "@sembark-travel/ui/base"
import { Helmet } from "react-helmet-async"
import { RoomTypeForm } from "../../../RoomTypes"
import { useNavigate, generatePath } from "../../../router-utils"
import { Breadcrumbs } from "@sembark-travel/ui/router"
import { ForbidUnlessAuthorized, PERMISSIONS } from "../../../Auth"

export default function NewRoomType() {
	const navigate = useNavigate()
	return (
		<>
			<Helmet>
				<title>New Room Type</title>
			</Helmet>
			<ForbidUnlessAuthorized permission={PERMISSIONS.UPLOAD_BULK_HOTELS}>
				<Breadcrumbs
					title="New Room Type"
					items={[
						[generatePath("/hotels"), "Hotels"],
						[generatePath("/room-types"), "Room Types"],
						["", "New"],
					]}
				/>
				<Box paddingX={{ md: "4" }} paddingY={{ md: "8" }}>
					<Box maxWidth="4xl" marginX="auto">
						<Box borderWidth={{ md: "1" }} rounded="md" bgColor="default">
							<Container paddingY="6">
								<RoomTypeForm
									onSuccess={() => navigate("/room-types")}
									onCancel={() => navigate("/room-types")}
								/>
							</Container>
						</Box>
					</Box>
					<Tip>
						Using an existing name will modify the details of that existing room
						type.
					</Tip>
				</Box>
			</ForbidUnlessAuthorized>
		</>
	)
}
